import React from "react";
import walletImage from "../../../../../../assets/Img/wallet.png";
import { Button, Grid, Typography } from "@mui/material";
const DownloadInvoicePage = ({ getInvoiceHandler }) => {
  return (
    <>
      <Grid container style={{ margin: "15px 0" }}>
        <Button
          className="btn_primary"
          onClick={() => getInvoiceHandler(false)}
        >
          Back
        </Button>
      </Grid>
      <Grid
        container
        style={{ border: "1px solid #DDDDDD", paddingBottom: "20px" }}
      >
        <Grid
          container
          item
          xs={12}
          style={{ padding: "10px 0", borderBottom: "1px solid #DDDDDD" }}
          direction="column"
          alignItems="center"
          gap="8px"
        >
          <Typography className="blackcolortypo fs20px">Amount</Typography>
          <Typography className="blackcolor525252_500 fs50px">
            {" "}
            ₹ 5000{" "}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ padding: "10px 0", borderBottom: "1px solid #DDDDDD" }}
          direction="column"
          alignItems="center"
          gap="8px"
        >
          <img src={walletImage} alt="walletImage" style={{ width: "40px" }} />
          <Typography className="greyColorTypo717171_400 fs18px">
            To Your
          </Typography>
          <Typography className="blackcolortypo fs20px">
            PostMyAd Wallet
          </Typography>
          <Typography className="greyColorTypo717171_400 fs18px ">
            Linked Mobile no : 9789455632
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ padding: "10px 0" }}
          direction="column"
          alignItems="center"
          gap="8px"
        >
          <Typography className="greyColorTypo717171_400 fs18px">
            From Your
          </Typography>
          <Typography className="blackcolortypo fs20px">
            Punjab National bank
          </Typography>
          <Typography className="greyColorTypo717171_400 fs14px ">
            A/c No. XX 9898
          </Typography>
          <Typography className="greyColorTypo717171_400 fs18px ">
            Added at 01:25 PM, 31 jul 2022
          </Typography>
          <Typography className="greyColorTypo717171_400 fs18px ">
            Wallet Ref No: 415236547898412
          </Typography>
          <Typography className="greyColorTypo717171_400 fs18px ">
            UPI Ref No: 415236547898412
          </Typography>

          <Button
            style={{ marginTop: "50px", width: "400px" }}
            className="purple_btn2"
          >
            Download invoice
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DownloadInvoicePage;
