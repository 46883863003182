import { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";

const Campaign = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "CAMPAIGN_NAME":
      return { ...state, campaignName: action.value };
    case "ABOUT_CAMPAIGN":
      return { ...state, aboutCampaign: action.value };
    case "DATE_RANGE":
      return { ...state, dateRange: action.value };
    case "ACTUAL_DATE":
      return { ...state, actualDate: action.value };
    case "AD_DURATION":
      return { ...state, adDuration: action.value };
    case "TIME_SLOTS_KEY":
      return { ...state, timeSlotKey: action.value };
    case "TIME_SLOTS":
      return { ...state, timeSlots: action.value };
    case "APPLY_TO_ALL_DAYS":
      return { ...state, applyToAllDays: action.value };
    case "BILLBOARDS":
      return { ...state, billboards: action.value };
    case "FILE":
      return { ...state, file: action.value };
    case "FILE_TYPE":
      return { ...state, filetype: action.value };
    case "TOTAL_AMOUNT":
      return { ...state, totalAmount: action.value };

    default: {
      throw new Error(`action type : ${action.type}`);
    }
  }
};

function CampaignControllerProvider({ children }) {
  const initialState = {
    campaignName: "",
    aboutCampaign: "",
    actualDate: {},
    dateRange: "",
    adDuration: "",
    timeSlotKey: {},
    timeSlots: {},
    applyToAllDays: false,
    billboards: [],
    file: "",
    totalAmount: "",
    filetype: "",
  };
  const [controller, dispatch] = useReducer(reducer, initialState);
  // const provider = useMemo(() => [controller, dispatch], []);

  // eslint-disable-next-line
  return (
    <Campaign.Provider value={[controller, dispatch]}>
      {children}
    </Campaign.Provider>
  );
}

// Typechecking props for the FilterControllerProvider
CampaignControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useCampaignController = () => {
  const context = useContext(Campaign);
  if (!context) {
    throw new Error(
      "useCampaignController should be used inside the CamapignControllerProvider."
    );
  }
  return context;
};

const setCampaignName = (dispatch, value) =>
  dispatch({ type: "CAMPAIGN_NAME", value });

const setAboutCampaign = (dispatch, value) =>
  dispatch({ type: "ABOUT_CAMPAIGN", value });

const setDateRange = (dispatch, value) =>
  dispatch({ type: "DATE_RANGE", value });

const setActualDate = (dispatch, value) =>
  dispatch({ type: "ACTUAL_DATE", value });

const setAdDuration = (dispatch, value) =>
  dispatch({ type: "AD_DURATION", value });

const setTimeSlots = (dispatch, value) =>
  dispatch({ type: "TIME_SLOTS", value });

const setTimeSlotsKey = (dispatch, value) =>
  dispatch({ type: "TIME_SLOTS_KEY", value });

const setApplyToAllDays = (dispatch, value) =>
  dispatch({ type: "APPLY_TO_ALL_DAYS", value });

const setBillboards = (dispatch, value) =>
  dispatch({ type: "BILLBOARDS", value });

const setFile = (dispatch, value) => dispatch({ type: "FILE", value });

const setFileType = (dispatch, value) => dispatch({ type: "FILE_TYPE", value });

const setTotalAmount = (dispatch, value) =>
  dispatch({ type: "TOTAL_AMOUNT", value });

export {
  CampaignControllerProvider,
  useCampaignController,
  setCampaignName,
  setAboutCampaign,
  setActualDate,
  setDateRange,
  setAdDuration,
  setTimeSlotsKey,
  setTimeSlots,
  setApplyToAllDays,
  setBillboards,
  setTotalAmount,
  setFileType,
  setFile,
};
