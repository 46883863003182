import React, { useState, useEffect, Suspense } from "react";
import {
  Grid,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
  Button,
} from "@mui/material";

import { Doughnut, Pie } from "react-chartjs-2";

import { Link } from "react-router-dom";

// React Icons
import { IoMdAddCircleOutline } from "react-icons/io";
import { BiRupee } from "react-icons/bi";
// Card Icon

import usercardIcon from "../../assets/Img/user-cardIcon.png";
import agencycardIcon from "../../assets/Img/agency-cardIcon.png";
import cluscardIcon from "../../assets/Img/clus-cardIcon.png";
import subcardIcon from "../../assets/Img/sub-cardIcon.png";

///===> FetchIP
import { FETCH_URL } from "../../fetchIp";
import axios from "../../api/axiosInstance";

import Analytics from "./Home_Analytics/AnalyticsTabs";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { AuthContext } from "../../Context/AuthContext";
import axiosInstance from "../../api/axiosInstance";

/// new icons
import deviceImg from "../../assets/Img/deviceHome.png";
import orderImg from "../../assets/Img/orderHome.png";
import campaignImg from "../../assets/Img/campaignHome.png";
import biilboardImg from "../../assets/Img/billboardHome.png";
import topupImg from "../../assets/Img/topupHome.png";
import revenueImg from "../../assets/Img/reveneHome.png";
import spendImg from "../../assets/Img/spendHome.png";
import depositImg from "../../assets/Img/depositHome.png";

import permissionUtils from "../../component/permissionUtils";
import AppCharts from "./Home_Analytics/AgeGroupChart";
const optionsSecond = {
  title: {
    display: true,
    text: "Donut Chart",
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  // cutout: "0%",
};

const MapComponent = React.lazy(() => import("./HomeMap/GoogleMap/Map"));

const Home = () => {
  const navigate = useNavigate();
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  const { user } = React.useContext(AuthContext);

  const [userCount, setUserCount] = React.useState(null);
  const [viewCount, setViewCount] = React.useState(null);
  const [revenueData, setRevenueData] = React.useState(null);

  const [allDashboardData, setAllDashboardData] = React.useState(null);

  const getCountUser = async () => {
    try {
      let res = await axios.get("/api/user/getAllTypesUserCount");

      setUserCount(res?.data?.data);
    } catch (error) {}
  };

  const getAllDasboard = async () => {
    try {
      let res = await axios.get(
        "/api/billboard/getDevBillOrderData?isTesting=false"
      );
      console.log("RESPONSE FROM USER COUNT => ", res);
      setAllDashboardData(res?.data?.data);
    } catch (error) {}
  };

  const getViewsMaleFemale = async () => {
    try {
      let res = await axios.get("/api/order/getAgeGroupAViews");

      setViewCount(res?.data?.data);
      console.log("viewcount", res?.data?.data);
    } catch (error) {}
  };
  const Allviews = viewCount?.viewsData[0]?.billViews;
  const dataSecond = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: [
          viewCount?.viewsData[0]?.billMaleViews,
          viewCount?.viewsData[0]?.billFemaleViews,
        ],
        backgroundColor: ["#91D5EF", "#EF669E"],
        hoverBackgroundColor: ["#91D5EF", "#EF669E"],
        borderWidth: 1,
      },
    ],
  };

  const getRevenueCount = async () => {
    try {
      let res = await axios.get("/api/payment/getFinanceOverview");

      setRevenueData(res?.data?.data);
    } catch (error) {}
  };

  const [billBoardList, setBillBoardList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getallBillBoardsScreens = async () => {
    setLoading(true);
    let token = JSON.parse(localStorage.getItem("userData")).token;
    const response = await fetch(
      `${FETCH_URL}/api/billBoard/getTopTenBillboards?isTesting=false&page=1&limit=10`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let res = await response.json();

    if (response.ok) {
      console.log("All Screens resp ===> ", res);
      setLoading(false);
      setBillBoardList(res?.data);
    } else {
      setLoading(false);
    }
  };
  const [allBillboards, setAllBillboards] = useState([]);

  const getallDevices = async () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    const response = await fetch(`${FETCH_URL}/api/billboard/getallbillboard`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let res = await response.json();
    if (response.ok) {
      setAllBillboards(res.msg);
    } else {
    }
  };

  const [topClusterHeadData, setTopClusterHeadData] = useState([]);
  const getTopClusterhead = async () => {
    try {
      const res = await axiosInstance.get(`/api/user/getTopClusterhead`);

      if (res?.status == 200) {
        setTopClusterHeadData(res?.data?.msg);
      } else {
      }
    } catch (error) {}
  };
  const [topAdvertiseeData, setTopAdvertiseeData] = useState();

  const getTopAdvertiseData = async () => {
    try {
      const res = await axiosInstance.get(
        `api/user/getTopAdviserList?page=1&limit=10`
      );

      if (res?.status == 200) {
        setTopAdvertiseeData(res?.data?.data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTopAdvertiseData();
    getTopClusterhead();
    getRevenueCount();
    getallBillBoardsScreens();
    getCountUser();
    getViewsMaleFemale();

    getallDevices();

    getAllDasboard();
  }, []);

  return (
    <>
      <div className="width-90LR">
        {/*  */}
        <Grid
          container
          justifyContent="space-between"
          rowGap="20px"
          sx={{ marginTop: "100px" }}
        >
          <Grid
            container
            direction="column"
            item
            md={2.8}
            sm={3.8}
            xs={12}
            className="card_div_new"
            gap="10px"
          >
            <Link
              to={PermissionCheck("view-device") && "/admin/device"}
              className="linkcolor"
            >
              <Grid
                container
                justifyContent="space-between"
                textAlign="center"
                alignItems="center"
              >
                {" "}
                <Grid item>
                  <img src={deviceImg} alt="devices" />
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography className="blackcolortypo fs20px">
                      Devices
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="blackcolortypo fs28px">
                      {" "}
                      {/* {allDashboardData?.devicData
                        ? allDashboardData?.devicData.find(
                            (item) => item?._id === "Offline"
                          )?.count +
                          allDashboardData?.devicData.find(
                            (item) => item?._id === "Online"
                          )?.count
                        : 0} */}
                      {allDashboardData?.devicData &&
                      allDashboardData.devicData.length > 0
                        ? allDashboardData.devicData.find(
                            (item) => item?._id === "Offline"
                          )?.count +
                          allDashboardData.devicData.find(
                            (item) => item?._id === "Online"
                          )?.count
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid item>
                    <Typography className="blackcolortypo fs16px">
                      {allDashboardData
                        ? allDashboardData?.devicData.find(
                            (item) => item?._id === "Online"
                          )?.count
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item textAlign="right">
                    <Typography className="blackcolortypo fs16px">
                      {allDashboardData
                        ? allDashboardData?.devicData.find(
                            (item) => item?._id === "Offline"
                          )?.count
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <progress
                  className="progressHome"
                  max={
                    allDashboardData
                      ? allDashboardData?.devicData.find(
                          (item) => item?._id === "Offline"
                        )?.count +
                        allDashboardData?.devicData.find(
                          (item) => item?._id === "Online"
                        )?.count
                      : 0
                  }
                  value={
                    allDashboardData
                      ? allDashboardData?.devicData.find(
                          (item) => item?._id === "Online"
                        )?.count
                      : 0
                  }
                ></progress>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography className="secondary_heading500 fs16px">
                    <span className="online_dot"></span> Online
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="secondary_heading500 fs16px">
                    <span className="onffine_dot"></span> Offline
                  </Typography>
                </Grid>
              </Grid>
            </Link>
          </Grid>
          <Grid
            container
            direction="column"
            item
            md={2.8}
            sm={3.8}
            xs={12}
            className="card_div_new"
            gap="10px"
          >
            <Link
              to={
                PermissionCheck("view-live-billboard") &&
                "/admin/billboardscreens"
              }
              className="linkcolor"
            >
              <Grid
                container
                justifyContent="space-between"
                textAlign="center"
                alignItems="center"
              >
                {" "}
                <Grid item>
                  <img src={biilboardImg} alt="devices" />
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography className="blackcolortypo fs20px">
                      BillBoard
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="blackcolortypo fs28px">
                      {" "}
                      {allDashboardData
                        ? allDashboardData?.billData.find(
                            (item) => item?._id?.device === "Total"
                          )?.count
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid item>
                    <Typography className="blackcolortypo fs16px">
                      {allDashboardData
                        ? allDashboardData?.billData.find(
                            (item) => item?._id?.device === "Online"
                          )?.count
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item textAlign="right">
                    <Typography className="blackcolortypo fs16px">
                      {allDashboardData
                        ? allDashboardData?.billData?.find(
                            (item) => item?._id?.device === "Offline"
                          )?.count
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <progress
                  className="progressHome"
                  max={
                    allDashboardData
                      ? allDashboardData?.billData.find(
                          (item) => item?._id?.device === "Total"
                        )?.count
                      : 0
                  }
                  value={
                    allDashboardData
                      ? allDashboardData?.billData.find(
                          (item) => item?._id?.device === "Online"
                        )?.count
                      : 0
                  }
                ></progress>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item>
                  {" "}
                  <Typography className="secondary_heading500 fs16px">
                    <span className="online_dot"></span> Online
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className="secondary_heading500 fs16px">
                    <span className="onffine_dot"></span> Offline
                  </Typography>
                </Grid>
              </Grid>
            </Link>
          </Grid>
          <Grid
            container
            direction="column"
            item
            sm={5.8}
            xs={12}
            md={2.8}
            className="card_div_new"
            gap="10px"
          >
            <Link
              to={PermissionCheck("view-billboard") && "/admin/order"}
              className="linkcolor"
            >
              <Grid
                container
                justifyContent="space-between"
                textAlign="center"
                alignItems="center"
              >
                {" "}
                <Grid item>
                  <img src={campaignImg} alt="devices" />
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography className="blackcolortypo fs20px">
                      Orders
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="blackcolortypo fs28px">
                      {" "}
                      {allDashboardData
                        ? allDashboardData?.orders?.totalNOrders
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid item>
                    <Typography className="blackcolortypo fs16px">
                      {allDashboardData
                        ? allDashboardData?.orders?.pendingNOrders
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item textAlign="right">
                    <Typography className="blackcolortypo fs16px">
                      {allDashboardData
                        ? allDashboardData?.orders?.totalNOrders -
                          allDashboardData?.orders?.pendingNOrders
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <progress
                  className="progressHomeOrder"
                  max={
                    allDashboardData
                      ? allDashboardData?.orders?.totalNOrders
                      : 0
                  }
                  value={
                    allDashboardData
                      ? allDashboardData?.orders?.pendingNOrders
                      : 0
                  }
                ></progress>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item>
                  {" "}
                  <Typography className="secondary_heading500 fs16px">
                    <span className="pending_dot"></span> Pending
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="secondary_heading500 fs16px">
                    <span className="other_dot"></span> Other
                  </Typography>
                </Grid>
              </Grid>
            </Link>
          </Grid>
          <Grid
            container
            direction="column"
            item
            md={2.8}
            sm={3.8}
            xs={12}
            className="card_div_new"
            gap="10px"
          >
            <Link
              to={PermissionCheck("view-live-campaign") && "/admin/campaignmgt"}
              className="linkcolor"
            >
              <Grid
                container
                justifyContent="space-between"
                textAlign="center"
                alignItems="center"
              >
                {" "}
                <Grid item>
                  <img src={orderImg} alt="devices" />
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography className="blackcolortypo fs20px">
                      Campaigns
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="blackcolortypo fs28px">
                      {" "}
                      {allDashboardData
                        ? allDashboardData?.orders?.totalCamOrders
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid item>
                    <Typography className="blackcolortypo fs16px">
                      {allDashboardData
                        ? allDashboardData?.orders?.pendingCamOrders
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item textAlign="right">
                    <Typography className="blackcolortypo fs16px">
                      {allDashboardData
                        ? allDashboardData?.orders?.totalCamOrders -
                          allDashboardData?.orders?.pendingCamOrders
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <progress
                  className="progressHomeOrder"
                  max={
                    allDashboardData
                      ? allDashboardData?.orders?.totalCamOrders
                      : 0
                  }
                  value={
                    allDashboardData
                      ? allDashboardData?.orders?.pendingCamOrders
                      : 0
                  }
                ></progress>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid item>
                    <Typography className="secondary_heading500 fs16px">
                      <span className="pending_dot"></span> Pending
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography className="secondary_heading500 fs16px">
                      <span className="other_dot"></span> Other
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Link>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={12} md={12} lg={4.9}>
            <Grid
              container
              sx={{
                border: "1px solid #ddd",
                margin: "24px 0",
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              <Grid container justifyContent="space-between">
                <>
                  <Grid item>
                    <Grid container alignItems="center">
                      <Typography className="blackcolortypo500 fs20px">
                        Finance Overview
                        <Button className="addbtn"></Button>
                      </Typography>
                    </Grid>{" "}
                  </Grid>
                  {PermissionCheck("view-finance-overview") && (
                    <Grid item>
                      <Link
                        to={`/admin/finance?tab=${1}`}
                        className="link-style"
                      >
                        <Typography className="purplecolortypo-500 pt7px">
                          See All
                        </Typography>
                      </Link>
                    </Grid>
                  )}
                </>
              </Grid>
              <Grid container justifyContent="space-between" rowGap="15px">
                <Grid
                  container
                  item
                  lg={2.8}
                  sm={3.7}
                  xs={12}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "16px",
                    padding: "8px 0 8px 0px",
                  }}
                  className="mt10px"
                  direction="column"
                  alignItems="center"
                >
                  <IconButton className="cursor_auto" disableRipple>
                    <img
                      src={topupImg}
                      alt="Cluster"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                  <Typography className="greycolortypo       " align="center">
                    Total Topup
                  </Typography>
                  <Typography
                    align="center"
                    className="purplecolortypo fs20px "
                  >
                    ₹{" "}
                    {revenueData
                      ? Math.round(
                          revenueData?.find(
                            (item) => item?._id === "depositByAdmin"
                          )?.totalAmount
                        )
                      : 0}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  lg={2.8}
                  sm={3.7}
                  xs={12}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "16px",
                    padding: "8px 0 8px 0px",
                  }}
                  className="mt10px"
                  direction="column"
                  alignItems="center"
                >
                  <IconButton className="cursor_auto" disableRipple>
                    <img
                      src={depositImg}
                      alt="AddAgency"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                  <Typography className="greycolortypo       " align="center">
                    Total Deposit
                  </Typography>
                  <Typography
                    align="center"
                    className="purplecolortypo fs20px "
                  >
                    ₹{" "}
                    {revenueData
                      ? Math.round(
                          revenueData?.find(
                            (item) => item?._id === "walletDeposit"
                          )?.totalAmount
                        )
                      : 0}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  lg={2.8}
                  sm={3.7}
                  xs={12}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "16px",
                    padding: "8px 0 8px 0px",
                  }}
                  className="mt10px"
                  direction="column"
                  alignItems="center"
                >
                  <IconButton className="cursor_auto" disableRipple>
                    <img
                      src={spendImg}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                  <Typography className="greycolortypo" align="center">
                    Total Spent
                  </Typography>
                  <Typography
                    align="center"
                    className="purplecolortypo fs20px "
                  >
                    ₹{" "}
                    {revenueData
                      ? Math.round(
                          revenueData?.find((item) => item?._id === "margin")
                            ?.totalAmount
                        )
                      : 0}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  lg={2.8}
                  sm={3.7}
                  xs={12}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "16px",
                    padding: "8px 0 8px 0px",
                  }}
                  className="mt10px"
                  direction="column"
                  alignItems="center"
                >
                  <IconButton className="cursor_auto" disableRipple>
                    <img
                      src={revenueImg}
                      alt="UserLogo"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                  <Typography className="greycolortypo       " align="center">
                    Total refund
                  </Typography>
                  <Typography
                    align="center"
                    className="purplecolortypo fs20px "
                  >
                    ₹{" "}
                    {revenueData
                      ? Math.round(
                          revenueData?.find((item) => item?._id === "refund")
                            ?.totalAmount
                        )
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                border: "1px solid #ddd",
                margin: "24px 0",
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              <Grid container justifyContent="space-between">
                {(user?.role === "SAdmin" || user?.permissions?.campaign) && (
                  <>
                    <Grid item>
                      <Grid container alignItems="center">
                        <Typography className="blackcolortypo500 fs20px">
                          User Overview
                          <Button className="addbtn"></Button>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Link to="/admin/manage-user" className="link-style">
                        <Typography className="purplecolortypo-500 pt7px">
                          See All
                        </Typography>
                      </Link>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container justifyContent="space-between" rowGap="15px">
                <Grid
                  container
                  item
                  lg={2.8}
                  sm={3.7}
                  xs={12}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "16px",
                    padding: "8px 0 8px 0px",
                  }}
                  className="mt10px"
                  direction="column"
                  alignItems="center"
                >
                  <IconButton className="cursor_auto" disableRipple>
                    <img
                      src={cluscardIcon}
                      alt="Cluster"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                  <Typography className="greycolortypo " align="center">
                    Clusterhead
                  </Typography>
                  <Typography align="center" className="purplecolortypo fs20px">
                    {userCount
                      ? userCount?.find((item) => item?._id === "clusterhead")
                          ?.count
                      : 0}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  lg={2.8}
                  sm={3.7}
                  xs={12}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "16px",
                    padding: "8px 0 8px 0px",
                  }}
                  className="mt10px"
                  direction="column"
                  alignItems="center"
                >
                  <IconButton className="cursor_auto" disableRipple>
                    <img
                      src={subcardIcon}
                      alt="AddAgency"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                  <Typography className="greycolortypo" align="center">
                    Sub-Admin
                  </Typography>
                  <Typography
                    align="center"
                    className="purplecolortypo fs20px "
                  >
                    {userCount
                      ? userCount?.find((item) => item?._id === "admin")?.count
                      : 0}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  lg={2.8}
                  sm={3.7}
                  xs={12}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "16px",
                    padding: "8px 0 8px 0px",
                  }}
                  className="mt10px"
                  direction="column"
                  alignItems="center"
                >
                  <IconButton className="cursor_auto" disableRipple>
                    <img
                      src={agencycardIcon}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                  <Typography className="greycolortypo       " align="center">
                    Business Owner
                  </Typography>
                  <Typography
                    align="center"
                    className="purplecolortypo fs20px "
                  >
                    {userCount
                      ? userCount?.find((item) => item?._id === "businessowner")
                          ?.count
                      : 0}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  lg={2.8}
                  sm={3.7}
                  xs={12}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "16px",
                    padding: "8px 0 8px 0px",
                  }}
                  className="mt10px"
                  direction="column"
                  alignItems="center"
                >
                  <IconButton className="cursor_auto" disableRipple>
                    <img
                      src={usercardIcon}
                      alt="UserLogo"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                  <Typography className="greycolortypo       " align="center">
                    User
                  </Typography>
                  <Typography
                    align="center"
                    className="purplecolortypo fs20px "
                  >
                    {userCount
                      ? userCount?.find((item) => item?._id === "normaluser")
                          ?.count
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6.9}>
            <Grid
              container
              justifyContent="space-between"
              sx={{
                margin: "35px 0",
              }}
            >
              <Grid
                container
                className="relative card_div_new"
                xs={12}
                md={4.8}
              >
                <Grid container justifyContent="center">
                  <Grid container justifyContent="center">
                    <Typography className="gray5A5A5Atypo500 fs20px">
                      Views Distribution
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      // width: "240px",
                      // marginTop: "0px",
                      // marginBottom: "20px",
                      alignItems: "center",
                    }}
                  >
                    <Doughnut data={dataSecond} options={optionsSecond} />
                  </Grid>
                  <Grid className="text_inside_donut" item>
                    <Grid container alignItems="center" direction="column">
                      {" "}
                      <Typography className="greycolor505050500 fs20px">
                        Total view
                      </Typography>
                      <Typography className="blackcolortypo fs20px">
                        {Allviews ? Allviews : 0}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      gap="10px"
                    >
                      <Grid item>
                        <Grid container>
                          {" "}
                          <span className="pink_blockAnalytics"></span>
                          <Typography className="greyColorTypo717171_400">
                            Male : {viewCount?.viewsData[0]?.billMaleViews}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container>
                          {" "}
                          <span className="blue_blockAnalytics"></span>
                          <Typography className="greyColorTypo717171_400">
                            Female : {viewCount?.viewsData[0]?.billFemaleViews}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                className="relative card_div_new"
                xs={12}
                md={6.8}
              >
                {/* <AnalyticsViews viewCount={viewCount} /> */}
                <AppCharts viewCount={viewCount} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="mt1px" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card className="dash-table-card">
              <Grid container className="mt10px" justifyContent="space-between">
                <Grid item>
                  <Typography className="blackcolortypo500 fs1-3rem">
                    Top 10 Billboards
                    {PermissionCheck("add-billboard") && (
                      <Button className="addbtn">
                        <IoMdAddCircleOutline className="iconcolorbk   fs25px mr10  mt2px " />
                        <Link
                          to="/admin/addbillboard"
                          className="linkcolor purplecolortypo"
                        >
                          <Typography className="purplecolortypo-500 fs18px ml5px">
                            Add New
                          </Typography>
                        </Link>
                      </Button>
                    )}
                  </Typography>
                </Grid>
                {PermissionCheck("view-live-billboard") && (
                  <Grid item>
                    <Link to="/admin/billboardscreens" className="link-style">
                      <Typography className="purplecolortypo-500  pt7px">
                        See All
                      </Typography>
                    </Link>
                  </Grid>
                )}
              </Grid>
              <div className="table-style">
                <Grid container className="width100 " sx={{ mb: 3 }}>
                  <Table
                    className="width100 home_table"
                    aria-label="simple table "
                  >
                    <TableHead className="width100 tablehead homelist-item ">
                      <TableRow className="tablehead  ">
                        <TableCell className="blackcolortypo500 tablecell fs18px ">
                          Billboard Name
                        </TableCell>
                        <TableCell
                          className="blackcolortypo500 tablecell fs18px"
                          align="center"
                        >
                          Orders
                        </TableCell>
                        <TableCell
                          className="blackcolortypo500 tablecell fs18px"
                          align="center"
                        >
                          Campaigns
                        </TableCell>
                        <TableCell
                          className="blackcolortypo500 tablecell fs18px"
                          align="center"
                        >
                          Revenue
                        </TableCell>
                        <TableCell
                          className="blackcolortypo500 tablecell fs18px"
                          align="center"
                        >
                          Total Views
                        </TableCell>
                        <TableCell
                          className="blackcolortypo500 tablecell fs18px"
                          align="center"
                        >
                          Male Views
                        </TableCell>
                        <TableCell
                          className="blackcolortypo500 tablecell fs18px"
                          align="center"
                        >
                          Female Views
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tablerow home_table">
                      {billBoardList?.length > 0 ? (
                        <>
                          {" "}
                          {billBoardList?.map((item, index) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className="homelist-itemtext fs16px"
                                  component="th"
                                  scope="row"
                                  align="left"
                                >
                                  <Link
                                    to={
                                      user?.role === "SAdmin" ||
                                      PermissionCheck("view-live-billboard")
                                        ? `/admin/billboardscreens/${item?._id}`
                                        : "#"
                                    }
                                    className="linkcolor"
                                    state={{ item: item }}
                                  >
                                    <Typography className="homelist-itemtext fs16px text_transform_capitalize">
                                      {" "}
                                      {item?.billboardName
                                        ? item?.billboardName
                                        : "Billboard Deleted"}
                                    </Typography>
                                  </Link>
                                </TableCell>

                                <TableCell
                                  scope="row"
                                  padding="none"
                                  className="blackcolor484848 "
                                  align="center"
                                >
                                  {item?.orderCount}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  padding="none"
                                  className="blackcolor484848"
                                  align="center"
                                >
                                  {item?.campaignCount}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  padding="none"
                                  className="blackcolor484848"
                                  align="center"
                                >
                                  <BiRupee />{" "}
                                  {parseInt(item?.revenue).toFixed(2)}
                                  {/* {item?.revenue && item?.revenue.length > 0
                                      ? item?.revenue[0]?.totalRevenue?.toFixed(
                                          2
                                        )
                                      : 0} */}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  padding="none"
                                  className="blackcolor484848"
                                  align="center"
                                >
                                  {item?.totalView}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  padding="none"
                                  className="blackcolor484848"
                                  align="center"
                                >
                                  {item?.maleViews}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  padding="none"
                                  className="blackcolor484848"
                                  align="center"
                                >
                                  {item?.femaleViews}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {loading &&
                            [1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((item, index) => {
                              return (
                                <TableRow
                                  key={item.name}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    className="homelist-itemtext fs16px"
                                    component="th"
                                    scope="row"
                                    align="left"
                                  >
                                    <Typography className="homelist-itemtext fs16px">
                                      {" "}
                                      <Skeleton
                                        variant="text"
                                        sx={{
                                          fontSize: "1rem",
                                          width: "30%",
                                        }}
                                      />
                                    </Typography>

                                    <Typography className=" blackcolor484848 fs14px">
                                      <Skeleton
                                        variant="text"
                                        sx={{
                                          fontSize: "1rem",
                                          width: "20%",
                                        }}
                                      />
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    scope="row"
                                    padding="none"
                                    className="greycolortypo "
                                    align="center"
                                  >
                                    <Grid container justifyContent="center">
                                      {" "}
                                      <Skeleton
                                        variant="text"
                                        sx={{
                                          fontSize: "1rem",
                                          width: "30%",
                                        }}
                                      />
                                    </Grid>
                                  </TableCell>
                                  <TableCell
                                    scope="row"
                                    padding="none"
                                    className="greycolortypo"
                                    align="center"
                                  >
                                    <Grid container justifyContent="center">
                                      {" "}
                                      <Skeleton
                                        variant="text"
                                        sx={{
                                          fontSize: "1rem",
                                          width: "30%",
                                        }}
                                      />
                                    </Grid>
                                  </TableCell>
                                  <TableCell
                                    scope="row"
                                    padding="none"
                                    className="greycolortypo"
                                    align="center"
                                  >
                                    <Grid container justifyContent="center">
                                      {" "}
                                      <Skeleton
                                        variant="text"
                                        sx={{
                                          fontSize: "1rem",
                                          width: "30%",
                                        }}
                                      />
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </>
                      )}
                    </TableBody>
                  </Table>
                  {billBoardList?.length === 0 && loading == false && (
                    <Grid
                      container
                      alignItems="center"
                      sx={{
                        height: "610px",
                      }}
                    >
                      <Typography
                        align="center"
                        className="blackcolor484848 width100 "
                      >
                        No BillBoard Found <br />
                        Please Add BillBoard To See Data
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>

        <Card
          className="dash-table-card"
          style={{ marginTop: "30px", marginBottom: "40px" }}
        >
          <Grid container className="mt10px" justifyContent="space-between">
            <Grid item>
              <Typography className="blackcolortypo500 fs1-3rem">
                Locations
              </Typography>
            </Grid>
            <Grid item>
              <Link to="/admin/location" className="link-style">
                <Typography className="purplecolortypo-500 pt7px">
                  See All
                </Typography>
              </Link>
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Grid item lg={12} xs={12}>
              <div>
                <Suspense fallback={<div> Please Wait... </div>}>
                  <MapComponent data={allBillboards} />
                </Suspense>
              </div>
            </Grid>
          </Grid>
        </Card>
        <Grid container>
          <Analytics />
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          sx={{ marginBottom: "50px" }}
          rowGap="30px"
        >
          {user?.userRole !== 2 && (
            <Grid
              container
              item
              md={5.8}
              sm={12}
              sx={{
                border: "1px solid #ddd",
                padding: "10px 20px",
                borderRadius: "15px",
              }}
            >
              <div style={{ width: "100%" }}>
                <Grid container gap="16px">
                  {" "}
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography className="greycolor505050400 fs20px">
                        Top Clusterhead
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Typography className="greyColorTypo717171_400 fs16px">
                      Here’s top performing Clusterhead in last 7 days
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid container>
                      <Grid item xs={4.8}>
                        <Typography className="blackcolor484848 fs18px">
                          Clusterhead Name{" "}
                        </Typography>
                      </Grid>
                      <Grid container item justifyContent="flex-end" xs={3.6}>
                        <Typography className="blackcolor484848 fs18px">
                          Orders{" "}
                        </Typography>
                      </Grid>
                      <Grid container item justifyContent="flex-end" xs={3.6}>
                        <Typography className="blackcolor484848 fs18px">
                          Amount{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    {topClusterHeadData?.map((data, i) => (
                      <>
                        <Grid container key={i}>
                          <Grid container alignItems="center">
                            <Grid item xs={4.8}>
                              <Typography className="purplecolortypo fs16px text_transform_capitalize">
                                {data?.firstName} {data?.lastName}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              justifyContent="flex-end"
                              xs={3.6}
                            >
                              <Typography className="blackcolor484848 fs16px">
                                {data?.orderCount
                                  ? data?.pendingOrderCount +
                                    "/" +
                                    data?.orderCount
                                  : 0}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              justifyContent="flex-end"
                              xs={3.6}
                            >
                              <Typography className="blackcolor484848 fs16px">
                                ₹ {data?.revenueCount?.toFixed(2)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            borderTop: "1px solid #ddd",
                            margin: "6px 0 12px 0",
                          }}
                        ></Grid>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}
          <Grid
            container
            item
            md={5.8}
            sm={12}
            sx={{
              // boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid #ddd",
              padding: "10px 20px",
              borderRadius: "15px",
            }}
          >
            <div style={{ width: "100%" }}>
              <Grid container gap="16px">
                {" "}
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className="greycolor505050400 fs20px">
                      Top Advertisee
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography className="greyColorTypo717171_400 fs16px">
                    Here’s top Advertisee in last 7 days
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid container>
                    <Grid item xs={4.8}>
                      <Typography className="blackcolor484848 fs18px">
                        Advertisee Name
                      </Typography>
                    </Grid>
                    <Grid container item justifyContent="flex-end" xs={3.6}>
                      <Typography className="blackcolor484848 fs18px">
                        Orders{" "}
                      </Typography>
                    </Grid>
                    <Grid container item justifyContent="flex-end" xs={3.6}>
                      <Typography className="blackcolor484848 fs18px">
                        Amount{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  {topAdvertiseeData?.map((tData, i) => (
                    <>
                      <Grid container key={i}>
                        <Grid container alignItems="center">
                          <Grid item xs={4.8}>
                            <Typography className="purplecolortypo fs16px text_transform_capitalize">
                              {/* {tData?.firstName} {tData?.lastName}
                               */}
                              {tData?.user?.firstName
                                ? tData?.user?.firstName
                                : tData?.user?.name
                                ? tData?.user?.name === null ||
                                  tData?.user?.firstName === null
                                : "user deleted"
                                ? "user deleted"
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            justifyContent="flex-end"
                            xs={3.6}
                          >
                            <Typography className="blackcolor484848 fs16px">
                              {tData?.orderCounts}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            justifyContent="flex-end"
                            xs={3.6}
                          >
                            <Typography className="blackcolor484848 fs16px">
                              ₹ {tData?.amount?.toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          borderTop: "1px solid #ddd",
                          margin: "6px 0 12px 0",
                        }}
                      ></Grid>
                    </>
                  ))}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Home;
