import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "../../api/axiosInstance";
// core component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  // validation form
  const [formErrors, setFormErrors] = useState("");
  const navigate = useNavigate();

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
    navigate("/auth/signin");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let emailRegex = new RegExp(
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    );
    if (email === "") {
      setFormErrors("Email is required !");
    } else if (!emailRegex.test(email)) {
      setFormErrors("This is not a valid email format");
    } else {
      setFormErrors("");

      const sendmail = async () => {
        const body = {
          email: email,
        };
        // console.log("Body of email", email);
        try {
          const resp = await axios.post("/api/auth/forgotpassword", body);
          setSnackOpen(true);
          setSnackMsg(resp.data.msg);
          // setSnackMsg("Email has been sent")
          setEmail("");
        } catch (err) {
          // Handle Error Here
          console.error(err);
          // setSnackerropen(true);
          // setSnackErrMsg(err);
        }
      };
      sendmail();
    }
  };

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <div
        style={{
          background: "#05254C",
          height: "100vh",
        }}
      >
        <div>
          <Grid container component="main" sx={{ padding: "4em" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.5)",
                background: "#ffffff",
              }}
            >
              <Typography align="center">
                <img
                  src="/img/logosmall.png"
                  alt="logo"
                  style={{ width: "10em", marginTop: "80px" }}
                />
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "9em",
                  paddingLeft: "2em",
                  paddingRight: "3em",
                }}
              >
                <Typography
                  component="h1"
                  variant="h3"
                  sx={{ fontWeight: "700", fontSize: "32px" }}
                >
                  Trouble signing in? Resetting your password is easy.
                </Typography>
                <h5>
                  Please enter your registered e-mail. An e-mail will be sent to
                  you shortly with the link to reset your password. Please be
                  sure to check your spam / junk folders.
                </h5>
              </Box>
              <Box
                sx={{
                  mb: 19,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "0em 3em 0em 2em",
                  marginTop: "2em",
                }}
              >
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    error
                    fullWidth
                    id="email"
                    label="Enter E-mail"
                    name="email"
                    variant="filled"
                    size="small"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    InputProps={{ disableUnderline: true }}
                    sx={{ background: "#F7F8FD", borderRadius: "6px" }}
                    InputLabelProps={{
                      style: { color: "#080605" },
                    }}
                    helperText={formErrors}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: "#05254C",
                      fontWeight: "700",
                      fontSize: "18px",
                      "&:hover": {
                        backgroundColor: "#05254C",
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    Send mail
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
