import { Typography, Grid, Button, Snackbar, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import Calendar from "../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
import moment from "moment";
import axiosInstance from "../../../../../api/axiosInstance";
import CircularProgress from "@mui/material/CircularProgress";

const loaderStyle = {
  width: "20px", // Adjust the width as needed
  height: "20px", // Adjust the height as needed
  // Add any other styles you may need
};
function AnalyticsReport(props) {
  const [date, setDate] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [loading, setLoading] = useState(false);
  // SnackBar

  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [snackerropen, setSnackerropen] = useState(false);

  const [severity, setSeverity] = useState("info");

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };
  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };
  console.log("useridssssss", props);
  const sendReport = async () => {
    setLoading(true);

    try {
      let body = {
        startDate: moment(date?.[0]?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.[0]?.endDate).format("YYYY-MM-DD"),
        userIds: [props?.userId],
      };

      // Adding a 0.2-second delay using setTimeout
      setTimeout(async () => {
        try {
          let res = await axiosInstance.post(`api/order/getUserReport`, body);

          if (res.status === 200 || res.status === 201) {
            setSnackOpen(true);
            setSnackMsg("Report sent successfully");
            setLoading(false);

            handleShowSnackbar("success");
          }
        } catch (err) {
          console.log("Error from revenue analytics data => ", err);
          handleShowSnackbar("error");
          setSnackMsg("Report send failed");
          setLoading(false);
        }
      }, 200); // 200 milliseconds = 0.2 second
    } catch (err) {
      console.log("Error from revenue analytics data => ", err);
      handleShowSnackbar("error");
      setSnackMsg("Report send failed");
      setLoading(false);
    }
  };

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={severity}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={severity}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid container className="ReportAnalytics">
        <Grid item>
          <Typography className="typoReport">
            Send detailed analytics report of this user. Select Date range and
            click on “Send Report”
          </Typography>
        </Grid>
        <Grid item>
          <Grid container gap={1}>
            <Calendar getDataFromChildHandler={getDataFromChildHandler} />
            <Button
              sx={{ padding: "8px 20px", marginLeft: "10px" }}
              className="purple_btn_primary text_transform_inherit"
              onClick={() => {
                sendReport();
              }}
            >
              {loading ? (
                <CircularProgress color="inherit" style={loaderStyle} />
              ) : (
                "Send Report"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AnalyticsReport;
