import { Grid, Typography, Box, Pagination, Stack } from "@mui/material";
import React, { useState } from "react";
import Calendar from "../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
import axiosInstance from "../../../../../api/axiosInstance";
import { useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
//
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import no_data_img from "../../../../../assets/Img/no_data_found_img.png";
import TableSkeleton from "../../../../../component/TableSkeleton/TableSkeleton";
//
const headCells = [
  {
    id: "Action",
    numeric: false,
    label: "Action",
    sortable: false,
  },
  {
    id: "Date & Time",
    numeric: true,
    label: "Date & Time",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    console.log(event, property, "event, property");
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead
        className="width100 tablehead homelist-item"
        style={{ borderRadius: "8px" }}
      >
        <TableRow className="tablehead-2">
          {headCells.map((headCell, index) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              align={index == 1 ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
              {headCell?.label !== "Action" && (
                <TableSortLabel
                  align="center"
                  className="whitecolortypo500 "
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {orderBy === headCell.id ? (
                    <Box
                      component="span"
                      sx={visuallyHidden}
                      className="whitecolortypo500"
                    >
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

//
const Devicelogs = ({ props }) => {
  // table states
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dense, setDense] = React.useState(false);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  //
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState();
  const [logData, setLogData] = useState([]);
  const [totalLength, setTotalLength] = useState([]);
  const [totalPage, setTotalPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const handleChangeByPage = (event, page) => {
    setCurrentPage(page);
  };
  const getDataFromChildHandler = (data) => {
    setDate(data);
  };
  const getOrderLogs = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/device/getDeviceLogs?start_date=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&end_date=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}&macId=${props?.macId}&limit=10&page=${currentPage}`
      );
      if (res.status == 200) {
        console.log(res, "i am looking for data");
        setTotalLength(res?.data?.length);
        setTotalPage(Math.ceil(res.data.length / 10));
        setLogData(res?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getOrderLogs();
  }, [date, currentPage]);

  return (
    <Grid
      container
      justifyContent="space-between"
      rowGap="20px"
      style={{ margin: "0px 0" }}
    >
      <Grid container justifyContent="space-between">
        <Typography className="secondary_heading500 fs18px">
          Showing {logData?.length} out of {totalLength} Logs
        </Typography>
        <Calendar getDataFromChildHandler={getDataFromChildHandler} />
      </Grid>
      <Grid container className="width100">
        <Grid container className="width100 ">
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer className="width100 table_border_radius">
            <Table
              sx={{
                width: "100%",
              }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                className="tablerow"
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
              />
              {logData.length > 0 ? (
                <TableBody className="tablerow ">
                  {logData?.map((data, index) => {
                    const isItemSelected = isSelected(data?._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        tabIndex={-1}
                        key={data?._id}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{ width: "12.5%" }}
                        >
                          {`IOT device ${data?.action}`}
                        </TableCell>
                        <TableCell
                          ccomponent="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="right"
                          sx={{ width: "10%" }}
                        >
                          {moment(data?.createdAt)?.format(
                            "DD/MM/YYYY, h:mm a"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                loading && (
                  <TableSkeleton
                    rowNumber={new Array(10).fill(0)}
                    tableCell={new Array(2).fill("16.66%")}
                  />
                )
              )}
            </Table>
            {logData && logData?.length === 0 && (
              <Grid container justifyContent="center">
                {" "}
                <img
                  src={no_data_img}
                  alt="no data found"
                  style={{ width: "40%" }}
                />
              </Grid>
            )}
          </TableContainer>
        </Grid>
      </Grid>
      {logData?.length > 0 && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ display: "flex", marginTop: "20px", marginBottom: "20px" }}
        >
          <Stack spacing={2}>
            <Pagination
              page={currentPage}
              count={totalPage}
              onChange={handleChangeByPage}
              size="large"
            />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default Devicelogs;
