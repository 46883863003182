import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Typography,
  DialogContent,
  FormLabel,
  InputBase,
  ListItemButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Pagination,
  IconButton,
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "../../../../api/axiosInstance";
import ApproveDialog from "./ApproveDialog";
//React Icons
import { CgScreen } from "react-icons/cg";
import SearchIcon from "@mui/icons-material/Search";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",

  width: "36%",
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "93%",
      },
    },
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="dialogtitle" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogcrossicon"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const options = ["Option 1", "Option 2"];
export default function SelectBillboardDialog(props) {
  const devices = props?.row;

  const { getAllDevice } = props;

  const value = props?.value;

  const { getDeviceInfo } = props;
  console.log(
    "Check SelectBillboard Dialog Device  Data-=====================>",
    getDeviceInfo
  );
  const AssignDeviceID = props?.AssignDeviceID;
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  // ==================== States ======================== //
  const [originalData, setOriginalData] = React.useState(null);
  const [deviceData, setDeviceData] = React.useState(null);
  const [totalPage, setTotalPage] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [billboardname, setBillboardName] = React.useState("");
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setBillboardName("");
  };
  // paginations
  const handleChangeByPage = (event, value) => {
    console.log("Page Number Clicked ==> ", value);
    setCurrentPage(value);
    getDevices(value);
  };
  const handleChange = (e, data) => {
    console.log("select device handle chanbge ==> ", e.target.value);
    setOriginalData(data._id);
    // searchBillBoardName();
    // handleDeviceId(data._id)
  };
  const searchBillBoardName = async () => {
    try {
      let resp = await axios.get(
        `/api/billboard/checkbillboardName?searchQuery=${billboardname}`
      );
      console.log("RESPONSE  searchBillBoardName ===> ", resp.data);
    } catch (error) {
      console.log("ERROR from GET DEVICE => ", error.message);
    }
  };
  const getDevices = async (value) => {
    try {
      let resp = await axios.get(
        `/api/billboard/getbillboardNodeviceId?page=${
          value || currentPage
        }&limit=10`
      );
      console.log(
        "RESPONSE  getbillboardNodeviceId ===> ",
        resp.data.msg.result
      );
      setTotalPage(resp.data.msg.totalPage);
      setDeviceData(resp.data.msg.result);
    } catch (error) {
      console.log("ERROR from GET DEVICE => ", error.message);
    }
  };

  const AssignDevicetoBillboard = async () => {
    let body = {
      billboardId: originalData,
      deviceId: value ? AssignDeviceID : devices?._id,
    };
    console.log("Check Assign Device Top Billboard body", body);
    try {
      let res = await axios.post("/api/device/assignBillboardToDevice", body);
      if (res.status === 200) {
        setSnackOpen(true);
        setSnackMsg(res.data.msg);
        setOpen(false);
        getAllDevice();
        handleClose();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.data.msg.err);
        setOpen(false);
        handleClose();
        console.log(" Device Assing to Billboard", res.data.msg);
      }
    } catch (err) {
      console.log("Error from Remove Device Function", err);
    }
  };
  const AssignDevice = async () => {
    let body = {
      billboardId: originalData,
      deviceId: value ? AssignDeviceID : devices?._id,
    };
    console.log("Check Assign Device Top Billboard body", body);
    try {
      let res = await axios.post("/api/device/assignBillboardToDevice", body);
      if (res.status === 200) {
        setSnackOpen(true);
        setSnackMsg(res.data.msg);
        setOpen(false);
        getDeviceInfo();
        handleClose();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.data.msg.err);
        setOpen(false);
        handleClose();
        console.log(" Device Assing to Billboard", res.data.msg);
      }
    } catch (err) {
      console.log("Error from Remove Device Function", err);
    }
  };

  // serach billboard

  const getSearchBillboard = async (value) => {
    try {
      let resp = await axios.get(
        `/api/billboard/searchbillboardNodeviceId?page=1&limit=10&searchQuery=${value}`
      );
      setDeviceData(resp.data.msg.result);
      setTotalPage(resp.data.msg.totalPage);

      console.log("search device data => ", resp.data.msg.result);
    } catch (error) {
      console.log("ERROR in search device data => ", error.message);
    }
  };

  React.useEffect(() => {
    console.log("original Data ==> ", originalData);
    getDevices();
    searchBillBoardName();
  }, []);
  // React.useEffect(() => {
  //   getAllDevice(value ? true : false);
  // }, [value]);
  //=============================================================================//

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      {value ? (
        <Typography className="greencolortypo" onClick={handleClickOpen}>
          Assign Billboard
        </Typography>
      ) : (
        <CgScreen onClick={handleClickOpen} />
      )}
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className="whitecolortypo500 fs16px">
            Select Billboard
          </Typography>{" "}
        </BootstrapDialogTitle>
        {deviceData ? (
          <DialogContent sx={{ m: 0 }}>
            <div className="admin-content">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Search className="search-admin">
                  <SearchIconWrapper>
                    <SearchIcon className="searchicon" />
                  </SearchIconWrapper>
                  <StyledInputBase
                    className=""
                    placeholder="Search Device"
                    inputProps={{ "aria-label": "search" }}
                    value={billboardname}
                    onChange={(e) => {
                      getSearchBillboard(e.target.value);
                      setBillboardName(e.target.value);
                    }}
                  />
                </Search>{" "}
              </Grid>
              <Grid container>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  className="width100"
                >
                  {deviceData?.map((data) => {
                    return (
                      <Grid
                        item
                        sm={5.5}
                        md={2.7}
                        sx={{ border: "1px solid #dddddd", marginLeft: "20px" }}
                        className="access-radio-grid "
                      >
                        <Grid container justifyContent="space-between">
                          <FormLabel>
                            <ListItemButton>
                              <Typography className=" greycolor505050400">
                                {data?.billboardName}
                                <Typography className="lightgreycolortypo">
                                  {data?.billboardId}{" "}
                                </Typography>
                              </Typography>
                            </ListItemButton>
                          </FormLabel>
                          <FormControlLabel
                            value={data?.billboardId}
                            className="radiostyle access-radio-formcontrolabel"
                            control={<Radio />}
                            style={{ justifyContent: "space-between" }}
                            onChange={(e) => handleChange(e, data)}
                            key={data?._id}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
            </div>

            <Grid container justifyContent="center" alignItems="center">
              <DialogActions className="dialogaction-p0px mt20px">
                <Stack spacing={2}>
                  <Pagination
                    page={currentPage}
                    count={totalPage}
                    onChange={handleChangeByPage}
                    size="large"
                  />
                </Stack>
              </DialogActions>
            </Grid>
            <DialogActions>
              <Button className="dcancelbutton" onClick={handleClose}>
                Cancel
              </Button>
              <ApproveDialog
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
                AssignDevicetoBillboard={AssignDevicetoBillboard}
                AssignDevice={AssignDevice}
                value={value}
              />
            </DialogActions>
          </DialogContent>
        ) : (
          <DialogContent sx={{ m: 0 }}>
            <Grid container sx={{ height: "70vh" }}>
              <Typography
                align="center"
                alignItems="center"
                alignSelf="center"
                className="width100 blackcolortypo fs25px"
              >
                No billboard found! <br />
                All billboard have device assigned to them.
              </Typography>
            </Grid>
          </DialogContent>
        )}
      </BootstrapDialog>
    </div>
  );
}
