import { Typography, Grid } from "@mui/material";
import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import axiosInstance from "../../../../../../../../api/axiosInstance";
// pie chart
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import { useEffect } from "react";
import Calendar from "../../../../../../../../component/ReactDateRangePicker/ReactDateRangePicker";

// pie chart
const options = {
  title: {
    display: true,
    text: "Donut Chart",
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  //   cutout: "70%",
};
const PieAnalytics = (props) => {
  const [billboardActiveHour, setbillboardActiveHour] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTime, setactiveTime] = useState(0);
  const [Inactivetime, setInactivetime] = useState(null);
  const [date, setDate] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [AllActiveData, setAllActiveData] = useState(0);
  const [acceptedBarAnalyticsData, setAcceptedBarAnalyticsData] = useState([]);

  const [value, setValue] = React.useState(null);

  const billBoardID = props.billBoardID;

  console.log("propsLatest", billBoardID);

  const timeInHour = [
    "12:00 Am",
    "1:00 Am",
    "2:00 Am",
    "3:00 Am",
    "4:00 Am",
    "5:00 Am",
    "6:00 Am",
    "7:00 Am",
    "8:00 Am",
    "9:00 Am",
    "10:00 Am",
    "11:00 Am",
    "12:00 Pm",
    "1:00 Pm",
    "2:00 Pm",
    "3:00 Pm",
    "4:00 Pm",
    "5:00 Pm",
    "6:00 Pm",
    "7:00 Pm",
    "8:00 Pm",
    "9:00 Pm",
    "10:00 Pm",
    "11:00 Pm",
  ];

  const handleData = (data, datatype) => {
    setDate(moment(data).format("YYYY-MM-DD"));
  };

  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);

    console.log("");
  };

  //////////////////////// Api To get billboard active time  ////////////////////

  const getBillboardActiveHours = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getBillboardActiveTime?billboardId=${billBoardID}&startDate=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}&dateWise=true`
      );
      setAcceptedBarAnalyticsData(res?.data.data);

      console.log("dataOccupency", res);
      if (res.status == 200 || res.status == 204) {
        setbillboardActiveHour(res?.data.data);

        setactiveTime(Math.floor(res?.data.data?.[0].videoDuration / 60));
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };

  console.log("Api data", activeTime);
  useEffect(() => {
    if (!date) {
      return;
    }
    getBillboardActiveHours();
  }, [date]);

  // useEffect(() => {
  //   const firstData = setbillboardActiveHour?.map((a) => a.videoDuration);
  //   const firstDataNew = firstData?.reduce((accum, elem) => {
  //     return (accum = accum + elem);
  //   }, 0);

  //   setAllActiveData(firstDataNew);
  // }, [getBillboardActiveHours]);
  console.log("totalActive", AllActiveData);

  const data = {
    labels: ["Active Hours", "InActive Hours"],
    datasets: [
      {
        data: [
          Math.floor(AllActiveData / 60),
          1440 - `${Math.floor(AllActiveData / 60)}`,
        ],
        backgroundColor: ["#fed0eeb3", "#D0E8FF"],
        hoverBackgroundColor: ["#fed0eeb3", "#D0E8FF"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const firstData = acceptedBarAnalyticsData?.map((a) => a.videoDuration);
    const firstDataNew = firstData?.reduce((accum, elem) => {
      return (accum = accum + elem);
    }, 0);

    setAllActiveData(firstDataNew);
  }, [acceptedBarAnalyticsData]);
  return (
    <>
      <Grid container justifyContent="space-between">
        <Typography className="gray5A5A5Atypo500 fs20px">Occupancy</Typography>
        <div>
          <Calendar getDataFromChildHandler={getDataFromChildHandler} />
        </div>
      </Grid>
      <Grid container sx={{ width: "300px", marginTop: "50px" }}>
        <Doughnut data={data} options={options} />
      </Grid>
      <Grid container gap="20px">
        <Typography className="gray5A5A5Atypo500 fs20px">
          Availability
        </Typography>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Grid container>
              {" "}
              <span
                className="pink_block"
                style={{ backgroundColor: "#fed0eeb3" }}
              ></span>
              <Typography className="greyColorTypo717171_400">
                Active Hours {Math.floor(AllActiveData / 60)}(min)
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              {" "}
              <span className="blue_block"></span>
              <Typography className="greyColorTypo717171_400">
                InActive Hours {} {1440 - `${Math.floor(AllActiveData / 60)}`}
                (min)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PieAnalytics;
