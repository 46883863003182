import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import { FETCH_URL } from "../../../../fetchIp";
// ALL Imgae Import
import Overview from "./BillboardScreenTab's/OverviewTab";
import ControlPanel from "./BillboardScreenTab's/ControlPanel";
import Orders from "./BillboardScreenTab's/OrdersTab";
import Analytics from "./BillboardScreenTab's/BillboardDetailAnalyticsTab/AnalyticsTab";
import Price from "./BillboardScreenTab's/PriceTab";
import Assigned from "./BillboardScreenTab's/AssignedTab";
import CampaignTab from "./BillboardScreenTab's/CampaignTab";

import usePaginatedValue from "../../../../utils/usePaginatedValue";
import BurnerAd from "./BillboardScreenTab's/BurnerAd/BurnerAd";
// ** Core Component
import TabList from "../../../../component/CustomTabs/TabList";
import TabPanelList from "./../../../../component/CustomTabs/TabPanel";
import CustomBreadcrumbs from "../../../../component/CustomBreadcrumbs";
import permissionUtils from "../../../../component/permissionUtils";

import { AuthContext } from "../../../../Context/AuthContext";
import Screenshot from "./BillboardScreenTab's/ScreenShot/Screenshot";
import Snapshot from "./BillboardScreenTab's/Snapshot/Snapshot";
import BillboardLogs from "./BillboardScreenTab's/BillboardLogs";

//=================================== MAIN COMPONENT =========================================//
const BillboardItem = () => {
  // custom hook
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const { user } = React.useContext(AuthContext);
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const { paginatedPage, paginateTabValue, getPaginatedValueHandler } =
    usePaginatedValue(navigate);
  //

  const [value, setValue] = useState(paginateTabValue ? paginateTabValue : 0);
  const [accessPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))?.permission || []
  );

  useEffect(() => {
    if (paginateTabValue === undefined) {
      setValue(0);
      return;
    }
    setValue(paginateTabValue);
  }, [paginateTabValue]);

  const handleChange = (event, newValue) => {
    getPaginatedValueHandler("", 1, newValue);
    setValue(newValue);
  };
  const [channels, setChannels] = useState([]);
  const [billBoardData, setBillBoardData] = useState("");
  const getBillBoardData = async () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    const response = await fetch(
      `${FETCH_URL}/api/billboard/getbillboardbyid/${params?.billboard_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let res = await response.json();
    if (response.ok) {
      setBillBoardData(res?.msg);
      setChannels([res?.msg?.deviceId?.macId]);
    } else {
      console.log("Error in ALL Screens ==> ", res);
    }
  };

  useEffect(() => {
    getBillBoardData();
  }, [params]);
  const breadcrumbs = [
    { label: "Dashboard", link: "/" },
    { label: "All Smart BillBoard", link: "/admin/billboardscreens" },
    { label: billBoardData?.billboardName },
  ];

  const permissionList = [
    "view-billboard-overview",
    "view-billboard-control-panel",
    "view-billboard-order",
    "view-billboard-campaign",
    "view-billboard-analytics",
    "view-billboard-price",
    "view-billboard-assigned",
    "view-burner-ad",
    "view-billboard-screenshot",
    "view-billboard-snapshot",
    "view-billboard-logs",
  ];

  const getTabLabel = (permission) => {
    const labelMap = {
      "view-billboard-overview": "Overview",
      "view-billboard-control-panel": "Control Panel",
      "view-billboard-order": "Orders",
      "view-billboard-campaign": "Campaign",
      "view-billboard-analytics": "Analytics",
      "view-billboard-price": "Price",
      "view-billboard-assigned": "Assigned",
      "view-burner-ad": "BurnerAd",
      "view-billboard-screenshot": "ScreenShot",
      "view-billboard-snapshot": "SnapShot",
      "view-billboard-logs": "Logs",
    };
    return labelMap[permission];
  };
  const permissions = CheckTabsPermissions(permissionList);

  const panels = [
    {
      permission: "view-billboard-overview",
      component: (
        <Overview billBoardData={billBoardData} id={billBoardData?._id} />
      ),
    },
    {
      permission: "view-billboard-control-panel",
      component: (
        <ControlPanel
          id={billBoardData?._id}
          billBoardData={billBoardData}
          getBillBoardData={getBillBoardData}
        />
      ),
    },
    {
      permission: "view-billboard-order",
      component: (
        <Orders
          deviceIddata={billBoardData?.deviceId}
          deviceMacId={billBoardData?.deviceId?.macId}
          id={billBoardData?._id}
          paginateState={{
            paginatedPage,
            paginateTabValue,
            getPaginatedValueHandler,
          }}
        />
      ),
    },
    {
      permission: "view-billboard-campaign",
      component: (
        <CampaignTab
          deviceIddata={billBoardData?.deviceId}
          deviceMacId={billBoardData?.deviceId?.macId}
          id={billBoardData?._id}
          paginateState={{
            paginatedPage,
            paginateTabValue,
            getPaginatedValueHandler,
          }}
        />
      ),
    },
    {
      permission: "view-billboard-analytics",
      component: (
        <Analytics
          id={billBoardData?._id}
          billBoardData={billBoardData}
          getBillBoardData={getBillBoardData}
        />
      ),
    },
    {
      permission: "view-billboard-price",
      component: (
        <Price
          billBoardData={billBoardData}
          id={billBoardData?._id}
          deviceId={billBoardData?.deviceId?._id}
        />
      ),
    },
    {
      permission: "view-billboard-assigned",
      component: (
        <Assigned
          id={billBoardData?._id}
          billBoardData={billBoardData}
          getBillBoardData={getBillBoardData}
        />
      ),
    },
    {
      permission: "view-burner-ad",
      component: (
        <BurnerAd
          id={billBoardData?._id}
          billBoardData={billBoardData}
          getBillBoardData={getBillBoardData}
        />
      ),
    },
    {
      permission: "view-billboard-screenshot",
      component: (
        <Screenshot
          id={billBoardData?._id}
          billBoardData={billBoardData}
          getBillBoardData={getBillBoardData}
        />
      ),
    },
    {
      permission: "view-billboard-snapshot",
      component: (
        <Snapshot
          id={billBoardData?._id}
          billBoardData={billBoardData}
          getBillBoardData={getBillBoardData}
        />
      ),
    },
    {
      permission: "view-billboard-logs",
      component: (
        <BillboardLogs
          id={billBoardData?._id}
          macId={billBoardData?.deviceId?.macId}
        />
      ),
    },
  ];
  return (
    <div className="width-90LR">
      <div className="flex-class">
        <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
      </div>
      <Grid container className="device-container mt20px">
        <Grid item xs={12}>
          <TabList
            value={value}
            variant={"scrollable"}
            classes={{ Tabs: "TabStyleAddDevice", Tab: "TabChangesDevice" }}
            handleChange={handleChange}
            getTabLabel={getTabLabel}
            permissions={permissions}
          />
        </Grid>
      </Grid>
      <TabPanelList value={value} permissions={permissions} panels={panels} />{" "}
    </div>
  );
};

export default BillboardItem;
