import { Grid, Typography, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import BillboardtableAnalstics from "./Order_Analytics/BillboardtableAnalystics";
import axiosInstance from "../../../../../api/axiosInstance";
import { useTheme } from "@mui/material/styles";
// pie chart
import { Doughnut, Pie } from "react-chartjs-2";
import moment from "moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import IconButton from "@mui/material/IconButton";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import nodata from "../../../../../assets/Img/no_data_found_img.png";

const headCells = [
  {
    id: "Label",
    numeric: true,
    disablePadding: false,
    label: "Label",
  },
  {
    id: "Count",
    numeric: true,
    disablePadding: false,
    label: "Count",
  },
  {
    id: "Accuracy",
    numeric: true,
    disablePadding: false,
    label: "Accuracy",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
// pie chart
const options = {
  title: {
    display: true,
    text: "Donut Chart",
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  cutout: "0%",
};
const optionsSecond = {
  title: {
    display: true,
    text: "Donut Chart",
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  // cutout: "0%",
};

const SlotsAnalytics = (props) => {
  const [value, setValue] = React.useState(null);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [maleView, setMaleView] = useState(null);
  const [Allviews, setAllviews] = useState(null);
  const [femaleView, setfemaleView] = useState(null);
  const [apiDataAnalystics, setapiDataAnalystics] = useState(null);
  const [ageGroup, setAgeGroup] = useState([]);
  const [dataRange, setDataRange] = useState([]);
  const [dense, setDense] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [totalVehicles, setTotalVehicles] = useState(0);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [acceptedBarAnalyticsData, setAcceptedBarAnalyticsData] = useState([]);
  const [vehicleData, setvehicleData] = useState([]);
  const handleData = (data, datatype) => {
    setDate(moment(data).format("YYYY-MM-DD"));
  };

  // Dummy data for the table
  const [tableData, setTableData] = useState([
    { label: "Class A", count: 50, accuracy: 85 },
    { label: "Class B", count: 30, accuracy: 92 },
    { label: "Class C", count: 20, accuracy: 78 },
    { label: "Class D", count: 40, accuracy: 65 },
    { label: "Class E", count: 25, accuracy: 88 },
  ]);

  const data = {
    labels: dataRange,
    datasets: [
      {
        data: ageGroup,

        backgroundColor: [
          "rgba(144, 70, 210, 1)",
          "rgba(180, 131, 225, 1)",
          "rgba(217, 192, 240, 1)",
        ],
        borderColor: [
          "rgba(144, 70, 210, 1)",
          "rgba(180, 131, 225, 1)",
          "rgba(217, 192, 240, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const getBillboardActiveHours = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/order/getViewsAgeGroupByOrderId/${props?.data?._id}`
      );
      setAcceptedBarAnalyticsData(res?.data?.data);

      console.log("dataOccupency", res);
      if (res.status == 200 || res.status == 204) {
        setapiDataAnalystics(res?.data?.data);
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };
  // const getVehicleData = async () => {
  //   try {
  //     const res = await axiosInstance.get(
  //       `/api/order/getOrderLabelAnalytics/${props?.data?._id}`
  //     );
  //     setvehicleData(res?.data);

  //     console.log("setvehicleData", res?.data?.table);
  //     if (res.status == 200 || res.status == 204) {
  //       setvehicleData(res?.data);
  //     }
  //   } catch (err) {
  //     console.log("error from getOrder anlytics data => ", err);
  //   }
  // };
  const getVehicleData = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/order/getOrderLabelAnalytics/${props?.data?._id}`
      );
      setvehicleData(res?.data);
      const total = Object.values(res?.data?.graph?.vehicleDistribution).reduce(
        (acc, count) => acc + count,
        0
      );
      console.log(total, "total");
      setTotalVehicles(total);

      console.log("setvehicleData", res?.data?.table);
      if (res.status === 200 || res.status === 204) {
        // Sort the table array in descending order based on a.percentage/a.count
        const sortedTable = res?.data?.table?.sort((a, b) => {
          const ratioA = a.percentage / a.count;
          const ratioB = b.percentage / b.count;
          return ratioB - ratioA;
        });

        setvehicleData({ ...res.data, table: sortedTable });
      }
    } catch (err) {
      console.log("error from getOrder analytics data => ", err);
    }
  };
  console.log(totalVehicles, "total");
  useEffect(() => {
    getBillboardActiveHours();
    getVehicleData();
  }, [props]);
  console.log("ageddddd", dataRange);
  const dataAudience = {
    labels: ["count"],
    datasets: [
      {
        data: [vehicleData?.graph ? vehicleData?.graph?.audienceCount : 0],
        backgroundColor: ["#A855F7"],
        hoverBackgroundColor: ["#A855F7"],
        borderWidth: 1,
      },
    ],
  };
  const dataSecond = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: [
          vehicleData?.graph?.genderDistribution?.male,
          vehicleData?.graph?.genderDistribution?.female,
        ],
        backgroundColor: ["#91D5EF", "#EF669E"],
        hoverBackgroundColor: ["#91D5EF", "#EF669E"],

        borderWidth: 1,
      },
    ],
  };
  const dataVehicle = {
    labels: ["Car", "Truck", "Bike", "Bicycle", "E-scooter", "Bus"],
    datasets: [
      {
        data: [
          vehicleData?.graph?.vehicleDistribution?.Car
            ? vehicleData?.graph?.vehicleDistribution?.Car
            : 0,
          vehicleData?.graph?.vehicleDistribution?.Truck
            ? vehicleData?.graph?.vehicleDistribution?.Truck
            : 0,
          vehicleData?.graph?.vehicleDistribution?.Bike
            ? vehicleData?.graph?.vehicleDistribution?.Bike
            : 0,
          vehicleData?.graph?.vehicleDistribution?.Bicycle
            ? vehicleData?.graph?.vehicleDistribution?.Bicycle
            : 0,
          vehicleData?.graph?.vehicleDistribution?.scooter
            ? vehicleData?.graph?.vehicleDistribution?.scooter
            : 0,
          vehicleData?.graph?.vehicleDistribution?.Bus
            ? vehicleData?.graph?.vehicleDistribution?.Bus
            : 0,
        ],
        backgroundColor: [
          "#a855f7",
          "#d946ef",
          "#6366f1",
          "#9496d8",
          "#ff8ceb",
          "#85d8f1",
        ],
        hoverBackgroundColor: [
          "#a855f7",
          "#d946ef",
          "#6366f1",
          "#9496d8",
          "#ff8ceb",
          "#85d8f1",
        ],
        borderWidth: 1,
      },
    ],
  };
  const dataDemo2 = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: [34, 12],
        backgroundColor: ["#91D5EF", "#EF669E"],
        hoverBackgroundColor: ["#91D5EF", "#EF669E"],
        borderWidth: 1,
      },
    ],
  };
  const dataDemo = {
    labels: dataRange,
    datasets: [
      {
        data: [23, 4, 13],
        backgroundColor: ["#9046D2", "#B483E1", "#D9C0F0"],
        hoverBackgroundColor: ["#9046D2", "#B483E1", "#D9C0F0"],
        borderWidth: 0,
      },
    ],
  };
  console.log("ageGroup data>>>>>>>>>>>", maleView);
  useEffect(() => {
    const firstData = acceptedBarAnalyticsData?.map((a) => a.totalViews);
    const firstDataNew = firstData?.reduce((accum, elem) => {
      return (accum = accum + elem);
    }, 0);
    setAllviews(firstDataNew);
    const firstDataMale = acceptedBarAnalyticsData?.map((a) => a.maleViews);

    const firstDataNewMale = firstDataMale?.reduce((accum, elem) => {
      return (accum = accum + elem);
    }, 0);
    setMaleView(firstDataNewMale);
    const firstDatafemale = acceptedBarAnalyticsData?.map((a) => a.femaleViews);
    const firstDataNewfemale = firstDatafemale?.reduce((accum, elem) => {
      return (accum = accum + elem);
    }, 0);
    setfemaleView(firstDataNewfemale);
  }, [acceptedBarAnalyticsData]);

  const ageGroupData = (acceptedBarAnalyticsData) => {
    console.log(acceptedBarAnalyticsData);
    let data1 = [];
    let data2 = [];
    acceptedBarAnalyticsData?.map((item, i) => {
      data1.push(item?.totalViews);
      data2.push(item?.ageGroup);
    });
    // console.log(data1, data2);
    setAgeGroup(data1);
    setDataRange(data2);
  };
  console.log("datrange", ageGroup);
  useEffect(() => {
    if (acceptedBarAnalyticsData.length > 0) {
      ageGroupData(acceptedBarAnalyticsData);
    }
    // console.log(ageGroup);
  }, [acceptedBarAnalyticsData]);

  return (
    <>
      {props?.data?.status === "Published" ||
      props?.data?.status === "Cancelled" ||
      props?.data?.status === "Live" ? (
        <>
          <Grid container justifyContent="space-between" rowGap="20px">
            <Grid container xs={12} md={3.9} className="relative card_div_new">
              <Grid container justifyContent="center">
                <Grid container justifyContent="center">
                  <Typography className="gray5A5A5Atypo500 fs20px">
                    Audience Count
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    width: "320px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "12px",
                  }}
                >
                  {vehicleData?.graph?.audienceCount > 0 ? (
                    <Doughnut data={dataAudience} options={optionsSecond} />
                  ) : (
                    <div className="fakeDataChart"></div>
                  )}
                </Grid>
                <Grid className="text_inside_donut" item>
                  <Grid container alignItems="center" direction="column">
                    {" "}
                    <Typography className="greycolor505050500 fs24px">
                      Total people
                    </Typography>
                    <Typography className="blackcolortypo fs24px">
                      {vehicleData?.graph?.audienceCount
                        ? vehicleData?.graph?.audienceCount
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container marginTop={"10px"}>
                  <Grid container item justifyContent="center" gap={"80px"}>
                    <Grid item>
                      <Grid container>
                        {" "}
                        <span className="count_blockAnalytics"></span>
                        <Typography className="greyColorTypo717171_400">
                          People : {vehicleData?.graph?.audienceCount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className="relative card_div_new" xs={12} md={3.9}>
              <Grid container justifyContent="center">
                <Grid container justifyContent="center">
                  <Typography className="gray5A5A5Atypo500 fs20px">
                    Gender Distribution
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    width: "320px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "12px",
                  }}
                >
                  {vehicleData?.graph?.genderDistribution &&
                  vehicleData?.graph?.genderDistribution?.female +
                    vehicleData?.graph?.genderDistribution?.male >
                    0 ? (
                    <Doughnut data={dataSecond} options={optionsSecond} />
                  ) : (
                    <div className="fakeDataChart"></div>
                  )}
                </Grid>
                <Grid className="text_inside_donut" item>
                  <Grid container alignItems="center" direction="column">
                    {" "}
                  </Grid>
                </Grid>
                <Grid container marginTop={"10px"}>
                  <Grid container item justifyContent="center" gap={"80px"}>
                    <Grid item>
                      <Grid container>
                        {" "}
                        <span className="pink_blockAnalytics"></span>
                        <Typography className="greyColorTypo717171_400">
                          Male : {vehicleData?.graph?.genderDistribution?.male}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        {" "}
                        <span className="blue_blockAnalytics"></span>
                        <Typography className="greyColorTypo717171_400">
                          Female :{" "}
                          {vehicleData?.graph?.genderDistribution?.female
                            ? vehicleData?.graph?.genderDistribution?.female
                            : 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="relative card_div_new" xs={12} md={3.9}>
              <Grid container justifyContent="center">
                <Grid container justifyContent="center">
                  <Typography className="gray5A5A5Atypo500 fs20px">
                    Vehicle Distribution
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    width: "320px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "12px",
                  }}
                >
                  {vehicleData?.graph?.vehicleDistribution &&
                  totalVehicles > 0 ? (
                    <Doughnut data={dataVehicle} options={optionsSecond} />
                  ) : (
                    <Grid className="fakeDataChart"></Grid>
                  )}
                </Grid>
                <Grid className="text_inside_donut" item>
                  <Grid
                    container
                    alignItems="center"
                    sx={{ marginTop: "-25px" }}
                    justifyContent={"center"}
                    direction="column"
                  >
                    {" "}
                    <Typography className="greycolor505050500 fs24px">
                      Total
                    </Typography>
                    <Typography className="blackcolortypo fs24px">
                      {totalVehicles ? totalVehicles : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container marginTop={"10px"}>
                  <Grid container item justifyContent="center" gap={2}>
                    <Grid item>
                      <Grid container>
                        {" "}
                        <span className="Car_blockAnalytics"></span>
                        <Typography className="greyColorTypo717171_400">
                          Car :{" "}
                          {vehicleData?.graph?.vehicleDistribution?.Car
                            ? vehicleData?.graph?.vehicleDistribution?.Car
                            : 0}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        {" "}
                        <span className="Truck_blockAnalytics"></span>
                        <Typography className="greyColorTypo717171_400">
                          Truck :{" "}
                          {vehicleData?.graph?.vehicleDistribution?.Truck
                            ? vehicleData?.graph?.vehicleDistribution?.Truck
                            : 0}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container>
                        {" "}
                        <span className="Bike_blockAnalytics"></span>
                        <Typography className="greyColorTypo717171_400">
                          Bike :{" "}
                          {vehicleData?.graph?.vehicleDistribution?.Bike
                            ? vehicleData?.graph?.vehicleDistribution?.Bike
                            : 0}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container>
                        {" "}
                        <span className="Bicycle_blockAnalytics"></span>
                        <Typography className="greyColorTypo717171_400">
                          Bicycle :{" "}
                          {vehicleData?.graph?.vehicleDistribution?.Bicycle
                            ? vehicleData?.graph?.vehicleDistribution?.Bicycle
                            : 0}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container>
                        {" "}
                        <span className="scooter_blockAnalytics"></span>
                        <Typography className="greyColorTypo717171_400">
                          E-scooter :{" "}
                          {vehicleData?.graph?.vehicleDistribution?.scooter
                            ? vehicleData?.graph?.vehicleDistribution?.scooter
                            : 0}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container>
                        {" "}
                        <span className="Bus_blockAnalytics"></span>
                        <Typography className="greyColorTypo717171_400">
                          Bus :{" "}
                          {vehicleData?.graph?.vehicleDistribution?.Bus
                            ? vehicleData?.graph?.vehicleDistribution?.Bus
                            : 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ marginTop: "40px", backgroundColor: "#F7F8FD" }}
          >
            <Accordion
              sx={{ border: "1px solid #DDDDDDDD " }}
              className="card_div_newAccordion"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="gray5A5A5Atypo500 fs20px">
                  Audience distribution
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EnhancedTableToolbar />
                <TableContainer className="width100 hide_sortBar table_border_radius">
                  <Table
                    sx={{
                      minWidth: 800,
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      className="tablerow"
                      order={order}
                      orderBy={orderBy}
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                    />

                    <TableBody
                      className="tablerow "
                      style={{ alignItems: "center" }}
                    >
                      {vehicleData?.table && vehicleData?.table?.length > 0 ? (
                        vehicleData?.table?.map((a, i) => (
                          <TableRow
                            key={i}
                            hover
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              align="Left"
                              component="th"
                              scope="row"
                              padding="none"
                              sx={{ width: "auto" }}
                            >
                              <Typography className="greycolor1c1c1c fs16px ">
                                {a?.name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="Left"
                              component="th"
                              scope="row"
                              padding="none"
                              sx={{ width: "auto" }}
                            >
                              <Typography className="greycolor1c1c1c fs16px ">
                                {a?.count}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="Left"
                              component="th"
                              scope="row"
                              padding="none"
                              sx={{
                                width: "auto",
                              }}
                            >
                              <Grid
                                display={"flex"}
                                gap={1}
                                sx={{
                                  alignItems: "center",
                                }}
                              >
                                <Grid display={"flex"}>
                                  <Typography className="greycolor1c1c1c fs16px ">
                                    {a?.percentage
                                      ? (a?.percentage / a?.count)?.toFixed(2)
                                      : 0}
                                    %
                                  </Typography>
                                </Grid>

                                <progress
                                  className="progressHomeAcc "
                                  max={100}
                                  value={(a?.percentage / a?.count)?.toFixed(2)}
                                ></progress>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <>
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className="no-data-container">
                                <img
                                  src={nodata}
                                  alt="no data found"
                                  className="noBillboardFoundGif_width"
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <div style={{ marginTop: "20px", marginBottom: "40px" }}>
            <BillboardtableAnalstics data={props?.data} />
          </div>
        </>
      ) : (
        <>
          <div style={{ paddingBottom: "10px" }} className=" relative">
            <Grid
              container
              justifyContent="space-between"
              rowGap="24px"
              className={
                props?.data?.status !== "Published" ? "section_blur" : ""
              }
              sx={{
                paddingBottom: "10px",
                marginTop: "20px",
              }}
            >
              <Grid
                container
                item
                xs={12}
                md={5.8}
                className="border_primary"
                gap="20px"
                sx={{ padding: "20px" }}
                justifyContent="center"
              >
                <Grid
                  container
                  item
                  direction="column"
                  gap="5px"
                  alignItems="center"
                >
                  <Typography className="blackcolortypo fs24px">
                    Audience Count
                  </Typography>
                  {/* <Typography className="greycolor505050500 fs20px">
                    Total number of people who viewed this Ad.
                  </Typography> */}
                </Grid>
                <Grid container item justifyContent="center">
                  <Doughnut data={dataDemo} options={options} />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5.8}
                className="border_primary"
                gap="20px"
                sx={{ padding: "20px" }}
                justifyContent="center"
              >
                <Grid
                  container
                  item
                  direction="column"
                  gap="5px"
                  alignItems="center"
                >
                  <Typography className="blackcolortypo fs24px">
                    Gender Distribution
                  </Typography>
                </Grid>
                <Grid container item justifyContent="center">
                  <Doughnut data={dataDemo2} options={optionsSecond} />
                </Grid>
              </Grid>{" "}
            </Grid>
            {/* <Grid container item className="border_primary">
                <Donut_chart_three viewData={props?.data} />
              </Grid>
            </Grid> Published*/}
            {props?.data?.status !== "Published" ||
              (props?.data?.status !== "Cancelled" && (
                <Grid
                  container
                  className="analytics_blur_div"
                  justifyContent="center"
                >
                  <Grid
                    container
                    item
                    sx={{
                      borderRadius: "15px",
                      background: "#FFFFFF",
                      border: "1px solid #ddd",
                      height: "150px",
                      padding: "30px 0",
                      width: "450px",
                    }}
                    justifyContent="center"
                    alignItems="space-between"
                  >
                    <Typography
                      sx={{ textAlign: "center" }}
                      className="purplecolortypo-500 fs20px"
                    >
                      No analytics yet!
                    </Typography>
                    <Typography
                      sx={{ textAlign: "center" }}
                      className="purplecolortypo-500 fs20px"
                    >
                      Analytics will be available after ad is published.
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default SlotsAnalytics;
