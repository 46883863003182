import React from "react";
import { Grid, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
const Item = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
}));
const CouponSkeleton = () => {
  return (
    <>
      {[1, 2, 3, 4, 5]?.map((a, i) => (
        <Grid item xs={12} sm={6} md={4} lg={2.4} key={i}>
          <Item>
            <Grid
              container
              style={{
                border: "1px solid #ddd",
                borderBottom: "none",
                borderRadius: "8px 8px 0 0",
              }}
            >
              <Grid xs={12}>
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "120px", borderRadius: "8px 8px 0 0" }}
                />
              </Grid>
              <Grid xs={12}>
                <Grid container justifyContent="center">
                  {" "}
                  <Skeleton sx={{ width: "20%" }} />
                </Grid>
              </Grid>
              <Grid xs={12}>
                <Grid container justifyContent="center">
                  <Skeleton sx={{ width: "30%" }} />
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid container justifyContent="center">
                  <Skeleton sx={{ width: "30%" }} />
                </Grid>
                <Grid container justifyContent="center">
                  <Skeleton sx={{ width: "60%" }} />
                </Grid>
              </Grid>
              <Grid xs={12} style={{ margin: "8px 0" }}>
                <Grid container justifyContent="center">
                  <Skeleton sx={{ width: "40%" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              style={{
                borderRadius: "0 0 8px 8px",
                height: "38px",
              }}
            >
              <Grid xs={12}>
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "38px", borderRadius: "0 0 8px 8px" }}
                />
              </Grid>
            </Grid>
          </Item>
        </Grid>
      ))}
    </>
  );
};

export default CouponSkeleton;
