import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  DialogContent,
  Breadcrumbs,
  Button,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import ActiveCoupon from "./CouponTableTab/ActiveCoupon";
import ExpiredCoupon from "./CouponTableTab/ExpiredCoupon";
import { FETCH_URL } from "../../../../../fetchIp";
import permissionUtils from "../../../../../component/permissionUtils";

import { useNavigate } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Coupon = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  const [value, setValue] = React.useState(0);
  const [query, setQuery] = useState("active");
  const navigate = useNavigate();
  //edit state
  //state
  // couponDataState
  const [allCoupon, setAllCoupon] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 0) {
      setQuery("active");
    } else if (newValue == 1) {
      setQuery("expired");
    }
  };

  const [loading, setLoading] = useState(false);
  // getAllCouponApi
  const getAllCoupon = async () => {
    setLoading(true);
    setAllCoupon([]);
    try {
      const response = await fetch(`${FETCH_URL}/coupon/getAllCoupon`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          type: query,
          recent: true,
        }),
      });
      let res = await response.json();
      if (response.ok) {
        console.log("get coupon response  ===>", res.msg);

        setAllCoupon(res.msg);
        setLoading(false);
      } else {
        console.log("Error in getting coupon ==> ", res.msg);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error in gettin coupon  => ", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllCoupon();
  }, [query]);
  return (
    <div>
      <Grid container>
        <Grid
          container
          sx={{
            marginBottom: "24px",
          }}
          alignItems="center"
          justifyContent="space-between"
          rowGap="10px"
        >
          <Grid item>
            {" "}
            <Typography className="blackcolortypo500 fs24px">
              Showing {allCoupon?.length} Coupons
            </Typography>
          </Grid>

          <Grid item>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="secondary_Tab border_primary "
              style={{ borderRadius: "999px" }}
              indicatorColor="none"
            >
              <Tab
                label="Active"
                {...a11yProps(0)}
                style={{ width: "150px" }}
                className="text_transform_capitalize Order_TabChangeDevice"
              />
              <Tab
                label="Expired"
                {...a11yProps(1)}
                style={{ width: "150px" }}
                className="text_transform_capitalize Order_TabChangeDevice"
              />
            </Tabs>
          </Grid>
          <Grid item>
            {PermissionCheck("create-coupons") && (
              <Button
                className="purple_btn_primary"
                onClick={() => navigate(`/admin/CouponVocher/AddCoupon`)}
              >
                Add New
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <TabPanel
            value={value}
            index={0}
            style={{ padding: "0px", width: "100%" }}
          >
            <ActiveCoupon
              activeCoupon={allCoupon}
              loading={loading}
              getAllCoupon={getAllCoupon}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            style={{ padding: "0px", width: "100%" }}
          >
            <ExpiredCoupon
              expiredCoupon={allCoupon}
              getAllCoupon={getAllCoupon}
              loading={loading}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
};

export default Coupon;
