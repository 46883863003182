import { Typography, Grid } from "@mui/material";

// pie chart
import { Doughnut } from "react-chartjs-2";

// pie chart
const options = {
  title: {
    display: true,
    text: "Donut Chart",
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  //   cutout: "70%",
};
const PieAnalytics = ({ showTypeArr }) => {
  const data = {
    labels: showTypeArr?.map((a, i) => a?._id),
    datasets: [
      {
        data: showTypeArr?.map((a, i) => a?.count),
        backgroundColor: showTypeArr?.map((a, i) => a?.color),
        hoverBackgroundColor: showTypeArr?.map((a, i) => a?.color),
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Typography className="gray5A5A5Atypo500 fs20px">
          Biilboard Type Distribution
        </Typography>
      </Grid>
      <Grid container sx={{ width: "280px", marginTop: "40px" }}>
        <Doughnut data={data} options={options} />
      </Grid>
      <Grid container sx={{ marginTop: "20px" }} gap="20px">
        <Grid container justifyContent="center">
          {" "}
          <Typography className="gray5A5A5Atypo500 fs20px">
            Total Billboards :{" "}
            {showTypeArr?.reduce((accumulator, currentValue) => {
              return (accumulator += currentValue?.count);
            }, 0)}
          </Typography>
        </Grid>

        <Grid container justifyContent="center" rowGap="5px">
          {showTypeArr?.map((data, index) => (
            <Grid item xs={6} key={index}>
              <Grid container justifyContent="center">
                <span
                  className="types_block"
                  style={{ background: `${data?.color}` }}
                ></span>
                <Typography className="greyColorTypo717171_400">
                  {data?._id} ({data?.count})
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default PieAnalytics;
