import React, { useEffect, useState } from "react";
import { Typography, Grid, Card, Button, IconButton } from "@mui/material";
// Icons
import { BsBroadcast } from "react-icons/bs";
import moment from "moment";
//Image imports
import ApprovedDialog from "../Camp_Details_Tab/Camp_Dialog's/ApproveDialog";
import RejectDialog from "../Camp_Details_Tab/Camp_Dialog's/RejactDialog";
import ApprovedToken from "../../../../../assets/Img/approvedStatus.png";
import RemoveToken from "../../../../../assets/Img/reject.png";
import SuccessDialog from "../../../../../utils/Dialog/SuceedFullDialog";
import WrongDialog from "../../../../../utils/Dialog/WrongDialog";
import TimeStamp from "./TimeStamp";
import ReactPlayer from "react-player/lazy";
import Skeleton from "@mui/material/Skeleton";
import PersonIcon from "@mui/icons-material/Person";
import CancelButtonDialogue from "./Camp_Dialog's/CancelButtonDialogue";
import { FiMail } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useLocation, useNavigate } from "react-router-dom";
import DisableBtn from "./Camp_Dialog's/disabledStatusButton/DisableBtn";
import TimeSlotTimeStamp from "./TimeSlotTimeStamp";
import permissionUtils from "../../../../../component/permissionUtils";

//utils/Dialog/SuceedFullDialog
export const NotationComponent = ({ noMargin }) => {
  return (
    <Grid item xs={0.4}>
      <Typography
        className={`${
          noMargin
            ? "secondary_blackSubheading_500 "
            : "secondary_blackSubheading_500 mt10px"
        }`}
      >
        :
      </Typography>
    </Grid>
  );
};
//==================== First tab to get open =======================//
export default function Camp_Overview_Tab(props) {
  const getRole = JSON.parse(localStorage.getItem("userData"))?.user?.userRole;
  const role = JSON.parse(localStorage.getItem("userData"))?.user?.role;
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  const { loading } = props;
  let userId = JSON.parse(localStorage.getItem("userData"))?.user?._id;
  let userRole = JSON.parse(localStorage.getItem("userData"))?.user?.role;

  const startscheduleDate = props?.data?.startscheduleDate;
  const [message, setMessage] = useState(null);
  const [openDialogName, setOpenDialog] = React.useState(null);
  const [multiTime, setMultiTime] = React.useState(null);
  const urlContentLink = props?.data.filetype;

  // helper function
  function formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " , " +
      strTime
    );
  }

  function formatDate2(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    // Add leading zeros if necessary
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return day + "/" + month + "/" + year;
  }
  // function convertSecondsToTime(arr, today) {
  //   let time = [];
  //   for (let item of arr[0][Object.keys(arr[0])]) {
  //     const startHour = props?.data?.startHour;
  //     const hourString = startHour < 10 ? `0${startHour}` : `${startHour}`;
  //     time.push(
  //       moment(`${startscheduleDate}T${hourString}:00:00+05:30`)
  //         .add(item, "seconds")
  //         .format("LTS")
  //     );
  //   }
  //   return time;
  // }

  const dateAndTime = formatDate(new Date(props?.data.createdAt));
  const approvedOnDate = formatDate(new Date(props?.data.orderDate));
  const orderDate = formatDate2(new Date(props?.data.orderDate));
  const date = new Date();
  const statusChangeDate = formatDate(new Date(props?.data?.updatedAt));
  // Convert to Indian time zone
  const options = {
    timeZone: "Asia/Kolkata",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const indianDateTime = statusChangeDate.toLocaleString("en-IN", options);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${month}/${day}/${year}`;
  const x = new Date(currentDate);
  const y = new Date(orderDate);
  const orderApproval = (newMsg, dialog) => {
    props.getCampaignDataById(props?.data?._id);
    setOpenDialog(dialog);
    setMessage(newMsg);
  };
  // useEffect(() => {
  //   if (props?.data) {
  //     setDisplayTime(
  //       convertSecondsToTime(
  //         props.data.secondsTaken,
  //         props.data.startscheduleDate
  //       )
  //     );
  //   }
  // }, [props?.data]);

  useEffect(() => {
    if (props?.data) {
      let dataTimeSlot = props?.data?.multiTimes;
      dataTimeSlot.map((data) => {
        if (data?.startHour < 4) {
          if (data.startHour === 0) data["actualTime"] = "12am - 1am";
          else
            data["actualTime"] = `${data.startHour}am - ${
              data.startHour + 1
            }am`;
        } else if (data?.startHour > 3 && data?.startHour < 8) {
          data["actualTime"] = `${data.startHour}am - ${data.startHour + 1}am`;
        } else if (data.startHour > 7 && data.startHour < 12) {
          if (data.startHour === 11) data["actualTime"] = "11am - 12pm";
          else
            data["actualTime"] = `${data.startHour}am - ${
              data.startHour + 1
            }am`;
        } else if (data.startHour > 11 && data.startHour < 16) {
          if (data.startHour === 12)
            data["actualTime"] = `12pm - ${data.startHour - 12 + 1}pm`;
          else
            data["actualTime"] = `${data.startHour - 12}pm - ${
              data.startHour - 12 + 1
            }pm`;
        } else if (data.startHour > 15 && data.startHour < 20) {
          data["actualTime"] = `${data.startHour - 12}pm - ${
            data.startHour - 12 + 1
          }pm`;
        } else if (data.startHour > 19) {
          if (data.startHour === 23)
            data["actualTime"] = `11pm - ${data.startHour - 12 + 1}am`;
          else
            data["actualTime"] = `${data.startHour - 12}pm - ${
              data.startHour - 12 + 1
            }pm`;
        }
      });
      setMultiTime(dataTimeSlot || []);
    }
  }, [props?.data]);

  return (
    <>
      <Grid container className="mb70px" rowGap="25px">
        <Grid item md={6} sm={12}>
          <Grid item>
            <Card
              className="compaign-overview-status mr40"
              sx={{ position: "relative" }}
            >
              <Grid container className="">
                <Grid container alignItems="flex-end" sx={{ margin: "16px 0" }}>
                  <Grid item md={3.8} sm={3.8} xs={3.8}>
                    <Typography
                      component="h5"
                      className="secondary_heading500  fs24px "
                    >
                      Status
                    </Typography>
                  </Grid>

                  <Grid item md={7.8} sm={7.8} xs={7.8}>
                    {props?.data?.status == "Approved" ? (
                      <Grid
                        container
                        justifyContent="flex-end
                      "
                        sx={{ position: "absolute", top: "5px", right: "20px" }}
                      >
                        {" "}
                        <img src={ApprovedToken} style={{ width: "70px" }} />
                      </Grid>
                    ) : props?.data?.status == "Rejected" ? (
                      <Grid
                        container
                        sx={{ position: "absolute", top: "5px", right: "20px" }}
                        justifyContent="flex-end
                      "
                      >
                        {" "}
                        <img src={RemoveToken} style={{ width: "70px" }} />
                      </Grid>
                    ) : props?.data?.status == "Live" ? (
                      <Grid container justifyContent="flex-start">
                        <Grid item className="red_status_block">
                          {" "}
                          {props?.data?.status}
                        </Grid>
                      </Grid>
                    ) : props?.data?.status == "Pending" ? (
                      <Grid container justifyContent="flex-start">
                        <Grid item className="status_block">
                          {props?.data?.status}
                        </Grid>
                      </Grid>
                    ) : props?.data?.status == "Published" ? (
                      <Grid container justifyContent="flex-start">
                        <Grid item className="published_status_block">
                          {props?.data?.status}
                        </Grid>
                      </Grid>
                    ) : props?.data?.status == "Cancelled" ? (
                      <>
                        <Grid container justifyContent="flex-start">
                          <Grid item className="Cancelled_status_block">
                            {props?.data?.status}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid container justifyContent="flex-start">
                          <Grid item className="expired_status_block">
                            {props?.data?.status}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                {props?.data?.status === "Rejected" ? (
                  <Grid container>
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 ">
                        Rejected on
                      </Typography>
                    </Grid>
                    <NotationComponent noMargin={true} />
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      <Typography className="greycolor505050500 ">
                        {statusChangeDate && statusChangeDate}
                        {/* 22/07/2022 */}
                      </Typography>
                    </Grid>{" "}
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 mt10px ">
                        Rejected by
                      </Typography>
                    </Grid>
                    <NotationComponent noMargin={true} />
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      <Typography className="greycolor505050500 mt10px">
                        {props?.data && props?.data?.statusChangedBy?.name}
                      </Typography>
                    </Grid>{" "}
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 mt10px ">
                        Reasons
                      </Typography>
                    </Grid>
                    <NotationComponent noMargin={true} />
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      {props?.data?.reason?.map((item) => {
                        return (
                          <Typography className="reasonOrder mt10px">
                            {`${item.data1} - ${item.data2}`}
                          </Typography>
                        );
                      })}
                    </Grid>
                  </Grid>
                ) : props?.data?.status === "Expired" ? (
                  <Grid container style={{ marginTop: "-5px" }}>
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 ">
                        Expired
                      </Typography>
                    </Grid>
                    <NotationComponent noMargin={true} />
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      <Typography className="greycolor505050500 ">
                        No action taken
                      </Typography>
                    </Grid>{" "}
                  </Grid>
                ) : props?.data?.status === "Pending" ? (
                  <Grid container style={{ marginTop: "-5px" }}>
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 ">
                        Approved on
                      </Typography>
                    </Grid>
                    <NotationComponent noMargin={true} />
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      <Typography className=" secondary_blackSubheading_500">
                        Not Yet
                      </Typography>
                    </Grid>{" "}
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 mt10px ">
                        Approved by
                      </Typography>
                    </Grid>
                    <NotationComponent />
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      <Typography className=" mt10px secondary_blackSubheading_500">
                        Not Yet
                        {/* Loreum ipsum (clusterhead) */}
                      </Typography>
                    </Grid>{" "}
                  </Grid>
                ) : props?.data?.status === "Cancelled" ? (
                  <Grid container style={{ marginTop: "-5px" }}>
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 ">
                        Cancelled on
                      </Typography>
                    </Grid>
                    <NotationComponent noMargin={true} />
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      <Typography className=" secondary_blackSubheading_500">
                        {indianDateTime && indianDateTime}
                      </Typography>
                    </Grid>{" "}
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 mt10px ">
                        Cancelled by
                      </Typography>
                    </Grid>
                    <NotationComponent noMargin={true} />
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      <Typography className=" mt10px secondary_blackSubheading_500">
                        {props?.data?.statusChangedBy
                          ? props?.data?.statusChangedBy?.name
                          : "Admin"}
                        {/* Loreum ipsum (clusterhead) */}
                      </Typography>
                    </Grid>{" "}
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 mt10px ">
                        Reasons
                      </Typography>
                    </Grid>
                    <NotationComponent noMargin={true} />
                    {
                      <Grid item md={7.8} sm={7.8} xs={7.8}>
                        {props?.data?.reason.map((item) => {
                          return (
                            <Typography className="reasonOrder mt10px">
                              {`${item.data1} - ${item.data2}`}
                            </Typography>
                          );
                        })}
                      </Grid>
                    }
                  </Grid>
                ) : (
                  <>
                    {" "}
                    <Grid container style={{ marginTop: "-5px" }}>
                      <Grid item md={3.8} sm={3.8} xs={3.8}>
                        <Typography className="secondary_graySubheading_500 ">
                          Approved on
                        </Typography>
                      </Grid>
                      <NotationComponent noMargin={true} />
                      <Grid item md={7.8} sm={7.8} xs={7.8}>
                        <Typography className=" secondary_blackSubheading_500">
                          {indianDateTime && indianDateTime}
                        </Typography>
                      </Grid>{" "}
                      <Grid item md={3.8} sm={3.8} xs={3.8}>
                        <Typography className="secondary_graySubheading_500 mt10px ">
                          Approved by
                        </Typography>
                      </Grid>
                      <NotationComponent />
                      <Grid item md={7.8} sm={7.8} xs={7.8}>
                        <Typography className=" mt10px secondary_blackSubheading_500">
                          {props?.data?.statusChangedBy &&
                          props?.data?.statusChangedBy?.name
                            ? props?.data?.statusChangedBy?.name
                            : props?.data?.statusChangedBy?.firstName
                            ? `${props?.data?.statusChangedBy?.firstName} ${props?.data?.statusChangedBy?.lastName}`
                            : "Admin"}
                          {/* Loreum ipsum (clusterhead) */}
                        </Typography>
                      </Grid>{" "}
                    </Grid>
                  </>
                )}
              </Grid>
            </Card>
          </Grid>
          {props?.data?.refundTransactionId && (
            <Grid item className="mt20px">
              <Card className="compaign-overview mr40">
                <Grid container alignItems="center">
                  {" "}
                  <Typography
                    component="h5"
                    className="secondary_heading500  fs24px "
                  >
                    Refund Details
                  </Typography>
                  <Grid container className="mt25px">
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 mt10px ">
                        Status
                      </Typography>
                    </Grid>
                    <NotationComponent />{" "}
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      {props?.data?.refundTransactionId?.amountStatus ===
                        "Pending" && (
                        <Button
                          className="purple_btn_primary text_transform_inherit"
                          sx={{
                            color: "#000",
                          }}
                        >
                          Pending
                        </Button>
                      )}
                      {props?.data?.refundTransactionId?.amountStatus ===
                        "Credited" && (
                        <Button
                          className="purple_btn_primary text_transform_inherit"
                          sx={{
                            color: "#000",
                          }}
                        >
                          Credited
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 mt10px">
                        Order Amount
                      </Typography>
                    </Grid>
                    <NotationComponent />
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      <Typography className="secondary_blackSubheading_500 mt10px">
                        ₹ {Math.round(props?.data?.refundTransactionId?.amount)}
                      </Typography>
                    </Grid>
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 mt10px ">
                        Refund Amount
                      </Typography>
                    </Grid>
                    <NotationComponent />
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      <Typography className="secondary_blackSubheading_500 mt10px">
                        ₹ {Math.round(props?.data?.refundTransactionId?.amount)}
                      </Typography>
                    </Grid>
                    <Grid item md={3.8} sm={3.8} xs={3.8}>
                      <Typography className="secondary_graySubheading_500 mt10px ">
                        Refund Mode
                      </Typography>
                    </Grid>
                    <NotationComponent />
                    <Grid item md={7.8} sm={7.8} xs={7.8}>
                      <Typography className="secondary_blackSubheading_500 mt10px">
                        wallet
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
          <Grid item className="mt20px">
            <Card className="compaign-overview mr40">
              <Typography
                component="h5"
                className="secondary_heading500  fs24px mt10px"
              >
                Campaign Overview
              </Typography>
              <Grid container className="mt16px">
                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 ">
                    Campaign ID
                  </Typography>
                </Grid>
                <NotationComponent noMargin={true} />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 ">
                    {props?.data && props?.data?.orderId ? (
                      props?.data?.orderId
                    ) : loading ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "100px" }}
                      />
                    ) : (
                      NaN
                    )}
                  </Typography>
                </Grid>

                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px">
                    Campaign Name
                  </Typography>
                </Grid>
                <NotationComponent />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography
                    className="secondary_blackSubheading_500 mt10px"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {props?.data?.adTitle?.length > 100
                      ? `${props?.data?.adTitle?.substring(0, 100)}...`
                      : props?.data?.adTitle}
                  </Typography>
                </Grid>
                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px">
                    Start Date
                  </Typography>
                </Grid>
                <NotationComponent />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 mt10px">
                    {props?.data?.startscheduleDate}
                  </Typography>
                </Grid>

                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px">
                    End Date
                  </Typography>
                </Grid>
                <NotationComponent />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 mt10px">
                    {props?.data?.endscheduleDate}
                  </Typography>
                </Grid>

                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px ">
                    Duration
                  </Typography>
                </Grid>
                <NotationComponent />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 mt10px">
                    {props?.data?.videoDuration} seconds
                  </Typography>
                </Grid>
                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px ">
                    Time Slot
                  </Typography>
                </Grid>

                <NotationComponent />

                <Grid
                  container
                  item
                  md={7.8}
                  sm={7.8}
                  xs={7.8}
                  gap="12px"
                  className="mt10px"
                >
                  <Grid item>
                    {" "}
                    <span className="Time-Stamp ">
                      {multiTime?.length > 0 && multiTime[0].actualTime} X{" "}
                      {multiTime?.length > 0 && multiTime[0].repetitions}
                    </span>
                  </Grid>
                  <Grid item>
                    {" "}
                    {multiTime?.length > 1 && (
                      <span className="Time-Stamp">
                        {multiTime?.length > 1 && multiTime[1].actualTime} X{" "}
                        {multiTime?.length > 1 && multiTime[1].repetitions}
                      </span>
                    )}
                  </Grid>

                  <Grid item>
                    {" "}
                    {multiTime?.length > 0 && (
                      <TimeSlotTimeStamp detail={multiTime} />
                    )}
                  </Grid>
                </Grid>

                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px ">
                    Submitted by
                  </Typography>
                </Grid>
                <NotationComponent />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 mt10px">
                    {props?.data?.userId?.name ? (
                      props?.data?.userId?.name
                    ) : props?.data?.userId?.firstName &&
                      props?.data?.userId?.lastName ? (
                      props?.data?.userId?.firstName +
                      " " +
                      props?.data?.userId?.lastName
                    ) : loading ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "100px" }}
                      />
                    ) : (
                      NaN
                    )}
                  </Typography>
                </Grid>
                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px ">
                    Submitted at
                  </Typography>
                </Grid>
                <NotationComponent />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 mt10px">
                    {dateAndTime ? (
                      dateAndTime
                    ) : loading ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "100px" }}
                      />
                    ) : (
                      NaN
                    )}
                  </Typography>
                </Grid>

                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px ">
                    About
                  </Typography>
                </Grid>
                <NotationComponent />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 mt10px">
                    {props?.data?.aboutAd?.length > 300
                      ? `${props?.data?.aboutAd?.substring(0, 300)}.`
                      : props?.data?.aboutAd}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid container item sx={{ marginTop: "16px" }}>
            <Grid
              container
              item
              className="order_detail_div"
              sx={{ marginRight: "40px" }}
              gap="16px"
            >
              <Grid container>
                <Typography
                  component="h5"
                  className="secondary_heading500  fs24px mt10px"
                >
                  Billboard Overview
                </Typography>
              </Grid>
              <Grid container>
                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500">
                    Billboard Quantity
                  </Typography>
                </Grid>
                <NotationComponent noMargin={true} />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500">
                    {props?.data && props?.data?.billboardId?.length ? (
                      props?.data?.billboardId?.length
                    ) : loading ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "100px" }}
                      />
                    ) : (
                      0
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item sx={{ marginTop: "16px" }}>
            <Grid
              container
              item
              className="order_detail_div"
              sx={{ marginRight: "40px" }}
            >
              <Grid container>
                <Typography
                  component="h5"
                  className="secondary_heading500  fs24px mt10px"
                >
                  Transaction Details
                </Typography>
              </Grid>
              <Grid container sx={{ marginTop: "16px" }}>
                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500">
                    Transaction ID
                  </Typography>
                </Grid>
                <NotationComponent noMargin={true} />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500">
                    {props?.data?.transactionId
                      ? props?.data?.transactionId
                      : NaN}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: "10px" }}>
                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500">
                    Mode of payment
                  </Typography>
                </Grid>
                <NotationComponent noMargin={true} />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500">
                    Wallet
                  </Typography>
                </Grid>
              </Grid>{" "}
              <Grid container sx={{ marginTop: "10px" }}>
                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500">
                    Amount
                  </Typography>
                </Grid>
                <NotationComponent noMargin={true} />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500">
                    {props?.data && props?.data?.orderPrice
                      ? props?.data?.orderPrice
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={12}>
          <Grid item className="box-shadow  b-r-8px">
            <Card className=" compaign-overview-card no-box-shadow">
              <Grid item className="relative">
                <Grid item className="orderbtn-itemR pt5px">
                  <Button className="order-live-white-btn  box-shadow width100 min-width-120px ">
                    <IconButton className="fs16px">
                      <BsBroadcast className="iconcolor-red" />
                    </IconButton>
                    <Typography className="redcolortypo500 fs14px mr10px ">
                      Live
                    </Typography>
                  </Button>
                </Grid>
              </Grid>{" "}
              {props?.data &&
                (props?.data?.filetype == "video/mp4" ||
                  props?.data?.filetype == "video/webm" ||
                  props?.data?.filetype == "video/quicktime") && (
                  <div className="content_container">
                    {" "}
                    <video
                      muted
                      src={props?.data?.contentLink}
                      autoPlay={true}
                      loop
                      className="content_style"
                      controls
                      controlsList="nodownload"
                      oncontextmenu="return false"
                    />
                  </div>
                )}
              {/* {props?.data && props?.data?.filetype == "url" && (
                <ReactPlayer
                  playing
                  width={"100%"}
                  height={"40vh"}
                  url={props?.data?.videoname}
                  muted={true}
                  autoplay={true}
                  loop={true}
                />
              )} */}
              {props?.data &&
                props?.data?.filetype === "url" &&
                ((urlContentLink.includes("jpg" || "jpeg" || "png") && (
                  <img
                    src={props?.data?.contentLink && props?.data?.contentLink}
                    className="width100"
                    alt="campaignCardImg"
                    height={"400px"}
                    style={{ objectFit: "contain" }}
                  />
                )) ||
                  (urlContentLink.includes("mp4" || "mov") && (
                    <video
                      src={props?.data?.contentLink && props?.data?.contentLink}
                      autoPlay={true}
                      className="width100"
                      height={"400px"}
                      style={{ objectFit: "contain" }}
                      controls
                    />
                  )) || (
                    <ReactPlayer
                      playing
                      className="width100"
                      height={"400px"}
                      style={{ objectFit: "contain" }}
                      url={props?.data?.contentLink}
                      muted={true}
                      autoPlay={true}
                      loop={true}
                    />
                  ))}
              {loading && !props?.data && !props?.data?.filetype && (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: "100%", height: "400px" }}
                />
              )}
              {((props?.data && props?.data?.filetype == "image/jpeg") ||
                (props?.data && props?.data?.filetype == "image/jpg") ||
                (props?.data && props?.data?.filetype == "image/png") ||
                (props?.data && props?.data?.filetype == "image/heic")) && (
                <div className="content_container">
                  {" "}
                  <img
                    style={{ objectFit: "contain" }}
                    src={props?.data?.contentLink}
                    alt="campaignCardImg"
                    className="width100"
                  />
                </div>
              )}
            </Card>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            sx={{ paddingTop: "20px" }}
            alignItems="center"
            rowGap="20px"
          >
            {PermissionCheck("cancel-campaign") && (
              <Grid item>
                {props?.data?.status !== "Published" &&
                  props?.data?.status !== "Expired" &&
                  props?.data?.status !== "Rejected" &&
                  props?.data?.status !== "Cancelled" && (
                    <Grid container justifyContent="flex-end">
                      <Grid>
                        <CancelButtonDialogue
                          approveFunc={orderApproval}
                          orderId={props?.data?._id}
                          userId={userId}
                        />
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            )}
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            className="mt20px"
            rowGap="20px"
          >
            {props?.data?.status && <DisableBtn data={props?.data?.status} />}

            {PermissionCheck("approve-reject-campaigns") && (
              <>
                {props?.data?.status == "Pending" && (
                  <>
                    <Grid item xs={12} sm={5.8} className="width100">
                      <ApprovedDialog
                        orderId={props.data._id}
                        approveFunc={orderApproval}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5.8}>
                      <RejectDialog
                        orderId={props.data._id}
                        approveFunc={orderApproval}
                        status={props?.data?.status}
                        videoDuration={props?.data?.videoDuration}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
          <Grid item className="mt20px width100">
            <Card className="compaign-overview-status ">
              <Grid container direction="row" justifyContent="space-between">
                <Typography
                  component="h5"
                  className="secondary_heading500  fs24px mt10px"
                >
                  Client information
                </Typography>
              </Grid>

              <Grid container className="mt25px">
                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 ">
                    User ID
                  </Typography>
                </Grid>
                <NotationComponent noMargin={true} />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 ">
                    #
                    {props?.data && props?.data?.userId?._id?.substring(14, 24)}
                    {/* PMA-BO1 */}
                  </Typography>
                </Grid>
                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px ">
                    User Name
                  </Typography>
                </Grid>
                <NotationComponent />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 mt10px">
                    {props?.data?.userId?.name
                      ? props?.data?.userId?.name
                      : props?.data?.userId?.firstName
                      ? `${props?.data?.userId?.firstName} ${props?.data?.userId?.lastName}`
                      : NaN}
                  </Typography>
                </Grid>
                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px ">
                    Role
                  </Typography>
                </Grid>
                <NotationComponent />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 mt10px">
                    {props?.data && props?.data?.userId?.role}
                  </Typography>
                </Grid>

                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px ">
                    Email
                  </Typography>
                </Grid>

                <NotationComponent />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 mt10px">
                    {props?.data && props?.data?.userId?.email}
                  </Typography>
                </Grid>

                <Grid item md={3.8} sm={3.8} xs={3.8}>
                  <Typography className="secondary_graySubheading_500 mt10px ">
                    Mobile Number
                  </Typography>
                </Grid>
                <NotationComponent />
                <Grid item md={7.8} sm={7.8} xs={7.8}>
                  <Typography className="secondary_blackSubheading_500 mt10px">
                    {props?.data?.billboardId?.[0]?.addedBy?.mobileNumber
                      ? props?.data?.billboardId?.[0]?.addedBy?.mobileNumber
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item className="mt20px width100">
            <Card className="compaign-overview-status ">
              {" "}
              <Grid container gap="5px" direction="column">
                <Typography className="secondary_heading500 fs20px ">
                  Help
                </Typography>
                <Typography className="secondary_graySubheading_400 fs14px ">
                  Having some issues? You can contact authority anytime
                </Typography>

                <Grid
                  container
                  alignItems="center"
                  style={{ marginTop: "16px" }}
                >
                  <Grid item xs={3.8}>
                    {" "}
                    <Typography className="secondary_graySubheading_500  ">
                      Name
                    </Typography>
                  </Grid>
                  <NotationComponent noMargin={true} />
                  <Grid item xs={7.8}>
                    {" "}
                    <Typography className="secondary_blackSubheading_500  ">
                      {props?.data?.billboardId?.[0]?.addedBy?.name
                        ? props?.data?.billboardId?.[0]?.addedBy?.name
                        : props?.data?.billboardId?.[0]?.addedBy?.firstName
                        ? `${props?.data?.billboardId?.[0]?.addedBy?.firstName} ${props?.data?.billboardId?.[0]?.addedBy?.lastName}`
                        : NaN}

                      {/* H&M */}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item alignItems="center">
                  <Grid item xs={3.8}>
                    {" "}
                    <Typography className="secondary_graySubheading_500  ">
                      Email id
                    </Typography>
                  </Grid>
                  <NotationComponent noMargin={true} />
                  <Grid item xs={7.8}>
                    <Typography className="secondary_blackSubheading_500  ">
                      {" "}
                      {props?.data?.billboardId?.[0]?.addedBy?.email ? (
                        props?.data?.billboardId?.[0]?.addedBy?.email
                      ) : loading ? (
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "100px" }}
                        />
                      ) : (
                        NaN
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item alignItems="center">
                  <Grid item xs={3.8}>
                    {" "}
                    <Typography className="secondary_graySubheading_500  ">
                      Number
                    </Typography>
                  </Grid>
                  <NotationComponent noMargin={true} />
                  <Grid item xs={7.8}>
                    {" "}
                    <Typography className="secondary_blackSubheading_500  ">
                      {props?.data?.billboardId?.[0]?.addedBy?.mobileNumber
                        ? props?.data?.billboardId?.[0]?.addedBy?.mobileNumber
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <SuccessDialog
          parentNavigate={true}
          openData={openDialogName === "success"}
          data={message}
        />
        <WrongDialog openData={openDialogName === "reject"} data={message} />
      </Grid>
    </>
  );
}
