import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axiosInstance from "../../../../api/axiosInstance";
import Calendar from "../../../../component/ReactDateRangePicker/ReactDateRangePickerFormonth";
import moment from "moment";

// temp
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    xAxes: [{ stacked: true }],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        stacked: false,
      },
    ],
  },
};

const AnalyticsTab = () => {
  const [dataType, setdataType] = useState("Orders");
  const [ratio, setRatio] = useState("All");
  let barChartData = [];

  const [analyticsData, setAnalyticsData] = useState([]);
  const [date, setDate] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [acceptedGraphData, setAcceptedGraphData] = useState([]);
  const [datasets, setDataSets] = useState([]);
  const [amountType, setamountType] = useState("refund");
  let userID = JSON.parse(localStorage.getItem("userData")).user._id;

  const timeInHour = [
    "12:00 Am",
    "1:00 Am",
    "2:00 Am",
    "3:00 Am",
    "4:00 Am",
    "5:00 Am",
    "6:00 Am",
    "7:00 Am",
    "8:00 Am",
    "9:00 Am",
    "10:00 Am",
    "11:00 Am",
    "12:00 Pm",
    "1:00 Pm",
    "2:00 Pm",
    "3:00 Pm",
    "4:00 Pm",
    "5:00 Pm",
    "6:00 Pm",
    "7:00 Pm",
    "8:00 Pm",
    "9:00 Pm",
    "10:00 Pm",
    "11:00 Pm",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels:
      analyticsDate?.length == 24
        ? timeInHour
        : analyticsDate?.length == 12
        ? months
        : analyticsDate,
    datasets: datasets,
  };
  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };

  ///////////////////// api call to get data for bar graph //////////////////////

  const getWalletOverallGraph = async () => {
    try {
      let res = await axiosInstance.get(
        `/api/payment/getWalletOverallGraphData?userId=${userID}&amountType=${amountType}&startDate=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}`
      );

      if (res.status == 200 || res.status == 201) {
        //  setAcceptedOrderAnalyticsData(res?.data.data);

        setAcceptedGraphData(res?.data.data);
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };
  useEffect(() => {
    if (!date) {
      return;
    }
    getWalletOverallGraph();
  }, [amountType, date]);

  ////////////// set data in the dataset ///////////////

  let amountData = [];
  useEffect(() => {
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = acceptedGraphData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          amount: acceptedGraphData[index].amount,
        };
        amountData.push(obj);
      } else {
        let obj = {
          Date: analyticsDate[i],
          amount: 0,
        };
        amountData.push(obj);
      }
    }
  }, [acceptedGraphData]);

  // making an empty array and pushing all data in (data with 0 count)
  // setting datasets
  useEffect(() => {
    if (amountType === "refund") {
      setDataSets([
        {
          label: "Refund",
          data: amountData?.map((a) => a?.amount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (amountType === "margin") {
      setDataSets([
        {
          label: "Margin",
          data: amountData?.map((a) => a?.amount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (amountType === "walletDeposit") {
      setDataSets([
        {
          label: "Wallet Deposit",
          data: amountData?.map((a) => a?.amount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (amountType === "spent") {
      setDataSets([
        {
          label: "Spent",
          data: amountData?.map((a) => a?.amount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (amountType === "depositByAdmin") {
      setDataSets([
        {
          label: "depositByAdmin",
          data: amountData?.map((a) => a?.amount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    }
  }, [amountType, acceptedGraphData]);
  // setting datasets

  return (
    <>
      <div style={{ paddingBottom: "10px", marginBottom: "10px" }}>
        <Grid
          container
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <Grid item xs={12}>
            <div>
              {" "}
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                rowGap="10px"
                sx={{ padding: "0 10px" }}
              >
                {" "}
                <Grid item>
                  <Grid container gap="15px" direction="column">
                    {" "}
                    <Typography className="gray5A5A5Atypo500 fs20px">
                      Wallet Usage
                    </Typography>{" "}
                    <FormControl
                      sx={{
                        minWidth: 200,
                        width: "60%",
                        fontSize: "10px",
                        border: "1px solid #DDDDDD",
                        fontFamily: "oswald",
                      }}
                      className="form_control_div"
                      size="small"
                    >
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle-noborder analytics_dropDown"
                        labelid="demo-select-small"
                        id="demo-select-small"
                        inputProps={{ "aria-label": "Without label" }}
                        value={amountType}
                        defaultValue={0}
                        onChange={(e) => {
                          setamountType(e.target.value);
                        }}
                      >
                        <MenuItem value={"refund"} className="Selectmenustyle">
                          <Typography className="Selectmenustyle fs16px  ">
                            Refund
                          </Typography>
                        </MenuItem>
                        <MenuItem value={"margin"} className="Selectmenustyle">
                          <Typography className="Selectmenustyle fs16px  ">
                            Margin
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          value={"walletDeposit"}
                          className="Selectmenustyle"
                        >
                          <Typography className="Selectmenustyle fs16px  ">
                            Wallet Deposit
                          </Typography>
                        </MenuItem>
                        <MenuItem value={"spent"} className="Selectmenustyle">
                          <Typography className="Selectmenustyle fs16px  ">
                            Spent
                          </Typography>
                        </MenuItem>{" "}
                        <MenuItem
                          value={"depositByAdmin"}
                          className="Selectmenustyle"
                        >
                          <Typography className="Selectmenustyle fs16px  ">
                            Top up
                          </Typography>
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    gap="15px"
                    sx={{ padding: "0 10px" }}
                  >
                    {" "}
                    <Calendar
                      getDataFromChildHandler={getDataFromChildHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Line options={options} data={data} />
        </Grid>
      </div>
    </>
  );
};

export default AnalyticsTab;
