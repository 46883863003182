import React, { useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import {
  DialogContent,
  IconButton,
  DialogTitle,
  Dialog,
  Typography,
  Grid,
} from "@mui/material";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Link } from "react-router-dom";
//React Icons

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitleConfirmation = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2 }} className="purple-gradient" {...other}>
      {children}
      <Typography className="whitecolortypo"> {props.Name} </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 3,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitleConfirmation.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function FilesDialog({ item, getFiles, files }) {
  const vidRef = useRef();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");
  // SnackBar

  // ==================== States ======================== //
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Grid
          item
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleClickOpen();
            getFiles(item);
          }}
        >
          <FolderOpenIcon
            style={{
              width: "60px",
              height: "auto",

              color: "#b937fa ",
            }}
          />
          <Typography style={{ fontSize: "12px", marginTop: "0px !important" }}>
            {item}
          </Typography>
        </Grid>

        <BootstrapDialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitleConfirmation
            onClose={handleClose}
            Name={item}
          ></BootstrapDialogTitleConfirmation>
          <DialogContent>
            <Grid
              container
              rowGap="20px"
              className="fileLogs"
              gap={3.5}
                          textAlign="center"
                          alignItems="center"
            >
              {files?.map((item, index) => {
                return (
                  <Link to={item} style={{ textDecoration: "none" }}>
                    <Grid container alignItems="center">
                      <SaveAltIcon
                        style={{
                          width: "60px",
                          height: "auto",

                          color: "#b937fa ",
                        }}
                      />
                    </Grid>
                    <Typography
                      style={{
                        fontSize: "13px",
                        marginTop: "0px !important",
                        color: "Black",
                      }}
                      textAlign="center"
                    >
                      {item?.slice(-6)}
                    </Typography>
                  </Link>
                );
              })}
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}
