import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const CustomTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#fff",
    borderRadius: theme.shape.borderRadius,
    "& fieldset": {
      borderColor: error ? "#d32f2f" : "#9e9e9e",
    },
    "&:hover fieldset": {
      borderColor: error ? "#d32f2f" : "#b937fa",
    },
    "&.Mui-focused fieldset": {
      borderColor: error ? "#d32f2f" : "#b937fa",
    },
  },
  "& .MuiFormLabel-root": {
    color: error ? "#d32f2f" : "#9e9e9e",
    "&.Mui-focused": {
      color: error ? "#d32f2f" : "#b937fa",
    },
  },
}));

export default CustomTextField;
