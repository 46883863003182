import React, { useRef, useEffect } from "react";
//**  Mui Component Import
import { Grid, Typography, Button } from "@mui/material";
//** Core Component Import
import LoaderDialog from "../../../../../../../component/LoaderDialogue/LoaderDialogue";
import DeleteDilogue from "../ActionBurner's/DeleteDialogue";

export default function PendingTab({
  loadingBurners,
  resBurnerAds,
  deleteBurnerAd,
  getBurnerAd,
  billBoardData,
}) {
  const vidRef = useRef();
  useEffect(() => {
    if (!!billBoardData?.deviceId?.macId) {
      getBurnerAd();
    }
  }, [billBoardData]);
  return (
    <>
      <Typography className="blackcolor484848 fs20px">
        Showing {resBurnerAds?.length ?? 0} Burner Ads{" "}
      </Typography>
      <Grid container mt={3} gap={3}>
        {resBurnerAds ? (
          resBurnerAds.map((data, index) => (
            <>
              <Grid
                item
                xs={12}
                sm={5.8}
                md={2.8}
                className="relative"
                key={index}
              >
                <Grid container className="relative">
                  <Grid
                    item
                    xs={12}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        height: "251px",
                      }}
                    >
                      <video
                        controls
                        src={
                          data?.s3Link ||
                          `https://s3.ap-south-1.amazonaws.com/storage.saps.one/${data?.Key}`
                        }
                        width="100%"
                        height="100%"
                        ref={vidRef}
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      />
                    </div>
                    <Typography
                      align="center"
                      className="cancel-secondary"
                      sx={{ padding: "12px", width: "100%" }}
                    >
                      Rejected{" "}
                    </Typography>
                    <Grid item className="absolute" sx={{ right: 7, top: 10 }}>
                      <DeleteDilogue
                        deleteBurnerAd={deleteBurnerAd}
                        burnerVdo={data}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))
        ) : (
          <Grid item xs={8}>
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <LoaderDialog loading={loadingBurners} />
            </Grid>{" "}
          </Grid>
        )}
      </Grid>{" "}
    </>
  );
}
