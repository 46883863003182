import React, { useState } from "react";

import { Typography, Grid, Card, IconButton, CardActions } from "@mui/material";
// Core Images
import Kidsk from "../../../../../assets/Img/KIOSK.png";
import Window from "../../../../../assets/Img/WINDOW.png";
import StandingDisplay from "../../../../../assets/Img/StandingDisplay.png";
import WallMounted from "../../../../../assets/Img/WallMounted.png";
// import { useBillboard } from "../../../../../Context/AddBillboardContext";
export default function Indoor({ formdata, handleBillBoardType }) {
  //=============================//
  // const { billboardtype, setBillboardtype } = useBillboard();

  const [ImageArray, setImageArray] = React.useState([
    {
      name: "Kiosk",
      image: Kidsk,
    },
    {
      name: "WINDOW",
      image: Window,
    },
    {
      name: "SIGNAGE TV",
      image: StandingDisplay,
    },
    {
      name: "WALL MOUNTED",
      image: WallMounted,
    },
  ]);

  const [selectedDiv, setSelectedDiv] = useState(formdata.billboardType);

  return (
    <>
      <Grid container>
        <Typography
          align="center"
          className="greycolor505050500 fs16px width100"
        >
          Select Billboard Type
        </Typography>
        <Grid
          container
          style={{ marginTop: "20px" }}
          justifyContent="space-between"
          rowGap="10px"
        >
          {ImageArray.map((item, i) => (
            <Grid item xs={12} sm={5.8} md={3.8} lg={2.8} key={i}>
              {item.name === selectedDiv ? (
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.45) 0px 5px 15px",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setSelectedDiv(item.name);
                    handleBillBoardType(item.name);
                  }}
                >
                  <>
                    <Card className="billboardImage-card  ">
                      <img src={item.image} alt="Kidsk" className="width100" />
                    </Card>
                    <CardActions className="billboardaction">
                      <Typography
                        className="greycolor505050500 fs16px width100"
                        align="center"
                      >
                        {item.name}
                      </Typography>
                    </CardActions>{" "}
                  </>
                </div>
              ) : (
                <div
                  className="Cardhover"
                  onClick={() => {
                    setSelectedDiv(item.name);
                    handleBillBoardType(item.name);
                  }}
                >
                  <Card className="billboardImage-card  ">
                    <img src={item.image} alt="Kidsk" className="width100" />
                  </Card>
                  <CardActions className="billboardaction">
                    <Typography
                      className="greycolor505050500 fs16px width100"
                      align="center"
                    >
                      {item.name}
                    </Typography>
                  </CardActions>{" "}
                </div>
              )}
            </Grid>
          ))}
        </Grid>

        {/* <Grid container spacing={3} style={{ marginTop: "10px" }}>
          <Grid item md={3}>
            <div className="Cardhover">
              <Card className="billboardImage-card  ">
                <img src={Kidsk} alt="Kidsk" className="width100" />
              </Card>
              <CardActions className="billboardaction">
                <Typography
                  className="greycolor505050500 fs16px width100"
                  align="center"
                >
                  KIOSK
                </Typography>
              </CardActions>{" "}
            </div>
          </Grid>
          <Grid item md={3}>
            <Card className=" billboardImage-card ">
              <img src={Window} alt="Window" className="width100" />
            </Card>{" "}
            <CardActions className="billboardaction">
              <Typography
                className="greycolor505050500 fs16px width100"
                align="center"
              >
                WINDOW
              </Typography>
            </CardActions>
          </Grid>
          <Grid item md={3}>
            <Card
              className=" billboardImage-card "
              onClick={() => handleClickitem()}
            >
              <img
                src={StandingDisplay}
                alt="StandingDisplay"
                className="width100"
              />
            </Card>{" "}
            <CardActions className="billboardaction">
              <Typography
                className="greycolor505050500 fs16px width100"
                align="center"
              >
                STANDING DISPLAY
              </Typography>
            </CardActions>
          </Grid>
          <Grid item md={3}>
            <Card className=" billboardImage-card ">
              <img src={WallMounted} alt="WallMounted " className="width100" />
            </Card>{" "}
            <CardActions className="billboardaction">
              <Typography
                className="greycolor505050500 fs16px width100"
                align="center"
              >
                WALL MOUNTED
              </Typography>
            </CardActions>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
}
