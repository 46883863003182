import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Calendar from "../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
import axiosInstance from "../../../../../api/axiosInstance";
import moment from "moment";
import no_data_img from "../../../../../assets/Img/no_data_found_img.png";
import useExportExcel from "../../../../../utils/useExportExcel";
const headCells = [
  {
    id: "Billboard Type",
    numeric: true,
    label: "Billboard Type ",
  },
  {
    id: "Billboard",
    numeric: true,
    disablePadding: false,
    label: "Billboard   ",
  },
  {
    id: "Orders",
    numeric: true,
    disablePadding: false,
    label: "Orders  ",
  },
  {
    id: "Views",
    numeric: true,
    disablePadding: false,
    label: "Views",
  },
  {
    id: "Gross Revenue",
    numeric: true,
    disablePadding: false,
    label: "Gross Revenue",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              align={headCell.id == "Billboard Type" ? "left" : "center"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const LocationAnalyticsTable = ({ country, state, city }) => {
  const { exportToExcel } = useExportExcel();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [dense, setDense] = React.useState(false);
  const [date, setDate] = useState();
  const [tableData, setTableData] = useState();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // sending this func to date range picker
  const getDataFromChildHandler = (data) => {
    setDate(data);
  };
  // sending this func to date range picker
  // api call for table data
  const getTableGraphData = async () => {
    try {
      setLoading(true);
      let body = {
        start_date: moment(date?.[0]?.startDate).format("YYYY-MM-DD"),
        end_date: moment(date?.[0]?.endDate).format("YYYY-MM-DD"),
        country: country,
        state: state ? state : "All",
        city: city ? city : "All",
      };

      const res = await axiosInstance.post(
        `/api/billboard/getDataByCountry`,
        body
      );
      if (res.status == 200) {
        console.log("success res after TableGraphData api", res);
        setLoading(false);
        setTableData(res?.data?.data);
      } else {
        setLoading(false);
        console.log("error res after TableGraphData api", res);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  // api call for table data

  useEffect(() => {
    if (date) {
      getTableGraphData();
    }
  }, [date, country, city, state]);

  return (
    <div>
      {" "}
      <Grid container className="width100 mt20px" gap="20px">
        <Grid container justifyContent="space-between">
          <Typography className="gray5A5A5Atypo500 fs20px">
            Billboard type
          </Typography>
          <Grid item>
            <Grid container gap="10px">
              <Calendar getDataFromChildHandler={getDataFromChildHandler} />
              <Button
                sx={{ padding: "0 10px" }}
                className="purple_btn_primary text_transform_inherit"
                onClick={() => exportToExcel(tableData)}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <EnhancedTableToolbar />
        <TableContainer className="width100 hide_sortBar table_border_radius">
          <Table
            sx={{
              minWidth: 800,
              backgroundColor: "#fff",
              width: "100%",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              className="tablerow"
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />

            <TableBody className="tablerow ">
              {tableData?.map((tData, i) => (
                <TableRow
                  key={i}
                  hover
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {tData?._id}
                  </TableCell>

                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="none"
                  >
                    {tData?.billboard ? tData?.billboard : 0}
                  </TableCell>
                  <TableCell
                    align="center"
                    ccomponent="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {tData?.orders ? tData?.orders : 0}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {tData?.views ? tData?.views : 0}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {tData?.revanues ? tData?.revanues : 0}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {tableData?.length == 0 && !loading && (
            <Grid container justifyContent="center">
              {" "}
              <img
                src={no_data_img}
                alt="no data found"
                className="noBillboardFoundGif_width"
              />
            </Grid>
          )}
        </TableContainer>
      </Grid>
    </div>
  );
};

export default LocationAnalyticsTable;
