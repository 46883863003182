import React from "react";
import { ErrorMessage, useField } from "formik";
import { Grid, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const TextField = ({ label, vText, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <input
        className={` p15px blackcolor525252_400 width100 fs18px ${
          meta.touched && meta.error ? "error_border_primary" : "border_primary"
        }`}
        {...field}
        {...props}
        autoComplete="off"
        disabled={field.name == "Voucher_Code" ? true : false}
        placeholder={label}
      />

      <Grid container className="mt-5px" gap="5px" alignItems="center">
        <InfoOutlinedIcon className="fs16px" />
        <Typography className="blackcolortypo1c1c1c400 fs14px">
          {vText}
        </Typography>
      </Grid>

      <ErrorMessage
        component="div"
        name={field.name}
        style={{ color: "red", fontSize: "14px" }}
      />
    </div>
  );
};

export default TextField;
