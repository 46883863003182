import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

export default function DeleteDilogue({
  deleteFun,
  id,
  handleCloseDialog,
  title,
  contentName,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        sx={{ width: "100%" }}
        className="cancel-secondaryDl"
      >
        {title}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 1.2,
            background: "red",
          }}
        >
          <Typography className="whitecolortypo">{title}</Typography>{" "}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 2,
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "20px",
          }}
        >
          <Typography className="greycolor505050500">
            {" "}
            {` Are you sure you want to delete this ${contentName} ?`}
          </Typography>
          <Typography className="greycolor505050500"></Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="diallog-no-btn">
            No
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleCloseDialog();
              deleteFun(id);
            }}
            className="reject-btn width-25 "
            style={{ padding: "4px 0px" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
