import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Input,
  Stack,
  DialogActions,
  Button,
  Avatar,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { MdVerifiedUser } from "react-icons/md";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import axiosInstance from "../../../../api/axiosInstance";
import { FETCH_URL } from "../../../../fetchIp";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Testing({ userID }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  // const token = useLogin();
  const [file, setFile] = useState("");
  function handleImg(event) {
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  }
  const [img, setImage] = useState();
  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }
  function ChangeInputCancel(e) {
    setInputState(true);
  }
  // states
  const [profile, setProfile] = React.useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [adharCard, setAdharCard] = useState("");
  const [email, setEmail] = useState("");
  const [landmark, setLandmark] = useState("");
  const [country, setCountry] = useState("");

  // ===================== SnackBar ==================== //
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  useEffect(() => {
    if (profile) {
      setFirstName(profile.firstName ?? "");
      setLastName(profile.lastName ?? "");
      setPhoneNumber(profile.mobileNumber ?? "");
      setAddress(profile.address ?? "");
      setPincode(profile.pincode ?? "");
      setAdharCard(profile.AdharCard ?? "");
      setLandmark(profile.state ?? "");
      setCountry(profile.country ?? "");
      setEmail(profile?.email ?? "");
    }
  }, [profile]);
  const [inputState, setInputState] = useState(true);

  function ChangeInputState() {
    setInputState(false);
  }
  // GetUser api

  const getProfile = async () => {
    try {
      let profile = await axiosInstance.get(
        `/api/user/getUserDetail/${userID}`
      );
      console.log("Profile ==> ", profile.data.msg);
      setProfile(profile.data.msg);
    } catch (error) {
      console.log("USER PROFILE DATA ==>", error);
    }
  };
  // ProfileEdit
  const ProfileEdit = async () => {
    try {
      let token = JSON.parse(localStorage.getItem("userData")).token;
      const response = await fetch(`${FETCH_URL}/api/user/profileupdate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: userID,
          userRole: 0,
          firstName: firstName,
          lastName: lastName,
          mobileNumber: +phoneNumber,
          address: address,
          pincode: pincode,
          adharCard: adharCard,
          email: email,
          state: landmark,
          country: country,
        }),
      });
      let res = await response.json();
      if (response.ok) {
        // setSnackOpen(true);
        // setSnackMsg("Edit Successfull");
        setInputState(true);
        getProfile(userID);
        console.log("Edit Profile   response  ===>", res.msg);
      } else {
        // setSnackerropen(true);
        // setSnackErrMsg(res.err);
        console.log("Error From Server ==> ", res.msg);
      }
    } catch (error) {
      console.log("Error during   Edit Profile data find  => ", error.message);
    }
  };

  React.useEffect(() => {
    getProfile();
  }, [userID]);

  console.log(profile, "profileprofile");
  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <div>
        <form onSubmit={handleSubmit(ProfileEdit)}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography className="  greycolor505050500 fs20px  mt15px ">
                Profile
              </Typography>
              <Grid
                container
                className="grey-border mt15px"
                sx={{ padding: "15px" }}
              >
                <Typography className="greycolor505050500 fs16px  ">
                  Admin Type *
                </Typography>
                <Input
                  className="inputstyle"
                  disableUnderline
                  value={profile?.role}
                  disabled
                />
                <Typography className="greycolor505050500 fs16px mt10px">
                  First Name
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={firstName}
                  disabled={inputState}
                  {...register("First-Name-ErrorInput", {
                    required: "First Name is required.",
                    value: { firstName },
                    onChange: (e) => {
                      setFirstName(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="First-Name-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Last Name
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={lastName}
                  disabled={inputState}
                  {...register("Last-Name-ErrorInput", {
                    required: "Last Name is required.",
                    value: { lastName },
                    onChange: (e) => {
                      setLastName(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="Last-Name-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Mobile Number
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={phoneNumber}
                  disabled={inputState}
                  {...register("PhoneNo-ErrorInput", {
                    required: "Mobile Number is required.",
                    minLength: 10,
                    maxLength: 10,
                    value: { phoneNumber },
                    onChange: (e) => {
                      setPhoneNumber(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="PhoneNo-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Email
                </Typography>
                <input
                  type="text"
                  className="inputstyle-custom"
                  disableUnderline
                  disabled={inputState}
                  value={email}
                  {...register("Email-ErrorInput", {
                    required: "Email  is required.",
                    // pattern: /^\S+@\S+$/i,
                    value: { email },
                    onChange: (e) => {
                      console.log("Check Email", email);
                      setEmail(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="Email-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Full Address
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={address}
                  disabled={inputState}
                  {...register("address-ErrorInput", {
                    required: "Address is required.",
                    value: { lastName },
                    onChange: (e) => {
                      setAddress(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="address-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  State
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={landmark}
                  disabled={inputState}
                  {...register("State-ErrorInput", {
                    required: "State is required.",
                    value: { landmark },
                    onChange: (e) => {
                      setLandmark(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="State-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />

                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Country
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={country}
                  disabled={inputState}
                  {...register("Country-ErrorInput", {
                    required: "Country is required.",
                    value: { country },
                    onChange: (e) => {
                      setCountry(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="Country-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Pincode
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={pincode}
                  disabled={inputState}
                  {...register("Pincode-ErrorInput", {
                    required: "Pincode is required.",
                    value: { pincode },
                    onChange: (e) => {
                      setPincode(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="Pincode-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography className="greycolor505050500 fs20px  mt15px ">
                Profile picture
              </Typography>
              <Grid
                container
                className="grey-border mt15px"
                sx={{ padding: "15px" }}
              >
                <Grid container>
                  <div className="profile-img">
                    {/* <Stack alignItems="center" spacing={2}>
                    <img
                      src={Man}
                      style={{
                        width: "100%",
                        borderRadius: "70%",
                        height: "140px",
                      }}
                    />
                    <Input
                      type="file"
                      onChange={ImageUploader}
                      style={{ display: "none" }}
                      id="uploadImg"
                    />
                    <label htmlFor="uploadImg">
                      <AiOutlineCamera className="Camera-img" />
                    </label>
                  </Stack> */}
                    <Stack alignItems="center" direction="row">
                      <Avatar
                        className="avatar-bg-color"
                        style={{
                          width: "80%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          borderRadius: "70%",
                          height: "140px",
                        }}
                      >
                        <Typography className="fs50px whitecolortypo">
                          {profile?.firstName?.split(" ")[0][0]}
                          <span className="ml5px ">
                            {profile?.lastName?.split(" ")[0][0]}
                          </span>
                        </Typography>
                      </Avatar>
                    </Stack>
                  </div>
                </Grid>
                <Typography
                  align="center"
                  className="width100 mt10px  greycolor505050500 fs18px"
                >
                  <MdVerifiedUser className="iconcolor " />
                  Verified
                </Typography>
              </Grid>
              <Typography className="greycolor505050500 fs20px  mt15px ">
                eKYC
              </Typography>
              <Grid
                container
                className="grey-border mt15px"
                sx={{ padding: "15px" }}
              >
                <Typography className="greycolor505050500 fs16px  ">
                  Adhar Card Number*
                </Typography>
                <Input
                  className="inputstyle "
                  disableUnderline
                  value={adharCard}
                  disabled={inputState}
                  onChange={(e) => setAdharCard(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <DialogActions className="mb20px">
            {inputState ? (
              <>
                <Button
                  className=" purplebtn"
                  onClick={() => ChangeInputState()}
                >
                  Edit Profile
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="cancelbutton"
                  onClick={() => ChangeInputCancel()}
                >
                  Cancel
                </Button>
                <Button className=" purplebtn" type="submit">
                  Save Changes
                </Button>
              </>
            )}
          </DialogActions>{" "}
        </form>
      </div>
    </>
  );
}
