import React from "react";
import { Typography, Grid, DialogContent, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
//core component that
import IndoorTab from "./Billboard-typeTab/IndoorTab";
import OutdoorTab from "./Billboard-typeTab/Outdoor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
//====================================== Main Funciton ==============================//
export default function Testing({
  formdata,
  handleLocationType,
  handleBillBoardType,
}) {
  const [value, setValue] = React.useState(
    formdata.locationType === "Indoor"
      ? 0
      : formdata.locationType === "Outdoor"
      ? 1
      : -1
  );

  const handleChange = (event, newValue) => {
    console.log("BillBoard Type ==> ", newValue);
    setValue(newValue);
    if (newValue === 0) {
      handleLocationType("Indoor");
    } else if (newValue === 1) {
      handleLocationType("Outdoor");
    }
  };

  //=================================================================================//
  return (
    <>
      <div>
        <DialogTitle className="dialogtitlenno-bg">
          <div className="admin-head">
            <Typography className="whitecolortypo500 fs16px">
              Billboard Type
            </Typography>
          </div>{" "}
        </DialogTitle>
        <DialogContent className="dilaogcontent-billboardEdit">
          <Grid container style={{ backgroundColor: "#FFFFFF" }}>
            <Box sx={{ width: "100%", bgcolor: "background.paper", pt: 2 }}>
              <Typography className="greycolor505050500 fs18px" align="center">
                Select where Billboard is located!
              </Typography>
              <Tabs
                value={value}
                onChange={handleChange}
                centered
                className="TabStyle"
                sx={{ minWidth: "300px !important" }}
              >
                <Tab label="Indoor" className="TabChanges" />
                <Tab label="Outdoor" className="TabChanges" />
              </Tabs>
            </Box>
            <DialogContent>
              <TabPanel value={value} index={0}>
                <IndoorTab
                  formdata={formdata}
                  handleBillBoardType={handleBillBoardType}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <OutdoorTab
                  formdata={formdata}
                  handleBillBoardType={handleBillBoardType}
                />
              </TabPanel>{" "}
            </DialogContent>
          </Grid>
        </DialogContent>
      </div>
    </>
  );
}
