import React, { useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteDilogue from "../../../../component/CustomDeleteDialogue";
import moment from "moment";
import ContentGuidline from "./contentGuidline";
import {
  DialogContent,
  IconButton,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import ConfirMationDialog from "../../../../component/CustomConfirmationDialogue";
//React Icons

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitleConfirmation = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2 }} className="purple-gradient" {...other}>
      {children}
      <Typography className="whitecolortypo">Content Details </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 3,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitleConfirmation.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  getContentDeatil,
  id,
  contentDetail,
  getContent,
  deleteContent,
  value,
  handleApproveRejectContent,
}) {
  const vidRef = useRef();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");
  // SnackBar

  // ==================== States ======================== //
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button
          onClick={() => {
            handleClickOpen();
            getContentDeatil(id);
          }}
          align="center"
          className="burner-details-btn"
          sx={{ padding: "12px" }}
        >
          View Details
        </Button>

        <BootstrapDialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitleConfirmation
            onClose={handleClose}
          ></BootstrapDialogTitleConfirmation>
          <DialogContent>
            <Grid
              container
              gap={1}
              sx={{ padding: "20px 20px" }}
              justifyContent={"space-between"}
            >
              <Grid item xs={12}>
                <div
                  style={{
                    width: "100%",
                    height: "430px",
                    position: "relative",
                    borderRadius: "10px",
                  }}
                >
                  {contentDetail?.fileType == "image/jpeg" ||
                  contentDetail?.fileType == "image/png" ||
                  contentDetail?.fileType == "image/jpg" ? (
                    <img
                      src={contentDetail?.contentLink}
                      width="100%"
                      height="100%"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  ) : (
                    <video
                      controls
                      src={contentDetail?.contentLink}
                      width="100%"
                      height="100%"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Typography className="secondary_heading500 fs20px">
                  For Billboard type :{" "}
                  <span style={{ color: "#E141C3" }}>
                    {" "}
                    {contentDetail?.orientation}
                  </span>
                </Typography>
                <Typography style={{ color: "#5FCA5D" }}>
                  {value === 0 && (
                    <>
                      Please Approve this content only if this Content doesn’t
                      break any guidelines.
                    </>
                  )}
                  {value === 1 && (
                    <>This Content complies with all content guidelines.</>
                  )}
                  {value === 2 && (
                    <> This Content was breaking content guidelines.</>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {/* <Button sx={{ width: "100%" }} className="chipsuccescolorGa">
                  Check out content guideline
                </Button> */}
                <ContentGuidline />
              </Grid>

              <Grid
                item
                mt={2}
                xs={5.8}
                className=" compaign-overview border_primary"
              >
                <Typography className="secondary_heading500 fs20px">
                  Uploaded by :-
                </Typography>
                <Grid container mt={2}>
                  <Grid item md={4} sm={12} xs={4}>
                    <Typography className="secondary_graySubheading_500 fs16px">
                      Date :
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={12} xs={8}>
                    <Typography className="secondary_heading500 fs16px">
                      {moment(contentDetail?.createdAt).format("L")}
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={12} xs={4}>
                    <Typography className="secondary_graySubheading_500 fs16px mt10px">
                      Name :
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={12} xs={8}>
                    <Typography className="secondary_heading500 fs16px mt10px">
                      {contentDetail?.uploadedBy?.firstName}{" "}
                      {contentDetail?.uploadedBy?.lastName}
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={12} xs={4}>
                    <Typography className="secondary_graySubheading_500 fs16px mt10px">
                      Email :
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={12} xs={8}>
                    <Typography className="secondary_heading500 fs16px mt10px">
                      {contentDetail?.uploadedBy?.email}{" "}
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={12} xs={4}>
                    <Typography className="secondary_graySubheading_500 fs16px mt10px">
                      Phone Number :
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={12} xs={8}>
                    <Typography className="secondary_heading500 fs16px mt10px">
                      {contentDetail?.uploadedBy?.mobileNumber}{" "}
                    </Typography>
                  </Grid>
                </Grid>{" "}
              </Grid>
              <Grid
                item
                mt={2}
                xs={5.8}
                className=" compaign-overview border_primary"
              >
                <Typography className="secondary_heading500 fs20px">
                  {value === 0 && <> Approved/Rejected by :-</>}
                  {value === 1 && <>Approved by :-</>}
                  {value === 2 && <>Rejected by :-</>}
                </Typography>
                {value === 0 ? (
                  <>
                    <Grid container mt={2}>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px">
                          Date :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px">
                          not yet
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px mt10px">
                          Name :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px mt10px">
                          not yet
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px mt10px">
                          Email :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px mt10px">
                          not yet
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px mt10px">
                          Phone Number :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px mt10px">
                          not yet
                        </Typography>
                      </Grid>
                    </Grid>{" "}
                  </>
                ) : (
                  <>
                    <Grid container mt={2}>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px">
                          Date :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px">
                          {moment(contentDetail?.statusChangedOn).format("L")}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px mt10px">
                          Name :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px mt10px">
                          {contentDetail?.statusChangedBy?.firstName}{" "}
                          {contentDetail?.statusChangedBy?.lastName}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px mt10px">
                          Email :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px mt10px">
                          {contentDetail?.statusChangedBy?.email}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px mt10px">
                          Phone Number :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px mt10px">
                          {contentDetail?.statusChangedBy?.mobileNumber}
                        </Typography>
                      </Grid>
                    </Grid>{" "}
                  </>
                )}
              </Grid>
              {value === 0 ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <ConfirMationDialog
                        name={"Approve"}
                        id={id}
                        confirmationFun={handleApproveRejectContent}
                        handleCloseDialog={handleClose}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ConfirMationDialog
                        name={"Reject"}
                        id={id}
                        confirmationFun={handleApproveRejectContent}
                        handleCloseDialog={handleClose}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={8}>
                      <Button
                        onClick={handleClickOpen}
                        sx={{ width: "100%" }}
                        className={
                          value === 1
                            ? "chipsuccescolor"
                            : "chipsuccescolorRejected"
                        }
                        disabled
                      >
                        {value === 1 && <>Approved</>}
                        {value === 2 && <>Reject</>}
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <DeleteDilogue
                        deleteFun={deleteContent}
                        id={contentDetail?._id}
                        getContent={getContent}
                        title={"Delete"}
                        contentName={"Content"}
                        handleCloseDialog={handleClose}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}
