import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
  Grid,
  Typography,
  Button,
  Card,
  IconButton,
  CardActions,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";

// ** React Icons
import { FiMail } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

// ** Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// **  Card Icon
import PersonIcon from "@mui/icons-material/Person";
import BillborardAdver4 from "../../../../../assets/Img/noLive.png";
import BILLBOARD from "../../../../../assets/Img/BillBoardImg.png";
import Audience from "../../../../../assets/Img/VenueImg/Average_viewers.png";
import Portrait from "../../../../../assets/Img/VenueImg/Portrait.png";
import Landscape from "../../../../../assets/Img/VenueImg/Landscape.png";
import Time from "../../../../../assets/Img/VenueImg/Time.png";
import Beauty from "../../../../../assets/Img/VenueImg/Beauty.png";
import Education from "../../../../../assets/Img/VenueImg/Education.png";
import Fashion from "../../../../../assets/Img/VenueImg/Fashion.png";
import Financial from "../../../../../assets/Img/VenueImg/Financial.png";
import Fitness from "../../../../../assets/Img/VenueImg/Fitness.png";
import Health from "../../../../../assets/Img/VenueImg/Health.png";
import Hospitality from "../../../../../assets/Img/VenueImg/Hospitality.png";
import Realty from "../../../../../assets/Img/VenueImg/Realty.png";
import RealtyCommercial from "../../../../../assets/Img/VenueImg/RealtyCommercial.png";
import Retail from "../../../../../assets/Img/VenueImg/Retail.png";
import Roads from "../../../../../assets/Img/VenueImg/Roads.png";
import Specialized from "../../../../../assets/Img/VenueImg/Specialized.png";
import MaxAge from "../../../../../assets/Img/VenueImg/Age.png";
import MaxSalary from "../../../../../assets/Img/VenueImg/maxSalary.png";

// Core Component
import Map2 from "../../../HomeMap/GoogleMap/Map";
import { FETCH_URL } from "../../../../../fetchIp.js";
import CarouselForOverview from "./Carousal/Caraousal";
import axiosInstance from "../../../../../api/axiosInstance.js";
import RestartPma from "../../../Edit-Billboard/Restart-pma-api";
import { AuthContext } from "../../../../../Context/AuthContext";
import EditBillboardDialog from "../../../Edit-Billboard/Edit-billboard-Dialog";
import PostButtonComponent from "./PostButtonComponent.js";
import permissionUtils from "../../../../../component/permissionUtils";

const data = [
  {
    Image: BILLBOARD,
    Name: "H&M Lobby Screen 1",
    Name: "up16-dlf-121-Lobby",
    Price: "$5200/sec",
  },
];

//============================ Main component =====================================//
export default function OverviewTab(props) {
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams();
  const access_token = localStorage.getItem("access_token");
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();

  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const [billBoardData, setBillBoardData] = useState({});
  const [views, setViews] = useState([]);
  // set video data live
  const [contentSrc, setContentSrc] = useState("");
  const [contentType, setContentType] = useState(null);
  const [contentMacId, setContentMacId] = useState(null);
  const [ordersArr, setOrdersArr] = useState([]);
  const [orderCountData, setOrderCountData] = useState([]);
  const [Swiperdata, setSwipeData] = useState([]);
  const [macId, setmacId] = useState();
  // restart
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [snackerropen, setSnackerropen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [loading, setLoading] = useState(false);
  const [systemInfo, setSystemInfo] = React.useState([]);
  const [accessPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))?.permission || []
  );
  //  api calling
  const getBillBoardData = async (billId) => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    const response = await fetch(
      `${FETCH_URL}/api/billboard/getbillboardbyid/${
        billId ? billId : params?.billboard_id
      }`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let res = await response.json();
    if (response.ok) {
      setBillBoardData(res?.msg);
      setViews(res?.billboardViews);
      // setChannels([res.msg.deviceId.macId]);
      upcomingAds(res?.msg?.deviceId?._id);
      setmacId(res?.msg?.deviceId?.macId);
      setSystemInfo(
        res?.msg?.deviceId?.appInfo[res?.msg?.deviceId?.appInfo.length - 1]
      );
    } else {
      console.log("Error in ALL Screens ==> ", res);
    }
  };

  const changeBillboardTestingStatus = async (e) => {
    try {
      let body = {
        billboardId: billBoardData?._id,
        status: e.target.checked,
      };
      const res = await axiosInstance.post(
        `/api/billboard/changeTestingState`,
        body
      );
      if (res.status == 200) {
        getBillBoardData();
      } else {
      }
      console.log(res, "after change");
    } catch (err) {}
  };

  // Get Upcoming Ads
  const upcomingAds = async (deviceId) => {
    try {
      let resp = await axiosInstance.get(
        `/api/order/orderListbydeviceId?limit=3&date=${moment().format(
          "YYYY-MM-DD"
        )}&deviceId=${deviceId}&page=${1}&status=All&time=[${moment(
          new Date()
        ).format("HH")}]`
      );
      // console.log("response from getting upcomingAds order =>", resp.data.msg);
      if (resp) {
        setOrdersArr(resp.data.msg);
      }
    } catch (err) {
      console.log("error from getOrder => ", err);
    }
  };
  const orderCount = async () => {
    try {
      let res = await axiosInstance.get(
        `/api/order/getordercountforBillboard/${billBoardData?.id}`
      );
      // console.log(res, "orderCount response");
      if (res.status == 200 || res.status == 201) {
        setOrderCountData(res?.data);
      }
    } catch (error) {
      console.log("error from GET orderCount ", error.message);
    }
  };
  // changes 16-12-2022//
  let arr = [
    { img: Beauty, label: "Beauty & Grooming" },
    { img: Education, label: "Education" },
    { img: Fashion, label: "Fashion" },
    { img: Financial, label: "Financial" },
    { img: Fitness, label: "Fitness" },
    { img: Health, label: "Health" },
    { img: Hospitality, label: "Hospitality" },
    { img: Realty, label: "Realty Residential" },
    { img: RealtyCommercial, label: "Realty Commercial" },
    { img: Retail, label: "Retail" },
    { img: Roads, label: "Roads & Highway" },
    { img: Specialized, label: "Specialized Retail" },
  ];
  useEffect(() => {
    if (billBoardData) {
      let temp = [
        {
          Image: Audience,
          size: views?.[0]?.totalViews,
          name: "Avg Audience Reach ",
        },
        {
          Image: MaxSalary,
          size: billBoardData?.maxSalary,
          name: "Avg Audience Income",
        },
        {
          Image: MaxAge,
          size: billBoardData?.maxAge,
          name: "Avg Audience Age",
        },

        {
          Image: Time,

          size: billBoardData?.timeslotType,
          name: "Operating Hours",
        },
        {
          Image:
            billBoardData?.billboardSize == "1920X1080" ? Landscape : Portrait,
          size:
            billBoardData?.billboardSize == "1920X1080"
              ? "Landscape"
              : "Portrait",
          name: "Orientation",
        },
      ];
      for (let key of temp) {
        if (key?.name == billBoardData?.venueType) {
          return;
        }
      }
      for (let i = 0; i < arr.length; i++) {
        if (billBoardData?.venueType == arr[i].label) {
          temp.push({
            Image: arr[i]?.img,
            size: arr[i]?.label,
            name: arr[i]?.label,
          });
          break;
        }
      }

      setSwipeData(temp);
    }
  }, [billBoardData, views]);

  // const [hasSentMessage, setHasSentMessage] = useState(false);
  // const sendMessage = () => {
  //   if (socket) {
  //     console.log("Check contentSrc", contentSrc, socket);
  //     const message = { event: "getLiveLink", macId: "1ec3d1d4781ba8e6" };
  //     socket.emit("message", message);
  //   }
  // };
  // useEffect(() => {
  //   if (isSocketConnected && !hasSentMessage) {
  //     sendMessage();
  //     setHasSentMessage(true);
  //   }
  // }, [isSocketConnected, hasSentMessage]);

  // useEffect(() => {
  //   if (isSocketConnected && socket) {
  //     const handleGetLiveLink = (message) => {
  //       console.log("Check message", message);
  //       setContentType(message?.filetype);
  //       setContentSrc(message?.contentLink);
  //       setContentMacId(message?.mac_id);
  //     };
  //     socket.on("getLiveLink", handleGetLiveLink);
  //     return () => {
  //       socket.off("getLiveLink", handleGetLiveLink);
  //     };
  //   }
  // }, [isSocketConnected]);

  // console.log("Check isSocketConnected", isSocketConnected);

  useEffect(() => {
    getBillBoardData();
  }, []);
  useEffect(() => {
    if (billBoardData?.id) {
      orderCount();
    }
  }, [billBoardData?.id]);

  // //////////// api to call restart pma app //////////////////////////////////////

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };

  const restartPmaApp = async (macID) => {
    setLoading(true);
    let body = { macId: macId };
    try {
      const res = await axiosInstance.post(
        `api/billboard/restartPmaTvApp`,
        body
      );

      if (res.status == 200) {
        setSnackOpen(true);
        setSnackMsg("Restart Success");
        setLoading(false);

        handleShowSnackbar("success");

        handleShowSnackbar("success");
      } else {
        setSnackErrMsg(res.msg);
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (error) {
      console.log(error, "error");
      handleShowSnackbar("error");
      setLoading(false);
    }
  };

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={severity}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={severity}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid container className="heading-ty_two ">
        <Grid className="headig-ty-left">
          <Typography className="blackcolortypo  fs24px text_transform_capitalize">
            {billBoardData?.billboardName}
          </Typography>
          <Typography className="family-color type-subheading fs16px text_transform_capitalize">
            {billBoardData?.fullAddress}
          </Typography>
        </Grid>

        <Grid item>
          <Grid container gap="20px">
            <RestartPma macId={macId} restartPmaApp={restartPmaApp} />
            {user?.role === "SAdmin" && (
              <>
                <Grid item>
                  {" "}
                  <Grid
                    container
                    alignItems="center"
                    gap="10px"
                    sx={{
                      padding: "6px 10px ",
                      borderRadius: "8px",
                      background: "#D6C2FF",
                    }}
                  >
                    <label class="toggle">
                      <input
                        class="toggle-checkbox"
                        type="checkbox"
                        checked={billBoardData?.isTesting}
                        onClick={(e) => {
                          changeBillboardTestingStatus(e);
                        }}
                      />

                      <div class="toggle-switch"></div>
                    </label>

                    <Typography className="whitecolortypo500">
                      Testing Billboard
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            {isMd && (
              <PostButtonComponent
                billboardId={billBoardData?._id}
                billBoardData={billBoardData}
              />
            )}
            {PermissionCheck("edit-billboard") && (
              <EditBillboardDialog
                getBillBoardData={getBillBoardData}
                AllbillboardData={billBoardData}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item md={8} sm={12}>
          <div className="mt20px">
            <CarouselForOverview billBoardData={billBoardData} />
          </div>

          <Grid container className="mt14px" gap="5px">
            <Grid container item xs={12} alignItems="center" gap="2px">
              <Typography className="blackcolortypo1c1c1c400 fs20px">
                Insight
              </Typography>
              <span style={{ fontSize: "25px" }}>&#8594;</span>
            </Grid>

            <Swiper
              slidesPerView={4}
              spaceBetween={20}
              slidesPerGroup={1}
              loop={false}
              loopFillGroupWithBlank={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
            >
              {Swiperdata?.map((item, index) => (
                <>
                  <SwiperSlide
                    className="border_primary billboard-details-swiper"
                    key={index}
                  >
                    <Grid item align="center" sx={{ height: "150px" }}>
                      <IconButton style={{ width: "50px" }}>
                        <img
                          src={item.Image}
                          style={{ width: "90px", height: "60px" }}
                        />
                      </IconButton>
                      <Typography
                        className="secondary_heading500   fs16px  pt5px"
                        align="center"
                      >
                        {item.size}
                      </Typography>
                      <Typography
                        align="center"
                        className="greycolor505050500 fs14px pb-10px "
                      >
                        {item.name}
                      </Typography>
                    </Grid>
                  </SwiperSlide>
                </>
              ))}
            </Swiper>
          </Grid>

          {/* ======================================================================== */}
          <Grid container justifyContent="space-between" className="mt20px">
            <Grid
              container
              direction="column"
              rowGap="10px"
              item
              xs={12}
              sm={5.8}
              md={5.8}
              className=" compaign-overview border_primary"
            >
              <Typography className="secondary_graySubheading_500 fs16px mt25px">
                About Billboard
              </Typography>
              <Typography className="secondary_heading500 fs16px ">
                {billBoardData?.billboardName}
              </Typography>
              <Typography className="secondary_heading500   fs16px">
                {billBoardData?.fullAddress}
              </Typography>
              <Typography className="secondary_heading500  fs16px">
                {billBoardData?.city}
              </Typography>
              <Typography className="secondary_heading500 fs16px ">
                {billBoardData?.state}
              </Typography>
              <Typography className="secondary_heading500 fs16px ">
                {billBoardData?.pincode}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5.8}
              md={5.8}
              className=" compaign-overview border_primary"
            >
              <Grid container className="mt25px">
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px">
                    Base price
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px">
                    Rs.{billBoardData?.basePrice}/sec
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Operating Hours{" "}
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {billBoardData?.timeslotType}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Orientation
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {billBoardData?.billboardSize}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Type
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {billBoardData?.billboardType}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Venue
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {billBoardData?.venueType}
                    {/* 24Hr. */}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Sub-Venue
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {billBoardData?.venueTags}
                    {/* 24Hr. */}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            className="border-bottom pb20px mt20px"
          >
            <Grid
              item
              xs={12}
              md={5.8}
              className=" compaign-overview border_primary"
            >
              <Grid container className="mt25px">
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px">
                    Added on
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px">
                    {billBoardData?.createdAt?.split("T")[0]}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Added by
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="purplecolortypo mt10px">
                    {billBoardData && billBoardData?.addedBy?.name
                      ? billBoardData?.addedBy?.name
                      : NaN}
                    {/* Loreum ipsum */}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Owner
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {/* Saps.ai */}
                    {billBoardData && billBoardData?.billboardOwner}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={5.8}
              className=" compaign-overview border_primary"
            >
              <Grid container className="mt10px">
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography className="blackcolortypo  fs16px ">
                    Device
                  </Typography>
                  <div>
                    {" "}
                    <Grid
                      container
                      gap="5px"
                      alignItems="center"
                      sx={{
                        border:
                          billBoardData?.deviceId?.deviceStatus == "Online"
                            ? "1px solid green"
                            : "1px solid red",
                        borderRadius: "8px",
                        padding: "5px",
                      }}
                    >
                      {billBoardData?.deviceId?.deviceStatus == "Online" ? (
                        <>
                          {" "}
                          <span className="online_dot"></span>{" "}
                          <Typography className="secondary_heading500 fs16px">
                            Online
                          </Typography>
                        </>
                      ) : (
                        <>
                          <span className="onffine_dot"></span>{" "}
                          <Typography className="secondary_heading500 fs16px">
                            Offline
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </div>
                </Grid>

                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Device ID
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {billBoardData &&
                      billBoardData?.deviceId?.deviceUID &&
                      billBoardData?.deviceId?.deviceUID}
                    {/* #12ax465bsh{" "} */}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Device Name
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {billBoardData?.deviceId?.deviceName}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Device Sensors
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    None
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container rowGap="10px" justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={5.8}
              className="mt20px compaign-overview border_primary"
            >
              <Typography
                component="h5"
                className="blackcolortypo fs20px mt10px"
              >
                About
              </Typography>
              <Typography className="greycolor505050400 mt10px text_transform_capitalize">
                {billBoardData?.aboutBillboard}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={5.8}
              className="mt20px compaign-overview border_primary"
            >
              <Typography
                component="h5"
                className="blackcolortypo fs20px mt10px"
              >
                System Info
              </Typography>

              <Grid container>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Brand
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.brand ? systemInfo?.brand : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Model
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.model ? systemInfo?.model : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    System Name
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.systemName ? systemInfo?.systemName : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    System Version
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.systemVersion
                      ? systemInfo?.systemVersion
                      : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Bundle ID
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?._id ? systemInfo?._id : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    App Name
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.appName ? systemInfo?.appName : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Build Number
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.buildNumber ? systemInfo?.buildNumber : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Version
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.version ? systemInfo?.version : "---"}
                  </Typography>
                </Grid>

                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    IP Address
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.ipAddress ? systemInfo?.ipAddress : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Resolution width
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.resolutionHeight
                      ? systemInfo?.resolutionWidth
                      : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Resolution Height
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.resolutionHeight
                      ? systemInfo?.resolutionHeight
                      : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Device Width
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.deviceWidth ? systemInfo?.deviceWidth : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Device Height
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.deviceHeight
                      ? systemInfo?.deviceHeight
                      : "---"}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Latitude
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {billBoardData?.lattitude}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Longitude
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {billBoardData?.longitude}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={4}>
                  <Typography className="secondary_graySubheading_500 fs16px mt10px">
                    Name
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={8}>
                  <Typography className="secondary_heading500 fs16px mt10px">
                    {systemInfo?.location?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={5.8}
              className="mt20px compaign-overview border_primary"
            >
              <Typography
                component="h5"
                className="blackcolortypo fs20px mt10px"
              >
                Specification
              </Typography>
              <Typography className="greycolor505050400 mt10px text_transform_capitalize">
                {billBoardData?.specification}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5.8}
              className="mt20px compaign-overview border_primary"
            >
              <Typography
                component="h5"
                className="blackcolortypo fs20px mt10px"
              >
                Supported Resolution
              </Typography>
              <Typography className="greycolor505050400 mt10px text_transform_capitalize">
                {billBoardData?.supportedResolution}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5.8}
              className="mt20px compaign-overview border_primary"
            >
              <Typography
                component="h5"
                className="blackcolortypo fs20px mt10px"
              >
                Guidelines
              </Typography>
              <Typography className="greycolor505050400 mt10px text_transform_capitalize">
                {billBoardData?.guidelines}
              </Typography>
            </Grid>
          </Grid>

          <Typography component="h5" className="blackcolortypo fs20px mt10px">
            Location
          </Typography>
          <Grid container className=" mt10px">
            <Grid item lg={12} xs={12}>
              <div>
                {Object.keys(billBoardData)?.length > 0 && (
                  <Map2 data={billBoardData} singleBill="true" />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4} sm={12} className="mt20px">
          {!isMd && (
            <PostButtonComponent
              billboardId={billBoardData?._id}
              billBoardData={billBoardData}
            />
          )}
          <Grid item md={12}>
            {data &&
              data.map((item, index) => {
                return (
                  <>
                    <Card
                      sx={{
                        width: "100%",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                      }}
                      className=" mt20px box_shadow_primary"
                      key={index}
                    >
                      {contentSrc &&
                      (contentType === "video/mp4" ||
                        contentType === "video/webm") &&
                      billBoardData?.deviceId?.macId == contentMacId ? (
                        <video
                          src={contentSrc}
                          autoPlay={true}
                          loop
                          className="width100"
                          controls
                          style={{ height: "300px", objectFit: "contain" }}
                        />
                      ) : contentSrc &&
                        contentType === "url" &&
                        billBoardData?.deviceId?.macId == contentMacId ? (
                        <ReactPlayer
                          playing
                          width={"100%"}
                          style={{ height: "300px", objectFit: "contain" }}
                          url={contentSrc}
                          muted={true}
                          autoplay={true}
                          loop={true}
                        />
                      ) : contentSrc &&
                        (contentType === "image/jpeg" ||
                          contentType === "image/png") &&
                        billBoardData?.deviceId?.macId == contentMacId ? (
                        <img
                          component="img"
                          height="auto"
                          style={{
                            height: "300px",
                            objectFit: "contain",
                            width: "100%",
                          }}
                          src={contentSrc}
                          alt="live Order Img"
                        />
                      ) : (
                        <img
                          component="img"
                          height="auto"
                          style={{ width: "100%" }}
                          src={BillborardAdver4}
                          alt="No live Order"
                        />
                      )}
                      <CardActions
                        sx={{ marginTop: "-10px" }}
                        className="campaign-billboardaction-2 p15px"
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          direction="row"
                        >
                          <Grid item>
                            <Typography className="greycolor505050500 ">
                              Live Ad
                            </Typography>
                          </Grid>
                        </Grid>
                        {/* <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          className="width-LR-96"
                        >
                          <StopAdDialogue stoptheAds={stopAds} />
                        
                        </Grid> */}
                      </CardActions>
                    </Card>
                  </>
                );
              })}
          </Grid>
          <Grid
            container
            className="box-shadow bg-white pb20px mt30px b-r-8px "
          >
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              className="mt10px width-LR-90 "
            >
              <Grid item>
                <Typography className="greycolor505050500 fs20px">
                  Upcoming Ads
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  className="purplecolortypo fs16px"
                  sx={{ textTransform: "inherit" }}
                  onClick={() => navigate("/admin/order")}
                >
                  See all
                </Button>
              </Grid>
            </Grid>

            {ordersArr?.length > 0 ? (
              ordersArr?.map((a, i) => {
                return (
                  <Grid
                    container
                    className="box-shadow bg-white b-r-8px mt20px width-LR-90  "
                    key={i}
                  >
                    <Grid item md={12} sm={12} className="">
                      <Grid item className="relative">
                        <Grid item className="orderbtn-itemL">
                          <Button className=" mt--10px order-green-btn  width-30  min-width-120px ">
                            {a?.status}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item className="relative ">
                        <Grid item className="orderbtn-itemR">
                          <Button
                            onClick={() =>
                              navigate(
                                `${
                                  a?.isCampaign
                                    ? `/admin/campaignmgt/${a._id}`
                                    : `/admin/order/${a._id}`
                                }`,
                                {
                                  state: { a },
                                }
                              )
                            }
                            className="order-purple-btn   width-20  min-width-120px "
                          >
                            View
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container className="p10px">
                        <Typography className="greycolor505050400 mt35px width100">
                          Order id{" "}
                          {a?._id && a?._id.substring(18, a?._id.length - 1)}
                        </Typography>
                        <Typography className="blackcolortypo mt10px width100">
                          {a?.adTitle}
                        </Typography>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="mt10px"
                        >
                          <Grid item>
                            <Typography className="greycolor505050500">
                              {/* 05th April 2022 */}
                              {a?.orderDate && a?.orderDate.substring(0, 10)}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography className="greycolor505050500">
                              {/* 04:00 - 05:00 PM */}
                              {a?.timeSlot && a?.timeSlot}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography className="greycolor505050500">
                              Duration:{" "}
                              {a?.videoDuration && a?.videoDuration / 60}
                              min
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <h1
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                No data
              </h1>
            )}

            {/* {ordersArr.length > 0 ? ordersArr.map((item) => {
              console.log("jnkhbgvuyihjkb")
            })
          :
          <h1>No data</h1>
          } */}
          </Grid>

          <Grid
            container
            className="b-r-8px  mt20px"
            style={{
              backgroundColor: "#ffffff",
              border: "1px solid #dddddd",
            }}
            gap="15px"
          >
            <Grid
              item
              className="purplebtn-no-radious width100 "
              style={{
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <Typography
                align="center"
                className="whitecolortypo500 "
                sx={{ padding: "5px" }}
              >
                Help
              </Typography>
            </Grid>
            <Grid
              container
              item
              className=" width100 "
              gap="5px"
              direction="row"
            >
              <Typography className="greycolor505050500 fs20px ml10px">
                Authority details:
              </Typography>

              <Grid container item alignItems="center">
                <IconButton className="fs25px">
                  <PersonIcon />
                </IconButton>{" "}
                <Typography className="blackcolor484848  ml10px">
                  {billBoardData?.addedBy?.name
                    ? billBoardData?.addedBy?.name
                    : billBoardData?.addedBy?.firstName
                    ? `${billBoardData?.addedBy?.firstName} ${billBoardData?.addedBy?.lastName}`
                    : NaN}
                </Typography>
              </Grid>
              <Grid container item alignItems="center">
                <IconButton className="fs25px">
                  <FiMail />
                </IconButton>{" "}
                <Typography className="blackcolor484848  ml10px">
                  {billBoardData?.addedBy?.email}
                </Typography>
              </Grid>
              <Grid container item alignItems="center">
                <IconButton className="fs25px">
                  <BsTelephone />{" "}
                </IconButton>
                <Typography className="blackcolor484848  ml10px">
                  {billBoardData?.addedBy?.mobileNumber}
                </Typography>
              </Grid>
              {/* <Grid container item sx={{ marginBottom: "10px" }}>
                <Button className="purplebtn-no-radious  br-15px   width-90LR ">
                  Contact
                </Button>{" "}
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
