import React, { useState } from "react";
import {
  Button,
  Step,
  Stepper,
  Box,
  StepLabel,
  Typography,
  Grid,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
//React Icons
import { IoChevronBack } from "react-icons/io5";
//core components that
import Sidebar from "../../../component/Sidebar/Sidebar";
import BillBoard from "./BillBoardDetails-Step/billboard-Step";
import BillBoardTypo from "./Bill-BoardType/billboard-type";
import Venue from "./Venue/Venue";
import TimeSlots from "./TimeSlots/TimeSlots";
import Sumbit from "./Submit/Submit";
import axios from "../../../api/axiosInstance";
import { FETCH_URL } from "../../../fetchIp";
import SuccessDialog from "./utils/Dialog/SuceedFullDialog";
import WrongDialog from "./utils/Dialog/WrongDialog";
import axiosInstance from "../../../api/axiosInstance";
const steps = ["Billboard Details", "Billboard Type", "Venue", "Submit"];
export default function Clusterhead({
  AllbillboardData,

  handleCloseFinal,
  getBillBoardData,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  // Success and Error Handler
  const [openDialogName, setOpenDialog] = React.useState(null);
  const Image = AllbillboardData?.filesArr;
  const [failedMsg, setFailedMsg] = useState("");
  //=========================== For Media Upload by Aman ===============================//
  const [uniqueThumbName, setUniqueThumbName] = useState("");
  const [thumNailCheck, setThumbNailCheck] = useState(false);
  const [thumbNail, setThumbNail] = useState(AllbillboardData?.thumbnail);
  const handleThumbnail = (file, bool) => {
    const uniqueName = `thumbnail_${Date.now()}_${file?.name}`;
    setUniqueThumbName(uniqueName);
    setThumbNailCheck(bool);
    setThumbNail(file);
  };
  const [file, setFile] = React.useState({
    file1: Image[0]?.fileurl,
    file2: Image[1]?.fileurl,
    file3: Image[2]?.fileurl,
    file4: Image[3]?.fileurl,
    file5: Image[4]?.fileurl,
    file6: Image[5]?.fileurl,
  });
  const [showImagefile, setShowImageFile] = React.useState({
    file1: Image[0]?.fileurl,
    file2: Image[1]?.fileurl,
    file3: Image[2]?.fileurl,
    file4: Image[3]?.fileurl,
    file5: Image[4]?.fileurl,
    file6: Image[5]?.fileurl,
  });
  // ================= STATES Added by Rahul ==================== //
  // validation form
  const [formErrors, setFormErrors] = React.useState({});
  const [fileErrors, setFileErrors] = React.useState({});
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [isSubmit1, setIsSubmit1] = React.useState(false);
  const [deviceIdBill, setdeviceIdBill] = useState(AllbillboardData?.deviceId);
  const [formdata, setFormdata] = React.useState({
    billboardName: AllbillboardData?.billboardName ?? " ",
    billboardOwner: AllbillboardData?.billboardOwner ?? " ",
    billboardSize: AllbillboardData?.billboardSize ?? " ",
    fullAddress: AllbillboardData?.fullAddress ?? " ",
    deviceId: deviceIdBill?._id ?? null,
    billboardId: AllbillboardData?.billboardId ?? " ",
    landmark: AllbillboardData?.landmark ?? " ",
    pincode: AllbillboardData?.pincode ?? " ",
    city: AllbillboardData?.city ?? " ",
    district: AllbillboardData?.district ?? " ",
    state: AllbillboardData?.state ?? " ",
    country: AllbillboardData?.country ?? " ",
    aboutBillboard: AllbillboardData?.aboutBillboard ?? " ",
    lattitude: AllbillboardData?.lattitude ?? 28.6448,
    longitude: AllbillboardData?.longitude ?? 77.216721,
    billboardType: AllbillboardData?.billboardType ?? "",
    locationType: AllbillboardData?.locationType ?? "",
    venueType: AllbillboardData?.venueType ?? [],
    venueTags: AllbillboardData.venueTags ?? [],
    timeslotType: AllbillboardData?.timeslotType ?? "",
    basePrice: String(AllbillboardData?.basePrice) ?? "",
    maxSalary: AllbillboardData?.maxSalary ?? "",
    maxAge: AllbillboardData?.maxAge ?? "",
    specification: AllbillboardData?.specification ?? "",
    supportedResolution: AllbillboardData?.supportedResolution ?? "",
    guidelines: AllbillboardData?.guidelines ?? "",
  });
  // Validating Error
  const validate = (values) => {
    const errors = {};
    let numeric = /^\d+$/;
    if (!thumbNail) {
      errors.thumbNail = "ThumbNail is required !";
    }
    if (!values.deviceId) {
      errors.deviceId = "Device Id are required !";
    }
    if (!values.guidelines) {
      errors.guidelines = "Guidelines are required !";
    }
    if (!values.maxAge) {
      errors.maxAge = "maxAge are required !";
    }
    if (!values.maxSalary) {
      errors.maxSalary = "maxSalary are required !";
    }
    if (!values.supportedResolution) {
      errors.supportedResolution = "Supported Content are required !";
    }
    if (!values.specification) {
      errors.specification = "Specification are required !";
    }
    if (!values.billboardName) {
      errors.billboardName = "Billboard Name is required !";
    }
    if (!values.billboardOwner) {
      errors.billboardOwner = "Billboard Owner is required !";
    }

    if (!values.billboardSize) {
      errors.billboardSize = "Billboard Size is required !";
    }

    if (!values.landmark) {
      errors.landmark = "Landmark type is required !";
    }
    if (String(values.pincode).length !== 6) {
      errors.pincode = "Pincode should have 6 digits !";
    }
    if (!values.pincode) {
      errors.pincode = "Pincode is required !";
    } else if (!numeric.test(values.pincode)) {
      errors.pincode = "Pincode must be a number !";
    }

    if (!values.fullAddress) {
      errors.fullAddress = "Full Address is required !";
    }

    if (!values.state) {
      errors.state = "State is required !";
    }

    if (!values.city) {
      errors.city = "City is required !";
    }

    if (!values.billboardId || values.billboardId?.length < 3) {
      errors.billboardId = "Minimum length of Billboard Id is atlest 3 !";
    }

    if (!values.lattitude) {
      errors.lattitude = "Mark position on Map !";
    }

    if (!values.country) {
      errors.country = "Country is required !";
    }

    if (!values.aboutBillboard) {
      errors.aboutBillboard = "About Billboard is required !";
    }
    if (activeStep == 1) {
      if (!values.billboardType) {
        errors.billboardType = "Billboard type is required !";
      }
    }
    if (activeStep == 2) {
      if (!values?.venueType || values?.venueTags?.length == 0) {
        errors.venueType = "Venue type is required !";
      }
    }

    return errors;
  };
  const validateFile = (values) => {
    const filter = Object.values(values);
    const filterData = filter.filter((a) => a !== undefined && a !== null);
    console.log(filterData, "filterData");
    const errors = {};
    if (
      (!values.file1 &&
        !values.file2 &&
        !values.file3 &&
        !values.file4 &&
        !values.file5 &&
        !values.file6) ||
      filterData.length < 3 ||
      (filterData?.filter((a) => a.type == "image/jpeg")?.length == 0 &&
        !filterData.some((url) => /\.(jpg|jpeg)$/i.test(url)))
    ) {
      errors.file = "At least three media (Atleast One Image) are required !";
      errors.id = "image_id";
    }
    return errors;
  };
  // ============== Function added by Rahul ============= //
  const handleChange = (e) => {
    if (e.target.name === "pincode") {
      setFormdata({ ...formdata, [e.target.name]: e.target.value, city: null });
    } else {
      setFormdata({ ...formdata, [e.target.name]: e.target.value });
    }
  };
  const handleCountryStateChange = (
    countryValue,
    stateValue,
    districtValue
  ) => {
    // console.log("Country ==> ", value)
    setFormdata({
      ...formdata,
      country: countryValue,
      state: stateValue,
      district: districtValue,
    });
  };
  const handleDeviceId = (value) => {
    // console.log("Add Billboard DeviceID ===> ", value)
    setFormdata({ ...formdata, deviceId: value });
  };

  const handleFormsubmit = () => {
    setFileErrors(validateFile(file));
    setFormErrors(validate(formdata));
    // setIsSubmit1(!isSubmit1)
    if (activeStep === 0) {
      // console.log("FORM Submission ==> ", activeStep)
      setIsSubmit(true);
    }
  };

  const handleFileSubmit = (fileNumber, Originalfile) => {
    if (fileNumber === 1) {
      setFile({ ...file, file1: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file1: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }

    if (fileNumber === 2) {
      setFile({ ...file, file2: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file2: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }

    if (fileNumber === 3) {
      setFile({ ...file, file3: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file3: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }
    if (fileNumber === 4) {
      setFile({ ...file, file4: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file4: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }
    if (fileNumber === 5) {
      setFile({ ...file, file5: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file5: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }
    if (fileNumber === 6) {
      setFile({ ...file, file6: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file6: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }
  };

  const handleLatLong = (lattitudeValue, longitudeValue) => {
    // console.log("FORM DATA LATLONG => ", number, value)
    setFormdata({
      ...formdata,
      lattitude: lattitudeValue,
      longitude: longitudeValue,
    });
  };

  const handleLocationType = (value) => {
    setFormdata({ ...formdata, locationType: value });
  };

  const handleBillBoardType = (value) => {
    // console.log("Billboard Type ===>", value)
    setFormdata({ ...formdata, billboardType: value });
  };

  const handleVenueType = (value) => {
    // console.log("Venue Type of Add-Bill-Board.js ===>", value)
    setFormdata({ ...formdata, venueType: value });
  };

  const handleTags = (value) => {
    setFormdata({ ...formdata, venueTags: value });
  };

  ////////////////////////////////////////////////////////////////////////////////////

  // =================== useEffect to validate form data ====================== //
  React.useEffect(() => {
    if (
      Object.keys(formErrors).length === 0 &&
      isSubmit &&
      Object.keys(fileErrors).length === 0
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (isSubmit1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [formErrors]);
  const billboardID = AllbillboardData._id;
  // =============================== Create Billboard Function ========================= //
  const createBillboard = async () => {
    setOpenDialog(null);
    try {
      let body = formdata;
      console.log("Create Billboard Body  Send Backend==> ", body);
      let res = await axios.post(
        `/api/billboard/updateBillboard/${billboardID}`,
        body
      );
      if (res.status === 200) {
        imageUploadHandler(billboardID);
        setOpenDialog("success");
      } else {
        setFailedMsg(res?.response?.data?.msg);
        setOpenDialog("reject");
      }
      console.log("Check Billboard Edit Response", res.data.msg);
    } catch (error) {
      console.log("ERROR FROM CreateBillboard ==> ", error.message);
      setFailedMsg(error?.response?.data?.msg);
      setOpenDialog("reject");
    }
  };

  //============================ Image Upload Handler by Aman ==============================//
  const imageValueArr = Object.values(showImagefile);
  let common = ["https://s3.ap-south-1.amazonaws.com"];
  let awnLink = [];
  for (let i = 0; i < imageValueArr?.length; i++) {
    if (
      imageValueArr?.[i] !== null &&
      imageValueArr?.[i] !== undefined &&
      common.some((word) => imageValueArr?.[i].toLowerCase()?.includes(word))
    ) {
      awnLink.push(i);
    }
  }
  const thumbnailHandler = async (id) => {
    console.log(uniqueThumbName, thumbNail, "check for error");
    try {
      const formData = new FormData();
      formData.append("uniqueFileName", uniqueThumbName);
      formData.append("billboardId", id);
      formData.append("file", thumbNail);
      const res = await axiosInstance.post(
        `/api/billboard/uploadThumnail`,
        formData
      );
      if (res.status == 200) {
        console.log("//====  Image Has Been Uploaded  ===//");
        getBillBoardData(billboardID);
      } else {
        console.log("error in handle off during Image Upload ==> ", res.msg);
      }
    } catch (err) {
      console.log("error in handle off during Image Upload ==> ", err);
    }
  };
  const imageUploadHandler = async (id) => {
    const formdata = new FormData();
    formdata.append("billboardId", id);
    formdata.append("edit", awnLink.join(","));
    formdata.append("file", file.file1);
    formdata.append("file", file.file2);
    formdata.append("file", file.file3);
    formdata.append("file", file.file4);
    formdata.append("file", file.file5);
    formdata.append("file", file.file6);

    if (id) {
      const response = await fetch(
        `${FETCH_URL}/api/billboard/uploaddevicefile`,
        {
          method: "POST",
          body: formdata,
        }
      );
      let res = await response.json();
      if (response.ok) {
        if (thumNailCheck) {
          thumbnailHandler(billboardID);
        } else {
          getBillBoardData(billboardID);
        }
        console.log("//====  Image Has Been Uploaded  ===//");
      } else {
        console.log("error in handle off during Image Upload ==> ", res.msg);
      }
    }
  };

  //============================ Billboard Price Create ==============================//

  //===============================  ALL Form Elements here ================================//
  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <BillBoard
            formdata={formdata}
            AllbillboardData={AllbillboardData}
            handleChange={handleChange}
            handleCountryStateChange={handleCountryStateChange}
            formErrors={formErrors}
            fileErrors={fileErrors}
            checkFile={file}
            handleDeviceId={handleDeviceId}
            handleFileSubmit={handleFileSubmit}
            handleLatLong={handleLatLong}
            billBoardData={AllbillboardData}
            thumbNail={thumbNail}
            handleThumbnail={handleThumbnail}
            setdeviceIdBill={setdeviceIdBill}
            deviceIdBill={deviceIdBill}
          />
        );
      case 1:
        return (
          <BillBoardTypo
            formdata={formdata}
            handleLocationType={handleLocationType}
            handleBillBoardType={handleBillBoardType}
          />
        );
      case 2:
        return (
          <Venue
            formdata={formdata}
            handleVenueType={handleVenueType}
            handleTags={handleTags}
          />
        );

      case 3:
        return (
          <Sumbit
            formdata={formdata}
            file={file}
            showImagefile={showImagefile}
            AllbillboardData={AllbillboardData}
          />
        );
    }
  }

  const handleNext = () => {
    console.log("activestep ===> ", activeStep);
    if (activeStep === 0) {
      handleFormsubmit();
    }

    if (activeStep === 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (activeStep == 3) {
      console.log("Active Step is 3");
      createBillboard();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Box className="width100">
        <div className="flex-class">
          <div className="width-20">
            <Grid
              container
              direction="row"
              alignItems="center"
              sx={{ paddingLeft: "26px", marginTop: "10px" }}
            >
              <Typography className="cursor pt7px greycolortypo6F6F6F fs20px">
                Edit Billboard
              </Typography>{" "}
            </Grid>
          </div>
          <div className="width100">
            <Stepper
              sx={{ marginLeft: "70px" }}
              activeStep={activeStep}
              alternativeLabel
              className=" stepper "
            >
              {steps.map((data, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={data} {...stepProps}>
                    <StepLabel className="step" {...labelProps}>
                      {data}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>{" "}
          </div>
        </div>
        <div className="stepmain-div94 ">
          {/* ====================== Rendering of element ========================= */}
          <div
            style={{
              border: "1px solid #dddddd",
              borderRadius: "8px",
              paddingBottom: "20px",
            }}
          >
            {_renderStepContent(activeStep)}{" "}
          </div>
          <DialogActions style={{ padding: "0px", margin: "0px" }}>
            <React.Fragment>
              <Box className="stepmain-box ">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  className="mt10px p-tb20-lr10"
                >
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 4 }}
                    className={activeStep ? "previousbtn " : null}
                  >
                    Previous
                  </Button>

                  {activeStep == 3 ? null : (
                    <Button
                      onClick={handleNext}
                      // className="purplebtn"
                      // className={activeStep ? "purplebtn " : null}
                      className={
                        activeStep === steps.length - 1 ? null : "purplebtn"
                      }
                    >
                      {/* {activeStep === steps.length - 1 ? null : "Next"} */}
                      {activeStep == 3 ? null : "next"}
                    </Button>
                  )}

                  {activeStep == 3 && (
                    <Button
                      onClick={handleNext}
                      className={
                        activeStep === steps.length - 1 ? "purplebtn" : null
                      }
                    >
                      Submit
                    </Button>
                  )}
                </Grid>
              </Box>
            </React.Fragment>{" "}
          </DialogActions>
        </div>{" "}
        <SuccessDialog
          openData={openDialogName === "success"}
          data={"Billboard Added"}
          parentNavigate={true}
          closeModal={handleCloseFinal}
        />
        <WrongDialog openData={openDialogName === "reject"} data={failedMsg} />
      </Box>
    </>
  );
}
