import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Box,
  IconButton,
  Typography,
  TextField,
  MenuItem,
  FormControl,
} from "@mui/material";
// table import
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import axiosInstance from "../../../../../api/axiosInstance";
import moment from "moment";
import { useCampaignController } from "../../../../../Context/campaignBook";
// table for billboard
const headCells = [
  {
    id: "UID",
    numeric: true,
    label: "UID",
  },
  {
    id: "Billboard Name",
    numeric: true,
    disablePadding: false,
    label: "Billboard Name  ",
  },
  {
    id: "Price",
    numeric: true,
    disablePadding: false,
    label: "Price ",
  },
  {
    id: "Date",
    numeric: true,
    disablePadding: false,
    label: "Date   ",
  },
  {
    id: "TimeSlot",
    numeric: true,
    disablePadding: false,
    label: "TimeSlot   ",
  },
  {
    id: "Duration",
    numeric: true,
    disablePadding: false,
    label: "Duration   ",
  },
  {
    id: "Repetition",
    numeric: true,
    disablePadding: false,
    label: "Repetition ",
  },

  {
    id: "Total Amount",
    numeric: true,
    disablePadding: false,
    label: "Total Amount",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default function ViewPriceUpDialogue({
  startdate,
  repeat,
  billBoardArr,
  starttime,
  enddate,
  selectSecond,
  timeSlots,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [campaignController, dispatch] = useCampaignController();
  const { adDuration } = campaignController;

  const [selectDate, setSelectDate] = useState(startdate);
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [multiTimeSolts, setMultiTimeSolts] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [timeSlotsPrice, setTimeSlotsPrice] = useState([]);
  // pagination
  const [billboardTableList, setBillboardTableList] = useState([]);
  // paginations

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getBillboardTableData = async () => {
    // console.log(adDuration, multiTimeSolts, billBoardArr);

    if ((adDuration, multiTimeSolts, billBoardArr)) {
      try {
        let body = {
          billboardIds: billBoardArr,
          multiTimes: multiTimeSolts,
          videoDuration: adDuration,
        };
        const res = await axiosInstance.post(
          `api/billboard/getPriceBreakDown`,
          body
        );
        if (res.status === 200) {
          setBillboardTableList(res?.data?.data);
          let dataTimeSlots = res?.data?.data?.startHour;
          dataTimeSlots?.map((data) => {
            if (data?.time < 4) {
              if (data.time === 0) data["actualTime"] = "12am - 1am";
              else data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
            } else if (data?.time > 3 && data?.time < 8) {
              data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
            } else if (data.time > 7 && data.time < 12) {
              if (data.time === 11) data["actualTime"] = "11am - 12pm";
              else data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
            } else if (data.time > 11 && data.time < 16) {
              if (data.time === 12)
                data["actualTime"] = `12pm - ${data.time - 12 + 1}pm`;
              else
                data["actualTime"] = `${data.time - 12}pm - ${
                  data.time - 12 + 1
                }pm`;
            } else if (data.time > 15 && data.time < 20) {
              data["actualTime"] = `${data.time - 12}pm - ${
                data.time - 12 + 1
              }pm`;
            } else if (data.time > 19) {
              if (data.time === 23)
                data["actualTime"] = `11pm - ${data.time - 12 + 1}am`;
              else
                data["actualTime"] = `${data.time - 12}pm - ${
                  data.time - 12 + 1
                }pm`;
            }
          });
          setTimeSlotsPrice(dataTimeSlots || []);
          console.log(res, "success res after api call ");
        } else {
          // console.log(res, "failed res after api call ");
        }
      } catch (err) {
        console.log(err, "error in price api call ");
      }
    }
    return;
  };
  useEffect(() => {
    getBillboardTableData();
  }, [multiTimeSolts, adDuration]);

  const [timeString, setTimeString] = useState(0);

  function getTimeString(number) {
    let num = +number;
    console.log("timeString", num);
    if (num === 12) {
      return "12PM - 1PM";
    } else if (num === 24) {
      return "12AM - 1AM";
    } else if (num > 12) {
      return num - 12 + "PM - " + (num - 11) + "PM";
    } else {
      return num + "AM - " + (num + 1) + "AM";
    }
  }

  useEffect(() => {
    if (starttime) {
      setTimeString(getTimeString(moment(starttime).format("HH")));
    }
  }, [starttime]);

  useEffect(() => {
    console.log(">>>>>>>>>>>>>>>", timeSlots);
    let newValue = [];
    Object.keys(timeSlots)?.map((item) => {
      timeSlots[item]?.map((itemObj) => {
        newValue.push({ ...itemObj, date: item });
      });
    });
    console.log(newValue, "uuuuuuuuuuuuuuuuuuiiooo");

    let multi = [];

    newValue.forEach((item, index) => {
      multi.push({
        date: item?.date,
        startHour: item?.time,
        repetitions: item?.repetition,
        actualTime: item?.actualTime,
      });
    });
    console.log("sum sum", multi);

    setMultiTimeSolts(multi);
  }, [timeSlots]);

  //dropdown dates

  const startDate = new Date(startdate && startdate);
  const endDate = new Date(enddate && enddate);
  const dates = [];
  // Set start date to midnight
  startDate.setHours(0, 0, 0, 0);
  while (startDate <= endDate) {
    dates.push(new Date(startDate));
    startDate.setDate(startDate.getDate() + 1);
  }

  useEffect(() => {
    console.log("LLLLLLLLLLLLL", selectDate);
  }, [selectDate]);

  //dropdown dates
  return (
    <div>
      <Button
        sx={{ padding: "0", textTransform: "inherit" }}
        className="purplecolortypo fs14px"
        onClick={handleClickOpen}
      >
        View price breakup
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"lg"}
        PaperProps={{
          style: {
            maxHeight: 600, // set the maximum height to 400px
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container justifyContent="space-between" sx={{ padding: "15px" }}>
          <Typography className="blackcolortypo1c1c1c400 fs16px font-Lato ">
            Showing{" "}
            {billboardTableList?.length ? billboardTableList?.length : 0}{" "}
            Billboard price breakdown
          </Typography>
          <Grid item>
            <Grid item sx={{ width: "130px" }}>
              <FormControl
                className="MainPageFormControl grey-border"
                size="small"
              >
                <TextField
                  placeholder="hello"
                  select
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  className="Selectdropstyle "
                  labelId="demo-select-small"
                  id="demo-select-small"
                  inputProps={{ "aria-label": "Without label" }}
                  value={selectDate}
                  onChange={(e) => {
                    setSelectDate(e.target.value);
                    // getBillboardTableData(e.target.value);
                  }}
                >
                  {/* <MenuItem
                    value={1}
                    className="blackcolor484848 fs13px"
                    disabled
                  >
                    {dates
                      ? moment(dates?.[0]).format('YYYY-MM-DD')
                      : 'Please select date'}
                  </MenuItem> */}
                  {dates?.map((a, i) => (
                    <MenuItem
                      className="blackcolor484848 fs13px"
                      value={moment(a).format("YYYY-MM-DD")}
                      key={a}
                    >
                      {moment(a).format("YYYY-MM-DD")}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            padding: "0px",
          }}
        >
          <>
            <div>
              <Grid container gap="20px">
                <Grid container item>
                  <div style={{ marginBottom: "20px", width: "100%" }}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer className="width100 ">
                      <Table
                        className="hide_sortBar"
                        sx={{
                          minWidth: 1100,
                          width: "100%",
                        }}
                        style={{ tableLayout: "fixed" }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead
                          className="tablerow "
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          // onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          style={{ backgroundColor: " #fff" }}
                        />

                        <TableBody
                          className="tablerow "
                          style={{ backgroundColor: " #fff" }}
                        >
                          {billboardTableList?.map(
                            (data, i) =>
                              data?.date == selectDate && (
                                <>
                                  <TableRow
                                    hover
                                    key={i}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      // id={labelId}

                                      scope="row"
                                      padding="none"
                                      sx={{ width: "16.66%" }}
                                    >
                                      {data?.billId
                                        ? data?.billId?.slice(-8)
                                        : "00"}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="none"
                                      sx={{ width: "16.66%" }}
                                    >
                                      {data?.billName}
                                    </TableCell>
                                    <TableCell
                                      ccomponent="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="none"
                                      sx={{ width: "16.66%" }}
                                    >
                                      ₹ {data?.basePrice}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="none"
                                      sx={{ width: "16.66%" }}
                                    >
                                      {data?.date}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="none"
                                      sx={{ width: "16.66%" }}
                                    >
                                      {getTimeString(data?.startHour)}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="none"
                                      sx={{ width: "16.66%" }}
                                    >
                                      {adDuration} seconds
                                    </TableCell>{" "}
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="none"
                                      sx={{ width: "16.66%" }}
                                    >
                                      {data?.repetitions}
                                    </TableCell>{" "}
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="none"
                                      sx={{ width: "16.66%" }}
                                    >
                                      ₹{" "}
                                      {data?.amount
                                        ? Number(data?.amount).toFixed(2)
                                        : "00"}
                                    </TableCell>
                                  </TableRow>
                                </>
                              )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}
