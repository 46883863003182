import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import useAccessPermissionFilter from "../../../../../component/permissionUtils/customHook/useAccessPermissionFilter";
import permissionUtils from "../../../../../component/permissionUtils";
import AccordionPanel from "../../../../../component/AccordionCustom/UnCheckAccordian";

export default function Testing({ checkedItems, checkHandler }) {
  const [
    updatedRoutesPermit,
    PermissionCheck,
    CheckTabsPermissions,
    accessPermission,
  ] = permissionUtils();
  const [expanded, setExpanded] = useState(false);
  const AllFilteredPermission = useAccessPermissionFilter(accessPermission);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div>
        <div className="admin-head">
          <Typography className="whitecolortypo500 fs16px">
            Business Details
          </Typography>
        </div>

        <Grid container style={{ marginTop: "20px" }}>
          {AllFilteredPermission && (
            <Grid item sm={12} mt={2} xs={12} md={6} lg={6}>
              <AccordionPanel
                data={AllFilteredPermission?.Agency}
                edit={true}
                id={"agency"}
                content="Additional content here"
                expanded={expanded === "agency"}
                checkedItems={checkedItems}
                checkHandler={checkHandler}
                handleChange={handleChange("agency")}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}
