import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Typography,
  DialogContent,
  Snackbar,
  Grid,
  Alert as MuiAlert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import TextField from "../../../../../component/TextField";
import axiosInstance from "../../../../../api/axiosInstance";
import RejectForm from "./RejectForm";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
  <DialogTitle {...other}>
    {children}
    {onClose && (
      <IconButton
        aria-label="close"
        onClick={onClose}
        className="dialogcrossicon"
      >
        <CloseIcon />
      </IconButton>
    )}
  </DialogTitle>
);

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  role,
  data,
  fetchWithdrawalTrans,
}) {
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };
  const onSubmit = async (item) => {
    let body = {
      staus: "approved",
      transactionId: item?.transactionId,
      id: data?._id,
    };
    try {
      const res = await axiosInstance.post(
        "api/payment/updateWithdrawStatus",
        body
      );
      if (res?.status === 200 || res?.status === 201) {
        showSnackbar(res.data.msg, "success");
        fetchWithdrawalTrans();
        handleClose();
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.msg || "An unexpected error occurred";
      showSnackbar(errorMessage, "error");
    }
  };
  const userName = `${data?.userId?.firstName || ""} ${
    data?.userId?.lastName || ""
  }`.trim();
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#FF9500"; // Yellow
      case "approved":
        return "#4CAF50"; // Green
      case "rejected":
        return "#F44336"; // Red
      default:
        return "#B0BEC5"; // Default color (optional)
    }
  };
  const Status = data?.status === "rejected" || data?.status === "approved";
  return (
    <div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Button
        size="small"
        onClick={handleClickOpen}
        className="select_date_filter_class "
      >
        View
      </Button>
      <BootstrapDialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          sx={{ backgroundColor: getStatusColor(data?.status) }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className="whitecolortypo500">Withdraw Form</Typography>
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ m: 2 }}>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography align="center" className="blackcolor484848 fs20px">
                  Withdraw Amout
                </Typography>
                <TextField
                  {...register("amount", {
                    required: "withdrawal amount  is required!",
                  })}
                  label="Withdrawal Amount"
                  variant="outlined"
                  size="small"
                  disabled={Status}
                  sx={{ mt: 2, minWidth: "300px" }}
                  placeholder="Enter Withdrawal Amount"
                  error={!!errors.amount}
                  defaultValue={data?.amount}
                  helperText={errors.amount?.message}
                />
              </Grid>
            </Grid>
            <Grid container mt={2} spacing={2}>
              <Typography
                align="center"
                className="width100 blackcolor484848 fs20px"
              >
                Personal information
              </Typography>
              <Grid item xs={4}>
                <TextField
                  label="Full Name"
                  variant="outlined"
                  className="width100"
                  size="small"
                  disabled={Status}
                  placeholder="Enter Withdrawal Amount"
                  defaultValue={userName}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...register("number", {
                    required: "mobile number is required!",
                  })}
                  label="Mobile Number"
                  className="width100"
                  variant="outlined"
                  size="small"
                  disabled={Status}
                  defaultValue={data?.userId?.mobileNumber}
                  placeholder="Enter Mobile Number"
                  error={!!errors.number}
                  helperText={errors.number?.message}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Email"
                  variant="outlined"
                  className="width100"
                  size="small"
                  disabled={Status}
                  placeholder="Enter Email"
                />
              </Grid>
            </Grid>
            <Grid container mt={2} spacing={2}>
              <Typography
                align="center"
                className="width100 blackcolor484848 fs20px"
              >
                Bank Details
              </Typography>
              <Grid item xs={4}>
                <TextField
                  label="Account Holder Name"
                  variant="outlined"
                  className="width100"
                  size="small"
                  disabled={Status}
                  placeholder="Account Holder Name"
                  defaultValue={data?.bankDetails?.receiverName}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Account Number"
                  className="width100"
                  variant="outlined"
                  size="small"
                  disabled={Status}
                  placeholder="Enter Mobile Number"
                  defaultValue={data?.bankDetails?.accountNumber}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="IFSC Code"
                  variant="outlined"
                  className="width100"
                  size="small"
                  disabled={Status}
                  defaultValue={data?.bankDetails?.ifsc}
                />
              </Grid>
            </Grid>
            {data?.status === "rejected" ? (
              <Grid container mt={2}>
                <Typography className="width100 blackcolor484848 fs20px">
                  Rejected Reason :
                </Typography>
                <TextField
                  sx={{ mt: 2 }}
                  label="Reason"
                  disabled={Status}
                  variant="outlined"
                  className="width100"
                  size="small"
                  defaultValue={data?.reason}
                />
              </Grid>
            ) : (
              <>
                <Typography
                  className="purple_color_500  width100"
                  mt={2}
                  align="center"
                >
                  Please Deposit Requested Amount to User First then only
                  approve this request
                </Typography>
                <Grid container justifyContent="center">
                  <Grid item mt={2}>
                    <Typography
                      align="center"
                      className="blackcolor484848 fs20px"
                    >
                      Enter Transaction
                    </Typography>
                    <TextField
                      {...register("transactionId", {
                        required: "transaction id is required!",
                      })}
                      label="Transaction ID"
                      variant="outlined"
                      size="small"
                      sx={{ mt: 2, minWidth: "300px" }}
                      placeholder="Enter Transaction Id"
                      error={!!errors.transactionId}
                      defaultValue={data?.transactionId ?? ""}
                      helperText={errors.transactionId?.message}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            {Status ? (
              <Typography
                align="center"
                sx={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "8px",
                  backgroundColor: getStatusColor(data?.status),
                  color: "#fff",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: getStatusColor(data?.status),
                  },
                }}
              >
                {data?.status}
              </Typography>
            ) : (
              data?.status === "pending" &&
              role === "SAdmin" && (
                <Button
                  variant="medium"
                  type="submit"
                  className="purple_btn_primary text_transform_inherit"
                >
                  Approve
                </Button>
              )
            )}
          </DialogActions>
        </form>
        {data?.status === "pending" && role === "SAdmin" && (
          <>
            <Grid
              container
              sx={{ position: "absolute", bottom: 6, right: 110 }}
              justifyContent={"flex-end"}
            >
              <RejectForm
                data={data}
                firstClose={handleClose}
                fetchWithdrawalTrans={fetchWithdrawalTrans}
              />
            </Grid>
          </>
        )}
      </BootstrapDialog>
    </div>
  );
}
