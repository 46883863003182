import { useState, useEffect } from "react";
import axiosInstance from "../../../api/axiosInstance";
function usePermission(userID) {
  const [accessPermissionBasedRole, setAccessPermission] = useState([]);

  const getPermissionBasedRole = async (userID) => {
    try {
      const resp = await axiosInstance.get(
        `/api/permission/getUserPermission/${userID}`
      );
      if (resp?.status === 200) {
        setAccessPermission(resp?.data?.permissions);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (userID) {
      getPermissionBasedRole();
    }
  }, [userID]);

  return { accessPermissionBasedRole, getPermissionBasedRole };
}

export default usePermission;
