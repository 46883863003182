import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ConfirmationDialog({ open, onClose, onConfirm, content }) {
  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} className="purple-gradient">
        <Typography className="whitecolortypo">Confirmation</Typography>
        {onClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "red",
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: "#fffff",
          marginTop: "10px",
          padding: "5px 20px",
        }}
      >
        <Typography className="greycolor505050500 fs18px">{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button className="previousbtn" onClick={onClose}>
          No
        </Button>
        <Button
          className="purplebtn"
          style={{ padding: "4px 0px" }}
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
