import {
  Grid,
  Typography,
  DialogActions,
  Button,
  Input,
  TextareaAutosize,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  useCampaignController,
  setCampaignName,
  setAboutCampaign,
  setDateRange,
  setAdDuration,
  setApplyToAllDays,
} from "../../../../Context/campaignBook";
import Calendar from "../CampCalender";

//==================================================================//

export default function CampaignTab(props) {
  const {
    states: { minDate },
    TabNext,
    formErrors,
    changeFormError,
  } = props;

  //const {data} = props;

  useEffect(() => {
    console.log("props Capmaign Tab ==> ", props.data.campaignName);
    //props.data.handleSet("//====  rahul kumar  ====//");
  }, []);
  const [controller, dispatch] = useCampaignController();
  const { campaignName, aboutCampaign, dateRange, adDuration } = controller;

  const [durationArr, setDurationArr] = useState([30, 60, 90, 120]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());

  const startMaxDate = new Date();
  startMaxDate.setDate(new Date().getDate() + 60);

  const getDataFromChildHandler = (data, dates2) => {
    console.log("selected date", data);
    setDateRange(dispatch, dates2);
    setApplyToAllDays(dispatch, false);
    let temp = formErrors;
    delete temp?.dateRange;
    changeFormError(temp);
    // setAnalyticsDate(dates2);
    // setDate(data);
    // console.log("");
  };

  useEffect(() => {
    const newMaxDate = new Date(
      selectedDate.getTime() + 90 * 24 * 60 * 60 * 1000
    );
    setMaxDate(newMaxDate);
  }, [selectedDate]);

  return (
    <>
      <Grid
        container
        className="campai gn-container mt10px"
        justifyContent="space-between "
        sx={{
          border: "1px solid #DDD",
          borderRadius: "8px",
          padding: "0px 30px",
        }}
      >
        <Grid item xs={10} sm={8} md={5} lg={5.6} sx={{ marginBottom: "50px" }}>
          <Typography className="blackcolortypo500 fs20px mt20px ">
            Create New Campaign
          </Typography>
          <Grid className="  mt30px">
            <Grid item style={{ width: "100%" }} className="mt10px">
              <Typography className="greycolor505050500 fs16px">
                Campaign Name
              </Typography>
              <Input
                className="inputstyleCamp"
                disableUnderline
                placeholder="Enter Campaign Name "
                value={campaignName || ""}
                onChange={(e) => {
                  setCampaignName(dispatch, e.target.value);
                  let temp = formErrors;
                  delete temp?.campaignName;
                  changeFormError(temp);
                }}
              />
              <Typography
                className="formError"
                variant={"subtitle2"}
                sx={{ color: "red" }}
              >
                {formErrors?.campaignName}
              </Typography>
            </Grid>

            <Grid item style={{ width: "100%" }}>
              <Typography className="greycolor505050500 fs16px mt15px">
                About Campaign
              </Typography>
              <TextareaAutosize
                className="textarea inputstyleCamp"
                aria-label="minimum height"
                minRows={3}
                disableUnderline
                placeholder="Enter About Campaign "
                value={aboutCampaign || " "}
                // onChange={(e) => {
                //   props.data.handleCampaignData(
                //     e.target.value,
                //     "aboutCampaign"
                //   );
                // }}

                onChange={(e) => {
                  setAboutCampaign(dispatch, e.target.value);
                  let temp = formErrors;
                  delete temp?.aboutCampaign;
                  changeFormError(temp);
                }}
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors?.aboutCampaign}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          class="divider"
          style={{ borderLeft: "1px solid #DDD", margin: "34px 0px" }}
        ></Grid>

        <Grid item xs={10} sm={8} md={5} lg={5.6} sx={{ marginBottom: "50px" }}>
          <Typography className="blackcolortypo500 fs20px mt20px ">
            {/* Schedule & Budget */}{" "}
          </Typography>
          <Grid className="  mt80px">
            <Grid item style={{ width: "100%" }} className="mt10px">
              <Typography className="greycolor505050500 fs16px">
                Select Start Date and End Date
              </Typography>
              <Grid item xs={12} sm={12}>
                <Calendar
                  getDataFromChildHandler={getDataFromChildHandler}
                  className=" Border "
                />
              </Grid>
            </Grid>

            <Grid item style={{ width: "100%" }}>
              <Typography className="greycolor505050500 fs16px mt15px">
                Ad Duration
              </Typography>
              <FormControl className="textfield-outline width100 " fullWidth>
                <Select
                  labelId="demo-simple-select-label Border borderRadius_15px "
                  id="demo-simple-select"
                  value={adDuration || "0"}
                  sx={{
                    marginTop: "10px !important",
                    width: "350px !important",
                  }}
                  // label="Select duration"
                  // onChange={(e) => {
                  //   props.data.handleCampaignData(
                  //     e.target.value,
                  //     "selectSecond"
                  //   );
                  // }}
                  onChange={(e) => {
                    setAdDuration(dispatch, e.target.value);
                    let temp = formErrors;
                    delete temp?.adDuration;
                    changeFormError(temp);
                  }}
                >
                  <MenuItem disabled value="0">
                    Select duration
                  </MenuItem>
                  {durationArr?.map((data, index) => (
                    <MenuItem key={index} value={data}>
                      {data ? data : 0} second
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Typography className="formError" variant={"subtitle2"}>
                {formErrors?.adDuration}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
