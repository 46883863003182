import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Dialog,
  Button,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
// core componennt
import Clusterhead from "../../../assets/Img/ClusterHead.png";
import SubAdminlogo from "../../../assets/Img/SubAdmin.png";
import AddAgencylogo from "../../../assets/Img/AdAgency.png";
import BusinessOwnerLogo from "../../../assets/Img/BusinessOwner.png";
// active logo
import ActiveClusterhead from "../../../assets/Img/ActiveClusterHead.png";
import ActiveSubAdminlogo from "../../../assets/Img/ActiveSubAdmin.png";
import ActiveAddAgencylogo from "../../../assets/Img/ActiveAdAgency.png";
import ActiveBusinessOwnerLogo from "../../../assets/Img/ActiveBusinessOwner.png";
// import ActiveOrganizationLogo from "../../../assets/Img/ActiveOrganization.png";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="dialogtitle" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 3,
            color: "#ffffff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ PermissionCheck }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  let userRole = JSON.parse(localStorage.getItem("userData"))?.user?.userRole;
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [data, setData] = React.useState([
    { index: 0 },
    { index: 1 },
    { index: 2 },
    { index: 3 },
    { index: 4 },
    { index: 5 },
  ]);
  const [linkstate, setLinkstate] = React.useState(null);
  const [selectedicon, setSelectedicon] = React.useState(null);
  const [ImageArray, setImageArray] = React.useState([]);

  const handleClickitem = (name, i) => {
    setSelectedicon(i);
    setLinkstate(name);
  };
  useEffect(() => {
    if (userRole === 2) {
      setImageArray([
        {
          name: "AdAgency",
          image: AddAgencylogo,
          link: "AdAgency",
          key: "add-new-user-agency",
          activeimage: ActiveAddAgencylogo,
        },
        {
          name: "BusinessOwner",
          image: BusinessOwnerLogo,
          link: "addbusinessOwner",
          key: "add-new-user-businessowner",

          activeimage: ActiveBusinessOwnerLogo,
        },
      ]);
    } else {
      setImageArray([
        {
          name: "ClusterHead",
          image: Clusterhead,
          link: "addclusterhead/1",
          key: "add-new-user-clusterhead",
          activeimage: ActiveClusterhead,
        },
        {
          name: "Sub-Admin",
          image: SubAdminlogo,
          link: "addsubadmin/0",
          key: "add-new-user-subadmin",
          activeimage: ActiveSubAdminlogo,
        },
        {
          name: "AdAgency",
          image: AddAgencylogo,
          link: "AdAgency/2",
          key: "add-new-user-agency",
          activeimage: ActiveAddAgencylogo,
        },
        {
          name: "BusinessOwner",
          image: BusinessOwnerLogo,
          link: "addbusinessOwner/3",
          key: "add-new-user-businessowner",
          activeimage: ActiveBusinessOwnerLogo,
        },
      ]);
    }
  }, [userRole]);

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid container gap="4px" alignItems="center">
        <IoMdAddCircleOutline className="iconcolorbk   fs25px mr10" />
        <Button className="addbtn_home" onClick={handleClickOpen}>
          Add New
        </Button>{" "}
      </Grid>

      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className="whitecolortypo"> Add User </Typography>
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            mt: "40px ",
            width: "93%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Typography className="blackcolortypo fs18px">
            Choose type of user you want to add
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ padding: "20px  0px " }}
            spacing={2}
          >
            {ImageArray?.map((item, i) => (
              <Grid item xs={12} sm={6} md={3} key={item?.name}>
                {PermissionCheck(item?.key) && (
                  <>
                    <Card
                      className="dashcard"
                      sx={{ border: "1px solid #050423" }}
                    >
                      <CardContent align="center" className="width100">
                        <IconButton
                          onClick={() => handleClickitem(item?.link, i)}
                        >
                          {i === selectedicon ? (
                            <img
                              src={item?.activeimage}
                              alt="Clusterhead"
                              style={{ width: "100px" }}
                            />
                          ) : (
                            <img
                              src={item?.image}
                              style={{ width: "100px" }}
                              alt="Clusterhead"
                            />
                          )}
                        </IconButton>
                      </CardContent>
                    </Card>
                    <Typography
                      className={
                        i === selectedicon
                          ? "purplecolortypo pt10px"
                          : "greycolortypo pt10px"
                      }
                      align="center"
                    >
                      {item?.name}
                    </Typography>{" "}
                  </>
                )}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            width: "97%",
            marginLeft: "auto",
            marginRight: "auto",
            m: "10px 0px ",
          }}
        >
          <Button
            disabled={linkstate ? false : true}
            className={linkstate ? " purplebtn" : "disable-dialognext-btn"}
          >
            <Typography onClick={() => navigate(`/admin/${linkstate}`)}>
              Next
            </Typography>
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
