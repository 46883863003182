import React, { useEffect, useState, useContext } from "react";

import {
  Typography,
  Grid,
  Breadcrumbs,
  Box,
  Button,
  TextField,
  MenuItem,
  FormControl,
} from "@mui/material";
import moment from "moment";
import { GrView } from "react-icons/gr";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import useExportExcel from "../../../../../../../../utils/useExportExcelFile";
import { OccupencyContext } from "../../../../../../../../Context/OccupencyContext";
import axiosInstance from "../../../../../../../../api/axiosInstance";
import Calendar from "../../../../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
// import Calendar from "../../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
// import Button from "@mui/material";

const headCells = [
  {
    id: "Date Range",
    numeric: true,
    label: "Date Range",
  },
  {
    id: "Active Duration",
    numeric: true,
    disablePadding: false,
    label: "Active Duration",
  },
  {
    id: "NON-Active Duration",
    numeric: true,
    disablePadding: false,
    label: "NON-Active Duration",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        {/* <Grid container justifyContent="space-between">
          <Typography className="gray5A5A5Atypo500 fs20px">
            Billboard type
          </Typography>
          <Grid item>
            <Grid container gap="10px">
              
              <Button
                sx={{ padding: "0 10px" }}
                className="purple_btn_primary text_transform_inherit"
               
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const BillboardAnalyticsTable = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [dense, setDense] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [date, setDate] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [acceptedBarAnalyticsData, setAcceptedBarAnalyticsData] = useState([]);
  const [dataForTable, setdataForTable] = useState(null);
  const [fileName, setfileName] = React.useState("Occupancy Slot");
  const [title, setTitle] = React.useState("Occupancy Slot Analytics");

  const { exportToExcel } = useExportExcel();
  const value = useContext(OccupencyContext);
  console.log("valueFromContext", value);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const billBoardID = props.billBoardID;

  const getDataFromChildHandler = (data, dates2) => {
    setDate(data);
    setAnalyticsDate(dates2);
    console.log("dateRange", dates2);
  };

  const getTableGraphData = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getBillboardActiveTime?billboardId=${billBoardID}&startDate=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}&dateWise=true`
      );
      setAcceptedBarAnalyticsData(res?.data.data);
      if (res.status == 200) {
        console.log("success res after TableGraphData api", res);
        setTableData(res?.data?.data);
      } else {
        console.log("error res after TableGraphData api", res);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (date && billBoardID) {
      getTableGraphData();
    }
  }, [date, billBoardID]);

  let acceptedData = [];

  useEffect(() => {
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = acceptedBarAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          Active: Math.floor(
            acceptedBarAnalyticsData[index].videoDuration / 60
          ),
          NonActive:
            1440 -
            `${Math.floor(acceptedBarAnalyticsData[index].videoDuration / 60)}`,
        };
        acceptedData.push(obj);
      } else {
        let obj = {
          Date: analyticsDate[i],
          Active: 0,
          NonActive: 0,
        };
        acceptedData.push(obj);
      }
    }
    setdataForTable(acceptedData);
  }, [acceptedBarAnalyticsData]);

  console.log("uSeeffects", dataForTable);

  return (
    <div>
      {" "}
      <Grid container className="width100 mt20px" gap="20px">
        <Grid container justifyContent={"space-between"}>
          <Typography className="gray5A5A5Atypo500 fs20px">
            Hourly Slot Analytics
          </Typography>
          <Grid item>
            <Grid container gap="10px">
              <Calendar getDataFromChildHandler={getDataFromChildHandler} />
              <Button
                sx={{ padding: "5px 20px" }}
                className="purple_btn_primary text_transform_inherit"
                onClick={() => {
                  exportToExcel(dataForTable, fileName, title);
                }}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <EnhancedTableToolbar />
        <TableContainer className="width100 hide_sortBar table_border_radius">
          <Table
            sx={{
              minWidth: 800,
              backgroundColor: "#fff",
              width: "100%",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              className="tablerow"
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />

            <TableBody className="tablerow ">
              {dataForTable?.map((a, i) => (
                <TableRow
                  key={i}
                  hover
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "50%" }}
                  >
                    {a?.Date}
                  </TableCell>

                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "16.66%" }}
                  >
                    {a?.Active}(min)
                  </TableCell>
                  <TableCell
                    ccomponent="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "16.66%" }}
                  >
                    {a?.NonActive}
                    (min)
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
};

export default BillboardAnalyticsTable;
