import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  Stack,
  InputBase,
  useMediaQuery,
  Pagination,
} from "@mui/material";
import moment from "moment";
import useExportExcel from "../../../../utils/useExportExcelFile";
import { styled, useTheme } from "@mui/material/styles";

// ** Icons
import SearchIcon from "@mui/icons-material/Search";

// ** User Imports
import CustomPaginationActionsTable from "../Utils/AllTable/AllTable";
// ** Core Component
import axiosInstance from "../../../../api/axiosInstance";
import TabList from "../../../../component/CustomTabs/TabList";
import permissionUtils from "../../../../component/permissionUtils";
import usePaginatedValue from "../../../../utils/usePaginatedValue";
import TabPanelList from ".././../../../component/CustomTabs/TabPanel";
import CustomBreadcrumbs from "../../../../component/CustomBreadcrumbs";
import ReportDialog from "../ReportDialog/ReportDialog";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      "&:focus": {
        width: "90%",
      },
    },
  },
}));

//=============================== Main Component ===============================//
export default function Testing() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const md = useMediaQuery(theme.breakpoints.up("md"));
  const { paginatedPage, paginateTabValue, getPaginatedValueHandler } =
    usePaginatedValue(navigate);
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();

  const [firstTimeSearch, setFirstTimeSearch] = useState(true);

  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [fileName, setfileName] = React.useState("users list");
  const [adminType, setAdminType] = useState("");
  const [adminData, setAdminData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [ExportArr, setExportArr] = useState([]);
  const [currentPage, setCurrentPage] = React.useState(
    paginatedPage ? paginatedPage : 1
  );
  const [deviceslength, setDevicesLength] = React.useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [userRole, setUserRole] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const tabValueString = queryParams.get("tabValue");
  const tabValue = parseInt(tabValueString, 10);
  const { exportToExcel } = useExportExcel();
  const handleButtonClick = () => {
    exportToExcel(ExportArr, fileName);
  };

  const handleChange = (event, newValue) => {
    setAdminType("");
    setFirstTimeSearch(true);
    getPaginatedValueHandler("", 1, newValue);
    setValue(newValue);
    setSearchQuery("");
  };

  const handleChangeByPage = (event, page) => {
    getPaginatedValueHandler("", page, value);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (isNaN(tabValue)) {
      setValue(0);
    } else {
      setValue(tabValue);
    }
  }, [tabValue]);

  useEffect(() => {
    if (firstTimeSearch) {
      setFirstTimeSearch(false);
      return;
    }
    const searchApiHandler = setTimeout(() => {
      getuseraccordingType(searchQuery);
    }, 500);
    return () => clearTimeout(searchApiHandler);
  }, [searchQuery]);

  const permissionList = [
    "view-sub-user",
    "view-cluster-head",
    "view-ad-agency",
    "view-business-user",
    "view-individual-user",
  ];

  const permissions = CheckTabsPermissions(permissionList);

  const updatedData = {};

  const renamedKeys = {
    "view-sub-user": "Admin",
    "view-cluster-head": "ClusterHead",
    "view-ad-agency": "AdAgency",
    "view-business-user": "BusinessOwner",
    "view-individual-user": "NormalUser",
  };

  for (const key in permissions) {
    if (key in renamedKeys && permissions[key] === true) {
      updatedData[renamedKeys[key]] = permissions[key];
    }
  }

  function typeChange(value) {
    const adminTypes = Object.keys(updatedData);
    if (updatedData[adminTypes[value]]) {
      setAdminType(adminTypes[value]);
    }
  }

  useEffect(() => {
    if (updatedData) {
      typeChange(value);
    }
  }, [updatedData]);

  useEffect(() => {
    if (userRole) {
      getuseraccordingType();
    }
  }, [userRole, currentPage]);
  useEffect(() => {
    if (paginatedPage < 1) {
      setCurrentPage(1);
      return;
    }
    setCurrentPage(paginatedPage ? paginatedPage : 1);
  }, [paginatedPage]);

  const breadcrumbs = [
    { label: "Dashboard", link: "/" },
    { label: "Manage Users" },
  ];

  const getTabLabel = (permission) => {
    const labelMap = {
      Admin: "Sub Admin",
      ClusterHead: "Cluster Head",
      AdAgency: "Ad Agency",
      BusinessOwner: "Business Owner",
      NormalUser: "Individual User",
    };
    return labelMap[permission];
  };

  const getCustomPaginationActionsTable = (permission) => (
    <CustomPaginationActionsTable
      value={value}
      data={adminData}
      loading={loading}
      getuseraccordingType={getuseraccordingType}
      searchQuery={searchQuery}
      adminType={adminType}
    />
  );
  useEffect(() => {
    if (adminType === "Admin") {
      setUserRole(6);
    } else if (adminType === "BusinessOwner") {
      setUserRole(1);
    } else if (adminType === "ClusterHead") {
      setUserRole(2);
    } else if (adminType === "AdAgency") {
      setUserRole(3);
    } else if (adminType === "NormalUser") {
      setUserRole(4);
    }
  }, [adminType]);

  const getuseraccordingType = async (query, sortBy, sortType) => {
    setAdminData([]);
    setTotalPage([]);
    setDevicesLength([]);
    setLoading(true);
    try {
      let resp = await axiosInstance.get(
        `/api/user/getUserLists?userRole=${userRole}&sortBy=${
          sortBy ? sortBy : "createdAt"
        }&sortType=${
          sortType ? sortType : -1
        }&page=${currentPage}&limit=15&searchQuery=${
          query ? query : null
        }&isDelete=false`
      );
      setLoading(false);
      setAdminData(resp?.data?.msg);
      setTotalPage(Math.ceil(resp?.data?.lengthData / 15));
      setDevicesLength(Math.ceil(resp?.data?.lengthData));
    } catch (error) {
      setLoading(false);
      console.log("error from Error ", error);
    }
  };

  const panels = [
    {
      permission: "Admin",
      component: getCustomPaginationActionsTable("Admin"),
    },
    {
      permission: "ClusterHead",
      component: getCustomPaginationActionsTable("ClusterHead"),
    },
    {
      permission: "AdAgency",
      component: getCustomPaginationActionsTable("AdAgency"),
    },
    {
      permission: "BusinessOwner",
      component: getCustomPaginationActionsTable("BusinessOwner"),
    },
    {
      permission: "NormalUser",
      component: getCustomPaginationActionsTable("NormalUser"),
    },
  ];

  const linkData = [
    {
      value: "Admin",
      path: `/admin/addsubadmin/${value}`,
      buttonText: "Add New",
      key: "add-new-user-subadmin",
    },
    {
      value: "ClusterHead",
      path: `/admin/addclusterhead/${value}`,
      buttonText: "Add New",
      key: "add-new-user-clusterhead",
    },
    {
      value: "AdAgency",
      path: `/admin/AdAgency/${value}`,
      buttonText: "Add New",
      key: "add-new-user-agency",
    },
    {
      value: "BusinessOwner",
      path: `/admin/addbusinessOwner/${value}`,
      buttonText: "Add New",
      key: "add-new-user-businessowner",
    },
  ];

  const linkInfo = linkData.find((item) => item.value === adminType);
  useEffect(() => {
    const newOrderListArr = adminData.map((a) => {
      return {
        ID: a?._id,
        FirstName: a?.firstName,
        LastName: a?.lastName,
        Role: a?.role,
        Email: a?.email,
        CreatedAt: moment(a?.createdAt)?.format("DD/MM/YYYY"),
      };
    });
    setExportArr(newOrderListArr);
  }, [adminData]);

  return (
    <>
      <div className="width-90LR">
        <div className="flex-class">
          <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
        </div>
        <Grid container>
          <Grid container className="device-container mt20px">
            <Grid item xs={12}>
              <TabList
                md={md}
                value={value}
                centered={true}
                classes={{ Tabs: "TabStyleAddDevice", Tab: "TabChangesDevice" }}
                handleChange={handleChange}
                getTabLabel={getTabLabel}
                permissions={updatedData}
              />
            </Grid>

            {linkInfo && PermissionCheck(linkInfo?.key) && (
              <>
                {linkInfo && (
                  <Link
                    to={linkInfo.path}
                    state={{ value }}
                    className="linkcolor"
                  >
                    <Button className="whitecolorbuttonlogin">
                      {linkInfo.buttonText}
                    </Button>
                  </Link>
                )}
              </>
            )}
          </Grid>
          <Grid
            container
            sx={{ marginTop: "-8px" }}
            justifyContent="space-between"
            alignItems="center"
            rowGap="10px"
          >
            <Grid item>
              <Typography className="greycolor1c1c1c fs18px">
                Showing {adminData?.length} out of {deviceslength} Results
              </Typography>
            </Grid>

            <Grid item>
              <Grid container>
                <Search className="search-admin">
                  <SearchIconWrapper>
                    <SearchIcon className="searchicon" />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search User"
                    inputProps={{ "aria-label": "search" }}
                    j
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </Search>
                {/* <Button
                  sx={{ padding: "8px 20px", marginLeft: "10px" }}
                  className="purple_btn_primary text_transform_inherit"
                  onClick={handleButtonClick}
                >
                  Export
                </Button> */}
                {adminType === "NormalUser" && (
                  <ReportDialog deviceslength={deviceslength} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>{" "}
        <TabPanelList value={value} permissions={updatedData} panels={panels} />
      </div>
      {adminData && adminData?.length > 0 && (
        <Grid
          justifyContent="center"
          alignItems="center"
          sx={{ display: "flex", marginTop: "20px", marginBottom: "20px" }}
        >
          <Stack spacing={2}>
            <Pagination
              count={totalPage}
              page={currentPage}
              onChange={handleChangeByPage}
              size="large"
            />
          </Stack>
        </Grid>
      )}
    </>
  );
}
