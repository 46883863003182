import React, { useEffect, useState } from "react";
import {
  Button,
  Step,
  Stepper,
  Box,
  StepLabel,
  Typography,
  Grid,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { Link, useParams } from "react-router-dom";
//React Icons
import { IoChevronBack } from "react-icons/io5";
//core components that
import Sidebar from "../../../../component/Sidebar/Sidebar";
import BuisnesssDetais from "./BuisnessPages/BuisnessDetails";
import BuisAccess from "./BuisnessPages/Buis-Access";
import axios from "../../../../api/axiosInstance";
import SuccessDialog from "../../../../utils/Dialog/SuceedFullDialog";
import WrongDialog from "../../../../utils/Dialog/WrongDialog";
import LoaderDialogue from "../../../../component/LoaderDialogue/LoaderDialogue";
import axiosInstance from "../../../../api/axiosInstance";
//--------------------------- custom hook -------------------------------------//
import useInputScrollRef from "../../../../utils/useInputScrollRef";
//--------------------------- custom hook -------------------------------------//
// total steps
const steps = ["Business Details", "Access"];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function BusinessOwner() {
  const params = useParams();
  const newURL = `/admin/manage-user?page=1&tabValue=${params.value}`;

  // Scrolls to the first empty input field and stops the loop
  const { addButtonClicked, inputRefs } = useInputScrollRef();
  // Scrolls to the first empty input field and stops the loop
  const [activeStep, setActiveStep] = React.useState(0);
  const [openDialogName, setOpenDialog] = React.useState(null);
  // loading for loader
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("success"); // or 'error', 'warning', etc.

  // =============================== testing Data =============================== //
  // validation form
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmit1, setIsSubmit1] = useState(false);

  const [formdata, setFormdata] = React.useState({
    prefix: "",
    firstName: "",
    lastName: "",
    email: "",
    deviceId: [],
    mobileNumber: "",
    landmark: "",
    pincode: "",
    address: "",
    state: "",
    websiteLink: "",
    businessType: "",
    yearlyTurnover: "",
    screenType: "",
    footfall: "",
    natureofOutlet: "",
    advertisementObjective: "",
    gstNumber: "",
    allowedPermissions: "",
  });

  const handleChange = (e) => {
    if (e.target.name == "mobileNumber") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormdata({ ...formdata, [e.target.name]: e.target.value });
      }
    } else if (e.target.name == "pincode") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormdata({ ...formdata, [e.target.name]: e.target.value });
      }
    } else {
      setFormdata({ ...formdata, [e.target.name]: e.target.value });
    }
  };

  // console.log("form data ==> ", formdata);

  // ==================================================== //
  const handleFormsubmit = () => {
    setFormErrors(validate(formdata));
    setIsSubmit1(!isSubmit1);
    console.log("form data ==> ", formdata);
    if (activeStep === 1) {
      // console.log("FORM Submission ==> ", activeStep)
      setIsSubmit(true);
    }
  };

  const handleImageChange = (event) => {
    console.log("Files ===> ", event.target.files);

    async function getBase64(file, cb) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
        // console.log("==> ", reader.result)
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }

    getBase64(event.target.files[0], (result) => {
      // console.log("IMAGE IN BASE64 ==> ", result);
      setFormdata({ ...formdata, AadharImage: result });
      // idCardBase64 = result;
    });
  };

  const handleDeviceId = (value) => {
    // console.log("+++++++++++++++++", value)
    setFormdata({ ...formdata, deviceId: value });
  };

  const handleAccess = (value) => {
    setFormdata({ ...formdata, access: value });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const showSnackbar = () => {
    setSnackbarOpen(true);
    setSeverity("error"); // or 'error', 'warning', etc.
  };

  const checkHandler = (e, item) => {
    setCheckedItems((prevCheckedItems) => {
      const isChecked = prevCheckedItems.includes(item.key);
      if (isChecked) {
        return prevCheckedItems.filter(
          (checkedItem) => checkedItem !== item.key
        );
      } else {
        const updatedCheckedItems = [...prevCheckedItems, item.key];
        setFormdata((prevFormData) => ({
          ...prevFormData,
          allowedPermissions: updatedCheckedItems,
        }));
        return updatedCheckedItems;
      }
    });
  };

  React.useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      createBusinessOwner(formdata);

      console.log("form data ==> ", formdata);
    }
    if (Object.keys(formErrors).length === 0 && isSubmit1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [formErrors]);
  const [dataMsg, setDataMsg] = useState("");

  const createBusinessOwner = async (form) => {
    if (checkedItems.length === 0) {
      showSnackbar();
      return;
    }
    try {
      setLoading(true);
      form.mobileNumber = `91${form.mobileNumber}`;
      let body = form;
      console.log("I am the response from createBusinessOwner", body);

      let resp = await axios.post("/api/user/createBusinessOwner", body);
      if (resp.status === 201) {
        setOpenDialog("success");
        setLoading(false);
      } else {
        setOpenDialog("reject");
        setDataMsg(resp?.response?.data?.msg);
        setLoading(false);
      }
    } catch (error) {
      setOpenDialog("reject");
      setLoading(false);
      console.log("error from creating BusinessOwner => ", error.message);
    }
  };

  const validate = (values) => {
    const errors = {};
    let emailRegex = new RegExp(
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    );
    let mobileRegex = /^[0]?[6789]\d{9}$/;
    let numeric = /^\d+$/;

    if (!values.prefix) {
      errors.prefix = "Prefix is required !";
    }
    if (!values.firstName) {
      errors.firstName = "First Name  is required !";
    }

    if (!values.lastName) {
      errors.lastName = "Last Name  is required !";
    }

    if (!values.email) {
      errors.email = "Email is required !";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }
    if (!values.deviceId.length > 0) {
      errors.deviceId = "DeviceId is required !";
    }

    if (!values.landmark) {
      errors.landmark = "Landmark type is required !";
    }
    if (!values.pincode) {
      errors.pincode = "Pincode is required !";
    } else if (!numeric.test(values.pincode)) {
      errors.pincode = "Pincode must be a number !";
    }

    if (!values.address) {
      errors.address = "Address is required !";
    }

    if (!values.state) {
      errors.state = "State is required !";
    }

    if (!values.mobileNumber) {
      errors.mobileNumber = "Phone Number is required !";
    } else if (!mobileRegex.test(values.mobileNumber)) {
      errors.mobileNumber = "Invalid Phone Number !";
    }
    if (!values.websiteLink) {
      errors.websiteLink = "WebsiteLink is required !";
    }
    if (!values.businessType) {
      errors.businessType = "Business Type is required !";
    }

    if (!values.footfall) {
      errors.footfall = "Footfall is required !";
    }

    if (!values.yearlyTurnover) {
      errors.yearlyTurnover = "Yearly Turnover is required !";
    }

    if (!values.screenType) {
      errors.screenType = "Screen Type is required !";
    }

    if (!values.natureofOutlet) {
      errors.natureofOutlet = "Nature of Outlet is required !";
    }

    if (!values.advertisementObjective) {
      errors.advertisementObjective = "Advertisement Objective is required !";
    }
    if (!values.gstNumber) {
      errors.gstNumber = "GST Number is required !";
    }
    if (existPhoneNum || existEmail) {
      errors.error = "Please enter a phone number or email address";
    }
    // if (!values.AadharImage) {
    //   errors.AadharImage = "Aadhar Image is required !";
    // }
    return errors;
  };
  // mobile num and email already exist api
  // mobile num and email already exist api

  const [existPhoneNum, setExistPhoneNum] = useState(false);
  const [mobileNumerCheck, setMobileNumCheck] = useState(true);
  useEffect(() => {
    if (mobileNumerCheck) {
      setMobileNumCheck(false);
      return;
    }
    let getData = null;
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `/api/user/checkEmailandMobileNumber?mobileNumber=91${formdata?.mobileNumber}`
        );
        if (response?.status == 200) {
          setExistPhoneNum(true);
        } else {
          setExistPhoneNum(false);
        }

        console.log(response, "i am response");
      } catch (error) {
        console.error(error);
        setExistPhoneNum(false);
      }
    }

    getData = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => {
      if (getData) {
        clearTimeout(getData);
      }
    };
  }, [formdata?.mobileNumber]);
  const [existEmail, setExistEmail] = useState(false);
  const [emailCheck, setEmailCheck] = useState(true);
  useEffect(() => {
    if (emailCheck) {
      setEmailCheck(false);
      return;
    }
    let getData = null;
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `/api/user/checkEmailandMobileNumber?email=${formdata?.email}`
        );
        if (response?.status == 200) {
          setExistEmail(true);
        } else {
          setExistEmail(false);
        }

        console.log(response, "i am response");
      } catch (error) {
        console.error(error);
        setExistEmail(false);
      }
    }

    getData = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => {
      if (getData) {
        clearTimeout(getData);
      }
    };
  }, [formdata?.email]);

  // mobile num and email already exist api
  // mobile num and email already exist api

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <BuisnesssDetais
            inputRefs={inputRefs}
            formdata={formdata}
            handleChange={handleChange}
            formErrors={formErrors}
            handleImageChange={handleImageChange}
            handleDeviceId={handleDeviceId}
            existPhoneNum={existPhoneNum}
            existEmail={existEmail}
          />
        );
      case 1:
        return (
          <BuisAccess checkedItems={checkedItems} checkHandler={checkHandler} />
        );
    }
  }
  const handleNext = () => {
    if (activeStep == 0) {
      addButtonClicked();
    }

    handleFormsubmit();
    console.log("FORM Submission ==> ", activeStep);
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={severity}
          onClose={handleSnackbarClose}
        >
          Please select at least one option
        </Alert>
      </Snackbar>
      <Box className="width100">
        <div className="flex-class">
          <div className="width-20">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="cursor pt14px">
                <Link to={newURL} className="link-style">
                  <IoChevronBack className="back-arrowcolor" />
                </Link>
              </Typography>
              <Link to={newURL} className="link-style">
                <Typography className="cursor pt7px greycolortypo6F6F6F fs16px">
                  Create Business Owner
                </Typography>{" "}
              </Link>
            </Grid>
          </div>
          <div className="width-80">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className=" stepper "
            >
              {steps.map((data, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={data} {...stepProps}>
                    <StepLabel className="step" {...labelProps}>
                      {data}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>

        <div className="stepmain-div ">
          {_renderStepContent(activeStep)}
          <React.Fragment>
            <Box className="stepmain-box ">
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                className="mt10px p-tb20-lr10"
              >
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 4 }}
                  className={activeStep ? "previousbtn " : null}
                >
                  Previous
                </Button>
                {activeStep === 0 ? (
                  <Button onClick={handleNext} className="purplebtn">
                    Next
                  </Button>
                ) : (
                  <Button onClick={handleNext} className="purplebtn">
                    Submit
                  </Button>
                )}
              </Grid>
            </Box>
          </React.Fragment>
          <SuccessDialog
            openData={openDialogName === "success"}
            data={"BusinessOwner Added Successfully"}
            navigateTo={newURL}
          />
          <WrongDialog openData={openDialogName === "Reject"} data={dataMsg} />
        </div>
      </Box>
      <LoaderDialogue loading={loading} />
    </>
  );
}
