import React, { useEffect, useState } from "react";
import { Typography, Grid, Divider, Card, Snackbar } from "@mui/material";
import moment from "moment";
import AssignDeviceDailog from "./Dialog/AssignDevice/AssignDeviceToBillboard";
import RemoveDeviceDilaog from "./Dialog/AssignDevice/RemoveDialog";
import MuiAlert from "@mui/material/Alert";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../../../../api/axiosInstance";
import AssignClusterHead from "./Dialog/AssignClusterHead/AssignClusterHead";
import AssignOwnerInfoHead from "./Dialog/AssignOwnerInfoHead/AssignOwnerInfoHead";
import permissionUtils from "../../../../../component/permissionUtils";

import Arrow from "./../../../../../assets/Img/arrowForward.png";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Submit({ id, billBoardData, getBillBoardData }) {
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();
  const navigate = useNavigate();
  const [assignDevice, setAssignDevice] = useState(false);
  const [deviceID, setDeviceId] = useState(null);
  const [deviceData, setDeviceData] = useState(null);
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openuser, setOpenUser] = useState(false);
  const [totalPage, setTotalPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [originalData, setOriginalData] = React.useState(null);
  const [originalUserData, setOriginalUserData] = React.useState(null);
  const [billboardAssign, setBillboardAssign] = useState(null);
  const [deviceAssignUser, setDeviceAssignUser] = useState(null);
  const [clusterHeadData, setClusterHaedData] = useState([]);
  // const [OwnerInfo, setOwnerInfo] = useState(billBoardData);
  const [search, setsearch] = useState();
  const AssignBillboardDeviceData = billBoardData?.deviceId;
  const AssignDeviceID = AssignBillboardDeviceData?._id;
  const BillBoardId = id;
  const OwnerInfo = billBoardData;

  //  ===========================================================> All Fuction <=====================================================================

  console.log("billBoardData", OwnerInfo.assignedBO);

  useEffect(() => {
    getBillBoardData();
  }, []);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const DeviceChange = (e, data) => {
    setOriginalData(data?._id);
  };

  const handleChange = (event) => {
    setAssignDevice(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseUser = () => {
    setOpenUser(false);
  };

  const handleClickOpenUser = () => {
    setOpenUser(true);
  };
  const handleChangeByPage = (event, value) => {
    console.log("Page Number Clicked ==> ", value);
    setCurrentPage(value);
    getDevices(value);
  };
  // ==================< All Api For BillBoard Details etc > ==================================================
  // =============== Get Device Info ================ //
  const getDeviceInfo = async () => {
    try {
      let resp = await axiosInstance.get(
        `/api/device/getDeviceInfo/${deviceID}`
      );
      // console.log("GetDeviceInfo () response ==> ", resp.data.msg);
      if (resp.data.msg) {
        setDeviceInfo(resp.data.msg);
      }
    } catch (error) {
      console.log("Error from GetdeviceInfo ==> ", error);
    }
  };
  const getDevices = async (value) => {
    try {
      let resp = await axiosInstance.get(
        `/api/device/getallDeviceforbillboard?page=${
          value || currentPage
        }&limit=10&search=${search ? search : null}`
      );
      // console.log(" Device RESPONSE Device ===> ", resp.data.data.result);
      setDeviceData(resp?.data?.data);
      // setTotalPage(resp?.data?.data?.length);
      setTotalPage(Math.ceil(resp?.data?.length / 10));
    } catch (error) {
      console.log("ERROR from GET DEVICE => ", error.message);
    }
  };
  // console.log("Check deviceInfo inAssign Billboard Tab", deviceData);

  const getUserDevices = async (value) => {
    try {
      let resp = await axiosInstance.get(
        `/api/user/deviceUserList?page=${currentPage}&limit=10`
      );
      setDeviceAssignUser(resp.data.msg);
    } catch (error) {
      console.log("ERROR from GET DEVICE => ", error.message);
    }
  };

  const AssignDevicetoBillboard = async () => {
    let body = { billboardId: billBoardData?._id, deviceId: originalData };
    try {
      let res = await axiosInstance.post(
        "/api/device/assignBillboardToDevice",
        body
      );
      if (res.status === 200) {
        setSnackOpen(true);
        setSnackMsg(res.data.msg);
        setOpen(false);
        handleClose();
        setBillboardAssign(res.data.msg.data);
        getBillBoardData();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.data.msg.err);
        setOpen(false);
        handleClose();
        console.log(" Device Assing to Billboard", res.data.msg);
      }
    } catch (err) {
      console.log("Error from Remove Device Function", err);
    }
  };

  const AssignDevicetoUser = async () => {
    let body = { userId: originalUserData, deviceId: AssignDeviceID };
    console.log(
      "Check Device ID in AssignDevicetoBillboard APi",
      AssignDeviceID
    );
    console.log(
      "Check Device ID in AssignDevicetoBillboard APi",
      originalUserData
    );

    try {
      let res = await axiosInstance.post(
        "/api/device/assignUserToDevice",
        body
      );
      if (res.status === 200) {
        setSnackOpen(true);
        setSnackMsg(res.data.msg);
        setOpenUser(false);
        handleCloseUser();
        setDeviceAssignUser(res.data.msg.data);
        getBillBoardData();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.data.msg.err);
        setOpenUser(false);
        handleCloseUser();
        console.log(" Device Assing to Billboard", res.data.msg);
      }
    } catch (err) {
      console.log("Error from Remove Device Function", err);
    }
  };

  const removeDevice = () => {
    setDeviceId(false);
    const deleteFunc = async () => {
      let body = {
        deviceId: AssignDeviceID,
      };
      try {
        let resp = await axiosInstance.post(
          "/api/device/removeBillboardToDevice",
          body
        );
        // console.log("Response from removeDevice ==>", resp.data.msg);
        if (resp.status === 200) {
          getBillBoardData();
          setSnackOpen(true);
          setSnackMsg(resp.data.msg);
          // console.log("response from device remove =>", resp.data.msg);
        } else {
          setSnackerropen(true);
          setSnackErrMsg(resp.data.msg.err);
          setOpen(false);
          handleClose();
          console.log(" Device Assing to Billboard", resp.data.msg);
        }
      } catch (error) {
        console.log("Error from Remove Device Function", error);
      }
    };

    deleteFunc();
  };
  const removeUser = () => {
    setDeviceId(false);
    const deleteFunc = async () => {
      let body = { userId: originalUserData, deviceId: AssignDeviceID };
      try {
        let resp = await axiosInstance.post(
          "/api/device/removeUserToDevice",
          body
        );
        // console.log("Response from removeDevice ==>", resp.data.msg);
        if (resp.status === 200) {
          getBillBoardData();
          setSnackOpen(true);
          setSnackMsg(resp.data.msg);
          // console.log("response from device remove =>", resp.data.msg);
        } else {
          setSnackerropen(true);
          setSnackErrMsg(resp.data.msg.err);
          setOpenUser(false);
          handleCloseUser();
          console.log(" Device Assing to Billboard", resp.data.msg);
        }
      } catch (error) {
        console.log("Error from Remove Device Function", error);
      }
    };

    deleteFunc();
  };

  // api calling to check clusterHead
  const getClusterHead = async (clid) => {
    try {
      const res = await axiosInstance.get(
        `api/user/getAddedByData/${clid ? clid : billBoardData?.addedBy?._id}`
      );
      if (res.status == 200 || res.status == 204) {
        setClusterHaedData(res?.data?.data?.[0]);
      } else {
      }
    } catch (err) {}
  };

  console.log("clusterinfo info", clusterHeadData);

  // api calling to check OwnerInfo
  // const getOwnerInfo = async (clid) => {
  //   try {
  //     const res = await axiosInstance.get(
  //       `api/user/getAllUsersForBO?billboardId=${
  //         clid ? clid : BillBoardId
  //       }&page=${currentPage}&limit=10`
  //     );
  //     if (res.status == 200 || res.status == 204) {
  //       setOwnerInfo(res?.data?.data?.[0]);
  //     } else {
  //     }
  //   } catch (err) {}
  // };

  // console.log("owner info", OwnerInfo);

  // useEffect(() => {
  //   getOwnerInfo();
  // }, [billBoardData]);

  useEffect(() => {
    getClusterHead();
  }, [billBoardData]);

  React.useEffect(() => {
    getDeviceInfo();
    getUserDevices();
  }, [billboardAssign]);

  React.useEffect(() => {
    getDevices();
  }, [search]);
  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={1000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid container rowGap="20px" style={{ gap: "15px" }}>
        <Grid item lg={3.8} md={3.8} sm={12} className="">
          <Card className="profileoverview-card  grey-border">
            <Typography
              component="h5"
              className="blackcolortypo Detail fs20px mt10px"
            >
              Device
            </Typography>
            <Divider className="mt10px" />
            {AssignDeviceID ? (
              <>
                <Grid container className="mt25px border-bottom pb20px">
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography className="lightgreycolortypo ">
                      Device ID
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <Grid
                      container
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `/admin/deviceprofile/${AssignBillboardDeviceData?._id}`,
                          {
                            state: { deviceData: AssignBillboardDeviceData },
                          }
                        )
                      }
                    >
                      <Typography className="blackcolor484848 ">
                        {AssignBillboardDeviceData?.deviceUID}
                      </Typography>
                      <img
                        src={Arrow}
                        style={{ height: "21px", marginLeft: "4px" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography className="lightgreycolortypo mt10px ">
                      Device Name
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <Typography className="blackcolor484848 mt10px">
                      {AssignBillboardDeviceData?.deviceName}
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography className="lightgreycolortypo mt10px ">
                      MAC ID
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <Typography className="blackcolor484848 mt10px">
                      {AssignBillboardDeviceData?.macId}
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography className="lightgreycolortypo mt10px ">
                      Added on
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <Typography className="blackcolor484848 mt10px">
                      {moment(AssignBillboardDeviceData?.createdAt).format(
                        "YYYY-MM-DD"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography className="lightgreycolortypo mt10px ">
                      Added by
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <Typography className="blackcolor484848 mt10px">
                      {AssignBillboardDeviceData?.assignedBy?.role
                        ? AssignBillboardDeviceData?.assignedBy?.role
                        : billBoardData?.addedBy?.firstName +
                          " " +
                          billBoardData?.addedBy?.lastName}
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography className="lightgreycolortypo mt10px ">
                      Status
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    {AssignBillboardDeviceData?.deviceStatus === "Online" ? (
                      <Typography className="greencolortypo mt10px">
                        Online
                      </Typography>
                    ) : (
                      <Typography className="redcolortypo mt10px">
                        Offline
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container className="mt25px border-bottom  pb-10px">
                  <Typography
                    align="center"
                    className=" width100 lightgreycolortypo"
                  >
                    No Device Assigned yet!
                  </Typography>

                  <Typography
                    align="center"
                    className=" width100 lightgreycolortypo"
                  >
                    Please follow below steps to assign Device
                  </Typography>
                  <ol>
                    <li className="greycolor505050400 ">
                      Click Below Button “Assign Device”
                    </li>
                    <li className="greycolor505050400 mt10px">
                      Select or search device UID.
                    </li>
                    <li className="greycolor505050400 mt10px">Click Confirm</li>
                    <li className="greycolor505050400 mt10px">Done</li>
                  </ol>
                </Grid>
              </>
            )}
            <Grid item xs={12} onClick={handleChange}>
              {PermissionCheck("update-billboard-assigned") && (
                <>
                  {AssignDeviceID ? (
                    <>
                      <RemoveDeviceDilaog
                        removeDevice={removeDevice}
                        setDeviceId={setDeviceId}
                      />
                    </>
                  ) : (
                    <Typography className="p15px  cursorpoint" align="center">
                      <AssignDeviceDailog
                        deviceData={deviceData}
                        getDevices={getDevices}
                        handleClose={handleClose}
                        open={open}
                        handleClickOpen={handleClickOpen}
                        DeviceChange={DeviceChange}
                        handleChangeByPage={handleChangeByPage}
                        currentPage={currentPage}
                        totalPage={totalPage}
                        AssignDevicetoBillboard={AssignDevicetoBillboard}
                        setsearch={setsearch}
                        search={search}
                      />
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          </Card>
        </Grid>

        {/* =================== Cluster =================== */}

        <Grid item lg={3.8} md={3.8} sm={12} className="">
          <Card
            className="profileoverview-card  grey-border"
            sx={{ height: "100%" }}
          >
            <Grid
              container
              alignContent="space-between"
              sx={{ height: "100%" }}
            >
              <Grid item xs={12}>
                {" "}
                <Typography
                  component="h5"
                  className="blackcolortypo Detail fs20px mt10px"
                >
                  Clusterhead
                </Typography>
                <Divider className="mt10px" />
                <Grid container className="mt25px pb20px">
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography className="lightgreycolortypo ">
                      Name
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <Typography className="blackcolor484848 ">
                      {clusterHeadData?.name
                        ? clusterHeadData?.name
                        : clusterHeadData?.firstName
                        ? `${clusterHeadData?.firstName} ${clusterHeadData?.lastName}`
                        : "--"}
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography className="lightgreycolortypo mt10px ">
                      Role
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <Typography className="blackcolor484848 mt10px">
                      {clusterHeadData?.role}
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography className="lightgreycolortypo mt10px ">
                      Email
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <Typography className="blackcolor484848 mt10px">
                      {clusterHeadData?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {PermissionCheck("update-billboard-assigned") && (
                <>
                  <Grid
                    container
                    onClick={handleChange}
                    justifyContent="center"
                    sx={{ borderTop: "1px solid #ddd" }}
                  >
                    <AssignClusterHead
                      snackStates={{
                        setSnackOpen,
                        setSnackMsg,
                        setSnackerropen,
                        setSnackErrMsg,
                      }}
                      id={id}
                      getBillBoardData={getBillBoardData}
                      getClusterHeadInfo={getClusterHead}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Card>
        </Grid>

        {/* =================== Device Owner=================== */}
        {OwnerInfo.billboardOwner === "PostMyAd" ? (
          <></>
        ) : (
          <>
            <Grid item lg={3.8} md={3.8} sm={12} className="">
              <Card
                className="profileoverview-card  grey-border"
                sx={{ height: "100%" }}
              >
                <Grid
                  container
                  alignContent="space-between"
                  sx={{ height: "100%" }}
                >
                  <Grid item xs={12}>
                    {" "}
                    <Typography
                      component="h5"
                      className="blackcolortypo Detail fs20px mt10px"
                    >
                      Owner
                    </Typography>
                    <Divider className="mt10px" />
                    <Grid container className="mt25px pb20px">
                      <Grid item md={4} sm={4} xs={4}>
                        <Typography className="lightgreycolortypo ">
                          First Name
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={8} xs={8}>
                        <Typography className="blackcolor484848 ">
                          {OwnerInfo?.assignedBO?.name
                            ? OwnerInfo?.assignedBO?.name
                            : OwnerInfo?.assignedBO?.firstName
                            ? `${OwnerInfo?.assignedBO?.firstName} `
                            : "--"}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={4} xs={4}>
                        <Typography className="lightgreycolortypo mt10px ">
                          Last Name
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={8} xs={8}>
                        <Typography className="blackcolor484848 mt10px">
                          {OwnerInfo?.assignedBO?.lastName
                            ? `${OwnerInfo?.assignedBO?.lastName} `
                            : "--"}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={4} xs={4}>
                        <Typography className="lightgreycolortypo mt10px ">
                          Role
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={8} xs={8}>
                        <Typography className="blackcolor484848 mt10px">
                          {OwnerInfo?.assignedBO?.role
                            ? `${OwnerInfo?.assignedBO?.role} `
                            : "--"}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={4} xs={4}>
                        <Typography className="lightgreycolortypo mt10px ">
                          Email
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={8} xs={8}>
                        <Typography className="blackcolor484848 mt10px">
                          {OwnerInfo?.assignedBO?.email
                            ? `${OwnerInfo?.assignedBO?.email} `
                            : "--"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {PermissionCheck("update-billboard-assigned") && (
                    <>
                      <Grid
                        container
                        onClick={handleChange}
                        justifyContent="center"
                        sx={{ borderTop: "1px solid #ddd" }}
                      >
                        <AssignOwnerInfoHead
                          snackStates={{
                            setSnackOpen,
                            setSnackMsg,
                            setSnackerropen,
                            setSnackErrMsg,
                          }}
                          id={id}
                          getBillBoardData={getBillBoardData}
                          getOwnerHeadInfo={getBillBoardData}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
