import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SideBar from "../../../component/Sidebar/Sidebar";
import Location from "./LocationTabPanel/Location";
import LocationAnalytics from "./LocationTabPanel/LocationAnalytics";
import { Breadcrumbs, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BillBoardTab() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="width-90LR">
      <div className="billboard_tab_position">
        <Grid container direction="row" justifyContent="start">
          <KeyboardBackspaceIcon
            onClick={() => navigate(-1)}
            style={{ marginRight: "8px", textAlign: "center" }}
          ></KeyboardBackspaceIcon>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              to="/admin/home"
              className="linkcolor"
              underline="hover"
              key="1"
            >
              <Typography className="purplecolortypo fs16px">
                Dashboard
              </Typography>
            </Link>
            ,<Typography className="blackcolortypo fs16px">Location</Typography>
            ,
          </Breadcrumbs>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            className="orderTab"
            aria-label="basic tabs example"
          >
            <Tab
              label="Overview"
              {...a11yProps(0)}
              className="Order_TabChangeDevice"
            />
            {/* <Tab
                label="Analytics"
                {...a11yProps(1)}
                className="Order_TabChangeDevice"
              /> */}
          </Tabs>
        </Box>
      </div>

      <div style={{ margin: "20px 0 50px 0" }}>
        <TabPanel value={value} index={0} className="superAdmin_profile_tab">
          <Location />
        </TabPanel>
        {/* <TabPanel value={value} index={1} className="superAdmin_profile_tab">
            <LocationAnalytics />
          </TabPanel> */}
      </div>
    </div>
  );
}
