import React from "react";

import { Grid, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import CouponImage from "../../../../../../assets/Img/Rectangle 8899.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import no_data_img from "../../../../../../assets/Img/no_data_found_img.png";
import CouponSkeleton from "../CouponSkeleton/CouponSkeleton";
import DeleteDilogue from "../../ActionDialogue/DeleteDialogue";
import SuccessDialog from "../../../../../../utils/Dialog/SuceedFullDialog";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import permissionUtils from "../../../../../../component/permissionUtils";

const Item = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
}));
const ActiveCoupon = ({ activeCoupon, loading, getAllCoupon }) => {
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  const [openDialogName, setOpenDialog] = React.useState(null);
  const [copiedCode, setCopiedCode] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        sx={{
          border: "1px solid #ddd",
          padding: "15px",
          borderRadius: "8px",
        }}
      >
        <Grid item xs={12}>
          <Typography className="blackcolortypo fs24px">
            Active Coupons & Offers
          </Typography>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          {activeCoupon && activeCoupon?.length > 0 ? (
            <>
              {activeCoupon?.map((cData, i) => (
                <Grid
                  item
                  key={i}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={2.4}
                  sx={{ cursor: "pointer" }}
                >
                  <Item className="relative">
                    <Grid
                      container
                      style={{
                        border: "1px solid #ddd",
                        borderBottom: "none",
                        borderRadius: "8px 8px 0 0",
                      }}
                    >
                      <Grid xs={12}>
                        <img
                          src={CouponImage}
                          style={{ width: "100%", height: "100%" }}
                          alt="coupon image"
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Typography
                          className="center-text blackcolortypo500 fs16px"
                          sx={{ textTransform: "uppercase" }}
                        >
                          {cData?.couponName}
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography className="center-text redColorTypo fs32px">
                          {cData?.discount}%
                        </Typography>
                      </Grid>

                      <Grid xs={12}>
                        <Typography className="center-text greycolortypo6F6F6F_500 fs12px">
                          Up to {cData?.couponAmount} off
                        </Typography>
                        <Typography className="center-text greycolortypo6F6F6F_500 fs12px">
                          On a minimum spend of {cData?.minSpend}
                        </Typography>
                      </Grid>
                      <Grid xs={12} style={{ margin: "8px 0" }}>
                        <Typography className="center-text blackcolortypo500 fs10px">
                          expires on {cData?.endDate?.split("T")[0]}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        borderRadius: "0 0 8px 8px",
                        padding: "10px 0",
                      }}
                      gap="15px"
                      className="purple-gradient"
                    >
                      <Typography className="whitecolortypo500 fs14px">
                        Code : {cData?.couponCode}
                      </Typography>
                      {copiedCode == cData?.couponCode ? (
                        <Typography
                          className="fs14px "
                          style={{ color: "white" }}
                        >
                          Copied
                        </Typography>
                      ) : (
                        <ContentCopyIcon
                          onClick={() => {
                            navigator.clipboard.writeText(cData?.couponCode);
                            setCopiedCode(cData?.couponCode);
                          }}
                          style={{ color: "white", fontSize: "20px" }}
                        />
                      )}
                    </Grid>
                    <div className="coup_del_btn">
                      {PermissionCheck("delete-coupons") && (
                        <DeleteDilogue
                          id={cData._id}
                          type="coupon"
                          getCouponApi={getAllCoupon}
                          setOpenDialog={setOpenDialog}
                        />
                      )}
                    </div>
                  </Item>
                </Grid>
              ))}
            </>
          ) : (
            loading && <CouponSkeleton />
          )}
        </Grid>
        {activeCoupon.length === 0 && loading == false && (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={4}>
              <img src={no_data_img} style={{ width: "100%" }} />
            </Grid>
          </Grid>
        )}
      </Grid>
      <SuccessDialog
        openData={openDialogName === "success"}
        data={"Coupon deleted successfully"}
        parentNavigate={true}
      />
    </>
  );
};

export default ActiveCoupon;
