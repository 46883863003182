import * as React from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Grid3x3 } from "@mui/icons-material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 1.2,
        background:
          " linear-gradient(93.22deg, #E141C3 -4.65%, #B937FA 53.05%, #6906C3 111.04%)",
      }}
      {...other}
    >
      {children}
      <Typography className="whitecolortypo500 fs18px">Preview</Typography>{" "}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: -2,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PreviewDialogue({ vdo, size }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log(typeof size, "sizesizesizesize");
  return (
    <div style={{ width: "100%" }}>
      <Button
        onClick={handleClickOpen}
        sx={{
          width: "100%",
          borderRadius: "8px !important",
          padding: "10px 8px !important",
        }}
        className="order-purple-btn"
      >
        Check out preview
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle onClose={handleClose}> </BootstrapDialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "20px",
          }}
        >
          <Grid container justifyContent="center">
            {/* <div class="video-wrapper"> */}
            {/* <ReactPlayer url={vdo4} height='1920px' width='1080px'/> */}
            <video
              controls
              autoplay
              muted
              src={vdo}
              className={
                size == "1920X1080" ? "landScape_video" : "portrait_video"
              }
              style={{
                background: "black",
                // objectFit: "fill",
              }}
            ></video>
            {/* </div> */}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
