import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  Input,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from "@mui/material";

export default function Testing(props) {
  const { inputRefs } = props.data;
  useEffect(() => {
    console.log(props.data.clusterHeadErr);
  }, [props.data.clusterHeadErr]);
  const onChangeNumberField = (e, dataType) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (dataType == "mobileNum") {
        props.data.handleClusterData(e.target.value, "mobilenumber");
      } else if (dataType == "adharCard") {
        props.data.handleClusterData(e.target.value, "adharCard");
      } else if (dataType == "pinCode") {
        props.data.handleClusterData(e.target.value, "pincode");
      }
    }
  };

  return (
    <>
      <div>
        <div className="admin-head">
          <Typography className="whitecolortypo500 fs16px">
            Sub-Admin Details
          </Typography>
        </div>
        <div className="admin-content">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography className="greycolor505050500 fs16px  ">
                Sub-Admin Type *
              </Typography>
              <FormControl className="MainPageFormControl mt10px " size="small">
                <TextField
                  select
                  disabled
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  className="Selectdropstyle"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={10}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={10} className="Selectmenustyle" disabled>
                    SubAdmin
                  </MenuItem>
                </TextField>
              </FormControl>
              <Typography className="greycolor505050500 fs16px mt10px">
                First Name*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[0] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter First Name of Admin"
                value={props.data.firstName}
                onChange={(e) => {
                  props.data.handleClusterData(e.target.value, "firstname");
                }}
              />
              {props.data.firstnameErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter the firstname here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Last Name*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[1] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter Last Name of Admin"
                value={props.data.lastName}
                onChange={(e) => {
                  props.data.handleClusterData(e.target.value, "lastname");
                }}
              />
              {props.data.lastnameErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please Enter Your Lastname"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Mobile Number*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[2] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter your Mobile Number"
                value={props.data.phoneNumber}
                inputProps={{ maxLength: 10 }}
                onChange={(e) => {
                  onChangeNumberField(e, "mobileNum");
                }}
              />
              {props.data.existPhoneNum && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"This num already exists"}
                </Typography>
              )}
              {props.data.phoneNumberErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter the 10 digit Phone Number here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Email Address*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[3] = ref)}
                type="email"
                className="inputstyle "
                disableUnderline
                placeholder="Example : xyz@loreum.com"
                value={props.data.email}
                onChange={(e) => {
                  props.data.handleClusterData(e.target.value, "email");
                }}
              />
              {props?.data?.existEmail && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"This email already exists"}
                </Typography>
              )}
              {props.data.emailErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter the correct Email here"}
                </Typography>
              )}

              <Typography className="greycolor505050500 fs16px mt10px">
                Aadhar Card Number*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[4] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter Your  Aadhar Card Number"
                value={props.data.adharCard}
                inputProps={{ maxLength: 12 }}
                onChange={(e) => {
                  onChangeNumberField(e, "adharCard");
                }}
              />
              {props.data.adharCardErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter your 12 digits Aadhar Card Number"}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="greycolor505050500 fs16px ">
                Address*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[5] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter your Address"
                value={props.data.address}
                onChange={(e) => {
                  props.data.handleClusterData(e.target.value, "address");
                }}
              />
              {props.data.addressErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter the Address here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Landmark*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[6] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="nearby landmark"
                value={props.data.landmark}
                onChange={(e) => {
                  props.data.handleClusterData(e.target.value, "landmark");
                }}
              />
              {props.data.landmarkErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter your Landmark here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Pincode*
              </Typography>
              <Input
                className="inputstyle "
                disableUnderline
                type="number"
                placeholder="Pincode"
                value={props.data.pincode}
                onChange={(e) => {
                  if (String(e.target.value)?.length <= 6) {
                    props.data.handleClusterData(e.target.value, "pincode");
                  }
                }}
              />
              {(props.data.pincodeErr || props.data.checkForPinCode) && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter your correct 6 digit pincode here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Country
              </Typography>
              <Input
                className="inputstyle "
                disableUnderline
                placeholder="Country"
                value={"India"}
                disabled
              />
              <Typography className="greycolor505050500 fs16px mt10px">
                State
              </Typography>
              <Input
                className="inputstyle "
                disableUnderline
                placeholder="State"
                value={props.data.adminState}
                disabled
              />
              <Typography className="greycolor505050500 fs16px mt10px">
                City
              </Typography>
              <Input
                className="inputstyle "
                disableUnderline
                placeholder="City"
                value={props.data.adminCity}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="I have read the terms and conditions and agree to abide by them."
                  name="radio-buttons-group"
                  className="radiostyle"
                >
                  <FormControlLabel
                    className="radiostyle"
                    value="female"
                    control={<Radio checked={props.data.agreement} />}
                    label="I have read the terms and conditions and agree to abide by them."
                    //value={props.data.agreement}
                    onClick={(e) => {
                      props.data.handleClusterData(
                        !props.data.agreement,
                        "agreement"
                      );
                    }}
                  />
                </RadioGroup>
              </FormControl>
              {props.data.agreementErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please Enable Agreement first"}
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
