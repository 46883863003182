import React, { useState } from "react";

import { Typography, Grid, Card, IconButton, CardActions } from "@mui/material";
// Core Images
import Pedestal from "../../../../../assets/Img/Pedestal.png";
import Monopole from "../../../../../assets/Img/Monopole.png";
import Flag from "../../../../../assets/Img/Flag.png";
import Spectacular from "../../../../../assets/Img/Spectacular.png";

export default function Indoor({ formdata, handleBillBoardType }) {
  const [ImageArray, setImageArray] = React.useState([
    {
      name: "Pedestal",
      image: Monopole,
    },
    {
      name: "Monopole",
      image: Monopole,
    },
    {
      name: "Flag",
      image: Flag,
    },
    {
      name: "SPECTACULAR",
      image: Spectacular,
    },
  ]);

  const [selectedDiv, setSelectedDiv] = useState(formdata.billboardType);

  // React.useEffect(() => {
  //   console.log("=+= After Change Outdoor =+=", selectedDiv);
  // }, [selectedDiv])

  return (
    <>
      <Grid container>
        <Typography
          align="center"
          className="greycolor505050500 fs16px width100"
        >
          Select Billboard Type
        </Typography>

        <Grid
          style={{ marginTop: "20px" }}
          container
          justifyContent="space-between"
          rowGap="10px"
        >
          {ImageArray.map((item, i) => (
            <Grid item xs={12} sm={5.8} md={3.8} lg={2.8} key={i}>
              {item.name === selectedDiv ? (
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.45) 0px 5px 15px",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setSelectedDiv(item.name);
                    handleBillBoardType(item.name);
                  }}
                >
                  <>
                    <Card className="billboardImage-card  ">
                      <img src={item.image} alt="Kidsk" className="width100" />
                    </Card>
                    <CardActions className="billboardaction">
                      <Typography
                        className="greycolor505050500 fs16px width100"
                        align="center"
                      >
                        {item.name}
                      </Typography>
                    </CardActions>{" "}
                  </>
                </div>
              ) : (
                <div
                  className="Cardhover"
                  onClick={() => {
                    setSelectedDiv(item.name);
                    handleBillBoardType(item.name);
                  }}
                >
                  <Card className="billboardImage-card  ">
                    <img src={item.image} alt="Kidsk" className="width100" />
                  </Card>
                  <CardActions className="billboardaction">
                    <Typography
                      className="greycolor505050500 fs16px width100"
                      align="center"
                    >
                      {item.name}
                    </Typography>
                  </CardActions>{" "}
                </div>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
