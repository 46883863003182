import React, { useState, useEffect } from "react";

import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 0, background: "#fffff" }}
      {...other}
      className="purple-gradient"
    >
      {children}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: "0 20px" }}
      >
        <Grid item>
          {" "}
          <Typography className="whitecolortypo500">Publish Time</Typography>
        </Grid>
        <Grid item>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: "#DDDDDD",
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function TimeSlotTimeStamp(props) {
  const [open, setOpen] = useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  const [displayTime, setDisplayTime] = React.useState(null);
  const [displayTimes, setDisplayTimes] = useState([]);
  //   const [startscheduleDate, setstartscheduleDate] = React.useState();
  //   const [startHour, setstartHour] = React.useState();

  const handleClose = () => {
    setOpen(false);
  };

  function convertSecondsToTime(arr, startscheduleDate, startHour) {
    let time = [];
    if (Array.isArray(arr) && arr.length > 0 && typeof arr[0] === "object") {
      for (let item of arr[0][Object.keys(arr[0])]) {
        if (startHour < 10) {
          time.push(
            moment(`${startscheduleDate}T0${startHour}:00:00+05:30`)
              .add(item, "seconds")
              .format("LTS")
          );
        } else {
          time.push(
            moment(`${startscheduleDate}T${startHour}:00:00+05:30`)
              .add(item, "seconds")
              .format("LTS")
          );
        }
      }
    }
    return time;
  }

  console.log("times for slots", displayTime);
  const reversedTime = [...(displayTimes || [])].reverse(); // Clone and reverse the array

  return (
    <React.Fragment>
      <Grid
        container
        style={{
          border: "1px solid #red",
        }}
      >
        <Button
          className="purple_btn_primary text_transform_inherit"
          sx={{
            color: "#000",
          }}
          onClick={() => setOpen(true)}
        >
          See More
        </Button>
      </Grid>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <Divider />
        <DialogContent>
          <Typography
            component="h5"
            className="secondary_heading500  fs20px "
            style={{ marginBottom: "10px" }}
          >
            Advertisement will play at :
          </Typography>

          {props &&
            props?.detail?.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ marginBottom: "10px", backgroundColor: "#F7F8FD" }}
              >
                <Accordion
                  sx={{ border: "1px solid #DDDDDDDD ", boxShadow: "none" }}
                  onChange={() => {
                    // Create a copy of the current displayTimes array
                    const newDisplayTimes = [...displayTimes];
                    newDisplayTimes[index] = convertSecondsToTime(
                      item?.seconds,
                      item?.newDate,
                      item?.startHour
                    );
                    setDisplayTimes(newDisplayTimes);
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    key={index}
                    sx={{ backgroundColor: "#F7F8FD" }}
                  >
                    <Typography className="secondary_graySubheading_500">
                      {" "}
                      {item?.actualTime} x {item?.repetitions}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "#F7F8FD" }}>
                    <Grid container spacing={2}>
                      {displayTimes[index]?.length > 0 ? (
                        [...displayTimes[index]]
                          .reverse()
                          .map((item, index) => (
                            <Grid
                              item
                              xs={4}
                              sm={3}
                              md={2}
                              lg={1.3}
                              key={index}
                            >
                              <Button
                                className="Time-Stamp"
                                sx={{ width: "100%" }}
                              >
                                {item}
                              </Button>
                            </Grid>
                          ))
                      ) : (
                        <Grid
                          container
                          justifyContent="center"
                          sx={{ paddingTop: "10px" }}
                        >
                          {props?.status === "Expired" ||
                          props?.status === "Cancelled" ||
                          props?.status === "Rejected" ? (
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: "20px",
                                  color: "#FF7F37",
                                }}
                              >
                                Not available for expired,cancelled and rejected
                                orders.
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: "20px",
                                  color: "#FF7F37",
                                }}
                              >
                                Pending - Will be available after some time.
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
