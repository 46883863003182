import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, Stack, Pagination } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Calendar from "../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
import axiosInstance from "../../../../../api/axiosInstance";
import moment from "moment";
import no_data_img from "../../../../../assets/Img/no_data_found_img.png";
const headCells = [
  {
    id: "Action",
    numeric: true,
    label: "Action ",
  },
  {
    id: "OldValue",
    numeric: true,
    disablePadding: false,
    label: "Old Value",
  },
  {
    id: "newValue",
    numeric: true,
    disablePadding: false,
    label: "New Value",
  },
  {
    id: "User",
    numeric: true,
    disablePadding: false,
    label: "User",
  },
  {
    id: "Plateform",
    numeric: true,
    disablePadding: false,
    label: "Plateform",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created At",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              align={headCell.id == "Action" ? "left" : "center"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Billlogs = ({ props }) => {
  const billboardId = props?.id;
  console.log("proppp", props);
  ///////////////////////// table states ///////////////////////////////////////
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [dense, setDense] = React.useState(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [logData, setLogData] = useState([]);
  const [totalLength, setTotalLength] = useState([]);
  const [totalPage, setTotalPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const handleChangeByPage = (event, page) => {
    setCurrentPage(page);
  };
  ///////////////////////// table states ///////////////////////////////////////
  const [date, setDate] = useState();
  ////////////////// sending this func to date range picker //////////////////////
  const getDataFromChildHandler = (data) => {
    setDate(data);
  };
  // ////////////// api for user logs ////////////////////////////////////////////
  const userLogs = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getBillboardLogs?billboardId=${billboardId}&start_date=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&end_date=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}&limit=10&page=${currentPage}`
      );
      if (res.status == 200) {
        setTotalLength(res?.data?.length);
        setTotalPage(Math.ceil(res?.data?.length / 10));
        setLogData(res?.data?.data);
      } else {
        console.log("erorr while getting user logs");
      }
    } catch (err) {
      console.log("error while getting userLogs", err);
    }
  };
  useEffect(() => {
    if (props?.id && date?.[0]?.startDate && date?.[0]?.endDate) {
      userLogs();
    }
  }, [props?.id, date, currentPage]);
  // ////////////// api for user logs ////////////////////////////////////////////
  return (
    <>
      <Grid container rowGap="20px">
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography className="secondary_heading500 fs18px">
            Showing {logData?.length} out of {totalLength} Logs
          </Typography>
          <Calendar getDataFromChildHandler={getDataFromChildHandler} />
        </Grid>
        <Grid container>
          <EnhancedTableToolbar />
          <TableContainer className="width100 hide_sortBar table_border_radius">
            <Table
              sx={{
                minWidth: 800,
                backgroundColor: "#fff",
                width: "100%",
              }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                className="tablerow"
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
              />

              <TableBody className="tablerow ">
                {logData?.map((tData, i) => (
                  <TableRow
                    key={i}
                    hover
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      component="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Typography>{tData?.action}</Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      align="center"
                      // id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Typography> {tData?.oldValue}</Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      component="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Typography> {tData?.newValue}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Typography>{tData?.userId?.email}</Typography>
                      <Typography>{tData?.userId?.role}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      padding="none"
                    >
                      <Typography>{tData?.platform}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Typography>
                        {" "}
                        {moment(tData?.createdAt)?.format("DD/MM/YYYY, h:mm a")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {logData && logData?.length === 0 && (
              <Grid container justifyContent="center">
                {" "}
                <img
                  src={no_data_img}
                  alt="no data found"
                  style={{ width: "40%" }}
                />
              </Grid>
            )}
          </TableContainer>
        </Grid>

        {logData?.length > 0 && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ display: "flex", marginTop: "20px", marginBottom: "20px" }}
          >
            <Stack spacing={2}>
              <Pagination
                page={currentPage}
                count={totalPage}
                onChange={handleChangeByPage}
                size="large"
              />
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Billlogs;
