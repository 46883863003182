import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
const TimeSlots = ({ handleFilterData, timeSlot }) => {
  const arr = [
    { time: "12-01 AM", id: 0 },
    { time: "01-02 AM", id: 1 },
    { time: "02-03 AM", id: 2 },
    { time: "03-04 AM", id: 3 },
    { time: "04-05 AM", id: 4 },
    { time: "05-06 AM", id: 5 },
    { time: "06-07 AM", id: 6 },
    { time: "07-08 AM", id: 7 },
    { time: "08-09 AM", id: 8 },
    { time: "09-10 AM", id: 9 },
    { time: "10-11 AM", id: 10 },
    { time: "11-12 PM", id: 11 },
    { time: "12-01 PM", id: 12 },
    { time: "01-02 PM", id: 13 },
    { time: "02-03 PM", id: 14 },
    { time: "03-04 PM", id: 15 },
    { time: "04-05 PM", id: 16 },
    { time: "05-06 PM", id: 17 },
    { time: "06-07 PM", id: 18 },
    { time: "07-08 PM", id: 19 },
    { time: "08-09 PM", id: 20 },
    { time: "09-10 PM", id: 21 },
    { time: "10-11 PM", id: 22 },
    { time: "11-12 AM", id: 23 },
  ];
  const [selectTimeSlot, setSelectTimeSlot] = useState([]);

  const selectTimeSlotHandler = (timeSlot, i) => {
    // console.log(timeSlot, "timeSlottimeSlot");
    const arr = [...selectTimeSlot];
    const tempArr = selectTimeSlot.findIndex((a) => a == timeSlot.id);
    console.log(tempArr.id, "tempArr");
    if (tempArr === -1) {
      arr.push(timeSlot.id);
    } else {
      arr.splice(tempArr, 1);
    }
    handleFilterData(arr, "timeSlot");
    setSelectTimeSlot(arr);
  };

  console.log(selectTimeSlot, "selectTimeSlotselectTimeSlot");
  console.log(timeSlot, "timeSlottimeSlot");
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        rowGap="10px"
        width="550px"
      >
        {arr.map((t, i) => (
          <Grid container item key={t.id} xs={2.8} justifyContent="center">
            <Button
              className={
                timeSlot?.find((a) => a == t.id)
                  ? "time_slot_select_class"
                  : timeSlot?.find((a) => a == t.id) === 0
                  ? "time_slot_select_class"
                  : "filter_time_slot_div"
              }
              style={{ width: "100%" }}
              onClick={() => selectTimeSlotHandler(t, i)}
            >
              {t.time}
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default TimeSlots;
