import React, { useState } from "react";

import { Tabs, Tab, DialogContent } from "@mui/material";
//

import TabContext from "@mui/lab/TabContext";

import Logs from "./Logs";
import { TabPanel } from "@mui/lab";
import FilesLog from "./FilesLog";

const OrderLogs = ({ deviceData }) => {
  // table states
  const [value, setvalue] = useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };

  return (
    <>
      <TabContext value={value}>
        <Tabs
          onChange={handleChange}
          value={value}
          centered
          className="TabStyleAddDevice"
        >
          <Tab
            value={0}
            label="Logs"
            sx={{
              borderRadius: "50px 0px 50px 0px",
              marginBottom: "20px",
            }}
            className="TabChangesDeviceLive"
          />
          <Tab
            label="Files"
            value={1}
            sx={{
              borderRadius: "50px 0px 50px 0px",
              marginBottom: "20px",
            }}
            className="TabChangesDeviceLive"
          />
        </Tabs>

        <DialogContent style={{ padding: "0px" }}>
          <TabPanel value={0} index={0}>
            <Logs deviceData={deviceData} />
          </TabPanel>
          <TabPanel value={1} index={1}>
            <FilesLog deviceData={deviceData} />
          </TabPanel>
        </DialogContent>
      </TabContext>
    </>
  );
};

export default OrderLogs;
