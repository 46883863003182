import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axiosInstance from "../../../../../api/axiosInstance";
import Calendar from "../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
import moment from "moment";
// temp
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    xAxes: [{ stacked: true }],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        stacked: false,
      },
    ],
  },
};

const BarChart = ({ donutData, country, state, city }) => {
  const [dateType, setDateType] = useState("Orders");
  const [ratio, setRatio] = useState("All");
  let barChartData = [];
  const [datasets, setDataSets] = useState([
    {
      label: "Accepted",
      data: [],
      borderColor: "rgb(95,202,93,100%)",
      backgroundColor: "rgba(95,202,93,100%)",
      borderRadius: 20,
      barThickness: 24,
    },
  ]);

  const [analyticsData, setAnalyticsData] = useState([]);
  const [date, setDate] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const timeInHour = [
    "12:00 Am",
    "1:00 Am",
    "2:00 Am",
    "3:00 Am",
    "4:00 Am",
    "5:00 Am",
    "6:00 Am",
    "7:00 Am",
    "8:00 Am",
    "9:00 Am",
    "10:00 Am",
    "11:00 Am",
    "12:00 Pm",
    "1:00 Pm",
    "2:00 Pm",
    "3:00 Pm",
    "4:00 Pm",
    "5:00 Pm",
    "6:00 Pm",
    "7:00 Pm",
    "8:00 Pm",
    "9:00 Pm",
    "10:00 Pm",
    "11:00 Pm",
  ];
  const data = {
    labels: analyticsDate?.length == 24 ? timeInHour : analyticsDate,
    datasets: datasets,
  };
  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };
  /////////////////////// locationGraph ////////////////////////////////

  const locationGraph = async () => {
    try {
      let body = {
        start_date: moment(date?.[0]?.startDate).format("YYYY-MM-DD"),
        end_date: moment(date?.[0]?.endDate).format("YYYY-MM-DD"),
        country: country ? country : "All",
        state: state ? state : "All",
        city: city ? city : "All",
        type: dateType,
        dropDown: ratio,
      };
      const res = await axiosInstance.post(
        `/api/billboard/getGraphDataByLocation`,
        body
      );
      if (res.status == 200) {
        setAnalyticsData(res?.data?.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  /////////////////////// locationGraph ////////////////////////////////
  useEffect(() => {
    if (date) {
      locationGraph();
    }
  }, [ratio, dateType, date]);

  // locationGraph
  // locationGraph
  // locationGraph

  useEffect(() => {
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = analyticsData.findIndex(
        (a, b) => a._id == analyticsDate?.[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate?.[i],
          count: analyticsData?.[index]?.count,
        };
        barChartData.push(obj);
      } else {
        let obj = {
          _id: analyticsDate?.[i],
          count: 0,
        };
        barChartData.push(obj);
      }
    }
  }, [analyticsData]);

  useEffect(() => {
    setDataSets([
      {
        label: `${dateType} ${barChartData?.reduce((accum, curr) => {
          return (accum += curr?.count);
        }, 0)}`,
        data: barChartData?.map((a) => a?.count),
        borderColor: "rgb(95,202,93,100%)",
        backgroundColor: "rgba(95,202,93,100%)",
        borderRadius: 20,
        barThickness: 24,
      },
    ]);
  }, [analyticsData]);

  return (
    <>
      <Grid container sx={{ height: "100%" }} gap="40px">
        <Grid item xs={12}>
          <div>
            {" "}
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              rowGap="10px"
            >
              {" "}
              <Grid item>
                <Grid container gap="15px" direction="column">
                  {" "}
                  <Typography className="gray5A5A5Atypo500 fs20px">
                    Billboard Distribution
                  </Typography>{" "}
                  <Grid item>
                    {" "}
                    <Grid container gap="15px">
                      <Button
                        className={
                          dateType === "Billboard"
                            ? "select_date_filter_class"
                            : "filterButtonDesign"
                        }
                        onClick={() => setDateType("Billboard")}
                      >
                        {" "}
                        Billboards
                      </Button>
                      <Button
                        className={
                          dateType === "Orders"
                            ? "select_date_filter_class"
                            : "filterButtonDesign"
                        }
                        onClick={() => setDateType("Orders")}
                      >
                        {" "}
                        Orders
                      </Button>
                      <Button
                        className={
                          dateType === "Views"
                            ? "select_date_filter_class"
                            : "filterButtonDesign"
                        }
                        onClick={() => setDateType("Views")}
                      >
                        {" "}
                        Views
                      </Button>
                      <Button
                        className={
                          dateType === "Amount"
                            ? "select_date_filter_class"
                            : "filterButtonDesign"
                        }
                        onClick={() => setDateType("Amount")}
                      >
                        {" "}
                        Amount
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" gap="15px">
                  {" "}
                  <Calendar getDataFromChildHandler={getDataFromChildHandler} />
                  <FormControl
                    className="billboard_price_filter"
                    sx={{ width: "150px !important" }}
                  >
                    <TextField
                      select
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      className="Selectdropstyle"
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={ratio}
                      onChange={(e) => {
                        setRatio(e.target.value);
                      }}
                    >
                      <MenuItem
                        value={"All"}
                        className="greycolor505050500 fs14px"
                      >
                        All
                      </MenuItem>
                      {donutData?.map((a, i) => (
                        <MenuItem
                          key={i}
                          value={a?._id}
                          className="greycolor505050500 fs14px "
                        >
                          {a?._id}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Bar options={options} data={data} />
      </Grid>
    </>
  );
};

export default BarChart;
