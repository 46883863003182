import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField } from "@mui/material";

import axiosInstance from "../../../../../api/axiosInstance";
import FilesDialog from "./FilesDialog";

import no_data_img from "../../../../../assets/Img/no_data_found_img.png";

function FilesLog({ deviceData }) {
  const [folder, setFolder] = useState([]);
  const [files, setfiles] = useState([]);

  const getFilesData = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/device/getAwsFolderNames/${deviceData?.macId}`
      );
      console.log("folder", res);
      if (res.status == 200 || res.status == 204) {
        setFolder(res?.data?.data);
      }
    } catch (err) {
      console.log("error from getGraphdata", err);
    }
  };
  const getFiles = async (date) => {
    try {
      const res = await axiosInstance.get(
        `/api/device/getAwsLogsFile?macId=${deviceData?.macId}&date=${date}`
      );
      console.log("files", res);
      if (res.status == 200 || res.status == 204) {
        setfiles(res?.data?.data);
      }
    } catch (err) {
      console.log("error from getGraphdata", err);
    }
  };
  useEffect(() => {
    getFilesData();
  }, []);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        padding="10px"
        alignItems="center"
      >
        <Typography className="secondary_heading500 fs18px">
          Showing {folder ? folder?.length : 0} folder
        </Typography>
      </Grid>
      <Grid container rowGap="20px" className="fileLogs" gap={3.5}>
        {folder?.map((item, index) => {
          return (
            <Grid alignItems="center">
              <FilesDialog item={item} getFiles={getFiles} files={files} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default FilesLog;
