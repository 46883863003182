import React from "react";
//React Icons
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { FaRegUserCircle } from "react-icons/fa";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { DiHtml5DeviceAccess } from "react-icons/di";
import { TiDeviceDesktop } from "react-icons/ti";
import { VscClippy } from "react-icons/vsc";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CampaignIcon from "@mui/icons-material/Campaign";
import { MdOutlineLocationOn } from "react-icons/md";
import otherCampaignIcon from "./assets/Img/other_campaigns.png";
import otherOrderIcon from "./assets/Img/other_orders.png";

// core component
import Home from "./views/Home/Home";
import AdAgency from "./views/Home/AddUserDialog/AdAgency/Ad-Agency";
import AddClusterhead from "./views/Home/AddUserDialog/AddCluseterHead/Clusterhead";
import AddSubAdmin from "./views/Home/AddUserDialog/AddSub-Admin/SubAdmin";
import BuisnesssOwner from "./views/Home/AddUserDialog/AddBuisnessOwner/BuisnessOwner";
import CreateCampaign from "./views/Home/CreateCampaign/CreateCampaign";
// ** Device
import Device from "./views/Home/Device/Device";
import DeviceProfile from "./views/Home/Device/DeviceProfile/AllDeviceProfuile";
//Add Bill Board
import AddBillBoard from "./views/Home/Bill-Board-Add/Add-Bill-Board";
import AddBurnerAd from "./views/Home/Bill-Board-Add-Burner-Ad/AddBurnerAd";
// BiilBoard Details
import BillBoardTab from "./views/Home/BillboardDetail/BillBoardTab";
import BillboardItem from "./views/Home/BillboardDetail/BillBoardSceenDetails/BillboardProfileTab";
import MBookingModify from "./views/Home/BillboardDetail/BillBoardSceenDetails/BillboardScreenTab's/ModifyBooking.js/MBookingtDetail";
//Campaign  Management

// new changes in camp
import Camp_Details from "./views/Home/CampaigMgt/CampDetail/Camp_Details";
///////////////////////////////////////// Content Creater //////////////////////////////////////////////////////////////////////
// Order Management
import OrderDetails from "./views/Home/Order/OrderDetails/Order-Details";
import OrderTab from "./views/Home/Order/OrderTab";
//User  Manangment
import UserDashboard from "./views/Home/UserMgt/UserDashboard";
import ManageUser from "./views/Home/UserMgt/Manage-User/ManageUser";
import ManageUserProfile from "./views/Home/UserMgt/Utils/AllUserProfiles";

//Map
import Loctaion from "./views/Home/Map/Map";
//Notification
import Notification from "./views/Home/Notification/notification";
//Form
// Location
import LocationTab from "./views/Home/Location/LocationTab";
// Finance
import Finance from "./views/Home/Finance/Finance";
import FinanceTransaction from "./views/Home/Finance/Finance";
import FinanceAnalytics from "./views/Home/Finance/Finance";
//coupon
import DiscountIcon from "@mui/icons-material/Discount";
import CouponVoucherTab from "./views/Home/Coupon&Voucher/Coupon&VoucherTab";
import AddVoucher from "./views/Home/Coupon&Voucher/Coupon&VocherTabPanels/VoucherTabPanel/VoucherTableTab/VoucherActions/AddVoucher";
import AddCoupon from "./views/Home/Coupon&Voucher/Coupon&VocherTabPanels/CouponTabPanel/CouponTableTab/CouponActions/AddCouponDialogue";
import WishList from "./views/Home/WishList/WishList";
// Profile
import Profile from "./views/Home/Profile/Profile";
// Analytics
import TimelineIcon from "@mui/icons-material/Timeline";
import Analytics from "./views/Home/Analytics/Analytics";
// Analytics
import Campaign_Tab from "./views/Home/CampaigMgt/Campaign_Tab";
import ApkManager from "./views/Home/ApkManager";
import SelectTimeSlot from "./views/Home/BillboardDetail/BillBoardSceenDetails/BillboardScreenTab's/timeSlot/index";
// Gallery
import GalleryIndex from "./views/Home/Gallery";
// Trash
import Trashindex from "./views/Home/Trash";
import Tutorial from "./views/Home/UploadTutorial";
const userData = JSON.parse(localStorage.getItem("userData"));

const Role = userData?.user?.userRole;
let routes = [
  // ** Navbar Routes
  {
    link: "/admin/apk-manager",
    Element: <ApkManager />,
  },
  {
    link: "/admin/upload-tutorial",
    Element: <Tutorial />,
  },
  {
    link: "/admin/createcampaign",
    Element: <CreateCampaign />,
    name: "Campaign",
    key: "create-campaign",
  },

  // ** Navbar DropDown Routes
  {
    link: "/admin/notification",
    Element: <Notification />,
  },

  {
    link: "/admin/Profile",
    Element: <Profile />,
  },
  {
    link: "/admin/WishList",
    Element: <WishList />,
  },
  {
    link: "/admin/userdashborad",
    Element: <UserDashboard />,
    name: "User",
  },

  // ** Sidebar Routes
  {
    invisible: false,
    name: "Dashboard",
    link: "/admin/home",
    Element: <Home />,
    icon: <MdOutlineDashboardCustomize />,
  },

  {
    invisible: false,
    key: "view-device",
    link: "/admin/device",
    Element: <Device />,
    icon: <DiHtml5DeviceAccess />,
    name: "Device",
  },
  {
    invisible: false,
    link: "/admin/locationState",
    Element: <LocationTab />,
    icon: <LocationCityIcon />,
    name: "Location",
  },
  {
    invisible: false,
    key: "view-finance-overview",
    link: "/admin/finance",
    Element: <Finance />,
    icon: <CurrencyRupeeIcon />,
    name: "Finance",
  },
  {
    invisible: false,
    key: "view-coupons",
    link: "/admin/CouponVocher",
    Element: <CouponVoucherTab />,
    icon: <DiscountIcon />,
    name: "Coupon",
  },
  {
    invisible: false,
    key: "view-live-billboard",
    link: "/admin/billboardscreens",
    Element: <BillBoardTab />,
    icon: <TiDeviceDesktop />,
    name: "Billboard",
  },

  {
    invisible: false,
    key: "view-live-order",
    link: "/admin/order",
    Element: <OrderTab />,
    icon: <VscClippy />,
    name: Role === 2 ? "My Order" : "Order",
  },
  {
    invisible: false,
    key: "view-user-profile",
    link: "/admin/manage-user",
    icon: <FaRegUserCircle />,
    name: "User",
    Element: <ManageUser />,
  },
  {
    invisible: false,
    key: "view-live-campaign",
    link: "/admin/campaignmgt",
    Element: <Campaign_Tab />,
    icon: <CampaignIcon />,
    name: Role === 2 ? "My Campaign" : "Campaign",
  },
  {
    invisible: false,
    link: "/admin/location",
    Element: <Loctaion />,
    icon: <MdOutlineLocationOn />,
    name: "Map",
  },
  {
    invisible: false,
    link: "/admin/analytics",
    icon: <TimelineIcon />,
    Element: <Analytics />,
    name: "Analytics",
  },

  {
    link: "/admin/deviceprofile/:id",
    Element: <DeviceProfile />,
    name: "Device",
  },

  // ** Other  Finance Routes

  {
    link: "/admin/financeTransaction",
    key: "view-finance-transaction",
    Element: <FinanceTransaction value={4} />,
  },
  {
    link: "/admin/financeAnalytics",
    key: "view-finance-analytics",
    Element: <FinanceAnalytics value={3} />,
  },
  // {
  //   link: "/admin/newadminhome",
  //   Element: <NewAdminHome />,
  // },

  // ** Other  Coupons Routes
  {
    link: "/admin/CouponVocher/AddVoucher",
    key: "create-voucher",
    Element: <AddVoucher />,
    name: "Coupon",
    action: "add",
  },
  {
    link: "/admin/CouponVocher/AddCoupon",
    key: "create-coupons",
    Element: <AddCoupon />,
    name: "Coupon",
    action: "add",
  },

  // ** Other Billboard Routes
  {
    link: "/admin/billboardscreens/:billboard_id",
    Element: <BillboardItem />,
    name: "Billboard",
  },
  {
    link: "/admin/addBurnerAd",
    key: "create-delete-burner-ad",
    Element: <AddBurnerAd />,
  },
  {
    link: "/admin/addbillboard",
    key: "add-billboard",
    Element: <AddBillBoard />,
    name: "billboard",
    action: "Add",
  },

  {
    link: "/admin/selectTimeslot",
    Element: <SelectTimeSlot />,
    name: "Billboard",
  },
  {
    link: "/admin/modifybooking",
    Element: <MBookingModify />,
    name: "Billboard",
  },

  // ** Other Order Routes
  {
    link: "/admin/order/:order_id",
    key: "view-order-detail",
    Element: <OrderDetails />,
    name: "Order",
  },

  {
    link: "/admin/userprofile/:id/:value",
    Element: <ManageUserProfile />,
    name: "User",
    key: "view-user-profile",
  },

  // ** Other User Routes

  //-----------------------SubAdmin
  {
    link: "/admin/addsubadmin/:value",
    Element: <AddSubAdmin />,
    name: "User",
    key: "add-new-user-subadmin",
  },

  //-----------------------Clusterhead
  {
    link: "/admin/addclusterhead/:value",
    Element: <AddClusterhead />,
    key: "add-new-user-clusterhead",
    name: "User",
  },

  //-----------------------Agency
  {
    link: "/admin/AdAgency/:value",
    key: "add-new-user-agency",
    Element: <AdAgency />,
    name: "User",
  },

  //-----------------------BusinessOwner

  {
    name: "User",
    link: "/admin/addbusinessOwner/:value",
    key: "add-new-user-businessowner",
    Element: <BuisnesssOwner />,
  },

  //-----------------------individual

  // ** Other Campaign Routes

  {
    link: "/admin/campaignmgt/:campaign_id",
    Element: <Camp_Details />,
    name: "Campaign",
    key: "view-campaign-detail",
  },
];

export const role2Routes = [
  {
    invisible: false,
    link: "/admin/otherOrder",
    key: "view-billboard-order",
    Element: <OrderTab />,
    icon: (
      <img src={otherOrderIcon} alt="Other Order" style={{ width: "20px" }} />
    ),
    name: "Other Orders",
  },
  {
    invisible: false,
    link: "/admin/otherCampaign",
    key: "view-billboard-campaign",
    Element: <Campaign_Tab />,
    icon: (
      <img
        src={otherCampaignIcon}
        alt="Other Campaign"
        style={{ width: "20px" }}
      />
    ),
    name: "Others Campaign",
  },
];
export const roleZeroRoutes = [
  // ** Other Gallery Routes
  {
    invisible: false,
    name: "Gallery",
    link: "/admin/gallery",
    Element: <GalleryIndex />,
    icon: <CollectionsOutlinedIcon />,
  },
  // ** Other trash Routes
  {
    invisible: false,
    name: "Trash",
    link: "/admin/trash&restore",
    Element: <Trashindex />,
    icon: <RestoreFromTrashIcon />,
  },
];

export default routes;
