import {
  Grid,
  Typography,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import noDataFound from "../../../../../assets/Img/no_data.gif";
import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import axiosInstance from "../../../../../api/axiosInstance";
import moment from "moment";
const Item = styled(Paper)(({ theme }) => ({}));
const ViewTab = (props) => {
  const [allFilterSlot, setAllFilterSlot] = useState([]);
  const [allFilterDate, setAllFilterDate] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [imagefile, setImageFile] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [dates, setDates] = useState([]);
  const [multiStartHour, setmultiStartHour] = useState([]);
  const billboardId = props?.data?.billboardId[0]?._id
    ? props?.data?.billboardId[0]?._id
    : props?.data?.billboardsData[0]?.billboardId;
  console.log("propes", billboardId);

  const getViewImages = async () => {
    try {
      let res = await axiosInstance.get(
        `/api/order/getBillViewsImages?date=${moment(
          props?.data?.startscheduleDate
        )?.format("YYYY-MM-DD")}&startHour=${selectedSlot?.time}&orderId=${
          props?.data?._id
        }&billboardId=${billboardId}`
      );

      console.log("response from setgetAdvertiseViseData =>", res);
      if (res.status == 200 || res.status == 201) {
        //  setAcceptedOrderAnalyticsData(res?.data.data);

        setImageFile(res?.data?.data);
      }
    } catch (err) {
      console.log("error from from setgetAdvertiseViseData => ", err);
    }
  };

  useEffect(() => {
    if (selectedSlot) {
      getViewImages();
      getRangebetweenDates(
        props?.data?.startscheduleDate,
        props?.data?.endscheduleDate
      );
    }
  }, [props, selectedSlot]);

  function getRangebetweenDates(startDate, endDate, type = "days") {
    let fromDate = moment(startDate);
    let toDate = moment(endDate);
    let diff = toDate.diff(fromDate, type);
    let range = [];
    for (let i = 0; i <= diff; i++) {
      range.push(moment(startDate).add(i, type).format("YYYY-MM-DD"));
    }
    setDates(range);
  }
  function tConvert(time) {
    const times = [...new Set(time)];
    let newTimes = [];
    times.map((data) => {
      let obj = {};
      if (data < 4) {
        if (data === 0) obj["actualTime"] = "12am - 1am";
        else obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 3 && data < 8) {
        obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 7 && data < 12) {
        if (data === 11) obj["actualTime"] = "11am - 12pm";
        else obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 11 && data < 16) {
        if (data === 12) obj["actualTime"] = `12pm - ${data - 12 + 1}pm`;
        else obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      } else if (data > 15 && data < 20) {
        obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      } else if (data > 19) {
        if (data === 23) obj["actualTime"] = `11pm - ${data - 12 + 1}am`;
        else obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      }
      obj["time"] = data;
      newTimes.push(obj);
    });
    setAllFilterSlot(newTimes);
    setSelectedSlot(newTimes[0]);
  }
  // console.log("selected >......",selectedSlot.time)
  useEffect(() => {
    // console.log(
    //   "tConvert(props?.data?.startHour)",
    //   );
    tConvert(multiStartHour);
    // setTimeSlot(tConvert(props?.data?.startHour));
  }, [multiStartHour]);

  useEffect(() => {
    console.log("props anaalityics", props?.data);
    let tempStart = [];
    if (props?.data?.multiTimes) {
      tempStart = props?.data?.multiTimes.map((item) => item?.startHour);
    }
    setmultiStartHour(tempStart);
  }, [props]);

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <Grid container className="border_primary">
          <Grid
            container
            sx={{
              borderBottom: "1px solid #ddd",
              padding: "10px 20px",
              justifyContent: "space-between",
            }}
          >
            <Typography className="blackcolor0000_500 fs20px">
              Showing : {imagefile?.length > 0 ? imagefile?.length : 0} Images
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
              <InputLabel id="demo-select-small"> Select Hour Slot </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={selectedSlot}
                label="       Select Hour Slot"
                onChange={(e) => setSelectedSlot(e.target.value)}
              >
                {allFilterSlot?.map((item, i) => (
                  <MenuItem value={item} key={i}>
                    {item?.actualTime}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container sx={{ padding: "35px 20px" }}>
            <Grid container spacing={2} className="paper_reset">
              {imagefile && imagefile?.length > 0 ? (
                <>
                  {imagefile.map((a, i) => (
                    <Grid item xs={12} sm={6} md={3} key={i}>
                      <Grid
                        container
                        sx={{
                          border: "1px solid #ddd",
                          borderRadius: "8px",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      >
                        <img
                          src={a}
                          alt="images"
                          loading="lazy"
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",

                            borderRadius: "8px",
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </>
              ) : (
                // : loadingFiles ? (
                // <>
                //   {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((a, b) => (
                //     <Grid item xs={12} sm={6} md={3} key={b}>
                //       <Grid
                //         container
                //         sx={{ border: "1px solid #ddd", borderRadius: "8px" }}
                //       >
                //         <Skeleton
                //           variant="rectangular"
                //           style={{
                //             width: "100%",
                //             height: "200px",
                //             borderRadius: "8px",
                //           }}
                //         />
                //       </Grid>
                //     </Grid>
                //   ))}
                // </>

                // )

                <Grid container justifyContent="center" alignItems="center">
                  <img
                    src={noDataFound}
                    className="noBillboardFoundGif_width"
                    alt="no image found"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ViewTab;
