import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  MenuItem,
  Input,
  InputLabel,
  Select,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TimeSlotDialog from "./TimeSloteEditDialog ";
import BillboardPostDetails from "./BillboardPostDetails";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "#fffff" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#dddddd",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function MaxWidthDialog(props) {
  const {
    states: {
      name,
      setName,
      about,
      setAbout,
      date,
      setDate,
      repeatsec,
      setRepeatSec,
      timeSlot,
      setTimeSlot,
      editduration,
      setEditDuration,
    },
    billboardId,
    billBoardData,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [editName2, setEditName2] = useState(name);
  const [editAbout2, setEditAbout2] = useState(about);
  const [editDate2, setEditDate2] = useState(date);
  const [editDuration2, setEditDuration2] = useState(editduration);
  const [editRepeatSec, setEditRepeatSec] = useState(repeatsec);
  const [editTimeSlot2, setEditTimeSlot2] = useState(timeSlot);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (
      editName2.trim().length === 0 ||
      editAbout2.trim().length === 0 ||
      editDate2.trim().length === 0 ||
      String(editRepeatSec).trim().length === 0 ||
      editTimeSlot2 === null
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [editName2, editAbout2, editDate2, editRepeatSec, editTimeSlot2]);

  const handleClose = () => {
    setOpen(false);
    setEditName2(name);
    setEditAbout2(about);
    setEditDuration2(editDuration2);
    setEditTimeSlot2(timeSlot);
    setEditRepeatSec(repeatsec);
    setEditDate2(date);
  };

  const saveAndHandleClode = () => {
    setOpen(false);
    setName(editName2);
    setAbout(editAbout2);
    setEditDuration(editDuration2);
    setDate(editDate2);
    setRepeatSec(editRepeatSec);
    setTimeSlot(editTimeSlot2);
  };

  //========================= states =========================>
  // const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const handleData = (data, datatype) => {
    setEditDate2(moment(data).format("YYYY-MM-DD"));
  };
  let minsarr = [];
  for (let i = 1; i <= 120; i++) {
    minsarr.push(i);
  }

  useEffect(() => {
    if (editDate2 !== date) {
      setEditRepeatSec("");
      setEditTimeSlot2(null);
    }
  }, [editDate2]);

  useEffect(() => {
    if (editRepeatSec !== repeatsec) {
      setEditTimeSlot2(null);
    }
  }, [editRepeatSec]);

  useEffect(() => {
    if (editDuration2 !== editduration) {
      setEditRepeatSec("");
      setEditTimeSlot2(null);
    }
  }, [editDuration2]);

  return (
    <React.Fragment>
      <div style={{ borderBottom: "1px solid #505050" }}>
        <button className="edit-btn" onClick={handleClickOpen}>
          Edit
        </button>
      </div>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Grid item sx={{ marginBottom: "30px" }}>
            <Typography
              className="greycolortypo fs16px"
              sx={{ marginBottom: "4px" }}
            >
              Starting from
            </Typography>
            <Typography className="blackcolortypo fs18px ">
              <span className="fs18px"> &#8377; </span>
              {billBoardData.basePrice}/Second
            </Typography>
            <Grid item className="mt10px">
              <Typography className="greycolor505050400"> Name</Typography>
              <Input
                className="inputstyle"
                value={editName2}
                onChange={(e) => setEditName2(e.target.value)}
                disableUnderline
              />
            </Grid>
            <Grid item className="mt10px">
              <Typography className="greycolor505050400"> About</Typography>
              <Input
                className="inputstyle"
                value={editAbout2}
                onChange={(e) => setEditAbout2(e.target.value)}
                disableUnderline
              />
            </Grid>

            <Grid item className="mt15px">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputFormat="dd/MM/yyyy"
                  value={editDate2}
                  onChange={(e) => {
                    handleData(e, "date");
                  }}
                  maxDate={new Date().setDate(new Date().getDate() + 60)}
                  renderInput={(params) => (
                    <TextField {...params} className="width100 " />
                  )}
                  disablePast={true}
                />
              </LocalizationProvider>
            </Grid>

            <Grid
              container
              item
              alignItems="center"
              className="mt20px"
              gap="10px"
            >
              <FormControl className="textfield-outline " fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select duration
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={editDuration2}
                  label="Select duration"
                  onChange={(e) => setEditDuration2(e.target.value)}
                >
                  <MenuItem value={30}>30 second</MenuItem>
                  {/* <MenuItem value={60}>60 second</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              container
              className="mt20px"
              gap="8px"
              style={{
                borderRadius: "6px",
              }}
            >
              <FormControl className="textfield-outline " fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Repetition
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Repetition"
                  value={editRepeatSec}
                >
                  {minsarr && minsarr.length > 0 ? (
                    minsarr.map((x, i) => (
                      <MenuItem
                        key={i}
                        value={x}
                        onClick={() => setEditRepeatSec(x)}
                      >
                        {x} Times
                      </MenuItem>
                    ))
                  ) : (
                    <h2>No Data</h2>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item className="mt20px">
              <TimeSlotDialog
                selectedTimeEdit={timeSlot}
                billboardId={billboardId}
                date={editDate2}
                duration={editDuration2}
                editRepeatSec={editRepeatSec}
                editTimeSlot2={editTimeSlot2}
                setEditTimeSlot2={setEditTimeSlot2}
              />
            </Grid>
          </Grid>
          <DialogActions item xs={12} className="mt10px">
            <Button
              className={
                disabled ? "disabledbtn width100" : "navbuttonstyle-2  width100"
              }
              onClick={saveAndHandleClode}
              disabled={disabled}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
