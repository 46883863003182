import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
const MarginAndCutDialogueTwo = ({
  update2,
  updateHandler2,
  setPostMyAdPer2,
  setClusterHeadPer2,
  setBusinessOwner2,
  sum2,
}) => {
  const [open, setOpen] = React.useState(false);
  console.log(sum2, "123");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const checkValueTrue = () => {
    updateHandler2(true);
    if (update2 && sum2 === 100) {
      updateHandler2(false);
    }
  };

  const checkValueFalse = () => {
    // if(sum!==100){

    // }
    setPostMyAdPer2(28);
    setClusterHeadPer2(2);
    setBusinessOwner2(70);
    updateHandler2(false);
  };

  return (
    <div>
      <Button
        onClick={() => {
          handleClickOpen();
        }}
        className="btn_secondary"
        style={{ marginTop: "15px" }}
      >
        {update2 ? "Save Changes" : "Update"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography className="greycolor505050500">
              {" "}
              {update2
                ? "  Are you sure you want save the changes"
                : "  Are you sure you want to update the margins"}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="  diallog-no-btn"
            onClick={() => {
              checkValueTrue();
              handleClose();
            }}
          >
            Yes
          </Button>
          <Button
            className="  diallog-no-btn"
            onClick={() => {
              checkValueFalse();
              handleClose();
            }}
            autoFocus
          >
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MarginAndCutDialogueTwo;
