import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Tabs,
  Tab,
  Grid,
  Stack,
  Tooltip,
  useTheme,
  Pagination,
  Typography,
  useMediaQuery,
  DialogContent,
  FormControl,
  TextField,
  MenuItem,
} from "@mui/material";

import PropTypes from "prop-types";

//core component that
import AllDevices from "./DeiveTableTab/AllDeviceTab";
import AddDevceDialog from "./AddDeviceDialog/AddDeviceDialog";
import axios from "../../../api/axiosInstance";
import usePaginatedValue from "../../../utils/usePaginatedValue";
import permissionUtils from "../../../component/permissionUtils";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";
import axiosInstance from "../../../api/axiosInstance";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Testing() {
  const navigate = useNavigate();
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();
  // custum Hook
  const { paginatedPage, paginateTabValue, getPaginatedValueHandler } =
    usePaginatedValue(navigate);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [value, setValue] = React.useState(
    paginateTabValue ? paginateTabValue : 0
  );
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  // States
  const [devices, setDevices] = React.useState(null);
  const [query, setQuery] = React.useState("");
  // pagination
  const [totalPage, setTotalPage] = React.useState(null);
  const [district, SetDistrict] = useState("All");
  const [currentPage, setCurrentPage] = React.useState(
    paginatedPage ? paginatedPage : 1
  );
  const [deviceslength, setDevicesLength] = React.useState("");
  const [seletDistrict, setseletDistrict] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [firstTimeSearch, setFirstTimeSearch] = useState(true);
  const [sortByName, setSortByName] = useState(null);
  const [sortByAscDsc, setSortByAscDsc] = useState(null);
  const [testing, setTesting] = useState(false);
  const [valueTest, setValueTest] = React.useState(0);
  const [testinData, setTestingData] = useState(null);
  const [isTesting, setIsTesting] = useState(false);
  const [sortByKey, setsortByKey] = useState("createdAt ");

  // helper function
  const handleChange = (event, newValue) => {
    getPaginatedValueHandler("", 1, newValue);
    setDevices([]);
    setSearchText("");
    setCurrentPage(1);
    if (newValue === 0) {
      setQuery("All");
    }
    if (newValue === 1) {
      setQuery("Active");
    }
    if (newValue === 2) {
      setQuery("InActive");
    }
    setValue(newValue);
  };
  const apiHandler = (sortBy, sortType) => {
    setSortByName(sortBy);
    setSortByAscDsc(sortType);
    setLoading(true);
  };
  // paginations
  const handleChangeByPage = (event, page) => {
    getPaginatedValueHandler("", page, value);
    setCurrentPage(page);
  };
  // ======================== All devcie API ====================== //

  const getAllDevice = async (
    value,
    searchValue,
    sortBy,
    sortType,
    newValue
  ) => {
    setDevices([]);
    apiHandler(sortBy, sortType);
    try {
      let res;

      if (query === "Active") {
        res = await axios.get(
          `/api/device/getdevice?status=${newValue ? newValue : query}&page=${
            value || currentPage
          }&limit=10&keyword=${searchValue ? searchValue : null}&sortBy=${
            sortBy ? sortBy : "_id"
          }&sortType=${
            sortType ? sortType : -1
          }&isDelete=false&testing=${testing}&district=${district}`
        );
      } else {
        res = await axios.get(
          `/api/device/getdevice?status=${newValue ? newValue : query}&page=${
            value || currentPage
          }&limit=10&keyword=${searchValue ? searchValue : null}&sortBy=${
            sortBy ? sortBy : "_id"
          }&sortType=${sortType ? sortType : -1}&isDelete=false`
        );
      }
      setLoading(false);
      setDevices(res.data.msg);
      setTotalPage(Math.ceil(res?.data?.lengthData / 10));
      setDevicesLength(Math.ceil(res?.data?.lengthData));
    } catch (error) {
      setLoading(false);
      console.log("error from GET ALL devices ", error.message);
    }
  };

  useEffect(() => {
    if (query) {
      getAllDevice(currentPage, searchText, sortByName, sortByAscDsc, query);
    }
  }, [currentPage, query, valueTest, district]);
  useEffect(() => {
    if (firstTimeSearch) {
      setFirstTimeSearch(false);
      return;
    }
    const searchApiHandler = setTimeout(() => {
      getAllDevice(currentPage, searchText);
    }, 200);
    return () => clearTimeout(searchApiHandler);
  }, [searchText]);
  useEffect(() => {
    if (paginateTabValue === undefined) {
      setQuery("All");
      setValue(0);
      return;
    }
    if (paginateTabValue === 0) {
      setQuery("All");
    }
    if (paginateTabValue === 1) {
      setQuery("Active");
    }
    if (paginateTabValue === 2) {
      setQuery("InActive");
    }
    setValue(paginateTabValue);
  }, [paginateTabValue]);
  useEffect(() => {
    if (paginatedPage < 1) {
      setCurrentPage(1);
      return;
    }
    setCurrentPage(paginatedPage ? paginatedPage : 1);
  }, [paginatedPage]);

  const breadcrumbs = [{ label: "Dashboard", link: "/" }, { label: "Devices" }];

  // added by rohit
  // let userRole = JSON.parse(localStorage.getItem("userData")).user.role;
  let Role = JSON.parse(localStorage.getItem("userData")).user.userRole;

  const handleChangeTest = (event, newValueTest) => {
    setDevices([]);
    setSearchText("");
    setCurrentPage(1);
    if (newValueTest === 0) {
      setQuery("Active");
      setTesting(false);
    }
    if (newValueTest === 1) {
      setQuery("Active");
      setTesting(true);
    }

    setValueTest(newValueTest);
  };
  //////////// District get api ////////////////

  const getTestingStatus = async () => {
    try {
      const res = await axiosInstance.get(`api/billboard/getTestingStatus`);
      if (res.status == 200) {
        setTestingData(res?.data?.data?.[0]);
        setIsTesting(res?.data?.data?.[0]?.isEnable);
      } else {
      }
      console.log(res, "check testing");
    } catch (err) {}
  };
  const changeTestingStatus = async (e) => {
    let body = {
      testingBillId: testinData?._id,
      status: e.target.checked,
    };
    try {
      const res = await axiosInstance.post(
        `/api/billboard/changeTestBillStatus`,
        body
      );
      if (res.status == 200) {
        getTestingStatus();
      } else {
      }
    } catch (err) {}
  };

  const getAllCities = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getAllDistrictOfBillboard?isTesting=${testinData}`
      );

      if (res.status == 200 || res.statusText == "OK") {
        setseletDistrict(res?.data?.data);
      } else {
        console.log("error in getAllBillboards", res);
      }
    } catch (err) {
      console.log("error in getAllBillboards", err);
    }
  };

  useEffect(() => {
    getTestingStatus();
    getAllCities();
  }, []);

  return (
    <>
      <div className="width-90LR ">
        <div className="flex-class">
          <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
        </div>
        <Grid container>
          <Grid
            container
            className="device-container mt20px"
            sx={{
              width: "100%",
              boxShadow: " 0px 1px 10px 0px #0000001A ",
            }}
          >
            <Grid
              item
              style={{
                width: "100%",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                className="TabStyleAddDevice"
                centered={sm ? true : false}
                variant={sm ? false : "scrollable"}
                scrollButtons
                aria-label="scrollable force tabs example"
              >
                <Tab label="All Devices" className="TabChangesDevice" />
                <Tooltip
                  title="These devices are assigned to a billboard"
                  className="tooltipheight"
                >
                  <Tab label="Assigned " className="TabChangesDevice" />
                </Tooltip>{" "}
                <Tooltip
                  title="These devices are not assigned to a billboard"
                  className="tooltipheight"
                >
                  <Tab label="Not Assigned" className="TabChangesDevice" />
                </Tooltip>{" "}
              </Tabs>
            </Grid>
          </Grid>

          {query === "Active" && Role === 0 ? (
            <Grid item className="width100">
              <Tabs
                value={valueTest}
                onChange={handleChangeTest}
                centered
                className="TabStyleAddDevice"
              >
                <Tab
                  label="Live"
                  sx={{
                    borderRadius: "50px 0px 50px 0px",
                    marginBottom: "20px",
                  }}
                  className="TabChangesDeviceLive"
                />
                <Tab
                  label="Testing"
                  sx={{
                    borderRadius: "50px 0px 50px 0px",
                    marginBottom: "20px",
                  }}
                  className="TabChangesDeviceLive"
                />
              </Tabs>
            </Grid>
          ) : (
            ""
          )}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: "16px" }}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              rowGap="10px"
            >
              <Typography className="greycolor505050500">
                Showing {devices?.length} out of {deviceslength} Devices
              </Typography>
              <Grid item>
                <Grid container alignItems="center" gap="20px">
                  <Grid item>
                    {/* <div style={{ width: "90%" }}> */}{" "}
                    <input
                      class="input-elevated"
                      type="text"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        // setCurrentPage(1);
                      }}
                    />
                  </Grid>
                  {query === "Active" && (
                    <FormControl className="billboard_price_filter-district">
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle"
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={district}
                        inputProps={{ "aria-label": "Without label" }}
                        defaultValue={0}
                        onChange={(e) => SetDistrict(e.target.value)}
                      >
                        <MenuItem
                          value={"All"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="greycolor505050500 fs14px ">
                            All District
                          </Typography>
                        </MenuItem>
                        {seletDistrict?.map((res, index) => (
                          <MenuItem
                            value={res}
                            key={index}
                            className="blackcolor484848 fs13px"
                          >
                            <Typography className="greycolor505050500 fs14px ">
                              {res}
                            </Typography>
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  )}
                  {PermissionCheck("add-device") && (
                    <AddDevceDialog
                      getAllDevice={getAllDevice}
                      currentPage={currentPage}
                      setSearchText={setSearchText}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DialogContent style={{ padding: "0px" }}>
            <TabPanel value={value} index={0} style={{ padding: "0px" }}>
              <AllDevices
                deviceslength={deviceslength}
                devices={devices}
                getAllDevice={getAllDevice}
                loading={loading}
                currentPage={currentPage}
                searchText={searchText}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AllDevices
                deviceslength={deviceslength}
                devices={devices}
                getAllDevice={getAllDevice}
                loading={loading}
                currentPage={currentPage}
                searchText={searchText}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AllDevices
                deviceslength={deviceslength}
                devices={devices}
                getAllDevice={getAllDevice}
                loading={loading}
                currentPage={currentPage}
                searchText={searchText}
              />
            </TabPanel>
          </DialogContent>
        </Grid>
      </div>
      {devices?.length > 0 && (
        <Grid
          justifyContent="center"
          alignItems="center"
          sx={{ display: "flex", marginTop: "20px", marginBottom: "20px" }}
        >
          <Stack spacing={2}>
            <Pagination
              page={currentPage}
              count={totalPage}
              onChange={handleChangeByPage}
              size="large"
            />
          </Stack>
        </Grid>
      )}
    </>
  );
}
