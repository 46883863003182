import React, { useState, useEffect } from "react";
import { Typography, Grid, Input, Stack, Button, Avatar } from "@mui/material";
import { MdVerifiedUser } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import axiosInstance from "../../../../../../api/axiosInstance";
import { FETCH_URL } from "../../../../../../fetchIp";
import permissionUtils from "../../../../../../component/permissionUtils";
import usetGetPincodeData from "../../../../../../utils/useGetPincodeData";
export default function Testing({ userID }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { pincode, city, state, country, checkForPinCode, setPincode } =
    usetGetPincodeData();
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const [file, setFile] = useState("");
  function handleImg(event) {
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  }
  const [img, setImage] = useState();
  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }
  function ChangeInputCancel(e) {
    setInputState(true);
  }
  // states
  const [profile, setProfile] = React.useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [adharCard, setAdharCard] = useState("");
  const [email, setEmail] = useState("");
  const userR = profile?.userRole;

  useEffect(() => {
    if (profile) {
      setFirstName(profile.firstName ?? "");
      setLastName(profile.lastName ?? "");
      setPhoneNumber(profile.mobileNumber ?? "");
      setAddress(profile.address ?? "");
      setPincode(profile.pincode ?? "");
      setAdharCard(profile.AdharCard ?? "");
      setEmail(profile?.email ?? "");
    }
  }, [profile]);
  const [inputState, setInputState] = useState(true);

  function ChangeInputState() {
    setInputState(false);
  }
  // GetUser api
  console.log("profile", profile);

  const getProfile = async () => {
    try {
      let profile = await axiosInstance.get(
        `/api/user/getUserDetail/${userID}`
      );
      console.log("Profile ==> ", profile.data.msg);
      setProfile(profile.data.msg);
    } catch (error) {
      console.log("USER PROFILE DATA ==>", error);
    }
  };
  // ProfileEdit

  const ProfileEdit = async () => {
    let num = "";
    if (checkForPinCode) {
      return;
    }
    if (phoneNumber) {
      if (String(phoneNumber)[0] !== "9" || String(phoneNumber)[1] !== "1") {
        console.log(String(phoneNumber)[0], "phoneNumber");
        num = `91${phoneNumber}`;
      }
    }
    try {
      let token = JSON.parse(localStorage.getItem("userData")).token;
      const response = await fetch(`${FETCH_URL}/api/user/profileupdate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: userID,
          userRole: userR,
          firstName: firstName,
          lastName: lastName,
          mobileNumber: num ? num : +phoneNumber,
          address: address,
          pincode: pincode,
          adharCard: adharCard,
          email: email,
          state: state,
          country: country,
        }),
      });
      let res = await response.json();
      if (response.ok) {
        // setSnackOpen(true);
        // setSnackMsg("Edit Successfull");
        setInputState(true);
        getProfile(userID);
        console.log("Edit Profile   response  ===>", res.msg);
      } else {
        // setSnackerropen(true);
        // setSnackErrMsg(res.err);
        console.log("Error From Server ==> ", res.msg);
      }
    } catch (error) {
      console.log("Error during   Edit Profile data find  => ", error.message);
    }
  };

  React.useEffect(() => {
    getProfile();
  }, [userID]);
  const [numLength, setNumlength] = useState(10);
  useEffect(() => {
    if (String(phoneNumber)[0] == "9" && String(phoneNumber)[1] == "1") {
      setNumlength(12);
    } else {
      setNumlength(10);
    }
  }, [phoneNumber]);

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(ProfileEdit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography className="  blackcolortypo500 fs20px   ">
                Profile
              </Typography>
              <Grid
                container
                className="grey-border mt15px"
                sx={{ padding: "15px" }}
              >
                <Typography className="greycolor505050500 fs16px  ">
                  User Type *
                </Typography>
                <Input
                  className="inputstyle"
                  disableUnderline
                  value={profile?.role}
                  disabled
                />
                <Typography className="greycolor505050500 fs16px mt10px">
                  First Name
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={firstName}
                  disabled={inputState}
                  {...register("First-Name-ErrorInput", {
                    required: "First Name is required.",
                    value: { firstName },
                    onChange: (e) => {
                      setFirstName(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="First-Name-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Last Name
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={lastName}
                  disabled={inputState}
                  {...register("Last-Name-ErrorInput", {
                    required: "Last Name is required.",
                    value: { lastName },
                    onChange: (e) => {
                      setLastName(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="Last-Name-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Mobile Number
                </Typography>
                <div className="width100 relative">
                  {" "}
                  <input
                    className="inputstyle-custom"
                    type="number"
                    disableUnderline
                    value={phoneNumber}
                    disabled
                    {...register("PhoneNo-ErrorInput", {
                      required: "Mobile Number is required.",
                      minLength: numLength,
                      maxLength: numLength,
                      value: { phoneNumber },
                      onChange: (e) => {
                        setPhoneNumber(e.target.value);
                      },
                    })}
                  />
                  <div className="verify_text_absolute">
                    {profile?.mobileStatus ? (
                      <Typography
                        variant="h6"
                        className="purplecolortypo-400  fs14px"
                      >
                        Verified
                      </Typography>
                    ) : (
                      <Typography
                        variant="h6"
                        className="redcolortypo_400 fs14px"
                      >
                        Not Verified
                      </Typography>
                    )}
                  </div>
                </div>

                <ErrorMessage
                  errors={errors}
                  name="PhoneNo-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Email
                </Typography>
                <div className="relative width100">
                  {" "}
                  <input
                    type="text"
                    className="inputstyle-custom"
                    disableUnderline
                    disabled
                    value={email}
                    {...register("Email-ErrorInput", {
                      required: "Email  is required.",
                      // pattern: /^\S+@\S+$/i,
                      value: { email },
                      // onChange: (e) => {
                      //   console.log("Check Email", email);
                      //   setEmail(e.target.value);
                      // },
                    })}
                  />
                  <div className="verify_text_absolute">
                    {profile?.emailstatus ? (
                      <Typography
                        variant="h6"
                        className="purplecolortypo-400  fs14px"
                      >
                        Verified
                      </Typography>
                    ) : (
                      <Typography
                        variant="h6"
                        className="redcolortypo_400 fs14px"
                      >
                        Not Verified
                      </Typography>
                    )}
                  </div>
                </div>

                <ErrorMessage
                  errors={errors}
                  name="Email-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Full Address
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={address}
                  disabled={inputState}
                  {...register("address-ErrorInput", {
                    required: "Address is required.",
                    value: { lastName },
                    onChange: (e) => {
                      setAddress(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="address-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  State
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={state}
                  disabled
                  {...register("State-ErrorInput", {
                    required: "State is required.",
                    value: { state },
                    // onChange: (e) => {
                    //   setLandmark(e.target.value);
                    // },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="State-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />

                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Country
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={country}
                  disabled
                  {...register("Country-ErrorInput", {
                    required: "Country is required.",
                    value: { country },
                    // onChange: (e) => {
                    //   setCountry(e.target.value);
                    // },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="Country-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                <Typography className="greycolor505050500 fs16px mt10px width100">
                  Pincode
                </Typography>
                <input
                  className="inputstyle-custom"
                  disableUnderline
                  value={pincode}
                  maxLength={6}
                  disabled={inputState}
                  {...register("Pincode-ErrorInput", {
                    required: "Pincode is required.",
                    value: { pincode },
                    onChange: (e) => {
                      setPincode(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="Pincode-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="blackcolortypo500 fs20px   ">
                Profile picture
              </Typography>
              <Grid
                container
                className="grey-border mt15px"
                sx={{ padding: "15px" }}
              >
                <Grid container>
                  <div className="profile-img">
                    <Stack alignItems="center" direction="row">
                      <Avatar className="avatar-bg-color avatar_size">
                        <Typography className="fs50px whitecolortypo">
                          {profile?.firstName?.split(" ")[0][0]}
                          <span className="ml5px ">
                            {profile?.lastName?.split(" ")[0][0]}
                          </span>
                        </Typography>
                      </Avatar>
                    </Stack>
                  </div>
                </Grid>
                <Typography
                  align="center"
                  className="width100 mt10px  greycolor505050500 fs18px"
                >
                  {profile?.mobileStatus && profile?.emailstatus ? (
                    <span>
                      <MdVerifiedUser className="iconcolor" /> Verified
                    </span>
                  ) : (
                    <span>
                      <MdCancel className="danger_color" /> Not Verified
                    </span>
                  )}
                </Typography>
              </Grid>
              {/* <Typography className="blackcolortypo500 fs20px  mt15px ">
                eKYC
              </Typography>
              <Grid
                container
                className="grey-border mt15px"
                sx={{ padding: "15px" }}
              >
                <Typography className="greycolor505050500 fs16px  ">
                  Adhar Card Number*
                </Typography>
                <Input
                  className="inputstyle "
                  disableUnderline
                  value={adharCard}
                  disabled={inputState}
                  onChange={(e) => setAdharCard(e.target.value)}
                />
              </Grid> */}
              {PermissionCheck("edit-user-profile") && (
                <Grid
                  container
                  style={{ marginTop: "24px" }}
                  justifyContent="flex-end"
                  gap="20px"
                >
                  {" "}
                  {inputState ? (
                    <>
                      <Button
                        className=" purplebtn"
                        onClick={() => ChangeInputState()}
                      >
                        Edit Profile
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="cancelbutton"
                        onClick={() => ChangeInputCancel()}
                      >
                        Cancel
                      </Button>
                      <Button className=" purplebtn" type="submit">
                        Save Changes
                      </Button>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          {/* <DialogActions className="mb20px">
            {inputState ? (
              <>
                <Button
                  className=" purplebtn"
                  onClick={() => ChangeInputState()}
                >
                  Edit Profile
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="cancelbutton"
                  onClick={() => ChangeInputCancel()}
                >
                  Cancel
                </Button>
                <Button className=" purplebtn" type="submit">
                  Save Changes
                </Button>
              </>
            )}
          </DialogActions>{" "} */}
        </form>
      </div>
    </>
  );
}
