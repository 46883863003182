import * as React from "react";
import { Grid, Typography, Button } from "@mui/material";
import axiosInstance from "../../../../api/axiosInstance";
import notification from "../../../../assets/Img/notification.gif";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteDilogue from "./dialogConfirm";
export default function AlignItemsList() {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = React.useState(
    Array.from({ length: 10 })
  );
  function formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " , " +
      strTime
    );
  }
  const [lengthForScroll, setLengthForScroll] = React.useState([]);
  const [readMsg, setReadMsg] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = useState(null);
  const [loading, setLoading] = React.useState(true);
  const getReadMsg = async () => {
    let userId = JSON.parse(localStorage.getItem("userData")).user._id;
    console.log(userId, "userIduserId");
    setLoading(true);
    try {
      let resp = await axiosInstance.get(
        `/api/notification/getallnotification?userId=${userId}&status=Read&page=${
          page ? page : 1
        }`
      );
      console.log("response from getting Read noti =>", resp);
      if (resp) {
        setLoading(false);

        setLengthForScroll(resp?.data?.msg);
        setTotalLength(resp?.data?.length);
        if (page == 1) {
          setReadMsg(resp?.data?.msg);
        } else {
          setReadMsg((pre) => [...pre, ...resp?.data?.msg]);
        }
      }
    } catch (err) {
      setLoading(false);
      console.log("error from getting Read noti => ", err);
    }
  };
  // infine scroll
  const handleisFetching = () => {
    if (lengthForScroll?.length == 0) {
      return;
    }
    setTimeout(() => {
      setIsFetching(isFetching.concat(Array.from({ length: 12 })));
      setPage((prev) => prev + 1);
    }, 1000);
  };

  // infine scroll
  React.useEffect(() => {
    getReadMsg();
  }, [page]);
  const changeReadStatus = async (a) => {
    if (a?.messageType === "Burner Ad") {
      navigate(`/admin/billboardscreens/${a?.detailPage}`);
    } else {
      if (a?.messageType === "Withdraw") {
        navigate(`/admin/finance?tab=4`);
      } else {
        navigate(`/admin/order/${a?.detailPage}`, {
          state: { a },
        });
      }
    }
  };
  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        sx={{ marginBottom: "15px" }}
      >
        <Typography className="blackcolor484848 fs24px">
          Showing {readMsg ? totalLength : 0} read notification
        </Typography>
        {readMsg?.length > 0 && (
          <DeleteDilogue name={"read"} getReadMsg={getReadMsg} />
        )}
      </Grid>
      <div
        id="scrollableDiv"
        style={{
          overflowY: "scroll",
          marginBottom: "50px",
          height: "90vh",
        }}
      >
        <InfiniteScroll
          dataLength={isFetching.length}
          next={handleisFetching}
          hasMore={lengthForScroll?.length == 0 ? false : true}
          scrollableTarget="scrollableDiv"
          className="width100"
          loader={Array(10)
            ?.fill(1)
            ?.map((a, i) => (
              <Grid
                container
                item
                sx={{ padding: "10px 0", borderBottom: "1px solid #ddd" }}
              >
                <Grid item sx={{ width: "100px", height: "100%" }}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%" }}
                  >
                    {" "}
                    <Skeleton
                      variant="circular"
                      sx={{ width: "60px", height: "60px" }}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container gap="5px" direction="column">
                    <Grid item>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "100px" }}
                      />
                    </Grid>
                    <Grid item>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "200px" }}
                      />
                    </Grid>

                    <Grid item>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "180px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          // endMessage={<h1>No Notification Ahead</h1>}
        >
          {" "}
          <Grid container className="box_shadow_border_radius">
            {readMsg && readMsg?.length > 0 ? (
              readMsg?.map((mData, i) => (
                <Grid
                  container
                  item
                  key={i}
                  className="cursor_pointer"
                  onClick={() => changeReadStatus(mData)}
                  sx={{ padding: "10px 0", borderBottom: "1px solid #ddd" }}
                >
                  <Grid item sx={{ width: "100px", height: "100%" }}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: "100%" }}
                    >
                      {" "}
                      <img
                        src={mData?.image}
                        alt={"order Img"}
                        style={{
                          objectFit: "contain",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container gap="5px" direction="column">
                      {" "}
                      <Grid item>
                        <Typography className="blackcolortypo500 fs18px ">
                          {mData?.status}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="blackcolor484848 fs16px">
                          {mData?.message}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="greyColorTypo717171_500 fs14px">
                          {/* {mData?.createdAt?.split("T")[0]}{" "}
                          {mData?.createdAt?.split("T")[1]?.split(".")[0]} */}
                          {formatDate(new Date(mData?.createdAt))}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : loading ? (
              <>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((a, i) => (
                  <Grid
                    container
                    item
                    sx={{ padding: "10px 0", borderBottom: "1px solid #ddd" }}
                  >
                    <Grid item sx={{ width: "100px", height: "100%" }}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ height: "100%" }}
                      >
                        {" "}
                        <Skeleton
                          variant="circular"
                          sx={{ width: "60px", height: "60px" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container gap="5px" direction="column">
                        <Grid item>
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "100px" }}
                          />
                        </Grid>
                        <Grid item>
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "200px" }}
                          />
                        </Grid>

                        <Grid item>
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "180px" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "50vh" }}
                direction="column"
              >
                <Grid container justifyContent="center">
                  {" "}
                  <img
                    src={notification}
                    alt="notification"
                    style={{ width: "150px" }}
                  />
                </Grid>

                <Typography className="fs20px">
                  No notifications to show yet
                </Typography>
                <Typography className=" fs16px" sx={{ color: "#6D787D" }}>
                  You’ll see useful information here soon. Stay tuned!
                </Typography>
              </Grid>
            )}
          </Grid>
        </InfiniteScroll>
      </div>
    </div>
  );
}
