import React, { useEffect, useState } from "react";

import { Typography, Grid, Card, Chip, Dialog, ListItem } from "@mui/material";
// Core Images
import WallMounted from "../../../../assets/Img/WallMounted.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoaderDialogue from "../../../../component/LoaderDialogue/LoaderDialogue";
// import { useBillboard } from "../../../../Context/AddBillboardContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Submit({
  priceData,
  formdata,
  file,
  loading,
  deviceName,
  checkFile,
}) {
  //=======================================================================//
  const renderImage = Object.values(checkFile);
  let renderMapImage = [];
  try {
    renderMapImage = renderImage?.filter((a, i) => {
      if (a !== null && a !== undefined) {
        return a;
      }
    });
  } catch (err) {
    console.log(err, "I am an error");
  }

  return (
    <>
      {" "}
      <Snackbar></Snackbar>
      <div className="admin-head">
        <Typography className="whitecolortypo500 fs16px">
          Time Slot Type*
        </Typography>
      </div>
      <div className="admin-content">
        <Grid container spacing={3} className="mt10px">
          {renderMapImage?.map((a, i) => (
            <>
              {a?.type == "image/jpeg" ? (
                <Grid item xs={12} sm={6} md={3} key={i}>
                  <Card className="billboardImage-card ">
                    <img
                      loading="lazy"
                      src={
                        URL.createObjectURL(a)
                          ? URL.createObjectURL(a)
                          : WallMounted
                      }
                      alt="WallMounted "
                      className="width100"
                    />
                  </Card>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={3} key={i}>
                  <Card className="billboardImage-card ">
                    <video
                      loading="lazy"
                      src={
                        URL.createObjectURL(a)
                          ? URL.createObjectURL(a)
                          : WallMounted
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      autoPlay
                      muted
                      loop
                    />
                  </Card>
                </Grid>
              )}
            </>
          ))}
        </Grid>

        <Grid container className="mt10px" rowGap="20px">
          <Grid item xs={12} md={6}>
            <Typography
              component="h5"
              className="blackcolortypo Detail fs20px mt10px"
            >
              Billboard Detail
            </Typography>
            <Grid container className="mt25px">
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo ">
                  Billboard ID
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 ">
                  {formdata?.billboardId}
                </Typography>
              </Grid>
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  BIllboard Name
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 mt10px">
                  {formdata?.billboardName}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Owner
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 mt10px">
                  {formdata?.billboardOwner}{" "}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Size
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 mt10px">
                  {formdata?.billboardSize}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Address
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 mt10px">
                  {formdata?.fullAddress}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="h5"
              className="blackcolortypo Detail fs20px mt10px "
            >
              Device Detail
            </Typography>
            <Grid container className="mt25px ">
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo ">
                  Device ID
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 ">
                  {formdata?.deviceId}
                </Typography>
              </Grid>
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Device Name
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 mt10px">
                  {deviceName}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Device Sensors
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <ListItem className="">
                  <Chip
                    className="addbilsub-chipstyle "
                    //label="Proximity Sensor"
                    label="NA"
                    variant="outlined"
                    clickable={true}
                  />{" "}
                  <Chip
                    className="addbilsub-chipstyle "
                    sx={{ ml: 3 }}
                    //label="Proximity Sensor"
                    label="NA"
                    variant="outlined"
                    clickable={true}
                  />{" "}
                </ListItem>
              </Grid>{" "}
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="mt20px" rowGap="20px">
          <Grid item xs={12} md={6}>
            <Typography
              component="h5"
              className="blackcolortypo Detail fs20px mt10px"
            >
              Billboard Type
            </Typography>
            <Grid container className="mt25px ">
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo ">
                  Billboard Location
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 ">
                  {formdata?.locationType}
                </Typography>
              </Grid>
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Type
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 mt10px">
                  {formdata?.billboardType}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Venue
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 mt10px">
                  {formdata?.venueTags}
                </Typography>
              </Grid>{" "}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="h5"
              className="blackcolortypo Detail fs20px mt10px "
            >
              Billboard Insight
            </Typography>
            <Grid container className="mt25px ">
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo ">
                  Billboard Time slot
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 ">
                  {priceData?.timeslotType}
                </Typography>
              </Grid>
              {/* <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Base view
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="greycolor505050500 mt10px">
                  {"  "}24 hour ₹ 7800/sec
                </Typography>
              </Grid>{" "} */}
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Base Price
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="blackcolor484848 mt10px">
                  {" "}
                  ₹ {priceData?.basePrice} /sec
                </Typography>
              </Grid>{" "}
            </Grid>
          </Grid>
        </Grid>

        <LoaderDialogue loading={loading} />
      </div>
    </>
  );
}
