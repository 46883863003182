import * as React from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "#F00000" }} {...other}>
      {children}
      <Typography className="whitecolortypo500 fs18px">Alert</Typography>{" "}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: -2,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SizeAndDurationDialogue = ({
  videoSizeDialogue,
  imageSizeDialogue,
  setImageSizeDialogue,
  setVideoSizeDialogue,
  adDuration,
  durationD,
}) => {
  const [open, setOpen] = React.useState(
    videoSizeDialogue ? videoSizeDialogue : imageSizeDialogue
  );

  const handleClose = () => {
    setOpen(false);
    if (videoSizeDialogue) {
      setVideoSizeDialogue(false);
    } else {
      setImageSizeDialogue(false);
    }
  };
  React.useEffect(() => {
    if (videoSizeDialogue ? videoSizeDialogue : imageSizeDialogue) {
      setOpen(true);
    }
  }, [videoSizeDialogue ? videoSizeDialogue : imageSizeDialogue]);

  return (
    <div style={{ width: "100%" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle onClose={handleClose}> </BootstrapDialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "20px",
          }}
        >
          <Grid container gap="15px" direction="column">
            <Typography className="blackcolortypo500 fs20px">
              {videoSizeDialogue
                ? `The content size and duration should be less than ${
                    adDuration ? adDuration * 2 : durationD * 2
                  }mb and ${adDuration ? adDuration : durationD} second.`
                : "The content size should be less than 10mb."}
            </Typography>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SizeAndDurationDialogue;
