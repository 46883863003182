import { Divider, Grid, Typography } from "@mui/material";
import WithDrawForm from "./WithdrawForm";
import moment from "moment";

const TransactionItem = ({
  data,
  role,
  value,
  fetchWithdrawalTrans,
  getTransactionDetails,
}) => (
  <Grid container>
    <Typography mt={1} className="blackcolor484848 fs20px">
      {data?.message === "Advertisement Payment"
        ? `${data.message} orderId ${data.orderId}`
        : data?.message || "Withdrawal Transaction"}
    </Typography>
    <Grid container justifyContent="space-between">
      <Grid item xs={3}>
        <Typography mt={2} className="greycolor505050500">
          Name :{" "}
          {`${data?.userId?.firstName || ""} ${
            data?.userId?.lastName || ""
          }`.trim()}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography mt={2} className="greycolor505050500">
          Number : {data?.userId?.mobileNumber}
        </Typography>
      </Grid>{" "}
      <Grid item xs={3}>
        <Typography mt={2} className="greycolor505050500">
          Email : {data?.userId?.email}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
          <Grid item>
            <Typography
              className={
                data?.amount ? "greycolor505050500" : "greycolor505050500"
              }
            >
              ₹ {data.amount.toFixed(2)}
            </Typography>
            {data?.transactionStatus && (
              <Typography
                style={{ textAlign: "end" }}
                className={
                  data?.transactionStatus === "successful"
                    ? "success_color"
                    : "pending_color"
                }
              >
                {data?.transactionStatus ?? data?.status}
              </Typography>
            )}
            {data?.status && (
              <Typography
                style={{ textAlign: "end" }}
                className={
                  data?.status === "approved"
                    ? "success_color"
                    : data?.status === "rejected"
                    ? "rejected_color"
                    : "pending_color"
                }
              >
                {data?.status}
              </Typography>
            )}
          </Grid>
          <Grid item ml={2}>
            {value === 1 && (
              <WithDrawForm
                role={role}
                data={data}
                fetchWithdrawalTrans={fetchWithdrawalTrans}
                getTransactionDetails={getTransactionDetails}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Typography className="greycolor505050500">
        Date: {moment(data?.createdAt).format("DD/MM/YYYY , h:mm a")}
      </Typography>
    </Grid>
    <Grid xs={12} mt={2}>
      <Divider orientation="horizontal" flexItem />
    </Grid>
  </Grid>
);
export default TransactionItem;
