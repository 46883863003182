import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  FormControl,
  InputBase,
  TextField,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#dddddd",
            // color: "#B937FA",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DetailDialog = ({
  date,
  duration,
  timeSlot,
  billBoardData,
  repeat,
  billboardId,
  item,
  index,
  timeArrMsgHandler,
}) => {
  const navigate = useNavigate();
  const [adTitle, setAdTitle] = React.useState(null);
  const [aboutAd, setAboutAd] = React.useState(null);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");
  const [open, setOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().getHours());

  const handleClickOpen = () => {
    if (
      date == moment(new Date()).format("YYYY-MM-DD") &&
      currentTime > timeSlot
    ) {
      return;
    }
    // if (timeSlot || timeSlot == 0) {
    //   setOpen(true);
    //   timeArrMsgHandler("");
    //   return;
    // }
    // timeArrMsgHandler("please select a time slot!");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [minsarr, setMinsarr] = useState([
    "Marketing/promotional ads",
    "Entertainment ads",
    "Public service announcement ads",
    "Event promotion ads",
    "Political campaign ads",
    "Educational/informational ads",
    "Community engagement ads",
    "Product launch ads",
    "Seasonal/holiday ads",
    "Sponsorship/partnership ads",
  ]);
  const [type, setType] = useState("");
  const disabled =
    adTitle?.trim().length > 0 &&
    aboutAd?.trim().length > 0 &&
    type?.trim()?.length > 0;
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().getHours());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Grid container justifyContent="center">
        {!billBoardData?.deviceId ||
        billBoardData?.deviceId?.deviceStatus === "Offline" ? (
          <Tooltip title="No device is assigned to this billboard">
            <div style={{ width: "100%" }}>
              <Button
                className="navbuttonstyle-2  width100"
                disabled={
                  !billBoardData?.deviceId
                    ? true
                    : billBoardData?.deviceId?.deviceStatus === "Offline" &&
                      date == moment(new Date()).format("YYYY-MM-DD")
                    ? true
                    : date == moment(new Date()).format("YYYY-MM-DD") &&
                      billBoardData?.deviceId?.deviceStatus === "Online"
                    ? false
                    : billBoardData?.deviceId?.deviceStatus === "Offline" &&
                      date != moment(new Date()).format("YYYY-MM-DD")
                    ? false
                    : true
                }
                onClick={() => {
                  handleClickOpen();
                }}
              >
                {!billBoardData?.deviceId
                  ? "Billboard is not available"
                  : billBoardData?.deviceId?.deviceStatus === "Offline" &&
                    date == moment(new Date()).format("YYYY-MM-DD")
                  ? "Billboard is not available"
                  : date == moment(new Date()).format("YYYY-MM-DD") &&
                    billBoardData?.deviceId?.deviceStatus === "Online"
                  ? "Continue to Book"
                  : billBoardData?.deviceId?.deviceStatus === "Offline" &&
                    date != moment(new Date()).format("YYYY-MM-DD")
                  ? "Continue to Book"
                  : "Continue to Book"}
              </Button>
            </div>
          </Tooltip>
        ) : (
          <div style={{ width: "100%" }}>
            <Button
              className="navbuttonstyle-2  width100"
              disabled={
                !billBoardData?.deviceId
                  ? true
                  : billBoardData?.deviceId?.deviceStatus === "Offline"
                  ? true
                  : false
              }
              onClick={() => {
                handleClickOpen();
              }}
            >
              {!billBoardData?.deviceId
                ? "Billboard is not available"
                : billBoardData?.deviceId?.deviceStatus === "Offline"
                ? "Billboard is not available"
                : date == moment(new Date()).format("YYYY-MM-DD") &&
                  timeSlot &&
                  currentTime > timeSlot
                ? "Please select correct timeslot"
                : "Continue to Book"}
            </Button>
          </div>
        )}
      </Grid>

      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {(billBoardData?.billboardOwner === "BusinessOwner" &&
          billBoardData?.assignedBO == null) ||
        (billBoardData?.billboardOwner === "PostMyAd" &&
          billBoardData?.addedBy == null) ? (
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Grid container className="Ad-detail-heading-container">
              <Typography
                align="center"
                className="blackcolortypo width100 fs20px"
              >
                {billBoardData?.billboardOwner === "BusinessOwner"
                  ? "   Please assigned Business Owner to this Billboard. "
                  : "   Please assigned Clusterhead Owner to this Billboard."}
              </Typography>
            </Grid>
          </BootstrapDialogTitle>
        ) : (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              <Grid container className="Ad-detail-heading-container">
                <Typography
                  align="center"
                  className="blackcolortypo width100 fs20px"
                >
                  just one more step!
                </Typography>
                <Typography
                  align="center"
                  className="blackcolortypo width100 fs20px"
                >
                  tell us about your advertisement
                </Typography>
              </Grid>
            </BootstrapDialogTitle>
            <DialogContent>
              <Grid
                container
                className="ad-detail-outer"
                sx={{ padding: "0px 20px" }}
              >
                <Grid container item md={12}>
                  <Grid item md={6} sm={12}>
                    <Typography className="blackcolortypo">Name*</Typography>
                    <Grid item md={11} sm={12}>
                      <InputBase
                        className="inputstyle-nobc"
                        onChange={(e) => setAdTitle(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography className="blackcolortypo">Type*</Typography>
                    <FormControl
                      className="MainPageFormControl  mt10px"
                      size="small"
                    >
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="timeslotstyle"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        inputProps={{ "aria-label": "Without label" }}
                        value={type ? type : 0}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <MenuItem value={0} disabled>
                          Please select a type
                        </MenuItem>
                        {minsarr && minsarr.length > 0 ? (
                          minsarr.map((x, i) => (
                            <MenuItem key={i} value={x}>
                              {x}
                            </MenuItem>
                          ))
                        ) : (
                          <h2>No Data</h2>
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} sm={11.5}>
                    <Typography className="blackcolortypo mt10px">
                      About*
                    </Typography>
                    <TextareaAutosize
                      aria-label="minimum height"
                      className="inputstyle-nobc"
                      minRows={4}
                      style={{
                        width: "95%",
                        fontFamily: "oswald",
                        padding: "10px",
                      }}
                      onChange={(e) => {
                        setAboutAd(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" className="mt10px">
                  <Button
                    className={
                      disabled
                        ? "navbuttonstyle-2  mt20px"
                        : "disabledbtn mt20px"
                    }
                    disabled={disabled ? false : true}
                    sx={{
                      width: "50%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    onClick={() => {
                      navigate("/admin/selectTimeslot", {
                        replace: true,
                        state: {
                          adTitle: adTitle,
                          aboutAd: aboutAd,
                          // timeSlot: timeSlot,
                          date: date,
                          // handleData: handleData,
                          billBoardData: billBoardData,
                          // repeat: repeat,
                          billboardId: billboardId,
                          type: type,
                          item: item,
                          index: index,
                          duration: duration,
                        },
                      });
                    }}
                  >
                    continue to book
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </BootstrapDialog>
    </>
  );
};

export default DetailDialog;
