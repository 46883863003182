import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Typography,
  DialogContent,
  InputBase,
  ListItemButton,
  FormControlLabel,
  Radio,
  IconButton,
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
  FormLabel,
  RadioGroup,
  Stack,
  Pagination,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
//React Icons
//core component
import ApproveDialog from "./ApproveDialogue";

import axiosInstance from "../../../../../../../api/axiosInstance";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",

  width: "36%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "93%",
      },
    },
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="dialogtitle" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogcrossicon"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AssignOwnerInfoHead({
  id,
  getOwnerHeadInfo,
  snackStates,
  getBillBoardData,
  billBoardData,
}) {
  const { setSnackOpen, setSnackMsg, setSnackerropen, setSnackErrMsg } =
    snackStates;
  // states

  console.log("ownerID", id);
  const [open, setOpen] = useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  const [ownerheadData, setownerheadData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [deviceslength, setDevicesLength] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  let [clId, setClId] = useState(null);
  //open close dialogue
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setClId(null);
    setOpen(false);
  };
  const handleChangeByPage = (event, value) => {
    setCurrentPage(value);
  };
  const selectOwnerHeadHandler = (data) => {
    setClId(data);
  };
  console.log("selectedUserId", clId);

  //   api calling
  const getOwnerhead = async () => {
    setDevicesLength([]);
    try {
      let resp = await axiosInstance.get(
        `api/user/getAllUsersForBO?billboardId=${id}&page=${currentPage}&limit=10`
      );
      if (resp.status == 200 || resp.status == 204) {
        console.log(resp, "ok see me");
        setownerheadData(resp?.data?.data);
        setTotalPage(Math.ceil(resp?.data?.length / 10));
      } else {
      }
    } catch (error) {
      console.log("error from Error ", error);
    }
  };

  console.log("ownerInfo update", ownerheadData);
  const assignOwnerHeadHandler = async () => {
    try {
      let body = {
        userId: clId,
        billboardId: id,
      };
      const res = await axiosInstance.post(`api/user/assignedToBO`, body);
      if (res.status == 200 || res.status == 204) {
        console.log("assigning Ownerhead", res);
        getBillBoardData();
        getOwnerHeadInfo();
        getOwnerhead();
        setSnackOpen(true);
        setSnackMsg(res.data.msg);
        getBillBoardData();
        handleClose();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.data.msg.err);
        console.log("error while assigning ownerhead", res);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getOwnerhead();
  }, [currentPage]);

  return (
    <div>
      <Typography
        className="p15px purplecolortypo-500 cursorpoint"
        align="center"
        onClick={handleClickOpen}
      >
        Update
      </Typography>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className="whitecolortypo500">
            Select Ownerhead{" "}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ m: 2 }}>
          <div>
            <div className="admin-content">
              <Grid container>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ width: "100%" }}
                >
                  {ownerheadData?.map((data, i) => {
                    return (
                      <Grid
                        item
                        key={i}
                        xs={12}
                        sm={5.5}
                        md={2.7}
                        sx={{ border: "1px solid #dddddd", marginLeft: "20px" }}
                        className="access-radio-grid "
                      >
                        <Grid container justifyContent="space-between">
                          <FormLabel>
                            <ListItemButton>
                              <Typography className=" greycolor505050400">
                                {data?.firstName
                                  ? data?.firstName + " " + data?.lastName
                                  : data?.name
                                  ? data?.firstName + " " + data?.lastName
                                  : "--"}
                                <Typography className="lightgreycolortypo">
                                  {data?.role ? data?.role : "--"}
                                </Typography>
                              </Typography>
                            </ListItemButton>
                          </FormLabel>
                          <FormControlLabel
                            className="radiostyle access-radio-formcontrolabel"
                            value={data?._id}
                            style={{ paddingTop: "10px" }}
                            control={<Radio />}
                            key={data?._id}
                            onChange={(e) => selectOwnerHeadHandler(data?._id)}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <DialogActions className="dialogaction-p0px mt20px">
            <Stack spacing={2}>
              <Pagination
                count={totalPage}
                page={currentPage}
                onChange={handleChangeByPage}
                size="large"
              />
            </Stack>
          </DialogActions>
        </Grid>
        <DialogActions>
          <Button className="previousbtn" onClick={handleClose}>
            Cancel
          </Button>
          <ApproveDialog
            clId={clId}
            assignOwnerHeadHandler={assignOwnerHeadHandler}
            getBillBoardData={getBillBoardData}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
