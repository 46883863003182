import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  // BootstrapDialog,
  TextField,
  Divider,
  Input,
  Button,
  DialogActions,
  InputBase,
} from "@mui/material";
import PropTypes from "prop-types";
import { RiCouponLine } from "react-icons/ri";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CouponImage from "../../../../assets/Img/Rectangle 8899.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axiosInstance from "../../../../api/axiosInstance";
import SuceedFullDialog from "../../../../utils/Dialog/SuceedFullDialog";
// import SuccessDialog from "./SuccessCoupon";
// external components
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ padding: "5px 10px" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
//   end external components

const CouponDialogue = (props) => {
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState(true);
  const [open, setOpen] = useState(false);
  const [allCoupon, setAllCoupon] = useState(["", ""]);
  const [checkRedeem, setCheckRedeem] = useState(["", ""]);
  const [amount, setAmount] = useState("");
  const [copyCode, setCopyCode] = useState("");
  const [couponCodeStatus, setCouponCodeStatus] = useState("first");
  const [disAmmount, setDisAmmount] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const Item = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(1),
  }));
  const copyCodeHandler = (e) => {
    setCopyCode(e);
    CheckAndRedeem();
  };

  const getAllCoupon = async () => {
    console.log("get all coupon call");
    let body = {
      type: "active",
      limit: 50,
      page: 1,
      recent: true,
    };
    try {
      const res = await axiosInstance.post(`/coupon/getAllCoupon`, body);

      if (res.status == 200 || res.status == 201) {
        setAllCoupon(res.data.msg);
      }
    } catch (err) {
      console.log("erorr in get all coupon", err);
    }
  };

  const CheckAndRedeem = async (code) => {
    if ((copyCode || code) && props.total) {
      let body = {
        userId: localStorage.getItem("saps_id"),
        couponCode: copyCode,
        amount: props.total,
      };
      if (code) {
        body.couponCode = code;
      }
      console.log("code >>> coupon ", body);
      try {
        const res = await axiosInstance.post(`/coupon/applyCouponToUser`, body);
        console.log("couipon>>", res);
        if (res.data.status) {
          setAmount(res.data.data.couponAmount);
          setDisAmmount(res.data.data.couponAmount);
          props.couponDiscountHandler(res.data.data.couponAmount);
          props.discountAmmount(res.data.data.couponAmount);
          props?.couponCode(res?.data?.data?.couponCode);
          setCouponCodeStatus("valid");
          setOpen(false);
          setSuccessPopup(true);
        } else {
          setCouponCodeStatus("invalid");
          props.discountAmmount(null);
          setDisAmmount(null);
        }
      } catch (error) {
        console.log("erorr in check coupon", error);
      }
    } else {
      setCouponCodeStatus("empty");
      props.discountAmmount(null);
      setDisAmmount(null);
    }
  };
  useEffect(() => {
    getAllCoupon();
  }, []);

  useEffect(() => {
    // CheckAndRedeem();
  }, [checkRedeem]);

  return (
    <>
      <Grid
        container
        className="mb1-5rem mt5px"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          md={4}
          xs={6}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid container md={2} xs={3}>
            {" "}
            <RiCouponLine />{" "}
          </Grid>
          <Grid container md={8} xs={6}>
            <Typography
              sx={{ fontSize: "18px", fontweight: "500", fontFamily: "oswald" }}
            >
              {" "}
              Coupons{" "}
            </Typography>
          </Grid>
        </Grid>
        <Button
          sx={{
            border: "none !important",
          }}
          onClick={() => setOpen(true)}
        >
          <Typography
            sx={{
              color: "#B937FA !important",
              fontWeight: "bold",
              fontFamily: "oswald",
            }}
          >
            {props.couponDiscount ? "Change" : "Apply"}
          </Typography>
        </Button>
      </Grid>
      <Dialog
        maxWidth={"lg"}
        open={open}
        // onClose={handleClose}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpen(false)}
          className=" family-color-white  mb10px purple-gradient"
          sx={{ fontSize: "18px" }}
        >
          Apply Coupon
        </BootstrapDialogTitle>
        <Divider />

        <DialogContent>
          <Grid
            container
            sx={{
              // border: "1px solid #ddd",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <Grid
              container
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                border: "1px solid #ddd",
                padding: "5px",
                borderRadius: "3px",
              }}
            >
              <Grid container md={8} sm={8} xs={8}>
                <InputBase
                  className="font-family "
                  placeholder="Enter your coupon code"
                  style={{ width: "100%" }}
                  value={copyCode}
                  onChange={(e) => setCopyCode(e.target.value)}
                />
              </Grid>
              <Grid container md={4} sm={4} xs={4} justifyContent="flex-end">
                <Button
                  sx={{
                    color: " #B937FA",
                  }}
                  className={copyCode ? "colorApply" : " "}
                  onClick={() => CheckAndRedeem(null)}
                  disabled={copyCode ? false : true}
                >
                  Apply
                </Button>
              </Grid>
              <Grid md={12} sm={12}>
                {couponCodeStatus == "empty" && !copyCode && (
                  <Typography sx={{ color: "red" }}>
                    Please Enter Coupon code
                  </Typography>
                )}
                {couponCodeStatus == "invalid" && (
                  <Typography sx={{ color: "red" }}>
                    Sorry,thiscouponis not valid for this user account
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container item spacing={2} style={{ marginTop: "20px" }}>
              {allCoupon?.map((cData, i) => (
                <Grid item xs={6} md={5} lg={3} key={i}>
                  <Item>
                    <Grid
                      container
                      style={{
                        border: "1px solid #ddd",
                        borderBottom: "none",
                        borderRadius: "8px 8px 0 0",
                      }}
                    >
                      <Grid xs={12}>
                        <img
                          src={CouponImage}
                          style={{ width: "100%" }}
                          alt="coupon image"
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Typography
                          className="center-text blackcolortypo500 fs16px"
                          style={{ textTransform: "inherit" }}
                        >
                          {cData?.couponCode}
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography className="center-text redColorTypo fs32px">
                          {cData?.discount}%
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography className="center-text greycolortypo6F6F6F_500 fs12px">
                          Up to {cData?.couponAmount} off On a minimum spend of{" "}
                          {cData?.minSpend}
                          {/* {cData?.minSpend > props?.total ? "rtyui" : "gfhj"} */}
                        </Typography>
                      </Grid>
                      <Grid xs={12} style={{ margin: "8px 0" }}>
                        <Typography className="center-text blackcolortypo500 fs14px">
                          expires on {cData?.endDate?.split("T")[0]}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        borderRadius: "0 0 8px 8px",
                        padding: "10px 0",
                      }}
                      sx={{
                        backgroundColor: "gray ",
                      }}
                      gap="15px"
                      className={
                        cData?.minSpend > props?.total
                          ? ""
                          : "purple-gradient cursorpoint"
                      }
                      onClick={() =>
                        cData?.minSpend > props?.total
                          ? ""
                          : CheckAndRedeem(cData?.couponCode)
                      }
                    >
                      <Typography className="whitecolortypo500 fs14px">
                        {" "}
                        Apply
                      </Typography>
                    </Grid>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {successPopup && (
        <SuceedFullDialog
          openData={"success"}
          data={"Coupon Added Successfully"}
          parentNavigate="parentNavigate"
        />
      )}
    </>
  );
};

export default CouponDialogue;
