import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Grid, Typography, TextField, Button, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import axiosInstance from "../../../api/axiosInstance";
import Calendar from "../../../component/ReactDateRangePicker/ReactDateRangePickerFormonth";
import moment from "moment";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { OrderStatusContext } from "../../../Context/OrderStatusContext";

// import Calendar from "../../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
// import Button from "@mui/material";

const headCells = [
  {
    id: "OrderDate",
    numeric: true,
    label: "Order Date",
  },
  {
    id: "PublishedOrder",
    numeric: true,
    disablePadding: false,
    label: "Published Order ",
  },
  {
    id: "PendingOrder",
    numeric: true,
    disablePadding: false,
    label: "Pending Order",
  },

  {
    id: "CancelledOrder",
    numeric: true,
    disablePadding: false,
    label: "Cancelled Order ",
  },
  {
    id: "ExpiredOrder",
    numeric: true,
    disablePadding: false,
    label: "Expired Order ",
  },

  {
    id: "RejectedOrder",
    numeric: true,
    disablePadding: false,
    label: "Rejected Order ",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
  // scales: {
  //   y: {
  //     beginAtZero: true,
  //   },
  // },
};

const AnalyticsTabs = () => {
  //states for billboard count
  const [acceptedOrderCount, setAcceptedOrderCount] = useState(0);
  const [rejectedOrderCount, setRejectedOrderCount] = useState(0);
  const [publishedOrderCount, setPublishedOrderCount] = useState(0);
  //states for billboard count
  // analytics states
  const [date, setDate] = useState();
  const [datasets, setDataSets] = useState([]);
  const [DataSetsForAnalytics, setDataSetsForAnalytics] = useState([]);
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [dataForTable, setdataForTable] = useState(null);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [dense, setDense] = React.useState(false);
  const [fileName, setfileName] = React.useState("OrderAnalytics");

  // const [analyticsData, setAnalyticsData] = useState([]);
  const [orderType, setOrderType] = useState("rejected");
  const [acceptedOrderAnalyticsData, setAcceptedOrderAnalyticsData] = useState(
    []
  );
  const [rejectedOrderAnalyticsData, setRejectedOrderAnalyticsData] = useState(
    []
  );
  const valueAnalyticsData = acceptedOrderAnalyticsData;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dispatchUserEvent = (actionType, payload) => {
    switch (actionType) {
      case "responseData":
        setAcceptedOrderAnalyticsData([
          ...acceptedOrderAnalyticsData,
          payload.newUser,
        ]);
        return;

      default:
        return;
    }
  };

  const [publishedOrderAnalyticsData, setPublishedOrderAnalyticsData] =
    useState([]);
  // analytics states
  // analytics date and datasets
  const timeInHour = [
    "12:00 Am",
    "1:00 Am",
    "2:00 Am",
    "3:00 Am",
    "4:00 Am",
    "5:00 Am",
    "6:00 Am",
    "7:00 Am",
    "8:00 Am",
    "9:00 Am",
    "10:00 Am",
    "11:00 Am",
    "12:00 Pm",
    "1:00 Pm",
    "2:00 Pm",
    "3:00 Pm",
    "4:00 Pm",
    "5:00 Pm",
    "6:00 Pm",
    "7:00 Pm",
    "8:00 Pm",
    "9:00 Pm",
    "10:00 Pm",
    "11:00 Pm",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels:
      analyticsDate?.length == 24
        ? timeInHour
        : analyticsDate?.length == 12
        ? months
        : analyticsDate,
    datasets: datasets,
  };

  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };
  // analytics date and datasets
  // get analytics api
  const getOrderAnalyticsData = async () => {
    try {
      let res = await axiosInstance.get(
        `api/order/getOrderAnalyticsForAdmin?isCampaign=false&isTesting=false&startDate=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}`
      );

      setAcceptedOrderAnalyticsData(res?.data.data);

      // console.log("response from getting order analytics data =>", res);
      // console.log("dataAnalytics", acceptedOrderAnalyticsData);

      /////////////// datasets ////////////

      if (res.status == 200 || res.status == 201) {
        setDataSetsForAnalytics(res?.data.data);
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };

  useEffect(() => {
    if (!date) {
      return;
    }
    getOrderAnalyticsData();
  }, [orderType, date]);

  // function for one month date duration

  // date logics
  // making an empty array and pushing all data in (data with 0 count)
  let acceptedData = [];

  useEffect(() => {
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = acceptedOrderAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          approveOrder: acceptedOrderAnalyticsData[index].approveOrder,
          cancelOrder: acceptedOrderAnalyticsData[index].cancelOrder,
          expireOrder: acceptedOrderAnalyticsData[index].expireOrder,

          pendingOrder: acceptedOrderAnalyticsData[index].pendingOrder,
          publishOrder: acceptedOrderAnalyticsData[index].publishOrder,
          rejectOrder: acceptedOrderAnalyticsData[index].rejectOrder,
        };
        acceptedData.push(obj);
      } else {
        let obj = {
          Date: analyticsDate[i],
          expireOrder: 0,
          approveOrder: 0,
          cancelOrder: 0,

          pendingOrder: 0,
          publishOrder: 0,
          rejectOrder: 0,
        };
        acceptedData.push(obj);
      }
    }
    setdataForTable(acceptedData);
  }, [acceptedOrderAnalyticsData]);

  // setting datasets
  useEffect(() => {
    setDataSets([
      {
        label: "Expired Order",
        data: acceptedData?.map((a) => a?.expireOrder),
        borderColor: "#ff6f6f",
        backgroundColor: "#ff6f6f",

        borderRadius: 20,
        barThickness: 24,
      },
      {
        label: "Published",
        data: acceptedData?.map((a) => a?.publishOrder),
        borderColor: "rgb(215,35,205,100%)",
        backgroundColor: "rgba(215,35,205,100%)",

        borderRadius: 20,
        barThickness: 24,
      },

      {
        label: "Cancelled Order",
        data: acceptedData?.map((a) => a?.cancelOrder),
        borderColor: "#FFA500",
        backgroundColor: "#FFA500",

        borderRadius: 20,
        barThickness: 24,
      },
      // {
      //   label: "Live Order",
      //   data: acceptedData?.map((a) => a?.liveOrder),
      //   borderColor: "#00FF00",
      //   backgroundColor: "#00FF00",
      //   stack: "Stack 1",
      //   borderRadius: 20,
      //   barThickness: 24,
      // },
      {
        label: "Pending Order",
        data: acceptedData?.map((a) => a?.pendingOrder),
        borderColor: "#FFFF00",
        backgroundColor: "#FFFF00",
        stack: "Stack 1",
        borderRadius: 20,
        barThickness: 24,
      },
      {
        label: "Rejected Order",
        data: acceptedData?.map((a) => a?.rejectOrder),
        borderColor: "rgb(246,14,14,100%)",
        backgroundColor: "rgb(246,14,14,100%)",
        stack: "Stack 1",
        borderRadius: 20,
        barThickness: 24,
      },
    ]);
  }, [acceptedOrderAnalyticsData]);

  useEffect(() => {
    const firstData = acceptedOrderAnalyticsData?.map(
      (a) =>
        a.expireOrder +
        a.publishOrder +
        a.cancelOrder +
        a.pendingOrder +
        a.rejectOrder
    );
    const firstDataNew = firstData?.reduce((accum, elem) => {
      return (accum = accum + elem);
    }, 0);

    setAcceptedOrderCount(firstDataNew);
  }, [orderType, acceptedOrderAnalyticsData]);

  return (
    <>
      <div style={{ paddingBottom: "10px", marginBottom: "15px" }}>
        <Grid
          container
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            paddingTop: "10px",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            rowGap="10px"
          >
            <Grid item>
              <Grid
                container
                item
                gap="10px"
                sx={{ padding: "0 10px" }}
                alignItems="center"
              >
                <Typography className="blackcolor484848 fs18px">
                  Total Order : {acceptedOrderCount ? acceptedOrderCount : 0}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                item
                gap="10px"
                sx={{ padding: "0 10px" }}
                alignItems="center"
              >
                <Calendar getDataFromChildHandler={getDataFromChildHandler} />
              </Grid>
            </Grid>
          </Grid>

          <Line options={options} data={data} />
        </Grid>
      </div>
    </>
  );
};

export default AnalyticsTabs;
