import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  Input,
  TextField,
  TextareaAutosize,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import SelectDevice from "../SelectDeviceDialolg/SelectDevice";
import MapDialog from "../Bill-Map-Dialog/bill-map-dialog";

import Map from "../Bill-Map-Dialog/data/Map";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
export default function Testing({
  handleChange,
  formdata,
  formErrors,
  fileErrors,
  handleCountryStateChange,
  handleLatLong,
  handleDeviceId,
  handleFileSubmit,
  AllbillboardData,
  billBoardData,
  checkFile,
  thumbNail,
  handleThumbnail,
  setdeviceIdBill,
  deviceIdBill,
}) {
  //=====================================================//

  const [pincodeData, setPincodeData] = useState([]);

  //=============== all pincodes =======================//
  const getPincodeData = async () => {
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${formdata.pincode}`,
      {
        method: "GET",
      }
    );

    let res = await response.json();
    if (response.ok) {
      console.log("Pincode res ==>", res);
      handleCountryStateChange(
        res[0]["PostOffice"][0]["Country"],
        res[0]["PostOffice"][0]["State"],
        res[0]["PostOffice"][0]["District"]
      );
      let cities = res[0]["PostOffice"].map((data, key) => {
        return data.Name;
      });

      setPincodeData(cities);
      // handleStateChange(res[0]["PostOffice"][0]["State"]);
    } else {
      console.log("=== Data Error in Pincode Api ===");
    }
  };

  useEffect(() => {
    if (formdata.pincode && formdata.pincode.length === 6) {
      getPincodeData();
    }
  }, [formdata.pincode]);
  const Image = AllbillboardData?.filesArr;
  console.log("Checl Image Upload", Image);

  //=============================  Image Upload ==========================================//
  const onChangeHandler = (imageList, addUpdateIndex) => {
    // const file = imageList ? imageList?.target?.files[0] : null;
    // if (file && file.type !== "image/jpeg") {
    //   alert("Please select a JPEG file");
    //   return;
    // }
    handleFileSubmit(
      addUpdateIndex,
      imageList == null ? null : imageList?.target?.files?.[0]
    );
  };
  const thumnailHandler = (e, bool) => {
    handleThumbnail(e?.target?.files?.[0], bool);
  };
  return (
    <>
      <div>
        <DialogTitle className="dialogtitlenno-bg">
          <div className="admin-head">
            <Typography className="whitecolortypo500 fs16px">
              Billboard Detail
            </Typography>
          </div>{" "}
        </DialogTitle>
        <DialogContent className="dilaogcontent-billboardEdit">
          <Grid container className="pt10px" justifyContent="space-between">
            <Grid item xs={12} md={5.8}>
              <Grid container justifyContent="space-between" rowGap="10px">
                <Grid item xs={12} sm={5.8} md={5.8}>
                  <Typography className="greycolor505050500 fs16px">
                    Billboard ID*
                  </Typography>
                  <Input
                    className="inputstyle "
                    disableUnderline
                    name="billboardId"
                    placeholder="Enter your Billboard ID"
                    value={formdata.billboardId}
                    onChange={handleChange}
                  />
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors?.billboardId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <Typography className="greycolor505050500 fs16px">
                    Assign Device*
                  </Typography>{" "}
                  <SelectDevice
                    handleDeviceId={handleDeviceId}
                    billBoardData={billBoardData}
                    deviceIdBill={deviceIdBill}
                    setdeviceIdBill={setdeviceIdBill}
                  />
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors?.deviceId}
                  </Typography>
                </Grid>
              </Grid>
              <Typography className="greycolor505050500 fs16px mt10px ">
                Billboard Name*
              </Typography>
              <Input
                required={true}
                className="inputstyle"
                disableUnderline
                name="billboardName"
                value={formdata.billboardName}
                onChange={handleChange}
                placeholder="Enter Billboard Name"
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.billboardName}
              </Typography>
              <Grid container justifyContent="space-between" rowGap="10px">
                <Grid item xs={12} sm={5.8}>
                  <Typography className="greycolor505050500 fs16px mt10px">
                    Billboard Owner*
                  </Typography>
                  <FormControl
                    className="MainPageFormControl mt10px "
                    size="small"
                  >
                    <TextField
                      select
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      className="Selectdropstyle"
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="billboardOwner"
                      value={formdata.billboardOwner}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={handleChange}
                    >
                      <MenuItem
                        value={"BusinessOwner"}
                        //className="Selectmenustyle"
                      >
                        BusinessOwner
                      </MenuItem>
                      <MenuItem value={"PostMyAd"}>Post My Ad</MenuItem>
                      <MenuItem value={"Cluster Head"}>Cluster Head</MenuItem>
                    </TextField>
                  </FormControl>
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.billboardOwner}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <Typography className="greycolor505050500 fs16px mt10px">
                    Orientation*
                  </Typography>{" "}
                  <FormControl
                    className="MainPageFormControl mt10px "
                    size="small"
                  >
                    <TextField
                      select
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      className="Selectdropstyle"
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="billboardSize"
                      inputProps={{ "aria-label": "Without label" }}
                      value={
                        formdata?.billboardSize ? formdata?.billboardSize : 0
                      }
                      onChange={handleChange}
                    >
                      <MenuItem
                        value={0}
                        className="greycolor505050500 fs14px "
                        disabled
                        //className="Selectmenustyle"
                      >
                        Please choose a screen size
                      </MenuItem>
                      {/* <MenuItem
                        value={"Adani"}
                        className="greycolor505050500 fs14px "
                      >
                        Adani
                      </MenuItem> */}
                      <MenuItem
                        value={"1920X1440"}
                        className="greycolor505050500 fs14px "
                      >
                        Square(4:3)
                      </MenuItem>
                      {/* <MenuItem
                        value={"AdaniPotrait"}
                        className="greycolor505050500 fs14px "
                      >
                        Adani portrait
                      </MenuItem> */}
                      <MenuItem
                        value={"1920X1080"}
                        className="greycolor505050500 fs14px "
                        //className="Selectmenustyle"
                      >
                        Landscape (1920X1080)
                      </MenuItem>
                      <MenuItem
                        value={"1080X1920"}
                        className="greycolor505050500 fs14px "
                      >
                        Portrait (1080X1920)
                      </MenuItem>
                    </TextField>
                  </FormControl>
                  {/* <Input
                    className="inputstyle "
                    disableUnderline
                    name="billboardSize"
                    placeholder="Enter Billboard Size"
                    value={formdata.billboardSize}
                    onChange={handleChange}
                  /> */}
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.billboardSize}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" rowGap="10px">
                <Grid item xs={12} sm={5.8}>
                  <Typography className="greycolor505050500 fs16px mt10px">
                    Average Audience Income*
                  </Typography>
                  <FormControl
                    className="MainPageFormControl mt10px "
                    size="small"
                  >
                    <TextField
                      select
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      className="Selectdropstyle"
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="maxSalary"
                      value={formdata.maxSalary}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={handleChange}
                    >
                      <MenuItem
                        value={0}
                        className="greycolor505050500 fs14px "
                        //className="Selectmenustyle"
                        disabled
                      >
                        Select Average Audience Salary
                      </MenuItem>
                      <MenuItem
                        value={"Up to 50,000"}
                        className="greycolor505050500 fs14px "
                        //className="Selectmenustyle"
                      >
                        Up to 50,000
                      </MenuItem>
                      <MenuItem
                        value={"More than 50,000"}
                        className="greycolor505050500 fs14px "
                      >
                        More than 50,000
                      </MenuItem>
                    </TextField>
                  </FormControl>
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.maxSalary}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <Typography className="greycolor505050500 fs16px mt10px">
                    Average Audience Age*
                  </Typography>{" "}
                  <FormControl
                    className="MainPageFormControl mt10px "
                    size="small"
                  >
                    <TextField
                      select
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      className="Selectdropstyle"
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="maxAge"
                      inputProps={{ "aria-label": "Without label" }}
                      value={formdata?.maxAge}
                      onChange={handleChange}
                    >
                      <MenuItem
                        value={0}
                        className="greycolor505050500 fs14px "
                        disabled
                        //className="Selectmenustyle"
                      >
                        Select Average Audience Age
                      </MenuItem>

                      <MenuItem
                        value={"Upto 30"}
                        className="greycolor505050500 fs14px "
                      >
                        Upto 30
                      </MenuItem>
                      <MenuItem
                        value={"30-45"}
                        className="greycolor505050500 fs14px "
                      >
                        30-45
                      </MenuItem>
                      <MenuItem
                        value={"45 and above"}
                        className="greycolor505050500 fs14px "
                      >
                        45 and above
                      </MenuItem>
                    </TextField>
                  </FormControl>
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.maxAge}
                  </Typography>
                </Grid>
              </Grid>
              <Typography className="greycolor505050500 fs16px mt10px">
                About BillBoard*
              </Typography>
              <TextareaAutosize
                className="textarea"
                aria-label="minimum height"
                minRows={3}
                disableUnderline
                name="aboutBillboard"
                placeholder="Enter About Billboard"
                value={formdata.aboutBillboard}
                onChange={handleChange}
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.aboutBillboard}
              </Typography>
              <Typography className="greycolor505050500 fs16px mt10px">
                GuideLines*
              </Typography>
              <TextareaAutosize
                className="textarea"
                aria-label="minimum height"
                minRows={3}
                disableUnderline
                name="guidelines"
                placeholder="Enter About GuideLines"
                value={formdata.guidelines}
                onChange={handleChange}
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.guidelines}
              </Typography>
              <Typography className="greycolor505050500 fs16px">
                Full Address*
              </Typography>
              <TextareaAutosize
                className="textarea"
                aria-label="minimum height"
                minRows={3}
                disableUnderline
                name="fullAddress"
                placeholder="Enter full Address"
                value={formdata.fullAddress}
                onChange={handleChange}
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.fullAddress}
              </Typography>
              <Typography className="greycolor505050500 fs16px mt10px">
                Specification*
              </Typography>
              <TextareaAutosize
                className="textarea"
                aria-label="minimum height"
                minRows={3}
                disableUnderline
                name="specification"
                placeholder="Enter Specification"
                value={formdata.specification}
                onChange={handleChange}
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.specification}
              </Typography>
              <Typography className="greycolor505050500 fs16px">
                Supported Content*
              </Typography>
              <TextareaAutosize
                className="textarea"
                aria-label="minimum height"
                minRows={3}
                disableUnderline
                name="supportedResolution"
                placeholder="Enter Supported Content"
                value={formdata.supportedResolution}
                onChange={handleChange}
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.supportedResolution}
              </Typography>
              <Typography className="greycolor505050500 fs16px mt10px">
                Mark Position on Map*
              </Typography>{" "}
              {/* <MapDialog
                lattitude={formdata.lattitude}
                longitude={formdata.longitude}
                handleLatLong={handleLatLong}
              /> */}
              {/* { ((formdata.longitude !== 28.6448) && (formdata.lattitude !== 77.21965721684242 ) )? */}
              {/* : null} */}
              <Grid container className="mt20px">
                <Map
                  longitude={formdata.longitude}
                  lattitude={formdata.lattitude}
                  handleLatLong={handleLatLong}
                />
                <Typography className="formError" variant={"subtitle2"}>
                  {formErrors.lattitude}
                </Typography>
              </Grid>
              <Grid
                container
                sx={{ paddingTop: "20px" }}
                justifyContent="space-between"
                rowGap="10px"
              >
                <Grid item xs={12} md={5.8}>
                  <Typography className="greycolor505050500 fs16px">
                    Nearby Landmark*
                  </Typography>

                  <Input
                    className="inputstyle "
                    disableUnderline
                    name="landmark"
                    placeholder="Enter your nearby Landmark"
                    value={formdata.landmark}
                    onChange={handleChange}
                  />
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.landmark}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5.8}>
                  <Typography className="greycolor505050500 fs16px ">
                    Pincode*
                  </Typography>{" "}
                  <Input
                    className="inputstyle "
                    type="number"
                    disableUnderline
                    name="pincode"
                    placeholder="Enter your Pincode"
                    value={formdata.pincode}
                    onChange={(e) => {
                      if (String(e.target.value).length <= 6) {
                        handleChange(e);
                      }
                    }}
                  />
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.pincode}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" rowGap="10px">
                <Grid item xs={12} sm={5.8}>
                  <Typography className="greycolor505050500 fs16px mt10px">
                    District*
                  </Typography>
                  <Input
                    className="inputstyle "
                    disableUnderline
                    name="district"
                    placeholder="Enter your district"
                    value={formdata?.district}
                    disabled
                    // onChange={handleChange}
                  />
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.district}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <Typography className="greycolor505050500 fs16px mt10px">
                    City*
                  </Typography>{" "}
                  <FormControl
                    className="MainPageFormControl mt10px "
                    size="small"
                  >
                    <TextField
                      select
                      variant="filled"
                      name="city"
                      InputProps={{ disableUnderline: true }}
                      className="Selectdropstyle"
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={formdata.city ? formdata.city : 0}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={handleChange}
                    >
                      <MenuItem value={0} disabled>
                        Please select a city
                      </MenuItem>
                      {pincodeData && pincodeData?.length > 0
                        ? pincodeData.map((x, i) => (
                            <MenuItem key={i} value={x}>
                              {console.log(x, "ok", formdata.city, "i am city")}
                              {x}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </FormControl>
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.city}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" rowGap="10px">
                <Grid item xs={12} sm={5.8}>
                  <Typography className="greycolor505050500 fs16px mt10px">
                    State*
                  </Typography>
                  <Input
                    className="inputstyle "
                    disableUnderline
                    disabled
                    name="state"
                    placeholder="Enter your State"
                    value={formdata.state}
                    onChange={handleChange}
                  />
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.state}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <Typography className="greycolor505050500 fs16px mt10px">
                    Country*
                  </Typography>{" "}
                  <Input
                    className="inputstyle "
                    disableUnderline
                    disabled
                    name="country"
                    placeholder="Enter your Country"
                    value={formdata.country}
                    onChange={handleChange}
                  />
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.country}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* ======================== Image Upload =================== */}
            <Grid tem xs={12} md={5.8}>
              <Typography className=" greycolor505050500 fs16px ">
                Upload Media of Billboard*
              </Typography>
              <div style={{ marginTop: "10px" }}>
                <Grid container justifyContent="space-between" rowGap="15px">
                  {["file1", "file2", "file3", "file4", "file5", "file6"]?.map(
                    (a, b) => (
                      <Grid
                        container
                        item
                        xs={5.8}
                        lg={3.8}
                        style={{
                          height: 200,
                          width: "100%",
                          border: "1px solid #dddddd",
                          boxShadow: "none",
                          backgroundColor: "#f7f8fd",
                          position: "relative",
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        {!!checkFile?.[a] ? (
                          <>
                            {(typeof checkFile?.[a] == "object" &&
                              checkFile?.[a]?.type == "video/mp4") ||
                            (typeof checkFile?.[a] !== "object" &&
                              checkFile?.[a]?.split(".")?.[
                                checkFile?.[a]?.split(".").length - 1
                              ] == "mp4") ? (
                              <Grid
                                container
                                item
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  position: "relative",
                                }}
                              >
                                <video
                                  src={
                                    typeof checkFile?.[a] == "object"
                                      ? URL.createObjectURL(checkFile?.[a])
                                      : checkFile?.[a]
                                  }
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  autoPlay
                                  muted
                                  loop
                                />
                                <div className="image-item__btn-wrapper">
                                  <ClearIcon
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => onChangeHandler(null, b + 1)}
                                  />
                                </div>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                item
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={
                                    typeof checkFile?.[a] == "object"
                                      ? URL.createObjectURL(checkFile?.[a])
                                      : checkFile?.[a]
                                  }
                                  alt={"image"}
                                  style={{ width: "100%", height: "100%" }}
                                />
                                <div className="image-item__btn-wrapper">
                                  <ClearIcon
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => onChangeHandler(null, b + 1)}
                                  />
                                </div>
                              </Grid>
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <input
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                opacity: "0",
                                border: "1px solid red",
                              }}
                              type="file"
                              id="raised-button-file"
                              accept="image/jpeg, video/mp4"
                              onChange={(e) => onChangeHandler(e, b + 1)}
                            />
                            <div>
                              <AddCircleOutlineIcon
                                sx={{ fontSize: "60px", color: "#E141C3" }}
                              />
                            </div>
                          </>
                        )}
                      </Grid>
                    )
                  )}
                </Grid>
              </div>
              <Typography className="formError mt10px" variant={"subtitle2"}>
                {fileErrors.file}
              </Typography>

              <Grid item xs={12} sx={{ marginTop: "15px" }}>
                <Typography className=" greycolor505050500 fs16px ">
                  Upload Image For Thumbnail (Supported format jpeg)*
                </Typography>
                <Grid
                  container
                  item
                  xs={5.8}
                  lg={3.8}
                  style={{
                    height: 200,
                    width: "100%",
                    border: "1px solid #dddddd",
                    boxShadow: "none",
                    backgroundColor: "#f7f8fd",
                    position: "relative",
                    marginTop: "13px",
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  {thumbNail ? (
                    <Grid
                      container
                      item
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <img
                        src={
                          typeof thumbNail == "string"
                            ? thumbNail
                            : URL.createObjectURL(thumbNail) &&
                              URL.createObjectURL(thumbNail)
                        }
                        alt={"image"}
                        style={{ width: "100%", height: "100%" }}
                      />
                      <div className="image-item__btn-wrapper">
                        <ClearIcon
                          onClick={(e) => thumnailHandler(null, false)}
                          style={{
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <>
                      {" "}
                      <input
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          opacity: "0",
                          border: "1px solid red",
                        }}
                        type="file"
                        id="raised-button-file"
                        accept=".jpg, .jpeg"
                        onChange={(e) => thumnailHandler(e, true)}
                      />
                      <div>
                        <AddCircleOutlineIcon
                          sx={{ fontSize: "60px", color: "#E141C3" }}
                        />
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>

              <Typography className="formError mt10px" variant={"subtitle2"}>
                {formErrors.thumbNail}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </div>
    </>
  );
}
