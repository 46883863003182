import { Button, Grid } from "@mui/material";
import React, { useState } from "react";

const OrderStatus = ({ handleFilterData, type }) => {
  const [orderStatus, setOrderStatus] = useState("All");

  const orderChangeHandler = (e) => {
    handleFilterData(e, "type");
  };
  console.log(orderStatus, "orderStatus");
  return (
    <>
      <Grid container gap="10px">
        <Grid item>
          <Button
            style={{
              background: "#fff",
              padding: "10px 20px ",
              borderRadius: "18px ",
              textTransform: "inherit ",
            }}
            className={
              type === "Pending"
                ? "filter_status_btn_orange_selected"
                : "filter_status_btn_orange"
            }
            onClick={() => {
              setOrderStatus("Pending");
              orderChangeHandler("Pending");
            }}
          >
            Pending
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{
              background: "#fff",
              padding: "10px 20px ",
              borderRadius: "18px ",
              textTransform: "inherit ",
            }}
            className={
              type === "Approved"
                ? "filter_status_btn_green_selected"
                : "filter_status_btn_green"
            }
            onClick={() => {
              setOrderStatus("Approved");
              orderChangeHandler("Approved");
            }}
          >
            Approved
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{
              background: "#fff",
              padding: "10px 20px ",
              borderRadius: "18px ",
              textTransform: "inherit ",
            }}
            className={
              type === "Rejected"
                ? "filter_status_btn_red_selected"
                : "filter_status_btn_red"
            }
            onClick={() => {
              setOrderStatus("Rejected");
              orderChangeHandler("Rejected");
            }}
          >
            Rejected
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{
              background: "#fff",
              padding: "10px 20px ",
              borderRadius: "18px ",
              textTransform: "inherit ",
            }}
            className={
              type === "Live"
                ? "filter_status_btn_live_selected"
                : "filter_status_btn_live"
            }
            onClick={() => {
              setOrderStatus("Live");
              orderChangeHandler("Live");
            }}
          >
            Live
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{
              background: "#fff",
              padding: "10px 20px ",
              borderRadius: "18px ",
              textTransform: "inherit ",
            }}
            className={
              type === "Published"
                ? "filter_status_btn_published_selected"
                : "filter_status_btn_published"
            }
            onClick={() => {
              setOrderStatus("Published");
              orderChangeHandler("Published");
            }}
          >
            Published
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderStatus;
