import React, { useEffect, useState } from "react";

import {
  Typography,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Skeleton,
  Button,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import no_billboard_found from "../../../../../assets/Img/no_billboard_found.gif";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
}));
const AllBillboard = ({
  formErrors,
  data,
  storeBillBoardId,
  storeDeviceId,
  storeMacId,
  storeScreenData,
  filterArr,
  selectAllScreensHandler,
  smartBillboard,
  billboardLength,
  loading,
  filterdata,
  selectedFilter,
}) => {
  return (
    <div

    // className="campaign-container"
    >
      <div>
        <Grid container>
          <Grid container className="campaign-billboard">
            <Typography className="formError mt5px" variant={"subtitle2"}>
              {formErrors?.billBoardArr}
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "10px" }}
            >
              {smartBillboard?.length ? (
                <FormGroup>
                  <FormControlLabel
                    className="greycolor505050500 fs16px "
                    control={
                      <Checkbox
                        className="purple-icon"
                        checked={
                          data?.billBoardArr?.length == 0
                            ? false
                            : data?.billBoardArr?.length === filterArr?.length
                            ? true
                            : false
                        }
                      />
                    }
                    label={
                      <Typography className="greycolor505050500 fs16px">
                        Select all
                      </Typography>
                    }
                    onChange={(e) => {
                      storeBillBoardId(null, "selectAll");
                      storeMacId(null, "selectAll");
                      storeDeviceId(null, "selectAll");
                      selectAllScreensHandler(e);
                    }}
                  />
                </FormGroup>
              ) : (
                ""
              )}

              <Typography className="greycolor505050500 fs16px">
                {data?.billBoardArr.length} Selected
              </Typography>
            </Grid>{" "}
            {smartBillboard?.length > 0 ? (
              <Grid container spacing={3}>
                {smartBillboard?.map((item, i) => (
                  <>
                    {" "}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item>
                        <FormControlLabel
                          className="billboard-screencheckbox width100"
                          control={
                            <Checkbox
                              className={item?.deviceId ? "purple-icon" : ""}
                              checked={
                                data?.billBoardArr.includes(item?._id)
                                  ? true
                                  : false
                              }
                            />
                          }
                          // disabled={
                          //   item?.deviceId == null ||
                          //   (filterdata?.length > 0 &&
                          //     filterdata?.[0]?.billboardSize !==
                          //       selectedFilter?.size?.[0]?.split(" ")[0])
                          //     ? true
                          //     : false
                          // }
                          disabled={item?.deviceId == null ? true : false}
                          onChange={(e) => {
                            storeBillBoardId(item?._id, "singleSelect");
                            storeDeviceId(item?.deviceId?._id, "singleSelect");
                            storeMacId(item?.deviceId?.macId, "singleSelect");
                            storeScreenData(e, item, i);
                          }}
                          label={
                            <React.Fragment>
                              <div className="width100 relative">
                                <Card className="billboardImage-card  mt10px ">
                                  <img
                                    src={item?.thumbnail}
                                    alt="Kidsk"
                                    className="width100"
                                  />
                                </Card>
                                <CardContent className="billboard-cardcontetnt width100">
                                  <Typography
                                    className="blackcolortypo500 fs16px width100"
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {item?.billboardName}
                                  </Typography>
                                  <Grid
                                    container
                                    justifyContent="space-between"
                                  >
                                    <Typography className="greycolor505050500 14px ">
                                      {item?.district}
                                    </Typography>
                                    <Typography className="greycolor505050500 14px ">
                                      ₹ {item?.basePrice}
                                    </Typography>
                                  </Grid>
                                </CardContent>
                                {item?.deviceId == null && (
                                  <div
                                    style={{
                                      width: "100px",
                                    }}
                                    className="sold_out_btn"
                                  >
                                    <Button className=" timelslot-red-btn ">
                                      Sold Out
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </React.Fragment>
                          }
                        />
                      </Item>
                    </Grid>
                  </>
                ))}{" "}
              </Grid>
            ) : loading === true ? (
              <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((a, i) => (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Item>
                      <Skeleton
                        variant="rectangular"
                        sx={{ height: "200px" }}
                      />
                    </Item>
                  </Grid>
                ))}
              </>
            ) : (
              <Grid container justifyContent="center" alignItems="center">
                <img
                  src={no_billboard_found}
                  alt="no_billboard_found"
                  style={{ width: "500px" }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
// export default AllBillboard;
export default AllBillboard;
