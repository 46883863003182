import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Breadcrumbs,
  Box,
  Button,
  TextField,
  MenuItem,
  FormControl,
} from "@mui/material";
import { GrView } from "react-icons/gr";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import useExportExcel from "../../../../../../../utils/useExportExcelFile";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Calendar from "../../../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
import moment from "moment";
import axiosInstance from "../../../../../../../api/axiosInstance";
import nodata from "../../../../../../../assets/Img/no_data_found_img.png";
import { SignLanguage } from "@mui/icons-material";
// import Calendar from "../../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
// import Button from "@mui/material";

const headCells = [
  {
    id: "Ranking",
    numeric: true,
    label: "Ranking",
  },
  {
    id: "Advertisee name",
    numeric: true,
    disablePadding: false,
    label: "Advertisee name  ",
  },
  {
    id: "Orders",
    numeric: true,
    disablePadding: false,
    label: "Orders",
  },
  {
    id: "Email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "Phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "Female Views",
    numeric: true,
    disablePadding: false,
    label: "Female Views",
  },
  {
    id: "Male Views",
    numeric: true,
    disablePadding: false,
    label: "Male Views",
  },
  {
    id: "Views",
    numeric: true,
    disablePadding: false,
    label: "Views",
  },
  {
    id: "Revenue",
    numeric: true,
    disablePadding: false,
    label: "Revenue ",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        {/* <Grid container justifyContent="space-between">
          <Typography className="gray5A5A5Atypo500 fs20px">
            Billboard type
          </Typography>
          <Grid item>
            <Grid container gap="10px">
              
              <Button
                sx={{ padding: "0 10px" }}
                className="purple_btn_primary text_transform_inherit"
               
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const AdvertiseViseTable = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [dense, setDense] = React.useState(false);
  const [dateType, setDateType] = useState("orders");
  const [date, setDate] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [getAdvertiseViseData, setgetAdvertiseViseData] = useState([]);
  const [fileName, setfileName] = useState("Advertise Vise");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const { exportToExcel } = useExportExcel();
  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };

  /////////////////api call ////////////////
  useEffect(() => {
    if (!date) {
      return;
    }

    getDataRevenueByAdvertise();
  }, [date]);

  const getDataRevenueByAdvertise = async () => {
    try {
      let res = await axiosInstance.get(
        `api/billboard/getUserRankingForBillboard?billboardId=${
          props?.id
        }&startDate=${moment(date?.[0]?.startDate).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(date?.[0]?.endDate).format("YYYY-MM-DD")}`
      );

      console.log("response from setgetAdvertiseViseData =>", res);
      if (res.status == 200 || res.status == 201) {
        //  setAcceptedOrderAnalyticsData(res?.data.data);

        setgetAdvertiseViseData(res?.data?.data);
      }
    } catch (err) {
      console.log("error from from setgetAdvertiseViseData => ", err);
    }
  };

  return (
    <div>
      {" "}
      <Grid container className="width100 mt20px" gap="20px">
        <Grid container justifyContent={"space-between"}>
          <Typography className="gray5A5A5Atypo500 fs20px">
            Showing top advertisers
          </Typography>
          <Grid item>
            <Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Calendar getDataFromChildHandler={getDataFromChildHandler} />
              </LocalizationProvider>
              <Button
                sx={{ padding: "5px 20px" }}
                className="purple_btn_primary text_transform_inherit"
                onClick={() => {
                  exportToExcel(getAdvertiseViseData, fileName);
                }}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <EnhancedTableToolbar />
        <TableContainer className="width100 hide_sortBar table_border_radius">
          <Table
            sx={{
              minWidth: 800,
              backgroundColor: "#fff",
              width: "100%",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              className="tablerow"
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />

            <TableBody className="tablerow ">
              {getAdvertiseViseData && getAdvertiseViseData.length > 0 ? (
                getAdvertiseViseData?.map((a, i) => (
                  <TableRow
                    key={i}
                    hover
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {i + 1}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="Left"
                      component="th"
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {a?.firstName
                          ? a?.firstName + " " + a?.lastName
                          : a?.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {a?.orderCount ? a?.orderCount : 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {a?.email ? a?.email : "---"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {a?.phone ? a?.phone : "---"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {a?.femaleViews ? a?.femaleViews : 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {a?.maleViews ? a?.maleViews : 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {a?.totalViews ? a?.totalViews : 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        &#x20b9; {a?.revenue ? a?.revenue : 0}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <>
                  <TableRow>
                    <TableCell colSpan={9}>
                      <div className="no-data-container">
                        <img
                          src={nodata}
                          alt="no data found"
                          className="noBillboardFoundGif_width"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
};

export default AdvertiseViseTable;
