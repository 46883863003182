import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Typography,
  DialogContent,
  Checkbox,
  FormControlLabel,
  InputBase,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
//React Icons
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../../../../../api/axiosInstance";
import Paper from "@mui/material/Paper";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",
  // [theme.breakpoints.up("lg")]: {
  //   marginLeft: theme.spacing(1),
  //   width: "auto",
  // },
  width: "36%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "93%",
      },
    },
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="dialogtitle" {...other}>
      {children}

      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogcrossicon"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ handleDeviceId }) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  // States
  const [originalData, setOriginalData] = React.useState([]);
  const [testData, setTestData] = React.useState([]);
  const [deviceData, setDeviceData] = React.useState([]);

  function storeDeviceId(e, x, i) {
    // console.log("====> ", x);
    let storeArr = [...originalData];
    let testArr = [...testData];
    let index = storeArr.findIndex((item) => item === x._id);
    //   console.log("== Check index ==", index);
    if (index === -1) {
      testArr.push(x.deviceUID);
      storeArr.push(x._id);
    } else {
      storeArr.splice(index, 1);
      testArr.splice(index, 1);
    }
    setOriginalData(storeArr);
    setTestData(testArr);
    handleDeviceId(storeArr);
  }

  React.useEffect(() => {
    // console.log("original Data ==> ", originalData)
    const getDevices = async () => {
      try {
        let resp = await axios.get("/api/device/getallDevice");
        // console.log("RESPONSE ===> ", resp.data.data);
        setDeviceData(resp.data.data);
      } catch (error) {
        // console.log("ERROR from GET DEVICE => ", error.message);
      }
    };
    getDevices();
  }, []);

  // React.useEffect(()=> {
  //   console.log(" Original DATA from UseEffect====> ", originalData);
  // },[originalData])
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const handleClick = () => {
    setSnackOpen(true);
  };

  // console.log("SELected Id of Device", selectedDevice);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Typography onClick={handleClickOpen} className="selectableInput">
        {testData.length > 0 ? testData.join(" , ") : "Select Devices"}
      </Typography>

      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className="whitecolortypo500 fs16px">
            Business Details
          </Typography>{" "}
        </BootstrapDialogTitle>
        <DialogContent sx={{ m: 0 }}>
          <div className="admin-content">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Search className="search-admin">
                <SearchIconWrapper>
                  <SearchIcon className="searchicon" />
                </SearchIconWrapper>
                <StyledInputBase
                  className=""
                  placeholder="Search Device"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>{" "}
            </Grid>
            {/* <Grid container gap="10px">
              {deviceData?.map((data, index) => {
                // ======================================================== //
                return (
                  <Grid
                    container
                    item
                    sm={5.5}
                    md={2.7}
                    className="access-radio-grid "
                    sx={{ border: "1px solid #ddd", padding: "2px 10px" }}
                  >
                    {originalData.includes(data._id) ? (
                      <FormControlLabel
                        control={<Checkbox checked={true} />}
                        onChange={(e) => storeDeviceId(e, data, index)}
                        value={data._id}
                        className="radiostyle access-radio-formcontrolabel"
                        style={{ justifyContent: "space-between" }}
                        label={
                          <Typography className="width100">
                            {data.deviceName}{" "}
                            <Typography> {data.deviceUID}</Typography>
                          </Typography>
                        }
                      />
                    ) : (
                      <FormControlLabel
                        control={<Checkbox />}
                        onChange={(e) => storeDeviceId(e, data, index)}
                        value={data._id}
                        className="radiostyle access-radio-formcontrolabel"
                        style={{ justifyContent: "space-between" }}
                        label={
                          <Typography className="width100">
                            {data.deviceName}{" "}
                            <Typography> {data.deviceUID}</Typography>
                          </Typography>
                        }
                      />
                    )}
                  </Grid>
                );
                // ========================================================== //
              })}
            </Grid> */}
            <Grid
              container
              spacing={2}
              sx={{ width: "100%" }}
              className="paper_reset"
            >
              {deviceData?.map((data, index) => (
                <Grid container item md={3} sm={4} xs={6}>
                  <Grid
                    container
                    item
                    className="access-radio-grid "
                    sx={{
                      border: "1px solid #ddd",
                      padding: "2px 10px",
                      width: "100%",
                    }}
                  >
                    {" "}
                    {originalData.includes(data._id) ? (
                      <FormControlLabel
                        control={<Checkbox checked={true} />}
                        onChange={(e) => storeDeviceId(e, data, index)}
                        value={data._id}
                        className="radiostyle access-radio-formcontrolabel"
                        style={{ justifyContent: "space-between" }}
                        label={
                          <Typography className="width100">
                            {data.deviceName}{" "}
                            <Typography> {data.deviceUID}</Typography>
                          </Typography>
                        }
                      />
                    ) : (
                      <FormControlLabel
                        control={<Checkbox />}
                        onChange={(e) => storeDeviceId(e, data, index)}
                        value={data._id}
                        className="radiostyle access-radio-formcontrolabel"
                        style={{ justifyContent: "space-between" }}
                        label={
                          <Typography className="width100">
                            {data.deviceName}{" "}
                            <Typography> {data.deviceUID}</Typography>
                          </Typography>
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="dcancelbutton" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="purplebtn" onClick={handleClose}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
