import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Grid,
  Card,
  Button,
  Typography,
  IconButton,
  CardMedia,
  Dialog,
  DialogTitle,
  CardActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import { BiRupee } from "react-icons/bi";
import Map from "../filter/Map/GoogleMap/Map";
import Map2 from "../../../HomeMap/GoogleMap/Map";
import FilterScreenDialog from "./ScreenFilterDialog";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(7),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const ShowBillMap = (props) => {
  const {
    states: {
      selectedFilter,
      setSelectedFilter,
      smartBillboard,
      setSmartBillboard,
    },
    filter,
    getallBillBoardsScreens,
    getFilteredBillboards,
  } = props;
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("xl");
  const [open2, setOpen2] = useState(false);
  const [state, setState] = useState();
  const [mapLocation, setMapLocation] = useState({
    lattitude: 28.7041,
    longitude: 77.1025,
  });

  console.log("lagLon", mapLocation);
  const [selectBillboard, setSelectBillboard] = useState("");
  const handleClickMap = () => {
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen2(false);
  };

  // addWishList

  return (
    <>
      {/* <Button className="show-btn">show 699 billboard</Button> */}
      <Grid
        container
        item
        sm={12}
        md={12}
        className="float_btn_container"
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button onClick={handleClickMap} className="float_btn">
          Show Map
        </Button>
      </Grid>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open2}
        className="pop-width"
      >
        <Grid container sx={{ margin: "20px 0" }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: "0px 35px 0px 30px" }}
          >
            {/* <Grid item>
              <FilterScreenDialog
                states={{ selectedFilter, setSelectedFilter }}
                filter={filter}
                getallBillBoardsScreens={getallBillBoardsScreens}
                getFilteredBillboards={getFilteredBillboards}
              />
            </Grid> */}
            <Grid item>
              <Typography
                align="center"
                className=" width100 fiter-heading-typo  family-color fs18px   "
              >
                Showing {smartBillboard?.length} Smart Billboards
              </Typography>{" "}
            </Grid>
            <CloseIcon onClick={handleClose} className="cursor_pointer" />
          </Grid>
          <Grid container sx={{ height: "80vh" }} className="mt20px">
            <Grid
              xs={12}
              md={4}
              lg={3}
              item
              className=" Billboard-card-maps  mostly-customized-scrollbar"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowY: "scroll",

                height: "100%",
                rowGap: "30px",
              }}
            >
              {smartBillboard?.length > 0 ? (
                smartBillboard?.map((item, index) => {
                  return (
                    <>
                      <Grid item key={index} className="cursor_pointer ">
                        {" "}
                        <Card
                          style={{
                            boxShadow:
                              selectBillboard === item._id
                                ? "none"
                                : "0px 1px 10px rgb(0 0 0 / 10%)",
                            border:
                              selectBillboard === item._id
                                ? "3px solid #b937fa "
                                : "none",
                          }}
                          sx={{
                            maxWidth: 270,
                            minWidth: 270,
                          }}
                          key={index}
                          onClick={() => {
                            setMapLocation({
                              lattitude: item?.lattitude,
                              longitude: item?.longitude,
                            });
                            setSelectBillboard(item?._id);
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="140"
                            image={item?.thumbnail}
                            alt="green iguana"
                          />
                          <CardActions className="campaign-billboardaction">
                            <Grid item className="width100">
                              <Typography className=" family-color  fs20px ">
                                {item?.billboardName}
                              </Typography>
                              <Typography className="greycolor505050500  fs14px ">
                                {item?.billboardId}
                              </Typography>
                              <Typography className="greycolor505050500 fs14px  ">
                                {item?.city}
                              </Typography>
                              <Grid
                                container
                                justifyContent="space-between"
                                direction="row"
                                className="width100"
                              >
                                <Grid item>
                                  <Typography className="greycolor505050500 mt5px fs20px ">
                                    <span>&#x20B9;</span>
                                    {item?.basePrice}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Link
                                    to={`/admin/billboardscreens/${item?._id}`}
                                    className="linkcolor"
                                    state={{ item }}
                                  >
                                    <Button
                                      sx={{ height: "28px" }}
                                      className="purplebtn mt10px"
                                    >
                                      View
                                    </Button>
                                  </Link>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Grid>
                    </>
                  );
                })
              ) : (
                <Grid
                  container
                  item
                  sx={{
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "4rem 0",
                      fontWeight: "bold",
                    }}
                  >
                    No Data Found
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container xs={12} md={8} lg={9} item sx={{ height: "100%" }}>
              {Object?.keys(mapLocation)?.length > 0 && (
                <Map2
                  data={mapLocation}
                  height="100%"
                  map__container2={"map__container2"}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </>
  );
};

export default ShowBillMap;
