import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Divider,
  Button,
  DialogActions,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import CouponDialogue from "./CouponDialogue";
import ViewPriceUpDialogue from "./ActionDialogue/ViewPriceUpDialogue";
import AddWallet from "../../../../utils/Dialog/AddWalletDialog";
import { useCampaignController } from "../../../../Context/campaignBook";
import axiosInstance from "../../../../api/axiosInstance";
import { AuthContext } from "../../../../Context/AuthContext";

import moment from "moment";
import { useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const PaymentTab = ({
  selectDevice,
  repeat,
  TabPre5,
  loading,
  startdate,
  enddate,
  billBoardArr,
  handleUploadimage,
  selectSecond,
  starttime,
}) => {
  let userRole = JSON.parse(localStorage.getItem("userData")).user.userRole;
  const { user } = React.useContext(AuthContext);

  const [currentTime, setCurrentTime] = useState(new Date().getHours());
  /// total billboardMoney/////
  let billboardMoney = 0;
  for (let data of selectDevice) {
    billboardMoney = billboardMoney + data?.basePrice * selectSecond * repeat;
  }
  /// total billboardMoney/////
  // ===================check wallet data =====================//
  let userID = JSON.parse(localStorage.getItem("userData")).user._id;
  const mobileView = useMediaQuery("(max-width:600px)");

  const [billboardsPriceData, setBillboardsPriceData] = useState(0);
  const [multiTimeSolts, setMultiTimeSolts] = useState();
  const [wallletDetails, setWalletDetails] = useState();
  const [transactionData, setTransactionData] = useState();
  const [disAmmount, setDisAmmount] = useState(0);
  const [goToAddWallet, setGoToAddWallet] = useState(false);
  const [couponCode, setcouponCode] = useState("");
  const [Valid, setValid] = useState("invalid");
  const [Insufficient, setInSufficient] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [campaignController, dispatch] = useCampaignController();
  const {
    campaignName,
    aboutCampaign,
    actualDate,
    dateRange,
    adDuration,
    timeSlotKey,
    timeSlots,
    applyToAllDays,
    billboards,
    file,
    totalAmount,
    filetype,
  } = campaignController;

  const amount = Math.round((billboardsPriceData * 18) / 100);

  const handleClose = () => {
    setGoToAddWallet(false);
  };

  const couponCodeHandler = (e) => {
    console.log(e, "event");
    setcouponCode(e);
  };

  const CheckWalletandAddMoney = async () => {
    try {
      const res = await axiosInstance.post(`api/payment/getWalletData`, {
        userId: userID,
      });
      console.log("gettwallet data responce", res);
      if (res.status == 200 || res.status == 201) {
        setWalletDetails(res?.data?.msg);
      }
    } catch (err) {
      console.log("error in get wallet data", err);
    }
  };

  const CheckVerification = async () => {
    // if (emailStatus == true && mobileStatus == true) {
    // WithdrawMoneyfromWallet(amount - disAmmount);
    handleUploadimage(amount - disAmmount, Valid, couponCode);
    // } else {
    // enqueueSnackbar(
    //   "please complete your verification then your booking process ON.",
    //   {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   }
    // );
    // console.log(" you r no #verify ");
    // }
  };

  const WithdrawMoneyfromWallet = async (amount) => {
    try {
      const res = await axiosInstance.post(`api/payment/makeTransaction`, {
        walletId: wallletDetails?._id,
        userId: localStorage.getItem("saps_id"),
        debitedAmount: amount,
        transactionStatus: "successful",
        message: "Advertisement Payment",
        status: "added to wallet",
      });
      if (Valid == "true") {
        const res1 = await axiosInstance.post(
          `api/coupon/ChnageCouponStatusToUser `,
          {
            userId: localStorage.getItem("saps_id"),
            couponCode: couponCode,
          }
        );
      }
      console.log("make Transaction data responce", res);
      if (res.status == 200 || res.status == 201) {
        console.log("i am inside");
        setTransactionData(res.data.msg);
        handleUploadimage(
          res?.data?.msg?.debitedAmount,
          res?.data?.msg?._id,
          wallletDetails?._id
        );
      }
    } catch (err) {
      console.log("error in make Transaction data responce", err);
    }
  };

  const getAllBillboardPrice = async () => {
    // console.log(billBoardArr, "billBoardArr");
    // console.log(repeat, "repeat");
    // console.log(startdate, "startdate");
    // console.log(enddate, "enddate");

    if ((adDuration, multiTimeSolts, billBoardArr)) {
      try {
        let body = {
          billboardIds: billBoardArr,
          multiTimes: multiTimeSolts,
          videoDuration: adDuration,
        };

        const res = await axiosInstance.post(
          `api/billboard/getPriceBreakDown`,
          body
        );
        if (res.status == 200) {
          let sum = 0;

          res?.data?.data?.map((item, index) => {
            sum += item?.amount || 0; // Use item?.amount or 0 to handle potential null or undefined values
            console.log(sum);
          });
          setBillboardsPriceData(sum);
          console.log(res, "i am billboard price  response");
        } else {
          console.log(res, "i am billboard price error response");
        }
      } catch (error) {
        console.log(error);
      }
    }
    return;
  };
  // diffrence between dates
  // diffrence between dates
  const date1 = new Date(startdate);
  const date2 = new Date(enddate);

  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  const diffInDays = Math.round(
    Math.abs((date2.getTime() - date1.getTime()) / oneDay)
  );

  useEffect(() => {
    let newValue = [];
    Object.keys(timeSlots)?.map((item) => {
      timeSlots[item]?.map((itemObj) => {
        newValue.push({ ...itemObj, date: item });
      });
    });
    console.log(newValue, "uuuuuuuuuuuuuuuuuuiiooo");

    let multi = [];

    newValue.forEach((item, index) => {
      multi.push({
        date: item?.date,
        startHour: item?.time,
        repetitions: item?.repetition,
        // actualTime: item?.actualTime,
      });
    });
    console.log("sum sum", multi);

    setMultiTimeSolts(multi);
  }, [timeSlots]);
  // diffrence between dates
  // diffrence between dates

  useEffect(() => {
    // getAllBillboardPrice();
    CheckWalletandAddMoney();
    // console.log(" statusof email", emailStatus, mobileStatus);
  }, []);

  useEffect(() => {
    getAllBillboardPrice();
    CheckWalletandAddMoney();
  }, [goToAddWallet, disAmmount, multiTimeSolts, billBoardArr, adDuration]);

  useEffect(() => {
    if (wallletDetails && wallletDetails?.walletBalance < amount) {
      setInSufficient(true);
    }
  }, [amount, wallletDetails, disAmmount]);

  useEffect(() => {
    console.log(
      // campaignName,
      // aboutCampaign,
      actualDate,
      // dateRange,
      // adDuration,
      "key,,,",
      timeSlotKey,

      timeSlots,
      // applyToAllDays,
      billboards,
      file,
      totalAmount,
      filetype,
      "billboardsPriceDatabillboardsPriceDatabillboardsPriceDatabillboardsPriceData",
      billboardsPriceData
    );
  }, [
    campaignName,
    aboutCampaign,
    actualDate,
    dateRange,
    adDuration,
    timeSlotKey,
    timeSlots,
    applyToAllDays,
    billboards,
    file,
    totalAmount,
    filetype,
    billboardsPriceData,
  ]);

  return (
    <>
      {userRole === 0 ? (
        <>
          <Grid
            container
            className={
              mobileView
                ? "Border b-r-8px mt10px "
                : "Border b-r-8px mt10px p40px "
            }
            justifyContent="space-between"
            sx={{ padding: "40px" }}
            rowGap="20px"
          >
            <Grid
              container
              item
              md={5.8}
              xs={12}
              className={mobileView ? " " : "border_primary "}
              sx={{ padding: "10px", maxHeight: "50vh", overflow: "scroll" }}
            >
              {/* <Grid container item style={{ borderTop: '1px solid #ddd' }}></Grid> */}
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                style={{ marginTop: "10px" }}
              >
                {/* <Typography className="blackcolortypo500 fs18px font-Lato fontWeight600 ">
                Order Summary
              </Typography> */}
              </Grid>
              <Grid
                container
                item
                xs={12}
                // justifyContent="space-around"
                style={{ marginTop: "16px" }}
              >
                <Typography className="blackcolortypo500 fs16px font-Lato">
                  Duration :
                </Typography>
                <Typography className="purplecolortypo fs16px font-Lato pl7px fontWeight600 ">
                  {adDuration}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                // justifyContent="space-between"
                style={{ marginTop: "16px" }}
              >
                <Typography className="blackcolortypo500 fs16px font-Lato purplecolortypo">
                  Date Range :
                </Typography>
                <Typography className="purplecolortypo fs16px font-Lato pl7px fontWeight600 ">
                  {dateRange[0]} / {dateRange[dateRange.length - 1]}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                // justifyContent="space-between"
                style={{ marginTop: "16px" }}
              >
                <Typography className="blackcolortypo500 fs16px font-Lato">
                  Billboards :
                </Typography>
                <Typography className="purplecolortypo fs16px font-Lato pl7px fontWeight600 ">
                  {selectDevice?.length}
                </Typography>
                {/* <Typography className="blackcolortypo500 fs16px font-Lato">
                  ₹ {billboardsPriceData ? billboardsPriceData : 0}
                </Typography> */}
                {/* <Grid container>
                <ViewPriceUpDialogue
                  billBoardArr={billBoardArr}
                  repeat={repeat}
                  startdate={startdate}
                  enddate={enddate}
                  starttime={starttime}
                  selectSecond={selectSecond}
                />
              </Grid> */}
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                style={{ marginTop: "10px" }}
              >
                <Typography className="blackcolortypo500 fs18px font-Lato fontWeight600 ">
                  Timeslot and Repetition details
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                gap={3}
                style={{ marginTop: "10px" }}
              >
                {Object.entries(timeSlots)?.map((obj, objIndex) => (
                  <Grid item sm={5} key={objIndex}>
                    <Accordion className="Border b-r-8px " key={objIndex}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="fs14px  fontWeight600 purplecolortypo">
                          {" "}
                          Date: {obj[0]}{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className="fs14px blackcolortypo500 fontWeight600">
                          Timeslot & Repetition :{" "}
                        </Typography>
                        <ul className="pl20px">
                          {obj[1]?.map((slots, i) => (
                            <li key={i}>
                              {slots?.actualTime} * {slots?.repetition}
                            </li>
                          ))}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid
              container
              item
              md={5.8}
              xs={12}
              className={mobileView ? " " : "border_primary "}
              sx={{ padding: "10px" }}
            >
              {user?.role == "ClusterHead" && (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    // sx={{ padding: "5px 0" }}
                  >
                    <CouponDialogue
                      total={billboardMoney + (billboardMoney * 18) / 100}
                      discountAmmount={(e) => setDisAmmount(e)}
                      couponCode={(e) => setcouponCode(e)}
                      couponCodeHandler={couponCodeHandler}
                      Valid={(e) => setValid(e)}
                    />
                  </Grid>{" "}
                  <Grid
                    container
                    item
                    style={{ borderTop: "1px solid #ddd" }}
                  ></Grid>{" "}
                </>
              )}

              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                style={{ marginTop: "10px" }}
              ></Grid>

              <Grid container item xs={12} justifyContent="space-between">
                <Typography className="blackcolortypo500 fs16px font-Lato">
                  Sub Total
                </Typography>
                <Typography className="purplecolortypo fs16px font-Lato">
                  {/* ₹ {billboardsPriceData * selectSecond * repeat}/- */}₹{" "}
                  {billboardsPriceData}/-
                </Typography>
              </Grid>
              <Grid container>
                <ViewPriceUpDialogue
                  billBoardArr={billBoardArr}
                  repeat={repeat}
                  startdate={dateRange[0]}
                  enddate={dateRange[dateRange.length - 1]}
                  starttime={starttime}
                  selectSecond={selectSecond}
                  timeSlots={timeSlots}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                style={{ marginTop: "16px" }}
              >
                <Typography className="blackcolortypo500 fs16px font-Lato">
                  Tax (18%)
                </Typography>
                <Typography className="blackcolortypo500 fs16px font-Lato">
                  {/* + ₹ {(billboardsPriceData * selectSecond * repeat * 18) / 100} */}
                  + ₹ {(billboardsPriceData * 18) / 100}
                  /-
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                style={{ marginTop: "16px" }}
              >
                <Typography className="blackcolortypo500 fs16px font-Lato">
                  Coupon Discount{" "}
                  {/* <span className="purplecolortypo">(Admin Discount)</span> */}
                </Typography>
                <Typography className="blackcolortypo500 fs16px font-Lato">
                  {/* - ₹{disAmmount && disAmmount} */}- ₹
                  {billboardsPriceData + (billboardsPriceData * 18) / 100}
                  /-
                </Typography>
              </Grid>
              <Grid
                container
                item
                style={{ borderTop: "1px solid #ddd", margin: "16px 0" }}
              ></Grid>
              <Grid container item xs={12} justifyContent="space-between">
                <Typography className="blackcolortypo500 fs20px">
                  Total
                </Typography>
                <Typography className="blackcolortypo500 fs16px font-Lato ">
                  ₹ 0
                </Typography>
              </Grid>

              <Grid
                container
                item
                // md={5.8}
                xs={12}
                sx={{ padding: "10px" }}
                alignItems="flex-end"
              >
                <Grid container justifyContent="space-between" rowGap="20px">
                  <Typography className="blackcolortypo500 fs16px">
                    Payment Method
                  </Typography>
                  <Typography className="blackcolortypo500 fs16px">
                    Wallet ( BAL :{" "}
                    <span className="">
                      {" "}
                      ₹ {parseInt(wallletDetails?.walletBalance).toFixed(2)}
                    </span>
                    )
                  </Typography>
                  <Grid container item xs={12} justifyContent="center">
                    {wallletDetails &&
                    wallletDetails?.walletBalance < amount ? (
                      <Button
                        sx={{
                          width: "100%",
                          borderRadius: "8px !important",
                        }}
                        className="order-purple-btn   fontWeight600"
                        // disabled={true}
                        onClick={() => setGoToAddWallet(true)}
                        // disabled={(mediafile || imagestring) && filetype ? false : true}
                      >
                        Insufficient Balance ( Need &#x20B9; {"  "}
                        {amount - parseInt(wallletDetails?.walletBalance)} )
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          width: "100%",
                          borderRadius: "8px !important",
                        }}
                        className="order-purple-btn  fontWeight600"
                        onClick={() => {
                          // videoLink
                          // ?
                          CheckVerification();
                        }}
                      >
                        Proceed to Payment
                      </Button>
                    )}
                    {Insufficient && (
                      <Typography className="fs14px TextTransform black-price mb5px">
                        Please Contact Super Admin
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid
          container
          className={
            mobileView
              ? "Border b-r-8px mt10px "
              : "Border b-r-8px mt10px p40px "
          }
          justifyContent="space-between"
          // sx={{ padding: "40px" }}
          rowGap="20px"
        >
          <Grid
            container
            item
            md={5.8}
            xs={12}
            className={mobileView ? " " : "border_primary "}
            sx={{ padding: "10px", maxHeight: "50vh", overflow: "scroll" }}
          >
            {/* <Grid container item style={{ borderTop: '1px solid #ddd' }}></Grid> */}
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginTop: "10px" }}
            >
              {/* <Typography className="blackcolortypo500 fs18px font-Lato fontWeight600 ">
                Order Summary
              </Typography> */}
            </Grid>
            <Grid
              container
              item
              xs={12}
              // justifyContent="space-around"
              style={{ marginTop: "16px" }}
            >
              <Typography className="blackcolortypo500 fs16px font-Lato">
                Duration :
              </Typography>
              <Typography className="purplecolortypo fs16px font-Lato pl7px fontWeight600 ">
                {adDuration}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              // justifyContent="space-between"
              style={{ marginTop: "16px" }}
            >
              <Typography className="blackcolortypo500 fs16px font-Lato purplecolortypo">
                Date Range :
              </Typography>
              <Typography className="purplecolortypo fs16px font-Lato pl7px fontWeight600 ">
                {dateRange[0]} / {dateRange[dateRange.length - 1]}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              // justifyContent="space-between"
              style={{ marginTop: "16px" }}
            >
              <Typography className="blackcolortypo500 fs16px font-Lato">
                Billboards :
              </Typography>
              <Typography className="purplecolortypo fs16px font-Lato pl7px fontWeight600 ">
                {selectDevice?.length}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginTop: "10px" }}
            >
              <Typography className="blackcolortypo500 fs18px font-Lato fontWeight600 ">
                Timeslot and Repetition details
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              gap={3}
              style={{ marginTop: "10px" }}
            >
              {Object.entries(timeSlots)?.map((obj, objIndex) => (
                <Grid item sm={5} key={objIndex}>
                  <Accordion className="Border b-r-8px " key={objIndex}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="fs14px  fontWeight600 purplecolortypo">
                        {" "}
                        Date: {obj[0]}{" "}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="fs14px blackcolortypo500 fontWeight600">
                        Timeslot & Repetition :{" "}
                      </Typography>
                      <ul className="pl20px">
                        {obj[1]?.map((slots, i) => (
                          <li key={i}>
                            {slots?.actualTime} * {slots?.repetition}
                          </li>
                        ))}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid
            container
            item
            md={5.8}
            xs={12}
            className={mobileView ? " " : "border_primary "}
            sx={{ padding: "10px" }}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              // sx={{ padding: "5px 0" }}
            >
              <CouponDialogue
                total={billboardMoney + (billboardMoney * 18) / 100}
                discountAmmount={(e) => setDisAmmount(e)}
                couponCode={(e) => setcouponCode(e)}
                couponCodeHandler={couponCodeHandler}
                Valid={(e) => setValid(e)}
              />
            </Grid>

            <Grid container item style={{ borderTop: "1px solid #ddd" }}></Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginTop: "10px" }}
            ></Grid>

            <Grid container item xs={12} justifyContent="space-between">
              <Typography className="blackcolortypo500 fs16px font-Lato">
                Sub Total
              </Typography>
              <Typography className="purplecolortypo fs16px font-Lato">
                {/* ₹ {billboardsPriceData * selectSecond * repeat}/- */}₹{" "}
                {billboardsPriceData}/-
              </Typography>
            </Grid>
            <Grid container>
              <ViewPriceUpDialogue
                billBoardArr={billBoardArr}
                repeat={repeat}
                startdate={dateRange[0]}
                enddate={dateRange[dateRange.length - 1]}
                starttime={starttime}
                selectSecond={selectSecond}
                timeSlots={timeSlots}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginTop: "16px" }}
            >
              <Typography className="blackcolortypo500 fs16px font-Lato">
                Tax (18%)
              </Typography>
              <Typography className="blackcolortypo500 fs16px font-Lato">
                {/* + ₹ {(billboardsPriceData * selectSecond * repeat * 18) / 100} */}
                + ₹ {(billboardsPriceData * 18) / 100}
                /-
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginTop: "16px" }}
            >
              <Typography className="blackcolortypo500 fs16px font-Lato">
                Coupon Discount{" "}
                {/* <span className="purplecolortypo">(Admin Discount)</span> */}
              </Typography>
              <Typography className="blackcolortypo500 fs16px font-Lato">
                - ₹{Math.round(disAmmount && disAmmount)}
                /-
              </Typography>
            </Grid>
            <Grid
              container
              item
              style={{ borderTop: "1px solid #ddd", margin: "16px 0" }}
            ></Grid>
            <Grid container item xs={12} justifyContent="space-between">
              <Typography className="blackcolortypo500 fs20px">
                Total
              </Typography>
              <Typography className="blackcolortypo500 fs16px font-Lato ">
                ₹ {Math.round(amount - disAmmount)}
              </Typography>
            </Grid>

            <Grid
              container
              item
              // md={5.8}
              xs={12}
              sx={{ padding: "10px" }}
              alignItems="flex-end"
            >
              <Grid container justifyContent="space-between" rowGap="20px">
                <Typography className="blackcolortypo500 fs16px">
                  Payment Method
                </Typography>
                <Typography className="blackcolortypo500 fs16px">
                  Wallet ( BAL :{" "}
                  <span className=""> ₹ {wallletDetails?.walletBalance}</span>)
                </Typography>
                <Grid container item xs={12} justifyContent="center">
                  {wallletDetails && wallletDetails?.walletBalance < amount ? (
                    <Button
                      sx={{
                        width: "100%",
                        borderRadius: "8px !important",
                      }}
                      className="order-purple-btn   fontWeight600"
                      // disabled={true}
                      onClick={() => setGoToAddWallet(true)}
                      // disabled={(mediafile || imagestring) && filetype ? false : true}
                    >
                      Insufficient Balance ( Need &#x20B9; {"  "}
                      {amount - parseInt(wallletDetails.walletBalance)} )
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        width: "100%",
                        borderRadius: "8px !important",
                      }}
                      className="order-purple-btn  fontWeight600"
                      onClick={() => {
                        // videoLink
                        // ?
                        CheckVerification();
                      }}
                    >
                      Proceed to Payment
                    </Button>
                  )}
                  {Insufficient && (
                    <Typography className="fs14px TextTransform black-price mb5px">
                      Please Contact Super Admin
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* <DialogActions className="dialog-action mt20px mb1-5rem ">
        <Button className="tab-back-btn" onClick={TabPre5}>
          Back
        </Button>
        {/* ======================================================== */}
      {/* <Button
          className="tab-next-btn"
          onClick={() => WithdrawMoneyfromWallet()}
        >
          Submit
        </Button> 
      </DialogActions> */}

      <Dialog
        open={loading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className=" loading-Zip-file"
      >
        <div className="loader1">
          <div className="loader-inner">
            <div className="loader-line-wrap">
              <div className="loader-line"></div>
            </div>
            <div className="loader-line-wrap">
              <div className="loader-line"></div>
            </div>
            <div className="loader-line-wrap">
              <div className="loader-line"></div>
            </div>
            <div className="loader-line-wrap">
              <div className="loader-line"></div>
            </div>
            <div className="loader-line-wrap">
              <div className="loader-line"></div>
            </div>
          </div>
        </div>
      </Dialog>

      {goToAddWallet && (
        <AddWallet
          handleClose={handleClose}
          walletDetail={wallletDetails && wallletDetails}
          amount={Math.round(amount - parseInt(wallletDetails?.walletBalance))}
        />
      )}
    </>
  );
};

export default PaymentTab;
