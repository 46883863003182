import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "red" }} {...other}>
      {children}
      <Typography className="whitecolortypo">Delete </Typography>{" "}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DeleteDilogue({
  valueForDel,
  deleteAllBillbaord,
  deleteAllUsers,
  deleteAllDevice,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const openSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {valueForDel === 1 && (
        <Tooltip title="Delete" arrow>
          <Button
            sx={{ padding: "5px 10px" }}
            className="purple_btn_primary text_transform_inherit"
            onClick={handleClickOpen}
          >
            Delete all Deleted Users
          </Button>
        </Tooltip>
      )}
      {valueForDel === 0 && (
        <Tooltip title="Delete" arrow>
          <Button
            sx={{ padding: "5px 10px" }}
            className="purple_btn_primary text_transform_inherit"
            onClick={handleClickOpen}
          >
            Delete all Deleted Billboards
          </Button>
        </Tooltip>
      )}
      {valueForDel === 2 && (
        <Tooltip title="Delete" arrow>
          <Button
            sx={{ padding: "5px 10px" }}
            className="purple_btn_primary text_transform_inherit"
            onClick={handleClickOpen}
          >
            Delete all Deleted Device
          </Button>
        </Tooltip>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle> </BootstrapDialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "20px",
          }}
        >
          <Typography className="greycolor505050500">
            {" "}
            {valueForDel === 1 && (
              <> {` Are you sure you want to permanently delete all Users ?`}</>
            )}
            {valueForDel === 0 && (
              <>
                {" "}
                {` Are you sure you want to permanently delete all Billboards ?`}
              </>
            )}
            {valueForDel === 2 && (
              <>
                {" "}
                {` Are you sure you want to permanently delete all Device ?`}
              </>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="  diallog-no-btn">
            No
          </Button>

          {valueForDel === 0 && (
            <Button
              onClick={() => {
                handleClose();
                deleteAllBillbaord(openSnackbar);
              }}
              className="reject-btn width-25 "
              style={{ padding: "4px 0px" }}
            >
              Yes
            </Button>
          )}
          {valueForDel === 1 && (
            <Button
              onClick={() => {
                handleClose();
                deleteAllUsers();
              }}
              className="reject-btn width-25 "
              style={{ padding: "4px 0px" }}
            >
              Yes
            </Button>
          )}
          {valueForDel === 2 && (
            <Button
              onClick={() => {
                handleClose();
                deleteAllDevice();
              }}
              className="reject-btn width-25 "
              style={{ padding: "4px 0px" }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
