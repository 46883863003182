import React, { useState, useEffect, Link } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Select,
  FormControl,
  MenuItem,
  Box,
  Breadcrumbs,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "../../../../../../api/axiosInstance";

import useMediaQuery from "@mui/material/useMediaQuery";
import SideBar from "../../../../../../component/Sidebar/Sidebar";
import { AuthContext } from "../../../../../../Context/AuthContext";

import "./TimeSlot.css";

export default function Index(props) {
  const mobileView = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { user } = React.useContext(AuthContext);
  const { state } = useLocation();
  const location = useLocation();
  const PrefieldData = location.state;
  const { billBoardData, billboardId, duration, date, adTitle, aboutAd, type } =
    location.state;
  console.log("data from state>>>>>>>>>>>>>", date);
  const [currentTime, setCurrentTime] = useState(new Date().getHours());
  const [timeSlots, setTimeSlots] = useState([]);

  const [selectedtime, setSelectedTime] = useState(null);
  const [selectTimeDuration, setSelectedTimeDuration] = useState(null);
  const [checked, setChecked] = useState();
  const [pricef, setPriceF] = useState();

  const [selectedSlot, setSelectedSlot] = useState([]);
  const [selectedSlotKey, setSelectedSlotKey] = useState([]);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const handleSelectSlot = (data) => {
    let temp = selectedSlotKey;
    let temp2 = selectedSlot;
    if (selectedSlotKey.includes(data?.time)) {
      temp = temp.filter((key) => key != data?.time);
      setSelectedSlotKey(JSON.parse(JSON.stringify(temp)));
      temp2 = temp2.filter((item) => item?.time != data?.time);
      setSelectedSlot(JSON.parse(JSON.stringify(temp2)));
    } else {
      temp.push(data?.time);
      setSelectedSlotKey(JSON.parse(JSON.stringify(temp)));
      data = { ...data, repetition: 1 };

      temp2.push(data);
      setSelectedSlot(JSON.parse(JSON.stringify(temp2)));
    }
    console.log(temp, temp2);
  };

  const handleTags = (value) => {
    // console.log("handle Tags of Add-Bill-Board.js ===>", value)
    // setFormdata({ ...formdata, venueTags: value });
  };
  const handleClickitem = (name, i) => {
    let temparr = [...checked];
    let index = temparr.findIndex((item) => item === name);
    if (index === -1) {
      temparr.push(name);
    } else {
      temparr.splice(index, 1);
    }
    setChecked(temparr);
    handleTags(temparr);
    // console.log("timeslot", timeSlots);
  };
  const queryParams = {
    date: date,
    billboardId: billboardId,
    videoDuration: duration,
  };
  const getTimeSlot = async () => {
    try {
      console.log("getTimeSlot called ");

      const response = await axiosInstance.post(
        `api/billBoardBooking/bookingSlot`,
        {
          date: date,
          billboardId: billboardId,
          videoDuration: duration,
        }
      );
      console.log("getTime Slot>>", response);
      if (response?.status == 200 || response?.status == 201) {
        let dataTimeSlots = response?.data?.data[0]?.timeslots;
        console.log("dataTimeSlot Slot>>", dataTimeSlots);
        dataTimeSlots?.map((data) => {
          if (data?.time < 4) {
            if (data.time === 0) data["actualTime"] = "12am - 1am";
            else data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
          } else if (data?.time > 3 && data?.time < 8) {
            data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
          } else if (data.time > 7 && data.time < 12) {
            if (data.time === 11) data["actualTime"] = "11am - 12pm";
            else data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
          } else if (data.time > 11 && data.time < 16) {
            if (data.time === 12)
              data["actualTime"] = `12pm - ${data.time - 12 + 1}pm`;
            else
              data["actualTime"] = `${data.time - 12}pm - ${
                data.time - 12 + 1
              }pm`;
          } else if (data.time > 15 && data.time < 20) {
            data["actualTime"] = `${data.time - 12}pm - ${
              data.time - 12 + 1
            }pm`;
          } else if (data.time > 19) {
            if (data.time === 23)
              data["actualTime"] = `11pm - ${data.time - 12 + 1}am`;
            else
              data["actualTime"] = `${data.time - 12}pm - ${
                data.time - 12 + 1
              }pm`;
          }
        });
        setTimeSlots(dataTimeSlots || []);
      }
    } catch (err) {
      console.log("error getting timeslot");
    }
  };

  const getTimeSlotAdmin = async () => {
    try {
      const response = await axiosInstance.get(
        `api/billboard/getSlotsForAdmin`,
        {
          params: queryParams,
        }
      );
      console.log("getTime Slot>>", response);
      if (response.status == 200 || response.status == 201) {
        let dataTimeSlot = response?.data?.data;

        dataTimeSlot.map((data) => {
          if (data?.time < 4) {
            if (data.time === 0) data["actualTime"] = "12am - 1am";
            else data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
          } else if (data?.time > 3 && data?.time < 8) {
            data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
          } else if (data.time > 7 && data.time < 12) {
            if (data.time === 11) data["actualTime"] = "11am - 12pm";
            else data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
          } else if (data.time > 11 && data.time < 16) {
            if (data.time === 12)
              data["actualTime"] = `12pm - ${data.time - 12 + 1}pm`;
            else
              data["actualTime"] = `${data.time - 12}pm - ${
                data.time - 12 + 1
              }pm`;
          } else if (data.time > 15 && data.time < 20) {
            data["actualTime"] = `${data.time - 12}pm - ${
              data.time - 12 + 1
            }pm`;
          } else if (data.time > 19) {
            if (data.time === 23)
              data["actualTime"] = `11pm - ${data.time - 12 + 1}am`;
            else
              data["actualTime"] = `${data.time - 12}pm - ${
                data.time - 12 + 1
              }pm`;
          }
        });
        setTimeSlots(dataTimeSlot || []);
      }
    } catch (err) {
      console.log("error getting timeslot");
    }
  };

  const handleChangeRepetition = (data, value, index) => {
    let dataAll = selectedSlot;
    dataAll?.map((item, i) => {
      if (i === index) {
        item["repetition"] = value;
      }
    });
    console.log(dataAll);
    setSelectedSlot(JSON.parse(JSON.stringify(dataAll)));
  };

  useEffect(() => {
    if (user?.role === "ClusterHead" && date && duration && billboardId) {
      setSelectedTimeDuration(duration * 30);
      getTimeSlot();
    }
  }, [duration]);

  useEffect(() => {
    if (user?.role === "ClusterHead") {
      getTimeSlot();
    }
  }, [user?.role]);
  useEffect(() => {
    if (user?.role === "SAdmin" && date && duration && billboardId) {
      setSelectedTimeDuration(duration * 30);
      getTimeSlotAdmin();
    }
  }, [duration]);

  useEffect(() => {
    if (user?.role === "SAdmin") {
      getTimeSlotAdmin();
    }
  }, [user?.role]);

  useEffect(() => {
    setSelectedTime(null);
  }, [date]);

  useEffect(() => {
    if (user?.role === "Admin" && date && duration && billboardId) {
      setSelectedTimeDuration(duration * 30);
      getTimeSlot();
    }
  }, [duration]);

  useEffect(() => {
    if (user?.role === "Admin") {
      getTimeSlot();
    }
  }, [user?.role]);

  return (
    <React.Fragment>
      <div
        // className="width-90LR"
        style={{ marginTop: "120px" }}
      >
        <Grid
          container
          item
          xs={12}
          sm={11}
          gap={1}
          // sx={{ margin: "5vh auto" }}
        >
          <Grid
            container
            justifyContent="space-between"
            xs={12}
            sm={6}
            md={7.8}
            className="Border borderRadius_8px"
          >
            <Grid
              className="width100 flex"
              sx={{ padding: "10px", borderBottom: "1px solid silver" }}
            >
              <Typography className="flex">
                <Typography className="bold fs16px">
                  Selected Duration :{" "}
                </Typography>
                <Typography className="bold fs16px colorApply">
                  {" "}
                  {duration} Second
                </Typography>
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className="width100 flex"
              sx={{ padding: "10px", borderBottom: "1px solid silver" }}
            >
              <Grid item md={4}>
                <Typography className="bold fs12px">
                  Select available time slot
                </Typography>
              </Grid>
              <Grid item md={8} sx={{ columnGap: "15px" }} className="flex">
                <Grid
                  className="flex"
                  sx={{ alignItems: "center", columnGap: "5px" }}
                >
                  <Typography
                    sx={{
                      height: "15px",
                      width: "15px",
                      backgroundColor: "green",
                      borderRadius: "4px",
                    }}
                  ></Typography>
                  <Typography className="fs12px bold">Selected</Typography>
                </Grid>
                <Grid
                  className="flex"
                  sx={{ alignItems: "center", columnGap: "5px" }}
                >
                  <Typography
                    sx={{
                      height: "15px",
                      width: "15px",
                      backgroundColor: "gray",
                      borderRadius: "4px",
                    }}
                  ></Typography>
                  <Typography className="fs12px bold">Not Available</Typography>
                </Grid>
                <Grid
                  className="flex"
                  sx={{ alignItems: "center", columnGap: "5px" }}
                >
                  <Typography
                    sx={{
                      height: "15px",
                      width: "15px",
                      backgroundColor: "orange",
                      borderRadius: "4px",
                    }}
                  ></Typography>
                  <Typography className="fs12px bold">
                    Few minutes remaining
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className="flex"
              flexDirection="column"
              gap="20px"
              sx={{
                height: "70vh",
                overflow: "auto",
                padding: "10px",
              }}
            >
              {user?.role === "SAdmin" ? (
                <>
                  {" "}
                  <Grid>
                    <div
                      className="grid_container"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {timeSlots?.map((data, index) => {
                        if (!data.ownedBy) return null;
                        const isDisabled =
                          (new Date().toISOString().slice(0, 10) === date &&
                            data.time < currentTime) ||
                          data.secondsLeft <= 0;
                        let timeOfDay;
                        if (data.time >= 0 && data.time < 4) {
                          timeOfDay = "Midnight";
                        } else if (data.time >= 4 && data.time < 8) {
                          timeOfDay = "Early Morning";
                        } else if (data.time >= 8 && data.time < 12) {
                          timeOfDay = "Morning";
                        } else if (data.time >= 12 && data.time < 16) {
                          timeOfDay = "Afternoon";
                        } else if (data.time >= 16 && data.time < 20) {
                          timeOfDay = "Evening";
                        } else {
                          timeOfDay = "Night";
                        }
                        return (
                          <div key={index}>
                            <Typography className="fs14px mt10px bold width100">
                              {timeOfDay}
                            </Typography>
                            <Grid
                              style={
                                mobileView
                                  ? { width: "85%" }
                                  : { width: "auto" }
                              }
                              className="grid_container flex"
                            >
                              <Grid
                                container
                                direction="column"
                                gap="5px"
                                xs={12}
                                style={{ minWidth: "200px" }}
                              >
                                <Button
                                  className={`${
                                    selectedSlotKey?.includes(data.time)
                                      ? "selectedbg"
                                      : data.secondsLeft <= 3600
                                      ? "timeslot-green-btn"
                                      : data.secondsLeft <= 1800
                                      ? "timelslot-orange-btn"
                                      : isDisabled
                                      ? "timelslot-orange-btn"
                                      : ""
                                  }`}
                                  sx={
                                    isDisabled && {
                                      color: "#ffffff !important",
                                      backgroundColor: "#ddd !important",
                                      border: "1px solid #ddd !important",
                                    }
                                  }
                                  onClick={() => handleSelectSlot(data)}
                                  disabled={isDisabled}
                                >
                                  <Typography
                                    align="center"
                                    className="fs12px timeslot-typo"
                                  >
                                    {data?.actualTime}
                                  </Typography>
                                  <Typography className="fs12px flex center timeslot-typoType">
                                    {Math.floor(data?.secondsLeft / duration)}{" "}
                                    Slots
                                  </Typography>
                                </Button>
                                <Grid container item>
                                  <Typography className="fs12px black-price">{`Price : ₹ ${data?.finalprice}/sec`}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        );
                      })}
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid>
                    <div
                      className="grid_container"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {timeSlots?.map((data, index) => {
                        if (!data.ownedBy) return null;
                        const isDisabled =
                          (new Date().toISOString().slice(0, 10) === date ||
                            tomorrow.toISOString().slice(0, 10) === date) &&
                          (data?.secondsLeft === 0 || data?.time < currentTime)
                            ? true
                            : false;
                        let timeOfDay;
                        if (data.time >= 0 && data.time < 4) {
                          timeOfDay = "Midnight";
                        } else if (data.time >= 4 && data.time < 8) {
                          timeOfDay = "Early Morning";
                        } else if (data.time >= 8 && data.time < 12) {
                          timeOfDay = "Morning";
                        } else if (data.time >= 12 && data.time < 16) {
                          timeOfDay = "Afternoon";
                        } else if (data.time >= 16 && data.time < 20) {
                          timeOfDay = "Evening";
                        } else {
                          timeOfDay = "Night";
                        }
                        return (
                          <div key={index}>
                            <Typography className="fs14px mt10px bold width100">
                              {timeOfDay}
                            </Typography>
                            <Grid
                              style={
                                mobileView
                                  ? { width: "85%" }
                                  : { width: "auto" }
                              }
                              className="grid_container flex"
                            >
                              <Grid
                                container
                                direction="column"
                                gap="5px"
                                xs={12}
                                style={{ minWidth: "200px" }}
                              >
                                <Button
                                  className={`${
                                    selectedSlotKey?.includes(data.time)
                                      ? "selectedbg"
                                      : data.secondsLeft <= 3600
                                      ? "timeslot-green-btn"
                                      : data.secondsLeft <= 1800
                                      ? "timelslot-orange-btn"
                                      : isDisabled
                                      ? "timelslot-orange-btn"
                                      : ""
                                  }`}
                                  sx={
                                    isDisabled && {
                                      color: "#ffffff !important",
                                      backgroundColor: "#ddd !important",
                                      border: "1px solid #ddd !important",
                                    }
                                  }
                                  onClick={() => handleSelectSlot(data)}
                                  disabled={isDisabled}
                                >
                                  <Typography
                                    align="center"
                                    className="fs12px timeslot-typo"
                                  >
                                    {data?.actualTime}
                                  </Typography>
                                  <Typography className="fs12px flex center timeslot-typoType">
                                    {Math.floor(data?.secondsLeft / duration)}{" "}
                                    Slots
                                  </Typography>
                                </Button>
                                <Grid container item>
                                  <Typography className="fs12px black-price">{`Price : ₹ ${data?.finalprice}/sec`}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        );
                      })}
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={5}
            md={4}
            className="Border borderRadius_8px"
            sx={{
              border: "2px solid gray",
              display: "block !important",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ borderBottom: "1px solid silver", padding: "5px 10px" }}
            >
              <Typography className="fs16px bold">Repetition</Typography>
              <Typography className="fs12px bold">
                (Number of times your ad will play in selected timeslot)
              </Typography>
            </Grid>
            <Grid
              // md={12}
              className="grid_container flex width100 flexSpaceJc "
              style={{
                flexWrap: "wrap",
                // border: " 1px solid red ",
                padding: "15px 10px",
              }}
            >
              <Grid
                className="flex width100"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography className="bold fs14px">
                  Selected timeslots
                </Typography>
                <Typography className="bold fs14px">Repetition</Typography>
              </Grid>
              {/* data.time > 3 && data.time < 8 && data.ownedBy  */}
              <Grid
                className="flex"
                flexDirection="column"
                gap="20px"
                sx={{
                  height: "64vh",
                  overflow: "auto",
                  padding: "10px",
                }}
                item
                xs={12}
              >
                {selectedSlot.map((data, index) =>
                  data.ownedBy ? (
                    <>
                      <Grid
                        className="flex width100"
                        sx={{
                          alignItems: "center",
                          marginBottom: "10px",
                          justifyContent: "space-between",
                        }}
                        gap={1}
                      >
                        <Grid
                          // style={
                          //   mobileView ? { width: "85%" } : { width: "auto" }
                          // }
                          className="grid_container flex"
                        >
                          <Grid
                            item
                            gap="5px"
                            xs={12}
                            style={{
                              minWidth: "200px",
                              maxWidth: "250px",
                            }}
                            className="flex centerAi"
                          >
                            <CancelOutlinedIcon
                              className=" Redcolor"
                              style={{
                                color: "red",
                                cursor: "pointer !important",
                              }}
                              onClick={() => {
                                // setSelectedTime(data.time);
                                // setPriceF(data.finalprice);
                                // setLeftSeconds(data);
                                handleSelectSlot(data);
                              }}
                            />
                            <Button
                              key={index}
                              className={
                                selectedSlotKey.includes(data.time)
                                  ? " selectedbg"
                                  : data.secondsLeft <= 3600
                                  ? "   timeslot-green-btn"
                                  : data.secondsLeft <= 1800
                                  ? "  timelslot-orange-btn "
                                  : data.secondsLeft <= 0
                                  ? "   timelslot-orange-btn "
                                  : null
                              }
                              sx={
                                new Date().toISOString().slice(0, 10) == date &&
                                data.time < currentTime && {
                                  color: "#ffffff !important",
                                  backgroundColor: "#ddd !important",
                                  border: "1px solid #ddd !important",
                                }
                              }
                              onClick={() => {
                                // setSelectedTime(data.time);
                                // setPriceF(data.finalprice);
                                // setLeftSeconds(data);
                                handleSelectSlot(data);
                              }}
                              disabled={
                                new Date().toISOString().slice(0, 10) == date &&
                                (data.secondsLeft == 0 ||
                                  data.time < currentTime)
                                  ? true
                                  : false
                              }
                            >
                              <Typography
                                align="center"
                                className="fs12px timeslot-typo"
                              >
                                {data.time < 4 &&
                                  `${
                                    data.time === 0
                                      ? "12am - 1am"
                                      : `${data.time}am - ${data.time + 1}am`
                                  }`}

                                {data.time > 3 &&
                                  data.time < 8 &&
                                  `${
                                    data.time === 0
                                      ? "12am - 1am"
                                      : `${data.time}am - ${data.time + 1}am`
                                  }`}
                                {data.time > 7 &&
                                  data.time < 12 &&
                                  `${
                                    data.time === 11
                                      ? "11am - 12pm"
                                      : `${data.time}am - ${data.time + 1}am`
                                  }`}
                                {data.time > 11 &&
                                  data.time < 16 &&
                                  `${
                                    data.time === 12
                                      ? `12pm - ${data.time - 12 + 1}pm`
                                      : `${data.time - 12}pm - ${
                                          data.time - 12 + 1
                                        }pm`
                                  }`}
                                {data.time > 15 &&
                                  data.time < 20 &&
                                  `${data.time - 12}pm - ${
                                    data.time - 12 + 1
                                  }pm`}
                                {data.time > 19 &&
                                  `${
                                    data.time === 23
                                      ? `11pm - ${data.time - 12 + 1}am`
                                      : `${data.time - 12}pm - ${
                                          data.time - 12 + 1
                                        }pm`
                                  }`}
                              </Typography>
                              <Typography
                                className="fs12px flex center timeslot-typoType"
                                justifyContent="center"
                              >
                                {Math.floor(data?.secondsLeft / duration)} Slots
                              </Typography>
                            </Button>
                            {/* <Grid container item>
                              <Typography className="fs12px black-price">{`Price : ₹ ${data?.finalprice}/sec`}</Typography>
                            </Grid> */}
                          </Grid>
                        </Grid>
                        <Typography className=" bold">X</Typography>

                        <Box>
                          <FormControl
                          // fullWidth
                          // style={{ paddingLeftLeft: "5px" }}
                          >
                            <Select
                              value={data?.repetition || 1}
                              onChange={(e) =>
                                handleChangeRepetition(
                                  data,
                                  e.target.value,
                                  index
                                )
                              }
                              sx={{ height: "35px", paddingLeftLeft: "5px" }}
                              className="borderRadius_8px"
                            >
                              {Array.from(
                                new Array(
                                  Math.floor(data?.secondsLeft / duration)
                                )
                              ).map((item, i) => (
                                <MenuItem value={i + 1}>{i + 1}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    </>
                  ) : null
                )}
                {/* {selectedSlot.length > 0 && ( */}
                <Button
                  className={
                    selectedSlot.length > 0
                      ? "purplebtn width100 fs14px"
                      : " disabledbtn width100 fs14px"
                  }
                  onClick={() =>
                    navigate(`/admin/modifybooking`, {
                      replace: true,
                      state: {
                        adTitle,

                        aboutAd,

                        billBoardData,
                        date,
                        type,
                        duration,
                        billboardId,

                        selectedSlot,
                      },
                    })
                  }
                  disabled={selectedSlot.length > 0 ? false : true}
                >
                  Continue to Book
                </Button>
                {/* )} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
