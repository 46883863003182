import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid, Typography, Divider, Button } from "@mui/material";

const TransactionSkeleton = () => {
  return (
    <div style={{ width: "100%" }}>
      <Grid container>
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", width: "60%", marginTop: "8px" }}
        />
        <Grid container justifyContent="space-between" mt={2}>
          <Grid item xs={3}>
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "80%" }} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "80%" }} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "80%" }} />
          </Grid>
          <Grid item xs={3}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem", width: "60%" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem", width: "60%", marginTop: "4px" }}
                />
              </Grid>
              <Grid item ml={2}>
                <Skeleton
                  variant="rectangular"
                  sx={{ width: "50px", height: "30px" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", width: "40%", marginTop: "16px" }}
        />
        <Grid xs={12} mt={2}>
          <Divider orientation="horizontal" flexItem />
        </Grid>
      </Grid>
    </div>
  );
};

export default TransactionSkeleton;
