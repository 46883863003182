import React, { useState } from "react";
import { Typography, Grid, Button, Breadcrumbs } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import SuccessDialog from "../../../../../../../utils/Dialog/SuceedFullDialog";
import WrongDialog from "../../../../../../../utils/Dialog/WrongDialog";
import SideBar from "../../../../../../../component/Sidebar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "./TextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../../../../../../api/axiosInstance";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const AddCouponDialogue = () => {
  const navigate = useNavigate();
  const [couponStartDate, setCouponStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [couponExpiaryDate, setCouponExpiaryDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [openDialogName, setOpenDialog] = useState(null);
  const [errMsg, setErrMsg] = useState("");
  const initialValues = {
    couponName: "",
    discount: "",
    description: "",
    minSpend: "",
    maxSpend: "",
    couponAmount: "",
    perUserLimit: "",
    couponCode: "",
  };
  const startDateChangeHandler = (newValue) => {
    setCouponStartDate(moment(newValue).format("YYYY-MM-DD"));
  };
  const couponExpiryDateHandler = (newValue) => {
    setCouponExpiaryDate(moment(newValue).format("YYYY-MM-DD"));
  };
  const validationSchema = Yup.object().shape({
    couponName: Yup.string().required("Coupon Name is required"),
    discount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be a positive number")
      .max(100, "Discount must be between 1 - 100%"),
    description: Yup.string().required("Description is required"),
    minSpend: Yup.number()
      .required("Minimum Spend is required")
      .positive("Minimum Spend must be a positive number"),
    maxSpend: Yup.number()
      .required("Maximum Spend is required")
      .positive("Maximum Spend must be a positive number"),
    couponAmount: Yup.number()
      .required("Coupon Amount is required")
      .positive("Coupon Amount must be a positive number"),
    perUserLimit: Yup.number()
      .required("Per User Limit is required")
      .positive("Per User Limit must be a positive number"),
    couponCode: Yup.string().required("Coupon Code is required"),
  });

  const addCoupon = async (values) => {
    setOpenDialog(null);
    try {
      const res = await axiosInstance.post("/coupon/createCoupon", values);

      if (res.status === 200 || res.status === 201) {
        setCouponStartDate(moment(new Date()).format("YYYY-MM-DD"));
        setCouponExpiaryDate(moment(new Date()).format("YYYY-MM-DD"));
        setOpenDialog("success");
      } else {
        console.log(res, "res in reject");
        setErrMsg(res?.response?.data?.msg);
        setOpenDialog("reject");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (values) => {
    const updatedValues = {
      ...values,
      startDate: couponStartDate,
      endDate: couponExpiaryDate,
    };
    addCoupon(updatedValues);
  };

  return (
    <>
      <Grid
        container
        className="width-90LR"
        style={{
          margin: "16px 0",
        }}
      >
        {" "}
        <KeyboardBackspaceIcon
          onClick={() => navigate(-1)}
          style={{ marginRight: "8px", textAlign: "center" }}
        ></KeyboardBackspaceIcon>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            to="/admin/CouponVocher"
            className="linkcolor"
            underline="hover"
            key="1"
          >
            <Typography className="purplecolortypo fs16px">
              Offers & Coupons
            </Typography>
          </Link>
          ,<Typography className="blackcolortypo fs16px">Add New</Typography>,
        </Breadcrumbs>
      </Grid>
      <div
        className="width-90LR"
        style={{
          margin: "16px 20px",

          borderRadius: "8px",
          // height: "calc(100vh - 120px)",
          border: "1px solid #F0F0F0",
          borderRadius: "8px",
          padding: "15px 30px 30px 30px",
        }}
      >
        <div>
          <Typography className="blackcolortypo fontWeight_500 fs24px">
            Add Coupon
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form style={{ width: "100%", marginTop: "24px" }}>
              <Grid
                container
                style={{ marginTop: "10px" }}
                rowGap="25px"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={5.8}>
                  <TextInput
                    label="Coupon Name"
                    name="couponName"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <TextInput
                    label="Coupon Code"
                    name="couponCode"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Description"
                    name="description"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      className="calender_input"
                      inputFormat="MM/dd/yyyy"
                      value={couponStartDate}
                      disablePast={true}
                      onChange={(e) => startDateChangeHandler(e)}
                      renderInput={(params) => (
                        <TextField {...params} style={{ width: "100%" }} />
                      )}
                    />{" "}
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      className="calender_input"
                      inputFormat="MM/dd/yyyy"
                      value={couponExpiaryDate}
                      disablePast={true}
                      onChange={(e) => {
                        couponExpiryDateHandler(e);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} style={{ width: "100%" }} />
                      )}
                    />{" "}
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <TextInput
                    label="Discount (%)"
                    name="discount"
                    type="number"
                  />
                </Grid>

                <Grid item xs={12} sm={5.8}>
                  <TextInput
                    label="Minimum Spend"
                    name="minSpend"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <TextInput
                    label="Maximum Spend"
                    name="maxSpend"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <TextInput
                    label="Coupon Amount"
                    name="couponAmount"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <TextInput
                    label="Per User Limit"
                    name="perUserLimit"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <TextInput
                    label="Discount type (Amount)"
                    name="Discount_type"
                    type="number"
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end">
                {" "}
                <Button
                  type="submit"
                  className="blue_color_btn"
                  style={{ width: "200px", marginTop: "24px" }}
                >
                  Add
                </Button>
              </Grid>
            </Form>
          </Formik>
        </div>
      </div>
      <SuccessDialog
        openData={openDialogName === "success"}
        data={"Coupon Added Successfully"}
        navigateTo="/admin/CouponVocher"
        state={{ tabValue: 1 }}
      />
      <WrongDialog openData={openDialogName === "reject"} data={errMsg} />{" "}
    </>
  );
};

export default AddCouponDialogue;
