import React, { useState } from "react";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";

// ** Tab's Import
import OverviewTab from "./OverviewTab";
import ScreenTab from "./Screens-Tab";
import OrderTab from "./Order-Tab/index";
import AccessTab from "./Access-Tab/index";
import Campaign from "./Campaign-Tab";
import MemberTab from "./Member-Tab";
import Revenue from "./Revenue-Tab/index";
import BillBoardTab from "./Billboard-Tab/index";
import ClusterHead from "./Clusterhead-Tab";
import WallletTab from "./Wallet-Tab/WalletTab";
import AllLogs from "../../Utils/AllLogs/AllLogs";
import axiosInstance from "../../../../../api/axiosInstance";
import AllAnalytics from "../../Utils/AllAnalytics/AllAnalytics";

// ** Reusable Component Import
import TabList from "../../../../../component/CustomTabs/TabList";
import permissionUtils from "../../../../../component/permissionUtils";
import TabPanelList from "../../../../../component/CustomTabs/TabPanel";
import CustomBreadcrumbs from "../../../../../component/CustomBreadcrumbs";

export default function UserProfile() {
  const theme = useTheme();
  const { state } = useLocation();
  const params = useParams();

  const { id } = useParams();
  const newURL = `/admin/manage-user?page=1&tabValue=${params.value}`;
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [profile, setProfile] = React.useState(null);

  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const userID = id;

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [inputState, setInputState] = useState(true);

  function ChangeInputState() {
    setInputState(false);
  }

  //get profile
  React.useEffect(() => {
    getProfile();
  }, [id]);

  const getProfile = async () => {
    try {
      let profile = await axiosInstance.get(`/api/user/getUserDetail/${id}`);
      setProfile(profile.data.msg);
    } catch (error) {
      console.log("USER PROFILE DATA ==>", error);
    }
  };

  // const newURL = `/admin/manage-user?page=1&tabValue=${params.value}`;

  const breadcrumbs = [
    { label: "Dashboard", link: "/admin/home" },
    { label: "Profile", link: "/admin/userdashborad" },
    { label: "Manage Users", link: "/admin/manage-user" },
    { label: state, link: newURL },
    { label: profile?.firstName },
  ];

  const permissionList = [
    "Overview",
    state === "Admin" ? "Clusterhead" : null,
    state === "AdAgency" ? "Member" : null,
    state === "BusinessOwner" ? "Billboard" : null,
    state === "ClusterHead" ? "Screens" : null,
    "view-user-orders",
    "view-user-campaign",
    "view-user-analytics",
    "view-user-access",
    "view-user-wallet-details",

    state === "ClusterHead" || state === "BusinessOwner" || state === "Admin"
      ? "view-user-revenue"
      : null,
    "view-user-logs",
  ].filter((permission) => permission !== null);

  const permissions = CheckTabsPermissions(permissionList);

  const getTabLabel = (permission) => {
    const labelMap = {
      Overview: "Overview",
      Clusterhead: state === "Admin" ? "Clusterhead" : null,
      Member: state === "AdAgency" ? "Member" : null,
      Billboard: state === "BusinessOwner" ? "Billboard" : null,
      Screens: state === "ClusterHead" ? "Screens" : null,
      "view-user-orders": "Orders",
      "view-user-campaign": "Campaign",
      "view-user-analytics": "Analytics",
      "view-user-access": "Access",
      "view-user-wallet-details": "Wallet",
      "view-user-revenue": "Revenue",
      Revenue:
        state === "ClusterHead" ||
        state === "BusinessOwner" ||
        state === "Admin"
          ? "Revenue"
          : null,
      "view-user-logs": "Log",
    };

    return labelMap[permission];
  };

  const panels = [
    {
      permission: "Overview",
      component: <OverviewTab userID={userID} />,
    },
    {
      permission: "Clusterhead",
      component: <ClusterHead userID={userID} />,
    },
    {
      permission: "Member",
      component: <MemberTab userID={userID} />,
    },
    {
      permission: "Billboard",
      component: (
        <BillBoardTab states={{ profile, inputState }} userID={userID} />
      ),
    },
    {
      permission: "Screens",
      component: <ScreenTab userID={userID} />,
    },

    {
      permission: "view-user-orders",
      component: <OrderTab userID={userID} />,
    },
    {
      permission: "view-user-campaign",
      component: <Campaign userID={userID} />,
    },
    {
      permission: "view-user-analytics",
      component: <AllAnalytics userID={userID} state={state} />,
    },
    {
      permission: "view-user-access",
      component: <AccessTab userID={userID} state={state} />,
    },
    {
      permission: "view-user-wallet-details",
      component: <WallletTab states={{ profile, userID }} />,
    },
    {
      permission: "view-user-revenue",
      component: <Revenue states={{ profile, userID }} />,
    },
    {
      permission: "view-user-logs",
      component: <AllLogs states={{ profile }} />,
    },
  ];

  const allowedPanels = panels.filter((panel) => {
    return (
      (panel.permission === "Clusterhead" && state === "Admin") ||
      (panel.permission === "Member" && state === "AdAgency") ||
      (panel.permission === "Billboard" && state === "BusinessOwner") ||
      (panel.permission === "Screens" && state === "ClusterHead") ||
      panel.permission === "Overview" ||
      panel.permission === "view-user-orders" ||
      panel.permission === "view-user-campaign" ||
      panel.permission === "view-user-analytics" ||
      panel.permission === "view-user-access" ||
      panel.permission === "view-user-wallet-details" ||
      panel.permission === "view-user-revenue" ||
      panel.permission === "view-user-logs"
    );
  });

  return (
    <>
      <div className="width-90LR ">
        <div className="flex-class">
          <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
        </div>
        <Grid container>
          <Grid container className="device-container mt20px">
            <Grid item xs={12}>
              <TabList
                md={md}
                value={value}
                centered={true}
                classes={{ Tabs: "TabStyleAddDevice", Tab: "TabChangesDevice" }}
                handleChange={handleChange}
                getTabLabel={getTabLabel}
                permissions={permissions}
              />
            </Grid>
          </Grid>{" "}
        </Grid>
        <TabPanelList
          value={value}
          permissions={permissions}
          panels={allowedPanels}
        />{" "}
      </div>
    </>
  );
}
