import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import CampaignList from "../CampaignTab/CampaignLive-test";
import TabList from "../../../../component/CustomTabs/TabList";
import permissionUtils from "../../../../component/permissionUtils";
import TabPanelList from "../../../../component/CustomTabs/TabPanel";

//=============================== Order =============================================//

export default function Orders({
  paginatedPage,
  paginateTabValue,
  getPaginatedValueHandler,
  currentPage,
  setCurrentPage,
}) {
  const location = useLocation();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const selectedTab = queryParams.get("orders-tab");
  const tabValue = parseInt(selectedTab, 10);
  const [value, setValue] = React.useState(0);
  const [shouldShowOrderComponent, setShouldShowOrderComponent] =
    useState(false);

  const permissionList = ["view-live-campaign", "view-testing-campaign"];
  const permissions = CheckTabsPermissions(permissionList);

  const getTabLabel = (permission) => {
    const labelMap = {
      "view-live-campaign": "Live",
      "view-testing-campaign": "Testing",
    };
    return labelMap[permission];
  };

  const panels = [
    {
      permission: "view-live-campaign",
      component: (
        <CampaignList
          paginatedPage={paginatedPage}
          paginateTabValue={paginateTabValue}
          getPaginatedValueHandler={getPaginatedValueHandler}
          tabValue={value}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ),
    },
    {
      permission: "view-testing-campaign",
      component: (
        <CampaignList
          paginatedPage={paginatedPage}
          paginateTabValue={paginateTabValue}
          getPaginatedValueHandler={getPaginatedValueHandler}
          tabValue={value}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ),
    },
  ];

  useEffect(() => {
    setShouldShowOrderComponent(permissions["view-testing-campaign"] === false);
  }, [permissions]);

  const handleChange = (event, newValue) => {
    searchParams.set("orders-tab", newValue);
    setValue(newValue);
    setSearchParams(searchParams);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (isNaN(tabValue)) {
      setValue(0);
    } else {
      setValue(tabValue);
    }
  }, [tabValue]);
  return (
    <>
      <Grid container>
        <Grid item className="width100 ">
          {shouldShowOrderComponent ? (
            <CampaignList
              paginatedPage={paginatedPage}
              paginateTabValue={paginateTabValue}
              getPaginatedValueHandler={getPaginatedValueHandler}
              tabValue={value}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            <Grid item className="width100">
              <TabList
                md={md}
                sx={{ borderRadius: "50px 0px 50px 0px", marginBottom: "20px" }}
                value={value}
                centered={true}
                classes={{
                  Tabs: "TabStyleAddDevice",
                  Tab: "TabChangesDeviceLive",
                }}
                handleChange={handleChange}
                getTabLabel={getTabLabel}
                permissions={permissions}
              />
              <TabPanelList
                value={value}
                permissions={permissions}
                panels={panels}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
