import React, { useState } from "react";
import {
  Grid,
  Typography,
  Card,
  DialogActions,
  Button,
  Input,
} from "@mui/material";

import axios from "../../../../../api/axiosInstance";
import SuccessDialog from "../../../../../utils/Dialog/SuceedFullDialog";
import WrongDialog from "../../../../../utils/Dialog/WrongDialog";

//utils/Dialog/SuceedFullDialog

export default function Device({ handleClose, getAllDevice, currentPage }) {
  const [deviceUID, setDeviceUID] = React.useState("");
  const [macId, setMacId] = React.useState("");
  const [deviceName, setDeviceName] = React.useState("");
  const [errMsg, setErrMsg] = useState("");
  // validation form
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});

  // Dialog
  const [openDialogName, setOpenDialog] = React.useState(null);

  // UseEffect Error handling
  React.useEffect(() => {
    console.log("Inside useEffect to check errors");
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("Inside Data from useEffect if no errors isSubmit === true");
      addDevice();
    }
  }, [formErrors]);

  // Validating Error
  const validate = (deviceUID, macId, deviceName) => {
    const errors = {};

    if (!macId || macId?.length !== 16) {
      errors.macId = "MacId should contain 16 digits !";
    }
    if (!deviceUID) {
      errors.deviceUID = "Device UID  is required !";
    }

    if (!deviceName) {
      errors.deviceName = "Device Name is required !";
    }
    return errors;
  };

  const SubmitForm = () => {
    setFormErrors(validate(deviceUID, macId, deviceName));
    setIsSubmit(true);
  };
  // add device
  const addDevice = async () => {
    setOpenDialog("");
    let body = {
      deviceName: deviceName,
      macId: macId,
      deviceUID: deviceUID,
    };

    try {
      console.log("Add Device Body ==> ", body);
      let device = await axios.post("/api/device/createdevice", body);
      console.log("device response =>", device);

      if (device.status === 201) {
        setMacId("");
        setDeviceUID("");
        setDeviceName("");
        setOpenDialog("success");
        getAllDevice(currentPage);
      } else {
        setOpenDialog("reject");
        setErrMsg(device?.response?.data?.msg);
      }
    } catch (error) {
      setOpenDialog("reject");
      setErrMsg(error?.response?.data?.msg);
    }
  };

  return (
    <>
      {" "}
      <Typography
        className="blackcolortypo500 18px"
        variant="h5"
        align="center"
      >
        {/* Device Detected Successfully! */}
        Enter Your Device Details
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={3}>
          <Typography
            className="greycolortypo  mt20px"
            variant="h5"
            align="center"
          >
            {" "}
            MAC ID
          </Typography>
          <Input
            className="inputstyle  mt20px"
            disableUnderline
            placeholder="Enter your mac Id"
            value={macId}
            onChange={(e) => setMacId(e.target.value?.trim(""))}
            inputProps={{ maxLength: 16 }}
          />
          <Typography className="formError" variant={"subtitle2"}>
            {formErrors.macId}
          </Typography>
          <Typography
            className="greycolortypo  mt20px"
            variant="h5"
            align="center"
          >
            {" "}
            Give it a name
          </Typography>{" "}
          <Input
            className="inputstyle  mt20px"
            disableUnderline
            placeholder="Enter your device Name"
            value={deviceName}
            onChange={(e) => setDeviceName(e.target.value)}
            inputProps={{ maxLength: 20 }}
          />
          <Typography className="formError" variant={"subtitle2"}>
            {formErrors.deviceName}
          </Typography>
          {/* <Typography className="typo-inputstyle mt20px"> Device 1</Typography> */}
          <Typography
            className="greycolortypo  mt20px"
            variant="h5"
            align="center"
          >
            {" "}
            Device UID
          </Typography>{" "}
          {/* <Typography className="typo-inputstyle mt20px"> DVC123</Typography>  */}
          <Input
            className="inputstyle  mt20px"
            disableUnderline
            placeholder="Enter device UID"
            value={deviceUID}
            onChange={(e) => setDeviceUID(e.target.value)}
            inputProps={{ maxLength: 8 }}
          />
          <Typography className="formError" variant={"subtitle2"}>
            {formErrors.deviceUID}
          </Typography>
        </Grid>
      </Grid>
      <DialogActions className="mt20px">
        <Button className="previousbtn" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="purplebtn" onClick={SubmitForm}>
          Save
        </Button>
      </DialogActions>
      <SuccessDialog
        openData={openDialogName === "success"}
        data={"Device Added"}
        closeModal={handleClose}
        parentNavigate={true}
      />
      <WrongDialog openData={openDialogName === "reject"} data={errMsg} />
    </>
  );
}
