import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import permissionUtils from "../../../../component/permissionUtils";

import MarginAndCutDialogue from "../marginAndCutDialouge/MarginAndCutDialogue";
import MarginAndCutDialogueTwo from "../marginAndCutDialouge/MarginAndCutDialogueTwo";
const Margin_Cut = () => {
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  // 1st input state
  const [postMyAdPer1, setPostMyAdPer1] = useState(98);
  const [clusterHeadPer1, setClusterHeadPer1] = useState(2);
  const [businessOwner1, setBusinessOwner1] = useState(0);
  // 1st input State
  const [update, setUpdate] = useState(false);
  const [disabledInput1, setDisabledInput1] = useState(true);
  //2nd input state
  const [postMyAdPer2, setPostMyAdPer2] = useState(28);
  const [clusterHeadPer2, setClusterHeadPer2] = useState(2);
  const [businessOwner2, setBusinessOwner2] = useState(70);
  const [update2, setUpdate2] = useState(false);
  const [disabledInput2, setDisabledInput2] = useState(true);
  //
  // firstInputFunction
  const updateHandler = (bool) => {
    if (bool) {
      setUpdate(true);
      setDisabledInput1(false);
    } else {
      setUpdate(false);
      setDisabledInput1(true);
    }
  };
  // secondInputFunction
  const updateHandler2 = (bool) => {
    if (bool) {
      setUpdate2(true);
      setDisabledInput2(false);
    } else {
      setUpdate2(false);
      setDisabledInput2(true);
    }
  };
  //
  let sum =
    Number(postMyAdPer1) + Number(clusterHeadPer1) + Number(businessOwner1);
  //
  let sum2 =
    Number(postMyAdPer2) + Number(clusterHeadPer2) + Number(businessOwner2);
  //
  useEffect(() => {
    sum =
      Number(postMyAdPer1) + Number(clusterHeadPer1) + Number(businessOwner1);
  }, [postMyAdPer1, clusterHeadPer1, businessOwner1]);
  //
  useEffect(() => {
    sum2 =
      Number(postMyAdPer2) + Number(clusterHeadPer2) + Number(businessOwner2);
  }, [postMyAdPer2, clusterHeadPer2, businessOwner2]);
  return (
    <>
      <Grid
        container
        style={{
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          sx={{ padding: "10px 0", borderRadius: "8px 8px 0px 0px" }}
          className="purple-gradient"
        >
          <Typography className="whitecolortypo fs20px">
            Margin & Cut
          </Typography>
        </Grid>
        {/*  */}
        <Grid
          container
          item
          xs={12}
          // sx={{ height: "50px" }}
          className="pink-gradient"
        >
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRight: "1px solid #E4E6EF",
              height: "100%",
              padding: "15px 0",
            }}
          >
            <Typography className="whitecolortypo500 fs16">
              Billboard Owned by{" "}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignItems="center"
            sx={{ borderRight: "1px solid #E4E6EF", height: "100%" }}
          >
            <Typography className="whitecolortypo500 fs16">
              POSTMYAD{" "}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignItems="center"
            sx={{ borderRight: "1px solid #E4E6EF", height: "100%" }}
          >
            <Typography className="whitecolortypo500 fs16">
              Clusterhead{" "}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Typography className="whitecolortypo500 fs16">
              Business Owner{" "}
            </Typography>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container item xs={12} style={{ border: "1px solid #E4E6EF" }}>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignContent="center"
            sx={{ height: "170px", borderRight: "1px solid #E4E6EF" }}
          >
            <Typography className="blackcolor484848 fontWeight_600 fs20px">
              Post my ad
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignContent="center"
            sx={{ height: "170px", borderRight: "1px solid #E4E6EF" }}
          >
            <input
              type="number"
              className="primary_input"
              value={postMyAdPer1}
              disabled={disabledInput1}
              onChange={(e) => {
                setPostMyAdPer1(e.target.value);
              }}
            />
          </Grid>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignContent="center"
            sx={{ height: "170px", borderRight: "1px solid #E4E6EF" }}
          >
            <input
              type="number"
              disabled={disabledInput1}
              className="primary_input"
              value={clusterHeadPer1}
              onChange={(e) => setClusterHeadPer1(e.target.value)}
            />
          </Grid>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignContent="center"
            sx={{ height: "170px", borderRight: "1px solid #E4E6EF" }}
          >
            <input
              type="number"
              disabled={disabledInput1}
              className="primary_input"
              value={businessOwner1}
              onChange={(e) => setBusinessOwner1(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        style={{ paddingTop: "10px" }}
      >
        {PermissionCheck("update-finance-margin-cut") && (
          <>
            <Grid item>
              <Typography
                className={
                  sum2 !== 100
                    ? "danger_color blackcolor484848fs18px fontWeight_600"
                    : "blackcolor484848fs18px fontWeight_600"
                }
              >
                Note : Make sure sum is 100%, Value should not be less than 0.
              </Typography>
            </Grid>
            <Grid item>
              <MarginAndCutDialogue
                sum={sum}
                postMyAdPer1={postMyAdPer1}
                clusterHeadPer1={clusterHeadPer1}
                businessOwner1={businessOwner1}
                update={update}
                updateHandler={updateHandler}
                setUpdate={setUpdate}
                setPostMyAdPer1={setPostMyAdPer1}
                setClusterHeadPer1={setClusterHeadPer1}
                setBusinessOwner1={setBusinessOwner1}
              />
            </Grid>
          </>
        )}
      </Grid>
      {/*  */}
      <Grid container style={{ marginTop: "40px" }}>
        <Grid
          container
          item
          xs={12}
          // sx={{ height: "50px" }}
          className="pink-gradient"
        >
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRight: "1px solid #E4E6EF",
              height: "100%",
              padding: "15px 0",
            }}
          >
            <Typography className="whitecolortypo500 fs16">
              Billboard Owned by{" "}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignItems="center"
            sx={{ borderRight: "1px solid #E4E6EF", height: "100%" }}
          >
            <Typography className="whitecolortypo500 fs16">
              POSTMYAD{" "}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignItems="center"
            sx={{ borderRight: "1px solid #E4E6EF", height: "100%" }}
          >
            <Typography className="whitecolortypo500 fs16">
              Clusterhead{" "}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Typography className="whitecolortypo500 fs16">
              Business Owner{" "}
            </Typography>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container item xs={12} style={{ border: "1px solid #E4E6EF" }}>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignContent="center"
            sx={{ height: "170px", borderRight: "1px solid #E4E6EF" }}
          >
            <Typography className="blackcolor484848 fontWeight_600 fs20px">
              Other
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignContent="center"
            sx={{ height: "170px", borderRight: "1px solid #E4E6EF" }}
          >
            <input
              type="number"
              className="primary_input"
              disabled={disabledInput2}
              value={postMyAdPer2}
              onChange={(e) => setPostMyAdPer2(e.target.value)}
            />
          </Grid>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignContent="center"
            sx={{ height: "170px", borderRight: "1px solid #E4E6EF" }}
          >
            <input
              type="number"
              className="primary_input"
              disabled={disabledInput2}
              value={clusterHeadPer2}
              onChange={(e) => setClusterHeadPer2(e.target.value)}
            />
          </Grid>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignContent="center"
            sx={{ height: "170px", borderRight: "1px solid #E4E6EF" }}
          >
            <input
              type="number"
              className="primary_input"
              disabled={disabledInput2}
              value={businessOwner2}
              onChange={(e) => setBusinessOwner2(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        style={{ paddingTop: "10px" }}
      >
        {PermissionCheck("update-finance-margin-cut") && (
          <>
            <Grid item>
              <Typography
                className={
                  sum2 !== 100
                    ? "danger_color blackcolor484848fs18px fontWeight_600"
                    : "blackcolor484848fs18px fontWeight_600"
                }
              >
                Note : Make sure sum is 100%, Value should not be less than 0.
              </Typography>
            </Grid>
            <Grid item>
              <MarginAndCutDialogueTwo
                sum2={sum2}
                postMyAdPer2={setPostMyAdPer2}
                clusterHeadPer2={clusterHeadPer2}
                businessOwner2={businessOwner2}
                update2={update2}
                updateHandler2={updateHandler2}
                setUpdate2={setUpdate2}
                setPostMyAdPer2={setPostMyAdPer2}
                setClusterHeadPer2={setClusterHeadPer2}
                setBusinessOwner2={setBusinessOwner2}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Margin_Cut;
