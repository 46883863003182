import React, { useEffect, useState, useRef } from "react";
//**  Mui Component Import
import {
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Button,
} from "@mui/material";
//**  Icons Import
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { FiUploadCloud } from "react-icons/fi";
import { BsInfoSquare } from "react-icons/bs";
//** Core Component Import
import DeleteDilogue from "../ActionBurner's/DeleteDialogue";
import axiosInstance from "../../../../../../../api/axiosInstance";
import BurnerAdDetails from "../ActionBurner's/BurnerAdDetails";
import permissionUtils from "../../../../../../../component/permissionUtils";

import LoaderDialog from "../../../../../../../component/LoaderDialogue/LoaderDialogue";
import DeleteAllBurnerAdDilogue from "../DeleteAllBurnerAd";
const AddBurnerAd = ({
  loadingBurners,
  setSnackOpen,
  setSnackMsg,
  setSnackErrMsg,
  setSnackerropen,
  billBoardData,
  handleShowSnackbar,
  getBurnerAd,
  resBurnerAds,
  deleteBurnerAd,
  allBurnerKey,
  deleteAllBurnerAd,
}) => {
  const vidRef = useRef();
  let userD = JSON.parse(localStorage.getItem("userData"));
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const [loading, setLoading] = useState(false);
  const [timmedname, setTrimmedname] = useState(null);
  const [file, setFile] = useState(null);
  const [localFileUrl, setLocalFileUrl] = useState(null);
  const [zipfilename, setZipfilename] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [filetype, setFiletype] = useState(null);
  const [mediafile, setMediafile] = useState(null);
  const [imageoriginalname, setImageoriginalname] = useState("");
  const [imagestring, setImagestring] = useState("");
  // SnackBar

  const [burnerToggle, setburnerToggle] = useState();
  const [labelToggle, setLabeltoggle] = useState("");
  const [burnerAdStatus, setBurnerAdStatus] = useState(false);
  useEffect(() => {
    if (burnerAdStatus) {
      setburnerToggle(burnerAdStatus);
      setLabeltoggle("Download Global Burner Ads");
    }
  }, [burnerAdStatus]);

  const onClickBurnerToggle = (e) => {
    const newBurnerToggle = !burnerToggle;
    setburnerToggle(newBurnerToggle);
    setLabeltoggle(newBurnerToggle ? "Download Global Burner Ads" : "");
    changeBurnerAdsStatus(newBurnerToggle); // Pass the new state as the status
  };

  const onChangeFile = (event, index) => {
    if (event.target.files[0].type == "video/mp4") {
      if (index == 1) {
        setFile(event.target.files[0]);
      }
      setLocalFileUrl(URL.createObjectURL(event.target.files[0]));
      setMediafile(event.target.files?.[0]);
      setFiletype(event.target.files[0].type);
      let Milliseconds =
        String(new Date().getFullYear()) +
        String(new Date().getMonth()) +
        String(new Date().getDate()) +
        String(new Date().getHours()) +
        String(new Date().getMinutes()) +
        String(new Date().getMilliseconds());
      let a = event.target.files[0].name.split(".")[0];
      let trimmedstr = a
        .replace(/[&\/\\_#,^!-@+()$~%'":-?<>{}\s+]/g, "")
        .concat(Milliseconds);
      setTrimmedname(`${userD.user._id}_${trimmedstr}`);
      setZipfilename(a); //==> to get original name of the uploaded
      let lastExtensionLength = event.target.files[0].name?.split(".")?.length;
      let extension = ".".concat(
        event.target.files[0].name.split(".")?.[lastExtensionLength - 1]
      );
      //.log("Extension ===>", extension);
      trimmedstr = `${userD.user._id}_${trimmedstr}`;
      let filename = trimmedstr.concat(extension); //==> to set the uniquename  of the file
      //.log("Final Name of the File => ", filename);
      setFileName(filename);
    }
    //=====================================================================================//
  };
  const handleUploadimage = async (data) => {
    try {
      let url;
      setLoading(true);
      let formdata;
      if (data?.type == "video/mp4") {
        formdata = new FormData();
        url = "/api/billboard/uploadBurnerAdVideo";
        formdata.append("userId", userD?.user?._id);
        formdata.append("billboardId", billBoardData?._id);
        formdata.append("filetype", filetype);
        formdata.append("uniquename", timmedname);
        formdata.append("macId", billBoardData?.deviceId?.macId);
        formdata.append("file", mediafile);
      } else {
        url = "/api/billboard/uploadBurnerAdImage";
        formdata = {
          userId: userD?.user?._id,
          imagename: imageoriginalname,
          filetype: filetype?.split("/")?.[1],
          uniquename: timmedname,
          macId: billBoardData?.deviceId?.macId,
          imagestring: imagestring,
        };
      }
      const res = await axiosInstance.post(url, formdata);
      if (res.status == 200) {
        //.log("Handle off response sfter vdo upload  ===>", res);
        getBurnerAd();
        setSnackMsg("Content Has been uploaded");
        setLoading(false);
        handleShowSnackbar("success");
        setLocalFileUrl(null);
        setFile(null);
      } else {
        //.log("error in handle off ==> ", res.msg);
        setSnackerropen(true);
        // setOpenDialog("reject");
        setSnackErrMsg(res.msg);
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (err) {
      setLoading(false);
      handleShowSnackbar("error");
    }
  };

  /////////////// change burner ads status ////////////
  const getBurnerAdStatus = async () => {
    try {
      const res = await axiosInstance.get(
        `api/billboard/getBurnerAdStatus/${billBoardData?._id}`
      );
      //.log(res, "res");
      if (res.status == 200 || res.status == 201) {
        //.log("res for status", res);
        setBurnerAdStatus(res?.data?.data.isAllBurnerAds);
        setburnerToggle(res?.data?.data.isAllBurnerAds);
      } else {
        //.log("error getting burnerVdo res", res.msg);
      }
    } catch (error) {
      //.log(error, "error");
    }
  };
  const changeBurnerAdsStatus = async (status) => {
    let body = {
      billboardId: billBoardData?._id,
      status, // Use the status passed as an argument
    };
    try {
      const res = await axiosInstance.post(
        `api/billboard/changeBurnerAdStatus`,
        body
      );
      if (res.status == 200 || res.status == 201) {
        getBurnerAdStatus();
        setSnackOpen(true);
        setSnackMsg("Status Updated");
        handleShowSnackbar("success");
      }
    } catch (error) {
      //.log(error, "error");
      handleShowSnackbar("error");
    }
  };
  useEffect(() => {
    getBurnerAdStatus();
  }, [billBoardData]);

  useEffect(() => {
    if (!!billBoardData?.deviceId?.macId) {
      getBurnerAd();
    }
  }, [billBoardData]);

  return (
    <>
      <div>
        <Grid
          container
          justifyContent="space-between"
          mt={1}
          alignItems="center"
        >
          <Typography className="type-info fs13px">
            <BsInfoSquare /> Burner Ad are Ads which plays automatically if
            there are no Paid ads playing on billboard
          </Typography>
          {PermissionCheck("change-burner-ad-status") && (
            <Grid item>
              <Grid
                container
                item
                gap="10px"
                sx={{
                  padding: "8px 10px ",
                  borderRadius: "8px",
                  // background: "#D6C2FF",
                }}
              >
                {/*  */}
                <label class="toggle">
                  <input
                    checked={burnerToggle}
                    class="toggle-checkbox"
                    type="checkbox"
                    value=""
                    onClick={(e) => {
                      onClickBurnerToggle(e);
                    }}
                  />
                  <div class="toggle-switch"></div>
                </label>{" "}
                <Typography className="blackcolor484848 fs18px">
                  Download Global Burner Ads
                </Typography>
                {PermissionCheck("create-delete-burner-ad") && (
                  <DeleteAllBurnerAdDilogue
                    deleteAllBurnerAd={deleteAllBurnerAd}
                    allBurnerKey={allBurnerKey}
                  />
                )}
              </Grid>{" "}
            </Grid>
          )}
        </Grid>

        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className="blackcolor484848 fs20px">
              Showing {resBurnerAds?.length ?? 0} Burner Ads{" "}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className="type-info fs12px"
              sx={{ marginRight: "200px" }}
            >
              <BsInfoSquare /> This will download PMA Burner Ads along <br />{" "}
              with billboard specific burner ad
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={3} gap={3} className="relative">
          {PermissionCheck("create-delete-burner-ad") && (
            <Grid item xs={12} sm={5.8} md={2.8}>
              {!file ? (
                <>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    xs={12}
                    style={{
                      height: "250px",
                      position: "relative",
                      border: "1px solid #ddd",
                      background: "#F7F8FD",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    {resBurnerAds?.length > 19 ? (
                      <>
                        <Typography
                          align="center"
                          className="blackcolor484848 fs20px"
                        >
                          You have already uploaded 20 videos.
                        </Typography>
                        <Typography
                          align="center"
                          className="blackcolor484848 fs20px"
                        >
                          To upload a new video,
                        </Typography>
                        <Typography
                          align="center"
                          className="blackcolor484848 fs20px"
                        >
                          Please delete some of your existing videos first.
                        </Typography>
                      </>
                    ) : (
                      <>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          accept=".mp4"
                          id="raised-button-file"
                          onChange={(e) => {
                            onChangeFile(e, 1);
                          }}
                        />
                        <label htmlFor="raised-button-file">
                          <IconButton
                            variant="contained"
                            size="large"
                            component="span"
                          >
                            <AddCircleOutlineIcon
                              sx={{ fontSize: "80px", color: "#E141C3" }}
                            />
                          </IconButton>
                        </label>
                        <Typography
                          align="center"
                          className="blackcolor484848 fs20px"
                        >
                          Supported format mp4
                        </Typography>
                      </>
                    )}
                  </Grid>
                </>
              ) : (
                <Grid
                  container
                  style={{
                    position: "relative",
                    border: "1px solid #ddd",
                    background: "#F7F8FD",
                    borderRadius: "8px 8px 0px 0px",
                  }}
                  justifyContent="space-between"
                  rowGap="20px"
                >
                  {localFileUrl ? (
                    <video
                      controls
                      muted
                      autoPlay
                      ref={vidRef}
                      src={localFileUrl}
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : null}
                  {loading ? null : (
                    <ClearIcon
                      className="red-icon fs40px ad_content_clear_Btn"
                      onClick={() => setFile(null)}
                    />
                  )}
                </Grid>
              )}

              {resBurnerAds?.length > 19 ? (
                <Button className={"purple_btn_primary--burnerad fs18px"}>
                  Limit Reached
                </Button>
              ) : (
                <Button
                  className={
                    !file
                      ? "disabledbtn--burnerad fs18px"
                      : "purple_btn_primary--burnerad fs18px"
                  }
                  endIcon={loading ? null : <FiUploadCloud />}
                  disabled={!file ? true : loading ? true : false}
                  onClick={() => handleUploadimage(file)}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "upload"
                  )}
                </Button>
              )}
            </Grid>
          )}

          {resBurnerAds ? (
            resBurnerAds.map((data, index) => (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={2.8}
                  className="relative"
                  key={index}
                >
                  <Grid container className="relative">
                    <Grid
                      item
                      xs={12}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #ddd",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                          height: "251px",
                        }}
                      >
                        <video
                          controls
                          src={
                            data?.s3Link ||
                            `https://s3.ap-south-1.amazonaws.com/storage.saps.one/${data?.Key}`
                          }
                          width="100%"
                          height="100%"
                          ref={vidRef}
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                        />
                      </div>
                      <BurnerAdDetails
                        key={index}
                        resBurnerAds={data}
                        getBurnerAd={getBurnerAd}
                      />
                    </Grid>
                    {PermissionCheck("create-delete-burner-ad") && (
                      <Grid
                        item
                        className="absolute"
                        sx={{ right: 7, top: 10 }}
                      >
                        <DeleteDilogue
                          deleteBurnerAd={deleteBurnerAd}
                          burnerVdo={data}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            ))
          ) : (
            <Grid item xs={8}>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <LoaderDialog loading={loadingBurners} />
              </Grid>{" "}
            </Grid>
          )}
          {/* {!resBurnerAds && (
            <Grid item xs={12} sm={5.8} md={2.8}>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                No data available
              </Grid>{" "}
            </Grid>
          )} */}
        </Grid>
      </div>
    </>
  );
};
export default AddBurnerAd;
