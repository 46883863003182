import React, { useState, useEffect } from "react";

import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Button,
} from "@mui/material";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{ m: 0, p: 0, background: "#fffff" }}
      {...other}
      className="purple-gradient"
    >
      {children}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: "0 20px" }}
      >
        <Grid item>
          {" "}
          <Typography className="whitecolortypo500">Time Stamp</Typography>
        </Grid>
        <Grid item>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: "#DDDDDD",
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function TimeStamp(props) {
  const [open, setOpen] = useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Grid
        container
        style={{
          border: "1px solid #red",
        }}
      >
        <Button
          className="Time-Stamp"
          sx={{
            border: "1px solid #000",
            color: "#000",
          }}
          onClick={() => setOpen(true)}
        >
          +{props && props.detail && props.detail.length - 3}
        </Button>
      </Grid>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            {props &&
              props.detail.map((item, index) => (
                <Grid item xs={4} sm={3} md={2} lg={1.3}>
                  {" "}
                  <Button
                    className="Time-Stamp"
                    sx={{ width: "100%" }}
                    key={index}
                  >
                    {" "}
                    {item}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
