import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

// ** Mui Component
import {
  Typography,
  Grid,
  Breadcrumbs,
  IconButton,
  Button,
  Snackbar,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

// ** Core Component
import { FETCH_URL } from "../../../fetchIp";
import SideBar from "../../../component/Sidebar/Sidebar";
import ConfrimDeleteDialog from "./DeleteApkDialog";

// ** React Icons
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { VscCloudUpload } from "react-icons/vsc";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Campaign() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const registerOptions = { required: true };
  const [apkList, setApkList] = useState();
  const [file, setFile] = useState(null);
  const [version, setVersion] = useState("");
  const [versionName, setVersionName] = useState();
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [uploading, setUploading] = useState(false); // Add the state variable
  const [apkError, setApkError] = React.useState("");
  const isFileInputDisabled = !version || !versionName;
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const fileUploader = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };
  const ApkVersionList = async () => {
    try {
      let token = JSON.parse(localStorage.getItem("userData")).token;
      const response = await fetch(`${FETCH_URL}/api/apk/getAllApk`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let res = await response.json();
      if (response.ok) {
        setApkList(res?.data);
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const PostApkVersion = async () => {
    if (!!file === false) {
      setApkError("Please upload a apk file.");
      return;
    }
    const formdata = new FormData();
    formdata.append("name", version);
    formdata.append("version", versionName);
    formdata.append("file", file);

    console.log("ooo", formdata);
    try {
      setUploading(true); // Set the state to true when starting the upload
      const userData = JSON.parse(localStorage.getItem("userData"));
      let token = userData.token;
      const headers = {
        Authorization: `Bearer ${token}`,
        ContentType: "multipart/form-data",
      };

      const response = await axios.post(
        `${FETCH_URL}/api/apk/uploadApk`,
        formdata,
        { headers }
      );
      let res = response.data;
      if (response.status === 200) {
        setSnackOpen(true);
        setSnackMsg(res.msg);
        setUploading(false);
        setFile(null);
        setVersion("");
        setVersionName("");
        setApkError("");
        ApkVersionList();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.msg.err);
        setUploading(false);
      }
    } catch (error) {}
  };
  useEffect(() => {
    ApkVersionList();
  }, []);

  const date = new Date(file?.lastModifiedDate);

  return (
    <>
        <Snackbar
          open={snackopen}
          autoHideDuration={1000}
          onClose={SnanbarClose}
        >
          <Alert onClose={SnanbarClose} severity={"success"}>
            {snackmsg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={snackerropen}
          autoHideDuration={3000}
          onClose={SnackbarErrorClose}
        >
          <Alert onClose={SnackbarErrorClose} severity={"error"}>
            {snackErrMsg}
          </Alert>
        </Snackbar>

        <div className="width-90LR ">
          <div
            className="flex-class"
            style={{ marginBottom: "16px", marginTop: "95px" }}
          >
            <Grid container direction="row" justifyContent="start">
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link
                  to="/admin/home"
                  className="linkcolor"
                  underline="hover"
                  key="1"
                >
                  <Typography className="purplecolortypo fs16px">
                    Dashboard
                  </Typography>
                </Link>
                ,
                <Link
                  to="/admin/apk-manager"
                  className="linkcolor"
                  underline="hover"
                  key="2"
                  color="inherit"
                  href="/material-ui/getting-started/installation/"
                >
                  <Typography className="blackcolortypo fs16px">
                    APK Manager
                  </Typography>
                </Link>
                ,
              </Breadcrumbs>
            </Grid>
          </div>
          <Typography className="greycolor505050500 fs25px">
            APK Manager
          </Typography>

          <Grid container>
            <Grid
              container
              justifyContent="space-between"
              rowGap="15px"
              sx={{
                border: "1px solid #ddd",
                minHeight: "400px",
                borderRadius: "8px",
              }}
              className="padding-20px mt20px"
            >
              <Grid item xs={12} sm={5} md={5} className="mt16px padding5-20px">
                <form onSubmit={handleSubmit()}>
                  <Grid container mb={1}>
                    <Typography className="greycolor1c1c1c fs18px ">
                      Enter Apk Details
                    </Typography>{" "}
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Typography className="greycolor1c1c1c fs16px">
                        Apk Version
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter latest apk version"
                        type="text"
                        size="small"
                        color="secondary"
                        variant="outlined"
                        className="mt10px"
                        value={version}
                        {...register("version", registerOptions)}
                        name="version"
                        error={!version && !!errors.version}
                        helperText={
                          !version
                            ? errors.version && "apk version  is required !"
                            : ""
                        }
                        onChange={(e) => setVersion(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Typography className="greycolor1c1c1c fs16px">
                        Apk Name
                      </Typography>
                      <TextField
                        fullWidth
                        type="text"
                        size="small"
                        variant="outlined"
                        color="secondary"
                        className="mt10px"
                        placeholder="Enter latest apk name"
                        {...register("versionName", registerOptions)}
                        name="versionName"
                        error={!versionName && !!errors.versionName}
                        helperText={
                          !versionName
                            ? errors.versionName &&
                              "apk version name is required !"
                            : ""
                        }
                        value={versionName}
                        onChange={(e) => setVersionName(e.target.value)}
                      />{" "}
                    </Grid>{" "}
                  </Grid>
                  {!file ? (
                    <div>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          height: "190px",
                          position: "relative",
                          borderStyle: "dotted",
                          border: `2px solid ${
                            apkError
                              ? "#d32f2f"
                              : isFileInputDisabled
                              ? "grey"
                              : "#b937fa"
                          } `,
                          width: "100%",
                          borderRadius: "20px",
                          margin: "auto",
                          marginTop: "20px",
                        }}
                      >
                        <Grid item>
                          <input
                            type="file"
                            hidden
                            disabled={isFileInputDisabled}
                            onChange={(event) => {
                              fileUploader(event);
                            }}
                            id="raised-button-file"
                          />
                          <label htmlFor="raised-button-file">
                            <Grid
                              container
                              direction={"column"}
                              justifyContent={"center"}
                            >
                              <Grid container justifyContent={"center"}>
                                <IconButton
                                  variant="contained"
                                  size="large"
                                  component="span"
                                >
                                  <AddCircleOutlineIcon
                                    sx={{
                                      fontSize: "80px",
                                      color: isFileInputDisabled
                                        ? "grey"
                                        : "#E141C3",
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                              <Typography
                                align="center"
                                className="blackcolor484848 fs16px"
                              >
                                {(!version || !versionName) && (
                                  <p>
                                    firstly enter apk version and name before
                                    uploading the APK.
                                  </p>
                                )}
                              </Typography>{" "}
                            </Grid>
                          </label>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="subtitle1"
                        className="width100 fs12px"
                        align="center"
                        color={"#d32f2f"}
                      >
                        {apkError}
                      </Typography>
                    </div>
                  ) : (
                    <Grid container>
                      <Grid item>
                        <p>
                          <strong>Name:</strong> {file?.name}
                        </p>
                        <p>
                          <strong>Size:</strong>{" "}
                          {(file?.size / (1024 * 1024)).toFixed(2)} MB
                        </p>
                        <p>
                          <strong>Last Modified Date:</strong>{" "}
                          {date.toDateString()}
                        </p>
                        <p>
                          <strong>Last Modified Time:</strong>{" "}
                          {date.toLocaleTimeString()}
                        </p>
                        <p>
                          <strong>Type:</strong> {file?.type}
                        </p>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container justifyContent={"flex-end"}>
                    <Button
                      type="submit"
                      size="medium"
                      disabled={uploading}
                      startIcon={<VscCloudUpload />}
                      onClick={PostApkVersion}
                      className={
                        uploading
                          ? "mt20px  width100 disabledbtn"
                          : "width100 mt20px purple_btn_primary"
                      }
                    >
                      {uploading ? "Uploading Apk..." : "Upload Apk"}
                    </Button>
                  </Grid>
                </form>
              </Grid>{" "}
              <Grid
                item
                xs={12}
                sm={7}
                md={7}
                sx={{ borderLeft: "1px solid #ddd" }}
                className=" padding5-20px "
              >
                <Typography className="greycolor1c1c1c fs18px  mt16px">
                  List of APK's
                </Typography>

                <table style={{ width: "100%" }} className="device mt16px  ">
                  <thead>
                    <tr>
                      <th
                        style={{ textAlign: "left" }}
                        className="greycolor1c1c1c fs18px "
                      >
                        Name
                      </th>
                      <th
                        style={{ textAlign: "left" }}
                        className="greycolor1c1c1c fs18px "
                      >
                        Version
                      </th>
                      <th className="center-text greycolor1c1c1c fs18px">
                        Date
                      </th>
                      <th className="center-text greycolor1c1c1c fs18px">
                        Time
                      </th>
                      <th className="center-text greycolor1c1c1c fs18px">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {apkList &&
                      apkList.length > 0 &&
                      apkList.map((item, i) => (
                        <tr key={i} className="mt10px">
                          <td className=" greycolor505050500 fs18px mt20px">
                            {item?.name}
                          </td>
                          <td className=" greycolor505050500 fs18px mt20px">
                            {item?.version}
                          </td>
                          <td className="center-text ml5px greycolor505050500  mt20px  fs18px">
                            {moment(item?.createdAt).format("DD-MM-YYYY ")}
                          </td>
                          <td className="center-text ml5px greycolor505050500  mt20px  fs18px">
                            {moment(item?.createdAt).format(" HH:mm:ss")}
                          </td>
                          <td className="center-text greycolor505050500 fs18px mt20px">
                            {/* <Button>delete</Button> */}
                            <ConfrimDeleteDialog
                              data={item}
                              ApkVersionList={ApkVersionList}
                            />

                            {/* delete */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ minHeight: "35vh" }}
                >
                  {apkList?.length < 1 && (
                    <Typography
                      align="center"
                      className=" greycolor505050500 fs18px mt20px"
                    >
                      No APKs uploaded yet.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
    </>
  );
}
