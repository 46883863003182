import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { Bar } from "react-chartjs-2";
import { Button, Grid, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import axiosInstance from "../../../../../../../../api/axiosInstance";

// temp
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    xAxes: [{ stacked: true }],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 24,
        },
        stacked: false,
      },
    ],
  },
};

const AnalyticsTab = (props) => {
  const [dateType, setDateType] = useState("orders");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [graphData, setGraphData] = useState(null);
  const handleData = (data, datatype) => {
    setDate(moment(data).format("YYYY-MM-DD"));
  };
  const timesArray = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];
  const billboardId = props?.billBoardID;
  //

  console.log("barChartActivetime", props);

  const timeInHour = [
    "12:00 Am",
    "1:00 Am",
    "2:00 Am",
    "3:00 Am",
    "4:00 Am",
    "5:00 Am",
    "6:00 Am",
    "7:00 Am",
    "8:00 Am",
    "9:00 Am",
    "10:00 Am",
    "11:00 Am",
    "12:00 Pm",
    "1:00 Pm",
    "2:00 Pm",
    "3:00 Pm",
    "4:00 Pm",
    "5:00 Pm",
    "6:00 Pm",
    "7:00 Pm",
    "8:00 Pm",
    "9:00 Pm",
    "10:00 Pm",
    "11:00 Pm",
  ];
  const [datasets, setDataSets] = useState([]);
  const data = {
    labels: timeInHour,
    datasets: datasets,
  };

  const getGraphdata = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getUsedSlotAnalyticsByBillId?billboardId=${billboardId}&date=${date}&filterBy=${dateType}`
      );
      if (res.status == 200 || res.status == 204) {
        setGraphData(res?.data?.data);
      }
    } catch (err) {
      console.log("error from getGraphdata", err);
    }
  };
  useEffect(() => {
    if (!date) {
      return;
    }
    getGraphdata();
  }, [date, dateType]);

  let acceptedData = [];
  let viewsdata = [];
  let amountData = [];

  useEffect(() => {
    for (let i = 0; i < timesArray.length; i++) {
      let index = graphData?.findIndex((item) => item._id === timesArray[i]);
      if (index >= 0) {
        let obj = {
          _id: timesArray[i],
          orderCounts: graphData[index].orderCounts,
        };
        acceptedData.push(obj);
      } else {
        let obj = {
          _id: timesArray[i],
          orderCounts: 0,
        };
        acceptedData.push(obj);
      }
      if (index >= 0) {
        let obj = {
          _id: timesArray[i],
          viewsCount: graphData[index].views,
        };
        viewsdata.push(obj);
      } else {
        let obj = {
          _id: timesArray,
          viewsCount: 0,
        };
        viewsdata.push(obj);
      }

      if (index >= 0) {
        let obj = {
          _id: timesArray[i],
          amountCount: graphData[index].totalAmount,
        };
        amountData.push(obj);
      } else {
        let obj = {
          _id: timesArray,
          amountCount: 0,
        };
        amountData.push(obj);
      }
    }
  }, [graphData]);
  useEffect(() => {
    if (dateType === "views") {
      setDataSets([
        {
          label: "Views",
          data: viewsdata.map((a, i) => a?.viewsCount),
          borderColor: "#fed0eeb3",
          backgroundColor: "#fed0eeb3",
          stack: "Stack 1",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (dateType === "amounts") {
      setDataSets([
        {
          label: "Amount",
          data: amountData.map((a, i) => a?.amountCount),
          borderColor: "#fed0eeb3",
          backgroundColor: "#fed0eeb3",
          stack: "Stack 1",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else {
      setDataSets([
        {
          label: "Order Counts",
          data: acceptedData.map((a, i) => a?.orderCounts),
          borderColor: "#fed0eeb3",
          backgroundColor: "#fed0eeb3",
          stack: "Stack 1",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    }
  }, [graphData]);
  console.log("graphdata", graphData);
  return (
    <>
      <Grid container sx={{ height: "100%" }}>
        <Grid item xs={12}>
          <div>
            {" "}
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              {" "}
              <Grid item>
                <Grid container gap="10px" direction="column">
                  {" "}
                  <Typography className="gray5A5A5Atypo500 fs20px">
                    Hourly Slot Analytics
                  </Typography>{" "}
                  <Grid item>
                    {" "}
                    <Grid container gap="10px">
                      <Button
                        className={
                          dateType === "orders"
                            ? "select_date_filter_class"
                            : "filterButtonDesign"
                        }
                        onClick={() => setDateType("orders")}
                      >
                        {" "}
                        Orders
                      </Button>
                      <Button
                        className={
                          dateType === "views"
                            ? "select_date_filter_class"
                            : "filterButtonDesign"
                        }
                        onClick={() => setDateType("views")}
                      >
                        {" "}
                        Views
                      </Button>
                      <Button
                        className={
                          dateType === "amounts"
                            ? "select_date_filter_class"
                            : "filterButtonDesign"
                        }
                        onClick={() => setDateType("amounts")}
                      >
                        {" "}
                        Amounts
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Date"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputFormat="dd/MM/yyyy"
                    value={date}
                    onChange={(e) => {
                      handleData(e, "date");
                    }}
                    maxDate="today"
                    renderInput={(params) => (
                      <TextField {...params} style={{ width: "150px" }} />
                    )}
                    disableFuture={true}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Bar options={options} data={data} />
      </Grid>
    </>
  );
};

export default AnalyticsTab;
