import PropTypes from "prop-types";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const TabPanelList = ({ value, permissions, panels }) => {
  return (
    <div>
      {panels
        ?.filter((panel) => permissions[panel.permission])
        .map((panel, index) => {
          return (
            <TabPanel key={panel.permission} value={value} index={index}>
              {panel.component}
            </TabPanel>
          );
        })}
    </div>
  );
};

export default TabPanelList;
