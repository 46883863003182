import React, { useEffect, useState } from "react";
//**  Mui Component Import
import PropTypes from "prop-types";
import { Snackbar, Alert, Tabs, Tab } from "@mui/material";
//** Core Component Import
import axiosInstance from "../../../../../../api/axiosInstance";
import FirstTab from "./BurnerAdTab/ApprovedTab";
import SecondTab from "./BurnerAdTab/PendingTab";
import ThirdTab from "./BurnerAdTab/RejectedTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddBurnerAd = ({ billBoardData }) => {
  const [resBurnerAds, setResBurnerAds] = useState(null);
  const [loading, setLoading] = useState(false);

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [snackerropen, setSnackerropen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [allBurnerKey, setallBurnerKey] = useState();

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setResBurnerAds(null);
    setValue(newValue);
  };
  /////////////// change burner ads status ////////////

  const getBurnerAd = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/billboard/getBurnerAdByMacId/${
          billBoardData?.deviceId?.macId
        }?status=${value === 0 ? 0 : value === 2 ? -1 : value === 1 ? 1 : ""}`
      );
      if (res.status == 200 || res.status == 201) {
        console.log("resssssssssssss", res);
        setLoading(false);

        setResBurnerAds(res?.data?.msg);
        setallBurnerKey(res?.data?.msg?.map((item) => item?.Key));
      } else {
        //.log("error getting burnerVdo res", res.msg);
        setLoading(false);
      }
    } catch (error) {
      //.log(error, "error");
    }
  };

  const deleteBurnerAd = async (key) => {
    setLoading(true);
    try {
      const res = await axiosInstance.delete(
        `/api/billboard/deleteBurnerAdByKey?key=${key}`
      );
      if (res.status == 200 || res.status == 201) {
        setSnackOpen(true);
        setSnackMsg("Video Has been deleted");
        setLoading(false);
        getBurnerAd();
        handleShowSnackbar("success");
      } else {
        setSnackErrMsg(res.msg);
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (error) {
      //.log(error, "error");
      handleShowSnackbar("error");
      setLoading(false);
    }
  };
  //.log("billBoardData?.deviceId?.macId", !!billBoardData?.deviceId);

  const deleteAllBurnerAd = async (key) => {
    setLoading(true);
    let body = {
      key: key,
    };
    try {
      const res = await axiosInstance.post(
        `/api/billboard/deleteAllBurnerAd`,
        body
      );
      if (res.status == 200 || res.status == 201) {
        setSnackOpen(true);
        setSnackMsg("Videos Has been deleted");
        setLoading(false);
        getBurnerAd();
        handleShowSnackbar("success");
      } else {
        setSnackErrMsg("Something goes wrong");
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (error) {
      //.log(error, "error");
      handleShowSnackbar("error");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!billBoardData?.deviceId?.macId) {
      getBurnerAd();
    }
  }, [billBoardData]);

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={severity}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={severity}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tabs
        value={value}
        onChange={handleChange}
        className="Tab-center"
        aria-label="basic tabs example"
      >
        <Tab
          label="Approved"
          {...a11yProps(0)}
          className="Order_TabChangeDevice"
        />
        <Tab
          label="Pending"
          {...a11yProps(1)}
          className="Order_TabChangeDevice"
        />
        <Tab
          label="Rejected"
          {...a11yProps(1)}
          className="Order_TabChangeDevice"
        />
      </Tabs>
      <TabPanel value={value} index={0} className="superAdmin_profile_tab">
        <FirstTab
          loadingBurners={loading}
          setSnackOpen={setSnackOpen}
          setSnackMsg={setSnackMsg}
          setSnackErrMsg={setSnackErrMsg}
          setSnackerropen={setSnackerropen}
          billBoardData={billBoardData}
          resBurnerAds={resBurnerAds}
          getBurnerAd={getBurnerAd}
          handleShowSnackbar={handleShowSnackbar}
          deleteBurnerAd={deleteBurnerAd}
          allBurnerKey={allBurnerKey}
          deleteAllBurnerAd={deleteAllBurnerAd}
        />
      </TabPanel>
      <TabPanel value={value} index={1} className="superAdmin_profile_tab">
        <SecondTab
          loadingBurners={loading}
          resBurnerAds={resBurnerAds}
          billBoardData={billBoardData}
          getBurnerAd={getBurnerAd}
          deleteBurnerAd={deleteBurnerAd}
        />
      </TabPanel>
      <TabPanel value={value} index={2} className="superAdmin_profile_tab">
        <ThirdTab
          loadingBurners={loading}
          resBurnerAds={resBurnerAds}
          billBoardData={billBoardData}
          getBurnerAd={getBurnerAd}
          deleteBurnerAd={deleteBurnerAd}
        />
      </TabPanel>
    </>
  );
};
export default AddBurnerAd;
