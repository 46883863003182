import * as React from "react";
import {
  Grid,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { FETCH_URL } from "../../../../fetchIp";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "orange" }} {...other}>
      {children}
      <Typography className="whitecolortypo500 fs18px">Warning</Typography>{" "}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AlertDialog({ deviceData, getAllDevice }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteDevice = async () => {
    try {
      let token = JSON.parse(localStorage.getItem("userData")).token;
      const response = await fetch(`${FETCH_URL}/api/device/deleteDevice`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          deviceId: deviceData._id,
        }),
      });
      let res = await response.json();
      if (response.ok) {
        getAllDevice();
        console.log("deleting device response ===>", res.msg);
      } else {
        console.log("Error From Server ==> ", res.msg);
      }
    } catch (error) {
      console.log("Error during   deleting device => ", error.message);
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <DeleteIcon className="danger_color" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle> </BootstrapDialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "20px",
          }}
        >
          <Grid container gap="5px" direction="column">
            <Typography className="greycolor505050500">
              To delete this device, you have to unassign billboard first.
            </Typography>
            <Typography className="greycolor505050500">
              Steps to unassign billboard
            </Typography>
            <Typography className="greycolor505050500">
              <li>Click on the device name.</li>
              <li>Click on the assign tab.</li>
              <li>Remove billboard</li>
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              background:
                "linear-gradient(93.22deg,#e141c3 -4.65%,#b937fa 53.05%,#6906c3 111.04%) !important",
              color: "#fff !important",
            }}
            className="diallog-no-btn"
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
