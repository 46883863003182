// Import necessary dependencies
import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import Imgbg from "./../../assets/Img/bgLogin.jpg";

// Create the LoginCard component
const LoginCard = () => {
  // State to store email and password values
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Function to handle login button click
  const handleLogin = () => {
    // Perform login logic here (e.g., send data to server)
    console.log("Email:", email);
    console.log("Password:", password);
  };

  return (
    <div
      style={{
        // backgroundImage: `url(${Imgbg})`, // Set the background image using Imgbg variable
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#e6e7ed",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.2)", // Light white with transparency
          backdropFilter: "blur(10px)", // Apply a blur effect for transparency
          borderRadius: "8px", // Add rounded corners
          maxWidth: "400px", // Set the maximum width of the card
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
        }}
      >
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Login
          </Typography>
          <TextField
            label="UID"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogin}
            sx={{ marginTop: "20px", padding: "8px 10px" }}
            className="purple_btn_primary text_transform_inherit"
          >
            Login
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginCard;
