import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Typography,
  Button,
  Snackbar,
  Alert,
  CardContent,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

// delete later
import axiosInstance from "../../../../../../api/axiosInstance";
import RevenueBarAnalystic from "./Analytics/RevenueBarAnalystic";
import OrderVise from "./Analytics/OrderVise";
import BillBoardViseTable from "./Analytics/BillBoardVise";
import AdvertiseViseTable from "./Analytics/AdvertiseViseTable";

import totalSpent from "../../../../../../assets/Img/rupee_2448368 1.png";
import offerPer from "../../../../../../assets/Img/offerPercentage.png";
import offerrupee from "../../../../../../assets/Img/offerRupee.png";

const Revenue = (props) => {
  const {
    states: { profile, forOther },
  } = props;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectDate, setSelectDate] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [amountType, setamountType] = useState("margin");
  const [addToWallet, setAddToWallet] = useState(false);
  const [getAllTimeRevenuedata, setgetAllTimeRevenuedata] = useState([]);
  const [getAllTimeThisMonthRevenue, setgetAllTimeThisMonthRevenue] = useState(
    []
  );
  const [getAllTimeLastMonthRevenue, setgetAllTimeLastMonthRevenue] = useState(
    []
  );
  const [pastvsthis, setpastvsthis] = useState(null);

  // snackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [dataType, setDataType] = useState("Overview");
  const userID = props?.states.userID;

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const setAddToWalletPageHandler = (bool) => {
    setAddToWallet(bool);
  };
  let adminId = JSON.parse(localStorage.getItem("userData")).user._id;
  console.log("userID", userID);
  useEffect(() => {
    getAllTimeRevenue();
    getAllTimeRevenueThisMonth();
    getAllTimeRevenueLastMonth();
  }, []);
  /////////////////// api all to get total data  //////////////////////////
  const getAllTimeRevenue = async () => {
    try {
      const link = forOther
        ? `api/payment/getAllTimeRevenueByUserId1`
        : `api/payment/getAllTimeRevenueByUserId`;

      const res = await axiosInstance.get(
        `${link}?amountType=${amountType}&userId=${userID}`
      );
      if (res.status == 200) {
        console.log("success res after getAllTimeRevenue api", res);
        setgetAllTimeRevenuedata(res?.data?.data);
        setLoading(false);
      } else {
        console.log("error res after getAllTimeRevenue api", res);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getAllTimeRevenueThisMonth = async () => {
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
    try {
      const link = forOther
        ? `api/payment/getRevenueByDateRangeByUserId1`
        : `api/payment/getRevenueByDateRangeByUserId`;

      const res = await axiosInstance.get(
        `${link}?amountType=${amountType}&userId=${userID}&startDate=${startOfMonth}&endDate=${endOfMonth}`
      );
      if (res.status == 200) {
        console.log("success res after setgetAllTimeThisMonthRevenue api", res);
        setgetAllTimeThisMonthRevenue(res?.data?.data);
        setLoading(false);
      } else {
        console.log("error res after setgetAllTimeThisMonthRevenue api", res);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const getAllTimeRevenueLastMonth = async () => {
    const lastmonthfirstdate = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");

    const lastmonthlastdate = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");

    try {
      const res = await axiosInstance.get(
        `api/payment/getRevenueByDateRangeByUserId?amountType=${amountType}&userId=${userID}&startDate=${lastmonthfirstdate}&endDate=${lastmonthlastdate}`
      );
      if (res.status == 200) {
        console.log("success res after setgetAllTimeThisMonthRevenue api", res);
        setgetAllTimeLastMonthRevenue(res?.data?.data);
        setLoading(false);
      } else {
        console.log("error res after setgetAllTimeThisMonthRevenue api", res);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  console.log("last month revenue", getAllTimeLastMonthRevenue);

  // ////////////////////////////// selectBar date details //////////////////////////
  const handleChangeDate = (event) => {
    setSelectDate(event.target.value);
  };
  let theMonths = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );
  let now = new Date();
  let arr = [];
  for (let i = 0; i < 3; i++) {
    let future = new Date(now.getFullYear(), now.getMonth() - i, 1);
    arr.push(future);
  }
  // ////////////////////////////// selectBar date details //////////////////////////

  //getTransactionApi
  useEffect(() => {
    setpastvsthis(
      getAllTimeThisMonthRevenue[0]?.amount -
        getAllTimeLastMonthRevenue[0]?.amount
    );
  }, [getAllTimeLastMonthRevenue, getAllTimeThisMonthRevenue]);

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={8000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>{" "}
      <div className="mb20px">
        <Grid container justifyContent="center" gap="10px">
          <Button
            className={
              dataType === "Overview"
                ? "select_date_filter_class_revenue"
                : "filterButtonDesign"
            }
            onClick={() => setDataType("Overview")}
          >
            {" "}
            Overview
          </Button>{" "}
          <Button
            className={
              dataType === "OrderVise"
                ? "select_date_filter_class_revenue"
                : "filterButtonDesign"
            }
            onClick={() => setDataType("OrderVise")}
          >
            Order Vise
          </Button>{" "}
          <Button
            className={
              dataType === "BillboardVise"
                ? "select_date_filter_class_revenue"
                : "filterButtonDesign"
            }
            onClick={() => setDataType("BillboardVise")}
          >
            {" "}
            Billboard Vise
          </Button>{" "}
          <Button
            className={
              dataType === "AdvertiseeVise"
                ? "select_date_filter_class_revenue"
                : "filterButtonDesign"
            }
            onClick={() => setDataType("AdvertiseeVise")}
          >
            {" "}
            Advertisee Vise
          </Button>{" "}
        </Grid>
      </div>
      {dataType === "Overview" && (
        <>
          <Grid container spacing={3} className="mt5px" justifyContent="center">
            <Grid item md={4} lg={2.2} sm={6} className="cursorpoint">
              <Card className="dashcard-home-start ">
                <CardContent className="card-content">
                  <Grid
                    container
                    justifyContent="flex-start"
                    style={{ width: "90px" }}
                  >
                    <img
                      src={totalSpent}
                      alt="walletImage"
                      style={{ width: "55%" }}
                    />
                  </Grid>

                  <Typography className="lightgreycolortypo fs14px pt10px  ">
                    All time revenue
                  </Typography>
                  <Typography className="blackcolor484848-700  fs1-5rem  pt10px    ">
                    &#x20b9; {getAllTimeRevenuedata[0]?.amount.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} lg={2.2} sm={6} className="cursorpoint">
              <Card className="dashcard-home-start ">
                <CardContent className="card-content">
                  <Grid
                    container
                    justifyContent="flex-start"
                    style={{ width: "90px" }}
                  >
                    <img
                      src={totalSpent}
                      alt="walletImage"
                      style={{ width: "55%" }}
                    />
                  </Grid>
                  <Typography className="lightgreycolortypo fs14px pt10px ">
                    This Month revenue
                  </Typography>
                  <Typography className="blackcolor484848-700  fs1-5rem    pt10px  ">
                    &#x20b9;{getAllTimeThisMonthRevenue[0]?.amount.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} lg={2.2} sm={6} className="cursorpoint">
              <Card className="dashcard-home-start ">
                <CardContent className="card-content">
                  <Grid
                    container
                    justifyContent="flex-start"
                    style={{ width: "90px" }}
                  >
                    <img
                      src={totalSpent}
                      alt="walletImage"
                      style={{ width: "55%" }}
                    />
                  </Grid>

                  <Typography className="lightgreycolortypo fs14px pt10px">
                    Previous Month’s Revenue
                  </Typography>
                  <Typography className="blackcolor484848-700  fs1-5rem    pt10px  ">
                    &#x20b9; {getAllTimeLastMonthRevenue[0]?.amount.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} lg={5.2} sm={6} className="cursorpoint">
              <Card className="dashcard-home-start-revenue ">
                <CardContent className="card-content">
                  <Typography className="lightgreycolortypo fs14px ">
                    Revenue Analysis
                  </Typography>

                  <Grid container justifyContent="space-between" rowGap="20px">
                    <Grid item xs={12} md={4}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                      >
                        {" "}
                        <Typography className="lightgreycolortypo fs14px pt10px">
                          Past Month
                        </Typography>
                        <Typography className="lightgreycolortypo fs14px pt10px">
                          VS
                        </Typography>
                        <Typography className="lightgreycolortypo fs14px pt10px">
                          This Month
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      container
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      {" "}
                      <Grid
                        container
                        justifyContent="center"
                        style={{ width: "80px" }}
                      >
                        <img
                          src={offerrupee}
                          alt="walletImage"
                          style={{ width: "55%" }}
                        />
                      </Grid>
                      <Typography className="lightgreycolortypo fs14px ">
                        In Amount
                      </Typography>
                      <Grid>
                        <Typography className="blackcolor484848-700  fs1-5rem     ">
                          &#x20b9; &#x20b9;{" "}
                          {pastvsthis ? (
                            <>{parseInt(pastvsthis).toFixed(2)}</>
                          ) : (
                            0
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      container
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      {" "}
                      <Grid
                        container
                        justifyContent="center"
                        style={{ width: "80px" }}
                      >
                        <img
                          src={offerPer}
                          alt="walletImage"
                          style={{ width: "55%" }}
                        />
                      </Grid>
                      <Typography className="lightgreycolortypo fs14px ">
                        In Percentage
                      </Typography>
                      <Grid>
                        <Typography className="blackcolor484848-700  fs1-5rem">
                          {(
                            ((getAllTimeThisMonthRevenue[0]?.amount -
                              getAllTimeLastMonthRevenue[0]?.amount) /
                              getAllTimeLastMonthRevenue[0]?.amount) *
                            100
                          ).toFixed(2)}
                          % {""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} style={{ margin: "40px 0" }}>
            <RevenueBarAnalystic userID={userID} />
          </Grid>
        </>
      )}
      {dataType === "OrderVise" && (
        <OrderVise dataType={dataType} userID={userID} />
      )}
      {dataType === "BillboardVise" && (
        <BillBoardViseTable dataType={dataType} userID={userID} />
      )}
      {dataType === "AdvertiseeVise" && <AdvertiseViseTable userID={userID} />}
    </>
  );
};

export default Revenue;
