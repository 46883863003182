import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import OrderStatus from "./OrderStatus";
import TimeSlots from "./TimeSlots";
import FilterDate from "./Date";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const FilterTab = ({
  handleFilterData,
  timeSlot,
  type,
  startDate,
  endDate,
  clearFilter,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // tab states
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // tab states
  return (
    <div>
      <Button
        onClick={handleClickOpen}
        sx={{
          border: "1px solid #ddd",
          borderRadius: "8px",
          height: "100%",
          color: "#484848",
          padding: "10px 20px",
          textTransform: "inherit",
        }}
      >
        <FilterAltIcon sx={{ marginRight: "8px" }} />
        Filter
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="filter_dialog"
      >
        <DialogContent className="side_tab_filter">
          <DialogActions
            className="side_tab_filter"
            style={{ marginBottom: "20px" }}
          >
            <CloseIcon
              onClick={handleClose}
              style={{ cursor: "pointer", marginLeft: "20px" }}
            />
            <Typography className="blackcolor484848 fs16px">
              Sort by{" "}
            </Typography>
            <Button onClick={clearFilter} className="cle_fltr_btn">
              Clear Filter
            </Button>
          </DialogActions>
          <div
            style={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: 224,
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              // sx={{
              //   borderRight: 1,
              //   borderColor: "divider",
              // }}
              className="side_fiter_tab"
            >
              <Tab label="Date" {...a11yProps(0)} className="side_tab_typo" />
              <Tab
                label="Time Slots"
                className="side_tab_typo"
                {...a11yProps(1)}
              />
              <Tab
                label="Order Status"
                className="side_tab_typo"
                {...a11yProps(2)}
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <FilterDate
                handleFilterData={handleFilterData}
                startDate={startDate}
                endDate={endDate}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TimeSlots
                handleFilterData={handleFilterData}
                timeSlot={timeSlot}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <OrderStatus handleFilterData={handleFilterData} type={type} />
            </TabPanel>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FilterTab;
