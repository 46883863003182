import React from "react";
import { useFilterController, setFilterData } from "../Context/filter";
import { useState } from "react";
import axiosInstance from "../api/axiosInstance";
import { useEffect } from "react";
const useBillboardCamp = (props) => {
  const multi = props?.multiTimes;
  console.log("camp>>>>>>>>>>>>>", props);

  const [loading, setLoading] = useState(false);
  // context state
  const [controller, dispatch] = useFilterController();
  const { filterData } = controller;
  // infinite loading state
  const [isFetching, setIsFetching] = React.useState(
    Array.from({ length: 12 })
  );
  // billboard states
  const [currentIndexOfSortBy, setCurrentIndexOfSortBy] =
    useState("newlyAdded");

  // const [district, setDistrict] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [smartBillboard, setSmartBillboard] = useState([]);
  const [location, setLocation] = React.useState("All");
  const [selectedFilter, setSelectedFilter] = useState({
    place: "",
    smartBillboard: [],
    venue: [],
    country: [],
    state: [],
    size: props.type == "campaign" ? "1080X1920" : "1080X1920",
    price: [],
    district: props.type == "campaign" ? "" : "",
  });
  const [page, setPage] = useState(1);
  const [billboardLength, setBillboardLength] = useState(0);
  const [filterBadge, setFilterBadge] = useState(0);
  const [dataBySearch, setDataBySearch] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000);
  const [firstVenue, setFirstVenue] = useState("all");
  const [filterDataCheck, setFilterDataCheck] = useState(true);
  // handlePrice function
  const handlePriceHandler = (one, two) => {
    setMinPrice(one);
    setMaxPrice(two);
  };
  // clear billboard
  const clearVenueFilter = () => {
    setFirstVenue("");
  };
  const clearAllBillboard = () => {
    setSmartBillboard([]);
    setPage(1);
  };
  // handleVenue function
  const handleSetFirstVenue = (venue) => {
    if (firstVenue == venue) {
      setFirstVenue("");
      filter("all", "place");
    } else if (firstVenue == "Outdoor" && firstVenue == "Indoor") {
      setFirstVenue("all");
      filter("all", "place");
    } else {
      setFirstVenue(venue);
      filter(venue, "place");
    }
    if (venue == "all") {
      let temp = filterData;
      temp.venue = [];
      let temp2 = JSON.stringify(temp);
      console.log(">>>>??????>>", JSON.parse(temp2));
      setFilterData(dispatch, JSON.parse(temp2));
    }
  };
  // filter
  const filter = (item, key) => {
    let newState = selectedFilter;

    switch (key) {
      case "place":
        newState.place = item;
        newState = JSON.stringify(newState);
        setSelectedFilter(JSON.parse(newState));

        break;
      case "smartBillboard":
        // console.log('filter>', item.name, key);
        let temp = selectedFilter.smartBillboard;
        if (temp.length > 0) {
          let exits = temp.find((name) => name == item.name);
          if (exits) {
            temp = temp.filter((name) => name != item.name);
          } else {
            temp.push(item.name);
          }
        } else {
          temp.push(item.name);
        }
        newState.smartBillboard = temp;
        newState = JSON.stringify(newState);
        setSelectedFilter(JSON.parse(newState));

        break;
      case "country":
        console.log("country>", item.name, key);
        let tempC = selectedFilter.country;
        if (tempC.length > 0) {
          let exits = tempC.find((name) => name == item.name);
          if (exits) {
            tempC = tempC.filter((name) => name != item.name);
          } else {
            tempC.push(item.name);
          }
        } else {
          tempC.push(item.name);
        }
        newState.country = tempC;
        newState = JSON.stringify(newState);
        console.log("hii");
        setSelectedFilter(JSON.parse(newState));

        break;
      case "district":
        newState.district = item;

        newState = JSON.stringify(newState);
        setSelectedFilter(JSON.parse(newState));
        break;

      case "state":
        console.log("filter>", item.name, key);
        let tempS = selectedFilter.state;
        if (tempS.length > 0) {
          let exits = tempS.find((name) => name == item.name);
          if (exits) {
            tempS = tempS.filter((name) => name != item.name);
          } else {
            tempS.push(item.name);
          }
        } else {
          tempS.push(item.name);
        }
        newState.state = tempS;
        newState = JSON.stringify(newState);
        setSelectedFilter(JSON.parse(newState));

        break;

      case "size":
        console.log("filter>", item.name, key);
        let tempSZ = [];
        if (tempSZ.length > 0) {
          let exits = tempSZ.find((name) => name == item.name);
          if (exits) {
            tempSZ = tempSZ.filter((name) => name != item.name);
          } else {
            tempSZ.push(item.name);
          }
        } else {
          tempSZ.push(item.name);
        }
        newState.size = tempSZ;
        newState = JSON.stringify(newState);
        // console.log('>>>>>', newState);
        setSelectedFilter(JSON.parse(newState));

        break;
      case "price":
        newState.price = item;
        newState = JSON.stringify(newState);
        setSelectedFilter(JSON.parse(newState));
        break;

      default:
        break;
    }
  };
  // filter badge variable and function
  const checkFilter =
    selectedFilter?.venue?.length > 0 ||
    selectedFilter?.place?.length > 0 ||
    String(minPrice)?.length > 0 ||
    String(maxPrice)?.length > 0 ||
    selectedFilter.state?.length > 0 ||
    selectedFilter.smartBillboard?.length > 0;
  const checkFilterCountHandler = () => {
    let counting = 0;
    if (selectedFilter?.venue?.length > 0) counting++;
    if (firstVenue.length > 0 && selectedFilter?.place?.length > 0) counting++;
    if (minPrice + maxPrice !== 100000) counting++;
    if (selectedFilter.state?.length > 0) counting++;
    if (selectedFilter.smartBillboard?.length > 0) counting++;
    setFilterBadge(counting);
  };
  // Api calling
  const getFilteredBillboards = async (
    currentIndexOfSortBy,
    filterPageNum,
    district
  ) => {
    setDataBySearch(false);
    setLoading(true);
    if (checkFilter) {
      checkFilterCountHandler(); //filter badge variable and function
    }
    let body;
    if (props.type == "billboard") {
      body = {
        venueTags: selectedFilter.venue,
        limit: "12",
        page: filterPageNum ? 1 : page,
        sort: currentIndexOfSortBy,
        locationType:
          selectedFilter.place && selectedFilter.place != "all"
            ? selectedFilter.place
            : "",
        billboardType: selectedFilter.smartBillboard,
        state: selectedFilter.state,
        minPrice: minPrice,
        maxPrice: maxPrice,
        billboardSize: selectedFilter?.size?.[0]?.split(" ")?.[0],
        district:
          selectedFilter?.state?.length > 0 ? "All" : selectedFilter?.district,
      };
    } else if (props.type == "campaign") {
      body = {
        venueTypes: selectedFilter.venue,
        limit: "50",
        // sort: "newlyAdded",
        page: filterPageNum ? 1 : page,
        locationType:
          selectedFilter.place && selectedFilter.place !== "all"
            ? selectedFilter.place
            : "All",
        // billboardType: selectedFilter.smartBillboard,
        // state: selectedFilter.state,
        minPrice: minPrice,
        maxPrice: maxPrice,

        // billboardSize: selectedFilter?.size?.[0]?.split(" ")?.[0],
        // duration: props?.campData?.repeat,
        // startDate: props?.campData?.startdate,
        // endDate: props?.campData?.enddate,
        // timeSlot: new Date(props?.campData?.starttime)?.getHours(),
        district: selectedFilter?.district || "",
        orientation: selectedFilter.size,
        isTesting: true,
        multiTimes: multi,
        priceType: "LowToHigh",
        videoDuration: props.videoDuration,
        search: searchText ? searchText : "",
      };
    }
    try {
      const res = await axiosInstance.post(props?.url, body);
      console.log("res filter", res);
      if (res.status == 200 || res.status == 201) {
        setLoading(false);

        setSmartBillboard(res?.data?.data);

        setBillboardLength(res?.data?.lengthData);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error iin getFilteredBillboards", err);
    }
  };
  const searchBillboard = async (search, pageNumStart) => {
    setLoading(true);
    setDataBySearch(true);
    if (search?.trim()?.length === 0) {
      setBillboardLength(0);
      setPage(1);
      getFilteredBillboards(currentIndexOfSortBy, 1);
      return;
    }
    try {
      const res = await axiosInstance(
        `/api/billboard/searchbillboard?page=${
          pageNumStart ? pageNumStart : page
        }&limit=12&searchQuery=${search}`
      );
      console.log(res, "res");
      if (res.status == 200) {
        setLoading(false);
        // if (pageNumStart == 1) {
        //   setSmartBillboard(res?.data?.msg);
        // } else {
        //   setSmartBillboard((prev) => [...prev, ...res?.data?.msg]);
        // }
        setSmartBillboard(res?.data?.msg);
        setBillboardLength(res?.data?.lengthData);
        console.log("successFully gettig billboard response", res);
      } else {
        setLoading(false);
        console.log("error in gettig billboard response", res);
      }
    } catch (e) {
      setLoading(false);
      console.log("error", e);
    }
  };
  //   useEffect(() => {
  //     if (smartBillboard?.length === 10) {
  //       return;
  //     }
  //     getFilteredBillboards(currentIndexOfSortBy, 1);
  //   }, []);
  useEffect(() => {
    if (props.multiTimes) {
      getFilteredBillboards();
    }
  }, [props.multiTimes]);
  useEffect(() => {
    if (page > 1) {
      if (dataBySearch) {
        searchBillboard(searchText);
      } else {
        getFilteredBillboards(currentIndexOfSortBy);
      }
    }
  }, [page, dataBySearch]);
  useEffect(() => {
    if (filterDataCheck) {
      setFilterDataCheck(false);
      return;
    }
    let temp = selectedFilter;
    temp.venue = filterData.venue;
    setSelectedFilter(temp);

    if (filterData || minPrice || maxPrice || selectedFilter || firstVenue) {
      console.log("i am running ,min max");
      setPage(1);
      setSmartBillboard([]);
      getFilteredBillboards(currentIndexOfSortBy, 1);
      // setSearchText("");
    }
  }, [
    filterData,
    firstVenue,
    selectedFilter,
    minPrice,
    maxPrice,
    props.multiTimes,
    searchText,
  ]);
  const handleisFetching = () => {
    if (billboardLength && smartBillboard?.length !== billboardLength) {
      setTimeout(() => {
        setIsFetching(isFetching.concat(Array.from({ length: 12 })));
        setPage((prev) => prev + 1);
      }, 1000);
    }
  };
  return {
    handleSetFirstVenue,
    handleisFetching,
    handlePriceHandler,
    getFilteredBillboards,
    clearVenueFilter,
    filter,
    setPage,
    setSmartBillboard,
    setBillboardLength,
    searchBillboard,
    setSearchText,
    setSelectedFilter,
    setCurrentIndexOfSortBy,

    setIsFetching,
    clearAllBillboard,

    filterData,
    firstVenue,
    selectedFilter,
    minPrice,
    maxPrice,
    smartBillboard,
    isFetching,
    filterBadge,
    loading,
    searchText,
    currentIndexOfSortBy,
    billboardLength,
    currentIndexOfSortBy,
  };
};

export default useBillboardCamp;
