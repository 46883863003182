import * as React from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "#F00000" }} {...other}>
      {children}
      <Typography className="whitecolortypo500 fs18px">Alert</Typography>{" "}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: -2,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Alert_Resolution_dialogue({
  handleUploadimage,
  WithdrawMoneyfromWallet,
  filetype,
  videoLink,
  playUrl,
  videoDimensions,
  preferredResolution,
  timeSlot,
  currentTime,
  date,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (
      videoDimensions?.width == preferredResolution?.split("X")?.[0] ||
      Object.keys(videoDimensions)?.length == 0
    ) {
      playUrl ? WithdrawMoneyfromWallet(playUrl) : handleUploadimage();
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const disabledButton =
    date == moment(new Date()).format("YYYY-MM-DD") && currentTime > timeSlot
      ? true
      : filetype &&
        (filetype !== "url"
          ? videoLink?.trim()?.length == 0
          : videoLink?.trim()?.length > 0 && playUrl?.trim()?.length > 0)
      ? false
      : true;
  return (
    <div style={{ width: "100%" }}>
      <Button
        onClick={handleClickOpen}
        disabled={disabledButton}
        sx={{
          width: "100%",
          borderRadius: "8px !important",
          padding: "10px 8px !important",
        }}
        // className="order-purple-btn "
        className={disabledButton ? "disabledbtn" : "order-purple-btn "}
      >
        Proceed to payment
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle onClose={handleClose}> </BootstrapDialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "20px",
          }}
        >
          <Grid container gap="15px" direction="column">
            <Typography className="blackcolortypo500 fs20px">
              You have selected content whose resolution is lower than preferred
              resolution
            </Typography>
            <Divider></Divider>
            <Grid container gap="20px" direction="column">
              <Typography className="greycolor505050500">
                Preferred Resolution :{" "}
                <span className="blackcolortypo500">
                  {preferredResolution} pixels
                </span>
              </Typography>
              <Typography className="greycolor505050500">
                Selected Resolution :{" "}
                <span className="redcolortypo_500">
                  {videoDimensions?.width} x {videoDimensions?.height} pixels (
                  Your content will not fit whole screen )
                </span>
              </Typography>
            </Grid>

            <Divider></Divider>
          </Grid>
          <DialogActions>
            <Grid container justifyContent="center" gap="20px">
              <Typography className="blackcolortypo500 fs20px">
                Are you sure you want to continue?
              </Typography>
              <Grid container item xs={8} gap="15px">
                <Button
                  className="order-purple-btn width100"
                  onClick={() => {
                    playUrl
                      ? WithdrawMoneyfromWallet(playUrl)
                      : handleUploadimage();
                    handleClose();
                  }}
                >
                  Yes, I want to proceed to payment
                </Button>
                <Button
                  className="disabledbtn blackcolortypo500 width100"
                  sx={{ padding: "6px 0 !important" }}
                  onClick={handleClose}
                >
                  No, I want to upload {preferredResolution} content
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
