import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Button,
  Divider,
  Breadcrumbs,
  IconButton,
  CircularProgress,
  Dialog,
  Backdrop,
  DialogTitle,
  DialogContent,
  InputBase,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useLocation } from "react-router-dom";
import SideBar from "../../../../../../component/Sidebar/Sidebar";
import Upload from "../../../../../../assets/Img/upload.png";
import { FETCH_URL } from "../../../../../../fetchIp";
import axiosInstance from "../../../../../../api/axiosInstance";
import SuccessDialog from "../Dialog/Confirmation-Dialog's/SuceedFullDialog";
import WrongDialog from "../Dialog/Confirmation-Dialog's/WrongDialog";
import DurationEditDailog from "../Dialog/BillBoardPostDialog/DurationFromEditDialog";
import { RiCoupon2Line } from "react-icons/ri";
import ReactPlayer from "react-player";
import { v4 as uuidv4 } from "uuid";
import CouponDialogue from "./ActionDialogues/CouponDialogue";
import permissionUtils from "../../../../../../component/permissionUtils";

//
import Alert_Resolution_dialogue from "./ActionDialogues/Alert_Resolution_dialogue";
import PreviewDialogue from "./ActionDialogues/PreviewDialogue";
import SizeAndDurationDialogue from "./ActionDialogues/SizeAndDurationDialogue";
import AddWallet from "../../../../../../utils/Dialog/AddWalletDialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "#5FCA5D" }} {...other}>
      {children}
      <Typography className="whitecolortypo">Approve order </Typography>{" "}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BoardSelectDetail = () => {
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  const [currentTime, setCurrentTime] = React.useState(new Date().getHours());
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  // getting data from routing
  const location = useLocation();
  const { billBoardData, billboardId, duration } = location.state;
  const PrefieldData = location.state;
  const [billBoardArr, setBillBoardArr] = useState([
    billBoardData?.deviceId?.macId,
  ]);
  const [openDialogName, setOpenDialog] = React.useState(null);
  const [seeMore, setSeeMore] = useState(false);
  const [name, setName] = useState(PrefieldData?.adTitle);
  const [about, setAbout] = useState(PrefieldData?.aboutAd);
  const [typeNameL, setTypeNameL] = useState(PrefieldData?.type || "");
  const [date, setDate] = useState(PrefieldData?.date);
  const [repeatsec, setRepeatSec] = useState(PrefieldData?.repeat);
  const [durationD, setdurationD] = useState(PrefieldData?.duration);
  const [editduration, setEditDuration] = useState(duration);
  const [timeSlot, setTimeSlot] = useState(PrefieldData?.timeSlot);
  const [dateL, setDateL] = useState(PrefieldData?.date || "");
  const [timeL, setTimeL] = useState(PrefieldData?.time || "");
  const [errMsg, setErrMsg] = useState("");
  const [finalPrice, setFinalPrice] = useState(PrefieldData?.finalPrice || "");
  const [itemDetail, setItemDetail] = useState(
    PrefieldData?.billBoardData || ""
  );
  const [multiTimeSolts, setMultiTimeSolts] = useState();
  console.log(PrefieldData);
  const [reps, setReps] = useState([]);
  const [repetitionSolt, setRepetitionSolt] = useState(
    PrefieldData?.selectedSlot
  );
  const [subTotal, setTotal] = useState(
    itemDetail ? finalPrice * durationD : 0
  );
  const [subTotalGst, setSubTotalGst] = useState(
    (subTotal ? (subTotal * 18) / 100 : 0).toFixed(2)
  );
  const [file, setFile] = useState(null);
  const [filetype, setFileType] = useState(null);
  const [playVideo, setPlayVideo] = useState(true);
  const [playUrl, setPlayUrl] = useState("");
  const [mediafile, setMediafile] = useState(null);
  const [zipfilename, setZipfilename] = useState(null);
  const [timmedname, setTrimmedname] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [imagestring, setImagestring] = useState("");
  const [imageoriginalname, setImageoriginalname] = useState("");
  const [durationForVideo, setDuration] = useState(0);
  const [videoSize, setVideoSize] = useState(0);
  const [videoDimensions, setVideoDimensions] = useState({});
  const [videoSizeDialogue, setVideoSizeDialogue] = useState(false);
  const [imageSizeDialogue, setImageSizeDialogue] = useState(false);
  const [videoSizeErr, setVideoSizeError] = useState(null);
  const [goToAddWallet, setGoToAddWallet] = useState(false);
  // state for uploading video and image
  // states for wallet & amount

  // billBoardData?.basePrice * editduration * repeatsec +
  //   (billBoardData?.basePrice * editduration * repeatsec * 18) / 100 -
  //   disAmmount;

  const [totalAmount, setTotalAmount] = useState(null);
  const [disAmmount, setDisAmmount] = useState(null);
  const [wallletDetails, setWalletDetails] = useState();
  const [transactionData, setTransactionData] = useState();
  // states for wallet & amount
  // getting info from localStorage
  let userD = JSON.parse(localStorage.getItem("userData"));
  let userRole = JSON.parse(localStorage.getItem("userData")).user.userRole;
  let userID = JSON.parse(localStorage.getItem("userData")).user?._id;
  // getting info from localStorage
  // api calling
  const onChangeFile = (event) => {
    console.log("Check event", billBoardData?.billboardSize);
    setVideoSizeDialogue(false);
    setImageSizeDialogue(false);
    if (
      event.target.files[0].type == "video/mp4" ||
      event.target.files[0].type == "video/webm" ||
      event.target.files[0].type == "video/quicktime"
    ) {
      const video = document.createElement("video");
      video.addEventListener("loadedmetadata", () => {
        setDuration(video.duration);

        if (
          billBoardData?.billboardSize == "AdaniPotrait" &&
          video.videoWidth >= 1080
        ) {
          setDuration(0);
          setVideoSize(event.target.files[0]?.size);
          if (event.target.files[0]?.size / 1000000 > durationD * 2) {
            setVideoSizeDialogue(true);
            return;
          }
          setMediafile(event.target.files?.[0]);
          setFile(URL.createObjectURL(event.target.files[0]));
          setFileType(event.target.files[0].type);
          setVideoDimensions({
            width: video.videoWidth,
            height: video.videoHeight,
          });
        } else if (billBoardData?.billboardSize == "1920X1080") {
          setDuration(0);
          setVideoSize(event.target.files[0]?.size);
          if (event.target.files[0]?.size / 1000000 > durationD * 2) {
            setVideoSizeDialogue(true);
            return;
          }
          setMediafile(event.target.files?.[0]);
          setFile(URL.createObjectURL(event.target.files[0]));
          setFileType(event.target.files[0].type);
          setVideoDimensions({
            width: video.videoWidth,
            height: video.videoHeight,
          });
        } else if (billBoardData?.billboardSize == "1080X1920") {
          setDuration(0);
          setVideoSize(event.target.files[0]?.size);
          if (event.target.files[0]?.size / 1000000 > durationD * 2) {
            setVideoSizeDialogue(true);
            return;
          }
          setMediafile(event.target.files?.[0]);
          setFile(URL.createObjectURL(event.target.files[0]));
          setFileType(event.target.files[0].type);
          setVideoDimensions({
            width: video.videoWidth,
            height: video.videoHeight,
          });
        } else if (billBoardData?.billboardSize == "1920X1440") {
          setDuration(0);
          setVideoSize(event.target.files[0]?.size);
          if (event.target.files[0]?.size / 1000000 > durationD * 2) {
            setVideoSizeDialogue(true);
            return;
          }
          setMediafile(event.target.files?.[0]);
          setFile(URL.createObjectURL(event.target.files[0]));
          setFileType(event.target.files[0].type);
          setVideoDimensions({
            width: video.videoWidth,
            height: video.videoHeight,
          });
        } else {
          console.log("not consider this size");
          setVideoSizeError(
            "'Invalid video orientation. Required only portrait video"
          );
        }
      });
      const file = event.target.files[0];
      video.src = URL.createObjectURL(file);
      // setLoading(true);
      const zip = require("jszip")();
      let files = event.target.files;
      let Milliseconds =
        String(new Date().getFullYear()) +
        String(new Date().getMonth()) +
        String(new Date().getDate()) +
        String(new Date().getHours()) +
        String(new Date().getMinutes()) +
        String(new Date().getMilliseconds());
      let a = event.target.files[0].name.split(".")[0];
      // console.log("original name of the video ===> ", a);
      let trimmedstr = a
        .replace(/[&\/\\_#,^!-@+()$~%'":-?<>{}[\]\s+]/g, "")
        .concat(Milliseconds);
      //console.log("trimmed str => ", trimmedstr);
      setTrimmedname(`${userD.user._id}_${trimmedstr}`);
      // setZipfilename(a); //==> to get original name of the uploaded
      let lastExtensionLength = event.target.files[0].name?.split(".")?.length;

      let extension = ".".concat(
        event.target.files[0].name.split(".")?.[lastExtensionLength - 1]
      );
      console.log("Extension ===>", extension);
      trimmedstr = `${userD.user._id}_${trimmedstr}`;
      let filename = trimmedstr.concat(extension); //==> to set the uniquename  of the file
      console.log("Final Name of the File => ", filename);
      // for (let file = 0; file < event.target.files.length; file++) {
      //   zip.file(filename, files[file]);
      // }
      // zip
      //   .generateAsync({
      //     type: "blob",
      //     compression: "DEFLATE",
      //     compressionOptions: {
      //       level: 1,
      //     },
      //   })
      //   .then((data) => {
      //     console.log("//=== convertion to zip success ===//");
      //     setMediafile(data);
      //     setLoading(false);
      //   });
    }
    //=====================================================================================//
    else if (event.target.files[0].type == "image/jpeg") {
      if (event.target.files[0]?.size / 1000000 > 5) {
        setImageSizeDialogue(true);
        return;
      }
      const file = event.target.files[0];
      setFile(URL.createObjectURL(event.target.files[0]));
      setFileType(event.target.files[0].type);
      setLoading(false);
      console.log("filetype Image ==> ", event.target.files[0].type);
      setFileType(event.target.files[0].type); //==> getting File type of here
      let Milliseconds =
        String(new Date().getFullYear()) +
        String(new Date().getMonth()) +
        String(new Date().getDate()) +
        String(new Date().getHours()) +
        String(new Date().getMinutes()) +
        String(new Date().getMilliseconds());
      let a = event.target.files[0].name.split(".")[0];
      console.log("Image Oriiginal Name ===> ", a);
      setImageoriginalname(a);
      // props.data.handleCampaignData(a, "imageoriginalname");
      let trimmedstr = a
        .replace(/[&\/\\_#,^!-@+()$~%'":-?<>{}[\]\s+]/g, "")
        .concat(Milliseconds);
      console.log("Unique Image => ", `${userD?.user?._id}_${trimmedstr}`);
      setTrimmedname(`${userD?.user?._id}_${trimmedstr}`);
      // props.data.handleCampaignData(trimmedstr, "contentname");
      let extension = ".".concat(event.target.files[0].name.split(".")[1]);
      console.log("Image Extension ===>", extension);
      //Converting to Base 64 ===>

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        onLoad(reader.result);
      };
      const onLoad = (fileString) => {
        setImagestring(fileString);
      };
    }
  };

  const handleUploadimage = async () => {
    setLoading(true);
    console.log(filetype, "//------- handle Upload Media -------------//");

    if (
      (filetype == "video/webm" ||
        filetype === "video/mp4" ||
        filetype == "video/quicktime") &&
      // zipfilename &&
      filetype &&
      timmedname
    ) {
      console.log(mediafile, "mediafilemediafile");
      const formdata = new FormData();

      formdata.append("userid", userD.user._id);
      formdata.append("filetype", filetype);
      formdata.append("uniquename", timmedname); //===> uniquename
      formdata.append("macArr", JSON.stringify(billBoardArr));
      formdata.append("billboardId", billboardId);
      formdata.append("file", mediafile); //===> mediafile

      const response = await fetch(`${FETCH_URL}/api/mediaupload/uploadVideo`, {
        method: "POST",
        body: formdata,
      });
      let res = await response.json();
      console.log("response after mediaupload/addzip call =>", res);

      if (response.ok) {
        // BookTime(res.contentLink);
        WithdrawMoneyfromWallet(res?.contentLink);
        setLoading(false);

        console.log("Handle off response for video  ===>", res.msg);
      } else {
        setLoading(false);
        console.log("error in handle off ==> ", res.msg);
      }
    } else if (
      filetype === "image/jpeg" &&
      imageoriginalname &&
      filetype &&
      imagestring &&
      timmedname
    ) {
      const response = await fetch(
        `${FETCH_URL}/api/mediaupload/base64upload`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            userid: userD.user._id,
            imagename: imageoriginalname,
            filetype: filetype,
            imagestring: imagestring,
            uniquename: timmedname,
            macArr: billBoardArr,
            billboardId: billboardId,
          }),
        }
      );
      setLoading(false);
      let res = await response.json();
      console.log("response from mediaupload/base64upload =>", res);
      if (response.ok) {
        await WithdrawMoneyfromWallet(res?.contentLink);
        setLoading(false);
        console.log("Handle off response for image  ===>", res.msg);
      } else {
        setLoading(false);
        console.log("error in handle off ==> ", res.msg);
      }
    }
    // BookTime();
  };
  const CheckWalletandAddMoney = async () => {
    try {
      const res = await axiosInstance.post(`/api/payment/getWalletData`, {
        userId: userID,
      });
      console.log("gettwallet data responce", res);
      if (res.status == 200 || res.status == 201) {
        setWalletDetails(res.data.msg);
      }
    } catch (err) {
      console.log("error in get wallet data", err);
    }
  };

  const WithdrawMoneyfromWallet = async (contentLink) => {
    console.log(wallletDetails?._id, "i am running");
    try {
      let body = {};
      if (userRole === 2) {
        body = {
          multiTimes: multiTimeSolts,
          videoDuration: parseInt(durationD),
          billboardIds: [billBoardData?._id],
          amount: parseInt(totalAmount),
          message: "Advertisement Payment",

          // walletId: wallletDetails?._id,
        };
      } else {
        body = {
          multiTimes: multiTimeSolts,
          videoDuration: parseInt(durationD),
          billboardIds: [billBoardData?._id],
          // amount: parseInt(totalAmount),
          message: "Advertisement Payment",

          amount: "0",
        };
      }

      const res = await axiosInstance.post(
        `/api/payment/createTransaction`,
        body
      );
      console.log("make Transaction data responce", res);
      if (res.status == 200 || res.status == 201) {
        // if (Valid == "true") {
        //   const res1 = await axiosInstance.post(
        //     `/api/coupon/ChnageCouponStatusToUser `,
        //     {
        //       userId: localStorage.getItem("saps_id"),
        //       couponCode: couponCode,
        //     }
        //   );
        // }

        BookTime(contentLink, res?.data?.data?.amount, res?.data?.data?._id);
        // setOpenDialog("success");
        // setTransactionData(res?.data?.data?._id);
        {
          // filetype == "url"
          // BookTime(contentLink, res?.data?.data?.amount, res?.data?.msg?._id);
          // : handleUploadimage(
          //     res?.data?.msg?.debitedAmount,
          //     res?.data?.msg?._id
          //   );
        }
      }
    } catch (error) {
      console.log("error in make Transaction data responce", error);
      setErrMsg(error?.response?.data?.msg);
      setOpenDialog("reject");
    }
  };
  const BookTime = async (contentLink, debitAmount, transactionId) => {
    try {
      let body = {
        adTitle: name,
        aboutAd: about,
        contentLink: contentLink ? contentLink : videoLink,
        adType: typeNameL,
        multiTimes: multiTimeSolts,
        // timeslot: timeSlot,

        startscheduleDate: dateL,
        endscheduleDate: dateL,
        isCampaign: false,
        videoDuration: parseInt(durationD),

        billboardId: [billBoardData?._id],
        videoname: timmedname ? timmedname : videoLink,

        filetype: filetype,

        transactionId: transactionId,
      };

      console.log("Book Time data ==> ", body);
      let resp = await axiosInstance.post(
        "/api/billBoardBooking/bookingBoard",
        body
      );
      console.log("response from Book Time func() =>", resp);
      if (resp?.status === 200) {
        // WithdrawMoneyfromWallet(resp?.data?.data);
        // setOrderId(resp?.data?.data);
        setOpenDialog("success");
      } else {
        setOpenDialog("reject");
      }
    } catch (error) {
      setOpenDialog("reject");
      console.log("Error from Book Time", error);
      setErrMsg(error?.response?.data?.msg);
    }
  };

  const handleClose = () => {
    setGoToAddWallet(false);
  };
  useEffect(() => {
    CheckWalletandAddMoney();
  }, [goToAddWallet]);

  // api calling
  // check previewContainer
  const CheckPreview = () => {
    return (
      <>
        {durationForVideo < 120 &&
          Object.keys(videoDimensions)?.length > 0 &&
          videoDimensions?.width !==
            Number(billBoardData?.billboardSize?.split("X")?.[0]) && (
            <Grid container className="check_preview_container mt10px">
              <Grid item xs={12} sx={{ padding: "20px" }}>
                <Typography align="center" className="redcolortypo_500">
                  Warning : You have selected Video with lower resolution then
                  required.Please Select{" "}
                  {billBoardData?.billboardSize?.split("X")?.[0]}p quality video
                  of resolution {billBoardData?.billboardSize}
                </Typography>
              </Grid>
              <PreviewDialogue vdo={file} size={billBoardData?.billboardSize} />
            </Grid>
          )}
      </>
    );
  };
  const checkUrlHandler = (url) => {
    setPlayVideo(true);
    setPlayUrl(url);
  };
  const handlePlayerError = (error) => {
    if (error?.type === "error") {
      setPlayVideo(false);
      setVideoLink("");
      return;
    }
    setPlayVideo(true);
  };
  useEffect(() => {
    CheckWalletandAddMoney();
  }, []);
  useEffect(() => {
    if (disAmmount) {
      setTotalAmount(subTotal + subTotalGst - disAmmount);
    } else {
      setTotalAmount(subTotal + subTotalGst);
    }
  }, [disAmmount]);
  // useEffect(() => {
  //   if (durationForVideo > 120) {
  //     setVideoSizeDialogue(true);

  //     setFile(null);
  //     setFileType(null);
  //   }
  // }, [durationForVideo]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().getHours());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    Object.keys(repetitionSolt);
    let sum = 0;
    let timeSum = [];
    let repSum = [];
    let multi = [];
    repetitionSolt.forEach((item, index) => {
      sum += +item?.finalprice * +item?.repetition * +PrefieldData?.duration;

      timeSum.push(item?.repetition);

      repSum.push(item?.time);

      multi.push({
        date: dateL,
        startHour: item?.time,
        repetitions: item?.repetition,
      });
    });
    console.log("sum sum", sum, repSum, timeSum, "mullti timesolt ", multi);

    sum = +sum;
    setTotal(sum);
    setTimeL(repSum);
    setReps(timeSum);
    let gstCalculate = ((sum * 18) / 100).toFixed(2);
    gstCalculate = +gstCalculate;
    setSubTotalGst(gstCalculate);
    console.log(":::::::::::::::", sum + gstCalculate);
    setTotalAmount(sum + gstCalculate);
    setMultiTimeSolts(multi);
  }, [repetitionSolt]);

  return (
    <>
      {videoSizeDialogue && (
        <SizeAndDurationDialogue
          videoSizeDialogue={videoSizeDialogue}
          setVideoSizeDialogue={setVideoSizeDialogue}
          durationD={durationD}
        />
      )}
      {imageSizeDialogue && (
        <SizeAndDurationDialogue
          imageSizeDialogue={imageSizeDialogue}
          setImageSizeDialogue={setImageSizeDialogue}
        />
      )}
      <div className="width-90LR" style={{ marginBottom: "40px" }}>
        <div className="flex-class" style={{ marginTop: "90px" }}>
          {/* <Grid container direction="row" justifyContent="start">
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link
                  to="/admin/home"
                  className="linkcolor"
                  underline="hover"
                  key="1"
                >
                  <Typography className="purplecolortypo fs16px">
                    Dashboard
                  </Typography>
                </Link>
                ,
                <Link
                  to="/admin/modifybooking"
                  className="linkcolor"
                  underline="hover"
                  key="2"
                >
                  <Typography className="blackcolortypo fs16px">
                    Ad Overview
                  </Typography>
                </Link>
              </Breadcrumbs>
            </Grid> */}
        </div>
        <div
          className="box_shadow_border_radius"
          style={{ padding: "16px 24px 56px 24px", marginTop: "16px" }}
        >
          <div>
            <Grid container justifyContent="space-between" rowGap="20px">
              <Grid
                item
                md={6}
                sm={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginTop: "10px" }}
                >
                  <Typography className="greycolor505050400 fs25px">
                    Your Booking
                  </Typography>
                  {/* 
                    <DurationEditDailog
                      states={{
                        name,
                        setName,
                        about,
                        setAbout,
                        date,
                        setDate,
                        repeatsec,
                        setRepeatSec,
                        timeSlot,
                        setTimeSlot,
                        editduration,
                        setEditDuration,
                      }}
                      billBoardData={billBoardData}
                      billboardId={billboardId}
                    /> */}
                </Grid>
                <Grid
                  container
                  sx={{ borderTop: "1px solid #DBDBDB", margin: "10px 0" }}
                ></Grid>
                <Grid container md={12} sm={12}>
                  <Grid item md={12} sm={12}>
                    <Typography className="lightgreycolortypo  fs18px">
                      Name
                    </Typography>
                    <Typography className="typo050423_500 mt5px fs16px">
                      {name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container md={12} sm={12} xs={12} className="mt16px">
                  <Grid item xs={12}>
                    <Typography className="lightgreycolortypo  fs18px">
                      About
                    </Typography>
                  </Grid>
                  {about && about?.length < 250 ? (
                    <Grid item xs={12}>
                      <Typography className="typo050423_500 mt5px fs16px">
                        {about}
                      </Typography>
                    </Grid>
                  ) : about && about?.length > 250 && !seeMore ? (
                    <Grid item xs={12}>
                      <Typography className="typo050423_500 mt5px fs16px">
                        {about?.slice(0, 250)}
                        <span
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={() => setSeeMore(true)}
                          className="purplecolortypo-500"
                        >
                          See more
                        </span>
                      </Typography>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Typography className="typo050423_500 mt5px fs16px">
                          {about}
                          <span
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={() => setSeeMore(false)}
                            className="purplecolortypo-500"
                          >
                            See less
                          </span>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container md={12} sm={12} className="mt16px">
                  <Grid item>
                    <Typography className="lightgreycolortypo  fs18px">
                      Dates
                    </Typography>
                    <Typography className="typo050423_500 mt5px fs16px">
                      {date}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container md={12} sm={12} className="mt16px">
                  <Grid item>
                    <Typography className="lightgreycolortypo  fs18px">
                      Duration
                    </Typography>
                    <Typography className="typo050423_500 mt5px fs16px">
                      {durationD} seconds
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container md={12} sm={12} className="mt16px">
                  <Grid item>
                    <Typography className="lightgreycolortypo  fs18px">
                      Time Slot
                    </Typography>
                    <Grid sx={{ display: "flex", flexWrap: "wrap" }}>
                      {repetitionSolt &&
                        repetitionSolt?.map((item, index) => (
                          <Typography className="typo050423_500 mt5px fs16px">
                            {" "}
                            {item?.actualTime} ({item?.repetition} )
                            {repetitionSolt.length - 1 > index && ","}
                          </Typography>
                        ))}
                    </Grid>
                    {/* <Typography></Typography> */}
                  </Grid>
                </Grid>
                <Divider sx={{ margin: "16px 0" }} />
                <Typography
                  className=" fs18px greycolor505050400"
                  // sx={{ marginBottom: "10px" }}
                >
                  Add Content (Suggested : {billBoardData?.billboardSize})
                </Typography>
                <CheckPreview />
                {!playUrl ? (
                  <>
                    {" "}
                    {!filetype || filetype == "url" ? (
                      <>
                        <Grid
                          container
                          item
                          md={12}
                          className="link-upload-div mt10px "
                          variant="raised"
                          style={{
                            position: "relative",
                          }}
                        >
                          <img
                            src={Upload}
                            alt="loading"
                            className="Upload-Folder"
                          />
                          <Typography className="Upload-Folder-text font-family">
                            Click here to upload from your device
                          </Typography>
                          <Typography
                            className="Upload-Folder-text font-family"
                            sx={{ marginBottom: "10px" }}
                          >
                            (Please use image/video of resolution{" "}
                            {billBoardData?.billboardSize})
                          </Typography>

                          <input
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              opacity: "0",
                            }}
                            type="file"
                            id="raised-button-file"
                            // accept="video/quicktime , image/jpg,image/jpeg"
                            // accept="video/*"
                            accept="video/*,.mov,image/jpg,image/jpeg"
                            onChange={(e) => {
                              onChangeFile(e);
                            }}
                            disabled={videoLink ? true : false}
                          />
                        </Grid>
                      </>
                    ) : filetype === "video/mp4" ||
                      filetype === "video/webm" ||
                      filetype === "video/quicktime" ? (
                      <>
                        <div
                          className="mt10px"
                          style={{
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                        >
                          <CloseIcon
                            onClick={() => {
                              setFileType(null);
                              setVideoDimensions({});
                            }}
                            style={{
                              width: "20px",
                              cursor: "pointer",
                            }}
                          />
                          <video
                            src={file}
                            type="video/mp4"
                            controls
                            style={{
                              height: "350px",
                              width: "100%",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            // className="dotted-border "
                          />
                        </div>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="raised-button-file"
                          accept="image/jpeg,video/mp4"
                          onChange={(e) => {
                            onChangeFile(e);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <div
                          className="mt10px"
                          style={{
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                        >
                          <CloseIcon
                            onClick={() => {
                              setFileType(null);
                              setVideoDimensions({});
                            }}
                            style={{
                              width: "20px",
                              cursor: "pointer",
                            }}
                          />
                          <img
                            src={file}
                            style={{
                              height: "250px",
                              width: "100%",
                              marginLeft: "auto",
                              marginRight: "auto",
                              objectFit: "contain",
                            }}
                            // className="dotted-border"
                          />
                        </div>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="raised-button-file"
                          accept=" audio/*, video/*, image/*,"
                          onChange={(e) => {
                            onChangeFile(e);
                            // className = "dotted-border";
                          }}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <Grid container sx={{ position: "relative" }}>
                    {playVideo ? (
                      <>
                        {" "}
                        <ReactPlayer
                          url={playUrl}
                          onError={handlePlayerError}
                          height="200px"
                          width="100%"
                          style={{
                            height: "200px",
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                      </>
                    ) : (
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          height: "200px",
                          width: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Typography>
                          The url you are providing is wrong
                        </Typography>
                      </Grid>
                    )}

                    <CloseIcon
                      onClick={() => {
                        setPlayUrl(null);
                        setPlayVideo(true);
                        setVideoLink("");
                        setVideoDimensions({});
                      }}
                      style={{
                        width: "20px",
                        cursor: "pointer",
                        position: "absolute",
                        right: "0",
                        color: "red",
                      }}
                    />
                  </Grid>
                )}
                {PermissionCheck("post-ad-with-url") &&
                  (!filetype || filetype == "url") && (
                    <Grid
                      container
                      item
                      md={12}
                      sx={{ marginTop: "10px" }}
                      className="link-div"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <Typography className="Link-web-text ">
                          Link from Web
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          placeholder="Example : http://www.youtube.com/wuewdgwygdyw"
                          className="input-link"
                          type="url"
                          value={videoLink}
                          onChange={(e) => {
                            setVideoLink(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid container item xs={2} justifyContent="center">
                        <Button
                          onClick={(e) => {
                            checkUrlHandler(videoLink);
                            setFileType("url");
                          }}
                          style={{ border: "1px solid #E4E6EF" }}
                          className="purple-gradient family-color-white"
                        >
                          save
                        </Button>
                      </Grid>
                    </Grid>
                  )}
              </Grid>

              <Grid item md={5.2}>
                <Grid
                  container
                  sx={{ marginTop: "10px" }}
                  justifyContent="space-between"
                >
                  <Typography className="greycolor505050400 fs25px">
                    Selected Billboard
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{ borderTop: "1px solid #DBDBDB", margin: "10px 0" }}
                ></Grid>
                <Grid container justifyContent="space-between">
                  <Grid item md={4}>
                    <img
                      src={
                        billBoardData &&
                        billBoardData?.filesArr?.filter(
                          (a) => a?.filetype == "image/jpeg"
                        )?.[0]?.fileurl
                      }
                      alt=""
                      className="width100"
                      style={{ height: "150px" }}
                    />
                  </Grid>
                  <Grid item md={8} className="pl20px">
                    <Typography className="greycolor505050400 fs20px">
                      {billBoardData?.billboardId}
                    </Typography>
                    <Typography className="lightgreycolortypo fs16px">
                      {billBoardData?.fullAddress}
                    </Typography>
                    <Typography className="lightgreycolortypo fs16px">
                      {billBoardData?.pincode}
                    </Typography>
                    <Typography className="lightgreycolortypo fs16px">
                      {billBoardData?.city}, {billBoardData?.state}
                      {billBoardData?.country}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    className="mt20px"
                    sx={{ borderTop: "2px solid #dddddd" }}
                  >
                    {userRole === 0 ? (
                      <Grid container sx={{ marginTop: "24px" }}>
                        <Grid
                          container
                          item
                          xs={12}
                          className="border_primary"
                          sx={{ padding: "10px" }}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="space-between"
                          >
                            <Typography className="blackcolortypo500 fs18px ">
                              Price Details
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              minHeight: "15px",
                              maxHeight: "150px",
                              overflow: "scroll",
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            {repetitionSolt &&
                              repetitionSolt.map((item, index) => (
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  justifyContent="space-between"
                                  style={{ marginTop: "16px" }}
                                >
                                  <Typography className="greycolortypo6F6F6F_500 fs16px">
                                    {item?.actualTime} X {item?.repetition}{" "}
                                    repetition
                                  </Typography>
                                  <Typography className="greycolortypo6F6F6F_500 fs16px">
                                    &#x20b9; {item?.finalprice} X{" "}
                                    {item?.repetition} X{" "}
                                    {+PrefieldData?.duration}
                                  </Typography>
                                </Grid>
                              ))}
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="space-between"
                            style={{ marginTop: "16px" }}
                          >
                            <Typography className="greycolortypo6F6F6F_500 fs16px">
                              GST(18%)
                            </Typography>
                            <Typography className="greycolortypo6F6F6F_500 fs16px">
                              + &#x20b9; ({subTotalGst})
                            </Typography>
                          </Grid>
                          {disAmmount && (
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="space-between"
                              style={{ marginTop: "16px" }}
                            >
                              <Typography className="greycolortypo6F6F6F_500 fs16px">
                                Coupon Discount
                              </Typography>
                              <Typography className="greycolortypo6F6F6F_500 fs16px">
                                &#x20b9; - {disAmmount}
                              </Typography>
                            </Grid>
                          )}
                          <Grid
                            container
                            item
                            style={{
                              borderTop: "1px solid #ddd",
                              margin: "16px 0",
                            }}
                          ></Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="space-between"
                          >
                            <Typography className="blackcolortypo500 fs20px">
                              Total
                            </Typography>
                            <Typography className="greycolortypo6F6F6F_500 fs16px">
                              ₹ 0
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            style={{
                              borderTop: "1px solid #ddd",
                              marginTop: "16px ",
                            }}
                          ></Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="space-between"
                            style={{ marginTop: "16px " }}
                          >
                            <Typography className="blackcolortypo500 fs20px">
                              Payment Method
                            </Typography>
                            <Typography className="greycolortypo6F6F6F_500 fs16px">
                              Wallet ₹
                              {parseInt(wallletDetails?.walletBalance).toFixed(
                                2
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center"
                            style={{
                              marginTop: "52px",
                            }}
                          >
                            <Alert_Resolution_dialogue
                              preferredResolution={billBoardData?.billboardSize}
                              videoDimensions={videoDimensions}
                              handleUploadimage={handleUploadimage}
                              WithdrawMoneyfromWallet={WithdrawMoneyfromWallet}
                              date={date}
                              filetype={filetype}
                              videoLink={videoLink}
                              playUrl={playUrl}
                              timeSlot={timeSlot}
                              currentTime={currentTime}
                            />
                          </Grid>
                        </Grid>

                        {date == moment(new Date()).format("YYYY-MM-DD") &&
                        timeSlot &&
                        currentTime > timeSlot ? (
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center"
                            sx={{ marginTop: "15px" }}
                          >
                            {" "}
                            <Typography className="formError">
                              Please select the correct timeslot
                            </Typography>
                          </Grid>
                        ) : filetype &&
                          (filetype !== "url"
                            ? videoLink?.trim()?.length == 0
                            : videoLink?.trim()?.length > 0 &&
                              playUrl?.trim()?.length > 0) ? (
                          ""
                        ) : (
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center"
                            sx={{ marginTop: "15px" }}
                          >
                            {" "}
                            <Typography className="formError">
                              Upload file before payment
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid container sx={{ marginTop: "24px" }}>
                        <Grid
                          container
                          item
                          xs={12}
                          className="border_primary"
                          sx={{ padding: "10px" }}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="space-between"
                          >
                            <Typography className="blackcolortypo500 fs18px ">
                              Price Details
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              minHeight: "15px",
                              maxHeight: "150px",
                              overflow: "scroll",
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            {repetitionSolt &&
                              repetitionSolt.map((item, index) => (
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  justifyContent="space-between"
                                  style={{ marginTop: "16px" }}
                                >
                                  <Typography className="greycolortypo6F6F6F_500 fs16px">
                                    {item?.actualTime} X {item?.repetition}{" "}
                                    repetition
                                  </Typography>
                                  <Typography className="greycolortypo6F6F6F_500 fs16px">
                                    &#x20b9; {item?.finalprice} X{" "}
                                    {item?.repetition} X{" "}
                                    {+PrefieldData?.duration}
                                  </Typography>
                                </Grid>
                              ))}
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="space-between"
                            style={{ marginTop: "16px" }}
                          >
                            <Typography className="greycolortypo6F6F6F_500 fs16px">
                              GST(18%)
                            </Typography>
                            <Typography className="greycolortypo6F6F6F_500 fs16px">
                              + &#x20b9; ({subTotalGst})
                            </Typography>
                          </Grid>
                          {disAmmount && (
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="space-between"
                              style={{ marginTop: "16px" }}
                            >
                              <Typography className="greycolortypo6F6F6F_500 fs16px">
                                Coupon Discount
                              </Typography>
                              <Typography className="greycolortypo6F6F6F_500 fs16px">
                                &#x20b9; - {disAmmount}
                              </Typography>
                            </Grid>
                          )}
                          <Grid
                            container
                            item
                            style={{
                              borderTop: "1px solid #ddd",
                              margin: "16px 0",
                            }}
                          ></Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="space-between"
                          >
                            <Typography className="blackcolortypo500 fs20px">
                              Total
                            </Typography>
                            <Typography className="greycolortypo6F6F6F_500 fs16px">
                              &#x20b9; {totalAmount}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            style={{
                              borderTop: "1px solid #ddd",
                              marginTop: "16px ",
                            }}
                          ></Grid>

                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="space-between"
                            style={{ marginTop: "16px " }}
                          >
                            <Typography className="blackcolortypo500 fs20px">
                              Payment Method
                            </Typography>
                            <Typography className="greycolortypo6F6F6F_500 fs16px">
                              Wallet ₹
                              {parseInt(wallletDetails?.walletBalance).toFixed(
                                2
                              )}
                            </Typography>
                          </Grid>
                          {wallletDetails?.walletBalance < totalAmount && (
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="end"
                              style={{ marginTop: "16px " }}
                            >
                              <Typography className="formError">
                                You don't have sufficient balance
                              </Typography>
                            </Grid>
                          )}

                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center"
                            style={{
                              marginTop: "52px",
                            }}
                          >
                            <Alert_Resolution_dialogue
                              preferredResolution={billBoardData?.billboardSize}
                              videoDimensions={videoDimensions}
                              handleUploadimage={handleUploadimage}
                              WithdrawMoneyfromWallet={WithdrawMoneyfromWallet}
                              date={date}
                              filetype={filetype}
                              videoLink={videoLink}
                              playUrl={playUrl}
                              timeSlot={timeSlot}
                              currentTime={currentTime}
                            />
                          </Grid>
                        </Grid>

                        {date == moment(new Date()).format("YYYY-MM-DD") &&
                        timeSlot &&
                        currentTime > timeSlot ? (
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center"
                            sx={{ marginTop: "15px" }}
                          >
                            {" "}
                            <Typography className="formError">
                              Please select the correct timeslot
                            </Typography>
                          </Grid>
                        ) : filetype &&
                          (filetype !== "url"
                            ? videoLink?.trim()?.length == 0
                            : videoLink?.trim()?.length > 0 &&
                              playUrl?.trim()?.length > 0) ? (
                          ""
                        ) : (
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center"
                            sx={{ marginTop: "15px" }}
                          >
                            {" "}
                            <Typography className="formError">
                              Upload file before payment
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}

                    {/* <Button
                        className="purplebtn width100 mt20px"
                        onClick={() => {
                          handleUploadimage();
                          // BookTime();
                        }}
                      >
                        Submit
                        <SuccessDialog
                          openData={openDialogName === "success"}
                          data={"ClusterHead Added"}
                        />
                        <Typography className="fs16px">
                          <WrongDialog
                            openData={openDialogName === "reject"}
                            data={"Error: Something went wrong !"}
                          />
                        </Typography>
                      </Button> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        {/* )} */}
      </div>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={loading}
        PaperProps={{
          className: "SuccessDialog",
        }}
        BackdropComponent={styled(Backdrop, {
          name: "MuiModal",
          slot: "Backdrop",
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({ zIndex: -1, backdropFilter: "blur(2px)" })}
      >
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", height: "600px" }}
          >
            <CircularProgress style={{ color: " #d723cd" }} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>
      <SuccessDialog
        openData={openDialogName === "success"}
        data={"Billboard Book Successfully"}
        navigateTo={`/admin/order/${orderId}`}
      />
      <WrongDialog openData={openDialogName === "reject"} data={errMsg} />

      {goToAddWallet && (
        <AddWallet
          handleClose={handleClose}
          walletDetail={wallletDetails}
          amount={totalAmount - parseInt(wallletDetails?.walletBalance)}
          reFetch={() => CheckWalletandAddMoney()}
        />
      )}
    </>
  );
};

export default BoardSelectDetail;
