import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Typography,
  DialogContent,
  InputBase,
  ListItemButton,
  FormControlLabel,
  Radio,
  IconButton,
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
  FormLabel,
  RadioGroup,
  Stack,
  Pagination,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
//React Icons
//core component
import axios from "../../../../../../../api/axiosInstance";

import ApproveDialog from "./ApproveDialog";
import { useLocation } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",

  width: "36%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "93%",
      },
    },
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="dialogtitle" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogcrossicon"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const options = ["Option 1", "Option 2"];
export default function AssignDeviceDialogs({
  AssignDevicetoBillboard,
  handleClose,
  DeviceChange,
  deviceData,
  handleClickOpen,
  open,
  currentPage,
  totalPage,
  handleChangeByPage,
  setsearch,
  search,
}) {
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  // paginations

  return (
    <div>
      <Typography className="greencolortypo" onClick={handleClickOpen}>
        Assign Device
      </Typography>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className="whitecolortypo500">Select Device </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ m: 2 }}>
          <div>
            <div className="admin-content">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Search className="search-admin">
                  <SearchIconWrapper>
                    <SearchIcon className="searchicon" />
                  </SearchIconWrapper>
                  <StyledInputBase
                    className=""
                    placeholder="Search Location"
                    inputProps={{ "aria-label": "search" }}
                    value={search}
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Search>{" "}
              </Grid>
              <Grid container>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ width: "100%" }}
                >
                  {deviceData?.map((data, i) => {
                    return (
                      <Grid
                        item
                        key={i}
                        xs={12}
                        sm={5.5}
                        md={2.7}
                        sx={{ border: "1px solid #dddddd", marginLeft: "20px" }}
                        className="access-radio-grid "
                      >
                        <Grid container justifyContent="space-between">
                          <FormLabel>
                            <ListItemButton>
                              <Typography className=" greycolor505050400">
                                {data?.deviceName}
                                <Typography className="lightgreycolortypo">
                                  {data?.deviceUID}
                                </Typography>
                              </Typography>
                            </ListItemButton>
                          </FormLabel>
                          <FormControlLabel
                            className="radiostyle access-radio-formcontrolabel"
                            value={data?.deviceUID}
                            style={{ paddingTop: "10px" }}
                            control={<Radio />}
                            key={data?._id}
                            onChange={(e) => DeviceChange(e, data)}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <DialogActions className="dialogaction-p0px mt20px">
            <Stack spacing={2}>
              <Pagination
                page={currentPage}
                count={totalPage}
                onChange={handleChangeByPage}
                size="large"
              />
            </Stack>
          </DialogActions>
        </Grid>
        <DialogActions>
          <Button className="previousbtn" onClick={handleClose}>
            Cancel
          </Button>
          <ApproveDialog
            handleClose={handleClose}
            AssignDevicetoBillboard={AssignDevicetoBillboard}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
