import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Breadcrumbs,
  Tabs,
  Tab,
  DialogContent,
  useTheme,
  useMediaQuery,
  Button,
  CircularProgress,
  Backdrop,
  DialogTitle,
  Dialog,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../../component/Sidebar/Sidebar";
// Tab Component Import
import CapmaignTab from "./C-BuilderTab's/CapmaignTab";
import BillBoardTab from "./C-BuilderTab's/BillBoardTab";
import ScheduleTab from "./C-BuilderTab's/ScheduleTab";
import AdContentTab from "./C-BuilderTab's/AdContentTab";
import moment from "moment";
// import SuccessDialog from "../../../utils/Dialog/SuceedFullDialog";
import PaymentTab from "./C-BuilderTab's/PaymentTab";
import axiosInstance from "../../../api/axiosInstance";
import { useSnackbar } from "notistack";
import SuccessDialog from "./C-BuilderTab's/successDialog";
///// new updated campaign create flow /////
import { useFilterController } from "../../../Context/filter";
import {
  useCampaignController,
  setCampaignName,
  setAboutCampaign,
  setDateRange,
  setAdDuration,
  setActualDate,
  setTimeSlots,
  setBillboards,
  setApplyToAllDays,
  setTotalAmount,
  setFileType,
  setFile,
  setTimeSlotsKey,
} from "../../../Context/campaignBook";

// import { useSnackbar } from "notistack";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "#5FCA5D" }} {...other}>
      {children}
      <Typography className="whitecolortypo">Approve order </Typography>{" "}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: "100%" }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// const initialCampaignData = {
//   campaignName: '',
//   aboutCampaign: '',
//   dateRange: [],
//   adDuration: '',
//   timeSlots: [],
// };

export default function Campaign() {
  let userRole = JSON.parse(localStorage.getItem("userData")).user.userRole;
  const [userId, setUserId] = useState(
    JSON.parse(localStorage.getItem("userData")).user._id
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  const [controller, dispatch] = useFilterController();
  const [campaignController, campaignDispatch] = useCampaignController();
  const { header_search, mobileStatus, emailStatus, laterStatus } = controller;
  const { campaignName, aboutCampaign, dateRange, adDuration, timeSlots } =
    campaignController;

  const iSm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [value, setValue] = useState(0);
  const [selectSecond, setSelectSecond] = useState(30);
  const [profileCheck, setProfileCheck] = useState(false);

  const [openDialogName, setOpenDialog] = useState(null);
  const [errorMsg, setErrorMsg] = useState("Error: Something went wrong !");
  const [loading, setLoading] = useState(false);
  // Submit states added by Rahul
  const [submit, setSubmit] = useState(false);
  const [submit1, setSubmit1] = useState(false);
  const [submit2, setSubmit2] = useState(false);
  const [submit3, setSubmit3] = useState(false);
  const [uploadedContentView, setUploadedContentView] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [transactionData, setTransactionData] = useState();
  const [Valid, setValid] = useState("invalid");
  const [couponCode, setcouponCode] = useState("");
  // const [dateRange, setDateRange] = useState();

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setValue(newValue);
    }
    if (newValue === 1) {
      if (campaignName && aboutCampaign && adDuration) {
        setValue(newValue);
      }
    }
    if (newValue === 2) {
      let temp = false;
      if (Object.keys(timeSlots)?.length != dateRange?.length) {
        enqueueSnackbar("Please Complete This Step ", {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }
      for (let i of Object.keys(timeSlots)) {
        if (timeSlots[i]?.length == 0) {
          enqueueSnackbar(`Please Select Time Slot on this date ${i}`, {
            variant: "error",
            autoHideDuration: 5000,
          });
          temp = true;
          break;
        }
      }
      if (temp) return;
      if (Object.keys(timeSlots)?.length > 0) {
        //  setValue(2);
        setValue(newValue);
      } else {
        enqueueSnackbar("Please Complete This Step ", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
      // if (campaignName && aboutCampaign) {
      //   setValue(newValue);
      // }
    }
    if (newValue === 3) {
      if (
        billBoardArr?.length > 0 &&
        campaignName &&
        // clientName &&
        aboutCampaign
      ) {
        setValue(newValue);
      }
    }
    if (newValue === 4) {
      if (
        billBoardArr?.length > 0 &&
        campaignName &&
        // clientName &&
        aboutCampaign &&
        fileType &&
        uploadedContentView !== null &&
        uploadedContentView !== false
      ) {
        setValue(newValue);
      }
    }
    if (newValue === 5) {
      if (
        billBoardArr?.length > 0 &&
        campaignName &&
        // clientName &&
        aboutCampaign &&
        fileType &&
        uploadedContentView !== null &&
        uploadedContentView !== false
      ) {
        setValue(newValue);
      }
    }
  };
  const handleNext = () => {
    if (value == 0) {
      let errors = {};
      if (!campaignName)
        errors = {
          ...errors,
          campaignName: "Campaign Name is required !",
        };
      if (!aboutCampaign)
        errors = {
          ...errors,
          aboutCampaign: "About Campaign is required !",
        };
      if (dateRange?.length == 0)
        errors = { ...errors, dateRange: "Date Range is required !" };
      if (!adDuration)
        errors = { ...errors, adDuration: "Duration is required !" };
      if (Object.keys(errors)?.length > 0) return setFormErrors(errors);
      if (campaignName && aboutCampaign && dateRange && adDuration) {
        setValue(1);
      } else {
        // setFormErrors(validate(campaignName, aboutCampaign));
        enqueueSnackbar("Please Complete This Step ", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    } else if (value == 1) {
      let temp = false;
      if (Object.keys(timeSlots)?.length != dateRange?.length) {
        enqueueSnackbar("Please select timeslot for all dates", {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }
      for (let i of Object.keys(timeSlots)) {
        if (timeSlots[i]?.length == 0) {
          enqueueSnackbar(`Please Select Time Slot on this date ${i}`, {
            variant: "error",
            autoHideDuration: 5000,
          });
          temp = true;
          break;
        }
      }
      if (temp) return;
      if (Object.keys(timeSlots)?.length > 0) {
        setValue(2);
      } else {
        enqueueSnackbar("Please select timeslot for all dates ", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    } else if (value == 2) {
      if (selectDevice.length > 0) {
        setValue(3);
      } else {
        enqueueSnackbar("Please Select Any free billboard", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    } else if (value == 3) {
      if (!!fileType) {
        setValue(4);
      } else {
        enqueueSnackbar("Please Choose any file", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    }
  };
  function TabNext() {
    // clientName,
    setFormErrors(validate(campaignName, aboutCampaign));
    setSubmit(true);
    setSubmit1(false);
    setSubmit2(false);
    setSubmit3(false);
  }
  function TabPre1() {
    setValue(0);
  }
  function TabNext1() {
    console.log("Inside Tab NExt 1");
    setFormErrors(
      ScheduleValidate(startdate, enddate, timeSlot, budget, duration)
    );
    setSubmit1(true);
    setSubmit(false);

    setSubmit2(false);
    setSubmit3(false);
  }
  function TabPre2() {
    setValue(1);
  }
  function TabNext2() {
    setFormErrors(BillboardValidate(billBoardArr));
    setSubmit2(true);
    setSubmit1(false);
    setSubmit(false);

    setSubmit3(false);
  }
  function TabPre3() {
    setValue(2);
  }
  function TabNext3() {
    setFormErrors(AdContentValidate(fileType));
    setSubmit3(true);
    setSubmit2(false);
    setSubmit1(false);
    setSubmit(false);
  }
  function TabPre4() {
    setValue(3);
  }
  function TabNext4() {
    setValue(5);
  }
  function TabPre5() {
    setValue(4);
  }
  //===> For Campaign tab
  const [videoDimensions, setVideoDimensions] = useState({});
  // const [campaignName, setCampaignName] = useState("");
  const [clientName, setClientName] = useState("");
  // const [aboutCampaign, setAboutCampaign] = useState("");
  const [billBoardArr, setBillBoardArr] = useState([]); //==> For screens () MAc ID arr
  const [deviceArr, setDeviceArr] = useState([]);
  const [macIdArr, setMacIdArr] = useState([]);
  //===> For Schedule tab
  const [startdate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [minTime, setMinTime] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());
  // ///////////////// variables for dates /////////////////////////////////////
  let date = new Date();
  date.setMinutes(0, 0, 0); // Set hours, minutes and seconds
  const [starttime, setStarttime] = useState(date.toString());
  let date2 = new Date();
  date2.setHours(date.getHours() + 1, 0, 0); // Set hours, minutes and seconds
  const [endtime, setEndtime] = useState(date2.toString());

  // ///////////////// variables for dates ///////////////////////////////////////
  const [timeSlot, setTimeSlot] = useState(null);
  const [budget, setBudget] = useState("fbhg");
  const [durationTrans, setDurationTrans] = useState("");
  const [duration, setDuration] = useState("");

  const [wallletDetails, setWalletDetails] = useState();
  const [debitAmount, setdebitAmount] = useState();

  //==> For Media Upload
  const [contentName, setContentName] = useState();
  const [contentType, setContentType] = useState();

  //==> For
  const [mediafile, setMediafile] = useState(null);
  const [zipfilename, setZipfilename] = useState(null);
  const [timmedname, setTrimmedname] = useState(null);
  const [conLink, setConLink] = useState("");

  //====> For images
  const [imagestring, setImagestring] = useState("");
  const [imageoriginalname, setImageoriginalname] = useState("");

  const [fileType, setFiletype] = useState(null);
  const [multiTimeSolts, setMultiTimeSolts] = useState();
  const [videoLink, setVideoLink] = useState("");

  //=========== for TimeSlote

  let minsarr = [];
  for (let i = 1; i <= 120; i++) {
    minsarr.push(i);
  }

  // console.log("Check Slot", duration);
  const [sec] = useState("30");
  const [repeat, setRepeat] = useState(1);
  const [second, setSecond] = useState(0);

  useEffect(() => {
    setDurationTrans(repeat);
    setDuration(repeat * 30 + second);
  }, [repeat, second]);

  const handleCustomTimeChange = (e) => {
    if (e.target.name === "minute") {
    }
  };
  // Function Start
  const handleCampaignData = (value, datatype) => {
    if (datatype === "dimension") {
      console.log(value, "dimensiondimension");
      setVideoDimensions(value);
    }
    if (datatype === "campaignName") {
      // setCampaignName(value);
    }
    // else if (datatype === "clientName") {
    //   setClientName(value);
    // }
    else if (datatype === "aboutCampaign") {
      // setAboutCampaign(value);
    } else if (datatype === "billboardarr") {
      setBillBoardArr([...value]);
    } else if (datatype === "macIdArr") {
      setMacIdArr([...value]);
    } else if (datatype === "starttime") {
      setStarttime(value);
      setTimeSlot(moment(value).format("HH"));

      setEndtime(moment(value).add(1, "h").toDate());
    } else if (datatype === "startdate") {
      if (
        moment(value).format("YYYY-MM-DD") ==
        moment(new Date()).format("YYYY-MM-DD")
      ) {
        setMinTime(new Date());
      } else {
        const date = value;
        date?.setHours(0, 0, 0, 0);
        setMinTime(date);
      }
      setMinDate(value);
      setStartDate(moment(value).format("YYYY-MM-DD"));
      setEndDate(moment(value).format("YYYY-MM-DD"));
    } else if (datatype === "enddate") {
      setEndDate(moment(value).format("YYYY-MM-DD"));
    } else if (datatype === "budget") {
      setBudget(value);
    } else if (datatype === "duration") {
      setDuration(value);
    } else if (datatype === "contentname") {
      setContentName(value);
      setTrimmedname(value);
    } else if (datatype === "contenttype") {
      setContentType(value);
      setFiletype(value);
    } else if (datatype === "imageString") {
      setImagestring(value);
    } else if (datatype === "imageoriginalname") {
      console.log("imageoriginalname ==> ", value);
      setImageoriginalname(value);
    } else if (datatype === "originalvideoname") {
      console.log("original video name ==> ", value);
      setZipfilename(value);
    } else if (datatype === "mediafile") {
      //console.log("original video name ==> ", value);
      setMediafile(value);
    } else if (datatype === "uploadedContentView") {
      if (value == null) {
        setVideoDimensions({});
        setFiletype(null);
      }
      setUploadedContentView(value);
    } else if (datatype === "selectSecond") {
      setSelectSecond(value);
    }
  };

  //==================> For deviceID arr =================>
  const handleDevicesArr = (value, datatype) => {
    if (datatype === "deviceArr") {
      console.log("Select deviceArr ==> ", value);
      setDeviceArr([...value]);
    }
  };
  //////////////////////for select billborad screen data///////////////
  const [selectDevice, setSelectDevice] = useState([]);
  const selectDeviceHandler = (value) => {
    setSelectDevice(value);
  };

  //////////////////////for select billborad screen data///////////////
  //==============================================================================//

  useEffect(() => {
    console.log(
      "device id arr ==> ",
      deviceArr,
      "  billBoardArr  billBoardArr  billBoardArr",
      billBoardArr
    );
  }, [deviceArr, billBoardArr]);

  useEffect(() => {
    setStartDate(moment(new Date()).format("YYYY-MM-DD"));
    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    setTimeSlot(moment(new Date()).format("HH"));
    // console.log(timeSlots, "timeSlotstimeSlots");
  }, []);

  //================================= API ======================================//
  const CheckWalletandAddMoney = async () => {
    try {
      const res = await axiosInstance.post(`api/payment/getWalletData`, {
        userId: userId,
      });
      console.log("gettwallet data responce", res);
      if (res.status == 200 || res.status == 201) {
        setWalletDetails(res?.data?.msg);
      }
    } catch (err) {
      console.log("error in get wallet data", err);
    }
  };

  function getRangebetweenDates(startDate, endDate, type = "days") {
    console.log("startDate ", startDate, "endDate ", endDate);
    let fromDate = moment(startDate);
    let toDate = moment(endDate);
    let diff = toDate.diff(fromDate, type);
    let range = [];
    for (let i = 0; i <= diff; i++) {
      range.push(moment(startDate).add(i, type).format("YYYY-MM-DD"));
    }
    console.log("range", range);
    return range;
  }

  const submitTimeSlot = async (transactionId, contentLink, multi) => {
    console.log("/|/===== submitTimeSlot Got Hit() =====/|/", conLink);
    try {
      let body = {
        adTitle: campaignName,
        aboutAd: aboutCampaign,
        adType: "entertainement",
        multiTimes: multiTimeSolts ? multiTimeSolts : multi,
        startscheduleDate: dateRange[0],
        endscheduleDate: dateRange[dateRange.length - 1],
        isCampaign: true,
        videoDuration: adDuration,
        billboardId: billBoardArr,
        contentLink: contentLink ? contentLink : conLink,
        filetype: contentType,
        transactionId: transactionId,
        videoname: contentName,
      };
      console.log("Body data inside CreateCampaign => ", body);

      const res = await axiosInstance.post(
        `api/billBoardBooking/bookingBoard`,
        body
      );

      if (res.status == 200 || res.status == 201) {
        console.log("Handle off response  ===>", res);
        // handleUploadimage();
        // setLoading(false);
        setOpenDialog("success");
        setOrderId(res?.data?.orderId);
        setSuccessPopup(true);

        // return navigate(-1)
      } else {
        console.log("Error From Server ==> ", res.msg);
        setErrorMsg(res.msg);
        // setLoading(false);
        setOpenDialog("reject");
        enqueueSnackbar("campaign uploading  error ", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    } catch (error) {
      enqueueSnackbar(" Booking Campaign is declined ", {
        variant: "error",
        autoHideDuration: 5000,
      });
      setOpenDialog("reject");
      console.log("Error during creating Billboard Price => ", error.message);
    }
  };

  //================================== handle upload image ============================//
  const handleUploadimage = async (amount, Valid, couponCode) => {
    setLoading(true);
    setcouponCode(couponCode);
    setValid(Valid);
    if (
      fileType === "video/mp4" &&
      zipfilename &&
      userId &&
      fileType &&
      timmedname &&
      macIdArr.length > 0
    ) {
      const formdata = new FormData();
      // formdata.append("zipfilename", zipfilename); //==> original zipfilename
      formdata.append("userid", userId);
      formdata.append("filetype", fileType);
      formdata.append("uniquename", timmedname); //===> uniquename
      formdata.append("macArr", JSON.stringify(macIdArr));
      formdata.append("billboardId", billBoardArr[0]);
      formdata.append("file", mediafile); //===> mediafile
      const res = await axiosInstance.post(
        `api/mediaupload/uploadVideo`,
        formdata
      );

      if (res.status === 200) {
        console.log("Handle off response sfter vdo upload  ===>", res);
        setConLink(res?.data?.contentLink);
        WithdrawMoneyfromWallet(amount, res?.data?.contentLink);

        // WithdrawMoneyfromWallet;
        //
        setMediafile(null);
        setFiletype(null);
        setTrimmedname(null);
        setZipfilename();
        // setCampaignName(dispatch, "");
        setClientName("");
        // setAboutCampaign("");
        setMacIdArr([]);
        setDeviceArr([]);
        setStartDate(moment(new Date()).format("YYYY-MM-DD"));
        setEndDate(moment(new Date()).format("YYYY-MM-DD"));
        setStarttime();
        setEndtime();
        setTimeSlot(moment(new Date()).format("HH"));
        // setBudget();
        setDuration();
      } else {
        enqueueSnackbar("video/img uploading  error ", {
          variant: "error",
          autoHideDuration: 5000,
        });
        console.log("error in handle off ==> ", res);

        setLoading(false);
      }
    } else if (
      fileType === "image/jpeg" &&
      userId &&
      imageoriginalname &&
      fileType &&
      imagestring &&
      timmedname &&
      macIdArr.length > 0
    ) {
      console.log("//=======  Image upload API hit ========//");
      console.log(
        "Data ===> ",
        JSON.stringify({
          userid: userId,
          imagename: imageoriginalname,
          filetype: fileType,
          imagestring: imagestring,
          uniquename: timmedname,
          macArr: macIdArr,
        })
      );
      let body = {
        userid: userId,
        imagename: imageoriginalname,
        filetype: fileType,
        imagestring: imagestring,
        uniquename: timmedname,
        macArr: macIdArr,
        billboardId: billBoardArr[0],
      };

      const res = await axiosInstance.post(
        `api/mediaupload/base64upload`,
        body
      );

      console.log(res, "response");
      if (res.status == 200) {
        console.log("Handle off response  ===>", res?.data?.msg);
        setFiletype(null);
        setTrimmedname(null);

        setConLink(res?.data?.contentLink);
        WithdrawMoneyfromWallet(amount, res?.data?.contentLink);

        setImageoriginalname("");
        setImagestring("");
        // setCampaignName("");
        setClientName("");
        // setAboutCampaign("");
        setMacIdArr([]);
        setDeviceArr([]);
        setStartDate(moment(new Date()).format("YYYY-MM-DD"));
        setEndDate(moment(new Date()).format("YYYY-MM-DD"));
        setStarttime();
        setEndtime();
        setTimeSlot(moment(new Date()).format("HH"));
        setBudget();
        setDuration();
      } else {
        enqueueSnackbar("video/img uploading  error ", {
          variant: "error",
          autoHideDuration: 5000,
        });
        console.log("error in handle off ==> ", res.msg);
        setLoading(false);
      }
    } else {
      WithdrawMoneyfromWallet(amount, videoLink);
      setMediafile(null);
      setFiletype(null);
      setTrimmedname(null);
      setZipfilename();
      // setCampaignName(dispatch, "");
      setClientName("");
      // setAboutCampaign("");
      setMacIdArr([]);
      setDeviceArr([]);
      setStartDate(moment(new Date()).format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
      setStarttime();
      setEndtime();
      setTimeSlot(moment(new Date()).format("HH"));
    }
  };

  const WithdrawMoneyfromWallet = async (amount, contentLink) => {
    setLoading(true);
    console.log(timeSlots, "-----------------", typeof timeSlots);
    if (timeSlots) {
      let newValue = [];
      Object.keys(timeSlots)?.map((item) => {
        timeSlots[item]?.map((itemObj) => {
          newValue.push({ ...itemObj, date: item });
        });
      });
      console.log(newValue, "uuuuuuuuuuuuuuuuuuiiooo");

      let multi = [];

      newValue.forEach((item, index) => {
        multi.push({
          date: item?.date,
          startHour: item?.time,
          repetitions: item?.repetition,
          // actualTime: item?.actualTime,
        });
      });
      console.log("sum sum", multi);

      setMultiTimeSolts(multi);
      // }

      try {
        const res = await axiosInstance.post(`api/payment/createTransaction`, {
          // orderId: uuidv4(),
          // walletId: wallletDetails?._id,

          // repetitions: [durationTrans],
          // duration: 30,
          // timeArr: [timeSlot],
          // dates: dateRange,

          amount: amount ? amount : 0,
          billboardIds: billBoardArr,
          message: "Advertisement Payment",
          multiTimes: multiTimeSolts ? multiTimeSolts : multi,
          videoDuration: adDuration,
        });
        if (Valid == "true") {
          const res1 = await axiosInstance.post(
            `/coupon/ChnageCouponStatusToUser `,
            {
              userId: localStorage.getItem("saps_id"),
              couponCode: couponCode,
            }
          );
        }
        console.log("make Transaction data responce", res);

        if (res.status == 200 || res.status == 201) {
          console.log("i am inside", res);
          setTransactionData(res?.data?.data);
          setdebitAmount(res?.data?.data?.amount);
          submitTimeSlot(res?.data?.data?._id, contentLink, multi);
        }
      } catch (err) {
        enqueueSnackbar(
          "Your Transaction is Cancel Booking order is declined ",
          {
            variant: "error",
            autoHideDuration: 5000,
          }
        );
        console.log("error in make Transaction data responce", err);
      }
    } else {
      // setLoading(false);
      console.log("err in wallet");
      // return;
    }
  };

  //========================= Validation ===============================//
  const [formErrors, setFormErrors] = useState({});

  const validate = (campaignName, aboutCampaign) => {
    const errors = {};
    // let numeric = /^\d+$/; clientName,
    if (!campaignName) {
      errors.campaignName = "Campaign Name is required !";
    }
    // if (!clientName) {
    //   errors.clientName = "Client Name is required !";
    // }

    if (!aboutCampaign) {
      errors.aboutCampaign = "About Campaign is required !";
    }
    return errors;
  };
  const BillboardValidate = (billBoardArr) => {
    let errors = {};
    if (billBoardArr.length === 0) {
      errors.billBoardArr = "Please select atleast one Billboard !";
    }
    return errors;
  };
  const AdContentValidate = (image) => {
    let errors = {};
    if (
      image?.length === 0 ||
      image === null ||
      image === undefined ||
      uploadedContentView == null ||
      uploadedContentView == false
    ) {
      errors.image = "Please upload an Image !";
    }
    return errors;
  };
  const ScheduleValidate = (startdate, enddate, timeSlot, budget, duration) => {
    let errors = {};

    if (!startdate) {
      errors.startdate = "Start date is required !";
    }

    if (!enddate) {
      errors.enddate = "End date is required !";
    }

    if (!timeSlot) {
      errors.timeSlot = "TimeSlot is required !";
    }

    // if (!budget) {
    //   errors.budget = "Budget is required !";
    // }

    if (!duration) {
      errors.duration = "Duration is required !";
    }
    console.log(errors, "me errroess");
    return errors;
  };

  const handleBack = () => {
    setValue(() => value - 1);
  };

  // ======================== validateFunction ======================== //
  useEffect(() => {
    // console.log("Inside React Useeffect ===> ", Object.keys(formErrors).length)
    if (Object.keys(formErrors).length === 0 && submit) {
      setValue(1);
    }

    if (Object.keys(formErrors).length === 0 && submit1) {
      setValue(2);
    }

    if (Object.keys(formErrors).length === 0 && submit2) {
      setValue(3);
    }
    if (Object.keys(formErrors).length === 0 && submit3) {
      setValue(4);
    }
  }, [formErrors]);

  useEffect(() => {
    let date = getRangebetweenDates(startdate, enddate);
    console.log(date);
    // setDateRange(date);
  }, [startdate, enddate]);

  useEffect(() => {
    if (timeSlots?.length > 0) {
      Object.keys(timeSlots);
      let sum = 0;
      let timeSum = [];
      let repSum = [];
      let multi = [];
      timeSlots.forEach((item, index) => {
        sum += +item?.finalprice * +item?.repetition * adDuration;

        timeSum.push(item?.repetition);

        repSum.push(item?.time);

        multi.push({
          date: item?.date,
          startHour: item?.time,
          repetitions: item?.repetition,
        });
      });
      console.log("sum sum", sum, repSum, timeSum, "mullti timesolt ", multi);

      sum = +sum;
      // setTotal(sum);
      // setTimeL(repSum);
      // setReps(timeSum);
      let gstCalculate = ((sum * 18) / 100).toFixed(2);
      gstCalculate = +gstCalculate;
      // setSubTotalGst(gstCalculate);
      console.log(":::::::::::::::", sum + gstCalculate);
      // setTotalAmount(sum + gstCalculate);
      setMultiTimeSolts(multi);
    }
  }, [timeSlots]);

  useEffect(() => {
    CheckWalletandAddMoney();
  }, []);

  useEffect(() => {
    console.log(multiTimeSolts, "multiTimeSolts------------");
  }, [multiTimeSolts]);

  useEffect(() => {
    setCampaignName(campaignDispatch, "");
    setAboutCampaign(campaignDispatch, "");

    setAdDuration(campaignDispatch, "");
    setActualDate(campaignDispatch, {});
    setTimeSlots(campaignDispatch, {});
    setBillboards(campaignDispatch, []);
    setApplyToAllDays(campaignDispatch, false);
    setTotalAmount(campaignDispatch, "");
    setFileType(campaignDispatch, "");
    setFile(campaignDispatch, "");
    setTimeSlotsKey(campaignDispatch, {});
  }, []);

  return (
    <>
      <div className="width-90LR ">
        <div
          className="flex-class"
          style={{ marginBottom: "16px", marginTop: "95px" }}
        >
          <Grid container direction="row" justifyContent="start">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                to="/admin/home"
                className="linkcolor"
                underline="hover"
                key="1"
              >
                <Typography className="purplecolortypo fs16px">
                  Dashboard
                </Typography>
              </Link>
              ,
              <Link
                to="/"
                className="linkcolor"
                underline="hover"
                key="2"
                color="inherit"
                href="/material-ui/getting-started/installation/"
              >
                <Typography className="blackcolortypo fs16px">
                  Campaign builder
                </Typography>
              </Link>
              ,
            </Breadcrumbs>
          </Grid>
        </div>
        <Typography className="greycolor505050500 fs25px">
          Campaigns Builder
        </Typography>
        <Grid container>
          <Grid container className="device-container mt16px">
            <Grid
              item
              className="width100 flex"
              sx={{ justifyContent: "space-between" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                className="TabStyleAddCampaign"
                centered={iSm ? true : false}
                variant={iSm ? false : "scrollable"}
                scrollButtons
                aria-label="scrollable force tabs example"
                sx={{ width: "75%" }}
              >
                <Tab label="Campaign" className="TabChangesCampaignNew" />
                <Tab
                  label="Timeslot & Repetition "
                  className="TabChangesCampaignNew"
                />
                <Tab label=" Billboard" className="TabChangesCampaignNew" />

                <Tab label="AdContent " className="TabChangesCampaignNew" />
                <Tab
                  label="Payment & publish"
                  className="TabChangesCampaignNew"
                />
              </Tabs>
              <Grid
                sx={{
                  width: "25%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
                className="flex"
              >
                {value != 0 && (
                  <Button
                    className="tab-back-btn"
                    onClick={handleBack}
                    style={{ marginRight: "8px" }}
                  >
                    Back
                  </Button>
                )}
                <Button
                  className="tab-next-btn"
                  value={value}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <TabPanel value={value} index={0}>
            <CapmaignTab
              TabNext={TabNext}
              data={{
                handleCampaignData,
                campaignName,
                // clientName,
                aboutCampaign,
              }}
              states={{
                minDate,
              }}
              formErrors={formErrors}
              changeFormError={(e) => setFormErrors(e)}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ScheduleTab
              TabPre1={TabPre1}
              TabNext1={TabNext1}
              states={{
                sec,
                setRepeat,
                minsarr,
                repeat,
                minTime,
                minDate,
              }}
              data={{
                handleCampaignData,
                startdate,
                enddate,
                duration,
                budget,
                timeSlot,
                starttime,
                endtime,
                selectSecond,
              }}
              formErrors={formErrors}
              handleCustomTimeChange={handleCustomTimeChange}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <BillBoardTab
              TabPre2={TabPre2}
              TabNext2={TabNext2}
              selectDevice={selectDevice}
              data={{
                billBoardArr,
                deviceArr,
                macIdArr,
                handleCampaignData,
                handleDevicesArr,
                selectDeviceHandler,
                startdate,
                enddate,
                repeat,
                starttime,
              }}
              formErrors={formErrors}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AdContentTab
              TabPre3={TabPre3}
              TabNext3={TabNext3}
              setFiletype={setFiletype}
              videoLink={videoLink}
              setVideoLink={setVideoLink}
              data={{
                handleCampaignData,
                mediafile, //setMediafile
                zipfilename, //setZipfilename
                timmedname, //setTrimmedname
                imagestring, //setImagestring
                imageoriginalname, //setImageoriginalname
                userId, //setUserId
                fileType, //setFiletype
                uploadedContentView,
                videoDimensions,
              }}
              formErrors={formErrors}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={4}>
                <SummaryTab
                  TabPre4={TabPre4}
                  TabNext4={TabNext4}
                  states={{
                    campaignName,
                    aboutCampaign,
                    clientName,
                    startdate,
                    enddate,
                    budget,
                    timeSlot,
                    contentName,
                    contentType,
                    deviceArr,
                    repeat,
                    starttime,
                    endtime,
                    billBoardArr,
                    uploadedContentView,
                    setCampaignName,
                    setClientName,
                    setAboutCampaign,
                    setBillBoardArr,
                    setDeviceArr,
                    setStartDate,
                    setEndDate,
                    setStarttime,
                    setTimeSlot,
                    setBudget,
                    setContentName,
                    setContentType,
                    setMediafile,
                    setTrimmedname,
                    filetype,
                    selectSecond,
                  }}
                />
              </TabPanel> */}
          <TabPanel value={value} index={4}>
            <PaymentTab
              billBoardArr={billBoardArr}
              selectDevice={selectDevice}
              startdate={startdate}
              enddate={enddate}
              repeat={repeat}
              starttime={starttime}
              loading={loading}
              handleUploadimage={handleUploadimage}
              TabPre5={TabPre5}
              submitTimeSlot={submitTimeSlot}
              selectSecond={selectSecond}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={6}>
                <Location />
              </TabPanel> */}
        </Grid>
      </div>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={loading}
        PaperProps={{
          className: "SuccessDialog",
        }}
        BackdropComponent={styled(Backdrop, {
          name: "MuiModal",
          slot: "Backdrop",
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({ zIndex: -1, backdropFilter: "blur(2px)" })}
      >
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", height: "600px" }}
          >
            <CircularProgress style={{ color: " #d723cd" }} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>
      {successPopup && (
        <SuccessDialog
          openData={true}
          id={orderId}
          data={"Advertisment Scheduled"}
        />
      )}
    </>
  );
}
