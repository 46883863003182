import React, { useState, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";
import { FETCH_URL } from "../../../../../fetchIp";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
// ======== pubnub ========== //
// import { usePubNub } from "pubnub-react";
import { Typography, Grid, Divider, Card, Button } from "@mui/material";
import axiosInstance from "../../../../../api/axiosInstance";
import permissionUtils from "../../../../../component/permissionUtils";

import moment from "moment";
ChartJS.register(ArcElement, Tooltip, Legend);

const option = {
  labels: ["Free Memory", "Used Memory"],
};
export default function Submit({ deviceData, getDeviceInfo }) {
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  const [open, setOpen] = React.useState(
    deviceData?.deviceStatus == "Offline" ? false : true
  );
  const [deviceInfo, setDeviceInfo] = useState("");
  const [edit, setEdit] = useState(false);
  const [deviceDataState, setDeviceDataState] = useState(deviceData);
  // device state
  const [deviceUId, setDeviceUId] = useState(deviceData?.deviceUID);
  const [deviceName, setDeviceName] = useState(deviceData?.deviceName);
  const [macId, setMacId] = useState(deviceData?.macId);
  // sleep resolve
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [systemInfo, setSystemInfo] = React.useState([]);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  useEffect(() => {
    if (deviceData) {
      getDeviceInfoo();
      getDeviceInfoById();
      setInterval(() => {
        getDeviceInfoo();
      }, 80000);
    }
  }, [deviceData]);

  const getDeviceInfoo = async () => {
    console.log(
      "//========= getDeviceInfo hum and temp API func() =============//",
      deviceInfo
    );
    let token = JSON.parse(localStorage.getItem("userData")).token;
    const response = await fetch(
      `${FETCH_URL}/api/device/getDeviceTempandHum/${deviceData._id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let res = await response.json();
    if (response.ok) {
      setDeviceInfo(res.msg);
    } else {
      console.log("Device Info Inside GetDeviceInfo() ==> ", res);
    }
  };
  const data = {
    labels: ["Free Memory", "Used Memory"],
    datasets: [
      {
        data: [
          `${deviceInfo?.freeSpace}`,
          deviceInfo?.totalSpace - deviceInfo?.freeSpace,
        ],
        backgroundColor: ["#B937FA", "#000000"],
        hoverBackgroundColor: ["#E141C3", "#000000"],
        borderWidth: 4,
      },
    ],
  };
  const options = {
    legend: {
      display: false,
      position: "right",
    },
    plugins: {
      datalabels: {
        color: "#fffff",
        font: {
          weight: "bold",
          size: 20,
        },
        formatter: (value, ctx) => {
          let label = data.labels[ctx.dataIndex];
          return label + "\n" + value + "%";
        },
      },
    },
  };

  const getDeviceInfoById = async () => {
    try {
      let resp = await axiosInstance.get(
        `/api/device/getDeviceInfo/${deviceData._id}`
      );

      setDeviceDataState(resp.data.msg);
      setSystemInfo(
        resp?.data?.msg?.appInfo[resp?.data?.msg?.appInfo?.length - 1]
      );
    } catch (error) {
      console.log("error from getDeviceInfoById ==> ", error.message);
    }
  };

  // ========= usePubnub ========== //
  // const pubnub = usePubNub();
  // const [channels, setChannels] = useState([deviceData?.macId]);

  // // Pubnub Publish
  // const shutdownFunc = () => {
  //   console.log(" ========== deleteFunc () =======");
  //   pubnub
  //     .publish({ channel: channels[0], message: { cmd: "shutdown" } })
  //     .then(() => {
  //       console.log("=== shutdown cmd executed ===");
  //       getDeviceInfoById();
  //     });
  // };

  // const powerOn = () => {
  //   console.log(" ========== powerOn () =======");
  //   pubnub
  //     .publish({ channel: channels[0], message: { cmd: "force_power_on" } })
  //     .then(() => console.log("=== powerOn cmd executed ==="));
  // };

  const editDeviceHandler = async () => {
    if (macId.trim().length < 16) {
      setSnackerropen(true);
      setSnackErrMsg("Mac Id should be at least 16 characters");
      console.log("stop");
      return;
    }
    if (deviceUId.trim().length === 0) {
      setSnackerropen(true);
      setSnackErrMsg("Device Id is required and cannot be empty");
      console.log("stop");
      return;
    }
    if (deviceName.trim().length === 0) {
      setSnackerropen(true);
      setSnackErrMsg("Device name field is required and cannot be empty");
      console.log("stop");
      return;
    }
    console.log("i am running");
    try {
      const response = await fetch(`${FETCH_URL}/api/device/editDevice`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          deviceUID: deviceUId,
          deviceName,
          macId,
          deviceId: deviceData._id,
        }),
      });
      let res = await response.json();
      if (response.ok) {
        setSnackOpen(true);
        setSnackMsg("Device edit successfully");
        setEdit(!edit);
        console.log("Editing device response  ===>", res.msg);
      } else {
        console.log("Error on  Server ==> ", res.msg);
        setSnackerropen(true);
        setSnackErrMsg(res.msg);
      }
    } catch (error) {
      console.log("Error during editing device  => ", error.message);
    }
  };

  // useEffect(() => {
  //   // if (user?.userRole === 0 || user?.userRole === 1 || user?.userRole === 2) {
  //   // console.log("CHANNELS  ==> ", channels);
  //   pubnub.subscribe({ channels });
  //   // }
  // }, [pubnub, channels]);
  // const [dontRunFirstTime, setDontRunFirstTime] = useState(true);
  // useEffect(() => {
  //   if (dontRunFirstTime) {
  //     setDontRunFirstTime(false);
  //     return;
  //   }
  //   if (open) {
  //     powerOn();
  //   } else {
  //     shutdownFunc();
  //   }
  // }, [open]);
  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={2000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={2000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid
        container
        className="mt25px"
        justifyContent="space-between"
        rowGap="20px"
      >
        <Grid item md={5.8} sm={12}>
          <div className="profileoverview-card  grey-border">
            <Grid container justifyContent="space-between">
              <Typography
                component="h5"
                className="blackcolortypo Detail fs20px mt10px"
              >
                Device Info
              </Typography>
              {PermissionCheck("edit-device") && (
                <>
                  {edit ? (
                    <Button
                      component="h5"
                      className="blackcolortypo Detail fs20px mt10px"
                      onClick={() => editDeviceHandler()}
                      style={{ padding: "0", textTransform: "inherit" }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      component="h5"
                      className="blackcolortypo Detail fs20px mt10px"
                      onClick={() => setEdit(!edit)}
                      style={{ padding: "0", textTransform: "inherit" }}
                    >
                      Edit
                    </Button>
                  )}
                </>
              )}
            </Grid>

            <Divider className="mt10px" />
            <Grid
              container
              className="mt25px"
              style={{ paddingBottom: "15px" }}
            >
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo ">
                  Device ID
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                {edit ? (
                  <input
                    className="blackcolor484848 fs16px no_outline"
                    style={{
                      border: "1px solid #ddd",
                      boxShadow: "0px 1px 10px 0px #0000001a",
                    }}
                    value={deviceUId}
                    type="text"
                    onChange={(e) => setDeviceUId(e.target.value)}
                    disabled={!edit}
                  />
                ) : (
                  <Typography
                    className="blackcolor484848 "
                    sx={{ padding: "2px 3px" }}
                  >
                    {deviceUId}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Device Name
                </Typography>
              </Grid>
              <Grid container item md={8} sm={8} xs={8} alignContent="end">
                {edit ? (
                  <input
                    className="blackcolor484848 fs16px no_outline"
                    style={{
                      border: "1px solid #ddd",
                      boxShadow: "0px 1px 10px 0px #0000001a",
                    }}
                    type="text"
                    value={deviceName}
                    onChange={(e) => setDeviceName(e.target.value)}
                    disabled={!edit}
                  />
                ) : (
                  <Typography
                    className="blackcolor484848 "
                    sx={{ padding: "2px 3px" }}
                  >
                    {deviceName}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  MAC ID
                </Typography>
              </Grid>
              <Grid container item md={8} sm={8} xs={8} alignContent="end">
                {edit ? (
                  <input
                    className="blackcolor484848 fs16px no_outline"
                    style={{
                      border: "1px solid #ddd",
                      boxShadow: "0px 1px 10px 0px #0000001a",
                    }}
                    type="text"
                    value={macId}
                    onChange={(e) => setMacId(e.target.value)}
                    disabled={!edit}
                  />
                ) : (
                  <Typography
                    className="blackcolor484848 "
                    sx={{ padding: "2px 3px" }}
                  >
                    {macId}
                  </Typography>
                )}
              </Grid>{" "}
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Added on
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 mt10px">
                  {new Date(deviceData?.createdAt)
                    .toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                    .replace(/\./g, "/")}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Added by
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 mt10px">
                  Super Admin
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          md={5.8}
          sm={12}
          className="grey-border profileoverview-card"
        >
          <Typography
            component="h5"
            className="blackcolortypo Detail fs20px mt10px "
          >
            Device Status
          </Typography>

          <Divider className="mt10px" />
          <Grid container className="mt25px pl20px">
            <Grid item md={4} sm={4} xs={4}>
              <Typography className="lightgreycolortypo ">Status</Typography>
            </Grid>
            <Grid item md={8} sm={8} xs={8}>
              {deviceInfo && deviceInfo.deviceStatus == "Online" ? (
                <Typography className="greencolortypo_500 ">Online</Typography>
              ) : (
                <Typography className="redcolortypo_500 ">Offline</Typography>
              )}
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
              <Typography className="lightgreycolortypo mt10px ">
                App Version
              </Typography>
            </Grid>
            <Grid item md={8} sm={8} xs={8}>
              <Typography className="redcolortypo_500 mt10px">
                v {deviceInfo && deviceInfo?.appVersion}
              </Typography>
            </Grid>{" "}
            <Grid item md={4} sm={4} xs={4}>
              <Typography className="lightgreycolortypo mt10px ">
                Last Online
              </Typography>
            </Grid>
            <Grid item md={8} sm={8} xs={8}>
              <Typography className="orangecolortypo_500 mt10px">
                {deviceInfo &&
                  moment(deviceInfo?.lastOnlineTime)?.format(
                    "DD/MM/YYYY, h:mm a"
                  )}
              </Typography>
            </Grid>{" "}
            <Grid item md={4} sm={4} xs={4}>
              <Typography className="lightgreycolortypo mt10px ">
                Last Offline
              </Typography>
            </Grid>
            <Grid item md={8} sm={8} xs={8}>
              <Typography className="orangecolortypo_500 mt10px">
                {deviceInfo &&
                  moment(deviceInfo?.lastOfflineTime)?.format(
                    "DD/MM/YYYY, h:mm a"
                  )}
              </Typography>
            </Grid>{" "}
          </Grid>
        </Grid>
        <Grid
          container
          className="mt20px mb20px"
          justifyContent="space-between"
          rowGap="20px"
        >
          <Grid
            item
            md={5.8}
            sm={12}
            className="grey-border profileoverview-card "
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={6}>
                <Typography
                  component="h5"
                  className="blackcolortypo Detail fs20px mt10px "
                >
                  Memory Status
                </Typography>{" "}
              </Grid>
              <Grid container item xs={6}>
                <Grid container justifyContent="flex-end" className="mt15px">
                  <Typography
                    className="blackcolortypo"
                    sx={{ marginRight: "10px" }}
                  >
                    Total Memory:
                  </Typography>
                  <Typography className="greycolor505050500">
                    {deviceInfo?.totalSpace}
                    <span>Gb </span>
                  </Typography>{" "}
                </Grid>
              </Grid>
            </Grid>
            <Divider className="mt10px" />

            <Grid container justifyContent="center">
              {deviceInfo?.totalSpace ? (
                <Grid item md={6} lg={6}>
                  <Doughnut data={data} options={options} />
                </Grid>
              ) : (
                <Grid item sx={{ margin: "80px 0px 80px 0px" }}>
                  <Typography className="greycolor505050 fs20px">
                    Can't Detect Memory!
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            md={5.8}
            sm={12}
            className="grey-border profileoverview-card "
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={6}>
                <Typography
                  component="h5"
                  className="blackcolortypo Detail fs20px mt10px "
                >
                  System Info
                </Typography>{" "}
              </Grid>
            </Grid>
            <Divider className="mt10px" />
            <Grid
              container
              className="mt25px pl20px"
              style={{ paddingBottom: "15px" }}
            >
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  Brand
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.brand ? systemInfo?.brand : "---"}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  Model
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.model ? systemInfo?.model : "---"}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  System Name
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.systemName ? systemInfo?.systemName : "---"}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  System Version
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.systemVersion
                    ? systemInfo?.systemVersion
                    : "---"}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  Bundle ID
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?._id ? systemInfo?._id : "---"}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  App Name
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.appName ? systemInfo?.appName : "---"}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  Build Number
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.buildNumber ? systemInfo?.buildNumber : "---"}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  Version
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.version ? systemInfo?.version : "---"}
                </Typography>
              </Grid>

              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  IP Address
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.ipAddress ? systemInfo?.ipAddress : "---"}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  Resolution width
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.resolutionHeight
                    ? systemInfo?.resolutionWidth
                    : "---"}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  Resolution Height
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.resolutionHeight
                    ? systemInfo?.resolutionHeight
                    : "---"}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  Device Width
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.deviceWidth ? systemInfo?.deviceWidth : "---"}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={4}>
                <Typography className="secondary_graySubheading_500 fs16px mt10px">
                  Device Height
                </Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={8}>
                <Typography className="secondary_heading500 fs16px mt10px">
                  {systemInfo?.deviceHeight ? systemInfo?.deviceHeight : "---"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
