import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { Grid, Typography, TextField, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import axiosInstance from "../../../../../api/axiosInstance";
import Calendar from "../../../../../component/ReactDateRangePicker/ReactDateRangePickerFormonth";
import moment from "moment";
import AdvertiseViseData from "./AdvertiseViseData";
import AnalyticsReport from "./AnalyticsReport";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      // text: "Chart.js Line Chart",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const AllAnalytics = ({ userID, state }) => {
  // analytics states
  const [datasets, setDataSets] = useState([]);
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [date, setDate] = useState();
  const [orderType, setOrderType] = useState("All");
  const [userDetails, setuserDetails] = useState(null);

  const [acceptedOrderAnalyticsData, setAcceptedOrderAnalyticsData] = useState(
    []
  );
  const [rejectedOrderAnalyticsData, setRejectedOrderAnalyticsData] = useState(
    []
  );
  const [publishedOrderAnalyticsData, setPublishedOrderAnalyticsData] =
    useState([]);
  const [viewOrderAnalytics, setViewOrderAnalytics] = useState([]);
  const [revenue, setRevenueAnalytics] = useState([]);
  // analytics states

  // analytics date and datasets
  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };
  const timeInHour = [
    "12:00 Am",
    "1:00 Am",
    "2:00 Am",
    "3:00 Am",
    "4:00 Am",
    "5:00 Am",
    "6:00 Am",
    "7:00 Am",
    "8:00 Am",
    "9:00 Am",
    "10:00 Am",
    "11:00 Am",
    "12:00 Pm",
    "1:00 Pm",
    "2:00 Pm",
    "3:00 Pm",
    "4:00 Pm",
    "5:00 Pm",
    "6:00 Pm",
    "7:00 Pm",
    "8:00 Pm",
    "9:00 Pm",
    "10:00 Pm",
    "11:00 Pm",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels:
      analyticsDate?.length == 24
        ? timeInHour
        : analyticsDate?.length == 12
        ? months
        : analyticsDate,
    datasets: datasets,
  };

  // get analytics api
  const userCardDetail = async () => {
    try {
      const res = await axiosInstance.get(
        `api/billboard/getAnalyticsForUser?isTesting=false&userId=${userID}`
      );
      if (res.status == 200) {
        setuserDetails(res?.data?.data);
      } else {
        console.log("erorr while getting user logs");
      }
    } catch (err) {
      console.log("error while getting userLogs", err);
    }
  };

  useEffect(() => {
    if (userID) {
      userCardDetail();
    }
  }, [userID]);

  const getOrderAnalyticsData = async () => {
    try {
      let body = {
        startDate: moment(date?.[0]?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.[0]?.endDate).format("YYYY-MM-DD"),
        orderType: orderType,
        userId: userID,
        isTesting: false,
      };

      let res = await axiosInstance.post(`/api/order/orderStatsforUser`, body);
      if (res.status == 200 || res.status == 201) {
        if (orderType === "All") {
          setAcceptedOrderAnalyticsData(res?.data?.msg[0]);
          setRejectedOrderAnalyticsData(res?.data?.msg[1]);
          setPublishedOrderAnalyticsData(res?.data?.msg[2]);
        }
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };

  const getOrderViewsAnalyticsData = async () => {
    try {
      let body = {
        startDate: moment(date?.[0]?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.[0]?.endDate).format("YYYY-MM-DD"),
        userId: userID,
        orderType: orderType,
        isTesting: false,
      };
      let res = await axiosInstance.post(`/api/order/orderViewsforUser`, body);
      if (res.status == 200 || res.status == 201) {
        setViewOrderAnalytics(res?.data?.msg);
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };

  // const getrevenueAnalyticsData = async () => {
  //   try {
  //     let body = {
  //       startDate: moment(date?.[0]?.startDate).format("YYYY-MM-DD"),
  //       endDate: moment(date?.[0]?.endDate).format("YYYY-MM-DD"),
  //       userId: userID,
  //       orderType: orderType,
  //       isTesting: false,
  //     };
  //     let res = await axiosInstance.post(
  //       `/api/order/orderRevenueforBillboard`,
  //       body
  //     );
  //     console.log("response from getting revenue analytics data =>", res);
  //     if (res.status == 200 || res.status == 201) {
  //       setRevenueAnalytics(res?.data?.msg);
  //     }
  //   } catch (err) {
  //     console.log("error from revenue anlytics data => ", err);
  //   }
  // };
  const getrevenueAnalyticsData = async () => {
    try {
      let body = {
        startDate: moment(date?.[0]?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.[0]?.endDate).format("YYYY-MM-DD"),
        userId: userID,
        isTesting: false,
      };
      let res = await axiosInstance.post(`/api/order/orderRevenueStats`, body);
      if (res.status == 200 || res.status == 201) {
        setRevenueAnalytics(res?.data?.msg);
      }
    } catch (err) {
      console.log("error from revenue anlytics data => ", err);
    }
  };

  // making an empty array and pushing all data in (data with 0 count)
  let acceptedData = [];
  let rejectedData = [];
  let publishedData = [];
  let viewsData = [];
  let revenueData = [];
  useEffect(() => {
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = acceptedOrderAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          totalCount: acceptedOrderAnalyticsData[index].totalCount,
        };
        acceptedData.push(obj);
      } else {
        let obj = { _id: analyticsDate[i], totalCount: 0 };
        acceptedData.push(obj);
      }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = rejectedOrderAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          totalCount: rejectedOrderAnalyticsData[index].totalCount,
        };
        rejectedData.push(obj);
      } else {
        let obj = { _id: analyticsDate[i], totalCount: 0 };
        rejectedData.push(obj);
      }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = publishedOrderAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          totalCount: publishedOrderAnalyticsData[index].totalCount,
        };
        publishedData.push(obj);
      } else {
        let obj = { _id: analyticsDate[i], totalCount: 0 };
        publishedData.push(obj);
      }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = viewOrderAnalytics?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          totalViews: viewOrderAnalytics[index].totalViews,
        };
        viewsData.push(obj);
      } else {
        let obj = { _id: analyticsDate[i], totalViews: 0 };
        viewsData.push(obj);
      }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = revenue?.findIndex((item) => item._id === analyticsDate[i]);
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          totalViews: revenue[index].totalViews,
        };
        revenueData.push(obj);
      } else {
        let obj = { _id: analyticsDate[i], totalViews: 0 };
        revenueData.push(obj);
      }
    }
  }, [
    acceptedOrderAnalyticsData,
    rejectedOrderAnalyticsData,
    publishedOrderAnalyticsData,
    viewOrderAnalytics,
    revenue,
  ]);

  useEffect(() => {
    if (!date && userID) {
      return;
    }
    if (orderType === "views") {
      getOrderViewsAnalyticsData();
      return;
    }
    if (orderType === "revenue") {
      getrevenueAnalyticsData();
      return;
    }
    if (userID) {
      getOrderAnalyticsData();
    }
  }, [orderType, date]);

  useEffect(() => {
    if (orderType === "All") {
      setDataSets([
        {
          label: "Accepted",
          data: acceptedData?.map((a) => a?.totalCount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          borderRadius: 20,
          barThickness: 24,
        },
        {
          label: "Rejected",
          data: rejectedData?.map((a) => a?.totalCount),
          borderColor: "rgb(246,14,14,100%)",
          backgroundColor: "rgba(246,14,14,100%)",
          borderRadius: 20,
          barThickness: 24,
        },
        {
          label: "Published",
          data: publishedData?.map((a) => a?.totalCount),
          borderColor: "rgb(215,35,205,100%)",
          backgroundColor: "rgba(215,35,205,100%)",

          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "views") {
      setDataSets([
        {
          label: "View",
          data: viewsData?.map((a) => a?.totalViews),
          borderColor: "rgb(246,14,14,100%)",
          backgroundColor: "rgba(246,14,14,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "revenue") {
      setDataSets([
        {
          label: "Amount",
          data: revenueData?.map((a) => a?.totalViews),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    }
  }, [
    orderType,
    acceptedOrderAnalyticsData,
    viewOrderAnalytics,
    rejectedOrderAnalyticsData,
    publishedOrderAnalyticsData,
    revenue,
  ]);

  return (
    <>
      <div>
        {state === "NormalUser" && <AnalyticsReport userId={userID} />}

        <Typography
          className="blackcolor484848 fs20px"
          sx={{ marginTop: "10px" }}
        >
          Check All-Time Data
        </Typography>
        <Grid
          container
          sx={{
            padding: "10px",
            marginBottom: "24px",
            borderRadius: "8px",
            marginTop: "10px",

            boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
          }}
          rowGap="10px"
        >
          <Grid
            container
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">Campaign</Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {userDetails?.campaignLength}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">Order</Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {userDetails?.orderLength}{" "}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Order Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {" "}
                ₹ {userDetails?.orderAmount}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Male views
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {userDetails?.billMaleViews}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Female Views
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {userDetails?.billFemaleViews}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Total View
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {userDetails?.totalViews}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Typography className="blackcolor484848 fs20px">
        Check Detailed Analytics
      </Typography>
      <div style={{ paddingBottom: "10px" }}>
        <Grid
          container
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            paddingTop: "10px",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            rowGap="10px"
            sx={{ marginBottom: "10px" }}
          >
            <Grid item>
              <Grid container item sx={{ padding: "0 10px" }}>
                <FormControl
                  sx={{
                    minWidth: 300,
                    width: "100%",
                    fontSize: "10px",
                    border: "1px solid #DDDDDD",
                    fontFamily: "oswald",
                  }}
                  className="form_control_div"
                  size="small"
                >
                  <TextField
                    select
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle-noborder analytics_dropDown"
                    labelid="demo-select-small"
                    id="demo-select-small"
                    inputProps={{ "aria-label": "Without label" }}
                    value={orderType}
                    defaultValue={0}
                    onChange={(e) => {
                      setOrderType(e.target.value);
                      // getFilteredBillboards();
                    }}
                  >
                    <MenuItem value={"revenue"} className="Selectmenustyle">
                      <Typography className="Selectmenustyle fs16px  ">
                        Booking Amount
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"All"} className="Selectmenustyle">
                      <Typography className="Selectmenustyle fs16px  ">
                        Orders
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"views"} className="Selectmenustyle">
                      <Typography className="Selectmenustyle fs16px  ">
                        Views
                      </Typography>
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid
                  container
                  item
                  columnGap="10px"
                  sx={{ padding: "0 10px" }}
                  alignItems="center"
                >
                  <Calendar getDataFromChildHandler={getDataFromChildHandler} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Bar options={options} data={data} />
        </Grid>
        <AdvertiseViseData userID={userID} />
      </div>
    </>
  );
};

export default AllAnalytics;
