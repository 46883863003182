import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useMediaQuery, useTheme, Box } from "@mui/material";

// ** Core Component
import TabList from "../../../component/CustomTabs/TabList";
import permissionUtils from "../../../component/permissionUtils";
import TabPanelList from "./../../../component/CustomTabs/TabPanel";
import Coupon from "./Coupon&VocherTabPanels/CouponTabPanel/Coupon";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";
import Voucher from "./Coupon&VocherTabPanels/VoucherTabPanel/Voucher";

export default function CouponVocherTab() {
  const theme = useTheme();
  const location = useLocation();
  const { state } = location;
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [value, setValue] = React.useState(0);
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();

  const permissionList = ["view-voucher", "view-coupons"];

  const getTabLabel = (permission) => {
    const labelMap = {
      "view-voucher": "Voucher",
      "view-coupons": "Coupons",
    };
    return labelMap[permission];
  };
  const permissions = CheckTabsPermissions(permissionList);

  const panels = [
    {
      permission: "view-voucher",
      component: <Voucher />,
    },
    {
      permission: "view-coupons",
      component: <Coupon />,
    },
  ];

  const breadcrumbs = [
    { label: "Dashboard", link: "/" },
    { label: "Coupon&Vocher" },
  ];

  // ** Function Calling

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(state?.tabValue ? state?.tabValue : 0);
  }, [location]);

  return (
    <div className="width-90LR">
      <div className="flex-class">
        <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
      </div>
      <Box sx={{ borderBottom: 1, mb: 4, borderColor: "divider" }}>
        <TabList
          md={md}
          value={value}
          centered={false}
          classes={{ Tabs: "orderTab", Tab: "Order_TabChangeDevice" }}
          handleChange={handleChange}
          getTabLabel={getTabLabel}
          permissions={permissions}
        />
      </Box>
      <TabPanelList value={value} permissions={permissions} panels={panels} />{" "}
    </div>
  );
}
