import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Button,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import walletImage from "../../../../../assets/Img/3d_wallet_10 1.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AddToWalletPageHandler from "./AddToWalletPageHandler";
import { FETCH_URL } from "../../../../../fetchIp";
import { object } from "yup";
const SelectUser = ({ selectUserPageHandler }) => {
  const [user, setUser] = React.useState("");
  const [searchInput, setSearchInput] = useState("");
  const [addMoneyToWallet, setAddMoneyToWallet] = useState(false);
  // userDataState
  const [userData, setuserData] = useState([]);
  const [individualUserData, setIndividualUserData] = useState({});
  const handleChange = (event) => {
    setUser(event.target.value);
  };
  // selectUserFunction
  const userSelectHandler = (e, uData) => {
    console.log("clicked >>>>>>>>>>>>");
    setIndividualUserData(uData);
  };

  const addMoneyToWalletPageHandler = (bool) => {
    if (Object.keys(individualUserData).length === 0) {
      return;
    }
    setAddMoneyToWallet(bool);
  };
  const Item = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(1),
  }));
  // searchInputHandler
  const serchInputHandler = async () => {
    console.log(user, "user");
    console.log(searchInput, "searchInput");
    try {
      const response = await fetch(
        `${FETCH_URL}/api/user/searchuser?userEmail=${searchInput}&userRole=${user}&limit=20`
      );
      let res = await response.json();
      if (response.ok) {
        setuserData(res.msg.result);
        console.log("get users  ===>", res.msg);
      } else {
        console.log("Error in getting users ==> ", res.msg);
      }
    } catch (error) {
      console.log("Error in getting users  => ", error.message);
    }
  };

  useEffect(() => {
    serchInputHandler();
  }, [user, searchInput]);

  return (
    <>
      {!addMoneyToWallet ? (
        <>
          <Grid container>
            <Grid
              container
              item
              xs={12}
              className="purple-gradient whitecolortypo500 relative_Grid fs16px"
              style={{ borderRadius: "8px 8px 0px 0px", padding: "10px 0" }}
              justifyContent="center"
            >
              <Typography>Deposit</Typography>
              <Button
                onClick={() => selectUserPageHandler(false)}
                className="absolute_btn fs16px"
              >
                Back
              </Button>
            </Grid>

            <Grid
              container
              item
              xs={12}
              style={{
                border: "1px solid #ddd",
                borderTop: "none",
                padding: "20px 0",
              }}
            >
              {/* Postmyad Wallet */}
              <Grid
                container
                item={12}
                alignItems="center"
                direction="column"
                sx={{ padding: "20px 0" }}
              >
                <Grid item>
                  <img src={walletImage} alt="walletImage" />
                </Grid>
                <Grid item>
                  <Typography className="purplecolortypo fs24px">
                    Postmyad Wallet
                  </Typography>
                </Grid>
                <Grid item>
                  {" "}
                  <Typography className="blackcolor525252_400 fs16px">
                    An easy way to pay and get refund
                  </Typography>
                </Grid>
              </Grid>
              {/* Search User */}
              <Grid
                container
                item={12}
                sx={{ borderTop: "1px solid #ddd", padding: "20px" }}
                gap="40px"
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  gap="20px"
                  alignItems="center"
                >
                  <Grid item>
                    <Grid container sx={{ height: "100%" }} alignItems="center">
                      {" "}
                      <Typography className="blackcolor484848 fs16px">
                        Select user
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item style={{ width: "400px" }}>
                    <div className="search_input">
                      <SearchIcon className="search_inputIcon" />
                      <input
                        placeholder="Enter user ID or Name"
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item sx={{ width: "150px" }}>
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                      className="select_wallet"
                      size="small"
                    >
                      <InputLabel id="demo-select-small">
                        Select User{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={user}
                        label="Select User"
                        onChange={handleChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Admin"}>Admin</MenuItem>
                        <MenuItem value={"AdAgency"}>Ad-Agency</MenuItem>
                        <MenuItem value={"ClusterHead"}>Clusterhead</MenuItem>
                        <MenuItem value={"BusinessOwner"}>
                          Business Owner
                        </MenuItem>
                        <MenuItem value={"NormalUser"}>Normal User</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {/*  */}
                <Grid container item xs={12}>
                  {userData?.map((uData, i) => (
                    <Grid item xs={12} sm={6} md={3} lg={2.4} key={i}>
                      <Item>
                        <Grid
                          container
                          item
                          xs={12}
                          sx={{
                            padding: "10px 0",
                            border: "1px solid #E4E6EF",
                            borderRadius: "8px",
                          }}
                          alignItems="center"
                        >
                          <Grid
                            container
                            item
                            xs={2}
                            justifyContent="center"
                            alignItems="center"
                          >
                            {/* <input
                              type="radio"
                              id={`dewey${i}`}
                              name="drone"
                              value="dewey"
                              // checked={false}
                              onClick={(e) => userSelectHandler(uData)}
                            /> */}
                            <FormControlLabel
                              className="radiostyle access-radio-formcontrolabel"
                              value={uData?._id}
                              style={{ paddingTop: "10px" }}
                              control={<Radio />}
                              key={uData?._id}
                              onChange={(e) => userSelectHandler(e, uData)}
                            />
                          </Grid>
                          <Grid container item xs={10}>
                            <Grid xs={12}>
                              <Typography className="greycolor505050400 fs16px text_transform_capitalize">
                                {uData?.firstName ? uData?.firstName : "-"}{" "}
                                {uData?.lastName ? uData?.lastName : "-"}
                              </Typography>
                            </Grid>
                            <Grid xs={12}>
                              <Typography className="greycolor505050400">
                                #{uData?._id?.slice(8, 16)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {/* next Button */}
              <Grid
                container
                item
                xs={12}
                style={{ padding: "0 20px" }}
                justifyContent="flex-end"
              >
                <Button
                  className="btn_primary"
                  onClick={() => addMoneyToWalletPageHandler(true)}
                  style={{ border: "1px solid #e141c3", padding: "0" }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <AddToWalletPageHandler
          individualUserData={individualUserData}
          addMoneyToWalletPageHandler={addMoneyToWalletPageHandler}
        />
      )}
    </>
  );
};

export default SelectUser;
