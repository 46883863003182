import React, { useEffect, useState, useRef } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { Grid, Typography, TextField, Button } from "@mui/material";

import axiosInstance from "../../../../../api/axiosInstance";
// temp
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css
import Calendar from "../../../../../component/ReactDateRangePicker/ReactDateRangePicker";

// temp
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      // text: "Chart.js Line Chart",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

const Deposit_Analytics = ({ bool }) => {
  const refOne = useRef(null);
  const [range, setRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateType, setDateType] = useState("last7days");
  const [datasets, setDataSets] = useState([]);
  const [date, setDate] = useState();
  const [orderType, setOrderType] = useState("All");
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const timeInHour = [
    "12:00 Am",
    "1:00 Am",
    "2:00 Am",
    "3:00 Am",
    "4:00 Am",
    "5:00 Am",
    "6:00 Am",
    "7:00 Am",
    "8:00 Am",
    "9:00 Am",
    "10:00 Am",
    "11:00 Am",
    "12:00 Pm",
    "1:00 Pm",
    "2:00 Pm",
    "3:00 Pm",
    "4:00 Pm",
    "5:00 Pm",
    "6:00 Pm",
    "7:00 Pm",
    "8:00 Pm",
    "9:00 Pm",
    "10:00 Pm",
    "11:00 Pm",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };

  function dateRange(startDate, endDate, steps = 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    return dateArray;
  }

  useEffect(() => {
    if (dateType === "last7days") {
      let d = new Date(); // today!
      let previousDay = d.setDate(d.getDate() - 1);
      let x = 6; // go back 7 days!
      d.setDate(d.getDate() - x);
      setAnalyticsDate(dateRange(d, previousDay));
    }
  }, [dateType]);

  const data = {
    labels:
      analyticsDate?.length == 24
        ? timeInHour
        : analyticsDate?.length == 12
        ? months
        : analyticsDate,
    datasets: datasets,
  };
  //
  const [open, setOpen] = useState(false);

  // get OrderAnalyics

  const [netRevenue, setNetRevenue] = useState([]);
  const [grossRevenue, setGrossRevenue] = useState([]);
  // GET order count, revenue and views  ()  by billboardId

  const getrevenueAnalyticsData = async () => {
    try {
      let body = {
        startDate: moment(date?.[0]?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.[0]?.endDate).format("YYYY-MM-DD"),
      };
      let res = await axiosInstance.post(`/api/revenue/analytics`, body);
      // console.log("response from getting revenue analytics data =>", res);
      if (res.status == 200 || res.status == 201) {
        setNetRevenue(res?.data?.msg[0]);
        setGrossRevenue(res?.data?.msg[1]);
      }
    } catch (err) {
      console.log("error from revenue anlytics data => ", err);
    }
  };

  useEffect(() => {
    if (dateType === "last7days") {
      const endDate = new Date().toISOString()?.split("T")[0];
      let d = new Date(); // today!
      let x = 7; // go back 7 days!
      d.setDate(d.getDate() - x);
      setStartDate(d.toISOString()?.split("T")[0]);
      setEndDate(endDate);
    }
  }, [dateType, range]);

  // date logics

  let revenueData = [];
  let revenueDataTwo = [];

  useEffect(() => {
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = netRevenue?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          totalRevenue: netRevenue[index].totalRevenue,
        };
        revenueData.push(obj);
      } else {
        let obj = { _id: analyticsDate[i], totalRevenue: 0 };
        revenueData.push(obj);
      }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = grossRevenue?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          totalRevenue: grossRevenue[index].totalRevenue,
        };
        revenueDataTwo.push(obj);
      } else {
        let obj = { _id: analyticsDate[i], totalRevenue: 0 };
        revenueDataTwo.push(obj);
      }
    }
  }, [netRevenue, grossRevenue]);

  // useEffect(() => {
  //   if (endDate || startDate) {
  //     getrevenueAnalyticsData();
  //   }
  // }, [endDate, startDate]);

  useEffect(() => {
    if (!date) {
      return;
    }
    getrevenueAnalyticsData();
  }, [date]);

  useEffect(() => {
    if (bool === "Deposit") {
      setDataSets([
        {
          label: "Deposit",
          data: revenueData?.map((a) => a?.totalRevenue),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    }

    if (bool === "Gross Revenue") {
      setDataSets([
        {
          label: "Gross Revenue",
          data: revenueDataTwo?.map((a) => a?.totalRevenue),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    }
  }, [netRevenue, grossRevenue, bool]);
  const [netRevenueReducer, setNetRevenueReducer] = useState(0);
  const [grossRevenueReducer, setGrossRevenueReducer] = useState(0);

  useEffect(() => {
    const netRevenueCount = netRevenue.reduce((accum, elem) => {
      return (accum = accum + elem?.totalRevenue);
    }, 0);
    const grossRevenueCount = grossRevenue.reduce((accum, elem) => {
      return (accum = accum + elem?.totalRevenue);
    }, 0);
    setNetRevenueReducer(netRevenueCount);
    setGrossRevenueReducer(grossRevenueCount);
  }, [netRevenue, grossRevenue]);

  return (
    <Grid container>
      <Grid
        container
        justifyContent="space-between"
        sx={{ padding: " 0 10px" }}
      >
        <Grid item>
          <Typography className="greycolor505050400 fs20px">
            {bool === "Deposit"
              ? `Deposit : ${
                  netRevenueReducer ? netRevenueReducer?.toFixed(2) : 0
                }`
              : `Gross Revenue : ${
                  grossRevenueReducer ? grossRevenueReducer?.toFixed(2) : 0
                }`}
          </Typography>
        </Grid>
        {/* <Grid item>
          <Typography className="purplecolortypo">See All</Typography>
        </Grid> */}
        <Grid item>
          <Calendar getDataFromChildHandler={getDataFromChildHandler} />
        </Grid>
      </Grid>

      <Grid xs={12}>
        {" "}
        <Bar options={options} data={data} />
      </Grid>
    </Grid>
  );
};

export default Deposit_Analytics;
