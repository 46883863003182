import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  Box,
  Stack,
  Select,
  InputLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
// pagination
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import TimeSlotsDialogue from "./Camp_Dialog's/TimeSlotsDialogue";
import axiosInstance from "../../../../../api/axiosInstance";
import moment from "moment";
import no_data_img from "../../../../../assets/Img/no_data_found_img.png";
import TableSkeleton from "../../../../../component/TableSkeleton/TableSkeleton";
const headCells = [
  {
    id: "UID",
    numeric: true,
    label: "UID",
  },
  {
    id: "Billboard Name",
    numeric: true,
    disablePadding: false,
    label: "Billboard Name  ",
  },
  {
    id: "Pincode",
    numeric: true,
    disablePadding: false,
    label: "Pincode ",
  },
  {
    id: "Date",
    numeric: true,
    disablePadding: false,
    label: "Date   ",
  },
  {
    id: "Hourslot",
    numeric: true,
    disablePadding: false,
    label: "Hourslot",
  },
  {
    id: "Repetition",
    numeric: true,
    disablePadding: false,
    label: "Repetition ",
  },
  // {
  //   id: "Status",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Downloading Status",
  // },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const ProgressTab = (props) => {
  const [loading, setLoading] = useState(true);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [campaignProgress, setCampaignProgress] = useState([]);
  // pagination
  const [totalPage, setTotalPage] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [allFilterSlot, setAllFilterSlot] = useState([]);
  const [allFilterDate, setAllFilterDate] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectDate, setSelectDate] = useState(1);
  const [imagefile, setImageFile] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [multiStartHour, setmultiStartHour] = useState([]);
  const [dates, setDates] = useState([]);

  // paginations
  // const handleChangeByPage = (event, value) => {
  //   console.log("Page Number Clicked ==> ", value);
  //   setCurrentPage(value);
  //   getCampaignProgress(selectDate, value);
  // };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getProgressData = async (time) => {
    if (time) {
      try {
        let res = await axiosInstance.get(
          `/api/order/getCampaignBillProgressByOrderId?date=${moment(
            selectDate
          )?.format("YYYY-MM-DD")}&startHour=${time}&orderId=${
            props?.data?._id
          }`
        );

        console.log("response from progress =>", res);
        if (res.status == 200 || res.status == 201) {
          //  setAcceptedOrderAnalyticsData(res?.data.data);

          setCampaignProgress(res?.data?.data);
          setLoading(false);
        }
      } catch (err) {
        console.log("error from from setgetAdvertiseViseData => ", err);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (selectedSlot?.time) {
      getProgressData();
      getRangebetweenDates(
        props?.data?.startscheduleDate,
        props?.data?.endscheduleDate
      );
    }
  }, [props, selectedSlot, selectDate]);
  function getRangebetweenDates(startDate, endDate, type = "days") {
    let fromDate = moment(startDate);
    let toDate = moment(endDate);
    let diff = toDate.diff(fromDate, type);
    let range = [];
    for (let i = 0; i <= diff; i++) {
      range.push(moment(startDate).add(i, type).format("YYYY-MM-DD"));
    }
    setDates(range);
  }
  const getStartHourData = async (date) => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getTimeSlotByDate?orderId=${
          props?.data?._id
        }&date=${moment(selectDate)?.format("YYYY-MM-DD")} `
      );
      if (res.status == 200) {
        if (res?.data?.data?.hours?.length > 0) {
          let newTimes = tConvert(res?.data?.data?.hours);
          console.log(newTimes, res?.data?.data?.hours);

          setSelectedSlot(newTimes[0]);
          setAllFilterSlot(newTimes);
          getProgressData(res?.data?.data?.hours[0]);
        }
      } else {
        console.log(res, "failed res after api call ");
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (selectDate) {
      getStartHourData();
      // getCampaignProgress();
    }
  }, [selectDate]);
  function tConvert(time) {
    const times = [...new Set(time)];
    let newTimes = [];
    times.map((data) => {
      let obj = {};
      if (data < 4) {
        if (data === 0) obj["actualTime"] = "12am - 1am";
        else obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 3 && data < 8) {
        obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 7 && data < 12) {
        if (data === 11) obj["actualTime"] = "11am - 12pm";
        else obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 11 && data < 16) {
        if (data === 12) obj["actualTime"] = `12pm - ${data - 12 + 1}pm`;
        else obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      } else if (data > 15 && data < 20) {
        obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      } else if (data > 19) {
        if (data === 23) obj["actualTime"] = `11pm - ${data - 12 + 1}am`;
        else obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      }
      obj["time"] = data;
      newTimes.push(obj);
    });

    return newTimes;
  }

  // console.log("selected >......",selectedSlot.time)
  useEffect(() => {
    // console.log(
    //   "tConvert(props?.data?.startHour)",
    //   );
    tConvert(multiStartHour);
    // setTimeSlot(tConvert(props?.data?.startHour));
  }, [multiStartHour]);

  useEffect(() => {
    console.log("props anaalityics", props?.data);
    let tempDate = [];
    if (props?.data?.multiTimes) {
      tempDate = props?.data?.multiTimes.map((item) => item?.newDate);
    }
    setAllFilterDate([...new Set(tempDate)]);
    setSelectDate(tempDate[0]);
  }, [props]);
  useEffect(() => {
    console.log("props anaalityics", props?.data);
    let tempStart = [];
    if (props?.data?.multiTimes) {
      tempStart = props?.data?.multiTimes.map((item) => item?.startHour);
    }
    setmultiStartHour(tempStart);
  }, [props]);
  return (
    <div>
      <Grid container gap="20px">
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography className="blackcolor484848 fs20px">
              Campaign Progress
            </Typography>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
              <InputLabel id="demo-select-small"> Select Date </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={selectDate}
                label="Select Date"
                onChange={(e) => setSelectDate(e.target.value)}
              >
                {allFilterDate?.map((item, i) => (
                  <MenuItem value={item} key={i}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
              <InputLabel id="demo-select-small"> Select Hour Slot </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={selectedSlot}
                label="       Select Hour Slot"
                onChange={(e) => {
                  setSelectedSlot(e.target.value);
                  getProgressData(e.target.value?.time);
                }}
              >
                {allFilterSlot?.map((item, i) => (
                  <MenuItem value={item} key={i}>
                    {item?.actualTime}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item>
          <div style={{ marginBottom: "20px", width: "100%" }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer className="width100  table_border_radius">
              <Table
                className="hide_sortBar"
                sx={{
                  minWidth: 1000,
                  width: "100%",
                }}
                style={{ tableLayout: "fixed" }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  className="tablerow "
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />
                {campaignProgress?.length > 0 ? (
                  <TableBody className="tablerow ">
                    {campaignProgress?.map((data, i) => (
                      <>
                        <TableRow
                          hover
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            // id={labelId}

                            scope="row"
                            padding="none"
                            sx={{ width: "16.66%" }}
                          >
                            {" "}
                            <Typography className="greycolor1c1c1c fs16px ">
                              {data?.billId?.slice(-12)}
                            </Typography>
                          </TableCell>

                          <TableCell
                            component="th"
                            // id={labelId}
                            scope="row"
                            padding="none"
                            sx={{ width: "16.66%" }}
                          >
                            <Typography className="greycolor1c1c1c fs16px ">
                              {data?.billName ? data?.billName : ""}
                            </Typography>
                          </TableCell>
                          <TableCell
                            ccomponent="th"
                            // id={labelId}
                            scope="row"
                            padding="none"
                            sx={{ width: "16.66%" }}
                          >
                            <Typography className="greycolor1c1c1c fs16px ">
                              {data?.pincode ? data?.pincode : ""}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            // id={labelId}
                            scope="row"
                            padding="none"
                            sx={{ width: "16.66%" }}
                          >
                            <Typography className="greycolor1c1c1c fs16px ">
                              {data?.formattedDate
                                ? data?.formattedDate
                                : data?.date}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            // id={labelId}
                            scope="row"
                            padding="none"
                            sx={{ width: "16.66%" }}
                          >
                            <Typography className="greycolor1c1c1c fs16px ">
                              {selectedSlot?.actualTime}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            // id={labelId}
                            scope="row"
                            padding="none"
                            sx={{ width: "16.66%" }}
                          >
                            <Typography className="greycolor1c1c1c fs16px ">
                              <TimeSlotsDialogue
                                progressData={data}
                                // campaignData={props}
                              />
                              {/* <span className="greencolortypo_500 fs18px">
                                {" "}
                                {data?.successCount}
                              </span>
                              /{data?.pendingCount}
                              {data?.repetitions} */}
                            </Typography>
                          </TableCell>
                          {/* <TableCell
                            component="th"
                     
                            scope="row"
                            padding="none"
                            sx={{ width: "16.66%" }}
                          >
                     
                            {data?.orderLogs?.contentStatus == "success"
                              ? "Completed"
                              : data?.orderLogs?.contentStatus == "pending"
                              ? "Pending"
                              : "Failed"}
                          </TableCell> */}
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                ) : (
                  loading && (
                    <TableSkeleton
                      rowNumber={new Array(10).fill(0)}
                      tableCell={[
                        "16.66%",
                        "16.66%",
                        "16.66%",
                        "16.66%",
                        "16.66%",
                        "16.66%",
                      ]}
                    />
                  )
                )}
              </Table>
            </TableContainer>
            {!campaignProgress?.length > 0 && !loading && (
              <Grid container justifyContent="center">
                {" "}
                <img
                  src={no_data_img}
                  alt="no data found"
                  style={{ width: "40%" }}
                />
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProgressTab;
