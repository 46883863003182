import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Grid,
  Radio,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";

export default function CustomizedDialogs({
  value,
  open,
  watch,
  dimension,
  setOpen,
  control,
  errors,
  trigger,
  dimensionUnit,
  handleDimensionChange,
  validateDimension,
  resizeMode,
  setResizeMode,
  setDimension,
  Controller,
  handleSubmit,
  onSubmit,
}) {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name && name.startsWith("dimension")) {
        setDimension({
          height:
            dimensionUnit === "fixedPixel"
              ? `${parseFloat(value.dimension.height)}px`
              : `${parseFloat(value.dimension.height)}%`,
          width:
            dimensionUnit === "fixedPixel"
              ? `${parseFloat(value.dimension.width)}px`
              : `${parseFloat(value.dimension.width)}%`,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, dimensionUnit]);

  useEffect(() => {
    if (open) {
      const { height, width } = watch("dimension");
      const newDimension = {
        height: dimensionUnit === "fixedPixel" ? `${height}px` : `${height}%`,
        width: dimensionUnit === "fixedPixel" ? `${width}px` : `${width}%`,
      };
      setDimension(newDimension);
      setResizeMode(watch("resizeMode"));
      setImageUrl(
        `https://via.placeholder.com/${parseInt(newDimension.width)}/${parseInt(
          newDimension.height
        )}`
      );
    }
  }, [open, dimensionUnit, watch("dimension"), watch("resizeMode")]);

  const orientation = watch("orientation");
  const position = watch("position");

  // Calculate preview container styles
  const previewStyles = {
    height: "100%",
    width: "100%",
    display: "flex",
    maxHeight: dimensionUnit === "fixedPixel" ? "600px" : "",
    maxWidth: "100%",
    justifyContent: position?.includes("Left")
      ? "flex-start"
      : position?.includes("Right")
      ? "flex-end"
      : "center",
    alignItems: position?.includes("top")
      ? "flex-start"
      : position?.includes("bottom")
      ? "flex-end"
      : "center",
    overflow: "hidden",
    border: "1px solid #e701b1",
    borderRadius: "8px",
    backgroundColor: "#ddd",
    marginTop:
      dimensionUnit === "fixedPixel" && orientation === "landscape"
        ? "80px"
        : "",
    transform: orientation === "landscape" ? "rotate(90deg)" : "none",
  };

  return (
    <div>
      {value === "advance" && (
        <Button variant="outlined" onClick={() => setOpen(true)}>
          View
        </Button>
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogTitle className="dialogtitle">
          <Typography className="whitecolortypo500">Advance Setting</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            size="medium"
            className="dialogcrossicon"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <IoMdClose />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* Settings */}
                <Grid
                  container
                  p={1.2}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    fontWeight="700"
                    variant="h6"
                    width={"100%"}
                    gutterBottom
                  >
                    Orientation
                  </Typography>
                  <Controller
                    name="orientation"
                    control={control}
                    render={({ field }) => (
                      <>
                        {["portrait", "landscape"].map((value) => (
                          <FormControlLabel
                            key={value}
                            {...field}
                            value={value}
                            control={<Radio />}
                            label={
                              value.charAt(0).toUpperCase() + value.slice(1)
                            }
                            checked={field.value === value}
                          />
                        ))}
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  container
                  p={1.2}
                  mt={2}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    fontWeight="700"
                    variant="h6"
                    width={"100%"}
                    gutterBottom
                  >
                    Dimension
                  </Typography>
                  {/* <FormControlLabel
                    value="fixedPixel"
                    control={<Radio />}
                    label="Fixed Pixel"
                    checked={dimensionUnit === "fixedPixel"}
                    onChange={handleDimensionChange}
                  /> */}
                  <FormControlLabel
                    value="percentage"
                    control={<Radio />}
                    label="Percentage"
                    checked={dimensionUnit === "percentage"}
                    onChange={handleDimensionChange}
                  />
                  <Grid container spacing={2}>
                    {["height", "width"].map((dim) => (
                      <Grid item xs={6} key={dim} mt={1}>
                        <Controller
                          name={`dimension.${dim}`}
                          control={control}
                          rules={{
                            required: `${
                              dim.charAt(0).toUpperCase() + dim.slice(1)
                            } is required`,
                            validate: (value) =>
                              validateDimension(value, dimensionUnit),
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              label={dim.charAt(0).toUpperCase() + dim.slice(1)}
                              placeholder={
                                dimensionUnit === "fixedPixel" ? "px" : "%"
                              }
                              helperText={
                                errors.dimension?.[dim]
                                  ? errors.dimension[dim].message
                                  : dimensionUnit === "fixedPixel"
                                  ? `e.g., 1080 (In Pixel)`
                                  : `e.g., 50 (In Percentage)`
                              }
                              error={!!errors.dimension?.[dim]}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger(`dimension.${dim}`); // Validate on change
                              }}
                            />
                          )}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  container
                  p={1.2}
                  mt={2}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    fontWeight="700"
                    variant="h6"
                    width={"100%"}
                    gutterBottom
                  >
                    Position
                  </Typography>
                  <Controller
                    name="position"
                    control={control}
                    render={({ field }) => (
                      <>
                        {[
                          "center",
                          "topLeft",
                          "topRight",
                          "bottomLeft",
                          "bottomRight",
                        ].map((value) => (
                          <FormControlLabel
                            key={value}
                            {...field}
                            value={value}
                            control={<Radio />}
                            label={value
                              .replace(/([A-Z])/g, " $1")
                              .replace(/^./, (str) => str.toUpperCase())}
                            checked={field.value === value}
                          />
                        ))}
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  container
                  p={1.2}
                  mt={2}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    fontWeight="700"
                    width={"100%"}
                    variant="h6"
                    gutterBottom
                  >
                    Resize Mode
                  </Typography>
                  <Controller
                    name="resizeMode"
                    control={control}
                    render={({ field }) => (
                      <>
                        {["contain", "cover", "stretch"].map((value) => (
                          <FormControlLabel
                            key={value}
                            {...field}
                            value={value}
                            control={<Radio />}
                            label={
                              value.charAt(0).toUpperCase() + value.slice(1)
                            }
                            checked={field.value === value}
                          />
                        ))}
                      </>
                    )}
                  />
                </Grid>
              </Grid>

              {/* Preview */}
              <Grid item xs={6} sx={{ position: "relative" }}>
                <div style={previewStyles}>
                  <div
                    style={{
                      height: dimension.height,
                      width: dimension.width,
                      maxHeight: "100%",
                      maxWidth: "100%",
                      backgroundColor: "#ddd",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt="Preview"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: resizeMode,
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>{" "}
          <DialogActions className="mt-5">
            <Button
              size="medium"
              color="secondary"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              size="medium"
              color="primary"
              variant="contained"
              type="submit"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
