import React from "react";

import { Grid, Tab, Tabs } from "@mui/material";

import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

import AnalyticsLive from "./AnalyticsTab/Analytics";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";

// temp

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const Analytics = (props) => {
  let userRole = JSON.parse(localStorage.getItem("userData")).user.userRole;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const breadcrumbs = [
    { label: "Dashboard", link: "/" },
    { label: "Analytics" },
  ];

  return (
    <>
      <>
        <div className="width-90LR" style={{ marginTop: "96px" }}>
          <div className="flex-class">
            <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
          </div>
        </div>
        <Grid container>
          {userRole === 0 ? (
            <>
              <Grid item className="width100">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  centered
                  className="TabStyleAddDevice"
                >
                  <Tab
                    label="Live"
                    sx={{
                      borderRadius: "50px 0px 50px 0px",
                      marginBottom: "20px",
                    }}
                    className="TabChangesDeviceLive"
                  />
                  <Tab
                    label="Testing"
                    sx={{
                      borderRadius: "50px 0px 50px 0px",
                      marginBottom: "20px",
                    }}
                    className="TabChangesDeviceLive"
                  />
                </Tabs>
              </Grid>
              <TabPanel value={value} index={0}>
                <AnalyticsLive value={value} />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <AnalyticsLive value={value} />
              </TabPanel>
            </>
          ) : (
            <AnalyticsLive value={value} />
          )}
        </Grid>
      </>
    </>
  );
};

export default Analytics;
