import * as React from "react";
import {
  Grid,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { FETCH_URL } from "../../../../fetchIp";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "red" }} {...other}>
      {children}
      <Typography className="whitecolortypo">Delete </Typography>{" "}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AlertDialog({ deviceData, getAllDevice, bool }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteDevice = async () => {
    try {
      let token = JSON.parse(localStorage.getItem("userData")).token;
      const response = await fetch(`${FETCH_URL}/api/device/deleteDevice`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          deviceId: deviceData._id,
        }),
      });
      let res = await response.json();
      if (response.ok) {
        getAllDevice();
        console.log("deleting device response ===>", res.msg);
      } else {
        console.log("Error From Server ==> ", res.msg);
      }
    } catch (error) {
      console.log("Error during   deleting device => ", error.message);
    }
  };

  console.log(deviceData, "deviceDatadeviceData123");
  return (
    <div>
      <Button onClick={handleClickOpen}>
        <DeleteIcon className="danger_color" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle> </BootstrapDialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "20px",
          }}
        >
          <Typography className="greycolor505050500">
            {" "}
            Are you sure you want to delete this device ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="  diallog-no-btn">
            No
          </Button>
          <Button
            onClick={() => {
              handleClose();
              deleteDevice();
            }}
            className="reject-btn width-25 "
            style={{ padding: "4px 0px" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
