import { Grid, InputBase, Typography } from "@mui/material";
import React from "react";
import VoucherImage from "../../../../../../assets/Img/Rectangle 8899.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import no_data_img from "../../../../../../assets/Img/no_data_found_img.png";
import DeleteDilogue from "../../ActionDialogue/DeleteDialogue";
import { useState } from "react";
import permissionUtils from "../../../../../../component/permissionUtils";
import SuccessDialog from "../../../../../../utils/Dialog/SuceedFullDialog";
const ActiveVoucher = ({ voucher, getCouponApi }) => {
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  const [copiedCode, setCopiedCode] = useState("");
  const [openDialogName, setOpenDialog] = React.useState(null);
  return (
    <>
      {" "}
      <div className="border_primary p15px">
        <Grid container justifyContent="space-between" rowGap="20px">
          {voucher?.length > 0 ? (
            voucher?.map((data, i) => (
              <Grid
                key={i}
                item
                className="p10px"
                xs={12}
                md={5.8}
                sx={{
                  background: "#F5F7FA",
                  borderRadius: "8px",
                  minHeight: "170px",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    height: "100%",
                  }}
                >
                  <div>
                    {" "}
                    <img
                      src={VoucherImage}
                      alt="VoucherImage"
                      style={{
                        width: "100%",
                        height: "100%",
                        minWidth: "180px",
                      }}
                    />
                  </div>
                  <Grid
                    container
                    flexDirection="column"
                    justifyContent="space-between"
                    className="p10px"
                  >
                    <Grid container justifyContent="space-between">
                      <Typography className="blackcolortypo1c1c1c400">
                        {data?.couponName}
                      </Typography>
                      {PermissionCheck("delete-voucher") && (
                        <DeleteDilogue
                          id={data._id}
                          type="voucher"
                          getCouponApi={getCouponApi}
                          setOpenDialog={setOpenDialog}
                        />
                      )}
                    </Grid>

                    <Grid container justifyContent="space-between" rowGap="5px">
                      {" "}
                      <Typography className="blackcolortypo1c1c1c400">
                        ₹ {data?.couponAmount}
                      </Typography>
                      <Typography className="blackcolortypo1c1c1c400">
                        Validity : {data?.startDate?.split("T")?.[0]} -{" "}
                        {data?.endDate?.split("T")?.[0]}
                      </Typography>{" "}
                    </Grid>

                    <Grid container className="relative">
                      <InputBase
                        type="text"
                        value={data?.couponCode}
                        className="p5px border_primary width100 "
                        disabled
                        sx={{
                          width: "100%",
                        }}
                      />
                      {copiedCode == data?.couponCode ? (
                        <Typography
                          className="absolute fs14px purplecolortypo"
                          style={{ right: "7px", top: "11px" }}
                        >
                          Copied
                        </Typography>
                      ) : (
                        <ContentCopyIcon
                          className="absolute purple-icon"
                          onClick={() => {
                            navigator.clipboard.writeText(data?.couponCode);
                            setCopiedCode(data?.couponCode);
                          }}
                          style={{
                            right: "5px",
                            top: "9px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            ))
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <img src={no_data_img} style={{ width: "100%" }} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
      <SuccessDialog
        openData={openDialogName === "success"}
        data={"Voucher deleted successfully"}
        parentNavigate={true}
      />
    </>
  );
};

export default ActiveVoucher;
