import SignIn from "./views/SignIn/login";
import ForgotPassword from "./views/SignIn/forgotPassword";
import NewPassword from "./views/SignIn/NewPassword";
import LoginCard from "./views/Home/LoginPageForSecure";

const Authroutes = [
  {
    invisible: false,
    name: "signIn",
    link: "/signIn",
    Element: <SignIn />,
  },

  {
    invisible: false,
    link: "/forgetpassword",
    Element: <ForgotPassword />,
  },
  {
    invisible: false,
    link: "/newpassword",
    Element: <NewPassword />,
    name: "newpassword",
  },
  {
    link: "/api/login",
    Element: <LoginCard />,
    name: "apilogin",
  },
];

export default Authroutes;
