import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  Pagination,
  Stack,
} from "@mui/material";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import useExportExcel from "../../../../../../../../utils/useExportExcel";
import axiosInstance from "../../../../../../../../api/axiosInstance";
// import Calendar from "../../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
// import Button from "@mui/material";

const headCells = [
  {
    id: "Sno.",
    numeric: true,
    label: "Sno.",
  },
  {
    id: "Orders",
    numeric: true,
    disablePadding: false,
    label: "Orders",
  },
  {
    id: "Views",
    numeric: true,
    disablePadding: false,
    label: "Views ",
  },
  {
    id: "Female Views",
    numeric: true,
    disablePadding: false,
    label: "Female Views ",
  },
  {
    id: "Male Views",
    numeric: true,
    disablePadding: false,
    label: "Male Views ",
  },
  {
    id: "Amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const BillboardAnalyticsTable = (props) => {
  const billboardId = props?.billBoardID;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [dense, setDense] = React.useState(false);
  const [slotData, setSlotData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = React.useState(null);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const { exportToExcel } = useExportExcel();
  ////////// api calling

  const getSlotsAnalyticsTable = async () => {
    try {
      const res = await axiosInstance.get(
        `api/billboard/getHourlySlotAnalytics?page=${currentPage}&limit=15&billboardId=${billboardId}`
      );

      if (res.status == 200 || res.status == 204) {
        setSlotData(res?.data?.data);
      }
    } catch (err) {
      console.log("error from getSlots analytics", err);
    }
  };
  useEffect(() => {
    getSlotsAnalyticsTable();
  }, [currentPage]);
  console.log("slot data >>>>>>>>>>>>", slotData);

  return (
    <div>
      {" "}
      <Grid container className="width100 mt20px" gap="20px">
        <Grid container justifyContent={"space-between"}>
          <Typography className="gray5A5A5Atypo500 fs20px">
            Hourly Slot Analytics
          </Typography>
          <Grid item>
            <Grid>
              <Button
                sx={{ padding: "8px 20px" }}
                className="purple_btn_primary text_transform_inherit"
                onClick={() => {
                  exportToExcel();
                }}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <EnhancedTableToolbar />
        <TableContainer className="width100 hide_sortBar table_border_radius">
          <Table
            sx={{
              minWidth: 800,
              backgroundColor: "#fff",
              width: "100%",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              className="tablerow"
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />

            <TableBody className="tablerow ">
              {slotData?.map((a, i) => (
                <TableRow
                  key={i}
                  hover
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "16.66%" }}
                  >
                    {a?._id}
                  </TableCell>

                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "16.66%" }}
                  >
                    {a?.orderCount}
                  </TableCell>
                  <TableCell
                    ccomponent="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "16.66%" }}
                  >
                    {a?.billViews}
                  </TableCell>
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "16.66%" }}
                  >
                    {a?.billFemaleViews}
                  </TableCell>
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "16.66%" }}
                  >
                    {a?.billMaleViews}
                  </TableCell>
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "16.66%" }}
                  >
                    {Math.round(a?.billAmount)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Stack
        direction="row"
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
        margin={"20px 0px"}
      >
        <Pagination count={10} page={currentPage} color="secondary" />
      </Stack>
    </div>
  );
};

export default BillboardAnalyticsTable;
