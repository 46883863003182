import React, { useState } from "react";
import {
  Typography,
  Grid,
  Stack,
  Pagination,
  Snackbar,
  Alert,
} from "@mui/material";
import BurnerAdDetails from "./contentDetails";

import axiosInstance from "../../../../api/axiosInstance";
import { useEffect } from "react";
import no_data_img from "../../../../assets/Img/no_data_found_img.png";

const Content = ({
  paginatedPage,
  status,
  getPaginatedValueHandler,
  tabValue,
  currentPage,
  setCurrentPage,
}) => {
  const [pendingContent, setPandingContent] = useState(null);
  const [contentDetail, setContentDeatil] = useState();
  const [pendingLength, setPendingLength] = useState();
  const [totalPage, setTotalPage] = useState(null);
  const [loading, setLoading] = useState(false);

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [snackerropen, setSnackerropen] = useState(false);

  const [severity, setSeverity] = useState("info");

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };

  const handleChangeByPage = (event, value) => {
    getPaginatedValueHandler("", value, tabValue);
    setCurrentPage(value);
  };

  const getContent = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/user/ads-gallery/getUserContent?page=${currentPage}&limit=8&status=${status}`
      );
      if (res.status == 200) {
        setPandingContent(res?.data?.data?.result);
        setTotalPage(Math.ceil(res?.data?.data?.totalDocuments / 8));
        setPendingLength(res?.data?.data);
      }
    } catch (err) {
      console.log("error while getting the pending content ", err);
    }
  };

  useEffect(() => {
    if (currentPage) {
      getContent();
    }
  }, [currentPage]);
  console.log("pending content", pendingContent);
  const getContentDeatil = async (id) => {
    try {
      const res = await axiosInstance.get(
        `/api/user/ads-gallery/getUserContentById/${id}`
      );
      if (res.status == 200) {
        setContentDeatil(res?.data?.data);
      }
    } catch (err) {
      console.log("error while getting the pending content ", err);
    }
  };

  // delete api
  const deleteContent = async (_id) => {
    setLoading(true);
    try {
      const res = await axiosInstance.delete(
        `/api/user/ads-gallery/deleteUserContentById/${_id}`
      );
      if (res.status == 200 || res.status == 201) {
        setSnackOpen(true);
        setSnackMsg("Content deleted successfully");
        setLoading(false);
        getContent();
        handleShowSnackbar("success");
      } else {
        setSnackErrMsg(res.msg);
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (error) {
      console.log(error, "error");
      handleShowSnackbar("error");
      setLoading(false);
    }
  };

  // confirmation approved || reject
  const handleApproveRejectContent = async (id, name) => {
    let body = {
      status: name === "Approve" ? 0 : -1,
      id: id,
    };
    try {
      const resp = await axiosInstance.post(
        "/api/user/ads-gallery/approveRejectUserContent",
        body
      );
      setSnackOpen(true);
      setSnackMsg(
        `Content ${name === "Approve" ? "Approved" : "Rejected"} successfully`
      );
      getContent();
      handleShowSnackbar("success");
    } catch (error) {
      console.log(error, "error");
      handleShowSnackbar("error");
    }
  };

  useEffect(() => {
    if (paginatedPage < 1) {
      setCurrentPage(1);
      return;
    }
    setCurrentPage(paginatedPage ? paginatedPage : 1);
  }, [paginatedPage]);

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={severity}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={severity}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid alignItems="center">
        <Typography className="blackcolor484848 fs24px">
          Showing {pendingContent?.length} out of{" "}
          {pendingLength?.totalDocuments}{" "}
          {status == 1
            ? "Pending"
            : status == 0
            ? "Approved"
            : status == -1
            ? "Rejected"
            : " "}{" "}
          Content
        </Typography>
        <Typography className="gallerySubtext">
          {status == 1
            ? "Click on “View” Details to know “Who uploaded this content and when”.If user uses approved content then their order/campaign will automatically get approved"
            : status == 0
            ? "Click on “View” Details to know “Who Approved this content and when”.If user uses approved content then their order/campaign will automatically get approved."
            : status == -1
            ? "Click on “View” Details to know “Who Rejected this content and when”."
            : " "}{" "}
        </Typography>
      </Grid>
      <Grid container mt={3} gap={3}>
        {pendingContent?.length > 0 ? (
          pendingContent.map((item, i) => {
            return (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={2.8}
                  className="relative"
                  key={i}
                >
                  <Grid container className="relative">
                    <Grid
                      item
                      xs={12}
                      className="box-shadow bg-white "
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #ddd",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                          height: "260px",
                        }}
                      >
                        {item?.fileType == "image/jpeg" ||
                        item?.fileType == "image/png" ||
                        item?.fileType == "image/jpg" ? (
                          <img
                            src={item?.contentLink}
                            width="100%"
                            height="100%"
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        ) : (
                          <video
                            controls
                            src={item?.contentLink}
                            width="100%"
                            height="100%"
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        )}
                      </div>
                      <BurnerAdDetails
                        value={tabValue}
                        getContentDeatil={getContentDeatil}
                        contentDetail={contentDetail}
                        id={item?._id}
                        getContent={getContent}
                        deleteContent={deleteContent}
                        handleApproveRejectContent={handleApproveRejectContent}
                      />
                    </Grid>{" "}
                    <div
                      style={{
                        position: "absolute",
                        top: "6px",
                        right: "6px",
                        padding: "5px 10px",
                        background:
                          item?.status == 1
                            ? "#FF7F37"
                            : item?.status == 0
                            ? "#5fca5d"
                            : item?.status == -1
                            ? "#f00000"
                            : " ",
                        color: "white",
                        borderRadius: "8px",
                      }}
                    >
                      {item?.status == 1
                        ? "Pending"
                        : item?.status == 0
                        ? "Approved"
                        : item?.status == -1
                        ? "Rejected"
                        : " "}
                    </div>
                  </Grid>
                </Grid>
              </>
            );
          })
        ) : (
          <>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <img src={no_data_img} style={{ width: "100%" }} />
              </Grid>
            </Grid>
            ;
          </>
        )}
      </Grid>{" "}
      {pendingContent?.length > 0 && (
        <Grid
          justifyContent="center"
          alignItems="center"
          sx={{ display: "flex", marginTop: "20px" }}
        >
          <Stack spacing={2}>
            <Pagination
              page={currentPage}
              count={totalPage}
              onChange={handleChangeByPage}
              size="large"
            />
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default Content;
