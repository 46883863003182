import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ProfileTab from "./ClusterHeadProfileTab/ProfileTab";
import Access from "./ClusterHeadProfileTab/Access";
import SettingTab from "./ClusterHeadProfileTab/SettingTab";
import Revenue from "../UserMgt/Utils/AllUserProfiles/Revenue-Tab/index";
import axiosInstance from "../../../api/axiosInstance";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminProfile() {
  const [value, setValue] = React.useState(0);
  const [profile, setProfile] = React.useState(null);

  let forOther = true;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [userID, setUserId] = useState(null);
  const [token, setToken] = React.useState(null);

  useEffect(() => {
    function checkUserData() {
      const storeData = JSON.parse(localStorage.getItem("userData"));
      const access_token = localStorage.getItem("access_token");

      console.log("Chheck storeData", storeData);
      if (storeData) {
        setUserId(storeData?.user?._id);
        setToken(access_token);
      }
    }
    checkUserData();
    window.addEventListener("storage", checkUserData);
    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, [token]);

  //get profile
  React.useEffect(() => {
    if (userID) getProfile();
  }, [userID]);

  const getProfile = async () => {
    try {
      let profile = await axiosInstance.get(
        `/api/user/getUserDetail/${userID}`
      );
      setProfile(profile.data.msg);
    } catch (error) {
      console.log("USER PROFILE DATA ==>", error);
    }
  };
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "16px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className="orderTab"
          aria-label="basic tabs example"
        >
          <Tab
            label="Profile"
            {...a11yProps(0)}
            className="Order_TabChangeDevice"
          />

          {/* <Tab
            label="Wallet"
            {...a11yProps(1)}
            className="Order_TabChangeDevice"
          /> */}

          <Tab
            label="Revenue"
            {...a11yProps(1)}
            className="Order_TabChangeDevice"
          />

          <Tab
            label="Access"
            {...a11yProps(2)}
            className="Order_TabChangeDevice"
          />

          <Tab
            label="Setting"
            {...a11yProps(3)}
            className="Order_TabChangeDevice"
          />
        </Tabs>
      </Box>
      <div style={{ marginTop: "24px" }}>
        <TabPanel value={value} index={0} className="superAdmin_profile_tab">
          <ProfileTab userID={userID} />
        </TabPanel>
        {/* <TabPanel value={value} index={1} className="superAdmin_profile_tab">
          <WalletTab states={{ profile, forOther }} userID={userID} />
        </TabPanel> */}
        <TabPanel value={value} index={1} className="superAdmin_profile_tab">
          <Revenue states={{ profile, userID, forOther }} />
        </TabPanel>
        <TabPanel value={value} index={2} className="superAdmin_profile_tab">
          <Access userID={userID} />
        </TabPanel>
        <TabPanel value={value} index={3} className="superAdmin_profile_tab">
          <SettingTab userID={userID} />
        </TabPanel>
      </div>
    </>
  );
}
