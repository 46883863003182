import React from "react";
import { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
const Date = ({ handleFilterData, startDate, endDate }) => {
  const [startDate1, setStartDate] = useState(null);
  const [endDate1, setEndDate] = useState(null);
  const handleData = (data, datatype) => {
    if (datatype == "startDate") {
      setStartDate(moment(data).format("YYYY-MM-DD"));
      handleFilterData(data, "startDate");
    } else if (datatype == "endDate") {
      console.log("endDate => ", moment(data).format("YYYY-MM-DD"));
      setEndDate(moment(data).format("YYYY-MM-DD"));
      handleFilterData(data, "endDate");
    }
  };
  return (
    <>
      <Grid container className="mt10px">
        <Grid item xs={5.5} className="deskDate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              InputProps={{
                disableUnderline: true,
              }}
              label="Start Date"
              inputFormat="MM/dd/yyyy"
              value={startDate}
              onChange={(e) => {
                handleData(e, "startDate");
              }}
              renderInput={(params) => (
                <TextField {...params} className="width100 " />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item md={1} xs={1} className="pt5px">
          <Typography align="center" className="greycolor505050500 ">
            to
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="End Date"
              inputFormat="MM/dd/yyyy"
              value={endDate}
              onChange={(e) => {
                handleData(e, "endDate");
              }}
              InputProps={{
                disableUnderline: true,
              }}
              renderInput={(params) => (
                <TextField {...params} className="width100 " />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default Date;
