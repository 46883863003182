import { useEffect, useState } from "react";
const usePaginatedValue = () => {
  const historyState = window.history?.state;
  const [paginatedPage, setPaginatedPage] = useState(historyState?.page);
  const [paginateTabValue, setPaginateTabValuee] = useState(
    historyState?.tabValue
  );
  useEffect(() => {
    setPaginatedPage(historyState?.page);
  }, [historyState?.page]);
  useEffect(() => {
    setPaginateTabValuee(historyState?.tabValue);
  }, [historyState?.tabValue]);

  const getPaginatedValueHandler = (route, page, tabValue) => {
    {
      tabValue !== undefined
        ? window.history.pushState(
            { page: page, tabValue: tabValue },
            "",
            `${route}?page=${page}&tabValue=${tabValue}`
          )
        : window.history.pushState(
            { page: page, tabValue: tabValue },
            "",
            `${route}?page=${page}`
          );
    }
  };

  return {
    paginatedPage,
    paginateTabValue,
    getPaginatedValueHandler,
  };
};

export default usePaginatedValue;
