import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Breadcrumbs,
  Box,
  Button,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import { GrView } from "react-icons/gr";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import moment from "moment";
// import axiosInstance from "../../../../../api/axiosInstance";
import axiosInstance from "../../../../../api/axiosInstance";
import nodata from "../../../../../assets/Img/no_data_found_img.png";
import { SignLanguage } from "@mui/icons-material";
import { setBillboards } from "../../../../../Context/campaignBook";
// import Calendar from "../../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
// import Button from "@mui/material";

const headCells = [
  {
    id: "UID",
    numeric: true,
    disablePadding: false,
    label: "UID",
  },
  {
    id: "Billboard Name",
    numeric: true,
    label: "Billboard Name",
  },
  {
    id: "Date",
    numeric: true,
    label: "Date",
  },

  {
    id: "Timeslot",
    numeric: true,
    disablePadding: false,
    label: "Timeslot ",
  },
  {
    id: "Time Slot Price",
    numeric: true,
    disablePadding: false,
    label: "Time Slot Price",
  },
  {
    id: "Repetition",
    numeric: true,
    disablePadding: false,
    label: "Repetition",
  },
  {
    id: "Duration",
    numeric: true,
    disablePadding: false,
    label: "Duration",
  },
  {
    id: "Total Amount",
    numeric: true,
    disablePadding: false,
    label: "Total Amount",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        {/* <Grid container justifyContent="space-between">
          <Typography className="gray5A5A5Atypo500 fs20px">
            Billboard type
          </Typography>
          <Grid item>
            <Grid container gap="10px">
              
              <Button
                sx={{ padding: "0 10px" }}
                className="purple_btn_primary text_transform_inherit"
               
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const BillBoardOverview = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [dense, setDense] = React.useState(false);
  const [dateType, setDateType] = useState("orders");
  const [date, setDate] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [getAdvertiseViseData, setgetAdvertiseViseData] = useState([]);
  const [fileName, setfileName] = useState("Advertise Vise");
  const [BillboardName, setBillboardName] = useState(null);
  const [selectDate, setSelectDate] = useState(1);
  const [allFilterSlot, setAllFilterSlot] = useState([]);
  const [allFilterDate, setAllFilterDate] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [dates, setDates] = useState([]);
  const [multiStartHour, setmultiStartHour] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [orderId, setOrderId] = useState(`${props?.data.orderId.toString()}`);
  console.log("props>>>>>>>>>>>>", props);

  /////////////////api call ////////////////
  useEffect(() => {
    if (selectedSlot) {
      getRangebetweenDates(
        props?.data?.startscheduleDate,
        props?.data?.endscheduleDate
      );
      getDataRevenueByAdvertise();
    }
  }, [props, selectedSlot, selectDate]);

  const getDataRevenueByAdvertise = async (time) => {
    if (time) {
      try {
        let res = await axiosInstance.get(
          `api/order/getCampaignBillboardsByOrderId?date=${moment(
            selectDate
          )?.format("YYYY-MM-DD")}&startHour=${time}&orderId=${
            props?.data?._id
          }`
        );

        console.log("response from setgetAdvertiseViseData =>", res);
        if (res.status == 200 || res.status == 201) {
          //  setAcceptedOrderAnalyticsData(res?.data.data);

          setgetAdvertiseViseData(res?.data?.data);
          setBillboardName(res?.data?.data?.billName);
        }
      } catch (err) {
        console.log("error from from setgetAdvertiseViseData => ", err);
      }
    }
  };

  function getRangebetweenDates(startDate, endDate, type = "days") {
    let fromDate = moment(startDate);
    let toDate = moment(endDate);
    let diff = toDate.diff(fromDate, type);
    let range = [];
    for (let i = 0; i <= diff; i++) {
      range.push(moment(startDate).add(i, type).format("YYYY-MM-DD"));
    }
    setDates(range);
  }

  const getStartHourData = async (date) => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getTimeSlotByDate?orderId=${
          props?.data?._id
        }&date=${moment(selectDate)?.format("YYYY-MM-DD")} `
      );
      if (res.status == 200) {
        if (res?.data?.data?.hours?.length > 0) {
          let newTimes = tConvert(res?.data?.data?.hours);
          console.log(newTimes, res?.data?.data?.hours);

          setSelectedSlot(newTimes[0]);
          setAllFilterSlot(newTimes);
          getDataRevenueByAdvertise(res?.data?.data?.hours[0]);
        }
      } else {
        console.log(res, "failed res after api call ");
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (selectDate) {
      getStartHourData();
      // getCampaignProgress();
    }
  }, [selectDate]);
  function tConvert(time) {
    const times = [...new Set(time)];
    let newTimes = [];
    times.map((data) => {
      let obj = {};
      if (data < 4) {
        if (data === 0) obj["actualTime"] = "12am - 1am";
        else obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 3 && data < 8) {
        obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 7 && data < 12) {
        if (data === 11) obj["actualTime"] = "11am - 12pm";
        else obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 11 && data < 16) {
        if (data === 12) obj["actualTime"] = `12pm - ${data - 12 + 1}pm`;
        else obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      } else if (data > 15 && data < 20) {
        obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      } else if (data > 19) {
        if (data === 23) obj["actualTime"] = `11pm - ${data - 12 + 1}am`;
        else obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      }
      obj["time"] = data;
      newTimes.push(obj);
    });

    return newTimes;
  }

  // console.log("selected >......",selectedSlot.time)
  useEffect(() => {
    // console.log(
    //   "tConvert(props?.data?.startHour)",
    //   );
    tConvert(multiStartHour);
    // setTimeSlot(tConvert(props?.data?.startHour));
  }, [multiStartHour]);

  useEffect(() => {
    console.log("props anaalityics", props?.data);
    let tempDate = [];
    if (props?.data?.multiTimes) {
      tempDate = props?.data?.multiTimes.map((item) => item?.newDate);
    }
    // setAllFilterDate(tempDate);
    setAllFilterDate([...new Set(tempDate)]);
    setSelectDate(tempDate[0]);
  }, [props]);
  useEffect(() => {
    console.log("props anaalityics", props?.data);
    let tempStart = [];
    if (props?.data?.multiTimes) {
      tempStart = props?.data?.multiTimes.map((item) => item?.startHour);
    }
    setmultiStartHour(tempStart);
  }, [props]);

  return (
    <div>
      {" "}
      <Grid container className="width100 mt10px" gap="20px">
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography className="gray5A5A5Atypo500 fs20px">
            Showing {getAdvertiseViseData ? getAdvertiseViseData?.length : 0}{" "}
            {""}
            billboards
          </Typography>
          {/* <Grid item>
            {" "}
            <Typography className="gray5A5A5Atypo500 fs20px">B123</Typography>
          </Grid> */}
          <Grid item justifyContent={"space-between"}>
            <Grid item>
              <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                <InputLabel id="demo-select-small"> Select Date </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={selectDate}
                  label="Select Date"
                  onChange={(e) => setSelectDate(e.target.value)}
                >
                  {allFilterDate?.map((item, i) => (
                    <MenuItem value={item} key={i}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                <InputLabel id="demo-select-small">
                  {" "}
                  Select Hour Slot{" "}
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={selectedSlot}
                  label="       Select Hour Slot"
                  onChange={(e) => {
                    setSelectedSlot(e.target.value);
                    getDataRevenueByAdvertise(e.target.value?.time);
                  }}
                >
                  {allFilterSlot?.map((item, i) => (
                    <MenuItem value={item} key={i}>
                      {item?.actualTime}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <EnhancedTableToolbar />
        <TableContainer className="width100 hide_sortBar table_border_radius">
          <Table
            sx={{
              minWidth: 800,
              backgroundColor: "#fff",
              width: "100%",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              className="tablerow"
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />

            <TableBody className="tablerow " style={{ alignItems: "center" }}>
              {getAdvertiseViseData && getAdvertiseViseData?.length > 0 ? (
                getAdvertiseViseData?.map((a, i) => (
                  <TableRow
                    key={i}
                    hover
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      align="Left"
                      component="th"
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Link
                        to={`/admin/billboardscreens/${a?.billId}`}
                        className="linkcolor"
                        state={{ item: a }}
                      >
                        <Typography className="greycolor1c1c1c fs16px ">
                          {a?.billId ? a?.billId.slice(-12) : "---"}
                        </Typography>
                      </Link>
                    </TableCell>

                    <TableCell
                      align="Left"
                      component="th"
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {a?.billName ? a?.billName : ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {a?.formattedDate ? a?.formattedDate : a?.date}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="Left"
                      component="th"
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {selectedSlot ? selectedSlot?.actualTime : "---"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        ₹ {a?.basePrice ? a?.basePrice : 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {a?.repetitions ? a?.repetitions : a?.seconds.length}
                      </Typography>
                    </TableCell>
                    <TableCell
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        {props?.data?.videoDuration} seconds
                      </Typography>
                    </TableCell>
                    <TableCell
                      ccomponent="th"
                      // id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "auto" }}
                    >
                      <Typography className="greycolor1c1c1c fs16px ">
                        ₹ {a?.amount ? a?.amount : a?.billAmount}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <>
                  <TableRow>
                    <TableCell colSpan={9}>
                      <div className="no-data-container">
                        <img
                          src={nodata}
                          alt="no data found"
                          className="noBillboardFoundGif_width"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
};

export default BillBoardOverview;
