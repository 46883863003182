import React from "react";
import { Grid, Breadcrumbs, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useNavigate } from "react-router-dom";

const CustomBreadcrumbs = ({ breadcrumbItems }) => {
  const navigate = useNavigate();

  return (
    <Grid container direction="row" justifyContent="start">
      <KeyboardBackspaceIcon
        onClick={() => navigate(-1)}
        style={{ marginRight: "8px", textAlign: "center" }}
      />
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbItems.map((item, index) => (
          <React.Fragment key={index}>
            {index < breadcrumbItems.length - 1 ? (
              <Link to={item.link} className="linkcolor" underline="hover">
                <Typography className="purplecolortypo fs16px">
                  {item.label}
                </Typography>
              </Link>
            ) : (
              <Typography className="blackcolortypo fs16px">
                {item.label}
              </Typography>
            )}
          </React.Fragment>
        ))}
      </Breadcrumbs>
    </Grid>
  );
};

export default CustomBreadcrumbs;
