import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  CardActionArea,
  Checkbox,
  IconButton,
  Button,
  Card,
  CardMedia,
  Input,
  Dialog,
  DialogTitle,
  DialogActions,
  Slider,
  FormControlLabel,
  FormControl,
  TextField,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// Image Filter
import PEDESTAL from "../../../../../assets/Img/Pedestal.png";
import MONO from "../../../../../assets/Img/Monopole.png";
import SPECT from "../../../../../assets/Img/spect.png";
import DISPLAY from "../../../../../assets/Img/stdisplay.png";
import WINDOW from "../../../../../assets/Img/WINDOW.png";
import Flag from "../../../../../assets/Img/Flag.png";
import Kiosk from "../../../../../assets/Img/Kiosk1.png";
import {
  useFilterController,
  setFilterData,
} from "../../../../../Context/filter";
import axiosInstance from "../../../../../api/axiosInstance";

//  Icons
import CloseIcon from "@mui/icons-material/Close";
import { MdFilterListAlt } from "react-icons/md";
import { BiRupee } from "react-icons/bi";
import { CheckBox } from "@mui/icons-material";
//core Component
import VenueComponent from "../filter/Venue";
import PortaitSizeImage from "../../../../../assets/Img/portraitSize.png";
import LandscapeSizeImage from "../../../../../assets/Img/LandscapeSize.png";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(7),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ScreenFilter(props) {
  const {
    states: { selectedFilter, setSelectedFilter, firstVenue },
    filter,
    filterState,
    handleSetFirstVenue,
    handlePriceHandler,
    clearVenueFilter,
    getFilteredBillboards,
  } = props;
  const [controller, dispatch] = useFilterController();
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [open2, setOpen2] = useState(false);
  const [value, setValue] = useState([20, 37]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000);
  const [IsTesting, setIsTesting] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    handlePriceHandler(newValue[0], newValue[1]);
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
    filter(newValue, "price");
  };

  // const getAllCountry = async () => {
  //   try {
  //     let res = await axiosInstance.get("/api/billboard/location");
  //     console.log("RESPONSE FROM states => ", res);

  //     const data = res.data.msg.map((state) => state.state);
  //     const datastates = data.map((item) => {
  //       return {
  //         image: <CheckBox />,
  //         name: item,
  //       };
  //     });
  //     setFilterState(datastates);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log("error from GETTing states ", error.message);
  //     setLoading(false);
  //   }
  // };

  const [filterCountry, setFilterCountry] = useState([
    {
      checked: false,
      name: "India",
    },
    // {
    //   checked: false,
    //   name: "Uk",
    // },
    // {
    //   checked: false,
    //   name: "us",
    // },
  ]);

  // const [filterSize, setFilterSize] = useState([
  //   {
  //     title: "All",
  //     name: "All Sizes",
  //   },
  //   {
  //     title: "Landscape (1920X1080)",
  //     name: "1920X1080 full screen",
  //   },
  //   {
  //     title: "Portrait (1080X1920)",
  //     name: "1080X1920 potrait screen",
  //   },
  // ]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [filterSmartbillBoard, setFilterSmartbillboard] = useState([
    {
      image: PEDESTAL,
      name: "Pedestal",
    },
    {
      image: DISPLAY,
      name: "Standing Display",
    },
    {
      image: WINDOW,
      name: "WINDOW",
    },
    {
      image: SPECT,
      name: "SPECTACULAR",
    },
    {
      image: MONO,
      name: "Monopole",
    },
    {
      image: Kiosk,
      name: "Kiosk",
    },
    {
      image: Flag,
      name: "Flag",
    },
  ]);
  function valuetext(value) {
    return `${value}°C`;
  }
  // const clearFilter =()=>{

  // }
  const filterClearAll = () => {
    setSelectedFilter({
      place: "",
      smartBillboard: [],
      venue: [],
      country: [],
      state: [],
      size: "1080X1920",
      price: [],
    });
    setFilterData(dispatch, {
      place: "",
      smartBillboard: [],
      venue: [],
      country: [],
      state: [],
      size: "1080X1920",
      price: [],
    });
    clearVenueFilter();
    handlePriceHandler(0, 100000);
    setMaxPrice(100000);
    setMinPrice(0);
  };
  const [selectData1, setSelectData1] = useState("Less than 100/Day");
  const [selectData2, setSelectData2] = useState("Less than 100/Day");
  const [selectData3, setSelectData3] = useState("Less than 100/Day");
  const selectHandler = (data, dataType) => {
    if (dataType == "selectOne") {
      setSelectData1(data);
    } else if (dataType == "selectTwo") {
      setSelectData2(data);
    } else {
      setSelectData3(data);
    }
  };
  const SelectComponent = ({ selectData, selectHandler, dataType }) => {
    return (
      <Grid item sx={{ height: "100%" }}>
        <FormControl className="billboard_price_filter">
          <TextField
            select
            variant="filled"
            InputProps={{ disableUnderline: true }}
            className="Selectdropstyle"
            labelId="demo-select-small"
            id="demo-select-small"
            value={selectData ? selectData : "Less than 100/Day"}
            defaultValue={0}
            onChange={(e) => selectHandler(e.target.value, dataType)}
          >
            <MenuItem
              value={"Less than 100/Day"}
              className="blackcolor484848 fs13px"
            >
              <Typography className="greycolor505050500 fs14px ">
                Less than 100/Day
              </Typography>
            </MenuItem>
            <MenuItem
              value={"Less than 500/Day"}
              className="blackcolor484848 fs13px"
            >
              <Typography className="greycolor505050500 fs14px ">
                Less than 500/Day
              </Typography>
            </MenuItem>
            <MenuItem
              value={"Less than 1000/Day"}
              className="blackcolor484848 fs13px"
            >
              <Typography className="greycolor505050500 fs14px ">
                Less than 1000/Day
              </Typography>
            </MenuItem>
            <MenuItem
              value={"More than 5000/Day"}
              className="blackcolor484848 fs13px"
            >
              <Typography className="greycolor505050500 fs14px ">
                More than 5000/Day
              </Typography>
            </MenuItem>
          </TextField>
        </FormControl>
      </Grid>
    );
  };
  return (
    <>
      <Button
        className=" family-color filter-btn"
        sx={{ height: "36px" }}
        startIcon={<MdFilterListAlt />}
        onClick={handleClickOpen}
      >
        Filters
      </Button>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="pop-width billboard_filter_dialog"
      >
        <Grid container className="purple-gradient ">
          <BootstrapDialogTitle
            className="family-color "
            onClose={handleClose}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography className="whitecolortypo500 fs20px">Filter</Typography>
          </BootstrapDialogTitle>
        </Grid>
        <Grid
          sx={{ width: "100%", overflowY: "scroll" }}
          className="filter-parent-container"
        >
          <Grid sx={{ width: "100%" }} className="filter-parent-container">
            {/* <Grid container md={12} className="border-bottom pb15px">
              <Typography className=" type-heading  fs20px">Size</Typography>

              {filterSize.map((item, index) => (
                <Grid
                  item
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => filter(item, "size")}
                >
                  <Checkbox
                    className="iconcolor"
                    checked={selectedFilter.size.includes(item.name) && checked}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography className="family-color sm-size-font">
                    {item.title}
                  </Typography>
                </Grid>
              ))}
            </Grid> */}

            {/* <Grid container className="border-bottom pb15px">
              <Typography className="type-heading  fs20px">Size</Typography>
              <Grid container gap="32px">
                

                <Grid item>
                  <Grid container>
                    <div>
                      {" "}
                      <Checkbox
                        className="iconcolor"
                        onClick={() =>
                          filter(
                            {
                              title: "Portrait (1080X1920)",
                              name: "1080X1920 potrait screen",
                            },
                            "size"
                          )
                        }
                        checked={
                          selectedFilter.size.includes(
                            "1080X1920 potrait screen"
                          ) && checked
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>

                    <Grid item className="border_secondary pb-5px">
                      <img src={PortaitSizeImage} alt="PortaitSizeImage" />
                      <Typography
                        align="center"
                        className="family-color sm-size-font"
                      >
                        1080 X 1920 <br /> (Portrait)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <div>
                      {" "}
                      <Checkbox
                        className="iconcolor"
                        onClick={() =>
                          filter(
                            {
                              title: "Landscape (1920X1080)",
                              name: "1920X1080 full screen",
                            },
                            "size"
                          )
                        }
                        checked={
                          selectedFilter.size.includes(
                            "1920X1080 full screen"
                          ) && checked
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>

                    <Grid item className="border_secondary pb-5px">
                      <img src={LandscapeSizeImage} alt="LandscapeSizeImage" />
                      <Typography
                        align="center"
                        className="family-color sm-size-font"
                      >
                        1920 X 1080 <br /> (Landscape)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}

            {/*  */}
            {/* <Grid container className="border-bottom pb15px" rowGap="10px">
              <Typography className="type-heading  fs20px">Audience</Typography>

              <Grid container alignItems="center">
                {" "}
                <FormControlLabel
                  sx={{ width: "150px" }}
                  label="All Views"
                  control={<Checkbox className="iconcolor" checked={true} />}
                />
                <SelectComponent
                  selectData={selectData1}
                  selectHandler={selectHandler}
                  dataType="selectOne"
                />
              </Grid>
              <Grid container alignItems="center">
                {" "}
                <FormControlLabel
                  sx={{ width: "150px" }}
                  label="Male Views"
                  control={<Checkbox className="iconcolor" checked={true} />}
                />
                <SelectComponent
                  selectData={selectData2}
                  selectHandler={selectHandler}
                  dataType="selectTwo"
                />
              </Grid>
              <Grid container alignItems="center">
                {" "}
                <FormControlLabel
                  sx={{ width: "150px" }}
                  label="Female Views"
                  control={<Checkbox className="iconcolor" checked={true} />}
                />
                <SelectComponent
                  selectData={selectData3}
                  selectHandler={selectHandler}
                  dataType="selectThree"
                />
              </Grid>
            </Grid> */}

            {/*  */}

            {/* <Grid className="border-bottom pb15px">
              <Typography className=" type-heading fs20px">
                Type of place
              </Typography>
              <Grid container className="type-content mt10px" rowGap="20px">
                <Grid item md={6} className="type-content-left">
                  <Grid>
                    <Checkbox
                      checked={
                        (firstVenue == "Indoor" || firstVenue == "all") &&
                        checked
                      }
                      className="iconcolor"
                      onChange={() =>
                        handleSetFirstVenue(
                          firstVenue == "Outdoor"
                            ? "all"
                            : firstVenue == "all"
                            ? "Outdoor"
                            : "Indoor"
                        )
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>

                  <Grid>
                    <Typography className="family-color type-content-head">
                      Indoor
                    </Typography>
                    <Typography className=" type-content-heading">
                      An indoor digital billboard can build brand awareness by
                      placing the right message in places where the right people
                      see it during their stay.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={6} className="type-content-right">
                  <Grid>
                    <Checkbox
                      checked={
                        (firstVenue == "Outdoor" || firstVenue == "all") &&
                        checked
                      }
                      className="iconcolor"
                      onChange={() =>
                        handleSetFirstVenue(
                          firstVenue == "Indoor"
                            ? "all"
                            : firstVenue == "all"
                            ? "Indoor"
                            : "Outdoor"
                        )
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                  <Grid>
                    <Typography className="family-color type-content-head">
                      Outdoor
                    </Typography>
                    <Typography className=" type-content-heading">
                      An outdoor digital billboard can build awareness by
                      placing the right message in places where the right people
                      see it when they are outside
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="border-bottom ">
              <Typography className=" type-heading fs20px">
                Type of smart billboard
              </Typography>
              <Grid container className="smart-billboard-card type-content ">
                {filterSmartbillBoard.map((item, index) => (
                  <Grid
                    item
                    md={2.2}
                    className="mt25px "
                    sx={{ border: "2px solid #ddd", borderRadius: "6px" }}
                  >
                    <Card
                      key={index}
                      className={
                        selectedFilter.smartBillboard.includes(item.name) &&
                        "selected-border"
                      }
                      onClick={() => filter(item, "smartBillboard")}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="100"
                          image={item.image}
                          alt="green iguana"
                        />

                        <Typography
                          gutterBottom
                          component="div"
                          className="family-color center-text"
                        >
                          {item.name}
                        </Typography>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid> */}

            <Grid className="border-bottom pb15px">
              <Typography className=" type-heading fs20px">Venue</Typography>
              <Grid container className="venue-box mt20px">
                <VenueComponent forSm={5} forXs={4} />
              </Grid>
            </Grid>
            <hr />
            <Grid container md={12} className="border-bottom pb15px">
              <Typography className=" type-heading  fs20px">Country</Typography>
              {filterCountry.map((detail, index) => (
                <Grid
                  item
                  key={index}
                  align="center"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => filter(detail, "country")}
                >
                  <Checkbox
                    className="iconcolor"
                    checked={
                      selectedFilter.country.includes(detail.name) && checked
                    }
                  />
                  <Typography className="family-color sm-size-font">
                    {detail.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <hr />
            <Grid container md={12} className="border-bottom pb15px">
              <Typography className=" type-heading fs20px">
                District{" "}
              </Typography>
              {filterState?.map((item, index) => (
                <Grid item key={index} className="flex centerAi">
                  <Checkbox
                    className="iconcolor"
                    checked={selectedFilter.district == item ? true : false}
                    onChange={() => filter(item, "district")}
                  />
                  <Typography className="family-color sm-size-font">
                    {item}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <hr />
          </Grid>
        </Grid>
        <DialogActions
          style={{
            justifyContent: "space-between",
            border: "1px solid #ddd",
          }}
        >
          <Button
            className="clear-all-btn family-color"
            onClick={() => filterClearAll()}
          >
            Clear all
          </Button>
          <Button
            className="purplebtn f "
            sx={{ color: "white", fontWeight: "500" }}
            onClick={() => {
              setOpen(false);
              // getFilteredBillboards();
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
