import React, { useState, useEffect } from "react";
// import Sidebar from "../../component/Sidebar/Sidebar";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
  Button,
} from "@mui/material";
import moment from "moment";
import axiosInstance from "../../api/axiosInstance";
import { Link } from "react-router-dom";
//core component
import Sidebar from "../../component/Sidebar/Sidebar";
import AddUser from "./AddUserDialog/AddUser";
import Map from "./HomeMap/GoogleMap/Map";
// React Icons
import { IoMdAddCircleOutline } from "react-icons/io";
import { BiRupee } from "react-icons/bi";
import Home_Bar_Analytics from "./Home_Analytics/Home_Bar_Analytics";
import Home_bar_Analytics_Finance from "./Home_Analytics/Home_bar_Analytics_Finance";
import { AuthContext } from "../../Context/AuthContext";
import permissionUtils from "../../component/permissionUtils";

// Card Icon

import buisnecardIcon from "../../assets/Img/buisne-cardIcon.png";

import agencycardIcon from "../../assets/Img/agency-cardIcon.png";

///===> FetchIP
import { FETCH_URL } from "../../fetchIp";
import axios from "../../api/axiosInstance";
import { useNavigate } from "react-router-dom";
const Campgainrows = [];

export default function ChHome() {
  const { user } = React.useContext(AuthContext);
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  const [userCount, setUserCount] = React.useState(null);

  const [topAdvertiseeData, setTopAdvertiseeData] = useState();
  const [totalCount, setTotalCount] = React.useState(null);
  const [allDashboardData, setAllDashboardData] = React.useState(null);

  console.log("Check allDashboardData", allDashboardData);
  const navigate = useNavigate();
  // =========== Added =========== //
  const getTotalCount = async () => {
    try {
      let res = await axiosInstance.get("/api/order/getDashboardTopBarData");
      if (res?.status == 200) {
        setTotalCount(res?.data?.data);
      }
    } catch (error) {
      console.error("check error getTotalCount", error);
    }
  };
  const getAllDasboard = async () => {
    try {
      let res = await axios.get(
        "/api/billboard/getDevBillOrderData?isTesting=false"
      );
      console.log("RESPONSE FROM USER COUNT => ", res);
      setAllDashboardData(res?.data?.data);
    } catch (error) {}
  };

  // ============ count Users API ============ //
  const getCountUser = async () => {
    try {
      let res = await axios.get("/api/user/countUser");
      console.log("RESPONSE FROM USER COUNT => ", res.data.msg[0]);
      setUserCount(res.data.msg[0]);
    } catch (error) {
      console.log("error from GET Count User", error.message);
    }
  };

  // ================ BillBoard Get API =================== //
  const [billBoardList, setBillBoardList] = useState([]);

  const getallBillBoardsScreens = async () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    const response = await fetch(`${FETCH_URL}/api/billBoard/getTopbillboard`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let res = await response.json();
    if (response.ok) {
      console.log("All Screens resp ===> ", res.msg);
      setBillBoardList(res.msg);
    } else {
      console.log("Error in ALL Screens ==> ", res);
    }
  };
  const [allBillboards, setAllBillboards] = useState([]);

  const getallDevices = async () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    const response = await fetch(`${FETCH_URL}/api/billboard/getallbillboard`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let res = await response.json();
    if (response.ok) {
      //console.log("All Billboard ===> ", res.msg);
      setAllBillboards(res.msg);
    } else {
      console.log("Error in All Billboard ==> ", res);
    }
  };
  // ================== getTopCampaigns Get API =================  //

  const [oncampaign, setOnCampaign] = useState(null);

  const todayDate = moment().format("YYYY-MM-DD");
  // console.log("Check oncampaign  ==============>", oncampaign);
  const onGoingcampaign = async () => {
    try {
      const response = await axiosInstance.get(`/api/campaign/getTopCampaigns`);

      if (response?.status == 200) {
        setOnCampaign(response?.data?.msg);
        // console.log("OnGoing campaign  response  ===>", res.msg);
      } else {
        console.log("Error From Server ==> ", response?.data?.msg);
      }
    } catch (error) {
      console.log(
        "Error during   OnGoing campaign data find  => ",
        error.message
      );
    }
  };

  const getTopAdvertiseData = async () => {
    try {
      const res = await axiosInstance.get(`/api/user/getTopAdvertisee`);
      // let res = await response.json();
      console.log("topadvertisee", res);
      if (res?.status == 200) {
        setTopAdvertiseeData(res?.data?.msg);
      } else {
        console.log("getTopAdvertise err ==> ", res.msg);
      }
    } catch (error) {
      console.log("Error getTopAdvertise  => ", error);
    }
  };
  useEffect(() => {
    // console.log("UseEffffect is running..")

    getallBillBoardsScreens();
    getCountUser();

    getallDevices();
    onGoingcampaign();
    getTopAdvertiseData();
    getTotalCount();
    getAllDasboard();
  }, []);
  return (
    <>
      <div className="width-90LR" style={{ marginBottom: "40px" }}>
        {/*  */}
        <Grid
          container
          justifyContent="space-between"
          rowGap="20px"
          sx={{ marginTop: "100px" }}
        >
          <Grid
            container
            direction="column"
            item
            md={2.2}
            sm={3.8}
            xs={12}
            className="card_div_new"
            gap="10px"
          >
            <Link
              to={PermissionCheck("view-device") && "/admin/device"}
              className="linkcolor"
            >
              <Grid item>
                <Typography className="blackcolortypo fs20px">
                  Devices
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="blackcolortypo fs28px">
                  {totalCount?.deviceCounts ? totalCount?.deviceCounts : 0}
                </Typography>
              </Grid>

              {/* <Grid container item>
                  <Typography className="blackcolortypo fs14px">
                    <span className="greencolortypo_600 fs14px">12%</span>{" "}
                    Increase From Last Month{" "}
                  </Typography>
                </Grid> */}
            </Link>
          </Grid>
          <Grid
            container
            direction="column"
            item
            md={2.2}
            sm={3.8}
            xs={12}
            className="card_div_new"
            gap="10px"
          >
            <Link
              to={
                PermissionCheck("view-live-billboard") &&
                "/admin/billboardscreens"
              }
              className="linkcolor"
            >
              <Grid item>
                <Typography className="blackcolortypo fs20px">
                  BillBoard
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="blackcolortypo fs28px">
                  {allDashboardData?.billData ? (
                    <>
                      <span style={{ color: "green", marginRight: "4px" }}>
                        {allDashboardData.billData[0]?.count}
                      </span>
                      /
                      <span style={{ color: "red", marginLeft: "4px" }}>
                        {allDashboardData.billData[1]?.count}
                      </span>
                    </>
                  ) : (
                    0
                  )}
                </Typography>
              </Grid>

              {/* <Grid container item>
                  <Typography className="blackcolortypo fs14px">
                    <span className="greencolortypo_600 fs14px">12%</span>{" "}
                    Increase From Last Month{" "}
                  </Typography>
                </Grid> */}
            </Link>
          </Grid>
          <Grid
            container
            direction="column"
            item
            md={2.2}
            sm={3.8}
            xs={12}
            className="card_div_new"
            gap="10px"
          >
            <Link
              to={
                PermissionCheck("view-finance-overview") &&
                `/admin/finance?tab=${3}`
              }
              className="linkcolor"
            >
              {" "}
              <Grid item>
                <Typography className="blackcolortypo fs20px">
                  Revenue
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="blackcolortypo fs28px">
                  {" "}
                  {totalCount?.revenue ? totalCount?.revenue : 0}
                </Typography>
              </Grid>
              {/* <Grid container item>
                  <Typography className="blackcolortypo fs14px">
                    <span className="greencolortypo_600 fs14px">12%</span>{" "}
                    Increase From Last Month{" "}
                  </Typography>
                </Grid> */}
            </Link>
          </Grid>

          <Grid
            container
            direction="column"
            item
            sm={5.8}
            xs={12}
            md={2.2}
            className="card_div_new"
            gap="10px"
          >
            <Link
              to={PermissionCheck("view-live-campaign") && "/admin/campaignmgt"}
              className="linkcolor"
            >
              <Grid item>
                <Typography className="blackcolortypo fs20px">
                  Campaigns
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="blackcolortypo fs28px">
                  <Typography className="blackcolortypo fs28px">
                    {allDashboardData?.orders ? (
                      <>
                        <span style={{ color: "orange", marginRight: "4px" }}>
                          {allDashboardData.orders.pendingCamOrders}
                        </span>
                        /
                        <span
                          className="blackcolortypo"
                          style={{ marginLeft: "4px" }}
                        >
                          {allDashboardData.orders.totalCamOrders}
                        </span>
                      </>
                    ) : (
                      0
                    )}
                  </Typography>
                </Typography>
              </Grid>

              {/* <Grid container item>
                  <Typography className="blackcolortypo fs14px">
                    <span className="greencolortypo_600 fs14px">12%</span>{" "}
                    Increase From Last Month{" "}
                  </Typography>
                </Grid> */}
            </Link>
          </Grid>
          <Grid
            container
            direction="column"
            item
            sm={5.8}
            xs={12}
            md={2.2}
            className="card_div_new"
            gap="10px"
          >
            <Link
              to={PermissionCheck("view-billboard") && "/admin/order"}
              className="linkcolor"
            >
              <Grid item>
                <Typography className="blackcolortypo fs20px">
                  Orders
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="blackcolortypo fs28px">
                  {allDashboardData?.orders ? (
                    <>
                      <span style={{ color: "orange", marginRight: "4px" }}>
                        {allDashboardData.orders.pendingNOrders}
                      </span>
                      /
                      <span
                        className="blackcolortypo500"
                        style={{ marginLeft: "4px" }}
                      >
                        {allDashboardData.orders.totalNOrders}
                      </span>
                    </>
                  ) : (
                    0
                  )}
                </Typography>
              </Grid>

              {/* <Grid container item>
                  <Typography className="blackcolortypo fs14px">
                    <span className="greencolortypo_600 fs14px">12%</span>{" "}
                    Increase From Last Month{" "}
                  </Typography>
                </Grid> */}
            </Link>
          </Grid>
        </Grid>

        {/*  */}
        <Grid container className="mt20px" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Card className="dash-table-card">
              <Grid container className="mt10px" justifyContent="space-between">
                <Grid item>
                  <Typography className="blackcolortypo500 fs1-3rem">
                    Top 10 Billboards
                    {PermissionCheck("add-billboard") && (
                      <Button className="addbtn">
                        <IoMdAddCircleOutline className="iconcolorbk   fs25px mr10  mt2px " />
                        <Link
                          to="/admin/addbillboard"
                          className="linkcolor purplecolortypo"
                        >
                          <Typography className="purplecolortypo-500 fs18px ml5px">
                            Add New
                          </Typography>
                        </Link>
                      </Button>
                    )}
                  </Typography>
                </Grid>
                {PermissionCheck("view-live-billboard") && (
                  <Grid item>
                    <Link to="/admin/billboardscreens" className="link-style">
                      <Typography className="purplecolortypo-500  pt7px">
                        See All
                      </Typography>
                    </Link>
                  </Grid>
                )}
              </Grid>
              <div className="table-style">
                <Grid container className="width100 " sx={{ mb: 3 }}>
                  <Table
                    className="width100 home_table"
                    aria-label="simple table "
                  >
                    <TableHead className="width100 tablehead homelist-item ">
                      <TableRow className="tablehead  ">
                        <TableCell className="blackcolortypo500 tablecell fs18px ">
                          Billboard Name
                        </TableCell>
                        <TableCell
                          className="blackcolortypo500 tablecell fs18px"
                          align="center"
                        >
                          Orders
                        </TableCell>
                        <TableCell
                          className="blackcolortypo500 tablecell fs18px"
                          align="center"
                        >
                          Revenue
                        </TableCell>
                        <TableCell
                          className="blackcolortypo500 tablecell fs18px"
                          align="center"
                        >
                          Views
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tablerow home_table">
                      {billBoardList.length > 0 &&
                        billBoardList.map((item, index) => {
                          return (
                            <TableRow
                              key={item.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                className="homelist-itemtext fs16px"
                                component="th"
                                scope="row"
                                align="left"
                              >
                                <Link
                                  to={`/admin/billboardscreens/${item._id}`}
                                  className="linkcolor"
                                  state={{ item: item }}
                                >
                                  <Typography className="homelist-itemtext fs16px">
                                    {" "}
                                    {item.billboardName}
                                  </Typography>

                                  <Typography className=" greycolor505050500 fs14px">
                                    {item.billboardId}
                                  </Typography>
                                </Link>
                              </TableCell>

                              <TableCell
                                scope="row"
                                padding="none"
                                className="greycolortypo "
                                align="center"
                              >
                                {item.order}
                              </TableCell>
                              <TableCell
                                scope="row"
                                padding="none"
                                className="greycolortypo"
                                align="center"
                              >
                                <BiRupee />
                                {parseInt(item?.revenue).toFixed(2)}
                                {/* {item?.revenue && item?.revenue.length > 0
                                      ? item?.revenue[0]?.totalRevenue?.toFixed(
                                          2
                                        )
                                      : 0} */}
                              </TableCell>
                              <TableCell
                                scope="row"
                                padding="none"
                                className="greycolortypo"
                                align="center"
                              >
                                {item?.views}
                                {/* {item?.views && item?.views.length > 0
                                    ? item?.views[0]?.totalViews
                                    : 0} */}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  {billBoardList?.length === 0 && (
                    <Grid
                      container
                      alignItems="center"
                      sx={{
                        height: "610px",
                      }}
                    >
                      <Typography
                        align="center"
                        className="greycolor505050500 width100 "
                      >
                        No BillBoard Found <br />
                        Please Add BillBoard To See Data
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Card className="dash-table-card">
              <Grid
                container
                justifyContent="space-between"
                className="width100 mt10px"
              >
                <Typography align="left" className="blackcolortypo500  fs20px ">
                  Ongoing Campaigns
                </Typography>
                {PermissionCheck("view-live-campaign") && (
                  <Link to={"/admin/campaignmgt"} className="link-style">
                    <Typography
                      align="right"
                      className="purplecolortypo-500 pt5px "
                    >
                      See All
                    </Typography>
                  </Link>
                )}
              </Grid>
              <div className="table-style">
                <Grid container className="width100 pt5px" sx={{ mb: 3 }}>
                  <Table className="width100" aria-label="simple table">
                    <TableHead className="width100 tablehead homelist-item">
                      <TableRow className="tablehead">
                        <TableCell className="blackcolortypo500 tablecell fs18px">
                          Campaign Name
                        </TableCell>
                        <TableCell
                          className="blackcolortypo500 tablecell fs18px"
                          align="left"
                        >
                          BillBoard
                        </TableCell>
                        <TableCell
                          className="blackcolortypo500 tablecell fs18px"
                          align="centerq"
                        >
                          Budget
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className="width100">
                      {oncampaign?.map((row, index) => {
                        return (
                          <>
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                className="homelist-itemtext fs16px text_transform_capitalize"
                                component="th"
                                scope="row"
                                align="left"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/admin/campaignmgt/${row?._id}`, {
                                    state: { row },
                                  })
                                }
                              >
                                {row.campaignName}
                                <Typography className=" greycolor505050500 fs14px">
                                  {row?.startDate + "/" + row?.endDate}
                                </Typography>
                              </TableCell>
                              <TableCell
                                scope="row"
                                padding="none"
                                className="greycolortypo"
                                align="left"
                              >
                                {row?.screens?.length}
                              </TableCell>
                              <TableCell
                                scope="row"
                                padding="none"
                                className="greycolortypo"
                                align="left"
                              >
                                <BiRupee />{" "}
                                {row?.orderPrice ? row?.orderPrice : 0}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>

                  {oncampaign?.length === 0 && (
                    <Grid
                      container
                      alignItems="center"
                      sx={
                        {
                          // height: "610px",
                        }
                      }
                    >
                      <Typography
                        align="center"
                        className="greycolor505050500 width100 "
                      >
                        No Campaigns Found <br />
                        Please Add Campaigns To See Data
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            border: "1px solid #ddd",
            margin: "24px 0",
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container alignItems="center">
                <Typography className="blackcolortypo500 fs20px">
                  User
                  <Button className="addbtn">
                    <AddUser PermissionCheck={PermissionCheck} />
                  </Button>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Link to="/admin/manage-user" className="link-style">
                <Typography className="purplecolortypo-500 pt7px">
                  See All
                </Typography>
              </Link>
            </Grid>
          </Grid>

          <Grid container gap="20px">
            <Grid
              container
              item
              md={3}
              sm={5}
              xs={12}
              sx={{
                border: "1px solid #ddd",
                borderRadius: "16px",
                padding: "20px 0 10px 0",
              }}
              className="mt10px"
              direction="column"
              alignItems="center"
            >
              <IconButton className="cursor_auto" disableRipple>
                <img
                  src={agencycardIcon}
                  style={{ width: "83px", height: "83px" }}
                />
              </IconButton>
              <Typography className="greycolortypo     pt10px  " align="center">
                Ad Agency
              </Typography>
              <Typography
                align="center"
                className="purplecolortypo fs20px pt10px"
              >
                {userCount?.adagency ? userCount.adagency : 0}
              </Typography>
            </Grid>
            <Grid
              container
              item
              md={3}
              sm={5}
              xs={12}
              sx={{
                border: "1px solid #ddd",
                borderRadius: "16px",
                padding: "20px 0 10px 0",
              }}
              className="mt10px"
              direction="column"
              alignItems="center"
            >
              <IconButton className="cursor_auto" disableRipple>
                <img
                  src={buisnecardIcon}
                  alt="AddAgency"
                  style={{ width: "83px", height: "83px" }}
                />
              </IconButton>
              <Typography className="greycolortypo     pt10px  " align="center">
                Buisness Owner
              </Typography>
              <Typography
                align="center"
                className="purplecolortypo fs20px pt10px"
              >
                {userCount?.businessowner ? userCount.businessowner : 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* <Card className="dash-table-card">
            <Grid container className="mt10px" justifyContent="space-between">
              <Grid item>
                <Typography className="greycolor3D3D3D fs1-3rem">
                  Locations
                </Typography>
              </Grid>
              <Grid item>
                <Link to="/admin/location" className="link-style">
                  <Typography className="purplecolortypo pt7px">
                    See All
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Grid item lg={12} xs={12}>
                <div>
                  <Map data={allBillboards} />
                </div>
              </Grid>
            </Grid>
          </Card> */}

        <Grid
          container
          justifyContent="space-between"
          sx={{ marginBottom: "30px" }}
          rowGap="30px"
        >
          <Grid
            container
            item
            lg={5.8}
            xs={12}
            sx={{
              // boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid #ddd",
              padding: "10px",
              borderRadius: "15px",
            }}
          >
            <Home_Bar_Analytics />{" "}
          </Grid>

          <Grid
            container
            item
            lg={5.8}
            xs={12}
            sx={{
              // boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid #ddd",
              padding: "10px",
              borderRadius: "15px",
            }}
          >
            <Home_bar_Analytics_Finance />{" "}
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          sx={{ marginBottom: "50px" }}
          rowGap="30px"
        >
          <Grid
            container
            item
            md={5.8}
            sm={12}
            sx={{
              // boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid #ddd",
              padding: "10px 20px",
              borderRadius: "15px",
            }}
          >
            <div style={{ width: "100%" }}>
              <Grid container gap="16px">
                {" "}
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className="greycolor505050400 fs20px">
                      Top Advertisee
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                      <Typography className="purplecolortypo">
                        See All
                      </Typography>
                    </Grid> */}
                </Grid>
                <Grid container>
                  <Typography className="greyColorTypo717171_400 fs16px">
                    Here’s top Advertisee in last 7 days
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid container>
                    <Grid item xs={4.8}>
                      <Typography className="blackcolor484848 fs18px">
                        Advertisee Name
                      </Typography>
                    </Grid>
                    <Grid container item justifyContent="flex-end" xs={3.6}>
                      <Typography className="blackcolor484848 fs18px">
                        Orders{" "}
                      </Typography>
                    </Grid>
                    <Grid container item justifyContent="flex-end" xs={3.6}>
                      <Typography className="blackcolor484848 fs18px">
                        Amount{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  {topAdvertiseeData?.map((tData, i) => (
                    <>
                      <Grid container key={i}>
                        <Grid container alignItems="center">
                          <Grid item xs={4.8}>
                            <Typography className="purplecolortypo fs16px text_transform_capitalize">
                              {/* {tData?.firstName} {tData?.lastName}
                               */}
                              {tData?.agencyName
                                ? tData?.agencyName
                                : tData?.firstName
                                ? tData?.firstName + " " + tData?.lastName
                                : tData?.name
                                ? tData?.name
                                : "user deleted"}
                            </Typography>
                            {/* <Typography className="greyColorTypo717171_400 fs12px">
                                Sector 59, Noida{" "}
                              </Typography> */}
                          </Grid>
                          <Grid
                            container
                            item
                            justifyContent="flex-end"
                            xs={3.6}
                          >
                            <Typography className="blackcolor484848 fs16px">
                              {tData?.orderCount}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            justifyContent="flex-end"
                            xs={3.6}
                          >
                            <Typography className="blackcolor484848 fs16px">
                              ₹ {tData?.revenueCount}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          borderTop: "1px solid #ddd",
                          margin: "6px 0 12px 0",
                        }}
                      ></Grid>
                    </>
                  ))}
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid
            container
            item
            md={5.8}
            sm={12}
            sx={{
              // boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid #ddd",
              padding: "10px 20px",
              borderRadius: "15px",
            }}
          >
            {/* <Card className="dash-table-card"> */}
            <Grid container className="mt10px" justifyContent="space-between">
              <Grid item>
                <Typography className="greycolor3D3D3D fs1-3rem">
                  Locations
                </Typography>
              </Grid>
              <Grid item>
                <Link to="/admin/location" className="link-style">
                  <Typography className="purplecolortypo pt7px">
                    See All
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Grid item lg={12} xs={12}>
                <div>
                  <Map data={allBillboards} />
                </div>
              </Grid>
            </Grid>
            {/* </Card> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
