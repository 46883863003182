import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Grid, Typography } from "@mui/material";

const AppCharts = ({ viewCount }) => {
  const colors = {
    steps: {
      stroke: "rgba(255, 151, 119, 1)",
      fill: "rgba(255, 151, 119, 0.5)",
    },
    threshold: { stroke: "#16A34A", fill: "#DCFCE7" },
    text: "#374151",
    background: "#fff",
  };
  return (
    <>
      {" "}
      <Grid container justifyContent="center">
        <Typography className="gray5A5A5Atypo500 fs20px">
          Age Group Distribution
        </Typography>
      </Grid>
      <ResponsiveContainer height={350} width={400}>
        <BarChart data={viewCount?.ageRangeData}>
          <XAxis
            dataKey={"_id"}
            angle={"40"}
            tickSize={8}
            tickMargin={15}
            tick={{ fill: colors.text }}
            tickLine={{ stroke: colors.text }}
            height={50}
          />
          <YAxis
            tick={{ fill: colors.text }}
            tickLine={{ stroke: colors.text }}
          />
          <CartesianGrid strokeDasharray="4" />
          <Tooltip
            contentStyle={{
              backgroundColor: colors.background,
              color: "#222",
            }}
            itemStyle={{ color: colors?.steps?.stroke }}
          />

          <Bar
            dataKey="views"
            stroke={colors.steps.stroke}
            fill={colors.steps.fill}
            strokeWidth={2}
            name="views"
            barSize={15}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default AppCharts;
