import { Tabs, Tab } from "@mui/material";
const TabList = ({
  value,
  handleChange,
  md,
  permissions,
  getTabLabel,
  classes,
  centered,
  variant,
  sx,
}) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      className={classes.Tabs}
      centered={centered && md ? true : false}
      variant={variant}
      scrollButtons
      aria-label="scrollable force tabs example"
    >
      {Object.keys(permissions).map((permission, index) => {
        if (permissions[permission]) {
          return (
            <Tab
              sx={sx}
              key={permission}
              label={getTabLabel(permission)}
              className={classes.Tab}
            />
          );
        }
        return null;
      })}
    </Tabs>
  );
};

export default TabList;
