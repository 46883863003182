import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../api/axiosInstance";
import { Grid, Typography, Button, Snackbar, Alert } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import Secure from "./../../../../assets/Img/secure.png";
function MfaSeting({ userID }) {
  const [mfaQrcode, setMfaQrcode] = useState();
  const [otp, setOtp] = React.useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [snackerropen, setSnackerropen] = useState(false);

  const [severity, setSeverity] = useState("info");
  // mfa
  const [mfaToggle, setmfaToggle] = useState();
  const [mfaSuccess, setMfaSuccess] = useState(true);

  const [mfaAdStatus, setmfaAdStatus] = useState(false);
  useEffect(() => {
    if (mfaAdStatus) {
      setmfaToggle(mfaAdStatus);
    }
  }, [mfaAdStatus]);

  const onClickmfaToggle = (e) => {
    const newBurnerToggle = !mfaToggle;
    setmfaToggle(newBurnerToggle);

    changemfaAdsStatus(newBurnerToggle); // Pass the new state as the status
  };

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };

  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  const mfaGenerateQr = async () => {
    let body = {
      _id: userID,
    };
    try {
      const res = await axiosInstance.post(`api/user/generateMFAQrCode`, body);
      console.log("mfacode", res);
      if (res.status === 200 || res.status === 201) {
        const blobResponse = await fetch(res?.data?.image);
        const blob = await blobResponse.blob();

        // Create a URL for the Blob
        const imgUrl = URL.createObjectURL(blob);

        // Set the URL as the image source
        setMfaQrcode(imgUrl);
      }
    } catch (error) {
      console.error(error, "error");
      // handleShowSnackbar("error");
    }
  };

  useEffect(() => {
    mfaGenerateQr();
  }, [userID]);

  const mfaCodeVerify = async () => {
    let body = {
      _id: userID,
      status: true,
      code: otp,
    };
    try {
      const res = await axiosInstance.post(
        `api/user/authenticatorAppMFA`,
        body
      );
      console.log("mfacode", res);
      if (res.status === 200 || res.status === 201) {
        setOtp();
        setSnackOpen(true);
        setSnackMsg(res?.data?.msg);
        handleShowSnackbar("success");
        setMfaSuccess(false);
      }
    } catch (error) {
      setOtp();
      console.error(error, "error");
      handleShowSnackbar("error");
      setSnackMsg(error.response?.data?.msg);
    }
  };
  console.log("code,", otp);
  // mfa
  const getmfaAdStatus = async () => {
    try {
      const res = await axiosInstance.get(
        `api/user/getUserMFAStatus/${userID}`
      );

      //.log(res, "res");
      if (res.status == 200 || res.status == 201) {
        console.log("res for status", res);
        setmfaAdStatus(res?.data?.enabled);
      } else {
        //.log("error getting burnerVdo res", res.msg);
      }
    } catch (error) {
      //.log(error, "error");
    }
  };
  useEffect(() => {
    getmfaAdStatus();
  }, []);
  const changemfaAdsStatus = async (status) => {
    let body = {
      _id: userID,
      status: status,
    };
    try {
      const res = await axiosInstance.post(`api/user/changeMFAStatus `, body);
      if (res.status == 200 || res.status == 201) {
        setSnackOpen(true);
        setSnackMsg("Status Updated");
        handleShowSnackbar("success");
      }
    } catch (error) {
      //.log(error, "error");
      handleShowSnackbar("error");
    }
  };
  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={severity}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={severity}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      {mfaSuccess ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography className="mt10px greycolor505050400 fs20px">
            Scan the QR code on your authenticator app
          </Typography>
          <Typography className="lightgreycolortypomfa mt1px">
            Note - Please use only Google Authenticator or Microsoft
            Authenticator app.
          </Typography>
          <Grid container justifyContent="center">
            <img src={mfaQrcode} alt="mfa Qr code" />
          </Grid>
          <Grid item alignItems="center" textAlign="center">
            <Typography className="lightgreycolortypomfa mt1px">
              Please Enter the verification code
            </Typography>
            <MuiOtpInput
              autoFocus
              value={otp}
              onChange={handleChange}
              length={6}
              TextFieldsProps={{ size: "small" }}
              style={{
                width: "380px",
                textAlign: "center",
                marginTop: "8px", // Adjust as needed
              }}
            />
            <Button
              onClick={() => {
                mfaCodeVerify();
              }}
              align="center"
              className="burner-details-btn"
              sx={{ padding: "12px", marginTop: "18px" }}
            >
              Verify code
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: "20px" }}
        >
          <img
            src={Secure}
            style={{ backgroundColor: "transparent", width: "1   50px" }}
          />
          <Typography className="mt10px greycolor505050400 fs20px">
            Your account is protected with 2-Step Verification
          </Typography>
          <Typography className="lightgreycolortypomfa mt1px">
            You received one time passcode on your Authenticator App while login
            use this passcode for login .
          </Typography>
        </Grid>
      )}
    </>
  );
}

export default MfaSeting;
