import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Typography,
  DialogContent,
  InputBase,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Stack,
  Pagination,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "../../../../api/axiosInstance";
import noOrderImage from "../../../../assets/Img/no_data_found_img.png";
//React Icons
import { MdOutlinePermContactCalendar } from "react-icons/md";
//core Module
import ApproveUserDialog from "./ApproveUserDialog";
import SearchIcon from "@mui/icons-material/Search";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",

  width: "36%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "93%",
      },
    },
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="dialogtitle" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogcrossicon"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const AssignDeviceID = props?.row?._id;
  const { getAllDevice } = props;

  const SelectAssignDeviceID = props?.AssignDeviceID;
  const { getDeviceInfo } = props;
  const value = props?.value;
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  // ==================== States ======================== //
  const [deviceData, setDeviceData] = React.useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [originalUserData, setOriginalUserData] = React.useState(null);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleClickOpen = () => {
    getUserDevices();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //=================================================//
  const handleChange = (e, data) => {
    setOriginalUserData(data?._id);
  };
  const handleChangeByPage = (event, value) => {
    setCurrentPage(value);
    getUserDevices(value);
  };

  const getUserDevices = async (value) => {
    try {
      let resp = await axios.get(
        `/api/user/deviceUserList?page=${value || currentPage}&limit=10`
      );
      setDeviceData(resp.data.msg.result);
      setTotalPage(resp.data.msg.totalPage);

      console.log(" GET DEVICE Select user all => ", resp.data.msg.result);
    } catch (error) {
      console.log("ERROR from GET DEVICE => ", error.message);
    }
  };

  // search use devices
  const geSearchUserDevices = async (value) => {
    if (!value) {
      getUserDevices();
      return;
    }
    try {
      let resp = await axios.get(
        `api/user/searchuser?userEmail=${value}&limit=10`
      );
      setDeviceData(resp.data.msg.result);
      setTotalPage(resp.data.msg.totalPage);

      console.log("search device data => ", resp);
    } catch (error) {
      console.log("ERROR in search device data => ", error.message);
    }
  };
  //
  // React.useEffect(() => {
  //   getUserDevices();
  // }, []);
  const AssignDevicetoUser = async () => {
    let body = {
      userId: originalUserData,
      deviceId: value ? SelectAssignDeviceID : AssignDeviceID,
    };
    try {
      let res = await axios.post("/api/device/assignUserToDevice", body);
      if (res.status === 200) {
        setSnackOpen(true);
        setSnackMsg(res.data.msg);
        setOpen(false);
        getDeviceInfo();
        getAllDevice();
        handleClose();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.data.msg.err);
        setOpen(false);
        handleClose();
        console.log(" Device Assing to Billboard", res.data.msg);
      }
    } catch (err) {
      console.log("Error from Remove Device Function", err);
    }
  };
  const DevicetoUser = async () => {
    let body = {
      userId: originalUserData,
      deviceId: value ? SelectAssignDeviceID : AssignDeviceID,
    };

    try {
      let res = await axios.post("/api/device/assignUserToDevice", body);
      if (res.status === 200) {
        setSnackOpen(true);
        setSnackMsg(res.data.msg);
        setOpen(false);
        getAllDevice();
        handleClose();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.data.msg.err);
        setOpen(false);
        handleClose();
        console.log(" Device Assing to Billboard", res.data.msg);
      }
    } catch (err) {
      console.log("Error from Remove Device Function", err);
    }
  };
  //=============================================================================//

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      {value ? (
        <Typography className="greencolortypo" onClick={handleClickOpen}>
          Assign User
        </Typography>
      ) : (
        <MdOutlinePermContactCalendar onClick={handleClickOpen} />
      )}

      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className="whitecolortypo500 fs16px">
            Select User
          </Typography>{" "}
        </BootstrapDialogTitle>
        <DialogContent sx={{ m: 0 }}>
          <div className="admin-content">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Search className="search-admin">
                <SearchIconWrapper>
                  <SearchIcon className="searchicon" />
                </SearchIconWrapper>
                <StyledInputBase
                  onChange={(e) => geSearchUserDevices(e.target.value)}
                  className=""
                  placeholder="Search User"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>{" "}
            </Grid>
            <Grid container>
              <RadioGroup
                style={{ width: "100%" }}
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                {deviceData?.length > 0 ? (
                  deviceData?.map((data) => {
                    return (
                      <Grid
                        item
                        sm={5.5}
                        md={2.7}
                        sx={{ border: "1px solid #dddddd", marginLeft: "20px" }}
                        className="access-radio-grid "
                      >
                        <Grid container justifyContent="space-between">
                          <FormLabel sx={{ padding: "8px 10px" }}>
                            <Typography className=" greycolor505050400">
                              {data?.firstName}
                            </Typography>
                            <Typography className="lightgreycolortypo">
                              {data?.email}
                            </Typography>
                          </FormLabel>
                          <FormControlLabel
                            value={data?._id}
                            className="radiostyle access-radio-formcontrolabel"
                            control={<Radio />}
                            style={{ justifyContent: "space-between" }}
                            onChange={(e) => handleChange(e, data)}
                            key={data._id}
                          />
                        </Grid>
                      </Grid>
                    );
                    // ========================================================== //
                  })
                ) : (
                  <Grid
                    sx={{ width: "100%" }}
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={noOrderImage}
                      alt="no order found"
                      className="noBillboardFoundGif_width"
                    />
                  </Grid>
                )}
              </RadioGroup>
            </Grid>
          </div>
        </DialogContent>
        {deviceData?.length > 0 && (
          <Grid container justifyContent="center" alignItems="center">
            <DialogActions className="dialogaction-p0px">
              <Stack spacing={2}>
                <Pagination
                  page={currentPage}
                  count={totalPage}
                  onChange={handleChangeByPage}
                  size="large"
                />
              </Stack>
            </DialogActions>
          </Grid>
        )}

        <DialogActions>
          <Button className="dcancelbutton" onClick={handleClose}>
            Cancel
          </Button>
          <ApproveUserDialog
            handleClose={handleClose}
            AssignDevicetoUser={AssignDevicetoUser}
            DevicetoUser={DevicetoUser}
            value={value}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
