import {
  Grid,
  Typography,
  Input,
  Stack,
  Avatar,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { MdVerifiedUser } from "react-icons/md";
import axiosInstance from "../../../../api/axiosInstance";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
const ProfileTab = ({ userID }) => {
  const [profile, setProfile] = React.useState([]);
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [numLength, setNumlength] = useState(10);

  const [email, setEmail] = useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [snackerropen, setSnackerropen] = useState(false);
  const [inputState, setInputState] = useState(true);

  const [severity, setSeverity] = useState("info");
  function ChangeInputCancel(e) {
    setInputState(true);
  }

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (profile) {
      setFirstName(
        profile?.name
          ? profile?.name
          : profile?.firstName + " " + profile?.lastName
      );

      setPhoneNumber(profile.mobileNumber ?? "");

      setEmail(profile?.email ?? "");
    }
  }, [profile]);

  function ChangeInputState() {
    setInputState(false);
  }
  const getProfile = async () => {
    try {
      let profile = await axiosInstance.get(
        `/api/user/getUserDetail/${userID}`
      );
      console.log("Profile ==> ", profile);
      setProfile(profile.data.msg);
    } catch (error) {
      console.log("USER PROFILE DATA ==>", error);
    }
  };

  const ProfileEdit = async () => {
    try {
      let response = await axiosInstance.post(`/api/user/profileupdate`, {
        name: firstName,

        mobileNumber: +phoneNumber,

        email: email,
        userId: userID,
      });

      console.log("res", response);
      if (response.status == 200) {
        setSnackOpen(true);
        setSnackMsg("Update Successfully");

        handleShowSnackbar("success");
        setInputState(true);
        getProfile(userID);
        console.log("Edit Profile   response  ===>", response.msg);
      } else {
        setSnackerropen(true);

        setSnackErrMsg("Failed to update");

        handleShowSnackbar("error");
        console.log("Error From Server ==> ", response.msg);
      }
    } catch (error) {
      handleShowSnackbar("error");
      console.log("Error during   Edit Profile data find  => ", error.message);
    }
  };
  useEffect(() => {
    getProfile();
  }, [userID]);

  useEffect(() => {
    if (String(phoneNumber)[0] == "9" && String(phoneNumber)[1] == "1") {
      setNumlength(12);
    } else {
      setNumlength(10);
    }
  }, [phoneNumber]);

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={severity}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={severity}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(ProfileEdit)}>
        <Grid container justifyContent="space-between" rowGap="20px">
          <Grid item xs={12} sm={5.8}>
            <Grid item>
              <Typography className="blackcolortypo fs20px  ">
                Profile
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                marginTop: "16px",
                border: "1px solid #ddd ",
                padding: "20px",
              }}
              rowGap="8px"
            >
              <Grid item xs={12}>
                {" "}
                <Typography className="blackcolortypo500 fs16px  ">
                  Name
                </Typography>
                <Input
                  className="inputstyle"
                  disableUnderline
                  value={firstName}
                  disabled={inputState}
                  {...register("Name-ErrorInput", {
                    required: "Name is required.",
                    value: { firstName },
                    onChange: (e) => {
                      setFirstName(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="Name-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Typography className="blackcolortypo500 fs16px  ">
                  Phone Number
                </Typography>
                <Input
                  className="inputstyle"
                  disableUnderline
                  value={phoneNumber}
                  disabled={inputState}
                  {...register("PhoneNo-ErrorInput", {
                    required: "Mobile Number is required.",
                    minLength: numLength,
                    maxLength: numLength,
                    value: { phoneNumber },
                    onChange: (e) => {
                      setPhoneNumber(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="PhoneNo-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
                {parseInt(phoneNumber.length) < 10 && (
                  <Typography className="redcolortypo400">
                    Please Enter Valid Mobile Number
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Typography className="blackcolortypo500 fs16px  ">
                  Email
                </Typography>
                <Input
                  className="inputstyle"
                  disableUnderline
                  disabled={inputState}
                  value={email}
                  {...register("Email-ErrorInput", {
                    required: "Email  is required.",
                    // pattern: /^\S+@\S+$/i,
                    value: { email },
                    onChange: (e) => {
                      console.log("Check Email", email);
                      setEmail(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="Email-ErrorInput"
                  render={({ message }) => (
                    <Typography className="redcolortypo400">
                      {message}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={5.8}
            style={{ height: "100%" }}
            direction="column"
          >
            <Grid item>
              <Typography className="blackcolortypo fs20px  ">
                Profile picture
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                border: "1px solid #ddd",
                marginTop: "16px",
                height: "100%",
              }}
            >
              <Grid container item>
                <div
                  className="profile-img"
                  style={{ paddingBottom: "15px", marginTop: "20px" }}
                >
                  <Stack alignItems="center" direction="row">
                    <Avatar
                      className="avatar-bg-color"
                      style={{
                        width: "140px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        borderRadius: "70%",
                        height: "140px",
                      }}
                    >
                      <Typography className="fs50px whitecolortypo">
                        {profile?.firstName?.split(" ")[0][0]}
                        <span className="ml5px ">
                          {profile?.lastName?.split(" ")[0][0]}
                        </span>
                      </Typography>
                    </Avatar>
                  </Stack>
                  <Typography
                    align="center"
                    className="width100 mt10px  greycolor505050500 fs18px"
                  >
                    <MdVerifiedUser className="iconcolor " />
                    Verified
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ marginTop: "15px" }}
              justifyContent="flex-end"
              gap="20px"
            >
              {" "}
              {inputState ? (
                <>
                  <Button
                    className=" purplebtn"
                    onClick={() => ChangeInputState()}
                  >
                    Edit Profile
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="cancelbutton"
                    onClick={() => ChangeInputCancel()}
                  >
                    Cancel
                  </Button>
                  <Button className=" purplebtn" type="submit">
                    Save Changes
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ProfileTab;
