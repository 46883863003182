import React, { useEffect, useState, useRef } from "react";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";
import { Grid, Typography, Snackbar, Alert } from "@mui/material";
import DeleteTutorial from "./Dialog/DeleteTutorial";
import TutorialDialog from "./Dialog/TutorialDialog";
import axiosInstance from "../../../api/axiosInstance";
import LoaderDialogue from "../../../component/LoaderDialogue/LoaderDialogue";

function Tutorial() {
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [snackerropen, setSnackerropen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [loading, setLoading] = useState(false);
  const [turorialList, setTurorialList] = useState([]);
  const [tutorialDetailByid, setTutorialDetailByid] = useState([]);
  const vidRef = useRef();

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };

  const breadcrumbItems = [
    { label: "Dashboard", link: "/" },
    {
      label: "Upload Tutorial",
    },
  ];

  const getTutorial = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/tutorial/fetchAllTutorials?limit=10&page=1`
      );
      if (res.status == 200 || res.status == 201) {
        console.log("resssssssssssss", res);
        setLoading(false);

        setTurorialList(res?.data?.data);
      } else {
        //.log("error getting burnerVdo res", res.msg);
        setLoading(false);
      }
    } catch (error) {
      //.log(error, "error");
    }
  };
  const deleteBurnerAd = async (key) => {
    setLoading(true);
    try {
      const res = await axiosInstance.delete(
        `/api/tutorial/deleteTutorialById/${key}`
      );
      console.log("dlt", res);
      if (res.status == 200 || res.status == 201) {
        setSnackOpen(true);
        setSnackMsg(res?.data?.msg);
        setLoading(false);
        getTutorial();
        handleShowSnackbar("success");
      } else {
        setSnackErrMsg(res?.data?.msg);
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (error) {
      setLoading(false);
      setSnackerropen(true);
      handleShowSnackbar("error");
      setSnackErrMsg(error?.response?.data?.msg);
    }
  };
  const getTutorialByid = async (key) => {
    console.log("resssssssssssss");
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/tutorial/fetchTutorialById/${key}`
      );
      if (res.status == 200 || res.status == 201) {
        console.log("resssssssssssss", res);
        setLoading(false);

        setTutorialDetailByid(res?.data?.tutorial);
      } else {
        //.log("error getting burnerVdo res", res.msg);
        setLoading(false);
      }
    } catch (error) {
      //.log(error, "error");
    }
  };
  useEffect(() => {
    getTutorial();
  }, []);

  return (
    <React.Fragment>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={severity}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={severity}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <div className="width-90LR">
        <div className="flex-class">
          <CustomBreadcrumbs breadcrumbItems={breadcrumbItems} />
        </div>
        <Grid
          container
          justifyContent="space-between"
          sx={{ margin: "20px 0" }}
        >
          <Grid item>
            <Typography className="blackcolor484848 fs20px">
              Showing{" "}
              {turorialList?.totalDocuments ? turorialList?.totalDocuments : 0}{" "}
              Tutorial
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={3} gap={3} className="relative">
          <Grid item xs={12} sm={5.8} md={2.8}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              xs={12}
              style={{
                height: "300px",
                position: "relative",
                border: "1px solid #ddd",
                background: "#F7F8FD",
                borderRadius: "8px 8px 8px 8px",
              }}
            >
              {turorialList?.result?.length > 9 ? (
                <>
                  <Typography
                    align="center"
                    className="blackcolor484848 fs20px"
                  >
                    You have already uploaded 10 Tutorial.
                  </Typography>
                  <Typography
                    align="center"
                    className="blackcolor484848 fs20px"
                  >
                    To upload a new Tutorial,
                  </Typography>
                  <Typography
                    align="center"
                    className="blackcolor484848 fs20px"
                  >
                    Please delete some of your existing Tutorial first.
                  </Typography>
                </>
              ) : (
                <>
                  <TutorialDialog
                    setSnackOpen={setSnackOpen}
                    setSnackMsg={setSnackMsg}
                    setSnackErrMsg={setSnackErrMsg}
                    setSnackerropen={setSnackerropen}
                    handleShowSnackbar={handleShowSnackbar}
                    getTutorial={getTutorial}
                  />

                  <Typography
                    align="center"
                    className="blackcolor484848 fs20px"
                  >
                    Upload Tutorial
                  </Typography>
                  <Typography
                    align="center"
                    className="blackcolor484848 fs16px"
                  >
                    {/* (Format: jpg,jpeg,mp4) */}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          {turorialList?.result ? (
            turorialList?.result?.map((data, index) => (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={2.8}
                  className="relative"
                  key={index}
                >
                  <Grid container className="relative">
                    <Grid
                      item
                      xs={12}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #ddd",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                          height: "251px",
                        }}
                      >
                        {data?.fileType === "video/mp4" ? (
                          <video
                            controls
                            src={data?.url}
                            width="100%"
                            height="100%"
                            ref={vidRef}
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              top: 0,
                              left: 0,
                            }}
                          />
                        ) : (
                          <img
                            src={data?.url}
                            style={{
                              width: "100%",
                              objectFit: "contain",
                              height: "100%",
                            }}
                          />
                        )}
                      </div>
                      <TutorialDialog
                        key={index}
                        turorialList={data}
                        getTutorialByid={getTutorialByid}
                        tutorialDetailByid={tutorialDetailByid}
                        deleteBurnerAd={deleteBurnerAd}
                      />
                    </Grid>

                    <Grid item className="absolute" sx={{ right: 7, top: 10 }}>
                      <DeleteTutorial
                        deleteBurnerAd={deleteBurnerAd}
                        id={data?._id}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ))
          ) : (
            <Grid item xs={8}>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <LoaderDialogue loading={loading} />
              </Grid>{" "}
            </Grid>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default Tutorial;
