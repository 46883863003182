import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Typography,
  DialogContent,
  InputBase,
  ListItemButton,
  FormControlLabel,
  Radio,
  IconButton,
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
  FormLabel,
  RadioGroup,
  Stack,
  Pagination,
  Checkbox,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
//React Icons
//core component
import ApproveDialog from "./ApproveDialogue";
import axiosInstance from "../../../../../../api/axiosInstance";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",

  width: "36%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "93%",
      },
    },
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="dialogtitle" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogcrossicon"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AssignClusterHead({
  userID,
  getAssignedClusterhead,
  snackStates,
  ClusterData,
}) {
  const { setSnackOpen, setSnackMsg, setSnackerropen, setSnackErrMsg } =
    snackStates;
  // states
  const [open, setOpen] = useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  const [clusterheadData, setClusterheadData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [deviceslength, setDevicesLength] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [uncheckedPermissions, setUncheckedPermissions] = useState([]);
  // const [clId, setClId] = useState(null);
  const [selectedClusterHeads, setSelectedClusterHeads] = useState([]);
  //open close dialogue
  const handleClickOpen = () => {
    setOpen(true);
    getuseraccordingType();
    if (ClusterData && ClusterData.length > 0) {
      const clusterHeadIds = ClusterData.map((item) => item._id);
      setSelectedClusterHeads(clusterHeadIds);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeByPage = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    if (ClusterData && ClusterData.length > 0) {
      const clusterHeadIds = ClusterData.map((item) => item._id);
      setSelectedClusterHeads(clusterHeadIds);
    }
  }, [ClusterData]);
  // Function to toggle the selection status of a cluster head
  const toggleClusterHeadSelection = (clusterHeadId) => {
    setSelectedClusterHeads((prevSelectedClusterHeads) => {
      if (prevSelectedClusterHeads.includes(clusterHeadId)) {
        // If already selected, remove it
        return prevSelectedClusterHeads.filter((id) => id !== clusterHeadId);
      } else {
        // If not selected, add it
        return [...prevSelectedClusterHeads, clusterHeadId];
      }
    });
  };
  // const toggleClusterHeadSelection = (key) => {
  //   if (selectedClusterHeads.includes(key)) {
  //     // If the access is already in permissions, remove it
  //     setSelectedClusterHeads(
  //       selectedClusterHeads.filter((access) => access !== key)
  //     );
  //     setUncheckedPermissions([...uncheckedPermissions, key]);
  //   } else {
  //     // If the access is not in permissions, add it
  //     setSelectedClusterHeads([...selectedClusterHeads, key]);
  //     setUncheckedPermissions(
  //       uncheckedPermissions.filter((access) => access !== key)
  //     );
  //   }
  // };
  console.log("selectedClusterHeads", selectedClusterHeads);
  // const selectClusterHeadHandler = (data) => {
  //   setClId(data);
  // };
  //   api calling

  const getuseraccordingType = async (query, sortBy, sortType) => {
    setClusterheadData([]);
    setTotalPage([]);
    setDevicesLength([]);
    setLoading(true);
    try {
      let resp = await axiosInstance.get(
        `/api/user/getUserList?role=ClusterHead&sortBy=${
          sortBy ? sortBy : null
        }&sortType=${
          sortType ? sortType : null
        }&page=${currentPage}&limit=100&query=${query ? query : " "}`
      );
      console.log("response from GetUserAccordingType =>", resp.data);
      setLoading(false);
      setClusterheadData(resp?.data?.msg);
      setTotalPage(Math.ceil(resp?.data?.lengthData / 15));
      setDevicesLength(Math.ceil(resp?.data?.lengthData));
    } catch (error) {
      setLoading(false);
      console.log("error from Error ", error);
    }
  };
  const assignClusterHeadHandler = async () => {
    try {
      let body = {
        subAdminId: userID,
        assignedUsers: selectedClusterHeads,
      };
      const res = await axiosInstance.post(
        `admin/assignClusterHeadToSubAdmin`,
        body
      );
      if (res.status == 200 || res.status == 204) {
        console.log("assigning clusterhead", res);

        getuseraccordingType();

        getAssignedClusterhead();
        setSnackOpen(true);
        setSnackMsg(res?.data?.msg);

        handleClose();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res?.data?.msg?.err);
        console.log("error while assigning clusterhead", res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Button
        className="order-purple-btn "
        sx={{ fontWeight: "500 !important" }}
        onClick={handleClickOpen}
      >
        Assign/Edit Clusterhead
      </Button>{" "}
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className="whitecolortypo500">
            Select Clusterhead{" "}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ m: 2 }}>
          <div>
            <div className="admin-content">
              <Grid container>
                {/* <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ width: "100%" }}
                > */}
                {clusterheadData?.map((data, i) => {
                  return (
                    <Grid
                      item
                      key={i}
                      xs={12}
                      sm={5.5}
                      md={2.7}
                      sx={{ border: "1px solid #dddddd", marginLeft: "20px" }}
                      className="access-radio-grid "
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        sx={{ cursor: "pointer" }}
                      >
                        <FormLabel>
                          <ListItemButton>
                            <Typography className=" greycolor505050400">
                              {data?.firstName
                                ? data?.firstName + " " + data?.lastName
                                : data?.name}
                              <Typography className="lightgreycolortypo">
                                {data?.role}
                              </Typography>
                            </Typography>
                          </ListItemButton>
                        </FormLabel>
                        <FormControlLabel
                          className="radiostyle access-radio-formcontrolabel"
                          value={data?._id}
                          style={{ paddingTop: "10px" }}
                          // control={<Radio />}
                          control={
                            <Checkbox
                              checked={selectedClusterHeads.includes(data?._id)}
                              onChange={(e) =>
                                toggleClusterHeadSelection(data?._id)
                              }
                            />
                          }
                          key={data?._id}
                          // onChange={(e) =>
                          //   selectClusterHeadHandler(data?._id)
                          // }
                        />
                      </Grid>
                    </Grid>
                  );
                })}
                {/* </RadioGroup> */}
              </Grid>
            </div>
          </div>
        </DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <DialogActions className="dialogaction-p0px mt20px">
            <Stack spacing={2}>
              <Pagination
                count={totalPage}
                page={currentPage}
                onChange={handleChangeByPage}
                size="large"
              />
            </Stack>
          </DialogActions>
        </Grid>
        <DialogActions>
          <Button className="previousbtn" onClick={handleClose}>
            Cancel
          </Button>
          <ApproveDialog assignClusterHeadHandler={assignClusterHeadHandler} />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
