import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  IconButton,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  Grid,
} from "@mui/material";
//React Icons
import permissionUtils from "../../../../../../../component/permissionUtils";
import ConfirmationDialog from "./confirmationDialog";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitleConfirmation = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2 }} className="purple-gradient" {...other}>
      {children}
      <Typography className="whitecolortypo">Burner Ad Detail </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 3,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitleConfirmation.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  resBurnerAds,
  value,
  getBurnerAd,
}) {
  const vidRef = useRef();
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");
  // SnackBar

  // ==================== States ======================== //
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button
          onClick={handleClickOpen}
          align="center"
          className="burner-details-btn"
          sx={{ padding: "12px" }}
        >
          View Details
        </Button>

        <BootstrapDialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitleConfirmation
            onClose={handleClose}
          ></BootstrapDialogTitleConfirmation>
          <DialogContent>
            <Grid container spacing={2} sx={{ padding: "20px 20px" }}>
              <Grid item xs={7}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    borderRadius: "10px",
                  }}
                >
                  <video
                    controls
                    src={
                      resBurnerAds?.s3Link ||
                      `https://s3.ap-south-1.amazonaws.com/storage.saps.one/${resBurnerAds?.Key}`
                    }
                    width="100%"
                    height="100%"
                    ref={vidRef}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                {value === 1 ? (
                  <Grid
                    item
                    xs={12}
                    className=" compaign-overview border_primary"
                  >
                    <Typography className="secondary_heading500 fs20px">
                      Please Approve Ad only if :
                    </Typography>
                    <ul>
                      <li>
                        <Typography className="secondary_heading500 fs16px">
                          This Burner Ad doesn’t break any guidelines.
                        </Typography>
                      </li>
                      <li>
                        <Typography className="secondary_heading500 fs16px">
                          This burner Ad is sfw
                        </Typography>
                      </li>
                    </ul>
                    {PermissionCheck("approve-reject-burner-ad") && (
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <ConfirmationDialog
                            handleCloseBurnerDetails={handleClose}
                            resBurnerAds={resBurnerAds}
                            getBurnerAd={getBurnerAd}
                            name={"Approve"}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <ConfirmationDialog
                            handleCloseBurnerDetails={handleClose}
                            resBurnerAds={resBurnerAds}
                            getBurnerAd={getBurnerAd}
                            name={"Reject"}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    className=" compaign-overview border_primary"
                  >
                    <Typography className="secondary_heading500 fs20px">
                      Approved by :-
                    </Typography>
                    <Grid container mt={2}>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px">
                          Date :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px">
                          {resBurnerAds?.uploadedAt?.split("T")[0]}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px mt10px">
                          Name :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px mt10px">
                          {resBurnerAds?.statusChangedBy?.role}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px mt10px">
                          Email :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px mt10px">
                          {resBurnerAds?.statusChangedBy?.email}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={4}>
                        <Typography className="secondary_graySubheading_500 fs16px mt10px">
                          Phone Number :
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12} xs={8}>
                        <Typography className="secondary_heading500 fs16px mt10px">
                          {resBurnerAds?.statusChangedBy?.mobileNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid
                  item
                  mt={2}
                  xs={12}
                  className=" compaign-overview border_primary"
                >
                  <Typography className="secondary_heading500 fs20px">
                    Uploaded by :-
                  </Typography>
                  <Grid container mt={2}>
                    <Grid item md={4} sm={12} xs={4}>
                      <Typography className="secondary_graySubheading_500 fs16px">
                        Date :
                      </Typography>
                    </Grid>
                    <Grid item md={8} sm={12} xs={8}>
                      <Typography className="secondary_heading500 fs16px">
                        {resBurnerAds?.uploadedAt?.split("T")[0]}
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={4}>
                      <Typography className="secondary_graySubheading_500 fs16px mt10px">
                        Name :
                      </Typography>
                    </Grid>
                    <Grid item md={8} sm={12} xs={8}>
                      <Typography className="secondary_heading500 fs16px mt10px">
                        {resBurnerAds?.uploadedBy?.role}
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={4}>
                      <Typography className="secondary_graySubheading_500 fs16px mt10px">
                        Email :
                      </Typography>
                    </Grid>
                    <Grid item md={8} sm={12} xs={8}>
                      <Typography className="secondary_heading500 fs16px mt10px">
                        {resBurnerAds?.uploadedBy?.email}
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={4}>
                      <Typography className="secondary_graySubheading_500 fs16px mt10px">
                        Phone Number :
                      </Typography>
                    </Grid>
                    <Grid item md={8} sm={12} xs={8}>
                      <Typography className="secondary_heading500 fs16px mt10px">
                        {resBurnerAds?.uploadedBy?.mobileNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}
