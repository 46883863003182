import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  InputBase,
  Stack,
  Pagination,
  Snackbar,
  Alert,
} from "@mui/material";
import moment from "moment";
import ConfirMationDialog from "../ConfirmationDialog.js/confirmationDialog";

import DeleteDilogueAll from "../ConfirmationDialog.js/DeleteAllTrash";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import TableSkeleton from "../../../../component/TableSkeleton/TableSkeleton";
import noDataImage from "../../../../assets/Img/no_data_found_img.png";
import axiosInstance from "../../../../api/axiosInstance";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "#ffffff",
  "&:hover": {
    backgroundColor: "#ffffff",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0.7, 0.7, 0.7, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      "&:focus": {
        width: "90%",
      },
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const headCells = [
  {
    id: "_id",
    numeric: false,
    label: "UID",
  },
  {
    id: "firstName",
    numeric: true,
    label: "Device Name",
  },

  {
    id: "macId",
    numeric: true,
    disablePadding: false,
    label: "MAC ID",
  },
  {
    id: "Deleted on",
    numeric: true,
    label: "Deleted on",
  },
  {
    id: "Deleted By",
    numeric: true,
    disablePadding: false,
    label: "Deleted By",
  },

  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const DeviceTrash = ({
  paginatedPage,
  paginateTabValue,
  getPaginatedValueHandler,
  tabValue,
  currentPage,
  setCurrentPage,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [file, setFile] = useState("Location_analytics");

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - 25) : 0;
  function handleClickBreadCrumb(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  ////////////////filter states////////////////

  const [regiondata, setRegiondata] = React.useState([]);

  ////////////////filter states////////////////
  const [search, setSearch] = useState(null);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [adminType, setAdminType] = useState("");
  const [adminData, setAdminData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [deviceslength, setDevicesLength] = React.useState("");
  const [sortByKey, setsortByKey] = useState("createdAt ");
  // ===================== SnackBar ==================== //
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const openSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };
  // loading
  const [loading, setLoading] = useState(true);
  const handleChangeByPage = (event, value) => {
    getPaginatedValueHandler("", value, tabValue);
    setCurrentPage(value);
  };
  // loading
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /////////api calling////////
  useEffect(() => {
    if (currentPage) {
      getuseraccordingType();
    }
  }, [search, currentPage]);

  const getuseraccordingType = async () => {
    setAdminData([]);
    setLoading(true);
    try {
      let resp = await axiosInstance.get(
        `/api/device/getdevice?status=All&page=${currentPage}&limit=10&keyword=${
          search === "" ? null : search
        }&sortBy=${sortByKey}&sortType=-1&isDelete=true`
      );
      console.log("response from GetUserAccordingType =>", resp.data);
      setLoading(false);
      setAdminData(resp?.data?.msg);
      setTotalPage(Math.ceil(resp?.data?.lengthData / 15));
      setDevicesLength(Math.ceil(resp?.data?.lengthData));
    } catch (error) {
      setLoading(false);
      console.log("error from Error ", error);
    }
  };

  const deleteAllDevice = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.post(
        "/api/billboard/deleteAllDeletedDevice"
      );
      if (res?.status == 200 || res?.status == 201) {
        setLoading(false);
        getuseraccordingType();
      } else {
        console.log("error while delete Device", res?.msg);
      }
    } catch (err) {
      console.log("error while delete Device", err);
    }
  };

  const restoreBillboard = async (id, handleClose) => {
    try {
      const resp = await axiosInstance.post(
        `/api/device/restoreDeviceById/${id}`
      );
      handleClose();

      getuseraccordingType();
      openSnackbar("Device Restore Successfully");
    } catch (error) {
      openSnackbar(error?.response?.data?.msg || "An error occurred.", "error");
    }
  };
  const permanentlyDeleteBillboard = async (id, handleClose) => {
    try {
      const resp = await axiosInstance.delete(
        `/api/device/permanentDelDevice/${id}`
      );
      handleClose();

      getuseraccordingType();
      openSnackbar("Device permanent delete Successfully");
    } catch (error) {
      openSnackbar(error?.response?.data?.msg || "An error occurred.", "error");
    }
  };

  useEffect(() => {
    if (paginatedPage < 1) {
      setCurrentPage(1);
      return;
    }
    setCurrentPage(paginatedPage ? paginatedPage : 1);
  }, [paginatedPage]);
  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography className="blackcolor484848 fs24px">
          Showing {adminData?.length} out of {deviceslength} Deleted Device
        </Typography>
        <Grid>
          <Grid
            container
            gap="10px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Search className="search-admin">
              <SearchIconWrapper>
                <SearchIcon className="searchicon" />
              </SearchIconWrapper>
              <StyledInputBase
                style={{ padding: "4px 4px 4px 4px" }}
                placeholder="Search Device"
                inputProps={{ "aria-label": "search" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Search>
            {adminData?.length > 0 && (
              <DeleteDilogueAll
                valueForDel={2}
                deleteAllDevice={deleteAllDevice}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="width100">
        <Grid container className="width100 mt10px">
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer className="width100  table_border_radius">
            <Table
              sx={{
                minWidth: 800,
                backgroundColor: "#fff",
                width: "100%",
              }}
              style={{ tableLayout: "fixed" }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                className="tablerow"
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
              />
              {adminData?.length > 0 ? (
                <TableBody className="tablerow ">
                  {adminData?.map((row, index) => (
                    <>
                      <TableRow
                        hover
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={row?._id}
                      >
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          #{row?.deviceUID}
                        </TableCell>

                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          {row?.deviceName?.length > 40
                            ? `${row?.deviceName?.substring(0, 20)}...`
                            : row?.deviceName}
                        </TableCell>

                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          {row?.macId}
                        </TableCell>
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          {moment(row?.deleteByDate)?.format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          <Grid sx={{ fontWeight: "bold" }}>
                            {row?.deleteBy?.firstName}
                          </Grid>
                          {row?.deleteBy?._id}
                        </TableCell>
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          <Grid container spacing={3} sx={{ padding: "6px" }}>
                            <Grid item xs={6}>
                              <ConfirMationDialog
                                name={"Restore"}
                                val={2}
                                id={row?._id}
                                restoreBillboard={restoreBillboard}
                                getuseraccordingType={getuseraccordingType}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <ConfirMationDialog
                                name={"Delete"}
                                val={2}
                                id={row?._id}
                                permanentlyDeleteBillboard={
                                  permanentlyDeleteBillboard
                                }
                                getuseraccordingType={getuseraccordingType}
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              ) : (
                loading && (
                  <TableSkeleton
                    rowNumber={new Array(10).fill(0)}
                    tableCell={new Array(6).fill("16.66%")}
                  />
                )
              )}
            </Table>
            {adminData && adminData?.length === 0 && loading == false && (
              <Grid container justifyContent="center">
                <img src={noDataImage} style={{ width: "40%" }} />
              </Grid>
            )}
          </TableContainer>
        </Grid>
      </Grid>
      {adminData?.length > 0 && (
        <Grid
          justifyContent="center"
          alignItems="center"
          sx={{ display: "flex", marginTop: "20px" }}
        >
          <Stack spacing={2}>
            <Pagination
              page={currentPage}
              count={totalPage}
              onChange={handleChangeByPage}
              size="large"
            />
          </Stack>
        </Grid>
      )}
    </div>
  );
};

export default DeviceTrash;
