import React, { useState, useEffect } from "react";

import {
  Typography,
  Grid,
  DialogActions,
  Button,
  InputBase,
  Tooltip,
} from "@mui/material";
import Upload from "../../../../assets/Img/upload.png";
import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";
import PreviewDialogue from "../../BillboardDetail/BillBoardSceenDetails/BillboardScreenTab's/ModifyBooking.js/ActionDialogues/PreviewDialogue";
import Alert_Resolution_dialogue from "./ActionDialogue/Alert_Resolution_Dialogue";
import SizeAndDurationDialogue from "../../BillboardDetail/BillBoardSceenDetails/BillboardScreenTab's/ModifyBooking.js/ActionDialogues/SizeAndDurationDialogue";
import {
  useCampaignController,
  setFile,
  setFileType,
} from "../../../../Context/campaignBook";
import permissionUtils from "../../../../component/permissionUtils";

export default function CampaignTab(props) {
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  const { setFiletype, videoLink, setVideoLink } = props;
  // const [file, setFile] = useState(props.data.uploadedContentView);
  // const [filetype, setFileType] = useState(props?.data?.filetype);
  const [videoDimensions, setVideoDimensions] = useState(
    props?.data?.videoDimensions
  );
  //==========> New Startes
  const [controller, dispatch] = useCampaignController();
  const { file, filetype, adDuration } = controller;
  console.log("Check filetype=----======", filetype);

  //const [filetype, setFiletype] = useState(null);
  //====================================== Onchange file ======================================//

  const [durationForVideo, setDuration] = useState(0);
  const [videoSize, setVideoSize] = useState(0);
  const [loading, setLoading] = useState(false);
  // upload content

  const [videoSizeDialogue, setVideoSizeDialogue] = useState(false);
  const [imageSizeDialogue, setImageSizeDialogue] = useState(false);

  const onChangeFile = (event) => {
    console.log("check ", event.target.files[0].type);
    if (
      event.target.files[0].type == "video/mp4" ||
      event.target.files[0].type == "video/webm"
    ) {
      setDuration(0);
      setVideoSize(event.target.files[0]?.size);
      // CONTAINEING IT IN A BLOCK SCOPE
      {
        const file = event.target.files[0];
        const video = document.createElement("video");
        if (event.target.files[0]?.size / 1000000 > adDuration * 2) {
          setVideoSizeDialogue(true);
          return;
        }
        video.addEventListener("loadedmetadata", () => {
          setDuration(video.duration);
          setVideoDimensions({
            width: video.videoWidth,
            height: video.videoHeight,
          });
        });

        video.src = URL.createObjectURL(file);
      }
      setFile(dispatch, URL.createObjectURL(event.target.files[0]));
      setFileType(dispatch, event.target.files[0].type); //==> For settinf File type
      props.data.handleCampaignData(
        URL.createObjectURL(event.target.files[0]),
        "uploadedContentView"
      );
      props.data.handleCampaignData(event.target.files[0].type, "contenttype");
      props.data.handleCampaignData(event.target.files?.[0], "mediafile");
      let Milliseconds =
        String(new Date().getFullYear()) +
        String(new Date().getMonth()) +
        String(new Date().getDate()) +
        String(new Date().getHours()) +
        String(new Date().getMinutes()) +
        String(new Date().getMilliseconds());

      let a = event.target.files[0].name.split(".")[0];
      console.log("original name of the video ===> ", a);

      let trimmedstr = a
        .replace(/[&\/\\_#,^!-@+()$~%'":-?<>{}[\]\s+]/g, "")
        .concat(Milliseconds);
      //console.log("trimmed str => ", trimmedstr);

      props.data.handleCampaignData(trimmedstr, "contentname");
      props.data.handleCampaignData(a, "originalvideoname");

      let lastExtensionLength = event.target.files[0].name?.split(".")?.length;
      let extension = ".".concat(
        event.target.files[0].name.split(".")?.[lastExtensionLength - 1]
      );
      console.log("Extension ===>", extension);
      let filename = trimmedstr.concat(extension); //==> to set the uniquename  of the file
      console.log("Final Name of the File => ", filename);
      // for (let file = 0; file < event.target.files.length; file++) {
      //   zip.file(filename, files[file]);
      // }
      // zip
      //   .generateAsync({
      //     type: "blob",
      //     compression: "DEFLATE",
      //     compressionOptions: {
      //       level: 1,
      //     },
      //   })
      //   .then((data) => {
      //     console.log("//=== convertion to zip success ===//");
      //     //setMediafile(data);
      //     props.data.handleCampaignData(data, "mediafile");
      //     //saveAs(data, a);
      //   });
    }
    //=====================================================================================//
    else if (event.target.files[0].type == "image/jpeg") {
      if (event.target.files[0]?.size / 1000000 > 5) {
        setImageSizeDialogue(true);
        return;
      }

      setFile(dispatch, URL.createObjectURL(event.target.files[0]));
      setFileType(dispatch, event.target.files[0].type); //==> For settinf File type
      props.data.handleCampaignData(
        URL.createObjectURL(event.target.files[0]),
        "uploadedContentView"
      );
      props.data.handleCampaignData(event.target.files[0].type, "contenttype");
      setFileType(dispatch, event.target.files[0].type); //==> getting File type of here
      let Milliseconds =
        String(new Date().getFullYear()) +
        String(new Date().getMonth()) +
        String(new Date().getDate()) +
        String(new Date().getHours()) +
        String(new Date().getMinutes()) +
        String(new Date().getMilliseconds());
      let a = event.target.files[0].name.split(".")[0];
      console.log("Image Oriiginal Name ===> ", a);

      props.data.handleCampaignData(a, "imageoriginalname");
      let trimmedstr = a
        .replace(/[&\/\\_#,^!-@+()$~%'":-?<>{}[\]\s+]/g, "")
        .concat(Milliseconds);
      console.log("Unique Image => ", trimmedstr);

      props.data.handleCampaignData(trimmedstr, "contentname");
      let extension = ".".concat(event.target.files[0].name.split(".")[1]);
      console.log("Image Extension ===>", extension);

      //Converting to Base 64 ===>
      const file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        onLoad(reader.result);
      };
      const onLoad = (fileString) => {
        //console.log("FileString ==> ", fileString);

        props.data.handleCampaignData(fileString, "imageString");
      };
    }
  };
  // kchanges
  const [playVideo, setPlayVideo] = useState(true);
  const [playUrl, setPlayUrl] = useState("");
  // check previewContainer
  console.log("CheckvideoLink ", videoLink);
  const CheckPreview = () => {
    return (
      <>
        {Object.keys(videoDimensions)?.length > 0 &&
          videoDimensions?.width !==
            props?.data?.selectDevice?.[0]?.billboardSize?.split("X")?.[0] && (
            <Grid container className="check_preview_container mt10px">
              <Grid item xs={12} sx={{ padding: "20px" }}>
                <Typography align="center" className="redcolortypo_500">
                  Warning : You have selected Video with lower resolution then
                  required.Please Select{" "}
                  {
                    props?.data?.selectDevice?.[0]?.billboardSize?.split(
                      "X"
                    )?.[0]
                  }
                  p quality video of resolution{" "}
                  {props?.data?.selectDevice?.[0]?.billboardSize}
                </Typography>
              </Grid>
              <PreviewDialogue vdo={file} />
            </Grid>
          )}
      </>
    );
  };
  useEffect(() => {
    if (videoLink) {
      setPlayVideo(true);
      setPlayUrl(videoLink);
    }
  }, [videoLink]);
  useEffect(() => {
    props.data.handleCampaignData(videoDimensions, "dimension");
  }, [videoDimensions]);
  const checkUrlHandler = (url) => {
    console.log("run");
    setPlayVideo(true);
    setPlayUrl(url);
  };
  const handlePlayerError = (error) => {
    console.log(error);
    if (error?.type === "error") {
      setPlayVideo(false);
      setVideoLink("");
      return;
    }
    setPlayVideo(true);
  };

  return (
    <>
      {videoSizeDialogue && (
        <SizeAndDurationDialogue
          videoSizeDialogue={videoSizeDialogue}
          setVideoSizeDialogue={setVideoSizeDialogue}
          adDuration={adDuration}
        />
      )}
      {imageSizeDialogue && (
        <SizeAndDurationDialogue
          imageSizeDialogue={imageSizeDialogue}
          setImageSizeDialogue={setImageSizeDialogue}
        />
      )}

      <Grid
        container
        className="mt10px campaign-container width100 "
        sx={{ paddingBottom: "20px" }}
      >
        <Typography
          className="blackcolortypo500 fs20px mt30px width100"
          align="center"
          // sx={{ marginBottom: "10px" }}
        >
          Add Content (Suggested :{" "}
          {props?.data?.selectDevice?.[0]?.billboardSize})
        </Typography>
        {/*  */}
        <CheckPreview />
        {!playUrl ? (
          <>
            {" "}
            {!filetype || filetype == "url" ? (
              <>
                <Grid
                  container
                  item
                  md={12}
                  className="link-upload-div mt10px "
                  variant="raised"
                  style={{
                    position: "relative",
                    height: "300px",
                  }}
                >
                  <img src={Upload} alt="loading" className="Upload-Folder" />
                  <Typography className="Upload-Folder-text font-family">
                    Click here to upload from your device
                  </Typography>
                  <Typography
                    className="Upload-Folder-text font-family"
                    sx={{ marginBottom: "10px" }}
                  >
                    (Please use image/video of resolution{" "}
                    {props?.data?.selectDevice?.[0]?.billboardSize})
                  </Typography>

                  <input
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      opacity: "0",
                    }}
                    type="file"
                    id="raised-button-file"
                    accept="video/mp4, image/jpeg,audio/*"
                    // accept="video/*"
                    // accept="image/jpg,image/jpeg"
                    onChange={(e) => {
                      onChangeFile(e);
                    }}
                    disabled={videoLink ? true : false}
                  />
                </Grid>
              </>
            ) : filetype === "video/mp4" || filetype === "video/webm" ? (
              <>
                <div
                  className="mt10px"
                  style={{
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <CloseIcon
                    onClick={() => {
                      setFileType(dispatch, null);
                      setVideoDimensions({});
                      props.data.handleCampaignData(
                        null,
                        "uploadedContentView"
                      );
                    }}
                    style={{
                      width: "20px",
                      cursor: "pointer",
                    }}
                  />
                  <video
                    autoplay
                    controls
                    muted
                    src={file}
                    type="video/mp4"
                    style={{
                      height: "300px",
                      width: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    // className="dotted-border "
                  />
                </div>
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="raised-button-file"
                  accept="image/jpeg,video/mp4"
                  onChange={(e) => {
                    onChangeFile(e);
                  }}
                />
              </>
            ) : (
              <>
                <div
                  className="mt10px"
                  style={{
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <CloseIcon
                    onClick={() => {
                      setFileType(dispatch, null);
                      setVideoDimensions({});
                      props.data.handleCampaignData(
                        null,
                        "uploadedContentView"
                      );
                    }}
                    style={{
                      width: "20px",
                      cursor: "pointer",
                    }}
                  />
                  <img
                    src={file}
                    style={{
                      height: "350px",
                      width: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      objectFit: "contain",
                    }}
                    // className="dotted-border"
                  />
                </div>
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="raised-button-file"
                  accept="image/jpeg,video/mp4"
                  onChange={(e) => {
                    onChangeFile(e);
                    // className = "dotted-border";
                  }}
                />
              </>
            )}
          </>
        ) : (
          <Grid container sx={{ position: "relative" }}>
            {playVideo ? (
              <>
                <ReactPlayer
                  url={playUrl}
                  onError={handlePlayerError}
                  height="400px"
                  width="100%"
                  style={{
                    height: "300px",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </>
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  height: "300px",
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Typography>The url you are providing is wrong</Typography>
              </Grid>
            )}

            <CloseIcon
              onClick={() => {
                setPlayUrl(null);
                setPlayVideo(true);
                setVideoLink("");
                setVideoDimensions({});
                props.data.handleCampaignData(null, "uploadedContentView");
              }}
              style={{
                width: "20px",
                cursor: "pointer",
                position: "absolute",
                right: "0",
                color: "red",
              }}
            />
          </Grid>
        )}
        {PermissionCheck("post-ad-with-url-campaign") &&
          (!filetype || filetype == "url") && (
            <Grid
              container
              sx={{ marginTop: "10px", paddingInline: "10px" }}
              className="link-div"
              alignItems="center"
              gap="20px"
            >
              <Grid item>
                <Typography className="Link-web-text ">
                  Link from Web
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <InputBase
                  placeholder="Example : http://www.youtube.com/wuewdgwygdyw"
                  className="input-link"
                  type="url"
                  value={videoLink}
                  onChange={(e) => {
                    setVideoLink(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <Button
                  onClick={(e) => {
                    checkUrlHandler(videoLink);
                    setFileType(dispatch, "url");
                    setFiletype("url");
                    props.data.handleCampaignData(videoLink, "contentname");
                    props.data.handleCampaignData("url", "contenttype");
                  }}
                  style={{ border: "1px solid #E4E6EF" }}
                  className="purple-gradient family-color-white"
                >
                  save
                </Button>
              </Grid>
            </Grid>
          )}

        {/*  */}
      </Grid>

      {/* <Grid container>
        {" "}
        <Typography className="formError mt5px" variant={"subtitle2"}>
          {formErrors.image}
        </Typography>{" "}
      </Grid> */}
    </>
  );
  // }
}
