import React from "react";
import { Typography, Grid, DialogContent, Breadcrumbs } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
// pagination
//core component that
import UnreadTab from "./NotificationTab/UnreadTab";
import ReadTab from "./NotificationTab/ReadTab";
import { Link } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
export default function Testing() {
  const [value, setValue] = React.useState(0);
  // States

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="width-90LR ">
        <div className="flex-class">
          <Grid container direction="row" justifyContent="start">
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{ padding: "10px 0px" }}
            >
              <Link
                to="/admin/home"
                className="linkcolor"
                underline="hover"
                key="1"
              >
                <Typography className="purplecolortypo fs16px">
                  Dashboard
                </Typography>
              </Link>
              ,
              <Link
                to="/"
                className="linkcolor"
                underline="hover"
                key="2"
                color="inherit"
                onClick={handleClick}
              >
                <Typography className="blackcolortypo fs16px">
                  Notifications
                </Typography>
              </Link>
              ,
            </Breadcrumbs>
          </Grid>
        </div>
        <Grid container>
          <Grid container className="device-container mt10px width100">
            <Grid item className="width100">
              <Tabs
                value={value}
                onChange={handleChange}
                centered
                className="TabStyleAddDevice "
              >
                <Tab label="Unread" className="TabChangesDevice" />
                <Tab label="Read" className="TabChangesDevice" />{" "}
              </Tabs>
            </Grid>
          </Grid>
          <DialogContent style={{ padding: "0px" }}>
            <TabPanel value={value} index={0} style={{ padding: "0px" }}>
              <UnreadTab />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ReadTab />
            </TabPanel>
          </DialogContent>
        </Grid>
      </div>
    </>
  );
}
