import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Input,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

export default function Testing(props) {
  const { inputRefs } = props.data;

  let BusinessType = [
    {
      businessname: "Proprietorship",
    },
    {
      businessname: "Partnership",
    },
    {
      businessname: "Company",
    },
    {
      businessname: "Others",
    },
  ];

  const [businessType, setBusinessType] = React.useState(null);

  const onChangeNumberField = (e, dataType) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (dataType == "mobileNum") {
        props.data.handleAgencyData(e.target.value, "mobilenumber");
      } else if (dataType == "adharCard") {
        props.data.handleAgencyData(e.target.value, "adharCard");
      } else if (dataType == "pinCode") {
        props.data.handleAgencyData(e.target.value, "pincode");
      }
    }
  };

  return (
    <>
      <div>
        <div className="admin-head">
          <Typography className="whitecolortypo500 fs16px">
            Agency Details
          </Typography>
        </div>
        <div className="admin-content">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography className="greycolor505050500 fs16px mt10px">
                Agency Name*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[0] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter First Name of Admin"
                value={props.data.agencyname}
                onChange={(e) => {
                  props.data.handleAgencyData(e.target.value, "agencyname");
                }}
              />
              {props.data.agencynameErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter the AgencyName here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Mobile Number*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[1] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter your Mobile Number"
                value={props.data.phoneNumber}
                inputProps={{ maxLength: 10 }}
                onChange={(e) => {
                  onChangeNumberField(e, "mobileNum");
                }}
              />
              {props.data.existPhoneNum && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"This num already exists"}
                </Typography>
              )}
              {props.data.phoneNumberErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter the 10 digit Mobile Number here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Email Address*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[2] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Example : xyz@loreum.com"
                value={props.data.email}
                onChange={(e) => {
                  props.data.handleAgencyData(e.target.value, "email");
                }}
              />{" "}
              {props?.data?.existEmail && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"This email already exists"}
                </Typography>
              )}
              {props.data.emailErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter the correct Email here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Telephone Number*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[3] = ref)}
                type="number"
                className="inputstyle "
                disableUnderline
                placeholder="Enter Telephone Number"
                value={props.data.telephonenumber}
                onChange={(e) => {
                  props.data.handleAgencyData(
                    e.target.value,
                    "telephonenumber"
                  );
                }}
              />
              {props.data.telephonenumberErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please Enter Your Telephone No"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Pincode*
              </Typography>
              <Input
                className="inputstyle "
                disableUnderline
                placeholder="Pincode"
                value={props.data.pincode ? props.data.pincode : ""}
                onChange={(e) => {
                  props.data.handleAgencyData(e.target.value, "pincode");
                }}
              />
              {(props.data.checkForPinCode || props.data.pincodeErr) && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter your 6 digit pincode here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Country
              </Typography>
              <Input
                className="inputstyle "
                disableUnderline
                placeholder="Country"
                value={"India"}
                disabled
              />
              <Typography className="greycolor505050500 fs16px mt10px">
                State
              </Typography>
              <Input
                className="inputstyle "
                disableUnderline
                placeholder="State"
                value={props?.data?.agencystate}
                disabled
              />
              <Typography className="greycolor505050500 fs16px mt10px">
                City
              </Typography>
              <Input
                className="inputstyle "
                disableUnderline
                placeholder="City"
                value={props?.data?.city}
                disabled
              />
              <Typography className="greycolor505050500 fs16px mt10px">
                Landmark*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[4] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="nearby landmark"
                value={props.data.landmark}
                onChange={(e) => {
                  props.data.handleAgencyData(e.target.value, "landmark");
                }}
              />
              {props.data.landmarkErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter your Landmark here"}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="greycolor505050500 fs16px mt10px">
                Contact Person Name*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[5] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter Your  Contact Person Name"
                value={props.data.contactperson}
                onChange={(e) => {
                  props.data.handleAgencyData(e.target.value, "contactperson");
                }}
              />
              {props.data.contactpersonErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter your Contact Person"}
                </Typography>
              )}{" "}
              <Typography className="greycolor505050500 fs16px mt10px">
                PAN Number*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[6] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter Your  PAN Number"
                value={props.data.pannumber}
                inputProps={{ maxLength: 10 }}
                onChange={(e) => {
                  props.data.handleAgencyData(e.target.value, "pannumber");
                }}
              />
              {props.data.pannumberErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter your 10 digit PAN Number"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Aadhar Card Number*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[7] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter Your  Aadhar Card Number"
                value={props.data.adharCard}
                inputProps={{ maxLength: 12 }}
                onChange={(e) => {
                  onChangeNumberField(e, "adharCard");
                }}
              />
              {props.data.adharCardErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter your 12 digit Aadhar Card Number"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                GST Number*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[8] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter your GST Number"
                value={props.data.gstNumber}
                inputProps={{ maxLength: 15 }}
                onChange={(e) => {
                  props.data.handleAgencyData(e.target.value, "gstNumber");
                }}
              />
              {props.data.gstNumberErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter the 15 digit GST Number here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Business Address*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[9] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter  your Address*"
                value={props.data.businessaddress}
                onChange={(e) => {
                  props.data.handleAgencyData(
                    e.target.value,
                    "businessaddress"
                  );
                }}
              />
              {props.data.businessaddressErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter the Address here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Website Link*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[10] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter Website Link "
                value={props.data.websitelink}
                onChange={(e) => {
                  props.data.handleAgencyData(e.target.value, "websitelink");
                }}
              />
              {props.data.websitelinkErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter the  Website Link here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Designation*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[11] = ref)}
                className="inputstyle "
                disableUnderline
                placeholder="Enter Designation City"
                value={props.data.designation}
                onChange={(e) => {
                  props.data.handleAgencyData(e.target.value, "designation");
                }}
              />
              {props.data.designationErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please enter the  Designation here"}
                </Typography>
              )}
              <Typography className="greycolor505050500 fs16px mt10px">
                Business Type*
              </Typography>
              <Grid container direction="row">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  className="radiostyle"
                >
                  <Grid container direction="row">
                    {BusinessType.map((data) => {
                      return (
                        <>
                          <FormControlLabel
                            className="radiostyle"
                            control={
                              <Radio
                                checked={
                                  props.data.businesstype.includes(
                                    data.businessname
                                  )
                                    ? true
                                    : false
                                }
                              />
                            }
                            label={data.businessname}
                            onClick={() => {
                              props.data.handleAgencyData(
                                data.businessname,
                                "businesstype"
                              );
                            }}
                          />
                        </>
                      );
                    })}
                  </Grid>
                </RadioGroup>
              </Grid>
              {props.data.agreementErr && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"Please Enable Business Type first"}
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
