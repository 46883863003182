import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import Beauty from "../../../../assets/Img/VenueImg/Beauty.png";
import Education from "../../../../assets/Img/VenueImg/Education.png";
import Fashion from "../../../../assets/Img/VenueImg/Fashion.png";
import Financial from "../../../../assets/Img/VenueImg/Financial.png";
import Fitness from "../../../../assets/Img/VenueImg/Fitness.png";
import Health from "../../../../assets/Img/VenueImg/Health.png";
import Hospitality from "../../../../assets/Img/VenueImg/Hospitality.png";
import Realty from "../../../../assets/Img/VenueImg/Realty.png";
import RealtyCommercial from "../../../../assets/Img/VenueImg/RealtyCommercial.png";
import Retail from "../../../../assets/Img/VenueImg/Retail.png";
import Roads from "../../../../assets/Img/VenueImg/Roads.png";
import Specialized from "../../../../assets/Img/VenueImg/Specialized.png";
import Radio from "@mui/material/Radio";
const venueTypeData = [
  { img: Beauty, label: "Beauty & Grooming" },
  { img: Education, label: "Education" },
  { img: Fashion, label: "Fashion" },
  { img: Financial, label: "Financial" },
  { img: Fitness, label: "Fitness" },
  { img: Health, label: "Health" },
  { img: Hospitality, label: "Hospitality" },
  { img: Realty, label: "Realty Residential" },
  { img: RealtyCommercial, label: "Realty Commercial" },
  { img: Retail, label: "Retail" },
  { img: Roads, label: "Roads & Highway" },
  { img: Specialized, label: "Specialized Retail" },
  // { img: Others, label: "Others" },
];
const venuTagsData = {
  "Beauty & Grooming": ["Salon", "Spa", "Skin treatment"],
  Education: ["School", "College", "Coaching", "centre"],
  Fashion: ["Boutique", "Designer", "store"],
  Financial: ["Bank", "Investment", "NBFC"],
  Fitness: ["Gym", "Sports", "centre", "Equipment"],
  Health: ["Hospital", "Clinic", "Pharmacy"],
  Hospitality: ["Café", "Food Kisosk", "Hotel", "Restaurant", "Nightclub"],
  "Realty Residential": ["Apartment", "Condominium"],
  "Realty Commercial": ["Office complex"],
  Retail: ["Shopping Mall", "SuperMarket"],
  "Roads & Highway": [
    "Bus Stand",
    "Eateries",
    "Petrol Station",
    "Charging Station",
    "Toilets",
    "Toll Plaza",
  ],
  "Specialized Retail": [
    "Alcohol",
    "Appliances",
    "Automobile",
    "Electronics",
    "Home Furnishing",
    "Sanitaryware",
  ],
};
export default function Testing({
  formdata,
  handleVenueType,
  handleTags,
  formErrors,
}) {
  const [selectVenueTag, setSelectVenueTag] = useState(formdata?.venueType);
  const [tagData, setTagData] = useState(formdata?.venueTags);
  useEffect(() => {
    handleTags(tagData);
  }, [tagData]);

  return (
    <>
      <Grid container direction="column" gap="25px">
        <div className="admin-head">
          <Typography className="whitecolortypo500 fs16px">
            Billboard Type
          </Typography>
        </div>
        <Typography
          className="secondary_blackSubheading_500 fs24px"
          align="center"
        >
          Select Venue type
        </Typography>
        <Grid container justifyContent="center" gap="20px">
          {venueTypeData?.map((data, i) => (
            <Grid
              item
              className="cursor_pointer border_primary "
              sx={{ padding: "10px 20px" }}
              onClick={() => {
                setSelectVenueTag(data.label);
                handleVenueType(data.label);
                setTagData([]);
              }}
            >
              <img src={data?.img} alt={data?.label} />
              <Grid container justifyContent="center">
                <Radio
                  checked={selectVenueTag === data.label}
                  // onChange={() => setTagData(data)}
                  value={selectVenueTag}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                  style={{ color: "#b937fa" }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Typography
          className="secondary_blackSubheading_500 fs24px"
          align="center"
        >
          Select Sub-Venue type
        </Typography>
        <Grid container justifyContent="center" gap="20px">
          {venuTagsData?.[selectVenueTag]?.map((data, i) => (
            <Grid
              item
              sx={{
                background: "#F7F8FD",
                borderRadius: "8px",
                padding: "0 20px",
              }}
            >
              <Radio
                checked={tagData === data}
                onChange={() => {
                  setTagData(data);
                }}
                value={tagData}
                name="radio-buttons"
                inputProps={{ "aria-label": "B" }}
                style={{ color: "#b937fa" }}
              />
              {data}
            </Grid>
          ))}
        </Grid>
        <Typography className="formError" variant={"subtitle2"}>
          {formErrors?.venueType}
        </Typography>
      </Grid>
    </>
  );
}
