import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
} from "@mui/material";
import RejectFinalDialog from "./RejectDialogFinal";
import { FETCH_URL } from "../../../../../../fetchIp";
export default function AlertDialog(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [otherReason, setOtherReason] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const staticData = [
    {
      Fname: "Sketchy Ad ",
      Lname: "Financial investing/Real Estate Investing/Any get rich scheme",
    },
    {
      Fname: "Prohibited Content",
      Lname: "Counterfiet Goods/Dangerous products/inappropriate content ",
    },
    {
      Fname: "NSFW Content",
      Lname: " Adult Content/Alcoho/Copyrights/Gambling ",
    },
    {
      Fname: "Video ad Requirements",
      Lname: " Wrong Video format/ Unclear content/ Copyright/ Video Quality ",
    },
    {
      Fname: "Other Reason",
      Lname: otherReason,
    },
  ];

  React.useEffect(() => {
    let index = selectedOptions.findIndex((element) => {
      return element.data1 == "Other Reason";
    });

    if (index != -1) {
      let a = { data1: "Other Reason", data2: otherReason };
      let arr = selectedOptions;
      arr.splice(index, 1, a);
      setSelectedOptions(arr);
    }
  }, [otherReason]);
  const controlOrderStatus = (value) => {
    setOpen(false);
  };
  const RejectedAPI = async () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    const response = await fetch(`${FETCH_URL}/api/order/approvedOrder`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        // videoDuration: props?.videoDuration,
        orderId: props?.orderId,
        status: "Rejected",
        reason: selectedOptions,
      }),
    });
    let res = await response.json();
    if (response.ok) {
      console.log("getRunningAd ==>", res.msg);
      props.approveFunc("The order has been Rejected", "success");
    } else {
      console.log("getRunningAd Error ==>", res);
    }
  };

  const handleOptions = (arg1, arg2) => {
    console.log(arg1, "arg1arg1");
    let arr;
    if (
      arg1 == "Other Reason" ||
      selectedOptions?.filter((a) => a?.data1 == "Other Reason")?.length > 0
    ) {
      console.log("hii");
      arr = [];
    } else {
      arr = [...selectedOptions];
    }

    console.log("handleOptions ===> ", arg1, arg2);
    let index = selectedOptions.findIndex((element) => {
      return element.data1 == arg1;
    });
    let a = { data1: arg1, data2: arg2 };
    if (index == -1) {
      arr.push(a);
    } else {
      arr.splice(index, 1);
    }
    setSelectedOptions(arr);
  };

  const handleClickOpen = () => {
    if (props.status == "Rejected") {
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedOptions([]);
    setErrMsg("");
    setOpen(false);
  };
  return (
    <div>
      <Button className="reject-btn width100 p15px" onClick={handleClickOpen}>
        Reject{" "}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography className="greycolor505050500">Reason :</Typography>
          <FormGroup className="mt10px">
            {staticData.map((data, i) => (
              <FormControlLabel
                key={i}
                className="mt15px"
                control={
                  <Checkbox
                    checked={
                      selectedOptions?.filter((a) => a?.data1 == data.Fname)
                        ?.length > 0
                        ? true
                        : false
                    }
                    className="iconcolor-red"
                    sx={{ marginTop: "-20px" }}
                    onClick={() => {
                      handleOptions(data.Fname, data.Lname);
                    }}
                  />
                }
                label={
                  <>
                    <Typography className="greycolor505050500 ">
                      {data.Fname}
                    </Typography>
                    <Typography className=" lightgreycolortypo fs12px">
                      {data.Fname == "Other Reason"
                        ? "Please write other reason down here."
                        : data.Lname}
                    </Typography>
                  </>
                }
              />
            ))}
          </FormGroup>
          <Grid
            item
            style={{ width: "85%", marginLeft: "auto", marginRight: "auto" }}
          >
            <TextareaAutosize
              className="textarea"
              aria-label="minimum height"
              minRows={2}
              disableUnderline
              value={otherReason}
              onChange={(e) => {
                // console.log("e terget Value =>", e.target.value)
                setOtherReason(e.target.value);
              }}
            />
          </Grid>
          {selectedOptions?.filter((a) => a.data1 == "Other Reason")?.length >
            0 &&
            otherReason?.trim("")?.length == 0 && (
              <Typography className="redcolortypo400 ">{errMsg}</Typography>
            )}
        </DialogContent>
        <DialogActions>
          <Button className="  diallog-no-btn" onClick={handleClose}>
            Cancel
          </Button>
          <RejectFinalDialog
            controlOrderStatus={controlOrderStatus}
            RejectedAPI={RejectedAPI}
            selectedOptions={selectedOptions}
            setErrMsg={setErrMsg}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
