import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  Breadcrumbs,
  ImageListItemBar,
  CardActions,
  Snackbar,
  Alert,
} from "@mui/material";
// checkbox
import Skeleton from "@mui/material/Skeleton";
import { BiRupee } from "react-icons/bi";
import { FETCH_URL } from "../../../fetchIp";
import Sidebar from "../../../component/Sidebar/Sidebar";
import BILLBOARD from "../../../assets/Img/BillBoardImg.png";
import noOrderImage from "../../../assets/Img/no_data.gif";
// checkbox
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const WishList = () => {
  const [wishListData, setWishListData] = useState([]);
  const [checked, setChecked] = React.useState(true);
  const [loading, setLoading] = useState(false);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const getWishListData = async () => {
    setLoading(true);
    let token = JSON.parse(localStorage.getItem("userData")).token;
    try {
      const response = await fetch(`${FETCH_URL}/api/user/getAllWishlist`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let res = await response.json();
      if (response.ok) {
        setLoading(false);
        console.log("wishList data  ===>", res);
        setWishListData(res.msg.billboardWishList);
        localStorage.setItem("wishList", res.msg);
      } else {
        setLoading(false);
        console.log("Error in wishList data ==> ", res.msg);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error in wishList data  => ", error.message);
    }
  };

  // remove from wishlist
  const removeFromWishList = async (billboardId) => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    console.log("remove to wishList");
    try {
      const response = await fetch(
        `${FETCH_URL}/api/user/removebillboardtowishlist`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            billboardId: billboardId,
          }),
        }
      );
      let res = await response.json();
      if (response.ok) {
        console.log("remove from wishList res  ===>", res);
        getWishListData();
        setSnackOpen(true);
        setSnackMsg(res.msg);
      } else {
        console.log("Error in removing wishlist==> ", res.msg);
        setSnackerropen(true);
        setSnackErrMsg(res.msg);
      }
    } catch (error) {
      console.log("Error in removing wishlist==>", error.message);
    }
  };

  useEffect(() => {
    getWishListData();
  }, []);
  return (
    <>
      {" "}
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={8000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>{" "}
      <div className="width-90LR ">
        <div>
          <Grid container direction="row" justifyContent="start">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                to="/admin/home"
                className="linkcolor"
                underline="hover"
                key="1"
              >
                <Typography className="purplecolortypo fs16px">
                  Dashboard{" "}
                </Typography>
              </Link>
              ,
              <Link
                to="/"
                className="linkcolor"
                underline="hover"
                key="2"
                color="inherit"
                href="/material-ui/getting-started/installation/"
              >
                <Typography className="blackcolortypo fs16px">
                  {" "}
                  Wishlist
                </Typography>
              </Link>
              ,
            </Breadcrumbs>
          </Grid>
        </div>
        <Typography
          className="blackcolortypo500 fs24px"
          sx={{ margin: "8px 0" }}
        >
          Wishlist
        </Typography>
        <Grid container className="billboard-card  pt20px mb70px " spacing={4}>
          {
            wishListData && wishListData.length !== 0 ? (
              <>
                {" "}
                {wishListData?.map((data, index) => (
                  <Grid
                    item
                    md={3}
                    sm={6}
                    lg={3}
                    key={index}
                    className="cursorpoint relative"
                  >
                    <Grid
                      item
                      key={data.billboardName}
                      className="box-shadow "
                      style={{
                        borderRadius: "8px",
                      }}
                    >
                      <Link
                        to={`/admin/billboardscreens/${data?._id}`}
                        className="linkcolor"
                        state={{ item: data }}
                      >
                        <ImageListItemBar
                          sx={{
                            background: "transparent",
                          }}
                          position="top"
                        />
                        <img
                          src={
                            data && data?.filesArr?.length > 0
                              ? data?.filesArr?.filter(
                                  (a) => a?.filetype == "image/jpeg"
                                )?.[0]?.fileurl
                              : BILLBOARD
                          }
                          alt="green iguana"
                          style={{
                            height: "150px",
                            width: "100%",
                          }}
                        />
                        <CardActions className="campaign-billboardaction">
                          <Grid
                            container
                            justifyContent="space-between"
                            direction="row"
                          >
                            <Grid item>
                              <Typography className="greycolor1c1c1c  fs18px">
                                {data?.billboardName}
                              </Typography>
                              <Typography className="greycolor505050500  fs12px ">
                                #{data?.billboardId}
                              </Typography>
                              <Typography className="greycolor505050500  fs14px">
                                {data?.city}
                              </Typography>{" "}
                              <Typography className="greycolor1c1c1c fs18px pt5px">
                                <BiRupee /> {data?.basePrice}
                              </Typography>
                            </Grid>
                          </Grid>{" "}
                        </CardActions>
                      </Link>
                    </Grid>
                    <Grid item className="billBoard_delete_btn">
                      <button
                        className="purplecolortypo-500 "
                        style={{
                          border: "none",
                          background: "none",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() => removeFromWishList(data._id)}
                      >
                        remove
                      </button>
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : loading === true ? (
              <>
                {" "}
                {[0, 1, 2, 3, 4, 5, 6, 7].map((a, i) => (
                  <Grid
                    key={i}
                    item
                    md={3}
                    sm={6}
                    lg={3}
                    className="cursorpoint relative"
                  >
                    <Grid
                      item
                      className="box-shadow "
                      style={{
                        borderRadius: "8px",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        sx={{ height: "150px" }}
                      />
                      <Grid
                        container
                        gap="5px"
                        direction="column"
                        sx={{ padding: "5px" }}
                      >
                        <Skeleton sx={{ width: "80%" }} />

                        <Skeleton sx={{ width: "40%" }} />

                        <Skeleton sx={{ width: "40%" }} />
                      </Grid>{" "}
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className="relative"
                style={{ height: "400px" }}
              >
                <Grid container item justifyContent="center">
                  <img
                    src={noOrderImage}
                    className="noTransScationImageWidth"
                    alt="no order found"
                  />
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  className="no_order_found_txt"
                >
                  {" "}
                  <Typography className="blackcolor525252_500 fs24px">
                    Your wishlist is empty, add billboards to see them here.
                  </Typography>
                </Grid>
              </Grid>
            )

            // <Grid
            //   container
            //   justifyContent="center"
            //   alignItems="center"
            //   style={{ height: "500px" }}
            // >
            //   <Typography className="blackcolortypo500 fs24px">

            //   </Typography>
            // </Grid>
          }
        </Grid>
      </div>
    </>
  );
};

export default WishList;
