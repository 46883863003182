import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  InputBase,
  Button,
  IconButton,
  DialogTitle,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
//core Component
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import orderIcon from "../../../../../assets/Img/orderCampaignBanner.png";
import moment from "moment";
import axiosInstance from "../../../../../api/axiosInstance";
//Icons
// pagination
import Pagination from "@mui/material/Pagination";
/////////
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FilterTab from "./FilterTab/FilterTab";
import noOrderImage from "../../../../../assets/Img/no_data_found_img.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Skeleton from "@mui/material/Skeleton";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
//======================================= OrdersTab =========================================//

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "#ffffff",
  "&:hover": {
    backgroundColor: "#ffffff",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0.7, 0.7, 0.7, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      "&:focus": {
        width: "90%",
      },
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function CampaignTab(props) {
  // extracting
  const { paginatedPage, paginateTabValue, getPaginatedValueHandler } =
    props.paginateState;
  const navigate = useNavigate();
  const [allOrder, setAllOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    paginatedPage ? paginatedPage : 1
  );
  const [totalPage, setTotalPage] = useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [type, setType] = React.useState("All");
  const [timeSlot, setTimeSlot] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [length, setLength] = React.useState("");
  const [search, setSearch] = useState(null);
  // helper func
  const buttonColor = (status) => {
    if (status === "Approved") {
      return "order-green-btn";
    }
    if (status === "Pending") {
      return "order-orange-btn ";
    }
    if (status === "Live") {
      return "order-white-btn ";
    }
    if (status === "Rejected") {
      return "red-colorbtn ";
    }
    if (status === "Scheduled") {
      return "order-light-purple-btn";
    }
    if (status === "Published") {
      return "order-purple-btn ";
    }
    if (status === "Expired") {
      return "order-Expired-btn  ";
    }
    if (status === "Cancelled") {
      return "order-Cancelled-btn  ";
    }
  };
  const handleFilterData = (data, datatype) => {
    if (datatype == "startDate") {
      setStartDate(moment(data).format("YYYY-MM-DD"));
    } else if (datatype == "endDate") {
      setEndDate(moment(data).format("YYYY-MM-DD"));
    } else if (datatype == "type") {
      setType(data);
    } else if (datatype == "timeSlot") {
      setTimeSlot(data);
    }
  };
  const handleChangeByPage = (event, value) => {
    getPaginatedValueHandler("", value, paginateTabValue);
    setCurrentPage(value);
  };
  // clearFilter
  const clearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setType("All");
    setTimeSlot(null);
  };
  // Api calling
  const getBillboardOrder = async () => {
    setLoading(true);
    try {
      let body = {
        startDate: startDate,
        endDate: endDate,
        status: type,
        startHours: timeSlot,
        search: search == "" ? null : search,
        limit: 10,

        page: currentPage,
        billboardId: props?.id,
        isCampaign: true,
      };
      console.log("Body before getting order => ", body);
      let resp = await axiosInstance.post(
        `/api/order/getOrdersByBillboardId`,
        body
      );
      console.log("response from getting order =>", resp.data.data);
      if (resp.status == 200 || resp.status == 204) {
        setLoading(false);
        setAllOrder(resp?.data?.data);
        setTotalPage(Math.ceil(resp?.data?.length / 10));
        setLength(Math.ceil(resp?.data?.length));
      }
    } catch (err) {
      setLoading(false);
      console.log("error from getOrder => ", err);
    }
  };
  useEffect(() => {
    if (paginatedPage < 1) {
      setCurrentPage(1);
      return;
    }
    setCurrentPage(paginatedPage ? paginatedPage : 1);
  }, [paginatedPage]);
  useEffect(() => {
    if (props?.id) {
      getBillboardOrder();
    }
  }, [startDate, endDate, type, timeSlot, search, currentPage, props?.id]);
  return (
    <>
      <Grid
        container
        md={12}
        sm={12}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
        alignItems="center"
        gap="40px"
      >
        <Grid item>
          <Typography className="greycolor505050500">
            Showing {allOrder?.length} out of {length} Campaign
          </Typography>
        </Grid>
        <Grid item>
          <Grid container gap="10px">
            {" "}
            <Grid item>
              <Search className="search-admin">
                <SearchIconWrapper>
                  <SearchIcon className="searchicon" />
                </SearchIconWrapper>
                <StyledInputBase
                  style={{ padding: "4px 4px 4px 4px" }}
                  placeholder="Search Order"
                  inputProps={{ "aria-label": "search" }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Search>
            </Grid>
            <Grid item className="fiter-heading-right">
              <FilterTab
                handleFilterData={handleFilterData}
                type={type}
                startDate={startDate}
                endDate={endDate}
                timeSlot={timeSlot}
                clearFilter={clearFilter}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="pt-9px" spacing={3}>
        {allOrder && allOrder.length > 0 ? (
          allOrder.map((a, i) => (
            <Grid item md={6} sm={12} className="mt20px" key={i}>
              <Grid
                container
                className="box-shadow bg-white relative"
                sx={{ border: "1px solid #ddd", borderRadius: "8px" }}
              >
                <Grid item md={3} sm={3}>
                  <Grid item className="relative">
                    <Grid item className="orderbtn-itemL">
                      <Button
                        // className={"order-orange-btn width100 mt--10px "}
                        className={`${buttonColor(
                          a.status
                        )}  width100 mt--10px`}
                      >
                        {a.status}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ height: "100%" }}>
                    <img
                      src={orderIcon}
                      alt="orderIcon"
                      className="width100"
                      style={{ height: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  md={9}
                  sm={9}
                  className="p10px"
                  direction="column"
                  justifyContent="space-between"
                >
                  <Grid item className="relative">
                    <Grid item className="orderbtn-itemR">
                      {/* <Link
                              to="/admin/orderdetails"
                              className="linkcolorwhite"
                              state={a}
                            > */}
                      <Button
                        className="order-purple-btn   width-20  min-width-120px "
                        sx={{ fontWeight: "500 !important" }}
                        onClick={() =>
                          navigate(`/admin/campaignmgt/${a?._id}`, {
                            state: { a },
                          })
                        }
                      >
                        View
                      </Button>{" "}
                      {/* </Link> */}
                    </Grid>
                  </Grid>
                  <Typography className="greycolor505050500 mt25px">
                    Order id : #{a.orderId}
                  </Typography>
                  <Typography
                    className="blackcolortypo mt10px"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {a?.adTitle?.length > 40
                      ? `${a?.adTitle?.substring(0, 40)}...`
                      : a?.adTitle}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="mt10px"
                  >
                    <Grid item>
                      <Typography className="greycolor505050500">
                        {a?.startscheduleDate && a?.startscheduleDate}
                        {/* 05th April 2022 */}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="greycolor505050500">
                        {a?.multiTimes?.length}
                        {""} Timeslots
                      </Typography>
                    </Grid>{" "}
                    <Grid item>
                      <Typography className="greycolor505050500">
                        Duration : {a?.videoDuration && a?.videoDuration}
                        {/* Duration: 30 sec */}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="order_views">
                  <Grid
                    container
                    alignItems="center"
                    gap="10px"
                    className="order_view_div"
                  >
                    <VisibilityIcon className="purple-icon" />
                    <Typography className="purplecolortypo-500 fs14px">
                      {a?.totalViews}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : loading ? (
          <>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((a, i) => (
              <Grid item md={6} sm={12} className="mt20px">
                <Grid
                  key={i}
                  container
                  className="box-shadow bg-white relative"
                  sx={{ border: "1px solid #ddd", borderRadius: "8px" }}
                >
                  <Grid item md={3} sm={3}>
                    <Grid item className="relative">
                      <Grid
                        item
                        className="orderbtn-itemL"
                        sx={{
                          marginTop: "-20px",
                          borderRadius: "16px",
                        }}
                      >
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            width: "100%",
                            fontSize: "2rem",
                            borderRadius: "16px",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item sx={{ height: "100%" }}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ width: "100%", height: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    md={9}
                    sm={9}
                    className="p10px"
                    direction="column"
                    justifyContent="space-between"
                  >
                    <Grid item className="relative">
                      <Grid item className="orderbtn-itemR">
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "70px" }}
                        />
                      </Grid>
                    </Grid>
                    <Typography className="greycolor505050400 mt25px">
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "70%" }}
                      />
                    </Typography>
                    <Typography className="blackcolortypo mt10px">
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "70%" }}
                      />
                    </Typography>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className="mt10px"
                    >
                      <Grid item>
                        <Typography className="greycolor505050500">
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "50px" }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="greycolor505050500">
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "50px" }}
                          />
                        </Typography>
                      </Grid>{" "}
                      <Grid item>
                        <Typography className="greycolor505050500">
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "50px" }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="order_views">
                    <Grid container alignItems="center" gap="10px">
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1.2rem", width: "50px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="relative"
              style={{ height: "500px" }}
            >
              <Grid container item justifyContent="center" alignItems="center">
                <img
                  src={noOrderImage}
                  alt="no order found"
                  className="noBillboardFoundGif_width"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {allOrder.length > 0 && (
        <Grid
          justifyContent="center"
          alignItems="center"
          sx={{ display: "flex", marginTop: "20px" }}
        >
          <Stack spacing={2}>
            <Pagination
              page={currentPage}
              count={totalPage}
              onChange={handleChangeByPage}
              size="large"
            />
          </Stack>
        </Grid>
      )}
    </>
  );
}
