export default async function displayRazorpay(
  amount,
  walletId,
  handleClose,
  MR
) {
  // let MR = v4();
  //   let userID = JSON.parse(localStorage.getItem("userData")).user._id;
  const datas = await fetch(
    // `https://admin.payomatix.com/payment/test/merchant/transaction`,
    " https://admin.payomatix.com/payment/merchant/transaction",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "PAY25NV59MAG73LDAEKWD1689144602.GCPN96SECKEY",
      },
      body: JSON.stringify({
        email: "yashi.srivastava@psiborg.in",
        amount: amount,
        currency: "INR",
        first_name: JSON.parse(localStorage.getItem("userData")).user.firstName,
        last_name: JSON.parse(localStorage.getItem("userData")).user.lastName,
        // phone_no: localStorage.getItem("userPhone"),
        // address: localStorage.getItem("userAddress"),
        // state: localStorage.getItem("userState"),
        // city: localStorage.getItem("userCity"),
        // zip: localStorage.getItem("userPincode"),
        country: "IN",

        // return_url: 'http://localhost:3000/selected-billboard-detail',
        return_url: "https://www.google.com/",
        notify_url: "https://api.postmyad.ai/api/payment/webhookPayment",
        merchant_ref: MR,
      }),
    }
  );
  return await datas.json();

  let check;
}
