import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Step,
  Stepper,
  Box,
  StepLabel,
  Typography,
  Grid,
  Snackbar,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
//React Icons
import { IoChevronBack } from "react-icons/io5";
//core components that
import Sidebar from "../../../../component/Sidebar/Sidebar";
import AdminDetails from "./AgencyPages/Agency-Details";
import Access from "./AgencyPages/Access";
import { FETCH_URL } from "../../../../fetchIp";
import SuccessDialog from "../../../../utils/Dialog/SuceedFullDialog";
import WrongDialog from "../../../../utils/Dialog/WrongDialog";
import axios from "../../../../api/axiosInstance";
import LoaderDialogue from "../../../../component/LoaderDialogue/LoaderDialogue";
import axiosInstance from "../../../../api/axiosInstance";
//--------------------------- custom hook -------------------------------------//
import useInputScrollRef from "../../../../utils/useInputScrollRef";
//--------------------------- custom hook -------------------------------------//
const steps = ["Agency Details", "Access"];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function AdAgency() {
  const params = useParams();
  const newURL = `/admin/manage-user?page=1&tabValue=${params.value}`;

  const [checkForPinCode, setCheckForPinCode] = useState(false);
  // Scrolls to the first empty input field and stops the loop
  const { addButtonClicked, inputRefs } = useInputScrollRef();
  const [activeStep, setActiveStep] = React.useState(0);
  //================= For Agency-Detials =======================================//
  const [agencyname, setAgencyName] = useState("");
  const [agencynameErr, setAgencyNameErr] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErr, setPhoneNumberErr] = useState(false);
  const [agencystate, setAgencyState] = useState("");
  const [agencystateErr, setAgencyStateErr] = useState(false);
  const [businessaddress, setBusinessAddress] = useState("");
  const [businessaddressErr, setBusinessAddressErr] = useState(false);
  const [websitelink, setWebsiteLink] = useState("");
  const [websitelinkErr, setWebsiteLinkErr] = useState(false);
  const [designation, setDesignation] = useState("");
  const [designationErr, setDesignationErr] = useState(null);
  const [gstNumber, setGSTNumber] = useState("");
  const [gstNumberErr, setGSTNumberErr] = useState(null);
  const [businesstype, setBusinessType] = useState([]);
  const [businesstypeErr, setBusinessTypeErr] = useState(null);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(null);
  const [telephonenumber, setTelephoneNumber] = useState("");
  const [telephonenumberErr, setTelephoneNumberErr] = useState(null);
  const [landmark, setLandMark] = useState("");
  const [landmarkErr, setLandmakErr] = useState(false);
  const [pincode, setPincode] = useState("");
  const [pincodeErr, setPincodeErr] = useState("");
  const [contactperson, setContactPerson] = useState("");
  const [contactpersonErr, setContactPersonErr] = useState("");
  const [pannumber, setPANNumber] = useState("");
  const [pannumberErr, setPANNumberErr] = useState("");
  const [adharCard, setAdharCard] = useState("");
  const [adharCardErr, setAdharCardErr] = useState(false);
  const [adharCardFile, setAdharCardFile] = useState("");
  const [country, setCountry] = useState("");
  const [countryErr, setCountryErr] = useState(false);
  const [city, setCity] = useState("");
  const [cityErr, setCityErr] = useState(false);
  /// Agency-Detials End///
  const [adminCity, setAdminCity] = useState("");
  const [permissions, setPermissions] = useState([]); //===> For access permissions array
  const [agreement, setAgreement] = useState(false);
  // loading for loader
  const [loading, setLoading] = useState(false);

  const [checkedItems, setCheckedItems] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("success"); // or 'error', 'warning', etc.

  const [existPhoneNum, setExistPhoneNum] = useState(false);
  const [mobileNumerCheck, setMobileNumCheck] = useState(true);
  const [existEmail, setExistEmail] = useState(false);
  const [emailCheck, setEmailCheck] = useState(true);

  //========================== For Access ===============================//

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const showSnackbar = () => {
    setSnackbarOpen(true);
    setSeverity("error"); // or 'error', 'warning', etc.
  };
  //================================ handling Cluster data ===========================//
  const handleAgencyData = (value, datatype) => {
    if (datatype == "permissionArr") {
      console.log("permission Arr Val ==> ", value);
      setPermissions([...value]);
    } else if (datatype == "mobilenumber") {
      setPhoneNumber(value);
    } else if (datatype == "agencyname") {
      setAgencyName(value);
    } else if (datatype == "agencystate") {
      // setAgencyState(value);
    } else if (datatype == "businessaddress") {
      setBusinessAddress(value);
    } else if (datatype == "websitelink") {
      setWebsiteLink(value);
    } else if (datatype == "designation") {
      setDesignation(value);
    } else if (datatype == "gstNumber") {
      setGSTNumber(value);
    } else if (datatype == "businesstype") {
      // console.log("IF INclude => ",businesstype.includes(value))

      if (businesstype.includes(value)) {
        console.log("pop");
        // businesstype.pop();
        setBusinessType([]);
      } else {
        console.log("Push", value);
        setBusinessType(value);
      }
    } else if (datatype == "email") {
      setEmail(value);
    } else if (datatype == "telephonenumber") {
      setTelephoneNumber(value);
    } else if (datatype == "landmark") {
      setLandMark(value);
    } else if (datatype == "country") {
      console.log("==== Inside Country dataType ====", value);
      setCountry(value);
    } else if (datatype == "city") {
      setCity(value);
    } else if (datatype == "pincode") {
      setPincode(value);
    } else if (datatype == "contactperson") {
      setContactPerson(value);
    } else if (datatype == "pannumber") {
      setPANNumber(value);
    } else if (datatype == "adharCard") {
      setAdharCard(value);
    }
  };

  const handleNext = () => {
    if (activeStep == 0) {
      addButtonClicked();
      if (!agencyname) {
        setAgencyNameErr(true);
      }
      if (!phoneNumber || String(phoneNumber)?.length !== 10) {
        setPhoneNumberErr(true);
      }

      if (!businessaddress) {
        setBusinessAddressErr(true);
      }
      if (!designation) {
        setDesignationErr(true);
      }
      if (!businesstype) {
        setBusinessTypeErr(true);
      }
      if (!email || !email.includes("@")) {
        setEmailErr(true);
      }

      if (!landmark) {
        setLandmakErr(true);
      }
      if (!pincode || String(pincode)?.trim().length !== 6) {
        setPincodeErr(true);
      }
      if (!contactperson) {
        setContactPersonErr(true);
      }
      if (existPhoneNum || existEmail) {
        return;
      }
      if (
        agencyname &&
        phoneNumber &&
        String(phoneNumber)?.length === 10 &&
        agencystate &&
        businessaddress &&
        !checkForPinCode &&
        designation &&
        businesstype &&
        email &&
        email.includes("@") &&
        landmark &&
        pincode &&
        String(pincode)?.length === 6 &&
        contactperson
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep == 1) {
      if (permissions.length > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep == 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [openDialogName, setOpenDialog] = React.useState(null);

  const checkHandler = (e, item) => {
    setCheckedItems((prevCheckedItems) => {
      const isChecked = prevCheckedItems.includes(item.key);
      if (isChecked) {
        return prevCheckedItems.filter(
          (checkedItem) => checkedItem !== item.key
        );
      } else {
        return [...prevCheckedItems, item.key];
      }
    });
  };
  //================================== Submit API ==================================//
  const handleSubmitdata = async () => {
    if (
      agencyname &&
      phoneNumber &&
      agencystate &&
      businessaddress &&
      designation &&
      businesstype &&
      email &&
      landmark &&
      pincode &&
      contactperson
    ) {
      let body = {
        name: agencyname,
        mobileNumber: `91${phoneNumber}`,
        telephoneNumber: telephonenumber,
        address: businessaddress,
        landmark: landmark,
        country: country,
        state: agencystate,
        city: city,
        pincode: pincode,
        websiteLink: websitelink,
        contactPerson: contactperson,
        designation: designation,
        panNumber: pannumber,
        gstNumber: gstNumber,
        aadharCard: adharCard,
        BusinessType: businesstype,
        email: email,
        allowedPermissions: checkedItems,
      };
      if (checkedItems.length === 0) {
        showSnackbar();
        return;
      }
      try {
        setLoading(true);
        let resp = await axios.post("/api/user/createAdAgency", body);
        // console.log("response ==>", resp.data);
        // let resp = {
        //   status: 201
        // }

        if (resp.status === 201) {
          setOpenDialog("success");
          setLoading(false);
        } else {
          setOpenDialog("reject");
          setLoading(false);
        }
      } catch (error) {
        console.log("error from creating AdAgency User", error);
        setOpenDialog("reject");
        setLoading(false);
      }
    } else {
      alert("Please Fill All the Required Details First");
      setLoading(false);
    }
  };

  ///=========================== Adding Permisions to permissions ===========================//
  const handlePermissions = (data, selecttype) => {
    console.log("handlePermission Value ==> ", data);
    if (data && selecttype == "singleSelect") {
      let arr = permissions;
      let index = arr.findIndex(
        (element) => element.accessname == data.accessname
      );
      console.log("Found index ====> ", index);

      if (index == -1) {
        console.log("//==== Selected Pincode doesn't exist ===//");
        arr.push(data);
        handleAgencyData(arr, "permissionArr");
      } else if (index != -1) {
        console.log("//==== yes Pincode exist =====// ");
        arr.splice(index, 1);
        handleAgencyData(arr, "permissionArr");
      }
    }
  };
  // mobile num and email already exist api
  useEffect(() => {
    if (mobileNumerCheck) {
      setMobileNumCheck(false);
      return;
    }
    let getData = null;
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `/api/user/checkEmailandMobileNumber?mobileNumber=91${phoneNumber}`
        );
        if (response?.status == 200) {
          setExistPhoneNum(true);
        } else {
          setExistPhoneNum(false);
        }

        console.log(response, "i am response");
      } catch (error) {
        console.error(error);
        setExistPhoneNum(false);
      }
    }

    getData = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => {
      if (getData) {
        clearTimeout(getData);
      }
    };
  }, [phoneNumber]);
  useEffect(() => {
    if (emailCheck) {
      setEmailCheck(false);
      return;
    }
    let getData = null;
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `/api/user/checkEmailandMobileNumber?email=${email}`
        );
        if (response?.status == 200) {
          setExistEmail(true);
        } else {
          setExistEmail(false);
        }

        console.log(response, "i am response");
      } catch (error) {
        console.error(error);
        setExistEmail(false);
      }
    }

    getData = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => {
      if (getData) {
        clearTimeout(getData);
      }
    };
  }, [email]);
  const searchByPostalCode = async (pincode) => {
    setPincode(pincode);
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`
    );
    const res = await response.json();
    if (response.ok) {
      const Postaldata = res;
      if (res[0].PostOffice === null || res[0].PostOffice === undefined) {
        setAdminCity("");
        setAgencyState("");
        setCheckForPinCode(true);
        return;
      }
      setCheckForPinCode(false);
      setCity(Postaldata?.map((a) => a?.PostOffice[0]?.District)[0]);
      setAgencyState(Postaldata?.map((a) => a?.PostOffice[0].State)[0]);
      setCountry("India");
    }
  };
  useEffect(() => {
    if (agencyname) {
      setAgencyNameErr(false);
    }
    if (phoneNumber) {
      setPhoneNumberErr(false);
    }
    if (agencystate) {
    }
    if (businessaddress) {
      setBusinessAddressErr(false);
    }
    if (websitelink) {
      setWebsiteLinkErr(false);
    }
    if (designation) {
      setDesignationErr(false);
    }
    if (gstNumber) {
      setGSTNumberErr(false);
    }
    if (businesstype) {
      setBusinessTypeErr(false);
    }
    if (email) {
      setEmailErr(false);
    }
    if (telephonenumber) {
      setTelephoneNumberErr(false);
    }
    if (landmark) {
      setLandmakErr(false);
    }
    if (pincode) {
      setPincodeErr(false);
    }

    if (contactperson) {
      setContactPersonErr(false);
    }
    if (pannumber) {
      setPANNumberErr(false);
    }
    if (adharCard) {
      setAdharCardErr(false);
    }

    if (country) {
      setCountryErr(false);
    }

    if (city) {
      setCityErr(false);
    }
  }, [
    agencyname,
    phoneNumber,
    agencystate,
    businessaddress,
    websitelink,
    designation,
    gstNumber,
    businesstype,
    email,
    telephonenumber,
    landmark,
    pincode,
    contactperson,
    pannumber,
    country,
    city,
    adharCard,
  ]);
  React.useEffect(() => {
    if (String(pincode).length === 6) {
      searchByPostalCode(pincode);
    } else {
    }
  }, [pincode]);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={severity}
          onClose={handleSnackbarClose}
        >
          Please select at least one option
        </Alert>
      </Snackbar>
      <Box className="width100">
        <div className="flex-class">
          <div className="width-20">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="cursor pt14px">
                <Link to={newURL} className="link-style">
                  <IoChevronBack className="back-arrowcolor" />
                </Link>
              </Typography>
              <Link to={newURL} className="link-style">
                <Typography className="cursor pt7px greycolortypo6F6F6F fs16px">
                  Create Ad-Agency
                </Typography>{" "}
              </Link>
            </Grid>
          </div>
          <div className="width-80">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className=" stepper "
            >
              {steps.map((data, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={data} {...stepProps}>
                    <StepLabel className="step" {...labelProps}>
                      {data}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
        <div className="stepmain-div ">
          {activeStep === 0 && (
            <AdminDetails
              data={{
                inputRefs,
                email,
                landmark,
                pincode,
                adminCity,
                phoneNumber,
                adharCard,
                agreement,
                adharCardFile,
                businessaddress,
                websitelink,
                designation,
                gstNumber,
                businesstype,
                telephonenumber,
                pannumber,
                contactperson,
                agencystate,
                agencyname,
                handleAgencyData,
                emailErr,
                agencynameErr,
                agencystateErr,
                landmarkErr,
                businessaddressErr,
                websitelinkErr,
                phoneNumberErr,
                adharCardErr,
                pincodeErr,
                designationErr,
                businesstypeErr,
                telephonenumberErr,
                contactpersonErr,
                pannumberErr,
                gstNumberErr,
                // ============ //
                country,
                city,
                countryErr,
                cityErr,

                existPhoneNum,
                existEmail,
                searchByPostalCode,
                checkForPinCode,
              }}
            />
          )}
          {activeStep === 1 && (
            <Access checkedItems={checkedItems} checkHandler={checkHandler} />
          )}
          {activeStep === 2 && null}
          <React.Fragment>
            <Box className="stepmain-box ">
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                className="mt10px p-tb20-lr10"
              >
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 4 }}
                  className={activeStep ? "previousbtn " : null}
                >
                  Previous
                </Button>
                {activeStep === steps.length - 1 ? null : (
                  <Button
                    onClick={handleNext}
                    className={
                      activeStep === steps.length - 1 ? null : "purplebtn"
                    }
                  >
                    Next
                  </Button>
                )}
                {activeStep === 1 ? (
                  <Button className="purplebtn" onClick={handleSubmitdata}>
                    Submit
                    <SuccessDialog
                      openData={openDialogName === "success"}
                      data={"AdAgency Added Successfully"}
                      navigateTo={newURL}
                    />
                    <Typography className="fs16px">
                      <WrongDialog
                        openData={openDialogName === "reject"}
                        data={"Error: Something went wrong !"}
                      />
                    </Typography>
                  </Button>
                ) : null}
              </Grid>
            </Box>
          </React.Fragment>
        </div>
      </Box>
      <LoaderDialogue loading={loading} />
    </>
  );
}
