import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { Bar } from "react-chartjs-2";
import { Button, Grid, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import Calendar from "../../../../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
import Calendar from "../../../../../../../../component/ReactDateRangePicker/ReactDateRangePickerFormonth";
import axiosInstance from "../../../../../../../../api/axiosInstance";
import { OccupencyContext } from "../../../../../../../../Context/OccupencyContext";
import BillboardAnalyticsTable from "./BillboardAnalyticsTable";

// temp
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    xAxes: [{ stacked: true }],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 24,
        },
        stacked: false,
      },
    ],
  },
};

const AnalyticsTab = (props) => {
  const [dateType, setDateType] = useState("Orders");
  // const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [activeTime, setactiveTime] = useState(0);
  const [date, setDate] = useState();
  const [billboardActiveHour, setbillboardActiveHour] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [acceptedBarAnalyticsData, setAcceptedBarAnalyticsData] = useState([]);
  const [datasets, setDataSets] = useState([]);
  const [AllActiveData, setAllActiveData] = useState(0);
  const handleData = (data, datatype) => {
    setDate(moment(data).format("YYYY-MM-DD"));
  };
  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };
  //
  const billBoardID = props.billBoardID;

  console.log("propsLatestBarGraph", billBoardID);

  // {
  //   label: "Published",
  //   data: [1, 2, 0, 7, 3, 4, 9],
  //   borderColor: "rgb(215,35,205,100%)",
  //   backgroundColor: "rgba(215,35,205,100%)",
  //   stack: "Stack 1",
  //   borderRadius: 20,

  //   barThickness: 24,
  // },

  const timeInHour = [
    "12:00 Am",
    "1:00 Am",
    "2:00 Am",
    "3:00 Am",
    "4:00 Am",
    "5:00 Am",
    "6:00 Am",
    "7:00 Am",
    "8:00 Am",
    "9:00 Am",
    "10:00 Am",
    "11:00 Am",
    "12:00 Pm",
    "1:00 Pm",
    "2:00 Pm",
    "3:00 Pm",
    "4:00 Pm",
    "5:00 Pm",
    "6:00 Pm",
    "7:00 Pm",
    "8:00 Pm",
    "9:00 Pm",
    "10:00 Pm",
    "11:00 Pm",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

   const data = {
     labels:
       analyticsDate?.length == 24
         ? timeInHour
         : analyticsDate?.length == 12
         ? months
         : analyticsDate,
     datasets: datasets,
  };
  
  const getBillboardActiveHours = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getBillboardActiveTime?billboardId=${billBoardID}&startDate=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}&dateWise=false`
      );
      setAcceptedBarAnalyticsData(res?.data.data);
      console.log("dataOccupency", res);
      if (res.status == 200 || res.status == 204) {
        setbillboardActiveHour(res?.data.data);

        setactiveTime(acceptedBarAnalyticsData);
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };

  console.log("ApidataForBar", activeTime);
  useEffect(() => {
    if (!date) {
      return;
    }
    getBillboardActiveHours();
  }, [date]);

  let acceptedData = [];

  console.log("barAccepteddata", acceptedData);

  useEffect(() => {
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = acceptedBarAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          videoDuration: acceptedBarAnalyticsData[index].videoDuration,
        };
        acceptedData.push(obj);
      } else {
        let obj = {
          _id: analyticsDate[i],
          videoDuration: 0,
        };
        acceptedData.push(obj);
      }
    }
  }, [acceptedBarAnalyticsData]);

  useEffect(() => {
    setDataSets([
      {
        label: "Active(min)",
        data: acceptedData.map((a, i) => Math.floor(a?.videoDuration / 60)),
        borderColor: "#fed0eeb3",
        backgroundColor: "#fed0eeb3",
        stack: "Stack 1",
        borderRadius: 20,
        barThickness: 24,
      },
    ]);
  }, [acceptedBarAnalyticsData]);

  useEffect(() => {
    const firstData = acceptedBarAnalyticsData?.map((a) => a.videoDuration);
    const firstDataNew = firstData?.reduce((accum, elem) => {
      return (accum = accum + elem);
    }, 0);

    setAllActiveData(firstDataNew);
  }, [acceptedBarAnalyticsData]);

  return (
    <>
      <Grid container sx={{ height: "100%" }}>
        <Grid item xs={12}>
          <div>
            {" "}
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              {" "}
              <Grid item>
                <Grid container gap="10px" direction="column">
                  {" "}
                  <Typography className="gray5A5A5Atypo500 fs20px">
                    Date Vise Occupancy Data
                  </Typography>
                  <Typography className="gray5A5A5Atypo500LineHeight fs16px">
                    Total Active Time :{Math.floor(AllActiveData / 60)} (min)
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Calendar getDataFromChildHandler={getDataFromChildHandler} />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Bar options={options} data={data} />
      </Grid>
    </>
  );
};

export default AnalyticsTab;
