import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AnalyticsOverview from "./AnalyticsTab";
import AnalyticsTabPanel from "./BAnalyticsTabPanel/AnalyticsTabPanel";
import SlotsAnalytics from "./BAnalyticsTabPanel/SlotsAnalytics/SlotsAnalytics";
import OccupancyAnalytics from "./BAnalyticsTabPanel/OccupancyAnalytics/OccupancyAnalytics";
import AdvertiseViseTable from "./BAnalyticsTabPanel/AdvertiseviseTable";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AnalyticsTab({
  id,
  billBoardData,

  getBillBoardData,
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // getProfile
  let userID = JSON.parse(localStorage.getItem("userData")).user._id;

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className="orderTab"
          aria-label="basic tabs example"
        >
          <Tab
            label="Overview"
            {...a11yProps(0)}
            className="Order_TabChangeDevice"
          />
          <Tab
            label="Slots Analytics"
            {...a11yProps(1)}
            className="Order_TabChangeDevice"
          />
          <Tab
            label="Occupancy "
            {...a11yProps(2)}
            className="Order_TabChangeDevice"
          />
          <Tab
            label="Advertiser Ranking "
            {...a11yProps(3)}
            className="Order_TabChangeDevice"
          />
        </Tabs>
      </Box>
      <div style={{ marginTop: "24px" }}>
        <TabPanel value={value} index={0} className="superAdmin_profile_tab">
          <AnalyticsTabPanel id={id} billBoardData={billBoardData} />
        </TabPanel>
        <TabPanel value={value} index={1} className="superAdmin_profile_tab">
          <SlotsAnalytics id={id} />
        </TabPanel>
        <TabPanel value={value} index={2} className="superAdmin_profile_tab">
          <OccupancyAnalytics id={id} />
        </TabPanel>
        <TabPanel value={value} index={3} className="superAdmin_profile_tab">
          <AdvertiseViseTable id={id} />
        </TabPanel>
      </div>
    </>
  );
}
