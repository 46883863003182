import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  Input,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import "react-image-upload/dist/index.css";
import axios from "../../../../../api/axiosInstance";
import { MdAddCircleOutline } from "react-icons/md";
import { HiOutlineMinusCircle } from "react-icons/hi";
import SuccessDialog from "../../../../../utils/Dialog/SuceedFullDialog";
import Autocomplete from "@mui/material/Autocomplete";
import axiosInstance from "../../../../../api/axiosInstance";
import LoaderDialog from "../../../../../component/LoaderDialogue/LoaderDialogue";
import permissionUtils from "../../../../../component/permissionUtils";

export default function Testing({ id, deviceId }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const [openDialogName, setOpenDialog] = React.useState(null);
  const [priceData, setPriceData] = React.useState({
    billboardId: id,
    deviceId: deviceId,
    timeslotType: "",
    basePrice: null,
    totalViews: 0,
    avgViews: 0,
    type: "normal",
    timesArray: [],
  });
  console.log(
    "Check priceData--------------------------------------",
    priceData?.timesArray
  );
  const currentDate = new Date();
  const handlepriceData = (name, value) => {
    setPriceData({ ...priceData, [name]: value });
  };
  const [minsarr, setMinsarr] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);

  const [startTime, setStartTime] = useState(1);
  const [endTime, setEndTime] = useState("AM");
  const [timeslot, setTimeSlot] = useState(false);
  const [loading, setLoading] = useState(false);

  // states for timeslots added By Rahul
  let testcreateArr;
  const [dataToMap, setDataToMap] = React.useState(null);
  const [selectedSlotType, setSelectedSlotType] = React.useState(10);

  const [basePrice, setBasePrice] = React.useState(null);
  const [showStartTime, setShowStartTime] = React.useState(false);
  const [customTimeSlot, setCustomTimeSlot] = React.useState(false);
  const [billBoardDurationData, setBillboardDurationData] = useState([]);
  const [selectedDurations, setSelectedDurations] = useState(
    billBoardDurationData ?? [30]
  );
  const [isEditing, setIsEditing] = useState(false);
  const [triggerApiCall, setTriggerApiCall] = React.useState(false);
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleApplyClick = () => {
    TimeSlotApply();
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false); // Hide buttons on cancel
  };

  useEffect(() => {
    setSelectedDurations(billBoardDurationData ?? [30]);
  }, [billBoardDurationData]);
  // const [DurationArr, setDurationArr] = useState([
  //   30, 60, 90, 120, 150, 180, 210, 240, 270, 300,
  // ]);
  const DurationArr = Array.from(
    { length: (3600 - 30) / 30 + 1 },
    (_, index) => (index + 1) * 30
  );

  // Handle changes in the Autocomplete component
  const handleAutocompleteChange = (event, newValue) => {
    setSelectedDurations(newValue);
  };

  const [count, setCount] = useState(1);
  // ======== get Billboard Price Data API ======== //
  const getBillboardPriceData = async () => {
    let resp = await axios.get(`/api/timeslot/getBillBoardPrice/${id}`);

    if (resp?.data?.msg && resp?.data?.msg?.timeslotType) {
      if (resp?.data?.msg?.timeslotType === "custom") {
        setSelectedSlotType(10);
      } else {
        setSelectedSlotType(Number(resp?.data?.msg?.timeslotType.slice(0, 2)));
      }
      setBasePrice(resp?.data?.msg?.basePrice);
      setBillboardDurationData(resp?.data?.msg?.billboardId?.durations);
      setPriceData({
        ...priceData,
        timeslotType: resp?.data?.msg.timeslotType,
        basePrice: resp?.data?.msg.basePrice,
        totalViews: Number(resp?.data?.msg.totalViews),
        avgViews: Number(resp?.data?.msg.avgViews),
        // timesArray : resp?.data?.msg.timeslots
      });
      if (resp?.data?.msg.timeslotType === "24 Hours") {
        setDataToMap(resp?.data?.msg.timeslots);
      } else {
        setDataToMap(resp?.data?.msg.timeslots);
      }
      setTimeSlot(true);
    }
  };
  // //////////////////////// Duration api post ///////////////

  const postDuration = async () => {
    try {
      let body = {
        billboardId: id,
        durations: selectedDurations,
      };
      let res = await axiosInstance.post(`api/billboard/updateDuration`, body);
      console.log(res, "resres");
      if (res?.status === 200 || res?.status === 201) {
        console.log(res, "resres");
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error in editing==>", error);
    }
  };
  const UpdateTimeSlot = async () => {
    let body = {
      billboardId: id,
      timeSlotType: priceData.timeslotType,
      basePrice: priceData.basePrice,
      timesArray: dataToMap,
      date: moment(currentDate).format("YYYY-MM-DD"),
    };
    setLoading(true);

    try {
      let update = await axios.post("/api/timeslot/updateTimeSlotPrice", body);
      if (update.status === 200) {
        getBillboardPriceData();
        setOpenDialog("success");
        setLoading(false);
      } else {
        setOpenDialog("reject");
      }
    } catch (error) {
      setOpenDialog("reject");
      setLoading(false);

      console.log("Price update Error ===> ", error.message);
    }

    // console.log("Body from updating timeSlot ==> ", body);
  };

  React.useEffect(() => {
    if (id) {
      getBillboardPriceData();
    }
  }, [id]);

  // Functions for timeslots added By Rahul

  const TimeSlotApply = () => {
    if (selectedSlotType === 12) {
      setCustomTimeSlot(false);
      testcreateArr = Array(12)
        .fill()
        .map((_, i) => {
          return {
            time: i + startTime,
            multiplier: 1,
          };
        });

      // console.log("testcreateArr from onClick apply ===>", testcreateArr);
      setDataToMap(testcreateArr);
      // handlepriceData("timesArray", testcreateArr)
    }

    if (selectedSlotType === 24) {
      setShowStartTime(false);
      setCustomTimeSlot(false);
      testcreateArr = Array(24)
        .fill()
        .map((_, i) => {
          return {
            time: i,
            multiplier: 1,
          };
        });
      setDataToMap(testcreateArr);
    }

    if (selectedSlotType === 10) {
      setShowStartTime(false);
      setCustomTimeSlot(true);
      testcreateArr = Array(count)
        .fill()
        .map((_, i) => {
          return {
            time: i + 1,
            multiplier: 1,
          };
        });
      setDataToMap(testcreateArr);
    }
    setTimeSlot(true);
    setTriggerApiCall(true);
  };

  const handleChange = (event) => {
    if (event.target.value === 10) {
      // this will work when custom is selected in Time Slot Type*
      setShowStartTime(false);
      setTimeSlot(false);
      handlepriceData("timeslotType", "custom");
    }

    if (event.target.value === 12) {
      // this will work when 12 Hour is selected in Time Slot Type*
      setShowStartTime(true);
      setTimeSlot(false);
      setCustomTimeSlot(false);
      handlepriceData("timeslotType", "12 Hours");
    }

    if (event.target.value === 24) {
      // this will work when 24 Hour is selected in Time Slot Type*
      setShowStartTime(false);
      setTimeSlot(false);
      setCustomTimeSlot(false);
      handlepriceData("timeslotType", "24 Hours");
    }
    setSelectedSlotType(event.target.value);
  };

  const handleBasePriceChange = (e) => {
    setBasePrice(e.target.value < 0 ? (e.target.value = 0) : e.target.value);
    handlepriceData(
      "basePrice",
      e.target.value < 0 ? (e.target.value = 0) : e.target.value
    );
  };

  const handleAddChange = (value) => {
    if (dataToMap?.length == 24) {
      return;
    }
    setCount(value);

    // Check if dataToMap contains all digits from 1-12
    const containsAllDigits = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].every(
      (digit) => dataToMap?.map((a) => a.time).includes(digit)
    );

    if (containsAllDigits) {
      const dataValue = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].find(
        (x) => !dataToMap?.map((a) => a.time).includes(x)
      );
      // Set data for PM time
      setDataToMap([
        ...dataToMap,
        {
          time: dataValue == 24 ? 0 : dataValue,
          multiplier: 1,
          finalprice: 1 * basePrice,
          amPm: dataValue == 24 ? "AM" : "PM",
        },
      ]);
    } else {
      // Set data for AM time
      const dataValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].find(
        (x) => !dataToMap?.map((a) => a.time).includes(x)
      );
      setDataToMap([
        ...dataToMap,
        {
          time: dataValue,
          multiplier: 1,
          finalprice: 1 * basePrice,
          amPm: dataValue == 12 ? "PM" : "AM",
        },
      ]);
    }
    setTriggerApiCall(true);
  };
  React.useEffect(() => {
    if (triggerApiCall) {
      // Perform API call here
      UpdateTimeSlot();
      postDuration();

      // Reset the trigger after the API call
      setTriggerApiCall(false);
    }
  }, [triggerApiCall]);

  const handleRemoveChange = (value, time, item) => {
    if (value == 0) {
      return;
    }
    setCount(value);
    // console.log("time need to be deleted ==> ", time);
    setDataToMap((current) =>
      current.filter((obj) => {
        if (obj.time !== time) {
          return obj;
        }
      })
    );
  };
  React.useEffect(() => {
    handlepriceData("timesArray", dataToMap);
  }, [dataToMap]);

  // Multiplier component added By Rahul

  const MultiplierComponent = ({ index, multiValue, viewsValue }) => {
    const handleMultiplierChange = (e) => {
      if (e.target.name === "views") {
        setDataToMap((current) =>
          current.map((obj) => {
            if (obj.time === index) {
              return {
                ...obj,
                ["views"]: Number(e.target.value),
                finalprice: multiValue * basePrice,
                multiplier: multiValue,
              };
            }
            return obj;
          })
        );
      }
      if (e.target.name === "multiplier") {
        setDataToMap((current) =>
          current.map((obj) => {
            if (obj.time === index) {
              return {
                ...obj,
                finalprice: e.target.value * basePrice,
                multiplier: e.target.value,
              };
            }
            return obj;
          })
        );
      }
    };

    return (
      <>
        <Grid item xs={2.5} md={2.6} lg={2} justifyContent="flex-end">
          <FormControl className="MainPageFormControl mt10px " size="small">
            <TextField
              select
              variant="filled"
              InputProps={{ disableUnderline: true }}
              className="Selectdropstyle"
              labelId="demo-select-small"
              id="demo-select-small"
              name="multiplier"
              disabled={!isEditing}
              defaultValue={1.0}
              value={multiValue ? multiValue : 1.0}
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleMultiplierChange}
            >
              <MenuItem value={0.8}>0.8</MenuItem>
              <MenuItem value={1.0}>1.0</MenuItem>
              <MenuItem value={1.25}>1.25</MenuItem>
              <MenuItem value={1.5}>1.5</MenuItem>
              <MenuItem value={1.75}>1.75</MenuItem>
              <MenuItem value={2.0}>2.0</MenuItem>
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={2.5} md={2.6} lg={2} justifyContent="flex-end">
          <Input
            className="inputstyle"
            disableUnderline
            disabled={!isEditing}
            placeholder="Enter final price"
            inputProps={{ style: { textAlign: "center" } }}
            value={multiValue ? multiValue * basePrice : basePrice}
          />
        </Grid>
      </>
    );
  };

  // custom Component for time Slot Type Custom
  const CustomComponent = ({ item, index }) => {
    const handleCustomTimeChange = (e) => {
      if (e) {
        if (e.target.name === "startTime") {
          let obj = {
            finalprice: item.multiplier * basePrice,
            multiplier: item.multiplier,
            time: e.target.value,
            amPm: item.amPm,
          };
          let indexFind = dataToMap?.findIndex(
            (a) => a.time == obj.time && a.amPm == obj.amPm
          );
          if (indexFind != -1) {
            return;
          }
        }
        if (e.target.value === "PM") {
          let obj = {
            finalprice: item.multiplier * basePrice,
            multiplier: item.multiplier,
            time: 12 + item.time,
            amPm: "PM",
          };
          let indexFind = dataToMap?.findIndex(
            (a) => a.time == obj.time && a.amPm == obj.amPm
          );

          if (indexFind != -1) {
            return;
          }
        }
        if (e.target.value === "AM") {
          let obj = {
            finalprice: item.multiplier * basePrice,
            multiplier: item.multiplier,
            time: Number(item.time) - 12,
            amPm: "AM",
          };
          let indexFind = dataToMap?.findIndex(
            (a) => a.time == obj.time && a.amPm == obj.amPm
          );

          if (indexFind != -1) {
            return;
          }
        }
      }

      if (e.target.name === "startTime") {
        console.log("iam inside setDataToMap", item.amPm);
        setDataToMap((current) =>
          current.map((obj, i) => {
            if (i === index) {
              return {
                ...obj,
                finalprice: item.multiplier * basePrice,
                multiplier: item.multiplier,
                time:
                  item.amPm == "PM"
                    ? Number(e.target.value) + 12
                    : e.target.value,
                amPm: item.amPm,
              };
            }
            return obj;
          })
        );
      }

      if (e.target.name === "startTimeAMPM" && e.target.value === "PM") {
        // console.log("startTimeAMPM PM target.value ==> ", e.target.value);
        setDataToMap((current) =>
          current.map((obj, i) => {
            if (i === index) {
              return {
                ...obj,
                finalprice: item.multiplier * basePrice,
                multiplier: item.multiplier,
                time: 12 + item.time,
                amPm: "PM",
              };
            }
            return obj;
          })
        );
      }

      if (e.target.name === "startTimeAMPM" && e.target.value === "AM") {
        console.log("startTimeAMPM  AM target.value ==> ", e.target.value);
        setDataToMap((current) =>
          current.map((obj, i) => {
            if (i === index) {
              return {
                ...obj,
                finalprice: Math.floor(item.multiplier * basePrice),
                multiplier: item.multiplier,
                time: item.time - 12,
                amPm: "AM",
              };
            }
            return obj;
          })
        );
      }
    };

    return (
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={1.8} sm={3} md={2.8} lg={2} xl={1.8}>
            <Grid
              container
              direction={isMd ? "column" : "row"}
              className=" inputstyle"
              justifyContent="space-between"
            >
              <Grid item xs={5.8}>
                <FormControl className="MainPageFormControl   " size="small">
                  <TextField
                    select
                    variant="filled"
                    disabled={!isEditing}
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle-noborder"
                    labelId="demo-select-small"
                    id="demo-select-small"
                    name="startTime"
                    inputProps={{ "aria-label": "Without label" }}
                    value={
                      item.time === 0
                        ? 12
                        : item.time > 12
                        ? item.time - 12
                        : item.time
                    }
                    onChange={(e) => {
                      handleCustomTimeChange(e);
                    }}
                  >
                    {minsarr && minsarr.length > 0 ? (
                      minsarr.map((x, i) => (
                        <MenuItem
                          key={i}
                          value={x}
                          disabled={
                            dataToMap[index]?.amPm == "AM" &&
                            dataToMap.map((a) => a.time)?.includes(x)
                              ? true
                              : dataToMap[index]?.amPm == "PM" &&
                                dataToMap?.map((a) => a.time)?.includes(x + 12)
                              ? true
                              : false
                          }
                        >
                          {x}
                        </MenuItem>
                      ))
                    ) : (
                      <h2>No Data</h2>
                    )}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={5.8}>
                <Typography className="greycolor505050500 fs16px">
                  <FormControl className="MainPageFormControl  " size="small">
                    <TextField
                      select
                      variant="filled"
                      disabled={!isEditing}
                      InputProps={{ disableUnderline: true }}
                      className="Selectdropstyle-noborder"
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="startTimeAMPM"
                      inputProps={{ "aria-label": "Without label" }}
                      value={item.time >= 12 ? "PM" : "AM"}
                      onChange={(e) => {
                        handleCustomTimeChange(e);
                      }}
                    >
                      <MenuItem value={"AM"}>AM</MenuItem>
                      <MenuItem value={"PM"}>PM</MenuItem>
                    </TextField>
                  </FormControl>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} md={1.8}>
            <Input
              className="inputstyle"
              disableUnderline
              disabled={!isEditing}
              value={
                item.time == 11
                  ? "12 PM"
                  : item.time < 12
                  ? `${item.time + 1} AM`
                  : item.time === 12
                  ? `${1} PM`
                  : item.time === 23
                  ? "12 AM"
                  : `${item.time + 1 - 12} PM`
              }
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Grid>

          <Grid item xs={1.8}>
            <Input
              className="inputstyle"
              disableUnderline
              value={basePrice}
              disabled={!isEditing}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Grid>

          <MultiplierComponent
            viewsValue={item.views}
            multiValue={item.multiplier}
            index={item.time}
          />
          <Grid item xs={6} sm={5} md={4} lg={1.8} justifyContent="flex-end">
            <Grid container item gap="10px">
              <Grid item>
                <Typography align="center">
                  <button
                    style={{
                      padding: "0px",
                      border: "none",
                      background: "white",
                    }}
                    onClick={() =>
                      handleRemoveChange(count - 1, item.time, item)
                    }
                  >
                    <HiOutlineMinusCircle
                      className="applypurplebtn_without_width "
                      style={{ color: "red" }}
                    />
                  </button>{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="center">
                  <button
                    style={{
                      padding: "0px",
                      border: "none",
                      background: "white",
                    }}
                    onClick={() => handleAddChange(count + 1)}
                  >
                    <MdAddCircleOutline className="applypurplebtn_without_width " />
                  </button>{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  ///////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className="box-shadow ">
        <div className="admin-head">
          <Typography className="whitecolortypo500 fs16px">
            Time Slot Type*
          </Typography>
        </div>
        <div className="admin-content">
          <Grid container spacing={2}>
            <Grid item xs={4} md={2.4}>
              <Typography className="greycolor505050500 fs16px">
                Time Slot Type*
              </Typography>
              <FormControl className="MainPageFormControl mt10px " size="small">
                <TextField
                  select
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  className="Selectdropstyle"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={selectedSlotType}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleChange}
                  disabled={!isEditing}
                >
                  <MenuItem value={24} className="Selectmenustyle">
                    24 Hours
                  </MenuItem>
                  <MenuItem value={12} className="Selectmenustyle">
                    12 Hours
                  </MenuItem>
                  <MenuItem value={10} className="Selectmenustyle">
                    Custom
                  </MenuItem>
                </TextField>
              </FormControl>
            </Grid>

            {/* if 12 Hours is selected then show the startTime */}

            {showStartTime ? (
              <Grid item xs={4} md={2.4}>
                <Typography className="greycolor505050500 fs16px">
                  Start Time*
                </Typography>
                <Grid container className="mt10px inputstyle">
                  <Grid
                    item
                    xs={6}
                    style={{ borderRight: "1px solid #dddddd" }}
                  >
                    <FormControl
                      className="MainPageFormControl   "
                      size="small"
                    >
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle-noborder"
                        labelId="demo-select-small"
                        id="demo-select-small"
                        inputProps={{ "aria-label": "Without label" }}
                        value={startTime}
                        disabled={!isEditing}
                        onChange={(e) => {
                          // console.log("startTime ==> ", e.target.value)
                          setStartTime(e.target.value);
                        }}
                      >
                        {minsarr && minsarr.length > 0 ? (
                          minsarr.map((x, i) => (
                            <MenuItem key={i} value={x}>
                              {x}
                            </MenuItem>
                          ))
                        ) : (
                          <h2>No Data</h2>
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="greycolor505050500 fs16px">
                      <FormControl
                        className="MainPageFormControl  "
                        size="small"
                      >
                        <TextField
                          select
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                          className="Selectdropstyle-noborder"
                          labelId="demo-select-small"
                          id="demo-select-small"
                          name="startTimeAMPM"
                          inputProps={{ "aria-label": "Without label" }}
                          value={endTime}
                          disabled={!isEditing}
                          onChange={(e) => setEndTime(e.target.value)}
                        >
                          <MenuItem value={"AM"}>AM</MenuItem>
                          <MenuItem value={"PM"}>PM</MenuItem>
                        </TextField>
                      </FormControl>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={4} md={2.4}>
              <Typography className="greycolor505050500 fs16px">
                Base Price*
              </Typography>
              <Input
                className="inputstyle"
                name="basePrice"
                disableUnderline
                placeholder="Enter Base Price"
                value={basePrice}
                disabled={!isEditing}
                onChange={handleBasePriceChange}
              />
            </Grid>
            <Grid item xs={4} md={3} className="AutoComplete">
              <Typography className="greycolor505050500 fs16px">
                Duration*
              </Typography>
              <Autocomplete
                multiple
                InputProps={{ disableUnderline: true }}
                className=" mt10px autocompleteCss"
                size="small"
                options={DurationArr}
                disabled={!isEditing}
                value={selectedDurations}
                onChange={handleAutocompleteChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>

            <Grid
              item
              xs={4}
              md={3}
              className="mt10px"
              justifyContent="flex-end"
              alignSelf="flex-end"
            >
              <Grid container>
                {PermissionCheck("edit-billboard-pricing") && !isEditing && (
                  <Typography align="center" style={{ width: "52%" }}>
                    <Button
                      className="applypurplebtn"
                      onClick={handleEditClick}
                    >
                      Edit
                    </Button>
                  </Typography>
                )}
                {isEditing && (
                  <Grid container>
                    <Button
                      sx={{
                        border: "1px solid grey",
                        ml: 2,
                        color: "grey",
                        fontWeight: "600",
                      }}
                      className="dcancelbutton"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        border: "1px solid purple",
                        ml: 2,
                        color: "#b937fa",
                        fontWeight: "600",
                      }}
                      onClick={handleApplyClick}
                    >
                      Apply
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* =================  TimeSlot Mapping After Apply Button =============== */}
      {timeslot ? (
        <div className="box-shadow">
          <div className="admin-content">
            <Grid container spacing={2}>
              <Grid container item>
                <Grid
                  item
                  xs={customTimeSlot ? 1.8 : 2.2}
                  md={customTimeSlot ? 2.8 : 2}
                  lg={customTimeSlot ? 2 : 1}
                >
                  <Typography
                    align="center"
                    className="greycolor505050500 fs16px"
                  >
                    From
                  </Typography>
                </Grid>

                <Grid item xs={2.2} md={customTimeSlot ? 1.8 : 2} lg={1}>
                  <Typography
                    align="center"
                    className="greycolor505050500 fs16px"
                  >
                    To
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={customTimeSlot ? 1.8 : 2.5}
                  md={customTimeSlot ? 2.8 : 2.6}
                  lg={customTimeSlot ? 2 : 2}
                >
                  <Typography
                    align="center"
                    className="greycolor505050500 fs16px"
                  >
                    Base Price
                  </Typography>
                </Grid>

                <Grid item xs={2.5} md={2.6} lg={2} justifyContent="flex-end">
                  <Typography
                    align="center"
                    className="greycolor505050500 fs16px"
                  >
                    Multiplier
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={2.5}
                  md={customTimeSlot ? 2.8 : 2.6}
                  lg={customTimeSlot ? 2 : 2}
                  justifyContent="flex-end"
                >
                  <Typography
                    align="center"
                    className="greycolor505050500 fs16px"
                  >
                    Final Price
                  </Typography>
                </Grid>
                {/* {isEditing && (
                  <Grid item xs={2.5} md={2.6} lg={2}>
                    {PermissionCheck("edit-billboard-pricing") && (
                      <Button
                        className="applypurplebtn"
                        onClick={() => {
                          UpdateTimeSlot();
                          postDuration();
                        }}
                      >
                        Update
                      </Button>
                    )}
                  </Grid>
                )} */}
              </Grid>
            </Grid>
            <div
              style={{
                maxHeight: "60vh",
                overflowY: "scroll",
                marginTop: "10px",
                cursor: "all-scroll",
              }}
            >
              {/* ===== TimeSlot Mapping For Custom Time SLot Type After Appy Button ===== */}
              {customTimeSlot ? (
                <>
                  {dataToMap?.map((item, index) => {
                    return <CustomComponent item={item} index={index} />;
                  })}
                </>
              ) : (
                <>
                  {dataToMap?.map((item, index) => {
                    return (
                      <Grid container spacing={2}>
                        <Grid item xs={2.2} md={2} lg={1}>
                          {item.time < 12 ? (
                            <Input
                              className="inputstyle"
                              disableUnderline
                              value={
                                item.time === 0 ? "12 AM" : `${item.time}  AM`
                              }
                              // endTime === "AM" ? "AM" : "PM"
                            />
                          ) : (
                            <Input
                              className="inputstyle"
                              disableUnderline
                              value={
                                item.time === 12
                                  ? `${item.time} PM`
                                  : `${item.time - 12} PM`
                              }
                            />
                          )}
                        </Grid>
                        <Grid item xs={2.2} md={2} lg={1}>
                          {item.time < 12 ? (
                            <Input
                              className="inputstyle"
                              disableUnderline
                              value={
                                item.time === 1
                                  ? "2 AM "
                                  : item.time + 1 === 12
                                  ? `${item.time + 1} PM`
                                  : `${item.time + 1} AM`
                              }
                            />
                          ) : (
                            <Input
                              className="inputstyle"
                              disableUnderline
                              value={`${item.time + 1 - 12} PM `}
                            />
                          )}
                        </Grid>
                        <Grid item xs={2.5} md={2.6} lg={2}>
                          <Input
                            className="inputstyle"
                            disableUnderline
                            value={basePrice}
                            inputProps={{ style: { textAlign: "center" } }}
                          />
                        </Grid>
                        {/* <Grid item xs={2.5} md={2.6} lg={2}>
                          <Input
                            className="inputstyle"
                            disableUnderline
                            value={basePrice}
                            inputProps={{ style: { textAlign: "center" } }}
                          />
                        </Grid> */}
                        <MultiplierComponent
                          viewsValue={item.views}
                          multiValue={
                            item.multiplier
                              ? item.multiplier
                              : item.finalprice / basePrice
                          }
                          index={item.time}
                        />
                      </Grid>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <Grid item xs={8}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <LoaderDialog loading={loading} />
        </Grid>{" "}
      </Grid>
      <SuccessDialog
        openData={openDialogName === "success"}
        data={"Price Updated"}
        parentNavigate={true}
      />
    </>
  );
}
