import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useMediaQuery, useTheme, Box } from "@mui/material";

// ** Core Component
import RankingTab from "./BillboardTabPanel/RankingTab";
import TabList from "../../../component/CustomTabs/TabList";
import OverviewTab from "./BillboardTabPanel/OverviewTabMain";
import permissionUtils from "../../../component/permissionUtils";
import TabPanelList from "./../../../component/CustomTabs/TabPanel";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";
import AllBillboardAnalyticsTab from "./BillboardTabPanel/AllBillboardAnalyticsTab";

export default function BillBoardTab() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const permissionList = [
    "overview",
    "view-overall-billboard-analytics",
    "view-overall-billboard-ranking",
  ];

  const breadcrumbs = [
    { label: "Dashboard", link: "/" },
    { label: "All Smart BillBoard" },
  ];

  const getTabLabel = (permission) => {
    const labelMap = {
      overview: "Overview",
      "view-overall-billboard-analytics": "Analytics",
      "view-overall-billboard-ranking": "Ranking",
    };
    return labelMap[permission];
  };
  const permissions = CheckTabsPermissions(permissionList);
  const panels = [
    {
      permission: "overview",
      component: <OverviewTab />,
    },
    {
      permission: "view-overall-billboard-analytics",
      component: <AllBillboardAnalyticsTab />,
    },
    {
      permission: "view-overall-billboard-ranking",
      component: <RankingTab />,
    },
  ];
  return (
    <div className="width-90LR">
      <div className="flex-class">
        <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
      </div>
      <Box sx={{ borderBottom: 1, mb: 4, borderColor: "divider" }}>
        <TabList
          md={md}
          value={value}
          centered={false}
          classes={{ Tabs: "orderTab", Tab: "Order_TabChangeDevice" }}
          handleChange={handleChange}
          getTabLabel={getTabLabel}
          permissions={permissions}
        />
      </Box>
      <TabPanelList value={value} permissions={permissions} panels={panels} />{" "}
    </div>
  );
}
