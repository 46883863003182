import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Grid, Typography, TextField, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import axiosInstance from "../../../../../../../api/axiosInstance";
// import Calendar from "../../../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
import moment from "moment";
import useExportExcel from "../../../../../../../utils/useExportExcelFile";
import Calendar from "../../../../../../../component/ReactDateRangePicker/ReactDateRangePickerFormonth";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    xAxes: [{ stacked: true }],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        stacked: false,
      },
    ],
  },
};

const AnalyticsTab = (props) => {
  const [orderCountData, setOrderCountData] = useState([]);
  // analytics states
  const [revenue, setRevenueAnalytics] = useState([]);
  const [viewOrderAnalytics, setViewOrderAnalytics] = useState([]);
  const [date, setDate] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [datasets, setDataSets] = useState([]);
  const [orderType, setOrderType] = useState("All");
  const [graphType, setgraphType] = useState("maleViews");
  const [countName, setcountName] = useState("Orders");
  const [fileName, setfileName] = React.useState("OrderAnalytics");
  const [dataForTableOrder, setdataForTableOrder] = useState(null);
  const [acceptedOrderCount, setAcceptedOrderCount] = useState(0);
  const [dataForTablePlayHour, setdataForTablePlayHour] = useState(null);
  const [dataForExcel, setdataForExcel] = useState([]);
  const [acceptedOrderAnalyticsData, setAcceptedOrderAnalyticsData] = useState(
    []
  );
  const [dataForTableMaleViews, setdataForTableMaleViews] = useState(null);
  const [dataForTableRevenue, setdataForTableRevenue] = useState(null);
  const [femaleAnalystics, setfemaleAnalystics] = useState([]);
  const [rejectedOrderAnalyticsData, setRejectedOrderAnalyticsData] = useState(
    []
  );
  const [publishedOrderAnalyticsData, setPublishedOrderAnalyticsData] =
    useState([]);

  const { exportToExcel } = useExportExcel();
  // analytics states
  // analytics date and datasets
  const timeInHour = [
    "12:00 Am",
    "1:00 Am",
    "2:00 Am",
    "3:00 Am",
    "4:00 Am",
    "5:00 Am",
    "6:00 Am",
    "7:00 Am",
    "8:00 Am",
    "9:00 Am",
    "10:00 Am",
    "11:00 Am",
    "12:00 Pm",
    "1:00 Pm",
    "2:00 Pm",
    "3:00 Pm",
    "4:00 Pm",
    "5:00 Pm",
    "6:00 Pm",
    "7:00 Pm",
    "8:00 Pm",
    "9:00 Pm",
    "10:00 Pm",
    "11:00 Pm",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels:
      analyticsDate?.length == 24
        ? timeInHour
        : analyticsDate?.length == 12
        ? months
        : analyticsDate,
    datasets: datasets,
  };

  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };
  // analytics date and datasets
  // get analytics api
  const orderCount = async () => {
    try {
      let res = await axiosInstance.get(
        `/api/order/getordercountforBillboard/${props?.id}`
      );
      console.log(res, "orderCount response");
      if (res.status == 200 || res.status == 201) {
        setOrderCountData(res?.data);
      }
    } catch (error) {
      console.log("error from GET orderCount", error.message);
    }
  };

  const getAnalyticsData = async () => {
    try {
      let res = await axiosInstance.get(
        `/api/order/getSingleOrderAnalyticsForAdmin?isCampaign=false&startDate=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}&billboardId=${props?.id}`
      );

      console.log("response from getting order analytics data =>", res);
      if (res.status == 200 || res.status == 201) {
        //  setAcceptedOrderAnalyticsData(res?.data.data);
        if (orderType === "All") {
          setAcceptedOrderAnalyticsData(res?.data.data);
        }
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };
  const getViewfemale = async () => {
    try {
      let res = await axiosInstance.get(
        `/api/billboard/getSingleBillboardAnalytics?isCampaign=false&graphType=${graphType}&startDate=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}&billboardId=${props?.id}`
      );

      console.log("response from getting order analytics data =>", res);
      if (res.status == 200 || res.status == 201) {
        setfemaleAnalystics(res?.data?.data);
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };
  // get analytics api

  // making an empty array and pushing all data in (data with 0 count)
  let acceptedData = [];
  let rejectedData = [];
  let publishedData = [];
  let viewsData = [];
  let revenueData = [];
  let femaleViewsData = [];
  let playHour = [];
  let avg = 0;
  let avgM = 0;
  let avgA = 0;
  let avgExpire = 0;
  let avgapprove = 0;
  let avgcancel = 0;
  let avgpending = 0;
  let avgpublish = 0;
  let avgreject = 0;

  useEffect(() => {
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = acceptedOrderAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          approveOrder: acceptedOrderAnalyticsData[index].approveOrder,
          cancelOrder: acceptedOrderAnalyticsData[index].cancelOrder,
          expireOrder: acceptedOrderAnalyticsData[index].expireOrder,

          pendingOrder: acceptedOrderAnalyticsData[index].pendingOrder,
          publishOrder: acceptedOrderAnalyticsData[index].publishOrder,
          rejectOrder: acceptedOrderAnalyticsData[index].rejectOrder,
        };
        avgExpire += acceptedOrderAnalyticsData[index].expireOrder;
        avgapprove += acceptedOrderAnalyticsData[index].approveOrder;
        avgcancel += acceptedOrderAnalyticsData[index].cancelOrder;
        avgpending += acceptedOrderAnalyticsData[index].pendingOrder;
        avgpublish += acceptedOrderAnalyticsData[index].publishOrder;
        avgreject += acceptedOrderAnalyticsData[index].rejectOrder;

        acceptedData.push(obj);
      } else {
        let obj = {
          Date: analyticsDate[i],

          approveOrder: 0,
          cancelOrder: 0,
          expireOrder: 0,
          pendingOrder: 0,
          publishOrder: 0,
          rejectOrder: 0,
        };
        acceptedData.push(obj);
      }
      // setdataForTableOrder(acceptedData);
      if (i === analyticsDate.length - 1) {
        setdataForTableOrder([
          ...acceptedData,

          {
            Date: "Total",
            approveOrder: avgapprove,
            cancelOrder: avgcancel,
            expireOrder: avgExpire,
            pendingOrder: avgpending,
            publishOrder: avgpublish,
            rejectOrder: avgreject,
          },
          {
            Date: "Average",

            approveOrder: parseFloat(avgapprove / analyticsDate.length).toFixed(
              2
            ),
            cancelOrder: parseFloat(avgcancel / analyticsDate.length).toFixed(
              2
            ),
            expireOrder: parseFloat(avgExpire / analyticsDate.length).toFixed(
              2
            ),
            pendingOrder: parseFloat(avgpending / analyticsDate.length).toFixed(
              2
            ),
            publishOrder: parseFloat(avgpublish / analyticsDate.length).toFixed(
              2
            ),
            rejectOrder: parseFloat(avgreject / analyticsDate.length).toFixed(
              2
            ),
          },
        ]);
      } else {
        setdataForTableOrder(acceptedData);
      }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = rejectedOrderAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          totalCount: rejectedOrderAnalyticsData[index].totalCount,
        };
        rejectedData.push(obj);
      } else {
        let obj = { _id: analyticsDate[i], totalCount: 0 };
        rejectedData.push(obj);
      }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = publishedOrderAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          totalCount: publishedOrderAnalyticsData[index].totalCount,
        };
        publishedData.push(obj);
      } else {
        let obj = { _id: analyticsDate[i], totalCount: 0 };
        publishedData.push(obj);
      }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = femaleAnalystics?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          totalViews: femaleAnalystics[index].views * 2,
        };
        avg += femaleAnalystics[index].views * 2;
        femaleViewsData.push(obj);
      } else {
        let obj = { Date: analyticsDate[i], totalViews: 0 };
        femaleViewsData.push(obj);
      }

      if (i === analyticsDate?.length - 1) {
        setdataForTableMaleViews([
          ...femaleViewsData,

          { Date: "Total", totalViews: avg },
          {
            Date: "Average",
            totalViews: parseFloat(avg / analyticsDate.length).toFixed(2),
          },
        ]);
      } else {
        setdataForTableMaleViews(femaleViewsData);
      }

      // if (i === analyticsDate.length - 1) {
      //   console.log("hfklsdfklsdjflsd", i);
      //   avg = avg / analyticsDate.length;
      //   setdataForTableMaleViews([
      //     ...dataForTableMaleViews,
      //     { Date: "Total", totalViews: analyticsDate.length },
      //     { Date: "Average", totalViews: avg },
      //   ]);

      //   console.log(
      //     "skfjklsdjflksjfklsjflsjfksjfrwerwsfsjfs",
      //     avg,
      //     dataForTableMaleViews
      //   );
      // }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = femaleAnalystics?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          amount: femaleAnalystics[index].amount,
        };
        avgA += femaleAnalystics[index].amount;
        revenueData.push(obj);
      } else {
        let obj = { Date: analyticsDate[i], amount: 0 };
        revenueData.push(obj);
      }
      // setdataForTableRevenue(revenueData);
      if (i === analyticsDate.length - 1) {
        setdataForTableRevenue([
          ...revenueData,

          { Date: "Total", amount: avgA },
          {
            Date: "Average",
            amount: parseFloat(avgA / analyticsDate.length).toFixed(2),
          },
        ]);
      } else {
        setdataForTableRevenue(revenueData);
      }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = femaleAnalystics?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          videoDuration: femaleAnalystics[index].videoDuration / 60,
        };
        avgM += femaleAnalystics[index].videoDuration / 60;
        playHour.push(obj);
      } else {
        let obj = { Date: analyticsDate[i], videoDuration: 0 };
        playHour.push(obj);
      }
      // setdataForTablePlayHour(playHour);
      if (i === analyticsDate.length - 1) {
        setdataForTablePlayHour([
          ...playHour,

          { Date: "Total", videoDuration: avgM },
          {
            Date: "Average",
            videoDuration: parseFloat(avgM / analyticsDate.length).toFixed(2),
          },
        ]);
      } else {
        setdataForTablePlayHour(playHour);
      }
    }
  }, [
    acceptedOrderAnalyticsData,
    rejectedOrderAnalyticsData,
    publishedOrderAnalyticsData,
    viewOrderAnalytics,
    revenue,
    femaleAnalystics,
  ]);
  // making an empty array and pushing all data in (data with 0 count)
  // useEffect(() => {
  //   if (props?.id) {
  //     if (orderType === "views") {
  //       getOrderViewsAnalyticsData();
  //       return;
  //     }
  //     if (orderType === "revenue") {
  //       getrevenueAnalyticsData();
  //       return;
  //     }
  //     if (!date) {
  //       return;
  //     }
  //     getOrderAnalyticsData();
  //   }
  // }, [orderType, date, props?.id]);

  useEffect(() => {
    if (orderType === "All") {
      setcountName("Orders");
      const firstData = acceptedOrderAnalyticsData?.map(
        (a) =>
          a.expireOrder +
          a.publishOrder +
          a.cancelOrder +
          a.pendingOrder +
          a.rejectOrder
      );
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setfileName("Orders Analytics");
      setDataSets([
        {
          label: "Expired Order",
          data: acceptedData?.map((a) => a?.expireOrder),
          borderColor: "#ff6f6f",
          backgroundColor: "#ff6f6f",

          borderRadius: 20,
          barThickness: 24,
        },
        {
          label: "Published",
          data: acceptedData?.map((a) => a?.publishOrder),
          borderColor: "rgb(215,35,205,100%)",
          backgroundColor: "rgba(215,35,205,100%)",

          borderRadius: 20,
          barThickness: 24,
        },

        {
          label: "Cancelled Order",
          data: acceptedData?.map((a) => a?.cancelOrder),
          borderColor: "#FFA500",
          backgroundColor: "#FFA500",

          borderRadius: 20,
          barThickness: 24,
        },

        {
          label: "Pending Order",
          data: acceptedData?.map((a) => a?.pendingOrder),
          borderColor: "#FFFF00",
          backgroundColor: "#FFFF00",

          borderRadius: 20,
          barThickness: 24,
        },
        {
          label: "Rejected Order",
          data: acceptedData?.map((a) => a?.rejectOrder),
          borderColor: "rgb(246,14,14,100%)",
          backgroundColor: "rgb(246,14,14,100%)",

          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "views") {
      setcountName("Views");
      setfileName("Total Views ");
      const firstData = femaleAnalystics?.map((a) => a.views);
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem * 2);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setDataSets([
        {
          label: "View",
          data: femaleViewsData?.map((a) => a?.totalViews),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "revenue") {
      setcountName("Booking Amount");
      setfileName("Booking Amount");

      const firstData = femaleAnalystics?.map((a) => a.amount);
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setDataSets([
        {
          label: "Booking Amount",
          data: revenueData?.map((a) => a?.amount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "female") {
      setcountName("Female Views");
      setfileName("Female Views");
      const firstData = femaleAnalystics?.map((a) => a.views);
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem * 2);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setDataSets([
        {
          label: "Female Views",
          data: femaleViewsData?.map((a) => a?.totalViews),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "male") {
      setcountName("Male Views");
      setfileName("Male Views");
      const firstData = femaleAnalystics?.map((a) => a.views);
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem * 2);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setDataSets([
        {
          label: "Male Views",
          data: femaleViewsData?.map((a) => a?.totalViews),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "playHour") {
      setcountName("Play Hour");
      setfileName("Play Hour");
      const firstData = playHour?.map((a) => a.videoDuration);
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setDataSets([
        {
          label: "Play Hour",
          data: playHour?.map((a) => a?.videoDuration),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    }
  }, [
    orderType,
    acceptedOrderAnalyticsData,
    viewOrderAnalytics,
    rejectedOrderAnalyticsData,
    publishedOrderAnalyticsData,
    revenue,
    femaleAnalystics,
    countName,
  ]);

  useEffect(() => {
    if (date) {
      if (orderType === "views") {
        getViewfemale();

        return;
      }
      if (orderType === "revenue") {
        getViewfemale();

        return;
      }
      if (orderType === "female") {
        getViewfemale();

        return;
      }
      if (orderType === "male") {
        getViewfemale();

        return;
      }
      if (orderType === "playHour") {
        getViewfemale();

        return;
      }
      getAnalyticsData();
    }
  }, [orderType, date, props?.id]);
  //  most populat time // least popular time api

  const getordercountforBillboard = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/order/getordercountforBillboard/${props?.id}`
      );
      if (res.status === 200) {
        console.log(res, "resssssssss");
      } else {
        console.log(res, "resssssssss");
      }
    } catch (err) {
      console.log(err);
    }
  };
  //  most populat time // least popular time api

  useEffect(() => {
    if (props?.id) {
      orderCount();
      getordercountforBillboard();
      getViewfemale();
    }
  }, [props?.id]);

  const excelFileDownloader = () => {
    if (orderType === "views") {
      exportToExcel(dataForTableMaleViews, fileName);
      return;
    }
    if (orderType === "revenue") {
      exportToExcel(dataForTableRevenue, fileName);

      return;
    }
    if (orderType === "female") {
      exportToExcel(dataForTableMaleViews, fileName);

      return;
    }
    if (orderType === "male") {
      exportToExcel(dataForTableMaleViews, fileName);

      return;
    }
    if (orderType === "playHour") {
      exportToExcel(dataForTablePlayHour, fileName);

      return;
    }
    exportToExcel(dataForTableOrder, fileName);
  };

  return (
    <>
      <div style={{ padding: "8px" }}>
        <Grid
          container
          sx={{
            padding: "10px",
            marginBottom: "24px",
            borderRadius: "8px",
            boxShadow: "0px 1px 10px 0px #0000001a",
          }}
          rowGap="10px"
        >
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">Orders</Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {orderCountData?.msg ? orderCountData?.msg : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                {" "}
                Campaign
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {orderCountData?.campaignCount
                  ? orderCountData?.campaignCount
                  : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                {" "}
                Gross Revenue
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                ₹{" "}
                {parseInt(
                  orderCountData?.revenue ? orderCountData?.revenue : 0
                ).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Total Views
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {orderCountData?.view ? orderCountData?.view * 2 : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Male Views
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {orderCountData?.maleViews ? orderCountData?.maleViews * 2 : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Female views
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {orderCountData?.femaleViewsviews
                  ? orderCountData?.femaleViewsviews * 2
                  : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Hours Play
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {Math.floor(orderCountData?.videoDuration / 60)} hr
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Most popular Time
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {!orderCountData?.peakPopularTime
                  ? "00:00"
                  : orderCountData?.peakPopularTime > 12
                  ? `${orderCountData?.peakPopularTime - 12} PM`
                  : `${orderCountData?.peakPopularTime} AM`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingBottom: "10px" }}>
        <Grid
          container
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            paddingTop: "10px",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            rowGap="10px"
            sx={{ marginBottom: "10px" }}
          >
            <Grid item>
              <Grid container item sx={{ padding: "0 10px" }}>
                <FormControl
                  sx={{
                    minWidth: 300,
                    width: "100%",
                    fontSize: "10px",
                    border: "1px solid #DDDDDD",
                    fontFamily: "oswald",
                  }}
                  className="form_control_div"
                  size="small"
                >
                  <TextField
                    select
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle-noborder analytics_dropDown"
                    labelid="demo-select-small"
                    id="demo-select-small"
                    inputProps={{ "aria-label": "Without label" }}
                    value={orderType}
                    defaultValue={0}
                    onChange={(e) => {
                      setOrderType(e.target.value);
                      // getFilteredBillboards();
                    }}
                  >
                    <MenuItem value={"revenue"} className="Selectmenustyle">
                      <Typography
                        className="Selectmenustyle fs16px  "
                        onClick={() => {
                          setgraphType("amount");
                        }}
                      >
                        Booking Amount
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"All"} className="Selectmenustyle">
                      <Typography className="Selectmenustyle fs16px  ">
                        Orders
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"views"} className="Selectmenustyle">
                      <Typography
                        className="Selectmenustyle fs16px  "
                        onClick={() => {
                          setgraphType("views");
                        }}
                      >
                        Total Views
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"male"} className="Selectmenustyle">
                      <Typography
                        className="Selectmenustyle fs16px  "
                        onClick={() => {
                          setgraphType("maleViews");
                        }}
                      >
                        Male Views
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"female"} className="Selectmenustyle">
                      <Typography
                        className="Selectmenustyle fs16px  "
                        onClick={() => {
                          setgraphType("femaleViews");
                        }}
                      >
                        Female Views
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"playHour"} className="Selectmenustyle">
                      <Typography
                        className="Selectmenustyle fs16px  "
                        onClick={() => {
                          setgraphType("playHour");
                        }}
                      >
                        Play Hours
                      </Typography>
                    </MenuItem>
                  </TextField>
                </FormControl>
                <Typography
                  className="blackcolor484848 fs18px"
                  style={{ marginTop: "10px" }}
                >
                  Total {countName} :{" "}
                  {acceptedOrderCount ? Math.floor(acceptedOrderCount) : 0}{" "}
                  {orderType === "playHour" ? "hr" : ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid
                  container
                  item
                  columnGap="10px"
                  sx={{ padding: "0 10px" }}
                  alignItems="center"
                >
                  <Calendar
                    getDataFromChildHandler={getDataFromChildHandler}
                    dataForTableOrder={dataForTableOrder}
                  />
                  <Button
                    lg={{ padding: "0 10px" }}
                    className="purple_btn_primary text_transform_inherit"
                    onClick={() => {
                      excelFileDownloader();
                    }}
                  >
                    Export
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Bar options={options} data={data} />
        </Grid>
      </div>
    </>
  );
};

export default AnalyticsTab;
