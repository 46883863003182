import React, { useState } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Tooltip,
  Snackbar,
  Input,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useForm } from "react-hook-form";

import PropTypes from "prop-types";
import { useLogin } from "../../../../../Context/ExportContext";
import { FETCH_URL } from "../../../../../fetchIp";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
//React Icons
import { MdOutlinePassword } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";
import { FaRegEyeSlash } from "react-icons/fa";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2 }} className="dialog-title-pass" {...other}>
      {children}
      <Typography className="whitecolortypo500">Reset Your Password</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function PasswordChange({
  userId,
  getuseraccordingType,
  passwordValue,
  confirmPasswordValue,
}) {
  const { token } = useLogin();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");
  const [show, setShow] = React.useState(false);
  const [password, setPassword] = useState(" ");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setPasswordInput({ password: "", confirmPassword: "" });
  };
  // console.log(" Check Password Status", password);

  const onSubmit = (data) => setOpen(false);

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const passwordChange = (event) => {
    setPasswordInput(event.target.value);
  };
  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...passwordInput,
      [passwordInputFieldName]: passwordInputValue,
    };
    setPasswordInput(NewPasswordInput);
  };
  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{6,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password can not  empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 6 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" &&
        passwordInput.confirmPassword.length > 0)
    ) {
      if (passwordInput.confirmPassword !== passwordInput.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };
  const newPassword = passwordInput.confirmPassword;

  const PasswordChange = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/user/updatePassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: userId,
          newPassword: newPassword,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setSnackOpen(true);
        setSnackMsg(res.msg);
        setOpen(false);
        getuseraccordingType();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  return (
    <React.Fragment>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={8000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Rest_Password" className="tooltipheight">
        <IconButton className="mt-5px" onClick={handleClickOpen}>
          <MdOutlinePassword />
        </IconButton>
      </Tooltip>

      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: "SmallDialog",
        }}
      >
        <BootstrapDialogTitle> </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            sx={{
              backgroundColor: "#fffff",
              marginTop: "10px",
            }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item md={5}>
                <Typography className="greycolortypo mt10px">
                  New Password
                </Typography>
                <Input
                  className="inputstyle"
                  type={show ? "text" : "password"}
                  // type="password"
                  disableUnderline
                  //   onChange={passwordChange}
                  // {...register("singleErrorInput", {
                  //   required: "This is required.",
                  // })}
                  value={passwordValue}
                  onChange={handlePasswordChange}
                  onKeyUp={handleValidation}
                  name="password"
                  placeholder="password"
                />
                <Typography className="redcolortypo_500">
                  {passwordError}
                </Typography>
                {/* <ErrorMessage
                  errors={errors}
                  name="singleErrorInput"
                  render={({ message }) => <p>{message}</p>}
                /> */}
              </Grid>
              <Grid item md={5}>
                <Typography className="greycolortypo mt10px">
                  Confirm Password
                </Typography>
                <Input
                  className="inputstyle"
                  type={show ? "text" : "password"}
                  // type="password"
                  disableUnderline
                  value={confirmPasswordValue}
                  onChange={handlePasswordChange}
                  onKeyUp={handleValidation}
                  name="confirmPassword"
                  placeholder="confirm password"
                />
                <Typography className="redcolortypo">
                  {confirmPasswordError}
                </Typography>
              </Grid>
              <Grid item md={1} className="mt40px">
                <Tooltip title="show_password">
                  {show ? (
                    <IconButton
                      disableRipple
                      className="ActionButtonSize"
                      onClick={() => setShow(false)}
                    >
                      <FaRegEyeSlash />
                    </IconButton>
                  ) : (
                    <IconButton
                      disableRipple
                      className="ActionButtonSize"
                      onClick={() => setShow(true)}
                    >
                      <AiOutlineEye />
                    </IconButton>
                  )}
                </Tooltip>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button className="  diallog-no-btn width-25" onClick={handleClose}>
              No
            </Button>
            <Button
              type="submit"
              className={
                passwordInput?.confirmPassword?.length < 6
                  ? "disabledbtn width-25"
                  : "purplebtn width-25"
              }
              disabled={newPassword?.length < 6 ? true : false}
              onClick={PasswordChange}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </React.Fragment>
  );
}
