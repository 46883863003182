import React, { useState, useEffect } from "react";
import moment from "moment";
import { Typography, Grid, Divider, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";

import axiosInstance from "../../../../../api/axiosInstance";
import RemoveBillboardDilaog from "../../ActionDialog/RemoveDialog";
import RemoveUserDialog from "../../ActionDialog/RemoveUserDialog";

import AssignBillboardDailog from "../../ActionDialog/SelectBillBoard";
import AssingUserDialog from "../../ActionDialog/SelectUser";
import permissionUtils from "../../../../../component/permissionUtils";
import Arrow from "./../../../../../assets/Img/arrowForward.png";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Submit(props) {
  const deviceData = props?.deviceData;
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  const value = props?.value;
  const { getDeviceInfo } = props;
  const [assignedData, setAssignedData] = useState(null);
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [openuser, setOpenUser] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deviceAssignUser, setDeviceAssignUser] = useState(null);
  const AssignDeviceID = deviceData?._id;
  const BillboardStatus = deviceData?.billboardAssignedStatus;
  const UserStatus = deviceData?.userAssignedStatus;
  const [assignDevice, setAssignDevice] = useState(false);
  const [deviceID, setDeviceId] = useState(null);
  const AssignBillboardDeviceData = deviceData?.assignedBillboard;
  const [originalUserData, setOriginalUserData] = React.useState(null);
  const UserChange = (e, data) => {
    setOriginalUserData(data?._id);
  };
  const handleChangeByPage = (event, value) => {
    setCurrentPage(value);
  };
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleChange = (event) => {
    setAssignDevice(true);
  };
  const handleClickOpenUser = () => {
    setOpenUser(true);
  };
  const handleCloseUser = () => {
    setOpenUser(false);
  };
  //
  const removeUser = () => {
    setDeviceId(false);
    const deleteFunc = async () => {
      let body = { userId: originalUserData, deviceId: AssignDeviceID };
      try {
        let resp = await axiosInstance.post(
          "/api/device/removeUserToDevice",
          body
        );

        if (resp.status === 200) {
          setSnackOpen(true);
          setSnackMsg(resp.data.msg);
          getDeviceInfo();
        } else {
          setSnackerropen(true);
          setSnackErrMsg(resp.data.msg.err);
          setOpenUser(false);
          handleCloseUser();
          console.log(" Device Assing to Billboard", resp.data.msg);
        }
      } catch (error) {
        console.log("Error from Remove Device Function", error);
      }
    };

    deleteFunc();
  };
  const removeBillboard = () => {
    setDeviceId(false);
    const deleteFunc = async () => {
      let body = { deviceId: AssignDeviceID };
      try {
        let resp = await axiosInstance.post(
          "/api/device/removeBillboardToDevice",
          body
        );
        if (resp.status === 200) {
          setSnackOpen(true);
          setSnackMsg(resp.data.msg);
          getDeviceInfo();
        } else {
          setSnackerropen(true);
          setSnackErrMsg(resp.data.msg.err);
          setOpenUser(false);
          handleCloseUser();
          console.log(" Device Remove to  Assing Billboard", resp.data.msg);
        }
      } catch (error) {
        console.log("Error from Remove Device Function", error);
      }
    };

    deleteFunc();
  };

  useEffect(() => {
    if (props?.deviceData) {
      setAssignedData(props?.deviceData);
    }
  }, [props?.deviceData]);
  console.log("assignedData", assignedData);

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={1000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid
        container
        className="mt25px"
        justifyContent="space-between"
        rowGap="20px"
      >
        {/* <Grid
          item
          lg={5.8}
          md={5.8}
          sm={12}
          className="grey-border profileoverview-card"
        >
          <Typography
            component="h5"
            className="blackcolortypo Detail fs20px mt10px"
          >
            User
          </Typography>
          <Divider className="mt10px" />
          {UserStatus === true ? (
            <Grid container className="mt25px pl20px  pb20px">
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo ">User ID</Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 ">
                #
                  {assignedData &&
                    assignedData.assignedTo &&
                    assignedData.assignedTo._id.substring(
                      15,
                      assignedData.assignedTo._id.length
                    )}
                </Typography>
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  User Name
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 mt10px text_transform_capitalize">
                  {assignedData && assignedData?.assignedTo?.name
                    ? assignedData?.assignedTo?.name
                    : assignedData &&
                      assignedData?.assignedTo?.firstName +
                        " " +
                        assignedData?.assignedTo?.lastName}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Role
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 mt10px">
                  {assignedData && assignedData?.assignedTo?.role
                    ? assignedData?.assignedTo?.role
                    : "BusinessOwner"}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Assigned on
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 mt10px">
                  {assignedData &&
                    moment(assignedData?.assignedOn).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Assigned by
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 mt10px">
                  {assignedData && assignedData?.assignedBy?.name}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container className="mt25px pb-10px">
                <Typography
                  align="center"
                  className=" width100 lightgreycolortypo"
                >
                  No User Assigned yet!
                </Typography>
                <Typography
                  align="center"
                  className=" width100 lightgreycolortypo"
                >
                  Please follow below steps to assign user
                </Typography>
                <Typography
                  align="center"
                  className=" width100 lightgreycolortypo"
                ></Typography>
                <ol className="mt30px" style={{ marginBottom: "15px" }}>
                  <li className="greycolor505050400  ">
                    Click Below Button “Assign user”
                  </li>
                  <li className="greycolor505050400 pt5px ">
                    Select or search user ID.
                  </li>
                  <li className="greycolor505050400 pt5px">Click Confirm</li>
                  <li className="greycolor505050400  pt5px"> Done.</li>
                </ol>
              </Grid>
            </>
          )}
          <Grid item xs={12} onClick={handleChange}>
            {UserStatus === true ? (
              <>
                <Typography
                  className="  cursorpoint"
                  align="center"
                  style={{ marginTop: "35px", borderTop: "1px solid #dddddd" }}
                >
                  <RemoveUserDialog
                    removeUser={removeUser}
                    setDeviceId={setDeviceId}
                  />
                </Typography>
              </>
            ) : (
              <Typography
                className="p15px  cursorpoint"
                align="center"
                sx={{ borderTop: "1px solid #dddddd" }}
              >
                <AssingUserDialog
                  value={value}
                  handleCloseUser={handleCloseUser}
                  handleClickOpenUser={handleClickOpenUser}
                  setOpenUser={setOpenUser}
                  openuser={openuser}
                  handleChangeByPage={handleChangeByPage}
                  deviceAssignUser={deviceAssignUser}
                  UserChange={UserChange}
                  AssignDeviceID={AssignDeviceID}
                  getDeviceInfo={getDeviceInfo}
                />
              </Typography>
            )}
          </Grid>
        </Grid> */}

        <Grid
          item
          lg={5.8}
          md={5.8}
          sm={12}
          className="grey-border profileoverview-card"
        >
          <Typography
            component="h5"
            className="blackcolortypo Detail fs20px mt10px pl0px"
          >
            Billboard
          </Typography>
          <Divider className="mt10px" />
          {BillboardStatus === true ? (
            <Grid container className="mt25px pl20px  pb20px">
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo ">
                  Billboard ID
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Link
                  to={`/admin/billboardscreens/${assignedData?.assignedBillboard?._id}`}
                  className="linkcolor"
                  state={{ item: assignedData }}
                >
                  <Grid container>
                    <Typography className="blackcolor484848 ">
                      {assignedData &&
                        assignedData?.assignedBillboard &&
                        assignedData?.assignedBillboard?.billboardId &&
                        assignedData?.assignedBillboard?.billboardId}
                      {/* UP16-HM-121 */}#
                      {AssignBillboardDeviceData?.assignedTo?._id.slice(-5)}
                    </Typography>{" "}
                    <img
                      src={Arrow}
                      style={{ height: "21px", marginLeft: "4px" }}
                    />
                  </Grid>
                </Link>
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Name
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 mt10px">
                  {assignedData &&
                    assignedData?.assignedBillboard &&
                    assignedData?.assignedBillboard?.billboardName &&
                    assignedData?.assignedBillboard?.billboardName}
                </Typography>
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Address
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 mt10px">
                  {assignedData &&
                    assignedData?.assignedBillboard &&
                    assignedData?.assignedBillboard?.fullAddress &&
                    assignedData?.assignedBillboard?.fullAddress}
                </Typography>
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Pincode
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 mt10px">
                  {assignedData &&
                    assignedData?.assignedBillboard &&
                    assignedData?.assignedBillboard?.pincode &&
                    assignedData?.assignedBillboard?.pincode}
                </Typography>
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Assigned by
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 mt10px">
                  {assignedData &&
                    assignedData?.billboardassignedBy &&
                    assignedData?.billboardassignedBy?.name &&
                    assignedData?.billboardassignedBy?.name}
                </Typography>
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <Typography className="lightgreycolortypo mt10px ">
                  Assigned on
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <Typography className="blackcolor484848 mt10px">
                  {/* 22/07/2022 */}
                  {assignedData &&
                    assignedData?.billboardassignedOn &&
                    moment(assignedData?.billboardassignedOn).format(
                      "DD-MM-YYYY"
                    )}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container className="mt25px pb-10px">
                <Typography
                  align="center"
                  className=" width100 lightgreycolortypo"
                >
                  No billboard Assigned yet!
                </Typography>

                <Typography
                  align="center"
                  className=" width100 lightgreycolortypo"
                >
                  Please follow below steps to assign billboard
                </Typography>
                <Typography
                  align="center"
                  className=" width100 lightgreycolortypo"
                ></Typography>
                <ol className="mt30px" style={{ marginBottom: "15px" }}>
                  <li className="greycolor505050400  ">
                    Click Below Button “Assign billboard”
                  </li>
                  <li className="greycolor505050400  pt5px">
                    Select or search billboard ID.
                  </li>
                  <li className="greycolor505050400  pt5px">Click Confirm</li>
                  <li className="greycolor505050400 pt5px ">Done.</li>
                </ol>
              </Grid>
            </>
          )}
          {PermissionCheck("assign-user-billboard-to-device") && (
            <Grid item xs={12} onClick={handleChange}>
              {BillboardStatus == true ? (
                <Typography
                  className=" greencolortypo cursorpoint"
                  align="center"
                  style={{ marginTop: "0px", borderTop: "1px solid #dddddd" }}
                >
                  <RemoveBillboardDilaog
                    setDeviceId={setDeviceId}
                    removeBillboard={removeBillboard}
                  />
                </Typography>
              ) : (
                <Typography
                  className="p15px greencolortypo cursorpoint"
                  align="center"
                  sx={{ borderTop: "1px solid #dddddd" }}
                >
                  <AssignBillboardDailog
                    value={value}
                    handleCloseUser={handleCloseUser}
                    handleClickOpenUser={handleClickOpenUser}
                    setOpenUser={setOpenUser}
                    openuser={openuser}
                    handleChangeByPage={handleChangeByPage}
                    deviceAssignUser={deviceAssignUser}
                    UserChange={UserChange}
                    getDeviceInfo={getDeviceInfo}
                    AssignDeviceID={AssignDeviceID}
                  />
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
