import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import DialogContent from "@mui/material/DialogContent";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  InputBase,
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import WalletImg from "../../assets/Img/checkout.png";
import useMediaQuery from "@mui/material/useMediaQuery";

import displayRazorpay from "../../utils/paymentGateway";
import { v4 } from "uuid";
import axiosInstance from "../../api/axiosInstance";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddWallet = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(true);
  const [amount, setAmount] = useState(props?.amount);
  const [maxWidth, setMaxWidth] = useState("md");
  const [walletId, setWalletId] = useState(props?.walletId);
  // const [loader, setLoader] = useState(false);
  const mobileView = useMediaQuery("(max-width:600px)");

  const [timerShow, setTimerShow] = useState(false);
  const [timer, setTimer] = useState();
  const [innerApiCall, setInnerApiCall] = useState(120);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [uID, setUID] = useState(v4());
  const [openTab, setOpenTab] = useState();
  let intervalapi;
  let check;
  let userID = JSON.parse(localStorage.getItem("userData")).user._id;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const Deposit = async () => {
    try {
      if (amount > 0 && amount != null) {
        console.log("amountsvvhh", amount);

        let data = await displayRazorpay(
          Number(amount),
          walletId,
          props.handleClose,
          uID
        );
        console.log("data>>>>>>>>>>", data);
        // setOpen(false);
        setTimerShow(true);
        if (data.responseCode == 300) {
          setTimer(120);
          let check = window.open(
            data.redirect_url
            // "_blank",
            // "width=600,height=400,left=200,top=200"
          );
          setOpenTab(check);
        }
      } else {
        enqueueSnackbar("amount is empty", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log("error in deposit", err);
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (innerApiCall == timer) {
        console.log("api", innerApiCall);
        axiosInstance
          .post("/api/payment/addMoneyToUserWallet", {
            merchant_ref: uID,
            userId: userID,
          })
          .then((datas1) => {
            console.log("finl", datas1, timer);
            if (datas1?.status == 200) {
              setPaymentSuccess(true);
              openTab?.close();
              setTimer(0);
              setTimeout(() => {
                setTimerShow(false);
                props.handleClose();
                props.reFetch();
              }, 10000);
            } else if (datas1?.response?.status == 402) {
              console.log(" eorror log", datas1, datas1?.response?.status);
              setPaymentSuccess(false);
              openTab?.close();
              setTimer(0);
              setTimeout(() => {
                setTimerShow(false);
                props.handleClose();
                props.reFetch();
              }, 10000);
              enqueueSnackbar(datas1?.response?.data?.msg, {
                variant: "error",
                autoHideDuration: 3000,
              });
            }
          });
        setInnerApiCall((val) => val - 5);
      }

      if (timer <= 0) {
        clearInterval(interval);
        openTab?.close();
      }
      setTimer((time) => time - 1);
    }, 1000);

    if (timer <= 0) {
      clearInterval(interval);
      openTab?.close();
    }
    return () => {
      clearInterval(interval);
      // openTab?.close();
    };
  }, [timer]);
  useEffect(() => {
    Deposit();
  }, []);

  return (
    <div>
      {timerShow && (
        <BootstrapDialog
          onClose={timerShow ? "" : props.handleClose}
          maxWidth={maxWidth}
          aria-labelledby="customized-dialog-title"
          open={open}
          sx={{ padding: "0 !important" }}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={timerShow && props.handleClose}
            className="font-Lato fontWeight600 fs18px whitecolor center-text purple-gradient flex centerAi  "
          >
            {timerShow ? "Time" : "Deposit"}
          </BootstrapDialogTitle>

          <DialogContent
            sx={{
              padding: "0 !important",
              marginBottom: "2rem",
            }}
          >
            <>
              <Grid
                container
                sm={12}
                sx={{
                  rowGap: "1.5rem",
                  height: "50Vh",
                  width: "30vw",
                  // minWidth: "320px",
                }}
                className="flex center "
              >
                <Grid
                  className={
                    paymentSuccess
                      ? "pmt_box_shadow_success pmt_icon fw20"
                      : timer > 0
                      ? "pmt_box_shadow_init pmt_icon fw20"
                      : "pmt_box_shadow_err pmt_icon fw20"
                  }
                >
                  {paymentSuccess ? (
                    <DoneIcon fontSize="large" color="success" />
                  ) : timer == 0 ? (
                    <CloseIcon fontSize="large" color="error" />
                  ) : (
                    timer
                  )}
                </Grid>
                <Grid container item className=" flex  centerJc width100 ">
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                    className={
                      paymentSuccess
                        ? "pmt_success"
                        : timer > 0
                        ? "pmt_init"
                        : "pmt_err"
                    }
                  >
                    {paymentSuccess
                      ? "Payment Successfully Done"
                      : timer > 0
                      ? "Payment under processing ..."
                      : "Payment failed !"}
                  </Typography>

                  {/* </Grid> */}
                </Grid>
              </Grid>
            </>
          </DialogContent>
        </BootstrapDialog>
      )}
    </div>
  );
};
export default AddWallet;
