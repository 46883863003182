import React, { useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  IconButton,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitleConfirmation = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2 }} className="purple-gradient" {...other}>
      {children}
      <Typography className="whitecolortypo"> Guidelines</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 3,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitleConfirmation.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ContentGuidline() {
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");
  // SnackBar
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={() => {
          handleClickOpen();
        }}
        sx={{ width: "100%" }}
        className="chipsuccescolorGa"
      >
        Check out content guideline
      </Button>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitleConfirmation
          onClose={handleClose}
        ></BootstrapDialogTitleConfirmation>
        <DialogContent>
          <Grid
            container
            gap={1}
            sx={{ padding: "20px 20px" }}
            justifyContent={"space-between"}
          >
            <Paper
              style={{
                backgroundColor: "white",
                marginTop: 12,
                marginBottom: 80,
                // padding: margin ? "0 5px" : "0 17px",
                paddingBottom: 10,
              }}
            >
              <Typography
                variant="h5"
                style={{
                  color: "rgba(183,54,248,255)",
                  fontFamily: "Oswald-Bold",
                    marginRight: 5,
                  padding:"10px"
                }}
              >
                Content Guidelines
              </Typography>
              <List>
                <ListItem>
                  <Typography
                    style={{
                      color: "rgba(183,54,248,255)",
                      fontFamily: "Oswald-Bold",
                      fontSize: 16,
                    }}
                  >
                    1.
                  </Typography>
                  <ListItemText primary="PostMyAd is not a place to post content which supports or praises terrorism, organized crime, or hate groups." />
                </ListItem>
                <ListItem>
                  <Typography
                    style={{
                      color: "rgba(183,54,248,255)",
                      fontFamily: "Oswald-Bold",
                      fontSize: 16,
                    }}
                  >
                    2.
                  </Typography>
                  <ListItemText primary="Do not post NSFW content." />
                </ListItem>
                <ListItem>
                  <Typography
                    style={{
                      color: "rgba(183,54,248,255)",
                      fontFamily: "Oswald-Bold",
                      fontSize: 16,
                    }}
                  >
                    3.
                  </Typography>
                  <ListItemText primary="Post photos and videos that are appropriate for a diverse audience." />
                </ListItem>
                <ListItem>
                  <Typography
                    style={{
                      color: "rgba(183,54,248,255)",
                      fontFamily: "Oswald-Bold",
                      fontSize: 16,
                    }}
                  >
                    4.
                  </Typography>
                  <ListItemText primary="Share only photos and videos that you’ve taken or have the right to share." />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default ContentGuidline;
