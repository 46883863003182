import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, CardActions, ImageListItemBar } from "@mui/material";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// All Images
import axiosInstance from "../../../../../../api/axiosInstance";
//React Icons
import noBillBoardFond from "../../../../../../assets/Img/no_billboard_found.gif";
// Mui Icons
// skeleten loading
import Skeleton from "@mui/material/Skeleton";
// skeleten loading

const BillList = ({ userID }) => {
  const [loading, setLoading] = useState(false);
  const [billBoardList, setAllBillboards] = useState([]);

  const getallBillBoardScreen = async () => {
    try {
      let res = await axiosInstance.get(
        `api/billboard/getBillboardsByUserId/${userID}`
      );
      console.log("billboards ==> ", res);
      if (res?.status === 200 || res?.status === 201) {
        setAllBillboards(res?.data?.data);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("USER PROFILE DATA ==>", error);
    }
  };

  React.useEffect(() => {
    getallBillBoardScreen();
  }, [userID]);
  return (
    <>
      <div>
        <Grid container>
          <Grid container className="fiter-heading">
            <Typography align="center" className="greycolor1c1c1c fs20px">
              Showing {billBoardList?.length ? billBoardList?.length : 0} Smart
              Billboards
            </Typography>
          </Grid>

          <Grid
            container
            className="billboard-card  pt20px mb70px "
            spacing={4}
          >
            {billBoardList.length > 0 ? (
              billBoardList.map((data, index) => (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={index}
                    className="cursorpoint relative"
                  >
                    <Grid
                      item
                      key={data.billboardName}
                      className="box-shadow "
                      style={{
                        borderRadius: "8px",
                      }}
                    >
                      <Link
                        to={`/admin/billboardscreens/${data._id}`}
                        className="linkcolor"
                        state={{ item: data }}
                      >
                        <ImageListItemBar
                          sx={{
                            background: "transparent",
                          }}
                          position="top"
                        />

                        <img
                          src={data?.thumbnail}
                          alt="green iguana"
                          style={{
                            height: "150px",
                            width: "100%",
                          }}
                        />

                        <CardActions className="campaign-billboardaction">
                          <Grid
                            container
                            justifyContent="space-between"
                            direction="row"
                          >
                            <Grid item>
                              <Typography
                                className="greycolor1c1c1c  fs18px"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {data.billboardName}
                              </Typography>
                              <Typography className="greycolor505050500  fs12px ">
                                #{data.billboardId}
                              </Typography>
                              {/* <Typography className="greycolor505050500  fs14px"></Typography>{" "} */}
                              <Typography className="greycolor1c1c1c fs18px pt5px">
                                {/* <BiRupee style={{ fontSize: "18px" }} /> */}
                                ₹ {data.basePrice}
                              </Typography>
                            </Grid>
                          </Grid>{" "}
                        </CardActions>
                      </Link>
                    </Grid>
                  </Grid>
                </>
              ))
            ) : loading === true ? (
              <>
                {[0, 1, 2, 3].map((a) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="cursorpoint relative"
                  >
                    <Grid
                      item
                      className="box-shadow "
                      style={{
                        borderRadius: "8px",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        sx={{ height: "150px" }}
                      />
                      <Grid
                        container
                        gap="5px"
                        direction="column"
                        sx={{ padding: "5px" }}
                      >
                        <Skeleton sx={{ width: "80%" }} />

                        <Skeleton sx={{ width: "40%" }} />

                        <Skeleton sx={{ width: "40%" }} />
                      </Grid>{" "}
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : (
              <Grid
                container
                item
                sx={{
                  height: "50vh",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={noBillBoardFond}
                  alt="NoBillBoardFond"
                  className="noBillboardFoundGif_width"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BillList;
