import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Typography,
  Container,
  Input,
  Button,
  Snackbar,
  InputBase,
} from "@mui/material";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import MuiAlert from "@mui/material/Alert";
import axiosInstance from "../../../../api/axiosInstance";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function UserManagment({ userID }) {
  // ===================== SnackBar ==================== //
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const vertical = "top";
  const horizontal = "center";
  const openSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };
  const [show, setShow] = useState(false);
  const [showconfrim, setShowConfrim] = useState(false);
  const [showcurrentshow, setCurrentShow] = useState(false);

  const [securityState, setSecurityState] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPassError, setCurrentPassError] = useState("");

  const [passwordValue] = useState(null);
  const [confirmPasswordValue] = useState(null);
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const newPassword = passwordInput.confirmPassword;
  // For PassWord Extra Validation
  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  function ChangeSecurityState() {
    setSecurityState(false);
  }

  function ChangeSecurityCancel(e) {
    setConfirmPasswordError("");
    setPasswordErr("");
    setCurrentPassError("");
    setSecurityState(true);
    setShow(false);
    setCurrentShow(false);
    setShowConfrim(false);
    setCurrentPassword("");
    setPasswordInput((prevState) => ({
      ...prevState,
      password: "",
      confirmPassword: "",
    }));
  }
  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;

    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-8])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password can not  empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" &&
        passwordInput.confirmPassword.length > 0)
    ) {
      if (passwordInput.confirmPassword !== passwordInput.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };
  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...passwordInput,
      [passwordInputFieldName]: passwordInputValue,
    };
    setPasswordInput(NewPasswordInput);
  };

  const handleChangePass = async () => {
    if (currentPassword.length == 0) {
      setCurrentPassError("Current password is required");
      return;
    }
    setCurrentPassError("");
    if (
      passwordInput.password.length === 0 &&
      passwordInput.confirmPassword.length === 0
    ) {
      setPasswordErr("Password is required");
      setConfirmPasswordError(" Confirm password is required");
      return;
    }
    if (passwordInput.confirmPassword !== passwordInput.password) {
      setConfirmPasswordError("Confirm password is not matched");
      return;
    }
    if (passwordInput.confirmPassword.length < 8) {
      setConfirmPasswordError("At least minumum 8 characters");
      return;
      setSecurityState(false);
    }
    if (passwordInput.confirmPassword.length < 8) {
      setConfirmPasswordError("At least minumum 8 characters");
      return;
      setSecurityState(false);
    }

    let body = {
      userId: userID,
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    try {
      const resp = await axiosInstance.post(
        "/api/user/updatePasswordForUser",
        body
      );
      if (resp) {
        openSnackbar(resp?.data?.msg, "success");
        setShow(false);
        setCurrentShow(false);
        setShowConfrim(false);
        setSecurityState(true);
        ChangeSecurityCancel();
      }
    } catch (error) {
      console.log("Catch Block", error);
      openSnackbar(error?.response?.data?.msg || "An error occurred.", "error");
    }
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Grid container className="p-20px mt-20px whitebackground">
        <Grid container xs={12} justifyContent="space-between">
          <Typography className="blackcolortypo fs20px  ">
            Password Change
          </Typography>
          <Grid item>
            {securityState ? (
              <>
                <Button
                  className="purplebtn  min-width-120px"
                  onClick={() => ChangeSecurityState()}
                >
                  Edit
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => ChangeSecurityCancel()}
                  className="previousbtn mr10px min-width-120px"
                >
                  Cancel
                </Button>
                <Button
                  className="purplebtn  min-width-120px"
                  onClick={handleChangePass}
                >
                  Update
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={4} mt={0.25}>
          <Grid item md={4} className="relative">
            <Typography className="darkblacktypo">Current Password</Typography>
            <InputBase
              className="inputstyle"
              disabled={securityState}
              type={showcurrentshow ? "text" : "password"}
              placeholder="Enter your Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />{" "}
            {securityState ? null : (
              <Typography
                sx={{ position: "absolute", top: 70, right: 10 }}
                align="right"
                className="fs-24 cursor-point"
                onClick={() => setCurrentShow(!showcurrentshow)}
              >
                {showcurrentshow ? (
                  <AiOutlineEye color="grey" />
                ) : (
                  <AiOutlineEyeInvisible color="grey" />
                )}
              </Typography>
            )}
            <Typography className="redcolortypo" variant={"subtitle2"}>
              {currentPassError}
            </Typography>{" "}
          </Grid>
          <Grid item md={4} className="relative">
            <Typography className="darkblacktypo">Password</Typography>
            <InputBase
              name="password"
              type={show ? "text" : "password"}
              placeholder="Enter your New Password"
              disabled={securityState}
              className="inputstyle"
              value={passwordValue ? passwordValue : passwordInput?.password}
              onChange={handlePasswordChange}
              onKeyUp={handleValidation}
            />
            {securityState ? null : (
              <Typography
                sx={{ position: "absolute", top: 70, right: 10 }}
                align="right"
                className="fs-24 cursor-point"
                onClick={() => setShow(!show)}
              >
                {show ? (
                  <AiOutlineEye color="grey" />
                ) : (
                  <AiOutlineEyeInvisible color="grey" />
                )}
              </Typography>
            )}
            <Typography className="redcolortypo" variant={"subtitle2"}>
              {passwordError}
            </Typography>{" "}
          </Grid>
          {securityState === false ? (
            <Grid item md={4} className="relative">
              <Typography className="darkblacktypo">
                Confirm Password
              </Typography>
              <InputBase
                disabled={securityState}
                name="confirmPassword"
                type={showconfrim ? "text" : "password"}
                placeholder="Enter your Confirm Pass..."
                className="inputstyle"
                value={
                  confirmPasswordValue
                    ? confirmPasswordValue
                    : passwordInput?.confirmPassword
                }
                onChange={handlePasswordChange}
                onKeyUp={handleValidation}
              />
              {securityState ? null : (
                <Typography
                  sx={{ position: "absolute", top: 70, right: 10 }}
                  align="right"
                  className="fs-24 cursor-point"
                  onClick={() => setShowConfrim(!showconfrim)}
                >
                  {showconfrim ? (
                    <AiOutlineEye color="grey" />
                  ) : (
                    <AiOutlineEyeInvisible color="grey" />
                  )}
                </Typography>
              )}
              <Typography className="redcolortypo" variant={"subtitle2"}>
                {confirmPasswordError}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
