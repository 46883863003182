import React, { useEffect, useState } from "react";
import "./assets/css/Style.css";
import { AdminContext } from "./Context/AdminContext.js";
import { AuthContext } from "./Context/AuthContext.js";
import { Login } from "./Context/ExportContext";
import Admin from "./layouts/Admin";
import Auth from "./layouts/Auth";
import Sidebar from "./component/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { FilterControllerProvider } from "./Context/filter";
import { CampaignControllerProvider } from "./Context/campaignBook";
import { SelectBillboardControllerProvider } from "./Context/selectBillboardNew";
import { newSocket } from "./component/socket";
import { ThemeProvider } from "@mui/material/styles";
import { baseDarkTheme } from "./utils/theme/DefaultColors.js";
function App() {
  const navigate = useNavigate();

  const [token, setToken] = React.useState(null);
  const [user, setUser] = useState(null);
  const [userID, setUserId] = useState(null);

  useEffect(() => {
    function checkUserData() {
      const storeData = JSON.parse(localStorage.getItem("userData"));
      const access_token = localStorage.getItem("access_token");
      if (storeData) {
        setToken(access_token);
        setUser(storeData.user);
        setUserId(storeData?.user?._id);
      } else {
        <Auth />;
      }
    }
    checkUserData();
    window.addEventListener("storage", checkUserData);
    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, [token]);
  useEffect(() => {
    if (newSocket && userID) {
      newSocket.emit("joinUser", userID);
    } else {
      console.error("WebSocket is not open");
    }
  }, [userID, newSocket]);

  return (
    <>
      <ThemeProvider theme={baseDarkTheme}>
        <Login.Provider value={{ token, setToken, user }}>
          <FilterControllerProvider>
            <SelectBillboardControllerProvider>
              <CampaignControllerProvider>
                <AuthContext.Provider value={{ token, setToken, user }}>
                  {token ? (
                    <AdminContext.Provider>
                      <Sidebar />
                      <Admin />
                    </AdminContext.Provider>
                  ) : (
                    <Auth />
                  )}
                </AuthContext.Provider>
              </CampaignControllerProvider>
            </SelectBillboardControllerProvider>
          </FilterControllerProvider>
        </Login.Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
