import { Skeleton } from "@mui/material";
import Carousel from "./CaraousalOpenSrc";

function CarouselForOverview({ billBoardData }) {
  const data = billBoardData?.filesArr?.map((a) => {
    if (a?.filetype == "video/mp4") {
      return {
        video: a?.fileurl,
      };
    } else {
      return {
        image: a?.fileurl,
      };
    }
  });

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  return (
    <div className="overviewCaraousal">
      <div style={{ textAlign: "center" }}>
        <div>
          {data?.length > 0 ? (
            <Carousel
              data={data}
              time={10000}
              width="100%"
              height="414px"
              captionStyle={captionStyle}
              radius="10px"
              slideNumber={false}
              slideNumberStyle={slideNumberStyle}
              captionPosition="bottom"
              automatic={true}
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="black"
              slideImageFit="contain"
              thumbnails={true}
              thumbnailWidth="16.66%"
              style={{
                textAlign: "center",
              }}
            />
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{ width: "100%", height: "500px", borderRadius: "10px" }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CarouselForOverview;
