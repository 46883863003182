import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Card,
  Divider,
  Alert,
  Snackbar,
} from "@mui/material";
import AssignClusterHead from "../Action--Component/AssignedClusterhead";
import axiosInstance from "../../../../../../api/axiosInstance";
function ClusterheadAssign(props) {
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ClusterData, setClusterData] = useState([]);

  const userID = props?.userID;
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const getAssignedClusterhead = async () => {
    setLoading(true);
    try {
      let resp = await axiosInstance.get(
        `admin/getAssignedClusterHeadsFromSubAdmin?subAdminId=${userID}`
      );
      console.log("response  =>", resp.data);
      setLoading(false);
      setClusterData(resp?.data?.data);
    } catch (error) {
      setLoading(false);
      console.log("error from Error ", error);
    }
  };
  useEffect(() => {
    if (userID) {
      getAssignedClusterhead();
    }
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().slice(0, -1); // Removes the trailing 'Z' character
  }

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={1000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid
        container
        md={12}
        sm={12}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
        alignItems="center"
        gap="10px"
      >
        <Grid item>
          <Typography className="greycolor505050500">
            Showing {ClusterData?.length ? ClusterData?.length : 0} ClusterHeads
          </Typography>
        </Grid>
        <Grid item>
          <AssignClusterHead
            snackStates={{
              setSnackOpen,
              setSnackMsg,
              setSnackerropen,
              setSnackErrMsg,
            }}
            userID={userID}
            getAssignedClusterhead={getAssignedClusterhead}
            ClusterData={ClusterData}
          />
        </Grid>

        <Grid
          container
          rowGap="20px"
          style={{
            justifyContent: "flex-start",
            gap: "15px",
            marginTop: "20px",
          }}
        >
          {ClusterData?.length > 0 &&
            ClusterData?.map((item, index) => {
              return (
                <Grid item lg={3.8} md={3.8} sm={12} className="" key={index}>
                  <Card className="profileoverview-card-assignedCluster  grey-border">
                    <>
                      <Grid container className="mt25px  pb20px">
                        <Grid item md={4} sm={4} xs={4}>
                          <Typography className="secondary_graySubheading_500 ">
                            User ID
                          </Typography>
                        </Grid>

                        <Grid item md={8} sm={8} xs={8}>
                          <Typography className="blackcolor484848 ">
                            {item?._id}
                          </Typography>
                        </Grid>
                        <Grid item md={4} sm={4} xs={4}>
                          <Typography className="secondary_graySubheading_500 mt10px ">
                            User Name
                          </Typography>
                        </Grid>
                        <Grid item md={8} sm={8} xs={8}>
                          <Typography className="blackcolor484848 mt10px">
                            {item?.firstName} {item?.lastName}
                          </Typography>
                        </Grid>
                        <Grid item md={4} sm={4} xs={4}>
                          <Typography className="secondary_graySubheading_500 mt10px ">
                            Role
                          </Typography>
                        </Grid>
                        <Grid item md={8} sm={8} xs={8}>
                          <Typography className="blackcolor484848 mt10px">
                            {item?.role}
                          </Typography>
                        </Grid>
                        <Grid item md={4} sm={4} xs={4}>
                          <Typography className="secondary_graySubheading_500 mt10px ">
                            Mobile Number
                          </Typography>
                        </Grid>
                        <Grid item md={8} sm={8} xs={8}>
                          <Typography className="blackcolor484848 mt10px">
                            {item?.mobileNumber}
                          </Typography>
                        </Grid>
                        <Grid item md={4} sm={4} xs={4}>
                          <Typography className="secondary_graySubheading_500 mt10px ">
                            Email
                          </Typography>
                        </Grid>
                        <Grid item md={8} sm={8} xs={8}>
                          <Typography className="blackcolor484848 mt10px">
                            {item?.email}
                          </Typography>
                        </Grid>
                        {item?.assignedOn && (
                          <>
                            {" "}
                            <Grid item md={4} sm={4} xs={4}>
                              <Typography className="secondary_graySubheading_500 mt10px ">
                                Assigned on
                              </Typography>
                            </Grid>
                            <Grid item md={8} sm={8} xs={8}>
                              <Typography className="blackcolor484848 mt10px">
                                {item?.assignedOn}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </>
                  </Card>
                  {/* <Button className={"ClusterDeleteBtn fs18px"}>Delete</Button> */}
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
}

export default ClusterheadAssign;
