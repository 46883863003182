import React from "react";
import { ErrorMessage, useField } from "formik";
import { Grid, InputBase, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const TextField = ({ label, vText, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <InputBase
        className={`secondary_input width100 fs18px ${
          meta.touched && meta.error ? "error_border_primary" : "border_primary"
        }`}
        {...field}
        {...props}
        autoComplete="off"
        disabled={field.name == "Discount_type" ? true : false}
        placeholder={label}
      />

      <ErrorMessage
        component="div"
        name={field.name}
        style={{ color: "red", fontSize: "14px" }}
      />
    </div>
  );
};

export default TextField;
