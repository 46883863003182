import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  Input,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import "react-image-upload/dist/index.css";
import { MdAddCircleOutline } from "react-icons/md";
import { HiOutlineMinusCircle } from "react-icons/hi";

export default function Testing({
  priceData,
  handlepriceData,
  formErrors,
  getTimeSlotDataHandler,
  timeSlotData,
}) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [minsarr, setMinsarr] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);
  const [startTime, setStartTime] = useState(1);
  const [endTime, setEndTime] = useState("AM");
  const [timeslot, setTimeSlot] = useState(
    priceData?.timesArray?.length > 0 ? true : false
  );

  // states for timeslots added By Rahul
  let testcreateArr;
  const [dataToMap, setDataToMap] = React.useState(null);
  useEffect(() => {
    getTimeSlotDataHandler(dataToMap);
  }, [dataToMap]);
  const [selectedSlotType, setSelectedSlotType] = React.useState(
    priceData?.timeslotType === "24 Hours"
      ? 24
      : priceData?.timeslotType === "12 Hours"
      ? 12
      : priceData?.timeslotType == "custom"
      ? 10
      : 0
  );

  const [basePrice, setBasePrice] = React.useState(
    priceData?.basePrice ? priceData?.basePrice : null
  );
  const [showStartTime, setShowStartTime] = React.useState(
    priceData?.timeslotType == "12 Hours" ? true : false
  );
  const [customTimeSlot, setCustomTimeSlot] = React.useState(
    priceData?.timeslotType == "custom" ? true : false
  );
  const [count, setCount] = useState(1);
  const [selectTimePeriod, setSelectTimePeriod] = React.useState("AM");

  // Function for time period
  const handleTimePeriod = (tp) => {
    setSelectTimePeriod(tp);
  };
  // Functions for timeslots added By Rahul

  const TimeSlotApply = () => {
    if (selectedSlotType === 12) {
      setCustomTimeSlot(false);
      testcreateArr = Array(12)
        .fill()
        .map((_, i) => {
          return {
            time: i + startTime,
            multiplier: 1,
          };
        });

      // console.log("testcreateArr from onClick apply ===>", testcreateArr);
      setDataToMap(testcreateArr);
      // handlepriceData("timesArray", testcreateArr)
    }

    if (selectedSlotType === 24) {
      setShowStartTime(false);
      setCustomTimeSlot(false);
      testcreateArr = Array(24)
        .fill()
        .map((_, i) => {
          return {
            time: i,
            multiplier: 1,
          };
        });
      setDataToMap(testcreateArr);
    }

    if (selectedSlotType === 10) {
      setShowStartTime(false);
      setCustomTimeSlot(true);
      testcreateArr = Array(count)
        .fill()
        .map((_, i) => {
          return {
            time: i + 1,
            multiplier: 1,
          };
        });
      setDataToMap(testcreateArr);
    }
    setTimeSlot(true);
  };

  const handleChange = (event) => {
    // console.log("EVENT TARGET VALUE FROM Timeslot ==> ", event.target.value);
    console.log(event.target.value, "eventevent");
    if (event.target.value === 10) {
      // this will work when custom is selected in Time Slot Type*
      setShowStartTime(false);
      setTimeSlot(false);
      handlepriceData("timeslotType", "custom");
    }

    if (event.target.value === 12) {
      // this will work when 12 Hour is selected in Time Slot Type*
      setShowStartTime(true);
      setTimeSlot(false);
      setCustomTimeSlot(false);
      handlepriceData("timeslotType", "12 Hours");
    }

    if (event.target.value === 24) {
      // this will work when 24 Hour is selected in Time Slot Type*
      setShowStartTime(false);
      setTimeSlot(false);
      setCustomTimeSlot(false);
      handlepriceData("timeslotType", "24 Hours");
    }
    setSelectedSlotType(event.target.value);
  };

  const handleBasePriceChange = (e) => {
    // console.log("event target name ==> ", e.target.name);
    // console.log("event target value ==> ",  e.target.value);
    setBasePrice(e.target.value);
    handlepriceData("basePrice", e.target.value);
  };

  const handleAddChange = (value) => {
    if (dataToMap?.length == 24) {
      return;
    }
    setCount(value);

    // Check if dataToMap contains all digits from 1-12
    const containsAllDigits = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].every(
      (digit) => dataToMap?.map((a) => a.time).includes(digit)
    );
    if (containsAllDigits) {
      const dataValue = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].find(
        (x) => !dataToMap?.map((a) => a.time).includes(x)
      );
      // Set data for PM time
      setDataToMap([
        ...dataToMap,
        {
          time: dataValue == 24 ? 0 : dataValue,
          multiplier: 1,
        },
      ]);
    } else {
      // Set data for AM time
      const dataValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].find(
        (x) => !dataToMap?.map((a) => a.time).includes(x)
      );
      setDataToMap([
        ...dataToMap,
        {
          time: dataValue,
          multiplier: 1,
        },
      ]);
    }
  };
  const handleRemoveChange = (value, time, item) => {
    if (value == 0) {
      return;
    }
    setCount(value);
    // console.log("time need to be deleted ==> ", time);
    setDataToMap((current) =>
      current.filter((obj) => {
        if (obj.time !== time) {
          return obj;
        }
      })
    );
  };

  React.useEffect(() => {
    console.log("After Change ===> ", dataToMap);
    handlepriceData("timesArray", dataToMap);
  }, [dataToMap]);

  // Multiplier component added By Rahul

  const MultiplierComponent = ({ index, multiValue, viewsValue }) => {
    const handleMultiplierChange = (e) => {
      // console.log(
      //   "handleMultiplierChange event target name 56 ==> ",
      //   e.target.name
      // );
      // console.log("handleMultiplierChange event target value ==> ",  e.target.value);
      // console.log("Index of clicked item ", index);
      if (e.target.name === "views") {
        setDataToMap((current) =>
          current.map((obj) => {
            if (obj.time === index) {
              return {
                ...obj,
                ["views"]: Number(e.target.value),
                finalPrice: Math.floor(multiValue * basePrice),
                multiplier: multiValue,
              };
            }
            return obj;
          })
        );
        // setDataToMap({...dataToMap, views: e.target.value})
      }
      if (e.target.name === "multiplier") {
        setDataToMap((current) =>
          current.map((obj) => {
            if (obj.time === index) {
              return {
                ...obj,
                finalPrice: Math.floor(e.target.value * basePrice),
                multiplier: e.target.value,
              };
            }
            return obj;
          })
        );
      }
    };

    return (
      <>
        <Grid item xs={2.4} md={2.6} lg={2} justifyContent="flex-end">
          <FormControl className="MainPageFormControl mt10px " size="small">
            <TextField
              select
              variant="filled"
              InputProps={{ disableUnderline: true }}
              className="Selectdropstyle"
              labelId="demo-select-small"
              id="demo-select-small"
              name="multiplier"
              value={multiValue}
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleMultiplierChange}
            >
              <MenuItem value={0.8} className="Selectmenustyle">
                0.8
              </MenuItem>
              <MenuItem value={1.0}>1.0</MenuItem>
              <MenuItem value={1.25}>1.25</MenuItem>
              <MenuItem value={1.5}>1.5</MenuItem>
              <MenuItem value={1.75}>1.75</MenuItem>
              <MenuItem value={2.0}>2.0</MenuItem>
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={2.5} md={2.6} lg={2} justifyContent="flex-end">
          <Input
            className="inputstyle"
            disableUnderline
            placeholder="Enter final price"
            inputProps={{ style: { textAlign: "center" } }}
            value={Number(multiValue * basePrice)?.toFixed(2)}
          />
        </Grid>
      </>
    );
  };

  // custom Component for time Slot Type Custom
  const CustomComponent = ({ item, index }) => {
    const handleCustomTimeChange = (e) => {
      if (e) {
        if (e.target.name === "startTime") {
          let obj = {
            finalPrice: item.multiplier * basePrice,
            multiplier: item.multiplier,
            time: e.target.value,
            amPm: item.amPm,
          };
          let indexFind = dataToMap?.findIndex(
            (a) => a.time == obj.time && a.amPm == obj.amPm
          );
          if (indexFind != -1) {
            return;
          }
        }
        if (e.target.value === "PM") {
          let obj = {
            finalPrice: item.multiplier * basePrice,
            multiplier: item.multiplier,
            time: 12 + item.time,
            amPm: "PM",
          };
          let indexFind = dataToMap?.findIndex(
            (a) => a.time == obj.time && a.amPm == obj.amPm
          );

          if (indexFind != -1) {
            return;
          }
        }
        if (e.target.value === "AM") {
          let obj = {
            finalPrice: item.multiplier * basePrice,
            multiplier: item.multiplier,
            time: Number(item.time) - 12,
            amPm: "AM",
          };
          let indexFind = dataToMap?.findIndex(
            (a) => a.time == obj.time && a.amPm == obj.amPm
          );

          if (indexFind != -1) {
            return;
          }
        }
      }

      if (e.target.name === "startTime") {
        console.log("iam inside setDataToMap", item.amPm);
        setDataToMap((current) =>
          current.map((obj, i) => {
            if (i === index) {
              return {
                ...obj,
                finalPrice: item.multiplier * basePrice,
                multiplier: item.multiplier,
                time:
                  item.amPm == "PM"
                    ? Number(e.target.value) + 12
                    : e.target.value,
                amPm: item.amPm,
              };
            }
            return obj;
          })
        );
      }

      if (e.target.name === "startTimeAMPM" && e.target.value === "PM") {
        // console.log("startTimeAMPM PM target.value ==> ", e.target.value);
        setDataToMap((current) =>
          current.map((obj, i) => {
            if (i === index) {
              return {
                ...obj,
                finalPrice: item.multiplier * basePrice,
                multiplier: item.multiplier,
                time: 12 + item.time,
                amPm: "PM",
              };
            }
            return obj;
          })
        );
      }

      if (e.target.name === "startTimeAMPM" && e.target.value === "AM") {
        console.log("startTimeAMPM  AM target.value ==> ", e.target.value);
        setDataToMap((current) =>
          current.map((obj, i) => {
            if (i === index) {
              return {
                ...obj,
                finalPrice: Math.floor(item.multiplier * basePrice),
                multiplier: item.multiplier,
                time: item.time - 12,
                amPm: "AM",
              };
            }
            return obj;
          })
        );
      }
    };

    return (
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={1.8} sm={3} md={2.8} lg={2} xl={1.8}>
            <Grid
              container
              direction={isMd ? "column" : "row"}
              className=" inputstyle"
              justifyContent="space-between"
            >
              <Grid item xs={5.8}>
                <FormControl className="MainPageFormControl   " size="small">
                  <TextField
                    select
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle-noborder"
                    labelId="demo-select-small"
                    id="demo-select-small"
                    name="startTime"
                    inputProps={{ "aria-label": "Without label" }}
                    value={
                      item.time === 0
                        ? 12
                        : item.time > 12
                        ? item.time - 12
                        : item.time
                    }
                    onChange={(e) => {
                      handleCustomTimeChange(e);
                    }}
                  >
                    {minsarr && minsarr.length > 0 ? (
                      minsarr.map((x, i) => (
                        <MenuItem
                          key={i}
                          value={x}
                          disabled={
                            dataToMap[index]?.amPm == "AM" &&
                            dataToMap.map((a) => a.time)?.includes(x)
                              ? true
                              : dataToMap[index]?.amPm == "PM" &&
                                dataToMap?.map((a) => a.time)?.includes(x + 12)
                              ? true
                              : false
                          }
                        >
                          {x}
                        </MenuItem>
                      ))
                    ) : (
                      <h2>No Data</h2>
                    )}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={5.8}>
                <Typography className="greycolor505050500 fs16px">
                  <FormControl className="MainPageFormControl  " size="small">
                    <TextField
                      select
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      className="Selectdropstyle-noborder"
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="startTimeAMPM"
                      inputProps={{ "aria-label": "Without label" }}
                      value={item.time >= 12 ? "PM" : "AM"}
                      onChange={(e) => {
                        handleCustomTimeChange(e);
                      }}
                    >
                      <MenuItem value={"AM"}>AM</MenuItem>
                      <MenuItem value={"PM"}>PM</MenuItem>
                    </TextField>
                  </FormControl>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} md={1.8}>
            <Input
              className="inputstyle"
              disableUnderline
              value={
                item.time == 11
                  ? "12 PM"
                  : item.time < 12
                  ? `${item.time + 1} AM`
                  : item.time === 12
                  ? `${1} PM`
                  : item.time === 23
                  ? "12 AM"
                  : `${item.time + 1 - 12} PM`
              }
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Grid>

          <Grid item xs={1.8}>
            <Input
              className="inputstyle"
              disableUnderline
              value={basePrice}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Grid>

          <MultiplierComponent
            viewsValue={item.views}
            multiValue={item.multiplier}
            index={item.time}
          />
          <Grid item xs={6} sm={5} md={4} lg={1.8} justifyContent="flex-end">
            <Grid container item gap="10px">
              <Grid item>
                <Typography align="center">
                  <button
                    style={{
                      padding: "0px",
                      border: "none",
                      background: "white",
                    }}
                    onClick={() =>
                      handleRemoveChange(count - 1, item.time, item)
                    }
                  >
                    <HiOutlineMinusCircle
                      className="applypurplebtn_without_width "
                      style={{ color: "red" }}
                    />
                  </button>{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="center">
                  <button
                    style={{
                      padding: "0px",
                      border: "none",
                      background: "white",
                    }}
                    onClick={() => handleAddChange(count + 1)}
                  >
                    <MdAddCircleOutline className="applypurplebtn_without_width " />
                  </button>{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  ///////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className="box-shadow">
        <div className="admin-head">
          <Typography className="whitecolortypo500 fs16px">
            Time Slot Type*
          </Typography>
        </div>
        <div className="admin-content">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} md={2.4}>
              <Typography className="greycolor505050500 fs16px">
                Time Slot Type*
              </Typography>
              <FormControl className="MainPageFormControl mt10px " size="small">
                <TextField
                  select
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  className="Selectdropstyle"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={
                    priceData?.timeslotType === "24 Hours"
                      ? 24
                      : priceData?.timeslotType === "12 Hours"
                      ? 12
                      : priceData?.timeslotType === "custom"
                      ? 10
                      : 0
                  }
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleChange}
                >
                  <MenuItem value={0} className="Selectmenustyle ">
                    <Typography
                      className="blackcolortypo1c1c1c400 fs14px "
                      sx={{ height: "24px" }}
                    >
                      -Select-
                    </Typography>
                  </MenuItem>
                  <MenuItem value={24} className="Selectmenustyle">
                    <Typography
                      className="blackcolortypo1c1c1c400 fs14px "
                      sx={{ height: "24px" }}
                    >
                      24 Hours
                    </Typography>
                  </MenuItem>
                  <MenuItem value={12} className="Selectmenustyle">
                    <Typography
                      className="blackcolortypo1c1c1c400 fs14px "
                      sx={{ height: "24px" }}
                    >
                      {" "}
                      12 Hours
                    </Typography>
                  </MenuItem>
                  <MenuItem value={10} className="Selectmenustyle">
                    <Typography
                      className="blackcolortypo1c1c1c400 fs14px "
                      sx={{ height: "24px" }}
                    >
                      Custom
                    </Typography>
                  </MenuItem>
                </TextField>
              </FormControl>
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.timeslotType}
              </Typography>
            </Grid>

            {/* if 12 Hours is selected then show the startTime */}

            {showStartTime ? (
              <Grid item xs={4} md={2.4}>
                <Typography className="greycolor505050500 fs16px">
                  Start Time*
                </Typography>
                <Grid container className="mt10px inputstyle">
                  <Grid item xs={6}>
                    <FormControl
                      className="MainPageFormControl   "
                      size="small"
                    >
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle-noborder"
                        labelId="demo-select-small"
                        id="demo-select-small"
                        inputProps={{ "aria-label": "Without label" }}
                        value={startTime}
                        onChange={(e) => {
                          // console.log("startTime ==> ", e.target.value)
                          setStartTime(e.target.value);
                        }}
                      >
                        {minsarr && minsarr.length > 0 ? (
                          minsarr.map((x, i) => (
                            <MenuItem key={i} value={x}>
                              {x}
                            </MenuItem>
                          ))
                        ) : (
                          <h2>No Data</h2>
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      className="MainPageFormControl   "
                      size="small"
                    >
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle-noborder"
                        labelId="demo-select-small"
                        id="demo-select-small"
                        inputProps={{ "aria-label": "Without label" }}
                        value={selectTimePeriod}
                        onChange={(e) => {
                          // console.log("startTime ==> ", e.target.value)
                          handleTimePeriod(e.target.value);
                        }}
                      >
                        <MenuItem value={"AM"}>AM</MenuItem>
                        <MenuItem value={"PM"}>PM</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            <Grid item xs={4} md={2.4}>
              <Typography className="greycolor505050500 fs16px">
                Base Price*
              </Typography>
              <Input
                type="number"
                className="inputstyle"
                name="basePrice"
                disableUnderline
                placeholder="Enter Base Price"
                value={basePrice}
                onChange={handleBasePriceChange}
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.basePrice}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              md={2.4}
              className="mt10px"
              justifyContent="flex-end"
            >
              <Typography align="center" style={{ width: "100%" }}>
                <Button
                  className="applypurplebtn mt20px"
                  onClick={TimeSlotApply}
                >
                  Apply
                </Button>
                <Typography className="formError" variant={"subtitle2"}>
                  {formErrors.timesArray}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* =================  TimeSlot Mapping After Apply Button =============== */}
      {timeslot ? (
        <div>
          <div className="box-shadow">
            <div className="admin-head mt20px">
              <Typography className="whitecolortypo500 fs16px">
                Time Slot
              </Typography>
            </div>
            <div className="admin-content">
              <Grid container spacing={2}>
                <Grid container item>
                  {" "}
                  {customTimeSlot ? (
                    <Grid item xs={1.8} sm={3} md={2.8} lg={2} xl={1.8}>
                      <Typography className="greycolor505050500 fs16px">
                        From
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={2.2} md={2} lg={1}>
                      <Typography
                        align="center"
                        className="greycolor505050500 fs16px"
                      >
                        From
                      </Typography>
                    </Grid>
                  )}
                  {customTimeSlot ? (
                    <Grid item xs={2.2} md={1.8}>
                      <Typography
                        align="center"
                        className="greycolor505050500 fs16px"
                      >
                        To
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={2.2} md={2} lg={1}>
                      <Typography
                        align="center"
                        className="greycolor505050500 fs16px"
                      >
                        To
                      </Typography>
                    </Grid>
                  )}
                  {customTimeSlot ? (
                    <Grid item xs={1.8}>
                      <Typography
                        className="greycolor505050500 fs16px"
                        align="center"
                      >
                        Base Price
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={2.5} md={2.6} lg={2}>
                      <Typography
                        align="center"
                        className="greycolor505050500 fs16px"
                      >
                        Base Price
                      </Typography>{" "}
                    </Grid>
                  )}
                  <Grid item xs={2.5} md={2.6} lg={2} justifyContent="flex-end">
                    <Typography
                      align="center"
                      className="greycolor505050500 fs16px"
                    >
                      Multiplier
                    </Typography>{" "}
                  </Grid>
                  {customTimeSlot ? (
                    <Grid
                      item
                      xs={2.5}
                      md={2.6}
                      lg={2}
                      justifyContent="flex-end"
                    >
                      <Typography
                        align="center"
                        className="greycolor505050500 fs16px"
                      >
                        Final Price
                      </Typography>{" "}
                    </Grid>
                  ) : (
                    <Grid item xs={2.5} md={2.6} lg={2}>
                      <Typography
                        align="center"
                        className="greycolor505050500 fs16px"
                      >
                        Final Price
                      </Typography>{" "}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {/* ============= Mapping timeSlots Added by Rahul ============ */}

              {/* ===== TimeSlot Mapping For Custom Time SLot Type After Appy Button ===== */}
              {customTimeSlot ? (
                <>
                  {dataToMap?.map((item, index) => {
                    return <CustomComponent item={item} index={index} />;
                  })}
                </>
              ) : (
                <>
                  {dataToMap?.map((item, index) => {
                    // console.log("ITEM FROM MAP ==> ", item);
                    // console.log("Index from maP ==> ", index);
                    return (
                      <Grid container spacing={2}>
                        <Grid item xs={2.4} md={2} lg={1}>
                          {item.time < 12 ? (
                            <Input
                              className="inputstyle"
                              disableUnderline
                              value={
                                item.time === 0 ? "12 AM" : `${item.time} AM`
                              }
                            />
                          ) : (
                            <Input
                              className="inputstyle"
                              disableUnderline
                              value={
                                item.time === 12
                                  ? `${item.time} PM`
                                  : `${item.time - 12} PM`
                              }
                            />
                          )}
                        </Grid>
                        <Grid item xs={2.4} md={2} lg={1}>
                          {item.time < 12 ? (
                            <Input
                              className="inputstyle"
                              disableUnderline
                              value={
                                item.time === 1
                                  ? "2 AM "
                                  : item.time + 1 === 12
                                  ? `${item.time + 1} PM`
                                  : `${item.time + 1} AM`
                              }
                            />
                          ) : (
                            <Input
                              className="inputstyle"
                              disableUnderline
                              value={`${item.time + 1 - 12} PM `}
                            />
                          )}
                        </Grid>
                        <Grid item xs={2.3} md={2.6} lg={2}>
                          <Input
                            className="inputstyle"
                            disableUnderline
                            value={basePrice}
                            inputProps={{ style: { textAlign: "center" } }}
                          />
                        </Grid>
                        {/* <Grid item xs={2}>
                      <Input
                        className="inputstyle"
                        disableUnderline
                        placeholder="Enter total Views"
                      />
                    </Grid> */}
                        <MultiplierComponent
                          viewsValue={item.views}
                          multiValue={item.multiplier}
                          index={item.time}
                        />
                        {/* <Grid item xs={2} className="mt10px" justifyContent="flex-end">
                      <Typography align="center">
                        <Button className="applypurplebtn mt20px"> Set</Button>
                      </Typography>
                    </Grid> */}
                      </Grid>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
