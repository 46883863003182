import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Airport from "../../../../../assets/Img/Filters/airport.png";
import Airport_color from "../../../../../assets/Img/Filters/airport_color.png";
import Bar from "../../../../../assets/Img/Filters/bar.png";
import Bar_color from "../../../../../assets/Img/Filters/bar_color.png";
import BusInterior from "../../../../../assets/Img/Filters/busInterior.png";
import BusInterior_color from "../../../../../assets/Img/Filters/busInterior_color.png";
import BusShelter from "../../../../../assets/Img/Filters/busShelter.png";
import BusShelter_color from "../../../../../assets/Img/Filters/busShelter_color.png";
import BusStand from "../../../../../assets/Img/Filters/busStand.png";
import BusStand_color from "../../../../../assets/Img/Filters/busStand_color.png";
import Cinema from "../../../../../assets/Img/Filters/cinema.png";
import Cinema_color from "../../../../../assets/Img/Filters/cinema_color.png";
import CollegeCampus from "../../../../../assets/Img/Filters/collegeCampus.png";
import CollegeCampus_color from "../../../../../assets/Img/Filters/collegeCampus_color.png";
import ConvenienceStore from "../../../../../assets/Img/Filters/convenienceStore.png";
import ConvenienceStore_color from "../../../../../assets/Img/Filters/convenienceStore_color.png";
import FoodTruck from "../../../../../assets/Img/Filters/foodtruck.png";
import FoodTruck_color from "../../../../../assets/Img/Filters/foodtruck_color.png";
import GeneralStore from "../../../../../assets/Img/Filters/generalStore.png";
import GeneralStore_color from "../../../../../assets/Img/Filters/generalStore_color.png";
import GolfCourse from "../../../../../assets/Img/Filters/golfCourse.png";
import GolfCourse_color from "../../../../../assets/Img/Filters/golfCourse_color.png";
import Gym from "../../../../../assets/Img/Filters/gym.png";
import Gym_color from "../../../../../assets/Img/Filters/gym_color.png";
import Highway from "../../../../../assets/Img/Filters/highway.png";
import Highway_color from "../../../../../assets/Img/Filters/highway_color.png";
import Hospital from "../../../../../assets/Img/Filters/hospitallobby.png";
import Hospital_color from "../../../../../assets/Img/Filters/hospitallobby_color.png";
import Hotel from "../../../../../assets/Img/Filters/hotelLobby.png";
import Hotel_color from "../../../../../assets/Img/Filters/hotelLobby_color.png";
import LiquorWineBeerShop from "../../../../../assets/Img/Filters/liquorWineBeer-Shop.png";
import LiquorWineBeerShop_color from "../../../../../assets/Img/Filters/liquorWineBeer-Shop_color.png";
import MedicalStore from "../../../../../assets/Img/Filters/medicalStore.png";
import MedicalStore_color from "../../../../../assets/Img/Filters/medicalStore_color.png";
import MetroStationSubway from "../../../../../assets/Img/Filters/metroStation.png";
import MetroStationSubway_color from "../../../../../assets/Img/Filters/metroStation_color.png";
import Office from "../../../../../assets/Img/Filters/office.png";
import Office_color from "../../../../../assets/Img/Filters/office_color.png";
import PetrolPump from "../../../../../assets/Img/Filters/petrolPump.png";
import PetrolPump_color from "../../../../../assets/Img/Filters/petrolPump_colorr.png";
import RailwayStation from "../../../../../assets/Img/Filters/railwayStation.png";
import RailwayStation_color from "../../../../../assets/Img/Filters/railwayStation_color.png";
import Residential from "../../../../../assets/Img/Filters/residential.png";
import Residential_color from "../../../../../assets/Img/Filters/residential_color.png";
import RoadSide from "../../../../../assets/Img/Filters/roadSide.png";
import RoadSide_color from "../../../../../assets/Img/Filters/roadSide_color.png";
import Salon_Men from "../../../../../assets/Img/Filters/salon(Men).png";
import Salon_Men_color from "../../../../../assets/Img/Filters/salon(Men)_color.png";
import Salon_Men_Women from "../../../../../assets/Img/Filters/salon(MenWomen).png";
import Salon_Men_Women_color from "../../../../../assets/Img/Filters/salon(MenWomen)_color.png";
import Salon_Women from "../../../../../assets/Img/Filters/salon(Women).png";
import Salon_Women_color from "../../../../../assets/Img/Filters/salon(Women)_color.png";
import Saloon from "../../../../../assets/Img/Filters/saloon.png";
import Saloon_color from "../../../../../assets/Img/Filters/saloon_color.png";
import School from "../../../../../assets/Img/Filters/school.png";
import School_color from "../../../../../assets/Img/Filters/school_color.png";
import Shopping_Mall from "../../../../../assets/Img/Filters/shoppingMall.png";
import Shopping_Mall_color from "../../../../../assets/Img/Filters/shoppingMall_color.png";
import SportsComplex from "../../../../../assets/Img/Filters/sportsComplex.png";
import SportsComplex_color from "../../../../../assets/Img/Filters/sportsComplex_color.png";
import Stadium from "../../../../../assets/Img/Filters/stadium.png";
import Stadium_color from "../../../../../assets/Img/Filters/stadium_color.png";
import Subway from "../../../../../assets/Img/Filters/subway.png";
import Subway_color from "../../../../../assets/Img/Filters/subway_color.png";
import Taxi from "../../../../../assets/Img/Filters/taxi.png";
import Taxi_color from "../../../../../assets/Img/Filters/taxi_color.png";
import TransitShelter from "../../../../../assets/Img/Filters/transitShelter.png";
import TransitShelter_color from "../../../../../assets/Img/Filters/transitShelter_color.png";
import VetClinic from "../../../../../assets/Img/Filters/vetClinic.png";
import VetClinic_color from "../../../../../assets/Img/Filters/vetClinic_color.png";
import { createMuiTheme, useMediaQuery } from "@mui/material";
import Beauty from "../../../../../assets/Img/VenueImg/Beauty.png";
import Education from "../../../../../assets/Img/VenueImg/Education.png";
import Fashion from "../../../../../assets/Img/VenueImg/Fashion.png";
import Financial from "../../../../../assets/Img/VenueImg/Financial.png";
import Fitness from "../../../../../assets/Img/VenueImg/Fitness.png";
import Health from "../../../../../assets/Img/VenueImg/Health.png";
import Hospitality from "../../../../../assets/Img/VenueImg/Hospitality.png";
import Realty from "../../../../../assets/Img/VenueImg/Realty.png";
import RealtyCommercial from "../../../../../assets/Img/VenueImg/RealtyCommercial.png";
import Retail from "../../../../../assets/Img/VenueImg/Retail.png";
import Roads from "../../../../../assets/Img/VenueImg/Roads.png";
import Specialized from "../../../../../assets/Img/VenueImg/Specialized.png";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  useFilterController,
  setFilterData,
} from "../../../../../Context/filter";
const Venue = (props) => {
  const navigate = useNavigate();
  const [controller, dispatch] = useFilterController();
  const { filterData } = controller;
  // const [venueImg, setVenueImg] = useState([
  //   {
  //     name: "Airport",
  //     img: Airport,
  //     img_color: Airport_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Bar&Cafe",
  //     img: Bar,
  //     img_color: Bar_color,
  //     selected: false,
  //   },
  //   {
  //     name: "BusInterior",
  //     img: BusInterior,
  //     img_color: BusInterior_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Bus Shelter",
  //     img: BusShelter,
  //     img_color: BusShelter_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Bus Stand",
  //     img: BusStand,
  //     img_color: BusStand_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Cinema lobby",
  //     img: Cinema,
  //     img_color: Cinema_color,
  //     selected: false,
  //   },
  //   {
  //     name: "College Campus",
  //     img: CollegeCampus,
  //     img_color: CollegeCampus_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Convenience Store",
  //     img: ConvenienceStore,
  //     img_color: ConvenienceStore_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Food Truck",
  //     img: FoodTruck,
  //     img_color: FoodTruck_color,
  //     selected: false,
  //   },
  //   {
  //     name: "General Store",
  //     img: GeneralStore,
  //     img_color: GeneralStore_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Golf Course",
  //     img: GolfCourse,
  //     img_color: GolfCourse_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Gym",
  //     img: Gym,
  //     img_color: Gym_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Highway",
  //     img: Highway,
  //     img_color: Highway_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Hospital Lobby",
  //     img: Hospital,
  //     img_color: Hospital_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Hotel",
  //     img: Hotel,
  //     img_color: Hotel_color,
  //     selected: false,
  //   },
  //   {
  //     name: "LiquorWineBeerShop",
  //     img: LiquorWineBeerShop,
  //     img_color: LiquorWineBeerShop_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Medical Store",
  //     img: MedicalStore,
  //     img_color: MedicalStore_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Metro Station",
  //     img: MetroStationSubway,
  //     img_color: MetroStationSubway_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Office",
  //     img: Office,
  //     img_color: Office_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Petrol Pump",
  //     img: PetrolPump,
  //     img_color: PetrolPump_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Railway Station",
  //     img: RailwayStation,
  //     img_color: RailwayStation_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Residential ",
  //     img: Residential,
  //     img_color: Residential_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Road Side",
  //     img: RoadSide,
  //     img_color: RoadSide_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Salon Men",
  //     img: Salon_Men,
  //     img_color: Salon_Men_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Salon Men Women",
  //     img: Salon_Men_Women,
  //     img_color: Salon_Men_Women_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Salon Women",
  //     img: Salon_Women,
  //     img_color: Salon_Women_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Saloon",
  //     img: Saloon,
  //     img_color: Saloon_color,
  //     selected: false,
  //   },
  //   {
  //     name: "School",
  //     img: School,
  //     img_color: School_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Shopping_Mall",
  //     img: Shopping_Mall,
  //     img_color: Shopping_Mall_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Sports Complex",
  //     img: SportsComplex,
  //     img_color: SportsComplex_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Stadium",
  //     img: Stadium,
  //     img_color: Stadium_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Subway",
  //     img: Subway,
  //     img_color: Subway_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Taxi",
  //     img: Taxi,
  //     img_color: Taxi_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Transit Shelter",
  //     img: TransitShelter,
  //     img_color: TransitShelter_color,
  //     selected: false,
  //   },
  //   {
  //     name: "Vet Clinic",
  //     img: VetClinic,
  //     img_color: VetClinic_color,
  //     selected: false,
  //   },
  // ]);

  const [venueImg, setVenueImg] = useState([
    {
      name: "Beauty & Grooming",
      img: Beauty,
      img_color: Beauty,
      selected: false,
    },
    {
      name: "Education",
      img: Education,
      img_color: Education,
      selected: false,
    },
    {
      name: "Fashion",
      img: Fashion,
      img_color: Fashion,
      selected: false,
    },
    {
      name: "Financial",
      img: Financial,
      img_color: Financial,
      selected: false,
    },
    {
      name: "Fitness",
      img: Fitness,
      img_color: Fitness,
      selected: false,
    },
    {
      name: "Health",
      img: Health,
      img_color: Health,
      selected: false,
    },
    {
      name: "Hospitality",
      img: Hospitality,
      img_color: Hospitality,
      selected: false,
    },
    {
      name: "Realty Residential",
      img: Realty,
      img_color: Realty,
      selected: false,
    },
    {
      name: "RealtyCommercial",
      img: RealtyCommercial,
      img_color: RealtyCommercial,
      selected: false,
    },
    {
      name: "Retail",
      img: Retail,
      img_color: Retail,
      selected: false,
    },
    {
      name: "Roads & Highway",
      img: Roads,
      img_color: Roads,
      selected: false,
    },
    {
      name: "Specialized Retail",
      img: Specialized,
      img_color: Specialized,
      selected: false,
    },
  ]);

  const handleFilter = (data) => {
    let temp = filterData;
    let tempV = filterData.venue;

    if (tempV.length > 0) {
      let exits = tempV.find((name) => name == data);
      if (exits) {
        tempV = tempV.filter((name) => name != data);
      } else {
        tempV.push(data);
      }
    } else {
      tempV.push(data);
    }

    temp.venue = tempV;
    temp = JSON.stringify(temp);

    setFilterData(dispatch, JSON.parse(temp));
    if (props && props.locationName == "home") {
      navigate("/all-billboards");
    }
  };
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        tiny: 300,
        sm: 700,
        md: 900,
        lg: 1280,
        xl: 1920,
      },
    },
  });
  const isXs = useMediaQuery(theme.breakpoints.up("xs"));
  const isTiny = useMediaQuery(theme.breakpoints.up("tiny"));
  const iSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <>
      <Grid container item className="venue-box">
        <Swiper
          slidesPerView={
            isXl
              ? 10
              : isLg
              ? 8
              : isMd
              ? 5
              : iSm
              ? props?.forSm
                ? props?.forSm
                : 3
              : isTiny
              ? props.forXs
                ? props.forXs
                : 2
              : 2
          }
          spaceBetween={10}
          slidesPerGroup={1}
          loop={false}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          sx={{ width: "100px" }}
          className="browseByVenue"
        >
          {venueImg &&
            venueImg.map((item, index) => (
              <>
                <SwiperSlide key={index}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={index}
                  >
                    <img
                      src={
                        filterData.venue.includes(item.name)
                          ? item.img_color
                          : item.img
                      }
                      style={{
                        height: "60px",

                        width: "100px",

                        cursor: "pointer",
                        objectFit: "contain",
                      }}
                      onClick={() => {
                        let temp = venueImg;
                        temp.map((data) => {
                          if (data.name == item.name)
                            data.selected = !item.selected;
                        });
                        let temp2 = JSON.stringify(temp);
                        setVenueImg(JSON.parse(temp2));
                        handleFilter(item.name);
                      }}
                    />
                    {filterData.venue.includes(item.name) ? (
                      <CheckCircleRoundedIcon className="checkedVenue" />
                    ) : (
                      ""
                    )}
                  </Grid>
                </SwiperSlide>
              </>
            ))}
        </Swiper>
      </Grid>
    </>
  );
};

export default Venue;
