import * as React from "react";
import {
  Grid,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosInstance from "../../../../../api/axiosInstance";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "red" }} {...other}>
      {children}
      <Typography className="whitecolortypo">Delete </Typography>{" "}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DeleteDilogue({
  id,
  type,
  getCouponApi,
  setOpenDialog,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteHandler = async () => {
    setOpenDialog(null);
    let url;
    if (type == "voucher") {
      url = `/coupon/deleteSpecialCouponById/${id}`;
    } else {
      url = `/coupon/deleteCouponById/${id}`;
    }
    try {
      const res = await axiosInstance.delete(url);
      if (res.status == 200) {
        getCouponApi();
        setOpenDialog("success");
      } else {
      }
    } catch (err) {}
  };

  return (
    <div>
      <DeleteIcon
        onClick={handleClickOpen}
        className="danger_color cursor_pointer"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle> </BootstrapDialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "20px",
          }}
        >
          <Typography className="greycolor505050500">
            {" "}
            {` Are you sure you want to delete this ${type} ?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="  diallog-no-btn">
            No
          </Button>
          <Button
            onClick={() => {
              deleteHandler();
              handleClose();
            }}
            className="reject-btn width-25 "
            style={{ padding: "4px 0px" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
