import { Typography, Grid } from "@mui/material";
import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// pie chart
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import axiosInstance from "../../../../../../../../api/axiosInstance";
import { useEffect } from "react";
// pie chart
const options = {
  title: {
    display: true,
    text: "Donut Chart",
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  //   cutout: "70%",
};
const PieAnalytics = (props) => {
  const [value, setValue] = React.useState(null);
  const billboardId = props?.billBoardID;
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [slotsData, setSlotsData] = useState(null);
  const handleData = (data, datatype) => {
    setDate(moment(data).format("YYYY-MM-DD"));
  };
  console.log("DAte ", slotsData);
  const getOccupiedSlots = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getActiveFreeTimeSlot?billboardId=${billboardId}&date=${date}`
      );

      if (res.status == 200 || res.status == 204) {
        setSlotsData(res?.data?.data);
      }
    } catch (err) {
      console.log("error from getSlots analytics", err);
    }
  };
  useEffect(() => {
    if (!date) {
      return;
    }
    getOccupiedSlots();
  }, [date]);

  const data = {
    labels: ["freeHours", "occupiedHours"],
    datasets: [
      {
        data: [slotsData?.freeHours, slotsData?.occupiedHours],
        backgroundColor: ["#fed0eeb3", "#D0E8FF"],
        hoverBackgroundColor: ["#fed0eeb3", "#D0E8FF"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <Grid container justifyContent="space-between">
        <Typography className="gray5A5A5Atypo500 fs20px">Time Slot</Typography>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              InputProps={{
                disableUnderline: true,
              }}
              inputFormat="dd/MM/yyyy"
              value={date}
              onChange={(e) => {
                handleData(e, "date");
              }}
              renderInput={(params) => (
                <TextField {...params} style={{ width: "150px" }} />
              )}
              disableFuture={true}
            />
          </LocalizationProvider>
        </div>
      </Grid>
      <Grid container sx={{ width: "300px", marginTop: "50px" }}>
        <Doughnut data={data} options={options} />
      </Grid>
      <Grid container gap="20px">
        <Typography className="gray5A5A5Atypo500 fs20px">
          Availability
        </Typography>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Grid container>
              {" "}
              <span className="pink_block"></span>
              <Typography className="greyColorTypo717171_400">
                freeHours: {slotsData?.freeHours}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              {" "}
              <span className="blue_block"></span>
              <Typography className="greyColorTypo717171_400">
                occupiedHours: {slotsData?.occupiedHours}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PieAnalytics;
