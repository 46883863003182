import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Grid, Typography, TextField, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import axiosInstance from "../../../../api/axiosInstance";
import AnalyticsTab from "./AnalyticsTab";
import Calendar from "../../../../component/ReactDateRangePicker/ReactDateRangePickerFormonth";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import useExportExcel from "../../../../utils/useExportExcelFile";
// temp
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    xAxes: [{ stacked: true }],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        stacked: false,
      },
    ],
  },
};

const Analytics = (props) => {
  //states for billboard count
  const [billboardCount, setBillboardCount] = React.useState(null);
  const [orderCountDetails, setOrderCountDetails] = useState({});
  //states for billboard count
  // analytics states
  const [datasets, setDataSets] = useState([]);
  const [date, setDate] = useState();
  const [viewOrderAnalytics, setViewOrderAnalytics] = useState([]);
  const [revenue, setRevenueAnalytics] = useState([]);
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [femaleAnalystics, setfemaleAnalystics] = useState([]);
  const [orderType, setOrderType] = useState("All");
  const [countName, setcountName] = useState("Orders");
  const [graphType, setgraphType] = useState("maleViews");
  const [dataForTablePlayHour, setdataForTablePlayHour] = useState(null);
  const [acceptedOrderCount, setAcceptedOrderCount] = useState(0);
  const [AcceptedViewCount, setAcceptedViewCount] = useState(0);
  const [fileName, setfileName] = React.useState("OrderAnalytics");
  const [dataForTableMaleViews, setdataForTableMaleViews] = useState(null);
  const [dataForTableBookingAmount, setdataForTableBookingAmount] =
    useState(null);
  const [dataForTableOrder, setdataForTableOrder] = useState(null);
  const [dataForTableTotalViews, setdataForTableTotalViews] = useState(null);
  const [dataForTableFemaleViews, setdataForTableFemaleViews] = useState(null);
  const [AcceptedbookingCount, setAcceptedbookingCount] = useState(0);
  const [acceptedOrderAnalyticsData, setAcceptedOrderAnalyticsData] = useState(
    []
  );
  const [rejectedOrderAnalyticsData, setRejectedOrderAnalyticsData] = useState(
    []
  );
  const [monthLabel, setmonthLabel] = useState([]);
  const [testing, setTesting] = useState(props?.value === 0 ? false : true);
  const [publishedOrderAnalyticsData, setPublishedOrderAnalyticsData] =
    useState([]);
  // analytics states
  // analytics date and datasets
  const navigate = useNavigate();
  const { exportToExcel } = useExportExcel();

  const timeInHour = [
    "12:00 Am",
    "1:00 Am",
    "2:00 Am",
    "3:00 Am",
    "4:00 Am",
    "5:00 Am",
    "6:00 Am",
    "7:00 Am",
    "8:00 Am",
    "9:00 Am",
    "10:00 Am",
    "11:00 Am",
    "12:00 Pm",
    "1:00 Pm",
    "2:00 Pm",
    "3:00 Pm",
    "4:00 Pm",
    "5:00 Pm",
    "6:00 Pm",
    "7:00 Pm",
    "8:00 Pm",
    "9:00 Pm",
    "10:00 Pm",
    "11:00 Pm",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels:
      analyticsDate?.length == 24
        ? timeInHour
        : analyticsDate?.length == 12
        ? months
        : analyticsDate,
    datasets: datasets,
  };

  console.log("analyticsDate", analyticsDate?.length);

  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };
  console.log("selectDate", analyticsDate);
  // console.log("selectDate1", date);
  // analytics date and datasets
  // get analytics api
  const getAnalyticsData = async () => {
    try {
      let res = await axiosInstance.get(
        `api/order/getOrderAnalyticsForAdmin?isCampaign=false&isTesting=${testing}&startDate=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}`
      );

      console.log("response from getting order analytics data =>", res);
      if (res.status == 200 || res.status == 201) {
        //  setAcceptedOrderAnalyticsData(res?.data.data);
        if (orderType === "All") {
          setAcceptedOrderAnalyticsData(res?.data.data);
        }
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };
  console.log("selectTab", orderType);

  const getViewAnalyticsData = async () => {
    try {
      let body = {
        startDate: moment(date?.[0]?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.[0]?.endDate).format("YYYY-MM-DD"),
        isTesting: testing,
      };

      let res = await axiosInstance.post(`/api/order/orderViewStats`, body);
      console.log("response from getting order analytics data =>", res);
      if (res.status == 200 || res.status == 201) {
        setViewOrderAnalytics(res?.data?.msg);
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };
  console.log("graphType", graphType);
  const getViewfemale = async () => {
    try {
      let res = await axiosInstance.get(
        `/api/billboard/getOverallBillboardAnalytics?isCampaign=false&isTesting=${testing}&graphType=${graphType}&startDate=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}`
      );

      console.log("response from getting order analytics data =>", res);
      if (res.status == 200 || res.status == 201) {
        setfemaleAnalystics(res?.data?.data);
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };

  console.log("resFeamle", femaleAnalystics);
  useEffect(() => {
    if (!date) {
      return;
    }
    getViewAnalyticsData();
  }, [orderType, date]);
  // useEffect(() => {
  //   if (!date) {
  //     return;
  //   }
  //   getViewfemale();
  // }, [orderType, date, graphType]);

  const getrevenueAnalyticsData = async () => {
    try {
      let body = {
        startDate: moment(date?.[0]?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.[0]?.endDate).format("YYYY-MM-DD"),

        isTesting: testing,
      };
      let res = await axiosInstance.post(`/api/order/orderRevenueStats`, body);
      console.log("response from getting revenue analytics data =>", res);
      if (res.status == 200 || res.status == 201) {
        setRevenueAnalytics(res?.data?.msg);
      }
    } catch (err) {
      console.log("error from revenue anlytics data => ", err);
    }
  };
  // get analytics api
  // get order and billboard api
  const getCountOrder = async () => {
    try {
      let res = await axiosInstance.get(
        `/api/order/getordercount?isTesting=${testing}`
      );
      console.log(res, "order count response");
      if (res?.status === 200) {
        setOrderCountDetails(res?.data);
      }
    } catch (error) {
      console.log("error from GET Billboard Count ", error.message);
    }
  };
  const getCountBillboard = async () => {
    console.log("GET COUNT Billboard before try ==> ");
    try {
      console.log("== Inside Try Block ==");
      let res = await axiosInstance.get(
        `/api/billBoard/getbillboardcount?isTesting=${testing}`
      );

      console.log("RESPONSE FROM bollboard COUNT => ", res);
      setBillboardCount(res.data.msg);
    } catch (error) {
      console.log("error from GET Billboard Count ", error.message);
    }
  };
  // get order and billboard api
  // making an empty array and pushing all data in (data with 0 count)
  let acceptedData = [];
  let rejectedData = [];
  let publishedData = [];
  let viewsData = [];
  let revenueData = [];
  let femaleViewsData = [];
  let playHour = [];
  useEffect(() => {
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = acceptedOrderAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          approveOrder: acceptedOrderAnalyticsData[index].approveOrder,
          cancelOrder: acceptedOrderAnalyticsData[index].cancelOrder,
          expireOrder: acceptedOrderAnalyticsData[index].expireOrder,

          pendingOrder: acceptedOrderAnalyticsData[index].pendingOrder,
          publishOrder: acceptedOrderAnalyticsData[index].publishOrder,
          rejectOrder: acceptedOrderAnalyticsData[index].rejectOrder,
        };
        acceptedData.push(obj);
      } else {
        let obj = {
          Date: analyticsDate[i],
          expireOrder: 0,
          approveOrder: 0,
          cancelOrder: 0,

          pendingOrder: 0,
          publishOrder: 0,
          rejectOrder: 0,
        };
        acceptedData.push(obj);
      }
      setdataForTableOrder(acceptedData);
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = rejectedOrderAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          totalCount: rejectedOrderAnalyticsData[index].totalCount,
        };
        rejectedData.push(obj);
      } else {
        let obj = { _id: analyticsDate[i], totalCount: 0 };
        rejectedData.push(obj);
      }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = publishedOrderAnalyticsData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          _id: analyticsDate[i],
          totalCount: publishedOrderAnalyticsData[index].totalCount,
        };
        publishedData.push(obj);
      } else {
        let obj = { _id: analyticsDate[i], totalCount: 0 };
        publishedData.push(obj);
      }
    }

    for (let i = 0; i < analyticsDate.length; i++) {
      let index = viewOrderAnalytics?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          totalViews: viewOrderAnalytics[index].totalViews * 2,
        };
        viewsData.push(obj);
      } else {
        let obj = { Date: analyticsDate[i], totalViews: 0 };
        viewsData.push(obj);
      }
      setdataForTableTotalViews(viewsData);
    }
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = revenue?.findIndex((item) => item._id === analyticsDate[i]);
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          revenue: revenue[index].totalViews,
        };
        revenueData.push(obj);
      } else {
        let obj = { Date: analyticsDate[i], revenue: 0 };
        revenueData.push(obj);
      }
      setdataForTableBookingAmount(revenueData);
    }
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = femaleAnalystics?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          totalViews: femaleAnalystics[index].views * 2,
        };
        femaleViewsData.push(obj);
      } else {
        let obj = { Date: analyticsDate[i], totalViews: 0 };
        femaleViewsData.push(obj);
      }
      setdataForTableMaleViews(femaleViewsData);
    }
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = femaleAnalystics?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          videoDuration: femaleAnalystics[index].videoDuration / 60,
        };
        playHour.push(obj);
      } else {
        let obj = { Date: analyticsDate[i], videoDuration: 0 };
        playHour.push(obj);
      }
      setdataForTablePlayHour(playHour);
    }
  }, [
    acceptedOrderAnalyticsData,
    rejectedOrderAnalyticsData,
    publishedOrderAnalyticsData,
    viewOrderAnalytics,
    revenue,
    femaleAnalystics,
  ]);
  // making an empty array and pushing all data in (data with 0 count)
  // setting datasets
  useEffect(() => {
    if (orderType === "All") {
      setcountName("Orders");
      const firstData = acceptedOrderAnalyticsData?.map(
        (a) =>
          a.expireOrder +
          a.publishOrder +
          a.cancelOrder +
          a.pendingOrder +
          a.rejectOrder
      );
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setfileName("Orders Analytics");
      setDataSets([
        {
          label: "Expired Order",
          data: acceptedData?.map((a) => a?.expireOrder),
          borderColor: "#ff6f6f",
          backgroundColor: "#ff6f6f",

          borderRadius: 20,
          barThickness: 24,
        },
        {
          label: "Published",
          data: acceptedData?.map((a) => a?.publishOrder),
          borderColor: "rgb(215,35,205,100%)",
          backgroundColor: "rgba(215,35,205,100%)",

          borderRadius: 20,
          barThickness: 24,
        },

        {
          label: "Cancelled Order",
          data: acceptedData?.map((a) => a?.cancelOrder),
          borderColor: "#FFA500",
          backgroundColor: "#FFA500",

          borderRadius: 20,
          barThickness: 24,
        },

        {
          label: "Pending Order",
          data: acceptedData?.map((a) => a?.pendingOrder),
          borderColor: "#FFFF00",
          backgroundColor: "#FFFF00",

          borderRadius: 20,
          barThickness: 24,
        },
        {
          label: "Rejected Order",
          data: acceptedData?.map((a) => a?.rejectOrder),
          borderColor: "rgb(246,14,14,100%)",
          backgroundColor: "rgb(246,14,14,100%)",

          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "views") {
      setcountName("Views");
      setfileName("Total Views ");
      const firstData = viewOrderAnalytics?.map((a) => a.totalViews);
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem * 2);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setDataSets([
        {
          label: "View",
          data: viewsData?.map((a) => a?.totalViews),
          borderColor: "rgb(246,14,14,100%)",
          backgroundColor: "rgba(246,14,14,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "revenue") {
      setcountName("Booking Amount");
      setfileName("Booking Amount");
      const firstData = revenue?.map((a) => a.totalViews);
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setDataSets([
        {
          label: "revenue",
          data: revenueData?.map((a) => a?.revenue),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "female") {
      setcountName("Female Views");
      setfileName("Female Views");

      const firstData = femaleAnalystics?.map((a) => a.views);
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem * 2);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setDataSets([
        {
          label: "Female Views",
          data: femaleViewsData?.map((a) => a?.totalViews),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "male") {
      setcountName("Male Views");
      setfileName("Male Views");

      const firstData = femaleAnalystics?.map((a) => a.views);
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem * 2);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setDataSets([
        {
          label: "Male Views",
          data: femaleViewsData?.map((a) => a?.totalViews),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (orderType === "playHour") {
      setcountName("Play Hour");
      setfileName("Play Hour");

      const firstData = playHour?.map((a) => a.videoDuration);
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem);
      }, 0);

      setAcceptedOrderCount(firstDataNew);
      setDataSets([
        {
          label: "Play Hour",
          data: playHour?.map((a) => a?.videoDuration),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    }
  }, [
    orderType,
    acceptedOrderAnalyticsData,
    viewOrderAnalytics,
    rejectedOrderAnalyticsData,
    publishedOrderAnalyticsData,
    revenue,
    countName,
    femaleAnalystics,
  ]);
  // setting datasets

  useEffect(() => {
    if (date) {
      if (orderType === "views") {
        getViewAnalyticsData();

        return;
      }
      if (orderType === "revenue") {
        getrevenueAnalyticsData();

        return;
      }
      if (orderType === "female") {
        getViewfemale();

        return;
      }
      if (orderType === "male") {
        getViewfemale();

        return;
      }
      if (orderType === "playHour") {
        getViewfemale();

        return;
      }
      getAnalyticsData();
    }
  }, [orderType, date]);

  useEffect(() => {
    getCountBillboard();
    getCountOrder();
  }, []);

  const excelFileDownloader = () => {
    if (orderType === "views") {
      exportToExcel(dataForTableTotalViews, fileName);

      return;
    }
    if (orderType === "revenue") {
      exportToExcel(dataForTableBookingAmount, fileName);

      return;
    }
    if (orderType === "female") {
      exportToExcel(dataForTableMaleViews, fileName);

      return;
    }
    if (orderType === "male") {
      exportToExcel(dataForTableMaleViews, fileName);

      return;
    }
    if (orderType === "playHour") {
      exportToExcel(dataForTablePlayHour, fileName);

      return;
    }
    exportToExcel(dataForTableOrder, fileName);
  };

  return (
    <>
      <div className="width-90LR">
        <Grid
          container
          sx={{
            padding: "10px",
            marginBottom: "24px",
            borderRadius: "8px",
            boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
          }}
          rowGap="10px"
        >
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Billboard
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {billboardCount ? billboardCount : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">Orders</Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {orderCountDetails?.msg ? orderCountDetails?.msg : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">Campaign</Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {orderCountDetails?.campaignCount
                  ? orderCountDetails?.campaignCount
                  : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Gross Revenue
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                ₹ {orderCountDetails?.revenue ? orderCountDetails?.revenue : 0}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Total Views
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {orderCountDetails?.views ? orderCountDetails?.views * 2 : 0}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Male views
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {/* ₹{" "}
                {orderCountDetails?.views == 0 ? (
                  0
                ) : (
                  <>
                    {" "}
                    {(
                      orderCountDetails?.revenue / orderCountDetails?.views
                    )?.toFixed(2)}
                  </>
                )} */}
                {orderCountDetails?.maleViews
                  ? orderCountDetails?.maleViews * 2
                  : 0}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Female Views
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {orderCountDetails?.femaleViews
                  ? orderCountDetails?.femaleViews * 2
                  : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            direction="column"
            alignItems="center"
            gap="8px"
          >
            <Grid item>
              <Typography className="greycolortypo fs16px">
                Hours Play
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="blackcolor484848 fs24px">
                {orderCountDetails?.videoDuration
                  ? Math.floor(orderCountDetails?.videoDuration / 60)
                  : 0}{" "}
                hr
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div style={{ paddingBottom: "10px" }} className="width-90LR">
        <Grid
          container
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            paddingTop: "10px",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            rowGap="10px"
            sx={{ marginBottom: "10px" }}
          >
            <Grid item>
              <Grid container item sx={{ padding: "0 10px" }}>
                <FormControl
                  sx={{
                    minWidth: 300,
                    width: "100%",
                    fontSize: "10px",
                    border: "1px solid #DDDDDD",
                    fontFamily: "oswald",
                  }}
                  className="form_control_div"
                  size="small"
                >
                  <TextField
                    select
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle-noborder analytics_dropDown"
                    labelid="demo-select-small"
                    id="demo-select-small"
                    inputProps={{ "aria-label": "Without label" }}
                    value={orderType}
                    defaultValue={0}
                    onChange={(e) => {
                      setOrderType(e.target.value);
                      // getFilteredBillboards();
                    }}
                  >
                    <MenuItem value={"revenue"} className="Selectmenustyle">
                      <Typography className="Selectmenustyle fs16px  ">
                        Booking Amount
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"All"} className="Selectmenustyle">
                      <Typography className="Selectmenustyle fs16px  ">
                        Orders
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"views"} className="Selectmenustyle">
                      <Typography className="Selectmenustyle fs16px  ">
                        Total Views
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"female"} className="Selectmenustyle">
                      <Typography
                        className="Selectmenustyle fs16px "
                        onClick={() => {
                          setgraphType("femaleViews");
                        }}
                      >
                        Female Views
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"male"} className="Selectmenustyle">
                      <Typography
                        className="Selectmenustyle fs16px  "
                        onClick={() => {
                          setgraphType("maleViews");
                        }}
                      >
                        Male Views
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"playHour"} className="Selectmenustyle">
                      <Typography
                        className="Selectmenustyle fs16px  "
                        onClick={() => {
                          setgraphType("playHour");
                        }}
                      >
                        Play Hour
                      </Typography>
                    </MenuItem>
                  </TextField>
                </FormControl>
                <Typography
                  className="blackcolor484848 fs18px"
                  style={{ marginTop: "10px" }}
                >
                  Total {countName} :{" "}
                  {acceptedOrderCount ? Math.floor(acceptedOrderCount) : 0}{" "}
                  {orderType === "playHour" ? "hr" : ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                item
                gap="10px"
                sx={{ padding: "0 10px" }}
                alignItems="center"
              >
                <Calendar
                  getDataFromChildHandler={getDataFromChildHandler}
                  dataForTableOrder={dataForTableOrder}
                />
                <Button
                  sx={{ padding: "5px 20px" }}
                  className="purple_btn_primary text_transform_inherit"
                  onClick={() => {
                    excelFileDownloader();
                  }}
                >
                  Export
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Bar options={options} data={data} />
        </Grid>
      </div>

      <Grid container className="width-90LR" sx={{ margin: "16px 0" }}>
        <AnalyticsTab />
      </Grid>
    </>
  );
};

export default Analytics;
