import React from "react";
import { Container } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import PermissionUtils from "../component/permissionUtils";
export default function Admin() {
  const [updatedRoutesPermit] = PermissionUtils();
  return (
    <>
      <div
        style={{
          marginTop: "100px",
          marginLeft: "72px",
          backgroundColor: "#ffffff",
        }}
      >
        <Container maxWidth="xl">
          <Routes>
            {updatedRoutesPermit &&
              updatedRoutesPermit?.map((prop) => (
                <Route
                  exact
                  path={prop?.link}
                  element={prop?.Element}
                  key={prop?.key}
                />
              ))}
            <Route
              path="*"
              element={<Navigate replace={true} to="/admin/home" />}
            />
          </Routes>
        </Container>
      </div>
    </>
  );
}
