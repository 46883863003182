import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Input,
  InputLabel,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { addDays, subDays, differenceInDays } from "date-fns";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import permissionUtils from "../../../../../component/permissionUtils";
import BillboardPostDetails from "./Dialog/BillBoardPostDialog/BillboardPostDetails";
const PostButtonComponent = ({ billboardId, item, index, billBoardData }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const Role = userData?.user?.userRole;
  // states
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const [selectTimeArr, setSelectTimeErr] = useState("");
  const timeArrMsgHandler = (str) => {
    setSelectTimeErr(str);
  };
  const [date, setDate] = useState(Role == 2 || Role == 6 ? moment().add(1,'days').format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"));
  const [repeat, setRepeat] = useState(1);
  const [duration, setDuration] = useState(30);
  const [timeSlot, setTimeSlot] = useState(null);
  let minsarr = [];
  for (let i = 1; i <= 120; i++) {
    minsarr.push(i);
  }
  const handleData = (data, datatype) => {
    if (datatype == "date") {
      setDate(moment(data).format("YYYY-MM-DD"));
    }
  };
  // helper function
  const getTimeSlot = (value) => {
    setTimeSlot(value);
  };
  React.useEffect(() => {
    handleData(repeat, "repeat");
  }, [repeat]);
  useEffect(() => {
    setRepeat(1);
  }, [date]);

  return (
    <div className="border_primary" style={{ padding: "12px 20px" }}>
      <Grid item sx={{ marginBottom: "30px" }}>
        <Typography
          className="greycolortypo fs16px"
          sx={{ marginBottom: "4px" }}
        >
          Starting from
        </Typography>
        <Typography className="blackcolortypo fs18px ">
          <span className="fs18px"> &#8377; </span>
          {billBoardData.basePrice}/Second
        </Typography>

        <Grid item className="mt15px">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              InputProps={{
                disableUnderline: true,
              }}
              inputFormat="dd/MM/yyyy"
              value={date}
              onChange={(e) => {
                handleData(e, "date");
              }}
              // maxDate={new Date().setDate(new Date().getDate() + 60)}
              minDate={
                Role == 2 || Role == 6 ? addDays(new Date(), 1) : new Date()
              }
              renderInput={(params) => (
                <TextField {...params} className="width100 " />
              )}
              disablePast={true}
            />
          </LocalizationProvider>
        </Grid>
        <Grid container item alignItems="center" className="mt20px" gap="10px">
          <FormControl className="textfield-outline " fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select duration
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                duration ||
                (billBoardData?.durations?.length > 0
                  ? billBoardData.durations?.[0]
                  : 0)
              }
              label="Select duration"
              onChange={(e) => setDuration(e.target.value)}
            >
              {billBoardData &&
                billBoardData?.durations?.length > 0 &&
                billBoardData?.durations.map((data, index) => (
                  <MenuItem key={index} value={data ? data : 0}>
                    {data ? data : 0} second
                  </MenuItem>
                ))}
              {/* <MenuItem value={60}>60 second</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {PermissionCheck("post-ad-on-billboard") && (
        <BillboardPostDetails
          handleData={handleData}
          // repeat={repeat}
          date={date}
          timeArrMsgHandler={timeArrMsgHandler}
          duration={duration}
          // timeSlot={timeSlot}
          billBoardData={billBoardData}
          billboardId={billboardId}
          item={item}
          index={index}
        />
      )}
    </div>
  );
};

export default PostButtonComponent;
