import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ErrorMessage } from "@hookform/error-message";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { useForm, reset } from "react-hook-form";
import axiosInstance from "../../../../api/axiosInstance";
import CloseIcon from "@mui/icons-material/Close";
import DeleteTutorial from "./DeleteTutorial";

//React Icons

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitleConfirmation = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2 }} className="purple-gradient" {...other}>
      {children}
      <Typography className="whitecolortypo">Upload Tutorial </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 3,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitleConfirmation.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TutorialDialog({
  setSnackMsg,
  setSnackErrMsg,
  setSnackerropen,
  setSnackOpen,
  handleShowSnackbar,
  getTutorial,
  turorialList,
  getTutorialByid,
  tutorialDetailByid,
  deleteBurnerAd,
}) {
  const vidRef = useRef();
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");
  const [file, setFile] = useState(turorialList?.url ?? null);
  const [localFileUrl, setLocalFileUrl] = useState(null);
  const [mediafile, setMediafile] = useState(null);
  const [filetype, setFiletype] = useState(null);
  const [timmedname, setTrimmedname] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tutorialName, setTutorialName] = useState("");
  const [tutorialDescription, setTutorialDescription] = useState("");
  const [updateState, setUpdateState] = useState(turorialList ? true : false);

  useEffect(() => {
    if (tutorialDetailByid) {
      setTutorialDescription(tutorialDetailByid?.description ?? "");
      setTutorialName(tutorialDetailByid?.name ?? "");
      setFile(tutorialDetailByid?.url ?? null);
      setLocalFileUrl(tutorialDetailByid?.url ?? null);
      setFiletype(tutorialDetailByid?.fileType ?? null);
    }
  }, [tutorialDetailByid]);
  // ==================== States ======================== //
  const handleClickOpen = () => {
    setOpen(true);
    if (turorialList) {
      getTutorialByid(turorialList?._id);
    }
  };
  function ChangeInputCancel(e) {
    setUpdateState(false);
  }
  const handleClose = (event, reason) => {
    setOpen(false);
    setTutorialDescription("");
    setTutorialName("");
    if (!turorialList) {
      setFile(null);
      reset({
        tutorialName: "",
        tutorialDescription: "",
      });
    }
  };

  const onChangeFile = (event, index) => {
    if (index == 1) {
      setFile(event.target.files[0]);
    }
    setLocalFileUrl(URL.createObjectURL(event.target.files[0]));
    setMediafile(event.target.files?.[0]);
    setFiletype(event.target.files[0].type);
    let Milliseconds =
      String(new Date().getFullYear()) +
      String(new Date().getMonth()) +
      String(new Date().getDate()) +
      String(new Date().getHours()) +
      String(new Date().getMinutes()) +
      String(new Date().getMilliseconds());
    let a = event.target.files[0].name.split(".")[0];

    //=====================================================================================//
  };

  const submit = async () => {
    let formdata;
    setLoading(true);
    formdata = new FormData();
    formdata.append("name", tutorialName);
    formdata.append("description", tutorialDescription);
    formdata.append("file", mediafile);
    formdata.append("fileType", filetype);
    try {
      const res = await axiosInstance.post(
        "/api/tutorial/createTutorial",
        formdata
      );
      console.log(res);
      if (res.status == 200) {
        setSnackOpen(true);
        setSnackMsg(res?.data?.msg);
        setLoading(false);
        handleShowSnackbar("success");
        setLocalFileUrl(null);
        setFile(null);
        handleClose();
        getTutorial();
      } else {
        setSnackerropen(true);

        setSnackErrMsg(res?.data?.msg);
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (err) {
      setLoading(false);
      setSnackerropen(true);
      handleShowSnackbar("error");
      setSnackErrMsg(err?.response?.data?.msg);
    }
  };

  return (
    <>
      <div>
        {turorialList ? (
          <Button
            onClick={handleClickOpen}
            align="center"
            className="burner-details-btn"
            sx={{ padding: "12px" }}
          >
            View Details
          </Button>
        ) : (
          <IconButton
            onClick={handleClickOpen}
            variant="contained"
            size="large"
            component="span"
          >
            <AddCircleOutlineIcon sx={{ fontSize: "80px", color: "#E141C3" }} />
          </IconButton>
        )}

        <BootstrapDialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitleConfirmation
            onClose={handleClose}
          ></BootstrapDialogTitleConfirmation>
          <DialogContent>
            <form onSubmit={handleSubmit(submit)}>
              <Grid container spacing={2} sx={{ padding: "20px 20px" }}>
                <Grid item xs={5}>
                  {!file ? (
                    <>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        xs={12}
                        style={{
                          height: "250px",
                          position: "relative",
                          border: "1px solid #ddd",
                          background: "#F7F8FD",
                          borderRadius: "8px 8px 8px 8px",
                        }}
                      >
                        <input
                          style={{ display: "none" }}
                          type="file"
                          accept="video/mp4,image/jpeg,image/jpg"
                          id="raised-button-file"
                          value={file}
                          {...register("file-ErrorInput", {
                            required: "file is required.",

                            onChange: (e) => {
                              onChangeFile(e, 1);
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="file-ErrorInput"
                          render={({ message }) => (
                            <Typography className="redcolortypo400">
                              {message}
                            </Typography>
                          )}
                        />

                        <label htmlFor="raised-button-file">
                          <IconButton
                            variant="contained"
                            size="large"
                            component="span"
                          >
                            <AddCircleOutlineIcon
                              sx={{ fontSize: "80px", color: "#E141C3" }}
                            />
                          </IconButton>
                        </label>
                        <Typography
                          align="center"
                          className="blackcolor484848 fs20px"
                        >
                          Upload Video
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      container
                      style={{
                        position: "relative",
                        border: "1px solid #ddd",
                        background: "#F7F8FD",
                        borderRadius: "8px 8px 0px 0px",
                      }}
                      justifyContent="space-between"
                      rowGap="20px"
                    >
                      {localFileUrl ? (
                        <>
                          {filetype === "video/mp4" ? (
                            <video
                              controls
                              muted
                              autoPlay
                              ref={vidRef}
                              src={localFileUrl}
                              style={{
                                height: turorialList ? "310px" : "250px",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <>
                              {" "}
                              <img
                                src={localFileUrl}
                                style={{
                                  height: turorialList ? "310px" : "250px",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </>
                          )}
                        </>
                      ) : null}
                      {!updateState && (
                        <>
                          {loading ? null : (
                            <ClearIcon
                              className="red-icon fs40px ad_content_clear_Btn"
                              onClick={() => setFile(null)}
                            />
                          )}
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={7}>
                  <>
                    <Typography className="greycolor505050500 fs16px mt10px">
                      Tutorial Title
                    </Typography>
                    <input
                      className="inputstyle-custom"
                      disableUnderline
                      disabled={updateState}
                      value={tutorialName}
                      placeholder="enter the title of the tutorial"
                      {...register("tutorialName-Name-ErrorInput", {
                        required: "Tutorial Title is required.",

                        onChange: (e) => {
                          setTutorialName(e.target.value);
                        },
                      })}
                    />

                    <ErrorMessage
                      errors={errors}
                      name="tutorialName-Name-ErrorInput"
                      render={({ message }) => (
                        <Typography className="redcolortypo400">
                          {message}
                        </Typography>
                      )}
                    />
                    <Typography className="greycolor505050500 fs16px mt10px">
                      Tutorial Description
                    </Typography>
                    <textarea
                      className="inputstyle-custom"
                      placeholder="Enter the title of the tutorial"
                      value={tutorialDescription}
                      disabled={updateState}
                      {...register("tutorialDescription-Name-ErrorInput", {
                        required: "Tutorial Description is required.",
                        onChange: (e) => {
                          setTutorialDescription(e.target.value);
                        },
                      })}
                      rows={turorialList ? 3 : 6}
                    />

                    <ErrorMessage
                      errors={errors}
                      name="tutorialDescription-Name-ErrorInput"
                      render={({ message }) => (
                        <Typography className="redcolortypo400">
                          {message}
                        </Typography>
                      )}
                    />
                  </>
                  {turorialList && (
                    <Grid
                      item
                      xs={12}
                      className=" compaign-overview border_primary"
                    >
                      <Typography className="greycolor505050500 fs16px ">
                        Uploaded by :-
                      </Typography>
                      <Grid container mt={2}>
                        <Grid item md={6} sm={12} xs={6}>
                          <Typography className="secondary_graySubheading_500 fs14px">
                            Date :{" "}
                            {tutorialDetailByid?.createdAt?.split("T")[0]}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={6}>
                          <Typography className="secondary_graySubheading_500 fs14px ">
                            Name : {tutorialDetailByid?.createdBy?.firstName}
                          </Typography>
                        </Grid>

                        <Grid item md={6} sm={12} xs={4}>
                          <Typography className="secondary_graySubheading_500 fs14px mt10px">
                            Email : {tutorialDetailByid?.createdBy?.email}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={4}>
                          <Typography className="secondary_graySubheading_500 fs14px mt10px">
                            Phone Number :{" "}
                            {tutorialDetailByid?.createdBy?.mobileNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  sx={{ margin: "10px 0" }}
                  justifyContent="flex-end"
                  gap="20px"
                >
                  {turorialList ? (
                    <>
                      {" "}
                      <DeleteTutorial
                        dltFromDetail={true}
                        deleteBurnerAd={deleteBurnerAd}
                        id={turorialList?._id}
                        handleCloseDetail={handleClose}
                      />
                      <Button className="cancelbutton " onClick={handleClose}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button className="cancelbutton " onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button className=" uploadTutorial" type="submit">
                        {loading ? (
                          <CircularProgress color="inherit" size="14px" />
                        ) : (
                          "Upload"
                        )}
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}
