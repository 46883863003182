import React, { useState, useEffect } from "react";

import {
  Typography,
  DialogContent,
  Grid,
  Breadcrumbs,
  Box,
  TextField,
  MenuItem,
  FormControl,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import BillBoardTab from "./Billboard";
import LocationTab from "./Location";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const AnalyticsTab = () => {
  const [value, setValue] = React.useState(0);
  const [countryName, setCountryName] = useState(1);
  const [stateName, setStateName] = useState(1);
  const [city, setCity] = useState(1);
  // States

  const handleChange = (event, newValue) => {
    console.log("New Value ===>", newValue);

    setValue(newValue);
  };
  return (
    <div style={{ width: "100%", marginBottom: "20px" }}>
      <Grid container>
        <Grid
          container
          className="device-container "
          sx={{
            width: "100%",
            boxShadow: " 0px 1px 10px 0px #0000001A ",
          }}
        >
          <Grid
            item
            style={{
              width: "100%",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              className="TabStyleAddDevice"
            >
              <Tab label="Location" className="TabChangesDevice " />
              {/* <Tab
                label="Billboard (3422)"
                className="TabChangesDevice"
                disabled
              /> */}
            </Tabs>
          </Grid>
        </Grid>

        <DialogContent style={{ padding: "0px" }}>
          <TabPanel value={value} index={0} style={{ padding: "0px" }}>
            <LocationTab />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <BillBoardTab />
          </TabPanel> */}
        </DialogContent>
      </Grid>
    </div>
  );
};

export default AnalyticsTab;
