import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  InputBase,
  Snackbar,
  Alert,
} from "@mui/material";
import checkOutImage from "../../../../../assets/Img/checkout.png";
import refundImage from "../../../../../assets/Img/refund.png";
import excitingRewards from "../../../../../assets/Img/rewards.png";
import walletImage from "../../../../../assets/Img/3d_wallet_10 1.png";
import SuccessDialog from "../../../../Home/UserMgt/UserMgtSuccessDialogue/SuccessDialogue";
import { FETCH_URL } from "../../../../../fetchIp";
import axiosInstance from "../../../../../api/axiosInstance";
const AddToWalletPageHandler = ({
  addMoneyToWalletPageHandler,
  individualUserData,
}) => {
  // states
  const [creditedAmount, setCreditedAmount] = useState("");
  const [openDialogName, setOpenDialog] = React.useState(null);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  let adminId = JSON.parse(localStorage.getItem("userData")).user._id;

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  //addToWalletData
  const addToWallet = async () => {
    try {
      const res = await axiosInstance.post(`/api/payment/addMoneyToWallet`, {
        userId: individualUserData?._id,
        // adminId,
        creditedAmount: +creditedAmount,
      });
      if (res?.status == 200 || res?.status == 201) {
        console.log("add to wallet response  ===>", res?.msg);
        setSnackOpen(true);
        setSnackMsg(res?.msg);
        setOpenDialog("success");
      } else {
        console.log("Error in add to wallet coupon ==> ", res?.msg);
        setSnackerropen(true);
        setSnackErrMsg(res?.msg);
      }
    } catch (error) {
      console.log("Error in adding wallet  => ", error?.message);
      setSnackerropen(true);
      setSnackErrMsg("Not have permission to add money");
    }
  };

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={8000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          className="purple-gradient whitecolortypo500 relative_Grid fs16px"
          style={{ borderRadius: "8px 8px 0px 0px", padding: "10px 0" }}
          justifyContent="center"
        >
          <Typography>Deposit</Typography>
          <Button
            onClick={() => addMoneyToWalletPageHandler(false)}
            className="absolute_btn fs16px"
          >
            Back
          </Button>
        </Grid>
      </Grid>
      {/* Postmyad Wallet */}
      <Grid
        container
        item
        xs={12}
        style={{
          padding: "20px 0",
        }}
      >
        <Grid
          container
          item={12}
          alignItems="center"
          direction="column"
          sx={{ padding: "20px 0" }}
        >
          <Grid item>
            <img src={walletImage} alt="walletImage" />
          </Grid>
          <Grid item>
            <Typography className="purplecolortypo fs24px">
              Postmyad Wallet
            </Typography>
          </Grid>
          <Grid item>
            {" "}
            <Typography className="blackcolor525252_400 fs16px">
              An easy way to pay and get refund
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Instant
Checkout,Exciting
Rewards */}
      <Grid container justifyContent="center" style={{ padding: "20px 0" }}>
        <Grid container item xs={3} direction="column" alignItems="center">
          <img
            src={checkOutImage}
            alt="checkOutImage"
            style={{ width: "15%", marginBottom: "5px" }}
          />
          <Typography className="textAlign_Center blackcolor525252_400 fs16px fontWeight_500">
            Instant
          </Typography>
          <Typography className="textAlign_Center blackcolor525252_400 fs16px fontWeight_500">
            Checkout
          </Typography>
        </Grid>
        <Grid container item xs={3} direction="column" alignItems="center">
          <img
            src={refundImage}
            alt="refundImage"
            style={{ width: "15%", marginBottom: "5px" }}
          />
          <Typography className="textAlign_Center blackcolor525252_400 fs16px fontWeight_500">
            Faster
          </Typography>
          <Typography className="textAlign_Center blackcolor525252_400 fs16px fontWeight_500">
            Refund
          </Typography>
        </Grid>
        <Grid container item xs={3} direction="column" alignItems="center">
          <img
            src={excitingRewards}
            alt="excitingRewards"
            style={{ width: "15%", marginBottom: "5px" }}
          />
          <Typography className="textAlign_Center blackcolor525252_400 fs16px fontWeight_500">
            Exciting
          </Typography>
          <Typography className="textAlign_Center blackcolor525252_400 fs16px fontWeight_500">
            Rewards
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          padding: "20px 0",
          borderTop: "1px solid #E4EFE6",
          borderBottom: "1px solid #E4EFE6",
        }}
      >
        <Grid container item xs={4} gap="5px">
          <Typography className="greycolor505050500 fs18px">
            Reciever Name :
          </Typography>
          <Typography className="blackcolortypo fs18px">
            {" "}
            {individualUserData?.firstName} {individualUserData?.lastName}
          </Typography>
        </Grid>
        <Grid container item xs={4} justifyContent="center" gap="5px">
          <Typography className="greycolor505050500 fs18px">
            Reciever E-mail :{" "}
          </Typography>
          <Typography className="blackcolortypo fs18px">
            {individualUserData?.email}
          </Typography>
        </Grid>
        <Grid container item xs={4} justifyContent="flex-end" gap="5px">
          <Typography className="greycolor505050500 fs18px">
            Reciever Phone Number :{" "}
          </Typography>
          <Typography className="blackcolortypo fs18px">
            {individualUserData?.mobileNo
              ? individualUserData?.mobileNo
              : "xxxxxxxxxx"}
          </Typography>
        </Grid>
      </Grid>
      {/* add PostMyAdWallet Balance */}
      <Grid
        container
        justifyContent="space-between"
        style={{
          // borderTop: "1px solid #E4EFE6",
          // marginTop: "40px",
          padding: "40px 0",
        }}
      >
        <Grid container item xs={3} gap="20px" direction="column">
          <Typography className="blackcolortypo500 fs18px">
            Available Postmyad Wallet Balance{" "}
          </Typography>
          <Typography className="purplecolortypo-500 fs24px">
            &#x20b9; 5000
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <InputBase
            style={{ border: "1px solid red" }}
            className="AddToWallet_Input"
            placeholder="&#x20b9; Enter an amount e.g(1000)"
            value={creditedAmount}
            onChange={(e) => setCreditedAmount(e.target.value)}
          ></InputBase>
        </Grid>
        <Grid container item xs={3} justifyContent="flex-end">
          <Button className="purple_btn" onClick={addToWallet}>
            Add Money to Wallet
          </Button>
        </Grid>
      </Grid>
      <SuccessDialog
        openData={openDialogName === "success"}
        data={"Money Is Added To The Wallet "}
        setAddToWalletPageHandler={addMoneyToWalletPageHandler}
      />
    </>
  );
};

export default AddToWalletPageHandler;
