import React, { useEffect, useState } from "react";

import {
  Typography,
  Grid,
  Card,
  IconButton,
  CardActions,
  Chip,
  ListItem,
} from "@mui/material";
// Core Images
import Kidsk from "../../../../assets/Img/KIOSK.png";
import Window from "../../../../assets/Img/WINDOW.png";
import StandingDisplay from "../../../../assets/Img/StandingDisplay.png";
import WallMounted from "../../../../assets/Img/WallMounted.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import { useBillboard } from "../../../../Context/AddBillboardContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Submit({ formdata, file, AllbillboardData }) {
  //=======================================================================//
  const values = Object.values(file).filter((a) => !!a);
  console.log(AllbillboardData, "formdataformdata");
  return (
    <>
      {" "}
      <Snackbar
      // open={snackopen}
      // autoHideDuration={3000}
      // onClose={SnackBarClose}
      >
        {/* <Alert onClose={SnackBarClose} severity={"error"}>
          {snackMsg && snackMsg}
        </Alert> */}
      </Snackbar>
      <div className="admin-head">
        <Typography className="whitecolortypo500 fs16px">
          Time Slot Type*
        </Typography>
      </div>
      <div className="admin-content">
        <Grid container spacing={3} className="mt10px">
          {values?.map((a, i) => (
            <>
              {(typeof a == "object" && a?.type == "video/mp4") ||
              (typeof a !== "object" &&
                a?.split(".")?.[a?.split(".").length - 1] == "mp4") ? (
                <Grid item xs={12} sm={6} md={3} key={i}>
                  <Card className="billboardImage-card ">
                    <video
                      src={typeof a == "object" ? URL.createObjectURL(a) : a}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      autoPlay
                      muted
                      loop
                    />
                  </Card>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={3} key={i}>
                  <Card className="billboardImage-card ">
                    <img
                      loading="lazy"
                      src={typeof a == "object" ? URL.createObjectURL(a) : a}
                      alt="WallMounted "
                      className="width100"
                    />
                  </Card>
                </Grid>
              )}
            </>
          ))}
        </Grid>

        <Grid container className="mt10px" rowGap="20px">
          <Grid item xs={12} md={6}>
            <Typography
              component="h5"
              className="blackcolortypo Detail fs20px mt10px"
            >
              Billboard Detail
            </Typography>
            <Grid container className="mt25px">
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo ">
                  Billboard ID
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 ">
                  {formdata?.billboardId}
                </Typography>
              </Grid>
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  BIllboard Name
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 mt10px">
                  {formdata?.billboardName}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Owner
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 mt10px">
                  {formdata?.billboardOwner}{" "}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Size
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 mt10px">
                  {formdata?.billboardSize}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Address
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 mt10px">
                  {formdata?.fullAddress}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="h5"
              className="blackcolortypo Detail fs20px mt10px pl20px"
            >
              Device Detail
            </Typography>
            <Grid container className="mt25px pl20px">
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo ">
                  Device ID
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 ">
                  {AllbillboardData?.deviceId?.deviceUID}
                </Typography>
              </Grid>
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Device Name
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 mt10px">
                  {AllbillboardData?.deviceId?.deviceName}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Device Sensors
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <ListItem className="">
                  <Chip
                    className="addbilsub-chipstyle "
                    //label="Proximity Sensor"
                    label="NA"
                    variant="outlined"
                    clickable={true}
                  />{" "}
                  <Chip
                    className="addbilsub-chipstyle "
                    sx={{ ml: 3 }}
                    //label="Proximity Sensor"
                    label="NA"
                    variant="outlined"
                    clickable={true}
                  />{" "}
                </ListItem>
              </Grid>{" "}
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="mt20px">
          <Grid item xs={12} md={6}>
            <Typography
              component="h5"
              className="blackcolortypo Detail fs20px mt10px"
            >
              Billboard Type
            </Typography>
            <Grid container className="mt25px ">
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo ">
                  Billboard Location
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 ">
                  {formdata?.locationType}
                </Typography>
              </Grid>
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Type
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 mt10px">
                  {formdata?.billboardType}
                </Typography>
              </Grid>{" "}
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Venue
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 mt10px">
                  {formdata?.venueTags}
                </Typography>
              </Grid>{" "}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="h5"
              className="blackcolortypo Detail fs20px mt10px pl20px"
            >
              Billboard Insight
            </Typography>
            <Grid container className="mt25px pl20px">
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo ">
                  Billboard Time slot
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 ">
                  {formdata?.timeslotType}
                </Typography>
              </Grid>
              <Grid item md={4} xs={6}>
                <Typography className="lightgreycolortypo mt10px ">
                  Billboard Base Price
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <Typography className="greycolor505050500 mt10px">
                  ₹ {formdata?.basePrice} /sec
                </Typography>
              </Grid>{" "}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
