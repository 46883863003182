import {
  Breadcrumbs,
  Button,
  Grid,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { BsInfoSquare } from "react-icons/bs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { Link, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import axiosInstance from "../../../../../../api/axiosInstance";
import axiosInstance from "../../../api/axiosInstance";
import ReactPlayer from "react-player";
// import LoaderDialogue from "../../../../../../component/LoaderDialogue/LoaderDialogue";
import LoaderDialogue from "../../../component/LoaderDialogue/LoaderDialogue";
import DeleteDilogue from "./DeleteDialogue";
import DeleteAllBurnerAd from "./ActionDialogue/DeleteAllBurnerAd";
import { FiUploadCloud } from "react-icons/fi";
import { FETCH_URL } from "../../../fetchIp";
import PermissionUtils from "../../../component/permissionUtils";
const AddBurnerAd = () => {
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    PermissionUtils();
  const vidRef = useRef();
  let userD = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [timmedname, setTrimmedname] = useState(null);
  const [file, setFile] = useState(null);
  const [localFileUrl, setLocalFileUrl] = useState(null);
  console.log("localFileUrl", localFileUrl);
  const [zipfilename, setZipfilename] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [filetype, setFiletype] = useState(null);
  const [mediafile, setMediafile] = useState(null);
  const [imageoriginalname, setImageoriginalname] = useState("");
  const [imagestring, setImagestring] = useState("");
  const [resBurnerAds, setResBurnerAds] = useState(null);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [snackerropen, setSnackerropen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [burnerToggle, setburnerToggle] = useState();
  const [labelToggle, setLabeltoggle] = useState("");
  const [allBurnerKey, setallBurnerKey] = useState();
  const [burnerAdStatus, setBurnerAdStatus] = useState(false);
  useEffect(() => {
    if (burnerAdStatus) {
      setburnerToggle(burnerAdStatus);
      setLabeltoggle("Download Global Burner Ads");
    }
  }, [burnerAdStatus]);

  const onClickBurnerToggle = (e) => {
    const newBurnerToggle = !burnerToggle;
    setburnerToggle(newBurnerToggle);
    setLabeltoggle(newBurnerToggle ? "Download Global Burner Ads" : "");
    changeBurnerAdsStatus(newBurnerToggle); // Pass the new state as the status
  };

  const getBurnerAdStatus = async () => {
    try {
      const res = await axiosInstance.get(
        `api/billboard/getGlobalBurnerAdStatus`
      );
      //.log(res, "res");
      if (res.status == 200 || res.status == 201) {
        console.log("res for status", res);
        setBurnerAdStatus(res?.data?.data.isGlobal);
        setburnerToggle(res?.data?.data.isGlobal);
      } else {
        //.log("error getting burnerVdo res", res.msg);
      }
    } catch (error) {
      //.log(error, "error");
    }
  };
  const changeBurnerAdsStatus = async (status) => {
    let body = {
      status, // Use the status passed as an argument
    };
    try {
      const res = await axiosInstance.post(
        `api/billboard/changeGlobalBurnerAdStatus `,
        body
      );
      if (res.status == 200 || res.status == 201) {
        getBurnerAdStatus();
        setSnackOpen(true);
        setSnackMsg("Status Updated");
        handleShowSnackbar("success");
      }
    } catch (error) {
      //.log(error, "error");
      handleShowSnackbar("error");
    }
  };
  useEffect(() => {
    getBurnerAdStatus();
  }, []);
  const navigate = useNavigate();
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };
  const onChangeFile = (event, index) => {
    if (event.target.files[0].type == "video/mp4") {
      if (index == 1) {
        setFile(event.target.files[0]);
      }
      setLocalFileUrl(URL.createObjectURL(event.target.files[0]));
      setMediafile(event.target.files?.[0]);
      setFiletype(event.target.files[0].type);
      let Milliseconds =
        String(new Date().getFullYear()) +
        String(new Date().getMonth()) +
        String(new Date().getDate()) +
        String(new Date().getHours()) +
        String(new Date().getMinutes()) +
        String(new Date().getMilliseconds());
      let a = event.target.files[0].name.split(".")[0];
      let trimmedstr = a
        .replace(/[&\/\\_#,^!-@+()$~%'":-?<>{}\s+]/g, "")
        .concat(Milliseconds);
      setTrimmedname(`${userD.user._id}_${trimmedstr}`);
      setZipfilename(a); //==> to get original name of the uploaded
      let lastExtensionLength = event.target.files[0].name?.split(".")?.length;
      let extension = ".".concat(
        event.target.files[0].name.split(".")?.[lastExtensionLength - 1]
      );
      console.log("Extension ===>", extension);
      trimmedstr = `${userD.user._id}_${trimmedstr}`;
      let filename = trimmedstr.concat(extension); //==> to set the uniquename  of the file
      console.log("Final Name of the File => ", filename);
      setFileName(filename);
    }
    //=====================================================================================//
  };
  const handleUploadimage = async () => {
    setLoading(true);
    console.log("//================ All Video Data Available ==============//");
    const formdata = new FormData();
    formdata.append("zipfilename", zipfilename); //==> original zipfilename
    formdata.append("filetype", filetype);
    formdata.append("uniqueName", fileName);
    formdata.append("uniquename", timmedname);
    formdata.append("macArr", ["*"]);
    formdata.append("file", mediafile); //===> mediafile
    const response = await fetch(`${FETCH_URL}/api/device/addBurnerAd`, {
      method: "POST",
      body: formdata,
    });
    let res = await response.json();
    if (response.ok) {
      console.log("Handle off response sfter vdo upload  ===>", res);
      getBurnerAd();
      setSnackMsg("Content Has been uploaded");
      setLoading(false);
      handleShowSnackbar("success");
      setLocalFileUrl(null);
      setFile(null);
    } else {
      console.log("error in handle off ==> ", res.msg);
      setSnackerropen(true);
      // setOpenDialog("reject");
      setSnackErrMsg(res.msg);
      setLoading(false);
    }
  };

  const getBurnerAd = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/device/getBurnerAdsOriginalVideos`
      );
      console.log(res, "res");
      if (res.status == 200 || res.status == 201) {
        setResBurnerAds(res?.data?.msg);
        setallBurnerKey(res?.data?.msg?.map((item) => item?.Key));
      } else {
        console.log("error getting burnerVdo res", res.msg);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const deleteAllBurnerAd = async (key) => {
    setLoading(true);
    let body = {
      key: key,
    };
    try {
      const res = await axiosInstance.post(
        `/api/billboard/deleteAllGlobalBurnerAd`,
        body
      );
      if (res.status == 200 || res.status == 201) {
        setSnackOpen(true);
        setSnackMsg("Videos Has been deleted");
        setLoading(false);
        getBurnerAd();
        handleShowSnackbar("success");
      } else {
        setSnackErrMsg("Something goes wrong");
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (error) {
      //.log(error, "error");
      handleShowSnackbar("error");
      setLoading(false);
    }
  };
  // delete api
  const deleteBurnerAd = async (key) => {
    setLoading(true);
    try {
      const res = await axiosInstance.delete(
        `api/device/deleteBurnerAd?key=${key}`
      );
      if (res.status == 200 || res.status == 201) {
        setSnackOpen(true);
        setSnackMsg("Video Has been deleted");
        setLoading(false);
        getBurnerAd();
        handleShowSnackbar("success");
      } else {
        setSnackErrMsg(res.msg);
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (error) {
      console.log(error, "error");
      handleShowSnackbar("error");
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   if (billBoardData?.deviceId?.macId) {
  //     getBurnerAd();
  //   }
  // }, [billBoardData]);
  useEffect(() => {
    getBurnerAd();
  }, []);
  console.log("=========================>resBurnerAds", resBurnerAds);
  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>{" "}
      <div className="width-90LR ">
        <div
          className="flex-class"
          style={{ margin: "16px 0", marginTop: "90px" }}
        >
          <Grid container direction="row" justifyContent="start">
            <KeyboardBackspaceIcon
              onClick={() => navigate(-1)}
              style={{ marginRight: "8px", textAlign: "center" }}
            ></KeyboardBackspaceIcon>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                to="/admin/home"
                className="linkcolor"
                underline="hover"
                key="1"
              >
                <Typography className="purplecolortypo fs16px">
                  Dashboard
                </Typography>
              </Link>
              ,
              <Link
                to="/admin/billboardscreens"
                className="linkcolor"
                underline="hover"
                key="2"
                color="inherit"
                href="/material-ui/getting-started/installation/"
              >
                <Typography className="purplecolortypo fs16px">
                  All Smart BillBoard
                </Typography>
              </Link>
              ,,
              <Link
                to="#"
                className="linkcolor"
                underline="hover"
                key="2"
                color="inherit"
                href="/material-ui/getting-started/installation/"
              >
                <Typography className="blackcolortypo fs16px">
                  Add Burner Ads
                </Typography>
              </Link>
              ,
            </Breadcrumbs>
          </Grid>
        </div>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography className="blackcolor484848 fs24px">
            Add Burner Ads
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          mt={1}
          alignItems="center"
        >
          <Typography className="type-info fs13px">
            <BsInfoSquare /> Burner Ad are Ads which plays automatically if
            there are no Paid ads playing on billboard
          </Typography>
          {PermissionCheck("change-burner-ad-status") && (
            <Grid item>
              <Grid
                container
                item
                gap="10px"
                sx={{
                  padding: "8px 10px ",
                  borderRadius: "8px",
                }}
              >
                {/*  */}
                <label class="toggle">
                  <input
                    checked={burnerToggle}
                    class="toggle-checkbox"
                    type="checkbox"
                    value=""
                    onClick={(e) => {
                      onClickBurnerToggle(e);
                    }}
                  />
                  <div class="toggle-switch"></div>
                </label>{" "}
                <Typography className="blackcolor484848 fs18px">
                  Download Global Burner Ads
                </Typography>
                <DeleteAllBurnerAd
                  deleteAllBurnerAd={deleteAllBurnerAd}
                  allBurnerKey={allBurnerKey}
                />
              </Grid>{" "}
            </Grid>
          )}
        </Grid>
        <Grid container mt={3} gap={3} className="relative">
          <Grid item xs={12} sm={5.8} md={2.8}>
            {!file ? (
              <>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  xs={12}
                  style={{
                    height: "250px",
                    position: "relative",
                    border: "1px solid #ddd",
                    background: "#F7F8FD",
                    borderRadius: "8px 8px 0px 0px",
                  }}
                >
                  {resBurnerAds?.length > 19 ? (
                    <>
                      <Typography
                        align="center"
                        className="blackcolor484848 fs20px"
                      >
                        You have already uploaded 20 videos.
                      </Typography>
                      <Typography
                        align="center"
                        className="blackcolor484848 fs20px"
                      >
                        To upload a new video,
                      </Typography>
                      <Typography
                        align="center"
                        className="blackcolor484848 fs20px"
                      >
                        Please delete some of your existing videos first.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept=".mp4"
                        id="raised-button-file"
                        onChange={(e) => {
                          onChangeFile(e, 1);
                        }}
                      />
                      <label htmlFor="raised-button-file">
                        <IconButton
                          variant="contained"
                          size="large"
                          component="span"
                        >
                          <AddCircleOutlineIcon
                            sx={{ fontSize: "80px", color: "#E141C3" }}
                          />
                        </IconButton>
                      </label>
                      <Typography
                        align="center"
                        className="blackcolor484848 fs20px"
                      >
                        Supported format mp4
                      </Typography>
                    </>
                  )}
                </Grid>
              </>
            ) : (
              <Grid
                container
                style={{
                  position: "relative",
                  border: "1px solid #ddd",
                  background: "#F7F8FD",
                  borderRadius: "8px 8px 0px 0px",
                }}
                justifyContent="space-between"
                rowGap="20px"
              >
                {localFileUrl ? (
                  <video
                    controls
                    muted
                    autoPlay
                    ref={vidRef}
                    src={localFileUrl}
                    style={{
                      height: "250px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : null}
                {loading ? null : (
                  <ClearIcon
                    className="red-icon fs40px ad_content_clear_Btn"
                    onClick={() => setFile(null)}
                  />
                )}
              </Grid>
            )}
            {resBurnerAds?.length > 19 ? (
              <Button className={"purple_btn_primary--burnerad fs18px"}>
                Limit Reached
              </Button>
            ) : (
              <Button
                className={
                  !file
                    ? "disabledbtn--burnerad fs18px"
                    : "purple_btn_primary--burnerad fs18px"
                }
                endIcon={loading ? null : <FiUploadCloud />}
                disabled={!file ? true : loading ? true : false}
                onClick={() => handleUploadimage(file)}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "upload"
                )}
              </Button>
            )}
          </Grid>
          {resBurnerAds?.map((data, index) => {
            const fileExtension =
              data?.Key?.split(".")?.[data?.Key?.split(".").length - 1];
            return (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={2.8}
                  className="relative"
                  key={index}
                >
                  <Grid container className="relative">
                    <Grid
                      item
                      xs={12}
                      sx={{ borderRadius: "8px", height: "298px" }}
                    >
                      <video
                        controls
                        src={
                          data?.s3Link ||
                          `https://s3.ap-south-1.amazonaws.com/storage.saps.one/${data?.Key}`
                        }
                        width="100%"
                        height="298px"
                        ref={vidRef}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                        }}
                      />
                    </Grid>
                    <Grid item className="absolute" sx={{ right: 7, top: 10 }}>
                      <DeleteDilogue
                        deleteBurnerAd={deleteBurnerAd}
                        burnerVdo={data}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
          })}
        </Grid>
      </div>
      <LoaderDialogue loading={loading} />
    </>
  );
};

export default AddBurnerAd;
