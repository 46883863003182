import React, { useEffect, useState } from "react";
import { Grid, Alert, Button, Snackbar } from "@mui/material";
// ** React Icons
import { BiSave } from "react-icons/bi";
import { BiEditAlt } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";
// ** Core Component
import AccordionPanel from "../../../../../../component/AccordionCustom";
import ConfirmationDialog from "../Action--Component/PermissionConfirmation";
import useAccessPermissionFilter from "../../../../../../component/permissionUtils/customHook/useAccessPermissionFilter";
import usePermission from "../../../../../../component/permissionUtils/customHook/usePermission";
import permissionUtils from "../../../../../../component/permissionUtils";

const AccessTab = ({ userID, state }) => {
  const [edit, setEdit] = useState(false);
  const [changePermission, setChangePermission] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [updateAccessPermission, setUpdateAccessPermission] = useState(null);
  const [
    updatedRoutesPermit,
    PermissionCheck,
    CheckTabsPermissions,
    accessPermission,
    snackbarType,
    snackbarOpen,
    snackbarMessage,
    setSnackbarOpen,
    getPermission,
    editAccessPermission,
    
  ] = permissionUtils();

  const { accessPermissionBasedRole, getPermissionBasedRole } =
    usePermission(userID);

  const AllFilteredPermission = useAccessPermissionFilter(
    accessPermissionBasedRole
  );

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (accessPermissionBasedRole) {
      setUpdateAccessPermission(accessPermissionBasedRole);
    } else {
    }
  }, [accessPermissionBasedRole]);
  useEffect(() => {
    if (userID) {
      getPermissionBasedRole(userID);
    }
  }, [userID]);

  const checkHandler = (e, item) => {
    // const path = item.path;
    // const permissionToUpdate = Object.values(AllFilteredPermission).find(
    //   (permissionList) => permissionList.some((item) => item.path === path)
    // );
    // const updatedData = permissionToUpdate.map((ele) =>
    //   ele.key === item.key ? { ...ele, allowed: !ele.allowed } : ele
    // );
    const updatedAccessPermission = updateAccessPermission.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, allowed: !dataItem.allowed }
        : dataItem
    );

    const permissionChange = updatedAccessPermission
      .filter((item) => !item.allowed)
      .map((item) => item.key);

    setUpdateAccessPermission(updatedAccessPermission);
    setChangePermission(permissionChange);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleEditClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    setEdit(true);
    setDialogOpen(false); // Close the dialog
    editAccessPermission(userID, changePermission, setEdit);
  };

  const shouldRemoveKeys = state === "BusinessOwner";

  if (shouldRemoveKeys && AllFilteredPermission) {
    const keysToRemove = [
      "Agency",
      "Coupons",
      "Finance",
      "UserManagement",
      "UserProfile",
    ];

    keysToRemove.forEach((key) => {
      if (key in AllFilteredPermission) {
        delete AllFilteredPermission[key];
      }
    });
  }

  return (
    <>
      <Snackbar
        sx={{ marginTop: "60px" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        content="Are you sure you want to change the permission?"
      />
      {PermissionCheck("edit-user-access") && (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              startIcon={edit ? <ImCancelCircle /> : <BiEditAlt />}
              className={edit ? "cancelbutton" : "purplebtn"}
              sx={{
                textTransform: "capitalize",
                width: edit ? "110px" : "90px",
              }}
              onClick={() => {
                setEdit((edit) => !edit);
              }}
            >
              {edit ? "Cancel" : "Edit"}
            </Button>
            {edit && (
              <Button
                startIcon={<BiSave />}
                className="purplebtn"
                sx={{
                  textTransform: "capitalize",
                  width: "100px",
                  marginLeft: "10px",
                }}
                onClick={handleEditClick}
              >
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
      )}

      {state === "AdAgency" && (
        <>
          {updateAccessPermission && (
            <Grid item sm={12} mt={2} xs={12} md={6} lg={6}>
              <AccordionPanel
                data={AllFilteredPermission?.Agency}
                edit={edit}
                id={"agency"}
                content="Additional content here"
                expanded={expanded === "agency"}
                checkHandler={checkHandler}
                updateAccessPermission={updateAccessPermission}
                handleChange={handleChange("agency")}
              />
            </Grid>
          )}
        </>
      )}

      {state !== "AdAgency" && (
        <>
          <Grid container spacing={4} style={{ marginBottom: "20px" }}>
            {updateAccessPermission &&
              Object.values(AllFilteredPermission)?.map((data, index) => (
                <Grid item sm={12} mt={1} xs={12} md={6} lg={6} key={index}>
                  <AccordionPanel
                    data={data}
                    edit={edit}
                    id={Object.keys(AllFilteredPermission)[index]}
                    content="Additional content here"
                    expanded={expanded === index}
                    checkHandler={checkHandler}
                    updateAccessPermission={updateAccessPermission}
                    handleChange={handleChange(index)}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default AccessTab;
