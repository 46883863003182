import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Grid,
  Breadcrumbs,
  Box,
  Button,
  TextField,
  MenuItem,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { GrView } from "react-icons/gr";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { OrderStatusContext } from "../../../../Context/OrderStatusContext";
import useExportExcel from "../../../../utils/useExportExcelFile";
import use12HrTime from "../../../../utils/use12HrTime";
import ApproveDialog from "./DialoguePopup";
import axios from "../../../../api/axiosInstance";
// import Calendar from "../../../../../../component/ReactDateRangePicker/ReactDateRangePicker";
// import Button from "@mui/material";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2 }} className="purple-gradient" {...other}>
      {children}
      <Typography className="whitecolortypo">Select date Range </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const headCells = [
  {
    id: "Status",
    numeric: true,
    label: "Status",
  },
  {
    id: "orderId",
    numeric: true,
    disablePadding: false,
    label: "Order Id ",
  },
  {
    id: "orderName",
    numeric: true,
    disablePadding: false,
    label: "Order Name",
  },

  {
    id: "Date",
    numeric: true,
    disablePadding: false,
    label: "Date ",
  },
  {
    id: "TimeSlot",
    numeric: true,
    disablePadding: false,
    label: "Time Slot",
  },

  {
    id: "Duration",
    numeric: true,
    disablePadding: false,
    label: "Duration ",
  },
  // {
  //   id: "Billboard",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Billboard",
  // },
  // {
  //   id: "Amount",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Amount",
  // },
  {
    id: "Views",
    numeric: true,
    disablePadding: false,
    label: "Views",
  },
  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
//////////////////////////////////////////////////// Order  Analytics Tabluler format //////////////////////////////////////////////////////////////

const OrderOverviewTable = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [dense, setDense] = React.useState(false);
  const [fileName, setfileName] = React.useState("Order Status");
  const [ExportArr, setExportArr] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [OrdersArrExport, setOrdersArrExport] = useState([]);
  const { timeslot } = use12HrTime();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");
  // const startDate = props?.startDate;
  // const endDate = props?.endDate;
  const value = useContext(OrderStatusContext);
  // const ordersArr = props?.ordersArr;
  const {
    ordersArr,
    startDate,
    endDate,
    OrdersArrayLength,
    type,
    starttimeSlot,
    endtimeSlot,
    currentPage,
    locationType,
    billtype,
    stateData,
    cityData,
    search,
  } = props;
  console.log("propsinOrderOvertable", ordersArr);
  // console.log("contextAPiData", value);
  const { exportToExcel } = useExportExcel();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const buttonColor = (status) => {
    if (status === "Approved") {
      return "order-green-btn";
    }
    if (status === "Pending") {
      return "order-orange-btn ";
    }
    if (status === "Live") {
      return "order-white-btn ";
    }
    if (status === "Rejected") {
      return "red-colorbtn ";
    }
    if (status === "Scheduled") {
      return "order-light-purple-btn";
    }
    if (status === "Published") {
      return "order-purple-btn_20br  ";
    }
    if (status === "Expired") {
      return "order-Expired-btn  ";
    }
    if (status === "Cancelled") {
      return "order-Cancelled-btn  ";
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleButtonClick = () => {
    if (!startDate || !endDate) {
      setOpen(true);
    } else {
      exportToExcel(ExportArr, fileName);
    }
  };

  const getOrderData = async () => {
    if (startDate == "Invalid date" || endDate === "Invalid date") {
      return;
    }
    setLoading(true);
    try {
      let body = {
        search: search,
        status: type,

        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : startDate,

        startHour: starttimeSlot ? +starttimeSlot : null,
        endHour: endtimeSlot ? +endtimeSlot : null,
        orientation: locationType,
        venue: billtype,
        state: stateData,
        city: cityData,

        page: currentPage,
        limit: OrdersArrayLength,
      };

      let resp = await axios.post(`/api/order/orderList`, body);

      if (resp) {
        setTimeout(() => {
          setLoading(false);

          setOrdersArrExport(resp?.data?.data);
        }, 0.5);
      }
    } catch (err) {
      setLoading(false);
      console.log("error from getOrder => ", err);
    }
  };
  useEffect(() => {
    getOrderData();
  }, [ordersArr]);
  console.log("exported array", OrdersArrExport);

  useEffect(() => {
    const newOrderListArr = OrdersArrExport.map((a) => {
      return {
        Status: a?.status,
        "Order ID": a?.orderId,
        "Order Name": a?.adTitle,
        "Start Schedule Date": a?.startscheduleDate,

        "Ad Title": a?.adTitle,

        "Time Slot": a?.timeSlots.length,
        Duration: a?.durations,
        Billboard: a?.bill?.billboardName,
        Amount: a?.orderPrice,
        Views: a?.totalViews,
      };
    });
    setExportArr(newOrderListArr);
  }, [OrdersArrExport]);

  return (
    <div>
      {" "}
      <Grid container justifyContent="flex-end" className="pt10px">
        <Grid item>
          <Grid>
            <Button
              sx={{ padding: "8px 20px" }}
              className="purple_btn_primary text_transform_inherit"
              onClick={handleButtonClick}
            >
              Export
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: "SmallDialog",
        }}
      >
        <BootstrapDialogTitle> </BootstrapDialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "10px",
          }}
        >
          <Typography className="greycolor505050500">
            Please Select A Date Range.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className="purplebtn width-25 "
            style={{ padding: "4px 0px" }}
            onClick={() => {
              handleClose();
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Grid container className="width100 mt20px" gap="20px">
        <EnhancedTableToolbar />
        <TableContainer className="width100 hide_sortBar table_border_radius">
          <Table
            sx={{
              minWidth: 800,
              backgroundColor: "#fff",
              width: "100%",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              className="tablerow"
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />

            <TableBody className="tablerow ">
              {ordersArr?.map((a, i) => (
                <TableRow
                  key={i}
                  hover
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "auto" }}
                  >
                    <Button
                      // className={"order-orange-btn width100 mt--10px "}
                      className={`${buttonColor(a?.status)}  width100 `}
                    >
                      {a?.status}
                    </Button>
                  </TableCell>

                  <TableCell
                    ccomponent="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "auto" }}
                  >
                    #{a?.orderId}
                  </TableCell>
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "auto" }}
                  >
                    {a?.adTitle}
                  </TableCell>

                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "auto" }}
                  >
                    {a?.startscheduleDate}
                  </TableCell>
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "auto" }}
                  >
                    {a?.timeSlots?.length}
                    {""} Timeslots
                  </TableCell>
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "auto" }}
                  >
                    Duration {""}
                    {a?.durations}
                  </TableCell>
                  {/* <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "auto" }}
                  >
                    {a?.bill?.billboardName ? a?.bill?.billboardName : "---"}
                  </TableCell>
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "auto" }}
                  >
                    {a?.orderPrice ? a?.orderPrice : "---"}
                  </TableCell> */}
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "auto" }}
                  >
                    {a?.totalViews}
                  </TableCell>
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ width: "auto" }}
                  >
                    <Button
                      className="order-purple-btn   width-20  min-width-90px "
                      sx={{ fontWeight: "500 !important" }}
                      onClick={() =>
                        navigate(`/admin/order/${a._id}`, {
                          state: { a },
                        })
                      }
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
};

export default OrderOverviewTable;
