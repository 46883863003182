import React, { useState } from "react";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  Input,
  Button,
  TextField,
} from "@mui/material";
//core component
import SelectDevice from "./SelectDeviceDialolg/SelectDevice";

export default function Testing({
  inputRefs,
  handleChange,
  formdata,
  formErrors,
  handleDeviceId,
  existEmail,
  existPhoneNum,
}) {
  return (
    <>
      <div>
        <div className="admin-head">
          <Typography className="whitecolortypo500 fs16px">
            Business Details
          </Typography>
        </div>
        <div className="admin-content">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography className="greycolor505050500 fs16px  ">
                    Prefix*
                  </Typography>
                  <FormControl
                    className="MainPageFormControl mt10px "
                    size="small"
                  >
                    <TextField
                      select
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      className="Selectdropstyle"
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="prefix"
                      value={formdata.prefix}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Mr."} className="Selectmenustyle">
                        Mr.
                      </MenuItem>
                      <MenuItem value={"Mrs."} className="Selectmenustyle">
                        Mrs.
                      </MenuItem>
                      <MenuItem value={"Ms."} className="Selectmenustyle">
                        Ms.
                      </MenuItem>
                      <MenuItem value={"other"} className="Selectmenustyle">
                        other
                      </MenuItem>
                    </TextField>
                  </FormControl>{" "}
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.prefix}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography className="greycolor505050500 fs16px">
                    First Name*
                  </Typography>
                  <Input
                    inputRef={(ref) => (inputRefs.current[0] = ref)}
                    className="inputstyle "
                    id="firstName"
                    name="firstName"
                    onChange={handleChange}
                    disableUnderline
                    value={formdata.firstName}
                    placeholder="Enter First Name of Admin"
                  />
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography className="greycolor505050500 fs16px ">
                    Last Name*
                  </Typography>
                  <Input
                    inputRef={(ref) => (inputRefs.current[1] = ref)}
                    className="inputstyle "
                    id="lastName"
                    name="lastName"
                    onChange={handleChange}
                    disableUnderline
                    value={formdata.lastName}
                    placeholder="Enter Last Name of Admin"
                  />{" "}
                  <Typography className="formError" variant={"subtitle2"}>
                    {formErrors.lastName}
                  </Typography>
                </Grid>
              </Grid>
              <Typography className="greycolor505050500 fs16px mt10px  ">
                Email Address*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[2] = ref)}
                type="email"
                id="email"
                name="email"
                className="inputstyle "
                onChange={handleChange}
                disableUnderline
                value={formdata.email}
                placeholder="Example : loreumipsum@loreum.com"
              />
              {existEmail && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"This email already exists"}
                </Typography>
              )}
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.email}
              </Typography>
              <Typography className="greycolor505050500 fs16px mt10px">
                Address*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[3] = ref)}
                id="address"
                name="address"
                className="inputstyle "
                onChange={handleChange}
                disableUnderline
                value={formdata.address}
                placeholder="Enter your Address"
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.address}
              </Typography>
              <Typography className="greycolor505050500 fs16px mt10px">
                State*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[4] = ref)}
                className="inputstyle "
                id="state"
                name="state"
                onChange={handleChange}
                disableUnderline
                value={formdata.state}
                placeholder="Enter your State"
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.state}
              </Typography>
              <Typography className="greycolor505050500 fs16px mt10px">
                Website Link*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[5] = ref)}
                id="websiteLink"
                name="websiteLink"
                onChange={handleChange}
                className="inputstyle "
                disableUnderline
                value={formdata.websiteLink}
                placeholder="Enter your website Link"
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.websiteLink}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="greycolor505050500 fs16px ">
                Device*
              </Typography>

              <Typography className="inputstyletypo">
                <SelectDevice
                  deviceId={formdata.deviceId}
                  handleChange={handleChange}
                  handleDeviceId={handleDeviceId}
                />
              </Typography>
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.deviceId}
              </Typography>

              <Typography className="greycolor505050500 fs16px mt10px">
                Phone Number*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[6] = ref)}
                className="inputstyle "
                disableUnderline
                name="mobileNumber"
                value={formdata.mobileNumber}
                onChange={handleChange}
                placeholder="Enter Phone Number"
                inputProps={{ maxLength: 10 }}
              />
              {existPhoneNum && (
                <Typography className="formError" variant={"subtitle2"}>
                  {"This number already exist!"}
                </Typography>
              )}
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.mobileNumber}
              </Typography>

              <Typography className="greycolor505050500 fs16px mt10px">
                Landmark*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[7] = ref)}
                className="inputstyle "
                disableUnderline
                name="landmark"
                value={formdata.landmark}
                onChange={handleChange}
                placeholder="Enter Landmark"
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.landmark}
              </Typography>
              <Typography className="greycolor505050500 fs16px mt10px">
                Pincode*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[8] = ref)}
                className="inputstyle "
                name="pincode"
                disableUnderline
                value={formdata.pincode}
                onChange={handleChange}
                placeholder="Enter Pincode"
                inputProps={{ maxLength: 6 }}
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.pincode}
              </Typography>
              <Typography className="greycolor505050500 fs16px mt10px">
                Yearly Turnover*
              </Typography>
              <Input
                inputRef={(ref) => (inputRefs.current[9] = ref)}
                className="inputstyle "
                disableUnderline
                name="yearlyTurnover"
                value={formdata.yearlyTurnover}
                onChange={handleChange}
                placeholder="Enter Yearly Turnover"
              />
              <Typography className="formError" variant={"subtitle2"}>
                {formErrors.yearlyTurnover}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt10px">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className="greycolor505050500 fs16px pb-10px">
                  Business Type*
                </Typography>
                <FormControl className="MainPageFormControl " size="small">
                  <TextField
                    select
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle"
                    labelId="demo-select-small"
                    id="businessType"
                    name="businessType"
                    value={formdata.businessType}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleChange}
                  >
                    <MenuItem
                      value={"Individual"}
                      className="blackcolor484848 fs13px"
                    >
                      Individual
                    </MenuItem>
                    <MenuItem
                      value={"Enterprise"}
                      className="blackcolor484848 fs13px"
                    >
                      Enterprise
                    </MenuItem>
                  </TextField>
                </FormControl>{" "}
                <Typography className="formError" variant={"subtitle2"}>
                  {formErrors.businessType}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className="greycolor505050500 fs16px pb-10px">
                  Screen Type*
                </Typography>
                <FormControl className="MainPageFormControl " size="small">
                  <TextField
                    select
                    variant="filled"
                    name="screenType"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle"
                    labelId="demo-select-small"
                    id="screenType"
                    value={formdata.screenType}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleChange}
                  >
                    <MenuItem
                      value={"Glass Window"}
                      className="blackcolor484848 fs13px"
                    >
                      Glass Window
                    </MenuItem>
                    <MenuItem
                      value={"Glass Door"}
                      className="blackcolor484848 fs13px"
                    >
                      Glass Door
                    </MenuItem>
                  </TextField>
                </FormControl>
                <Typography className="formError" variant={"subtitle2"}>
                  {formErrors.screenType}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className="greycolor505050500 fs16px pb-10px">
                  Footfall*
                </Typography>
                <FormControl className="MainPageFormControl " size="small">
                  <TextField
                    select
                    variant="filled"
                    name="footfall"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle"
                    labelId="demo-select-small"
                    id="footfall"
                    value={formdata.footfall}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleChange}
                  >
                    <MenuItem
                      value={"Less than 100"}
                      className="blackcolor484848 fs13px"
                    >
                      Less than 100
                    </MenuItem>
                    <MenuItem
                      value={"Less than 200"}
                      className="blackcolor484848 fs13px"
                    >
                      Less than 200
                    </MenuItem>
                    <MenuItem
                      value={"Less than 500"}
                      className="blackcolor484848 fs13px"
                    >
                      Less than 500
                    </MenuItem>
                  </TextField>
                </FormControl>{" "}
                <Typography className="formError" variant={"subtitle2"}>
                  {formErrors.footfall}
                </Typography>
              </Grid>{" "}
              <Grid item xs={12} sm={6} md={4}>
                <Typography className="greycolor505050500 fs16px pb-10px">
                  Nature of Outlet*
                </Typography>
                <FormControl className="MainPageFormControl " size="small">
                  <TextField
                    select
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle"
                    name="natureofOutlet"
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={formdata.natureofOutlet}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleChange}
                  >
                    <MenuItem
                      value={"Manufacturing"}
                      className="blackcolor484848 fs13px"
                    >
                      Manufacturing
                    </MenuItem>
                    <MenuItem
                      value={"Office"}
                      className="blackcolor484848 fs13px"
                    >
                      Office
                    </MenuItem>
                    <MenuItem
                      value={"Store"}
                      className="blackcolor484848 fs13px"
                    >
                      Store
                    </MenuItem>
                    <MenuItem
                      value={"Shop"}
                      className="blackcolor484848 fs13px"
                    >
                      Shop
                    </MenuItem>
                    <MenuItem
                      value={"Cafe"}
                      className="blackcolor484848 fs13px"
                    >
                      Cafe
                    </MenuItem>
                    <MenuItem
                      value={"Restaurant"}
                      className="blackcolor484848 fs13px"
                    >
                      Restaurant
                    </MenuItem>
                    <MenuItem value={"Bar"} className="blackcolor484848 fs13px">
                      Bar
                    </MenuItem>
                    <MenuItem
                      value={"Single-Brand Showroom"}
                      className="blackcolor484848 fs13px"
                    >
                      Single-Brand Showroom
                    </MenuItem>
                    <MenuItem
                      value={"Multi-Brand Showroom"}
                      className="blackcolor484848 fs13px"
                    >
                      Multi-Brand Showroom
                    </MenuItem>
                    <MenuItem
                      value={"Others"}
                      className="blackcolor484848 fs13px"
                    >
                      Others
                    </MenuItem>
                  </TextField>
                </FormControl>{" "}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className="greycolor505050500 fs16px pb-10px">
                  Advertisement Objective*
                </Typography>
                <FormControl className="MainPageFormControl " size="small">
                  <TextField
                    select
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle"
                    labelId="demo-select-small"
                    id="demo-select-small"
                    name="advertisementObjective"
                    value={formdata.advertisementObjective}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleChange}
                  >
                    <MenuItem
                      value={"Promote my own Product"}
                      className="blackcolor484848 fs13px"
                    >
                      Promote my own Product
                    </MenuItem>
                    <MenuItem
                      value={"Rent out my screen and earn extra income"}
                      className="blackcolor484848 fs13px"
                    >
                      Rent out my screen and earn extra income
                    </MenuItem>
                  </TextField>
                </FormControl>
                <Typography className="formError" variant={"subtitle2"}>
                  {formErrors.advertisementObjective}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className="greycolor505050500 fs16px">
                  GST*
                </Typography>
                <Input
                  inputRef={(ref) => (inputRefs.current[10] = ref)}
                  className="inputstyle "
                  disableUnderline
                  name="gstNumber"
                  value={formdata.gstNumber}
                  onChange={handleChange}
                  placeholder="Enter First Name of Admin"
                  inputProps={{ maxLength: 15 }}
                />
                <Typography className="formError" variant={"subtitle2"}>
                  {formErrors.gstNumber}
                </Typography>
              </Grid>{" "}
            </Grid>{" "}
          </Grid>

          {/* <Typography className="greycolor505050500 fs16px mt10px pb-10px">
            Upload Adhar Card
          </Typography> */}
        </div>
      </div>
    </>
  );
}
