import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
let timeSlots = "";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{ m: 0, p: 0, background: "#fffff" }}
      {...other}
      className="purple-gradient"
    >
      {children}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: "0 20px" }}
      >
        <Grid item>
          {" "}
          <Typography className="whitecolortypo500">
            Repetitions Slots
          </Typography>
        </Grid>
        <Grid item>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: "#DDDDDD",
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const TimeSlotsDialogue = ({ progressData, campaignData }) => {
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("lg");
  const [displayTime, setDisplayTime] = useState([]);
  const [sortedArr, setSortedArr] = useState([]);
  const [progressDataSort, setprogressDataSort] = useState(progressData);
  const handleClickOpen = () => {
    setOpen(true);
  };
  console.log("progress", sortedArr);
  const handleClose = () => {
    setOpen(false);
  };

  const calulateTimeMinSec = (date, startHour, sec) => {
    if (startHour < 10) {
      return (
        moment(`${date}T0${startHour}:00:00+05:30`)
          .add(sec, "seconds")
          .format("LTS"),
        "gfgdyfyg"
      );
    } else {
      return moment(`${date}T${startHour}:00:00+05:30`)
        .add(sec, "seconds")
        .format("LTS");
    }
  };

  function convertSecondsToTime(arr, today) {
    let time = [];

    for (let item of arr?.[0][Object.keys(arr?.[0])]) {
      if (campaignData?.startHour < 10) {
        time.push(
          moment(`${today}T0${campaignData?.startHour}:00:00+05:30`)
            .add(item, "seconds")
            .format("LTS")
        );
        console.log(
          moment(`${today}T0${campaignData?.startHour}:00:00+05:30`)
            .add(item, "seconds")
            .format("LTS"),
          "gfgdyfyg"
        );
      } else {
        time.push(
          moment(`${today}T${campaignData?.startHour}:00:00+05:30`)
            .add(item, "seconds")
            .format("LTS")
        );
        console.log(
          moment(`${today}T${campaignData?.startHour}:00:00+05:30`)
            .add(item, "seconds")
            .format("LTS")
        );
      }
    }
    console.log(time, "timeeee");
    return time;
  }

  useEffect(() => {
    if (campaignData) {
      // getRunningAd();
      setDisplayTime(
        convertSecondsToTime(
          progressData?.seconds,
          progressData?.startscheduleDate
        )
      );
    }
  }, [campaignData]);

  useEffect(() => {
    if (progressDataSort?.seconds) {
      const sort = [...progressDataSort?.seconds];
      sort?.sort((a, b) => a?.time - b?.time);
      setSortedArr(sort);
    }
    return;
  }, [progressDataSort?.seconds]);

  timeSlots = (
    <>
      {" "}
      {`${displayTime && displayTime?.[0]}  -  ${
        displayTime && displayTime[displayTime?.length - 1]
      }`}
    </>
  );
  const flterPlayStatus = progressData?.seconds?.filter(
    (a, b) => a?.playStatus == "success"
  );
  return (
    <React.Fragment>
      <Grid container alignItems="center">
        {" "}
        {/* {progressData?.progress?.seconds?.}/ */}
        <span className="purple_color">
          {progressData && progressData?.successCount
            ? progressData?.successCount
            : "0"}
        </span>
        /{progressData && progressData?.seconds?.length}{" "}
        <Button
          sx={{
            padding: "0",
            marginLeft: "5px",
          }}
          className="purplebtn "
          onClick={() => setOpen(true)}
        >
          {" "}
          View
        </Button>
      </Grid>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxHeight: 500,
          },
        }}
        className="br_8_dialogue"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <Divider />
        <DialogContent>
          <Grid container gap="10px">
            {progressData?.seconds && progressData?.seconds.length > 0 ? (
              <>
                {sortedArr?.map((data, i) => (
                  <Grid
                    key={i}
                    sx={{ alignItems: "center", textAlign: "center" }}
                  >
                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      sx={{ padding: "10px", gap: "40px" }}
                      // className="border_primary"
                      className={
                        data?.playStatus === "pending"
                          ? "orange_div font-Lato"
                          : data?.playStatus === "success"
                          ? "green_div font-Lato "
                          : "red_div font-Lato "
                      }
                    >
                      <Grid item>
                        <Typography className="font-Lato">
                          {calulateTimeMinSec(
                            progressData?.date,
                            progressData?.startHour,
                            data?.time
                          )}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography className="font-Lato">
                          {data?.playStatus === "pending"
                            ? "Pending"
                            : data?.playStatus === "success"
                            ? "Success"
                            : "Failed"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {[1]?.map((a, b) => (
                  <Grid item key={b}>
                    <Grid
                      container
                      item
                      key={b}
                      justifyContent="center"
                      // className="border_primary"
                      sx={{
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                    >
                      <Typography> No progress time ... </Typography>
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          width: "100%",
                          height: "40px",
                          borderRadius: "8px",
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default TimeSlotsDialogue;
