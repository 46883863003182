import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  IconButton,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  DialogActions,
} from "@mui/material";
//React Icons
import EditBillboard from "./EditStep-Bill-Board";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="dialogtitlenno-bg" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogcrossicon-red"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapDialogTitleConfirmation = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2 }} className="purple-gradient" {...other}>
      {children}
      <Typography className="whitecolortypo">Confirmation </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitleConfirmation.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const options = ["Option 1", "Option 2"];
export default function CustomizedDialogs({
  AllbillboardData,
  billBoardData,
  getBillBoardData,
}) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  const [openFinal, setOpenFinal] = React.useState(false);
  const [fullWidthFinal] = React.useState(true);
  const [maxWidthFinal] = React.useState("xs");
  // ==================== States ======================== //
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenFinal(true);
  };
  const handleCloseFinal = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
    setOpenFinal(false);
  };
  const handleCloseNFinal = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(true);
    setOpenFinal(false);
  };
  return (
    <>
      <div>
        <Button className="purplebtn " onClick={handleClickOpen}>
          Edit
        </Button>

        <BootstrapDialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            sx={{ padding: "0px", margin: "0px" }}
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <EditBillboard
              getBillBoardData={getBillBoardData}
              handleCloseFinal={handleCloseFinal}
              setOpen={setOpen}
              AllbillboardData={AllbillboardData}
            />
          </BootstrapDialogTitle>
        </BootstrapDialog>
        <BootstrapDialog
          fullWidth={fullWidthFinal}
          maxWidth={maxWidthFinal}
          open={openFinal}
          onClose={handleCloseFinal}
          PaperProps={{
            className: "SmallDialog",
          }}
        >
          <BootstrapDialogTitleConfirmation> </BootstrapDialogTitleConfirmation>
          <DialogContent
            sx={{
              backgroundColor: "#fffff",
              marginTop: "10px",
              padding: "10px",
            }}
          >
            <Typography
              className="greycolor505050500"
              sx={{
                paddingLeft: "20px",
              }}
            >
              Are you sure you want to exit?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button className="  previousbtn" onClick={handleCloseNFinal}>
              No
            </Button>
            <Button
              className="purplebtn  "
              style={{ padding: "4px 0px" }}
              onClick={() => {
                handleCloseFinal();
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
}
