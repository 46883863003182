import React, { useState } from "react";
import {
  Grid,
  Card,
  ButtonGroup,
  Typography,
  Divider,
  Button,
  Snackbar,
  Alert,
  CardContent,
  Stack,
  Pagination,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useNavigate } from "react-router-dom";

// import { FETCH_URL } from "../../../../../../../../../../fetchIp";
import { FETCH_URL } from "../../../../../../fetchIp";
import { useEffect } from "react";
// import axiosInstance from "../../../../../../../../../../api/axiosInstance";
import axiosInstance from "../../../../../../api/axiosInstance";
import moment from "moment";

// import TransactionSkeleton from "../../../../../../../../Finance/FinanceSkeleton/TransactionSkeleton";
import TransactionSkeleton from "../../../FinanceSkeleton/TransactionSkeleton";

import no_data_found_img from "../../../../../../assets/Img/no_transaction.png";

const OrderVise = (props) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectDate, setSelectDate] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [walletAmount, setWalletAmount] = useState([]);
  const [addToWallet, setAddToWallet] = useState(false);
  const [walletId, setwalletId] = useState("");
  const [transactionDetail, setTransactionDetail] = useState();
  const [getWalletOverall, setgetWalletOverall] = useState();

  // snackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);

  let userID = JSON.parse(localStorage.getItem("userData")).user._id;

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const setAddToWalletPageHandler = (bool) => {
    setAddToWallet(bool);
  };
  let adminId = JSON.parse(localStorage.getItem("userData")).user._id;

  const handleChangeDate = (event) => {
    setSelectDate(event.target.value);
  };
  let theMonths = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );
  let now = new Date();
  let arr = [];
  for (let i = 0; i < 3; i++) {
    let future = new Date(now.getFullYear(), now.getMonth() - i, 1);
    arr.push(future);
  }
  // ////////////////////////////// selectBar date details //////////////////////////
  const getTransactionData = async () => {
    let date;
    let firstDay;
    let lastDay;
    if (typeof selectDate === "object") {
      date = new Date(selectDate.toDateString());
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else {
      date = new Date(selectDate);
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    try {
      const res = await axiosInstance.get(
        `/api/payment/getTransactionData?filter=netRevenue&userId=${userID}&startDate=${moment(
          firstDay
        ).format("YYYY-MM-DD")}&endDate=${moment(lastDay).format(
          "YYYY-MM-DD"
        )}&page=${currentPage}&limit=10`
      );
      if (res?.status == 200 || res?.status == 201) {
        console.log("transactionDetail response  ===>y", res);
        setTransactionDetail(res?.data?.msg);
        setTotalPage(Math.ceil(res?.data?.length / 10));
        setLoading(false);
      } else {
        console.log("Error in  getting transactionDetail ==> ", res.msg);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error in getting transactionDetail  => ", error.message);
      setLoading(false);
    }
  };
  const handleChangeByPage = (event, value) => {
    console.log("Page Number Clicked ==> ", value);
    setCurrentPage(value);
    getTransactionData();
  };

  useEffect(() => {
    if (typeof selectDate === "object") {
      if (selectDate?.toDateString()?.length > 0) {
        getTransactionData();
        return;
      }
    }
    if (selectDate?.length > 0) {
      getTransactionData();
    }
  }, [selectDate, currentPage]);

  //getTransactionApi

  return (
    <>
      <>
        <Snackbar
          open={snackopen}
          autoHideDuration={3000}
          onClose={SnanbarClose}
        >
          <Alert onClose={SnanbarClose} severity={"success"}>
            {snackmsg}
          </Alert>
        </Snackbar>
        <Snackbar
          open={snackerropen}
          autoHideDuration={8000}
          onClose={SnackbarErrorClose}
        >
          <Alert onClose={SnackbarErrorClose} severity={"error"}>
            {snackErrMsg}
          </Alert>
        </Snackbar>{" "}
        <Grid
          container
          className="mt30px "
          style={{ border: "1px solid #ddd", padding: "15px" }}
          gap="15px"
        >
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Typography className="blackcolor484848 fs24px">
                Recent Transaction
              </Typography>
            </Grid>
            <Grid container item xs={4} justifyContent="flex-end">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small">Date </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={selectDate}
                  label="date"
                  onChange={(e) => handleChangeDate(e)}
                >
                  {arr?.map((date, i) => (
                    <MenuItem
                      value={date.toString()}
                      key={i}
                      className="blackcolor484848 "
                    >
                      {theMonths[date?.getMonth()]} {date.getFullYear()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {transactionDetail && transactionDetail?.length > 0 ? (
            <>
              {" "}
              {transactionDetail?.map((data, i) => (
                <>
                  <Grid container item xs={12} gap="25px" key={i}>
                    <Grid container item xs={12} justifyContent="space-between">
                      <Grid item xs={8}>
                        <Typography className="blackcolor484848 fs20px">
                          {" "}
                          {data?.message ? data?.message : "Margin Transaction"}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={
                            data?.amount
                              ? "money_depo_color"
                              : "money_debited_color"
                          }
                          style={{ textAlign: "end" }}
                        >
                          {data?.amount
                            ? `+ ₹ ${data?.amount.toFixed(2)}`
                            : `- ₹ ${data?.amount.toFixed(2)}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={8}>
                        <Typography className="greycolor505050500">
                          Date:{" "}
                          {moment(data?.createdAt).format(
                            "MMM Do YYYY , h:mm a"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          style={{ textAlign: "end" }}
                          sx={{
                            color:
                              data.transactionStatus == "failed"
                                ? "red"
                                : "green",
                          }}
                        >
                          {data?.transactionStatus
                            ? data?.transactionStatus
                            : data?.isDeposit
                            ? "credited Amount"
                            : "debited amount"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <Divider orientation="horizontal" flexItem />
                  </Grid>
                </>
              ))}
            </>
          ) : loading ? (
            <>
              {[1, 2, 3, 4, 5].map((a, b) => (
                <TransactionSkeleton key={b} />
              ))}
            </>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <img
                src={no_data_found_img}
                alt="no transaction found"
                style={{ maxWidth: "400px" }}
              />
            </Grid>
          )}
        </Grid>
        {transactionDetail?.length > 0 && (
          <Grid
            justifyContent="center"
            alignItems="center"
            sx={{ display: "flex", marginTop: "20px" }}
          >
            <Stack spacing={2}>
              <Pagination
                page={currentPage}
                count={totalPage}
                onChange={handleChangeByPage}
                size="large"
              />
            </Stack>
          </Grid>
        )}
      </>
    </>
  );
};

export default OrderVise;
