import * as React from "react";
import { Grid, Typography, Button } from "@mui/material";
import ActiveVoucher from "./VoucherTableTab/ActiveVoucher";
// icons
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../../api/axiosInstance";
import { useState } from "react";
import { useEffect } from "react";
import useExportExcel from "../../../../../utils/useExportExcelFile";
// icons
import permissionUtils from "../../../../../component/permissionUtils";

const Voucher = () => {
  const { exportToExcel } = useExportExcel();
  const [dataExport, setdataExport] = useState(null);
  const [value, setValue] = React.useState(0);
  const [voucher, setVoucher] = useState([]);
  const [fileName, setfileName] = useState("Vouchers");
  const navigate = useNavigate();
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  /////////////////////////////////////////// Get coupon api ////////////////////////////////////////
  const getCouponApi = async () => {
    try {
      const res = await axiosInstance.get(`/coupon/getAllSpecialCoupons`);
      if (res.status == 200) {
        setVoucher(res?.data?.data);
        console.log("res after getting special voucher", res);
      } else {
        console.log("error while getting special vouchers");
      }
    } catch (err) {
      console.log("error while getting special vouchers", err);
    }
  };

  /////////////////////////////////////////// Get coupon api ////////////////////////////////////////
  useEffect(() => {
    getCouponApi();
  }, []);

  let datavoucher = [];
  useEffect(() => {
    const newOrderListArr = voucher.map((voucher) => {
      return {
        id: voucher?._id,
        couponAmount: voucher?.couponAmount,
        couponCode: voucher?.couponCode,
        couponName: voucher?.couponName,
        createdAt: voucher?.createdAt,
        endDate: voucher?.endDate,
        maxSpend: voucher?.maxSpend,
        minSpend: voucher?.minSpend,
        perUserLimit: voucher?.perUserLimit,
        startDate: voucher?.startDate,
        updatedAt: voucher?.updatedAt,
      };
    });

    setdataExport(newOrderListArr);
  }, [voucher]);
  console.log("data", dataExport);
  const handleButtonClick = () => {
    exportToExcel(dataExport, fileName);
  };
  return (
    <>
      <div>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography className="blackcolortypo500 fs24px">
            Showing {voucher?.length} Voucher
          </Typography>

          <Grid item>
            {PermissionCheck("create-voucher") && (
              <Grid container gap="10px">
                <Button
                  startIcon={<ControlPointIcon />}
                  className="border_primary text_transform_inherit greyColorTypo717171_400"
                  onClick={() => navigate("/admin/CouponVocher/AddVoucher")}
                >
                  Create Voucher
                </Button>
                <Button
                  sx={{ padding: "8px 20px" }}
                  className="purple_btn_primary text_transform_inherit"
                  onClick={handleButtonClick}
                >
                  Export
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
      <div style={{ margin: "20px 0" }}>
        <ActiveVoucher voucher={voucher} getCouponApi={getCouponApi} />
      </div>
    </>
  );
};

export default Voucher;
