import * as React from "react";
import {
  Grid,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { FETCH_URL } from "../../../../../../fetchIp";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ background: "#5FCA5D" }} {...other}>
      {children}
      <Typography className="whitecolortypo">Approve order </Typography>{" "}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function MaxWidthDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");
  const { setSnackOpen, setSnackMsg, handleShowSnackbar, setSnackErrMsg } =
    props;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    // console.log("yes");
    // ApprovedAPI();
    setOpen(false);
  };

  const controlOrderStatus = () => {
    // props.approveFunc("The order has been approved","success")
    ApprovedAPI();
    setOpen(false);
  };

  // console.log("ApproveDialog props data ==> ", props.approveFunc);

  const ApprovedAPI = async () => {
    if (props.orderId) {
      // console.log("//===== getRunning Ad API got hit ====> ", props.orderId);
      let token = JSON.parse(localStorage.getItem("userData")).token;
      const response = await fetch(`${FETCH_URL}/api/order/approvedOrder`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          orderId: props.orderId,
          status: "Approved",
        }),
      });
      let res = await response.json();
      if (response.ok) {
        console.log("getRunningAd ==>", res.msg);
        setSnackOpen(true);
        setSnackMsg("Order Approved");
        handleShowSnackbar("success");

        props.approveFunc("The order has been approved", "success");
      } else {
        console.log("getRunningAd Error ==>", res);
        setSnackErrMsg(res.msg);
        handleShowSnackbar("error");
      }
    }

    // console.log("Approved API function got hit =")
  };

  return (
    <React.Fragment>
      <Button className="approved-btn width100 p15px" onClick={handleClickOpen}>
        Approve
      </Button>

      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: "SmallDialog",
        }}
        BackdropComponent={styled(Backdrop, {
          name: "MuiModal",
          slot: "Backdrop",
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({ zIndex: -1, backdropFilter: "blur(2px)" })}
      >
        <BootstrapDialogTitle> </BootstrapDialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "10px",
          }}
        >
          <Typography a className="greycolor505050500">
            Are you sure you want to approve this ad?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button className="  diallog-no-btn" onClick={handleClose}>
            No
          </Button>
          <Button
            className="approved-btn width-25 "
            style={{ padding: "4px 0px" }}
            onClick={controlOrderStatus}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
