import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Grid, useTheme, useMediaQuery } from "@mui/material";

// ** Icons

// **  Core component
import ViewTab from "./Orde-DetailsrTab/ViewTab";
import axios from "../../../../api/axiosInstance";
import Progress from "./Orde-DetailsrTab/Progress";
import OverviewTab from "./Orde-DetailsrTab/Order-OverviewTab";
import BillBoradTab from "./Orde-DetailsrTab/Order-BillBoardTab";
import AnalticsTab from "./Orde-DetailsrTab/Order-AnalyticsTab";
import TabList from "../../../../component/CustomTabs/TabList";
import TabPanelList from "../../../../component/CustomTabs/TabPanel";
import CustomBreadcrumbs from "../../../../component/CustomBreadcrumbs";
import permissionUtils from "../../../../component/permissionUtils";

//================================ Main Component =============================//
export default function Status() {
  const location = useLocation();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const [value, setValue] = React.useState(0);
  const params = useParams();
  const [imagefile, setImageFile] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [orderData, setOrderData] = useState("");
  const [loading, setLoading] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // get Order by id

  const getOrderData = async (orderId) => {
    try {
      let res = await axios.get(
        `api/order/getOrderById_v1/${orderId ? orderId : params?.order_id}`
      );
      console.log("response from getting order by id =>", res);
      if (res.status == 200 || res.status == 201) {
        setOrderData(res?.data?.msg);

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error from getOrder => ", err);
    }
  };

  useEffect(() => {
    getOrderData();
  }, [params?.order_id]);

  const breadcrumbs = [
    { label: "Dashboard", link: "/" },
    {
      label: orderData?.status,
      link: location?.state?.route ? location?.state?.route : "/admin/order",
    },
    {
      label: orderData?.adTitle,
    },
  ];
  const permissionList = [
    "overview-Tab",
    "view-order-billboard-list",
    "view-order-analytics",
    "view-order-images",
    "view-order-progress",
  ];
  const getTabLabel = (permission) => {
    const labelMap = {
      "overview-Tab": "Overview",
      "view-order-billboard-list": "Billboards",
      "view-order-analytics": "Analytics",
      "view-order-images": "Views",
      "view-order-progress": "Progress",
    };
    return labelMap[permission];
  };

  const permissions = CheckTabsPermissions(permissionList);

  const panels = [
    {
      permission: "overview-Tab",
      component: (
        <OverviewTab
          data={orderData}
          loading={loading}
          getOrderData={getOrderData}
        />
      ),
    },
    {
      permission: "view-order-billboard-list",
      component: <BillBoradTab data={orderData} loading={loading} />,
    },
    {
      permission: "view-order-analytics",
      component: (
        <AnalticsTab data={orderData} loading={loading} imagefile={imagefile} />
      ),
    },
    {
      permission: "view-order-images",
      component: (
        <ViewTab
          data={orderData}
          loading={loading}
          imagefile={imagefile}
          loadingFiles={loadingFiles}
        />
      ),
    },
    {
      permission: "view-order-progress",
      component: <Progress data={orderData} loading={loading} />,
    },
  ];
  return (
    <>
      <div className="width-90LR ">
        <div className="flex-class">
          <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
        </div>{" "}
        <Grid container className="device-container mt20px">
          <Grid item xs={12}>
            <TabList
              md={md}
              value={value}
              centered={true}
              classes={{ Tabs: "TabStyleAddDevice", Tab: "TabChangesDevice" }}
              handleChange={handleChange}
              getTabLabel={getTabLabel}
              permissions={permissions}
            />
          </Grid>
        </Grid>
        <TabPanelList value={value} permissions={permissions} panels={panels} />{" "}
      </div>
    </>
  );
}
