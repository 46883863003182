import React from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Typography,
  Grid,
  Card,
  CardContent,
  Stack,
  Avatar,
} from "@mui/material";
import axiosInstance from "../../../../../../api/axiosInstance";
import PasswordReset from "../Action--Component/PasswordResetDialog";
export default function OverviewTab({ userID }) {
  const { state } = useLocation();

  const [memberprofile, setMemberProfile] = React.useState(null);

  const getProfile = async () => {
    try {
      let profile = await axiosInstance.get(
        `/admin/adagency/getallmember/${userID}`
      );
      console.log("Profile ==> ", profile.data.msg);
      setMemberProfile(profile.data.msg);
    } catch (error) {
      console.log("USER PROFILE DATA ==>", error);
    }
  };
  console.log("Check== memberprofile======>", memberprofile);
  React.useEffect(() => {
    getProfile();
  }, [state]);
  return (
    <>
      <Grid container className="mb70px">
        <Grid container spacing={5} className="mt10px">
          {memberprofile?.map((item) => {
            return (
              <>
                <Grid
                  item
                  xs={3}
                  lg={2.4}
                  className="cursorpoint"
                  sx={{ position: "relative" }}
                >
                  {/* <Link
                    // to={"/admin/memberprofile"}
                    className="linkcolor"
                    // state={item}
                    // getProfile={{ getProfile }}
                  > */}
                  <Card className="campaign-analytics-card ">
                    <CardContent className="width100">
                      <Stack alignItems="center" direction="row">
                        <Avatar
                          className="avatar-bg-color"
                          style={{
                            width: "80px",
                            height: "80px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderRadius: "90%",
                          }}
                        >
                          <Typography className="fs50px whitecolortypo">
                            {item?.firstName?.split(" ")[0][0] +
                              "" +
                              item?.lastName?.split(" ")[0][0]}
                          </Typography>
                        </Avatar>
                      </Stack>
                      <Grid container justifyContent="center">
                        {/* <img src={item.ImagedataArr} /> */}

                        <Typography
                          className="greycolortypo mt10px "
                          align="center"
                          sx={{ textTransform: "uppercase" }}
                        >
                          {item?.name
                            ? item?.name
                            : item?.firstName + " " + item?.lastName}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        className="mt10px width-88LR"
                      >
                        <Typography className="blackcolortypo fs20px">
                          0
                        </Typography>
                        <Typography className="blackcolortypo fs20px">
                          0
                        </Typography>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Typography className="greycolortypo fs20px">
                          Orders
                        </Typography>
                        <Typography className="greycolortypo fs20px">
                          Wallet
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                  {/* </Link> */}
                  <span
                    item
                    style={{
                      position: "absolute",
                      top: "50px",
                      right: "5px",
                    }}
                    className="temp_absolute"
                  >
                    <PasswordReset />
                  </span>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}
