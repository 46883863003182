import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Checkbox,
  Avatar,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  InputBase,
  MenuItem,
  Select,
  ListItemButton,
  getStepIconUtilityClass,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import debounce from "lodash.debounce";
import { FETCH_URL } from "../../../../../fetchIp";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      "&:focus": {
        width: "90%",
      },
    },
  },
}));

export default function Testing(props) {
  const [showSelect, showShowSelect] = useState(false);
  useEffect(() => {
    if (
      props.data.Country !== "null" &&
      props.data.Country?.length > 0 &&
      props.data.state !== "null" &&
      props.data.state?.length > 0 &&
      props.data.city !== "null" &&
      props.data.city?.length > 0
    ) {
      showShowSelect(true);
      return;
    }
    showShowSelect(false);
  }, [
    props.data.Country,
    props.data.state,
    props.data.city,
    props.data.pincodes,
  ]);

  return (
    <>
      <div>
        <div className="admin-head">
          <Typography className="whitecolortypo500 fs16px">
            Admin Location
          </Typography>
        </div>
        <div className="admin-location ">
          {showSelect ? (
            <Grid container className="admin-content">
              <Grid item>
                <FormControlLabel
                  className="radiostyle"
                  control={
                    <Checkbox
                      className="pl25px"
                      checked={
                        props.data?.allpincodes?.length ==
                        props?.data?.pincodes?.length
                          ? true
                          : false
                      }
                    />
                  }
                  onClick={() => {
                    props.data.handlePincodes("null", "selectAll");
                  }}
                  label={<Typography className="">All Locations</Typography>}
                />
              </Grid>
              <Grid item>
                <Search className="search-admin">
                  <SearchIconWrapper>
                    <SearchIcon className="searchicon" />
                  </SearchIconWrapper>
                  <StyledInputBase
                    className=""
                    placeholder="Search Location"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>{" "}
              </Grid>
            </Grid>
          ) : null}
          <Grid
            container
            gap={4}
            className="cluseter-headsearch"
            sx={{ padding: "10px " }}
          >
            <Grid item md={2} lg={1.4} sm={2} xs={3}>
              <FormControl className="MainPageFormControl" size="small">
                <Select
                  notchedOutline
                  disableUnderline
                  variant="filled"
                  className="Selectdropstyle"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={props.data.Country}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => {
                    props.data.handlePincodeData(e.target.value, "Country");
                  }}
                >
                  <MenuItem value="null" className="Selectmenustyle">
                    Select a Country
                  </MenuItem>
                  <MenuItem value="India" className="Selectmenustyle">
                    India
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} lg={1.4} sm={2} xs={3}>
              <FormControl className="MainPageFormControl" size="small">
                <Select
                  notchedOutline
                  className="Selectdropstyle"
                  disableUnderline
                  variant="filled"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={props.data.state}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => {
                    //handleStates(e.target.value);
                    props.data.handlePincodeData(e.target.value, "state");
                  }}
                >
                  <MenuItem value="null" disabled className="Selectmenustyle">
                    Select a State
                  </MenuItem>
                  {props.data &&
                  props.data.Country != "null" &&
                  props.data.allStates &&
                  props.data.allStates.length > 0
                    ? props.data.allStates.map((data, index) => (
                        <MenuItem value={data} className="Selectmenustyle">
                          {data}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} lg={1.4} sm={2} xs={3}>
              <FormControl className="MainPageFormControl" size="small">
                <Select
                  notchedOutline
                  className="Selectdropstyle"
                  disableUnderline
                  variant="filled"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={props.data.city}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => {
                    props.data.handlePincodeData(e.target.value, "city");
                  }}
                >
                  <MenuItem value="null" className="Selectmenustyle">
                    {" "}
                    Select a City
                  </MenuItem>
                  {props.data.Country != "null" &&
                  props.data.allCities &&
                  props.data.allCities.length > 0
                    ? props.data.allCities.map(
                        (data, index) =>
                          data != "NA" && (
                            <MenuItem value={data} className="Selectmenustyle">
                              {data}
                            </MenuItem>
                          )
                      )
                    : ""}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" gap={2} className="admin-content">
            {props.data.Country != "null" &&
              props.data.allpincodes.length > 0 &&
              props.data.allpincodes.map((data, index) => {
                return (
                  <Grid
                    container
                    item
                    xs={2.7}
                    sm={5.5}
                    md={2.7}
                    className="access-radio-grid "
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="pl25px"
                          checked={
                            props.data.pincodes.includes(data) ? true : false
                          }
                        />
                      }
                      onChange={(e) =>
                        props.data.handlePincodes(data, "singleSelect")
                      }
                      value={data}
                      className="radiostyle access-radio-formcontrolabel"
                      label={
                        <Typography className="width100 ml10px">
                          <Typography>{data}</Typography>
                        </Typography>
                      }
                    />
                  </Grid>
                );
              })}
            {/* {props.data.pincodeCheckErr && (
              <Typography className="formError" variant={"subtitle2"}>
                {"Please enter the Your Adhar Card Number here"}
              </Typography>
            )} */}
          </Grid>{" "}
        </div>
      </div>
    </>
  );
}
