import {
  Grid,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import noDataFound from "../../../../../assets/Img/no_data.gif";

import { useState } from "react";
import axiosInstance from "../../../../../api/axiosInstance";
const Item = styled(Paper)(({ theme }) => ({}));
const Progress = (props) => {
  const [displayTime, setDisplayTime] = React.useState(null);
  const startscheduleDate = props?.data?.startscheduleDate;
  const [apiDataProgress, setapiDataProgress] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [allFilterDate, setAllFilterDate] = useState([]);
  const [allFilterSlot, setAllFilterSlot] = useState([]);
  const [multiStartHour, setmultiStartHour] = useState([]);
  const [isLoading, setLoading] = useState(true);

  let arr = [];
  for (let i = 0; i < 120; i++) {
    arr.push(i);
  }

  function convertSecondsToTime(secondsArray, startscheduleDate) {
    if (!startscheduleDate) {
      console.error("startscheduleDate is required.");
      return [];
    }

    if (!Array.isArray(secondsArray)) {
      console.error("secondsArray should be an array.");
      return [];
    }

    // Sort the secondsArray in ascending order
    secondsArray.sort((a, b) => a.time - b.time);

    const time = secondsArray.map((item) => {
      if (item.time >= 0) {
        const formattedHour =
          selectedSlot?.time < 10
            ? `0${selectedSlot?.time}`
            : selectedSlot?.time;
        const dateTimeString = `${startscheduleDate}T${formattedHour}:00:00+05:30`;
        const newTime = moment(dateTimeString)
          .add(item.time, "seconds")
          .format("LTS");
        return {
          time: newTime,
          playStatus: item.playStatus,
        };
      } else {
        console.error("Invalid time value:", item.time);
        return null;
      }
    });

    return time;
  }
  useEffect(() => {
    if (apiDataProgress) {
      // getRunningAd();
      setDisplayTime(
        convertSecondsToTime(
          apiDataProgress?.seconds,
          props.data.startscheduleDate
        )
      );
    }
  }, [selectedSlot]);
  useEffect(() => {
    // Use setTimeout to change isLoading to false after 5 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []); // Empty dependency array to run the effect only once

  console.log("props>>>>>>>>>>>>progrss", props);

  const playTimeSlots = props?.data?.orderLogs[0]?.progress[0]?.seconds?.filter(
    (a, b) => a?.playStatus === "success"
  );
  console.log(
    props?.data?.orderLogs,
    "props?.data?.orderLogsprops?.data?.orderLogs"
  );
  const getProgressData = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/order/getProgressTime?startHour=${selectedSlot?.time}&orderId=${props?.data?._id}`
      );

      console.log("dataOccupency", res);
      if (res.status == 200 || res.status == 204) {
        setapiDataProgress(res?.data?.data[0]);
        setDisplayTime(
          convertSecondsToTime(
            res?.data?.data[0]?.seconds,
            props.data.startscheduleDate
          )
        );
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };
  useEffect(() => {
    if (selectedSlot?.time) {
      getProgressData();
    }
  }, [props, selectedSlot]);

  function tConvert(time) {
    const times = [...new Set(time)];
    let newTimes = [];
    times.map((data) => {
      let obj = {};
      if (data < 4) {
        if (data === 0) obj["actualTime"] = "12am - 1am";
        else obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 3 && data < 8) {
        obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 7 && data < 12) {
        if (data === 11) obj["actualTime"] = "11am - 12pm";
        else obj["actualTime"] = `${data}am - ${data + 1}am`;
      } else if (data > 11 && data < 16) {
        if (data === 12) obj["actualTime"] = `12pm - ${data - 12 + 1}pm`;
        else obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      } else if (data > 15 && data < 20) {
        obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      } else if (data > 19) {
        if (data === 23) obj["actualTime"] = `11pm - ${data - 12 + 1}am`;
        else obj["actualTime"] = `${data - 12}pm - ${data - 12 + 1}pm`;
      }
      obj["time"] = data;
      newTimes.push(obj);
    });
    setAllFilterSlot(newTimes);
    setSelectedSlot(newTimes[0]);
  }
  useEffect(() => {
    // console.log(
    //   "tConvert(props?.data?.startHour)",
    //   );
    tConvert(multiStartHour);
    // setTimeSlot(tConvert(props?.data?.startHour));
  }, [multiStartHour]);

  useEffect(() => {
    console.log("props anaalityics", props?.data);
    let tempStart = [];
    if (props?.data?.multiTimes) {
      tempStart = props?.data?.multiTimes.map((item) => item?.startHour);
    }
    setmultiStartHour(tempStart);
  }, [props]);
  return (
    <div>
      <Grid
        container
        className="border_primary"
        sx={{ paddingBottom: "20px" }}
        gap="20px"
      >
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "20px", borderBottom: "1px solid #ddd" }}
        >
          <Grid item>
            <Grid container item gap="15px" alignItems="center">
              <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                <InputLabel id="demo-select-small">
                  {" "}
                  Select Hour Slot{" "}
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={selectedSlot}
                  label="       Select Hour Slot"
                  onChange={(e) => setSelectedSlot(e.target.value)}
                >
                  {allFilterSlot?.map((item, i) => (
                    <MenuItem value={item} key={i}>
                      {item?.actualTime}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container item gap="15px" alignItems="center">
              <Typography className="greycolor1c1c1c fs18px">
                Progress
              </Typography>
              <Typography className="greycolor1c1c1c fs18px">
                <span className="greencolortypo_500 fs18px">
                  {" "}
                  {apiDataProgress?.successCount
                    ? apiDataProgress?.successCount
                    : 0}
                </span>
                /
                {apiDataProgress?.seconds
                  ? apiDataProgress?.seconds?.length
                  : 0}{" "}
                Repetition
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            padding: "0 20px",

            maxHeight: "50vh",
            overflow: "scroll",
          }}
        >
          {displayTime && displayTime?.length > 0 ? (
            <>
              {" "}
              {displayTime?.map((time, b) => (
                <Grid item xs={12} md={4} xl={3} key={b}>
                  <Grid
                    container
                    item
                    className={
                      time.playStatus == "pending"
                        ? "orange_div"
                        : time?.playStatus == "success"
                        ? "green_div"
                        : "red_div"
                    }
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: "10px 20px" }}
                  >
                    <Grid item>
                      <Typography>{time?.time}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {" "}
                        {time?.playStatus == "pending"
                          ? "Pending"
                          : time?.playStatus == "success"
                          ? "Success"
                          : "Failed"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : isLoading ? (
            <>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]?.map(
                (a, b) => (
                  <Grid item xs={12} md={4} xl={3} key={b}>
                    <Grid
                      container
                      item
                      // className="green_div"
                      // className={
                      //   props?.data?.orderLogs[0]?.seconds[b]?.playStatus === false
                      //     ? " red_div"
                      //     : "green_div"
                      // }
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          width: "100%",
                          height: "40px",
                          borderRadius: "8px",
                        }}
                      />
                    </Grid>
                  </Grid>
                )
              )}
            </>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <img
                src={noDataFound}
                className="noBillboardFoundGif_width"
                alt="no image found"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Progress;
