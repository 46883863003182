import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, Chip, Typography, Tooltip, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Link, useNavigate } from "react-router-dom";
import { visuallyHidden } from "@mui/utils";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
//React ICons for
import { GrView } from "react-icons/gr";
import { FiUserX } from "react-icons/fi";
//Image
import Billboardoff from "../../../../assets/Img/Billboardoff.png";
//core components
import SelectBillBoard from "../ActionDialog/SelectBillBoard";
import SelectUser from "../ActionDialog/SelectUser";
import TableSkeleton from "../../../../component/TableSkeleton/TableSkeleton";
import WarningDeviceDialogue from "../ActionDialog/WarningDeviceDialogue";
import DeleteDeviceDialogue from "../ActionDialog/DeleteDeviceDialogue";
import no_data_img from "../../../../assets/Img/no_data_found_img.png";
import permissionUtils from "../../../../component/permissionUtils";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const headCells = [
  {
    id: "deviceUID",
    numeric: false,
    label: "UID",
    sortable: false,
  },
  {
    id: "deviceName",
    numeric: true,
    label: "Device Name",
  },
  {
    id: "deviceStatus",
    numeric: true,
    label: "Power",
  },
  {
    id: "macId",
    numeric: true,
    disablePadding: false,
    label: "MAC ID ",
  },
  {
    id: "Sensors",
    numeric: true,
    disablePadding: false,
    label: "Sensors",
  },
  {
    id: "billboardAssignedStatus",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Added on",
  },
  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    console.log(event, property, "event, property");
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead
        className="width100 tablehead homelist-item"
        style={{ borderRadius: "8px" }}
      >
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
              {headCell?.label !== "Action" && (
                <TableSortLabel
                  align="center"
                  className="whitecolortypo500 "
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {orderBy === headCell.id ? (
                    <Box
                      component="span"
                      sx={visuallyHidden}
                      className="whitecolortypo500"
                    >
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTable({
  devices,
  getAllDevice,
  deviceslength,
  loading,
  currentPage,
  searchText,
}) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [openUser, setOpenUser] = React.useState(false);

  const handleClickSnack = () => {
    setOpen(true);
  };
  const handleSnackUser = () => {
    setOpenUser(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setOpenUser(false);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - 25) : 0;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [sorbyName, setSortByName] = useState("");
  const handleRequestSort = (event, property) => {
    setSortByName(property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (sorbyName == property) {
      getAllDevice(currentPage, searchText, property, isAsc ? -1 : 1);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <>
      <>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="warning"
              sx={{ width: "100%" }}
            >
              Billboard Assigned Already!
            </Alert>
          </Snackbar>
          <Snackbar
            open={openUser}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="warning"
              sx={{ width: "100%" }}
            >
              User Assigned Already!
            </Alert>
          </Snackbar>
        </Stack>
      </>

      <Grid container className="width100">
        <Grid container className="width100 ">
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer className="width100  table_border_radius">
            <Table
              sx={{
                minWidth: 1200,
                width: "100%",
              }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                className="tablerow"
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              {devices && devices?.length > 0 ? (
                <TableBody className="tablerow ">
                  {devices?.map((row, index) => {
                    const isItemSelected = isSelected(row?._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onClick={(event) => handleClick(event, row.name)}
                        tabIndex={-1}
                        key={row?._id}
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{ width: "12.5%" }}
                        >
                          {row?.deviceUID}
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="greycolortypo"
                          sx={{ width: "12.5%" }}
                        >
                          <Link
                            to={`/admin/deviceprofile/${row?._id}`}
                            className="purple_color_500"
                            style={{ textTransform: "capitalize" }}
                            state={{ deviceData: row }}
                          >
                            {row?.deviceName}
                          </Link>
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{ width: "12.5%" }}
                        >
                          {row?.deviceStatus === "Online" ? (
                            <Chip
                              label={"Online"}
                              className="chipsuccescolor"
                            />
                          ) : (
                            <Chip
                              className="chipdeavtivecolor"
                              label={"Offline"}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          component="th"
                          sx={{ width: "12.5%" }}
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row?.macId}
                        </TableCell>
                        <TableCell
                          ccomponent="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          // className="greycolortypo"

                          sx={{ width: "10%" }}
                        >
                          False
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="greycolortypo"
                          sx={{ width: "12.5%" }}
                        >
                          {row?.billboardAssignedStatus ? (
                            <Chip
                              label={"Assigned"}
                              className="chipsuccescolor"
                            />
                          ) : (
                            <Chip
                              className="chipdeavtivecolor"
                              label={"Not Assigned"}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          // className="greycolortypo"

                          sx={{ width: "12.5%" }}
                        >
                          {new Date(row?.createdAt)
                            .toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .replace(/\./g, "/")}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="greycolortypo"
                          sx={{ width: "15%" }}
                        >
                          <Grid container justifyContent="space-between">
                            <Grid container justifyContent="center" item xs={3}>
                              <Tooltip
                                title="View"
                                className="tooltipheight"
                                // onClick={() =>
                                //   navigate("/admin/deviceprofile", {
                                //     state: { deviceData: row },
                                //   })
                                // }
                                onClick={() =>
                                  navigate(`/admin/deviceprofile/${row?._id}`, {
                                    state: { deviceData: row },
                                  })
                                }
                              >
                                <IconButton>
                                  <GrView />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid container justifyContent="center" item xs={3}>
                              {row?.billboardAssignedStatus === true ? (
                                <Tooltip
                                  className="tooltipheight"
                                  title="BillBoard Assigned"
                                >
                                  <IconButton onClick={handleClickSnack}>
                                    <img
                                      src={Billboardoff}
                                      alt="Billboardoff"
                                      style={{ height: "21px" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <>
                                  {PermissionCheck(
                                    "assign-user-billboard-to-device"
                                  ) && (
                                    <Tooltip
                                      className="tooltipheight"
                                      title="Select BillBoard"
                                    >
                                      <IconButton>
                                        <SelectBillBoard
                                          row={row}
                                          getAllDevice={getAllDevice}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </Grid>
                            {/* 
                            <Grid container justifyContent="center" item xs={3}>
                              {row?.userAssignedStatus === true ? (
                                <Tooltip
                                  title=" User Assigned"
                                  className="tooltipheight"
                                >
                                  <IconButton onClick={handleSnackUser}>
                                    <FiUserX />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <>
                                  {PermissionCheck(
                                    "assign-user-billboard-to-device"
                                  ) && (
                                    <Tooltip
                                      title="Select User"
                                      className="tooltipheight"
                                    >
                                      <IconButton>
                                        <SelectUser
                                          row={row}
                                          getAllDevice={getAllDevice}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </Grid> */}
                            <Grid container justifyContent="center" item xs={3}>
                              {PermissionCheck("delete-device") && (
                                <>
                                  {row.billboardAssignedStatus === false ? (
                                    <>
                                      <Tooltip
                                        title="Delete"
                                        className="tooltipheight"
                                      >
                                        <IconButton>
                                          <DeleteDeviceDialogue
                                            deviceData={row}
                                            getAllDevice={getAllDevice}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <Tooltip
                                      title="Delete"
                                      className="tooltipheight "
                                    >
                                      <IconButton>
                                        <WarningDeviceDialogue
                                          deviceData={row}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </Grid>
                            {/* <Grid container justifyContent="center" item xs={3}>
                              <Tooltip title="Delete" className="tooltipheight">
                                <IconButton>
                                  <DeleteDeviceDialogue
                                    deviceData={row}
                                    getAllDevice={getAllDevice}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid> */}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                        color: "white",
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                loading && (
                  <TableSkeleton
                    rowNumber={new Array(10).fill(0)}
                    tableCell={new Array(7).fill("12.5%").fill(10, 4, 5)}
                    showOption={new Array(4).fill("15%")}
                  />
                )
              )}
            </Table>
            {devices && devices?.length === 0 && loading == false && (
              <Grid container justifyContent="center">
                {" "}
                <img
                  src={no_data_img}
                  alt="no data found"
                  style={{ width: "40%" }}
                />
              </Grid>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
