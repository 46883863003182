import React, { useState, useEffect } from "react";
import AdminHome from "./AdminHome";
import Clusterhead from "./ChHome";
export default function Home() {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.user && userData.user._id) {
      setUserRole(userData.user?.userRole);
    }
  }, []);
  return userRole === 2 ? <Clusterhead /> : <AdminHome />;
}
