import React, { useEffect, useState } from "react";
import DonutChart from "./AllCharts/DonutChart";
import BarChart from "./AllCharts/BarChart";
import BillboardAnalyticsTable from "./AllCharts/BillboardTable";
import axiosInstance from "../../../../api/axiosInstance";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  FormControl,
  CardActions,
  InputBase,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import moment from "moment";
import Calendar from "../../../../component/ReactDateRangePicker/ReactDateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useExportExcelFile from "../../../../utils/useExportExcelFile";

const headCells = [
  {
    id: "Ranking",
    numeric: true,
    label: "Ranking ",
  },
  {
    id: "Billboard Name",
    numeric: true,
    disablePadding: false,
    label: "Billboard Name",
  },
  {
    id: "Location",
    numeric: true,
    disablePadding: false,
    label: "Location  ",
  },
  {
    id: "Orders",
    numeric: true,
    disablePadding: false,
    label: "Orders",
  },
  {
    id: "Total Views",
    numeric: true,
    disablePadding: false,
    label: "Total Views",
  },
  {
    id: "Male Views",
    numeric: true,
    disablePadding: false,
    label: "Male Views",
  },
  {
    id: "Female Views",
    numeric: true,
    disablePadding: false,
    label: "Female Views",
  },
  {
    id: "Gross Revenue",
    numeric: true,
    disablePadding: false,
    label: "Gross Revenue",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              align={headCell.id == "Billboard Name" ? "left" : "center"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
////////////////////////////// Main Component Ranking //////////////////////////////////

const RankingTab = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [dense, setDense] = React.useState(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [dateType, setDateType] = useState("orders");
  const [date, setDate] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [RankingTableData, setRankingTableData] = useState();
  const [IsTesting, setIsTesting] = useState(null);
  const [seletcities, setSelectCites] = useState([]);
  const [dataForTable, setdataForTable] = useState(null);
  const [fileName, setFileName] = useState("BillBoards Ranking");
  const { exportToExcel } = useExportExcelFile();

  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };
  const [location, setLocation] = React.useState("all");
  // console.log("location", location);
  // console.log("typefilter", dateType);
  // console.log("date", date);

  const getTestingStatus = async () => {
    try {
      // setLoader(loaderDispatch, true);
      const res = await axiosInstance.get("/api/billboard/getTestingStatus");
      // setLoader(loaderDispatch, false);
      if (res.status == 200 || res.statusText == "OK") {
        setIsTesting(res?.data?.data?.[0]?.isEnable);
      } else {
        console.log("error in getAllBillboards", res);
      }
    } catch (err) {
      //  setLoader(loaderDispatch, false);
      console.log("error in getAllBillboards", err);
    }
  };

  const getAllCities = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getAllDistrictOfBillboard?isTesting=${IsTesting}`
      );

      if (res.status == 200 || res.statusText == "OK") {
        setSelectCites(res?.data?.data);
      } else {
        console.log("error in getAllBillboards", res);
      }
    } catch (err) {
      console.log("error in getAllBillboards", err);
    }
  };

  useEffect(() => {
    getTestingStatus();
    getAllCities();
  }, []);

  console.log("IsTesting", IsTesting);
  console.log("getAllCities", seletcities);

  const getTableGraphDataForRanking = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getBillboardRanking?location=${location}&startDate=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}&rankingBy=${dateType}&page=1&limit=50`
      );
      if (res.status == 200) {
        console.log("success res after TableGraphData api", res);
        setRankingTableData(res?.data?.data);
      } else {
        console.log("error res after TableGraphData api", res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!date) {
      return;
    }
    getTableGraphDataForRanking();
  }, [date, dateType, location]);

  useEffect(() => {
    setdataForTable(RankingTableData);
  }, [RankingTableData]);
  // console.log("rankingtable for excl", dataForTable);

  return (
    <>
      <div
        id="scrollableDiv"
        style={{
          marginBottom: "20px",
          overflowY: "scroll",
          height: "100vh",
          scrollBehavior: "smooth",
        }}
      >
        <Grid
          container
          justifyContent="flex-start"
          sx={{ margin: "10px 0" }}
          rowGap="10px"
          gap="10px"
        >
          <Typography className="greycolor1c1c1c fs20px ">
            Top billboards
          </Typography>
          <FormControl className="billboard_price_filter">
            <TextField
              select
              variant="filled"
              InputProps={{ disableUnderline: true }}
              className="Selectdropstyle"
              labelId="demo-select-small"
              id="demo-select-small"
              value={location}
              inputProps={{ "aria-label": "Without label" }}
              onChange={(e) => {
                setLocation(e.target.value);
              }}
            >
              <MenuItem value={"all"} className="greycolor505050500 fs14px">
                All
              </MenuItem>
              {seletcities?.map((res, index) => (
                <MenuItem
                  value={res}
                  key={index}
                  className="greycolor505050500 fs14px"
                >
                  {res}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          sx={{ margin: "20px 0" }}
          rowGap="10px"
          gap="10px"
        >
          <Grid item>
            {" "}
            <Grid container gap="10px">
              <Button
                className={
                  dateType === "orders"
                    ? "select_date_filter_class"
                    : "filterButtonDesign"
                }
                onClick={() => setDateType("orders")}
              >
                {" "}
                Orders
              </Button>
              <Button
                className={
                  dateType === "views"
                    ? "select_date_filter_class"
                    : "filterButtonDesign"
                }
                onClick={() => setDateType("views")}
              >
                {" "}
                Views
              </Button>
              <Button
                className={
                  dateType === "amounts"
                    ? "select_date_filter_class"
                    : "filterButtonDesign"
                }
                onClick={() => setDateType("amounts")}
              >
                {" "}
                Amount
              </Button>
            </Grid>
          </Grid>
          <Grid item gap="10px">
            {" "}
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Calendar getDataFromChildHandler={getDataFromChildHandler} />
              </LocalizationProvider>
              <Button
                sx={{ padding: "5px 10px" }}
                className="purple_btn_primary text_transform_inherit"
                onClick={() => {
                  exportToExcel(dataForTable, fileName);
                }}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <EnhancedTableToolbar />
        <TableContainer className="width100 hide_sortBar table_border_radius">
          <Table
            sx={{
              minWidth: 800,
              backgroundColor: "#fff",
              width: "100%",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              className="tablerow"
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />

            <TableBody className="tablerow ">
              {RankingTableData?.map((a, i) => (
                <TableRow
                  key={i}
                  hover
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="none"
                  >
                    <Typography className="greycolor1c1c1c fs16px ">
                      {i + 1}
                    </Typography>
                  </TableCell>

                  <TableCell
                    align="Left"
                    component="th"
                    scope="row"
                    padding="none"
                  >
                    <Typography className="greycolor1c1c1c fs16px ">
                      {a?.billboardName ? a?.billboardName : "----"}
                    </Typography>
                    <Typography className="greycolor1c1c1cT fs16px ">
                      {a?.billboardUID ? a?.billboardUID : "----"}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="none"
                  >
                    {a?.location ? a?.location : "----"}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="none"
                  >
                    {a?.ordersCount ? a.ordersCount : 0}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="none"
                  >
                    {a?.totalViews ? a.totalViews * 2 : 0}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="none"
                  >
                    {a?.maleViews ? a.maleViews * 2 : 0}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="none"
                  >
                    {a?.femaleViews ? a.femaleViews * 2 : 0}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="none"
                  >
                    {parseInt(a?.totalRevenues ? a.totalRevenues : 0).toFixed(
                      2
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default RankingTab;
