import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import PieAnalytics from "./PieAnalytics";
import BarAnalytics from "./BarAnalytics";
import BillboardAnalyticsTable from "./BillboardAnalyticsTable";
import axiosInstance from "../../../../../../../../api/axiosInstance";

const OccurencyAnalytics = (props) => {
  const [billboardActiveHour, setbillboardActiveHour] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTime, setactiveTime] = useState(null);
  const [Inactivetime, setInactivetime] = useState(null);
  



  const billBoardID = props.id;

  //////////////////////// Api To get billboard active time  ////////////////////

  // const getBillboardActiveHours = async () => {
  //   try {
  //     const res = await axiosInstance.get(
  //       `/api/billboard/getAllBillboardActiveTime?billboardId=${billBoardID}&page=${currentPage}&limit=10`
  //     );
  //     if (res.status == 200 || res.status == 204) {
  //       setbillboardActiveHour(res?.data.data);

  //       setactiveTime(Math.floor(res?.data.data[0].videoDuration / 3600));
  //     } else {
  //     }
  //   } catch (err) {}
  // };

  // useEffect(() => {
  //   getBillboardActiveHours();
  // }, []);

  return (
    <>
      <Grid container justifyContent="space-between" rowGap="20px">
        <Grid item xs={12} md={4.3} className=" card_div_new">
          <Grid container justifyContent="center">
            <PieAnalytics billBoardID={billBoardID} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={7.3} className=" card_div_new">
          <BarAnalytics billBoardID={billBoardID} />
        </Grid>
      </Grid>
      <div style={{ marginTop: "20px" }}>
        <BillboardAnalyticsTable billBoardID={billBoardID} />
      </div>
    </>
  );
};

export default OccurencyAnalytics;
