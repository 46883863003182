import React, { useEffect, useState } from "react";
import Billlogs from "./Billlogs";
import { Tabs, Tab, DialogContent } from "@mui/material";
//
import Devicelogs from "./Devicelogs";
import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
const headCells = [
  {
    id: "Action",
    numeric: true,
    label: "Action ",
  },
  {
    id: "OldValue",
    numeric: true,
    disablePadding: false,
    label: "Old Value",
  },
  {
    id: "newValue",
    numeric: true,
    disablePadding: false,
    label: "New Value",
  },
  {
    id: "User",
    numeric: true,
    disablePadding: false,
    label: "User",
  },
  {
    id: "Plateform",
    numeric: true,
    disablePadding: false,
    label: "Plateform",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created At",
  },
];

const BillboardLogs = (props) => {
  const [value, setvalue] = useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };

  return (
    <>
      <TabContext value={value}>
        <Tabs
          onChange={handleChange}
          value={value}
          centered
          className="TabStyleAddDevice"
        >
          <Tab
            value={0}
            label="Billboard Logs"
            sx={{
              borderRadius: "50px 0px 50px 0px",
              marginBottom: "20px",
            }}
            className="TabChangesDeviceLive"
          />
          <Tab
            label="Device Logs"
            value={1}
            sx={{
              borderRadius: "50px 0px 50px 0px",
              marginBottom: "20px",
            }}
            className="TabChangesDeviceLive"
          />
        </Tabs>

        <DialogContent style={{ padding: "0px" }}>
          <TabPanel value={0} index={0}>
            <Billlogs props={props} />
          </TabPanel>
          <TabPanel value={1} index={1}>
            <Devicelogs props={props} />
          </TabPanel>
        </DialogContent>
      </TabContext>
    </>
  );
};

export default BillboardLogs;
