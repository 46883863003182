import React, { useState } from "react";
import {
  Grid,
  Card,
  ButtonGroup,
  Typography,
  Divider,
  Button,
  Snackbar,
  Alert,
  Stack,
  CardContent,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import walletImage from "../../../../../../assets/Img/3d_wallet_10 1.png";
import depositImg from "../../../../../../assets/Img/wallet_6418298 1.png";
import totalSpent from "../../../../../../assets/Img/rupee_2448368 1.png";
import totalRefund from "../../../../../../assets/Img/Group.png";

import totalWithdraw from "../../../../../../assets/Img/atm_1578023 1.png";

import AddToWallet from "./AddToWallet";
import { useNavigate } from "react-router-dom";
// delete later

import { FETCH_URL } from "../../../../../../fetchIp";
import { useEffect } from "react";
import axiosInstance from "../../../../../../api/axiosInstance";
import moment from "moment";
// delete later
import TransactionSkeleton from "../../../../Finance/FinanceSkeleton/TransactionSkeleton";
import noTransactionImage from "../../../../../../assets/Img/no_transaction.png";
import WalletBarAnalystic from "./WalletBarAnalystic";
import useExportExcel from "../../../../../../utils/useExportExcelFile";
const WallletTab = (props) => {
  const {
    states: { profile, state, userID },
  } = props;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectDate, setSelectDate] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [walletAmount, setWalletAmount] = useState([]);
  const [addToWallet, setAddToWallet] = useState(false);
  const [walletId, setwalletId] = useState("");
  const [transactionDetail, setTransactionDetail] = useState();
  const [getWalletOverall, setgetWalletOverall] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [length, setLength] = React.useState("");

  // snackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [transactionFilter, settransactionFilter] = useState("All");
  const [fileName, setfileName] = useState("Transaction History");
  const [ExportArr, setExportArr] = useState([]);
  // const userID = state;
  // const userID = JSON.parse(localStorage.getItem("userProfile")).userIndividual;
  const { exportToExcel } = useExportExcel();
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const setAddToWalletPageHandler = (bool) => {
    setAddToWallet(bool);
  };
  let adminId = JSON.parse(localStorage.getItem("userData")).user._id;
  console.log("Wallet Tab userID", userID);
  /////////////////// api all to get total data  //////////////////////////

  const getWalletOverallData = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/payment/getWalletOverallData/${userID}`
      );

      console.log("getWalletOverallData response  ===>y", res);
      setgetWalletOverall(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log("Error in getting getWalletOverallData  => ", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getWalletOverallData();
  }, [userID]);

  // getWalletApi
  const getWalletData = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/payment/getWalletData`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userId: userID,
        }),
      });
      let res = await response.json();
      if (response.ok) {
        console.log("add to wallet response  ===>", res?.msg);
        setWalletAmount(res?.msg?.walletBalance);
        setwalletId(res?.msg?.userId);
      } else {
        console.log("Error in add to getting Wallet ==> ", res.msg);
        setSnackerropen(true);
        setSnackErrMsg(res.msg);
      }
    } catch (error) {
      console.log("Error in getting wallet  => ", error.message);
    }
  };

  // ////////////////////////////// selectBar date details //////////////////////////
  const handleChangeDate = (event) => {
    setSelectDate(event.target.value);
  };
  let theMonths = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );
  let now = new Date();
  let arr = [];
  for (let i = 0; i < 3; i++) {
    let future = new Date(now.getFullYear(), now.getMonth() - i, 1);
    arr.push(future);
  }
  // ////////////////////////////// selectBar date details //////////////////////////

  //getTransactionApi

  const getTransactionData = async () => {
    let date;
    let firstDay;
    let lastDay;
    if (typeof selectDate === "object") {
      date = new Date(selectDate.toDateString());
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else {
      date = new Date(selectDate);
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    try {
      const res = await axiosInstance.get(
        `api/payment/getTransactionData?userId=${walletId}&filter=${transactionFilter}&startDate=${moment(
          firstDay
        ).format("YYYY-MM-DD")}&endDate=${moment(lastDay).format(
          "YYYY-MM-DD"
        )}&page=${currentPage}&limit=10`
      );
      if (res?.status == 200 || res?.status == 201) {
        console.log("transactionDetail response  ===>y", res);
        setTransactionDetail(res?.data?.msg);
        setTotalPage(Math.ceil(res?.data?.length / 10));
        setLength(Math.ceil(res?.data?.length));
        setLoading(false);
      } else {
        console.log("Error in  getting transactionDetail ==> ", res.msg);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error in getting transactionDetail  => ", error.message);
      setLoading(false);
    }
  };
  const handleChangeByPage = (event, value) => {
    console.log("Page Number Clicked ==> ", value);
    setCurrentPage(value);

    // setclientsPage(value);
  };

  useEffect(() => {
    if (!walletId) {
      return;
    }
    if (typeof selectDate === "object") {
      if (selectDate?.toDateString()?.length > 0) {
        getTransactionData();
        return;
      }
    }
    if (selectDate?.length > 0) {
      getTransactionData();
    }
  }, [selectDate, walletId, transactionFilter, currentPage]);

  useEffect(() => {
    getWalletData();
  }, [walletId]);

  /////////// export file //////////

  useEffect(() => {
    if (transactionDetail?.length > 0) {
      const newOrderListArr = transactionDetail?.map((a) => {
        return {
          Date: moment(a?.createdAt).format("MMM Do YYYY , h:mm a"),
          OrderID: a?.order_id,
          AmountStatus: a?.amountStatus,
          Amount: a?.amount,
          TransactionStatus: a?.transactionStatus,
          TransactionID: a?._id,
          Message: a?.message,
        };
      });
      setExportArr(newOrderListArr);
    } else {
      const newOrderListArr = [1].map((a) => {
        return {
          Data: "No data found",
        };
      });
      setExportArr(newOrderListArr);
    }
  }, [transactionDetail]);

  console.log("transac>>>>>>", transactionDetail);

  return (
    <>
      {!addToWallet ? (
        <>
          <Snackbar
            open={snackopen}
            autoHideDuration={3000}
            onClose={SnanbarClose}
          >
            <Alert onClose={SnanbarClose} severity={"success"}>
              {snackmsg}
            </Alert>
          </Snackbar>
          <Snackbar
            open={snackerropen}
            autoHideDuration={8000}
            onClose={SnackbarErrorClose}
          >
            <Alert onClose={SnackbarErrorClose} severity={"error"}>
              {snackErrMsg}
            </Alert>
          </Snackbar>{" "}
          <Grid container spacing={3} className="mt5px">
            <Grid item md={4} lg={2.2} sm={6} className="cursorpoint">
              <Card className="dashcard-home-start ">
                <CardContent className="card-content">
                  <Grid
                    container
                    justifyContent="flex-start"
                    style={{ width: "90px" }}
                  >
                    <img
                      src={depositImg}
                      alt="walletImage"
                      style={{ width: "55%" }}
                    />
                  </Grid>

                  <Typography className="lightgreycolortypo fs14px pt10px  ">
                    Total Deposit
                  </Typography>
                  <Typography className="blackcolor484848-700  fs1-5rem  pt10px    ">
                    &#x20b9;{" "}
                    {parseFloat(getWalletOverall?.tDeposit)?.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} lg={2.2} sm={6} className="cursorpoint">
              <Card className="dashcard-home-start ">
                <CardContent className="card-content">
                  <Grid
                    container
                    justifyContent="flex-start"
                    style={{ width: "90px" }}
                  >
                    <img
                      src={totalSpent}
                      alt="walletImage"
                      style={{ width: "55%" }}
                    />
                  </Grid>
                  <Typography className="lightgreycolortypo fs14px pt10px ">
                    Total spent
                  </Typography>
                  <Typography className="blackcolor484848-700  fs1-5rem    pt10px  ">
                    &#x20b9; {parseFloat(getWalletOverall?.tSpent)?.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} lg={2.2} sm={6} className="cursorpoint">
              <Card className="dashcard-home-start ">
                <CardContent className="card-content">
                  <Grid
                    container
                    justifyContent="flex-start"
                    style={{ width: "100px" }}
                  >
                    <img
                      src={totalWithdraw}
                      alt="walletImage"
                      style={{ width: "55%" }}
                    />
                  </Grid>
                  <Typography className="lightgreycolortypo fs14px pt10px">
                    Top Up
                  </Typography>
                  <Typography className="blackcolor484848-700  fs1-5rem    pt10px  ">
                    &#x20b9;{" "}
                    {getWalletOverall?.tDepositByAdmin
                      ? getWalletOverall?.tDepositByAdmin
                      : 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item md={4} lg={2.2} sm={6} className="cursorpoint">
              <Card className="dashcard-home-start ">
                <CardContent className="card-content">
                  <Grid
                    container
                    item
                    display="flex"
                    justifyContent="space-between"
                    gap="80px"
                  >
                    <Grid item>
                      <Grid container direction="column">
                        {" "}
                        <img
                          src={totalWithdraw}
                          alt="walletImage"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        {" "}
                        <img
                          src={totalWithdraw2}
                          alt="walletImage"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Typography className="lightgreycolortypo fs14px pt10px">
                    Total withdraw
                  </Typography>
                  <Typography className="blackcolor484848-700  fs1-5rem    pt10px  ">
                    &#x20b9; {getWalletOverall?.tWithdraw.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid> */}
            <Grid item md={4} lg={2.2} sm={6} className="cursorpoint">
              <Card className="dashcard-home-start ">
                <CardContent className="card-content">
                  <Grid
                    container
                    justifyContent="flex-start"
                    style={{ width: "100px" }}
                  >
                    <img
                      src={totalRefund}
                      alt="walletImage"
                      style={{ width: "55%" }}
                    />
                  </Grid>
                  <Typography className="lightgreycolortypo fs14px pt10px">
                    Total Refund
                  </Typography>
                  <Typography className="blackcolor484848-700  fs1-5rem    pt10px  ">
                    &#x20b9; {parseFloat(getWalletOverall?.tRefund)?.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={4} lg={3.2} sm={6} className="cursorpoint">
              <Card className="dashcard-home-start ">
                <CardContent className="card-content">
                  <Grid
                    container
                    justifyContent="flex-start"
                    flexDirection="row"
                    rowGap="10px"
                    gap="10px"
                  >
                    <Grid container item gap="10px">
                      {" "}
                      <img
                        src={walletImage}
                        alt="walletImage"
                        style={{ width: "45%" }}
                      />
                      <Grid>
                        <Typography className="lightgreycolortypo fs14px pt10px">
                          Available balance
                        </Typography>
                        <Typography className="blackcolor484848-700  fs1-5rem    pt10px  ">
                          &#x20b9;{" "}
                          {walletAmount && walletAmount?.length > 0
                            ? Math.round(walletAmount * 100) / 100
                            : 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid justifyContent="center">
                    <Button
                      className="deposit_btn"
                      onClick={() => setAddToWalletPageHandler(true)}
                      sx={{
                        background:
                          "linear-gradient(141.46deg, #E141C3 -11.28%, #B937FA 50.78%, #6906C3 112.19%)",

                        color: "white",
                        borderRadius: "15px",
                        color: "white",

                        padding: "8px 0",
                        fontFamily: "oswald",
                        width: "100%",
                      }}
                    >
                      DEPOSIT MONEY IN WALLET?
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} style={{ margin: "40px 0" }}>
            <WalletBarAnalystic userID={userID} />
          </Grid>
          <Grid
            container
            className="mt30px "
            style={{ border: "1px solid #ddd", padding: "15px" }}
            gap="15px"
          >
            <Grid
              container
              item
              xs={12}
              // style={{ marginBottom: "20px" }}
            >
              <Grid item xs={8}>
                <Typography className="blackcolor484848 fs24px">
                  Recent Transaction
                </Typography>
              </Grid>
              <Grid container item xs={4} justifyContent="flex-end">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={transactionFilter}
                    onChange={(e) => settransactionFilter(e.target.value)}
                  >
                    <MenuItem value="All" className="blackcolor484848 ">
                      All
                    </MenuItem>
                    <MenuItem value="spent" className="blackcolor484848 ">
                      Advertisement
                    </MenuItem>
                    <MenuItem
                      value="depositByAdmin"
                      className="blackcolor484848 "
                    >
                      Top up
                    </MenuItem>
                    <MenuItem value="refund" className="blackcolor484848 ">
                      Refund
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small">Date </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={selectDate}
                    label="date"
                    onChange={(e) => handleChangeDate(e)}
                  >
                    {arr?.map((date, i) => (
                      <MenuItem
                        value={date.toString()}
                        key={i}
                        className="blackcolor484848 "
                      >
                        {theMonths[date?.getMonth()]} {date.getFullYear()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  sx={{
                    padding: "5px 20px !important",
                    height: "40px",
                    marginTop: "8px",
                  }}
                  className="purple_btn_primary text_transform_inherit"
                  onClick={() => {
                    exportToExcel(ExportArr, fileName);
                  }}
                >
                  Export
                </Button>
              </Grid>
            </Grid>
            {transactionDetail && transactionDetail?.length > 0 ? (
              <>
                {" "}
                {transactionDetail?.map((data, i) => (
                  <>
                    <Grid container item xs={12} gap="25px" key={i}>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="space-between"
                      >
                        <Grid item xs={8}>
                          <Typography className="blackcolor484848 fs20px">
                            {" "}
                            {data?.message
                              ? data?.message
                              : "Margin Transaction"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            className={
                              data?.amount
                                ? "money_depo_color"
                                : "money_debited_color"
                            }
                            style={{ textAlign: "end" }}
                          >
                            {data?.transactionStatus == "successful"
                              ? "+ "
                              : data.transactionStatus == "failed"
                              ? "- "
                              : data.transactionStatus == null &&
                                data.AdminId == null
                              ? "-"
                              : data.AdminId !== null
                              ? "+ "
                              : "++"}
                            {data?.amount
                              ? ` ₹ ${parseFloat(data?.amount)?.toFixed(2)}`
                              : `₹ ${parseFloat(data?.amount)?.toFixed(2)}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={8}>
                          <Typography className="greycolor505050500">
                            Date:{" "}
                            {moment(data?.createdAt).format(
                              "MMM Do YYYY , h:mm a"
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            style={{ textAlign: "end" }}
                            sx={{
                              color:
                                data.transactionStatus == "failed"
                                  ? "red"
                                  : "green",
                            }}
                          >
                            {data?.transactionStatus
                              ? data?.transactionStatus
                              : data?.isDeposit
                              ? "credited Amount"
                              : "debited amount"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      <Divider orientation="horizontal" flexItem />
                    </Grid>
                  </>
                ))}
              </>
            ) : loading ? (
              <>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((a, b) => (
                  <TransactionSkeleton key={b} />
                ))}
              </>
            ) : (
              <Grid container justifyContent="center" alignItems="center">
                <img
                  src={noTransactionImage}
                  alt="no transaction found"
                  style={{ maxWidth: "400px" }}
                />
              </Grid>
            )}
          </Grid>
          {transactionDetail?.length > 0 && (
            <Grid
              justifyContent="center"
              alignItems="center"
              sx={{ display: "flex", marginTop: "20px" }}
            >
              <Stack spacing={2}>
                <Pagination
                  page={currentPage}
                  count={totalPage}
                  onChange={handleChangeByPage}
                  size="large"
                />
              </Stack>
            </Grid>
          )}
        </>
      ) : (
        <>
          {/* Add to wallet jsx */}
          <AddToWallet
            setAddToWalletPageHandler={setAddToWalletPageHandler}
            walletAmount={walletAmount}
            profile={profile}
            getWalletData={getWalletData}
            getTransactionData={getTransactionData}
          />
        </>
      )}
    </>
  );
};

export default WallletTab;
