import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Grid,
  Chip,
  FormControl,
  TextField,
  MenuItem,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  TableRow,
  TableSortLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Link } from "react-router-dom";
//React ICons for

// //core components
// import SelectBillBoard from "../ActionDialog/SelectBillBoard";
// import SelectUser from "../ActionDialog/SelectUser";
// import ViewDialog from "../ActionDialog/ViewDialog";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      "&:focus": {
        width: "90%",
      },
    },
  },
}));
function createData(
  name,
  calories,
  fat,
  carbs,
  protein,
  starttime,
  endtime,
  duration,
  budget
) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    starttime,
    endtime,
    duration,
    budget,
  };
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    label: "UID",
    minWidth: 100,
  },
  {
    id: "calories",
    numeric: true,
    label: "Campaigns",
    minWidth: 100,
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Status ",
    minWidth: 100,
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Start Date",
    minWidth: 100,
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "End Date",
    minWidth: 100,
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "  Start Time",
    minWidth: 100,
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "End Time",
    minWidth: 100,
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Ad duration",
    minWidth: 100,
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Budget",
    minWidth: 100,
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              align="center"
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                align="center"
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const rows = [
  createData(
    "PMA-D1",
    "New Product Launch",
    "Live",
    "24-05-22",
    "25 / 07 / 2022",
    "5 pm",
    "6 pm",
    "1m 50sec",
    "₹ 30,000"
  ),
  createData(
    "PMA-D1",
    "New Product Launch",
    "Live",
    "24-05-22",
    "25 / 07 / 2022",
    "5 pm",
    "6 pm",
    "1m 50sec",
    "₹ 30,000"
  ),
  createData(
    "PMA-D1",
    "New Product Launch",
    "Live",
    "24-05-22",
    "25 / 07 / 2022",
    "5 pm",
    "6 pm",
    "1m 50sec",
    "₹ 30,000"
  ),
  createData(
    "PMA-D1",
    "New Product Launch",
    "Live",
    "24-05-22",
    "25 / 07 / 2022",
    "5 pm",
    "6 pm",
    "1m 50sec",
    "₹ 30,000"
  ),
  createData(
    "PMA-D1",
    "New Product Launch",
    "Live",
    "24-05-22",
    "25 / 07 / 2022",
    "5 pm",
    "6 pm",
    "1m 50sec",
    "₹ 30,000"
  ),
  createData(
    "PMA-D1",
    "New Product Launch",
    "Live",
    "24-05-22",
    "25 / 07 / 2022",
    "5 pm",
    "6 pm",
    "1m 50sec",
    "₹ 30,000"
  ),
  createData(
    "PMA-D1",
    "New Product Launch",
    "Live",
    "24-05-22",
    "25 / 07 / 2022",
    "5 pm",
    "6 pm",
    "1m 50sec",
    "₹ 30,000"
  ),
  createData(
    "PMA-D1",
    "New Product Launch",
    "Live",
    "24-05-22",
    "25 / 07 / 2022",
    "5 pm",
    "6 pm",
    "1m 50sec",
    "₹ 30,000"
  ),
  createData(
    "PMA-D1",
    "New Product Launch",
    "Live",
    "24-05-22",
    "25 / 07 / 2022",
    "5 pm",
    "6 pm",
    "1m 50sec",
    "₹ 30,000"
  ),
  createData(
    "PMA-D1",
    "New Product Launch",
    "Live",
    "24-05-22",
    "25 / 07 / 2022",
    "5 pm",
    "6 pm",
    "1m 50sec",
    "₹ 30,000"
  ),
  createData(
    "PMA-D1",
    "New Product Launch",
    "Live",
    "24-05-22",
    "25 / 07 / 2022",
    "5 pm",
    "6 pm",
    "1m 50sec",
    "₹ 30,000"
  ),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

export default function CustomPaginationActionsTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [chipActive, setChipActive] = useState(true);
  const [date, setDate] = React.useState();

  const DateChange = (event) => {
    setDate(event.target.value);
  };
  const [location, setLocation] = React.useState();
  const locationChange = (event) => {
    setLocation(event.target.value);
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={7}>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Grid item xs={11}>
                <FormControl
                  className="MainPageFormControl mt10px "
                  size="small"
                >
                  <TextField
                    select
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle"
                    labelId="demo-select-small"
                    id="demo-select-small"
                    // value={date}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={DateChange}
                    defaultValue={0}
                  >
                    <MenuItem value={0} className="Selectmenustyle" disabled>
                      Select a Location
                    </MenuItem>
                    <MenuItem value={10} className="Selectmenustyle">
                      {" "}
                      Testing
                    </MenuItem>
                    <MenuItem value={20} className="Selectmenustyle">
                      Testing
                    </MenuItem>
                    <MenuItem value={30} className="Selectmenustyle">
                      Testing
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>{" "}
            </Grid>

            <Grid item xs={4}>
              <Grid item xs={11} sx={{ marginLeft: "20px" }}>
                <FormControl
                  className="MainPageFormControl mt10px "
                  size="small"
                >
                  <TextField
                    select
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle"
                    labelId="demo-select-small"
                    id="demo-select-small"
                    // value={date}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={DateChange}
                    defaultValue={0}
                  >
                    <MenuItem value={0} className="Selectmenustyle" disabled>
                      Select a Date
                    </MenuItem>
                    <MenuItem value={10} className="Selectmenustyle">
                      Testing
                    </MenuItem>
                    <MenuItem value={20} className="Selectmenustyle">
                      Testing
                    </MenuItem>
                    <MenuItem value={30} className="Selectmenustyle">
                      Testing
                    </MenuItem>
                  </TextField>
                </FormControl>{" "}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <FormControl
            className="MainPageFormControl-no-norder mt10px "
            size="small"
          >
            <Typography className="greycolor505050500"> Sort by : </Typography>
            <TextField
              select
              variant="filled"
              InputProps={{ disableUnderline: true }}
              className="Selectdropstyle"
              labelId="demo-select-small"
              id="demo-select-small"
              value={age}
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleChange}
            >
              <MenuItem value={10} className="Selectmenustyle">
                ALL
              </MenuItem>
              <MenuItem value={20}> Recently Added</MenuItem>
              <MenuItem value={30}> Revenue Low to High </MenuItem>
              <MenuItem value={30}> Revenue High to Low </MenuItem>
            </TextField>
          </FormControl>{" "}
        </Grid>
      </Grid>
      <Grid container className="width100 mt10px">
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className="width100 " sx={{ borderRadius: "10px" }}>
          <Table
            sx={{
              backgroundColor: "#fff",
              width: "100%",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              className="tablerow"
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              // rowCount={rows.length}
            />

            <TableBody className="tablerow ">
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="center"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="greycolortypo"
                        align="center"
                      >
                        <Link
                          to="/admin/campaigndetils"
                          className="greycolortypo link-style"
                        >
                          {" "}
                          {row.calories}{" "}
                        </Link>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        {chipActive ? (
                          <Chip label={row.fat} className="chipsuccescolor" />
                        ) : (
                          <Chip className="chipdeavtivecolor" label={row.fat} />
                        )}
                      </TableCell>
                      <TableCell
                        ccomponent="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="greycolortypo"
                        align="center"
                      >
                        {row.carbs}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="greycolortypo"
                        align="center"
                      >
                        {" "}
                        {row.protein}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="greycolortypo"
                        align="center"
                      >
                        {" "}
                        {row.starttime}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="greycolortypo"
                        align="center"
                      >
                        {row.endtime}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="greycolortypo"
                        align="center"
                      >
                        {row.duration}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="greycolortypo"
                        align="center"
                      >
                        {row.budget}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                    color: "white",
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Grid container>
            <TablePagination
              className="width100"
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Grid>
        </TableContainer>
      </Grid>
    </>
  );
}
