import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  Button,
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import SelectUser from "./SelectUser";
import Deposit_Analytics from "../Common_Analytics/Deposit_Analytics";
import TransactionSkeleton from "../../FinanceSkeleton/TransactionSkeleton";

import walletImage from "../../../../../assets/Img/3d_wallet_10 1.png";
import depositImg from "../../../../../assets/Img/wallet_6418298 1.png";
import totalSpent from "../../../../../assets/Img/rupee_2448368 1.png";
import totalRefund from "../../../../../assets/Img/Group.png";
import totalWithdraw from "../../../../../assets/Img/atm_1578023 1.png";
import no_data_found_img from "../../../../../assets/Img/no_transaction.png";
import Topup from "../../../../../assets/Img/topup-amount.png";
import withdraw from "../../../../../assets/Img/withdraw.png";
import axiosInstance from "../../../../../api/axiosInstance";
import { FETCH_URL } from "../../../../../fetchIp";
import useExportExcel from "../../../../../utils/useExportExcelFile";
import permissionUtils from "../../../../../component/permissionUtils";

import TabList from "../../../../../component/CustomTabs/TabList";
import TabPanelList from "../../../../../component/CustomTabs/TabPanel";
import TransactionItem from "./TransactionDetails";
const containerStyles = {
  marginTop: "30px",
  border: "1px solid #ddd",
  padding: "15px",
  borderRadius: "8px",
  height: "50vh",
};

const sectionStyles = {
  border: "1px solid #ddd",
  padding: "15px",
  borderRadius: "8px",
};

const buttonStyles = {
  padding: "5px 20px !important",
  height: "40px",
  marginTop: "8px",
};
const FilterSelect = ({ labelId, value, onChange, options }) => (
  <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
    <InputLabel id={labelId}>{labelId}</InputLabel>
    <Select labelId={labelId} id={labelId} value={value} onChange={onChange}>
      {options.map((option) => (
        <MenuItem
          value={option.value}
          key={option.value}
          className="blackcolor484848"
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const Wallet = ({ value, userId, role }) => {
  const theme = useTheme();
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [selectUserPage, setSelectUserPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionFilter, settransactionFilter] = useState("All");
  const [getWalletOverall, setgetWalletOverall] = useState();
  const [walletId, setwalletId] = useState("");
  const [walletAmount, setWalletAmount] = useState([]);
  const [ExportArr, setExportArr] = useState([]);
  const [selectDate, setSelectDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [transactionDetail, setTransactionDetail] = useState([]);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTransactionDetail([]);
    setTabValue(newValue);
    settransactionFilter("All");
  };

  const selectUserPageHandler = (bool) => {
    setSelectUserPage(bool);
  };
  const { exportToExcel } = useExportExcel();
  const handleChange = (event) => {
    setSelectDate(event.target.value);
  };
  let theMonths = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );
  let now = new Date();
  let arr = [];

  for (let i = 0; i < 3; i++) {
    let future = new Date(now.getFullYear(), now.getMonth() - i, 1);
    arr.push(future);
  }

  const dateOptions =
    arr?.map((date) => ({
      value: date.toString(),
      label: `${theMonths[date.getMonth()]} ${date.getFullYear()}`,
    })) || [];

  const getWalletData = async () => {
    try {
      const response = await axiosInstance.post(
        `${FETCH_URL}/api/payment/getWalletData`,
        { userId: userId }
      );
      const res = response.data;
      setWalletAmount(
        res?.msg?.walletBalance?.length > 0
          ? Math.round(res?.msg?.walletBalance * 100) / 100
          : 0
      );
      setwalletId(res?.msg?.userId);
    } catch (error) {
      console.log("Error in getting wallet => ", error.message);
    }
  };

  const getWalletOverallData = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/payment/getWalletOverallData/${userId}`
      );

      setgetWalletOverall(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  let firstDay;
  let lastDay;
  if (moment.isMoment(selectDate)) {
    firstDay = moment(selectDate).startOf("month").format("YYYY-MM-DD");
    lastDay = moment(selectDate).endOf("month").format("YYYY-MM-DD");
  } else {
    firstDay = moment(new Date(selectDate))
      .startOf("month")
      .format("YYYY-MM-DD");
    lastDay = moment(new Date(selectDate)).endOf("month").format("YYYY-MM-DD");
  }
  const getTransactionDetails = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `api/payment/getTransactionData?userId=${""}&filter=${transactionFilter}&startDate=${moment(
          firstDay
        ).format("YYYY-MM-DD")}&endDate=${moment(lastDay).format(
          "YYYY-MM-DD"
        )}&page=1&limit=5`
      );
      if (res.status == 200 || res.status == 201) {
        setTransactionDetail(res?.data?.msg);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log("error in gettTransaction  data", err);
    }
  };
  const fetchWithdrawalTrans = async () => {
    setLoading(true);
    try {
      const { data, status } = await axiosInstance.get(
        `api/payment/getAllWithdraw?status=${
          transactionFilter === "All" ? "" : transactionFilter
        }&userId=${""}&startDate=${firstDay}&endDate=${lastDay}&page=1&limit=5`
      );
      setTransactionDetail(data?.data?.result);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWalletOverallData();
  }, []);

  useEffect(() => {
    if (tabValue === 1) {
      fetchWithdrawalTrans();
    } else if (tabValue === 0) {
      getTransactionDetails();
    }
    // getTransactionDetails();
  }, [transactionFilter, tabValue, selectDate]);

  // useEffect(() => {
  //   if (typeof selectDate === "object") {
  //     if (selectDate?.toDateString()?.length > 0) {
  //       getTransactionDetails();
  //       return;
  //     }
  //   }
  //   if (selectDate?.length > 0) {
  //     getTransactionDetails();

  //   }
  // }, [selectDate, transactionFilter]);
  useEffect(() => {
    getWalletData();
  }, [walletId]);

  useEffect(() => {
    if (transactionDetail?.length > 0) {
      const newOrderListArr = transactionDetail?.map((a) => {
        return {
          Date: moment(a?.createdAt).format("MMM Do YYYY , h:mm a"),
          OrderID: a?.order_id,
          AmountStatus: a?.amountStatus,
          Amount: a?.amount,
          TransactionStatus: a?.transactionStatus,
          TransactionID: a?._id,
          Message: a?.message,
        };
      });
      setExportArr(newOrderListArr);
    } else {
      const newOrderListArr = [1].map((a) => {
        return {
          Data: "No data found",
        };
      });
      setExportArr(newOrderListArr);
    }
  }, [transactionDetail]);

  const walletCards = [
    {
      label: "Total Deposit",
      value: getWalletOverall?.tDeposit,
      img: depositImg,
    },
    { label: "Total Spent", value: getWalletOverall?.tSpent, img: totalSpent },
    {
      label: "Top Up",
      value: getWalletOverall?.tDepositByAdmin || 0,
      img: Topup,
    },

    {
      label: "Total Refund",
      value: getWalletOverall?.tRefund,
      img: totalRefund,
    },
    {
      label: "Total Withdraw",
      value: getWalletOverall?.tWithdraw,
      img: withdraw,
    },
    {
      label: "Available Balance",
      value: walletAmount,
      img: walletImage,
      button: value || PermissionCheck("add-money-to-user-wallet-finance"),
    },
  ];

  const transactionFilterOptions = [
    { value: "All", label: "All" },
    { value: "spent", label: "Advertisement" },
    { value: "walletDeposit", label: "Deposit" },
    { value: "refund", label: "Refund" },
    { value: "withdraw", label: "Withdraw" },
  ];
  const transactionFilterOptions1 = [
    { value: "All", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "reject", label: "Rejected" },
  ];

  const getTabLabel = (permission) => {
    const labelMap = {
      "view-transaction-completed": "Transactions",
      "view-transaction-pending": "Withdrawal",
    };
    return labelMap[permission];
  };

  const renderTransactionPanel = (transactions, isLoading, userRole) => {
    if (transactions?.length > 0) {
      return transactions.map((data) => (
        <TransactionItem
          data={data}
          key={data._id}
          value={tabValue}
          role={userRole}
          fetchWithdrawalTrans={fetchWithdrawalTrans}
          getTransactionDetails={getTransactionDetails}
        />
      ));
    } else if (isLoading) {
      return Array(5)
        .fill()
        .map((_, i) => <TransactionSkeleton key={i} />);
    } else {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <img
            src={no_data_found_img}
            alt="no transaction found"
            style={{ maxWidth: "400px" }}
          />
        </Grid>
      );
    }
  };

  const panels = [
    {
      permission: "view-transaction-completed",
      component: renderTransactionPanel(transactionDetail, loading, role),
    },
    {
      permission: "view-transaction-pending",
      component: renderTransactionPanel(transactionDetail, loading, role),
    },
  ];
  const permissionList = [
    "view-transaction-completed",
    "view-transaction-pending",
  ];

  const permissions = CheckTabsPermissions(permissionList);

  return (
    <>
      {!selectUserPage ? (
        <>
          <Grid container spacing={2}>
            {walletCards.map((card, index) => (
              <Grid
                item
                key={index}
                md={4}
                lg={index === walletCards.length - 1 ? 3 : 1.8}
                sm={6}
                xs={12}
                className="cursorpoint"
              >
                <Card className={`dashcard-home-start`}>
                  <CardContent className="card-content" sx={{ width: "100%" }}>
                    <Grid
                      container
                      justifyContent="flex-start"
                      flexDirection="row"
                      rowGap="10px"
                      gap="10px"
                    >
                      <Grid container gap="10px">
                        <Grid
                          item
                          lg={index === walletCards.length - 1 ? 5 : 7}
                        >
                          <img
                            src={card.img}
                            alt={card.label}
                            style={{
                              width: "80%",
                              height: "60px",
                            }}
                          />
                        </Grid>
                        <Grid>
                          <Typography className="lightgreycolortypo fs14px ">
                            {card.label}
                          </Typography>
                          <Typography className="blackcolor484848-700 fs1-3rem ">
                            &#x20b9; {card?.value}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {card?.button && (
                      <Grid container xs={12}>
                        <Button
                          className="deposit_btn"
                          onClick={() => selectUserPageHandler(true)}
                          sx={{
                            background:
                              "linear-gradient(141.46deg, #E141C3 -11.28%, #B937FA 50.78%, #6906C3 112.19%)",
                            color: "white",
                            borderRadius: "15px",
                            padding: "8px 0",
                            fontFamily: "oswald",
                            width: "100%",
                            mt: 2,
                          }}
                        >
                          DEPOSIT MONEY IN WALLET?
                        </Button>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Grid container justifyContent="space-between" sx={containerStyles}>
            <Deposit_Analytics bool="Deposit" />
          </Grid>
          <Grid container sx={sectionStyles} gap="15px" mt={2} mb={2}>
            <Grid container item xs={12} alignItems="center">
              <Grid item xs={6}>
                <Typography className="blackcolor484848 fontWeight_600">
                  Recent Transaction
                </Typography>
              </Grid>
              <Grid container item xs={6} justifyContent="flex-end">
                <FilterSelect
                  value={transactionFilter}
                  onChange={(e) => settransactionFilter(e.target.value)}
                  options={
                    tabValue === 0
                      ? transactionFilterOptions
                      : transactionFilterOptions1
                  }
                />
                <FilterSelect
                  labelId="Date"
                  value={selectDate}
                  onChange={(e) => handleChange(e)}
                  options={dateOptions}
                />
                <Button
                  sx={buttonStyles}
                  className="purple_btn_primary text_transform_inherit"
                  onClick={() =>
                    exportToExcel(ExportArr, "Transaction History")
                  }
                >
                  Export
                </Button>
              </Grid>
            </Grid>
            <Grid item className="width100" sx={{ minHeight: "600px" }}>
              <TabList
                md={md}
                sx={{ borderRadius: "50px 0px 50px 0px", marginBottom: "20px" }}
                value={tabValue}
                centered={true}
                classes={{
                  Tabs: "TabStyleAddDevice",
                  Tab: "TabChangesDeviceLive",
                }}
                handleChange={handleTabChange}
                getTabLabel={getTabLabel}
                permissions={permissions}
              />
              <TabPanelList
                value={tabValue}
                permissions={permissions}
                panels={panels}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <SelectUser selectUserPageHandler={selectUserPageHandler} />
        </>
      )}
    </>
  );
};

export default Wallet;
