import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  DialogContent,
  Breadcrumbs,
  Button,
  Snackbar,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

//core component that
import Sidebar from "../../../component/Sidebar/Sidebar";
import { FETCH_URL } from "../../../fetchIp";
import OverviewTab from "./ProfileTab/OverViewTab";
import LocationTab from "./ProfileTab/LocationTab";
import ScreenTab from "./ProfileTab/ScreensTab";
import OrderTab from "./ProfileTab/OrderTab";
import AnalyticsTab from "./ProfileTab/AnalyticsTab";
import axiosInstance from "../../../api/axiosInstance";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function SubAdminProfile() {
  let userId = JSON.parse(localStorage.getItem("userData")).user._id;
  const [value, setValue] = React.useState(0);

  // States

  const handleChange = (event, newValue) => {
    console.log("New Value ===>", newValue);

    setValue(newValue);
  };
  // SnackBar

  return (
    <>
      <div className="width-90LR ">
        <div className="flex-class">
          <Grid container direction="row" justifyContent="start">
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{ padding: "10px 0px" }}
            >
              <Link
                to="/admin/home"
                className="linkcolor"
                underline="hover"
                key="1"
              >
                <Typography className="purplecolortypo fs16px">
                  Dashboard{" "}
                </Typography>
              </Link>
              ,
              <Link
                to="/admin/userdashborad"
                className="linkcolor"
                underline="hover"
              >
                <Typography className="purplecolortypo fs16px">
                  Profile
                </Typography>
              </Link>
              ,
              <Link
                to="/admin/manage-user"
                className="linkcolor"
                underline="hover"
              >
                <Typography className="purplecolortypo fs16px">
                  Manage Users
                </Typography>
              </Link>
              <Typography className="blackcolortypo fs16px">
                Sub-Admin
              </Typography>
            </Breadcrumbs>
          </Grid>
        </div>
        <Grid container>
          <Grid
            container
            className="device-container mt20px"
            sx={{
              width: "100%",
              boxShadow: " 0px 1px 10px 0px #0000001A ",
            }}
            // className="mt20px"
          >
            <Grid
              item
              style={{
                width: "100%",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                centered
                className="TabStyleAddDevice"
              >
                <Tab label="Overview" className="TabChangesDevice" />
                <Tab label="Location" disabled className="TabChangesDevice" />
                <Tab label="Screens" className="TabChangesDevice" />
                <Tab label="Orders" className="TabChangesDevice" />
                <Tab label="Analytics" disabled className="TabChangesDevice" />
                <Tab label="Access" disabled className="TabChangesDevice" />
                {/* <Tab label="Wallet" disabled className="TabChangesDevice" /> */}
              </Tabs>
            </Grid>
          </Grid>
          <DialogContent style={{ padding: "0px" }}>
            <TabPanel value={value} index={0} style={{ padding: "0px" }}>
              <OverviewTab userID={userId} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <LocationTab />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ScreenTab userID={userId} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <OrderTab />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <AnalyticsTab />
            </TabPanel>
          </DialogContent>
        </Grid>
      </div>
    </>
  );
}
