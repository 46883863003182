import { Button } from "@mui/material";
import React from "react";

const DisableBtn = ({ data }) => {
  const role = JSON.parse(localStorage.getItem("userData"))?.user?.role;
  return (
    <>
      {data == "Approved" && (
        <Button className={`approved-btn width100 p15px`}>Approved</Button>
      )}
      {data == "Cancelled" && (
        <Button className={`cancelled_btn width100 p15px`}>Cancelled</Button>
      )}
      {data == "Rejected" && (
        <Button className={`reject-btn width100 p15px`}>Reject</Button>
      )}
      {data == "Live" && (
        <Button className={`reject-btn width100 p15px`}>Live</Button>
      )}
      {data == "Expired" && (
        <Button className={`expired_btn width100 p15px`}>Expired</Button>
      )}
      {data == "Published" && (
        <Button className={`published_btn width100 p15px`}>Published </Button>
      )}
      {role !== "SAdmin" && data == "Pending" && (
        <Button className={`pending_btn width100 p15px`}>Pending </Button>
      )}
    </>
  );
};

export default DisableBtn;
