import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { MAPBOX_ACCESS_KEY } from "../../../../../utils/constant";

const containerStyle = {
  width: "100%",
  height: "350px",
};

function MyComponent({ handleLatLong, longitude, lattitude }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const [lngLat, setLngLat] = useState([77.1025, 28.7041]);

  mapboxgl.accessToken = MAPBOX_ACCESS_KEY;

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: lngLat,
        zoom: 2,
      });

      marker.current = new mapboxgl.Marker()
        .setLngLat(lngLat)
        .addTo(map.current);

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false, // Disable the default marker
      });

      map.current.addControl(geocoder);

      geocoder.on("result", (e) => {
        const { center } = e.result.geometry;
        setLngLat(center);
        marker.current.setLngLat(center);
      });

      map.current.on("click", (e) => {
        setLngLat([e.lngLat.lng, e.lngLat.lat]);
        marker.current.setLngLat([e.lngLat.lng, e.lngLat.lat]);
      });
    }
  }, [lngLat]);

  useEffect(() => {
    handleLatLong(lngLat?.[1], lngLat?.[0]);
  }, [lngLat]);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div ref={mapContainer} style={containerStyle} />
    </div>
  );
}

export default React.memo(MyComponent);
