import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Stack,
  Pagination,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import TransactionSkeleton from "../FinanceSkeleton/TransactionSkeleton";
import no_data_found_img from "../../../../assets/Img/no_transaction.png";
import axiosInstance from "../../../../api/axiosInstance";
import useExportExcel from "../../../../utils/useExportExcelFile";
import permissionUtils from "../../../../component/permissionUtils";
import TabList from "../../../../component/CustomTabs/TabList";
import TabPanelList from "../../../../component/CustomTabs/TabPanel";
import TransactionItem from "./Wallet/TransactionDetails";

const sectionStyles = {
  border: "1px solid #ddd",
  padding: "15px",
  borderRadius: "8px",
};

const buttonStyles = {
  padding: "5px 20px !important",
  height: "40px",
  marginTop: "8px",
};
const FilterSelect = ({ labelId, value, onChange, options }) => (
  <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
    <InputLabel id={labelId}>{labelId}</InputLabel>
    <Select labelId={labelId} id={labelId} value={value} onChange={onChange}>
      {options.map((option) => (
        <MenuItem
          value={option.value}
          key={option.value}
          className="blackcolor484848"
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const Wallet = ({ value, userId, role }) => {
  const theme = useTheme();
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const [transactionFilter, settransactionFilter] = useState("All");
  const [ExportArr, setExportArr] = useState([]);
  const [selectDate, setSelectDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [transactionDetail, setTransactionDetail] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTransactionDetail([]);
    setTabValue(newValue);
    settransactionFilter("All");
    setCurrentPage(1);
  };

  const { exportToExcel } = useExportExcel();
  const handleChange = (event) => {
    setSelectDate(event.target.value);
  };
  let theMonths = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );
  let now = new Date();
  let arr = [];

  for (let i = 0; i < 3; i++) {
    let future = new Date(now.getFullYear(), now.getMonth() - i, 1);
    arr.push(future);
  }

  const dateOptions =
    arr?.map((date) => ({
      value: date.toString(),
      label: `${theMonths[date.getMonth()]} ${date.getFullYear()}`,
    })) || [];

  let firstDay;
  let lastDay;
  if (moment.isMoment(selectDate)) {
    firstDay = moment(selectDate).startOf("month").format("YYYY-MM-DD");
    lastDay = moment(selectDate).endOf("month").format("YYYY-MM-DD");
  } else {
    firstDay = moment(new Date(selectDate))
      .startOf("month")
      .format("YYYY-MM-DD");
    lastDay = moment(new Date(selectDate)).endOf("month").format("YYYY-MM-DD");
  }
  const getTransactionDetails = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `api/payment/getTransactionData?userId=${""}&filter=${transactionFilter}&startDate=${moment(
          firstDay
        ).format("YYYY-MM-DD")}&endDate=${moment(lastDay).format(
          "YYYY-MM-DD"
        )}&page=${currentPage}&limit=10`
      );
      if (res.status == 200 || res.status == 201) {
        setTransactionDetail(res?.data?.msg);
        setTotalPage(Math.ceil(res?.data?.length / 10));

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log("error in gettTransaction  data", err);
    }
  };
  const fetchWithdrawalTrans = async () => {
    setLoading(true);
    try {
      const { data, status } = await axiosInstance.get(
        `api/payment/getAllWithdraw?status=${
          transactionFilter === "All" ? "" : transactionFilter
        }&userId=${""}&startDate=${firstDay}&endDate=${lastDay}&page=${currentPage}&limit=10`
      );
      setTransactionDetail(data?.data?.result);
      setTotalPage(Math.ceil(data?.data?.totalPage / 10));
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tabValue === 1) {
      fetchWithdrawalTrans();
    } else if (tabValue === 0) {
      getTransactionDetails();
    }
  }, [transactionFilter, tabValue, selectDate]);

  useEffect(() => {
    if (transactionDetail?.length > 0) {
      const newOrderListArr = transactionDetail?.map((a) => {
        return {
          Date: moment(a?.createdAt).format("MMM Do YYYY , h:mm a"),
          OrderID: a?.order_id,
          AmountStatus: a?.amountStatus,
          Amount: a?.amount,
          TransactionStatus: a?.transactionStatus,
          TransactionID: a?._id,
          Message: a?.message,
        };
      });
      setExportArr(newOrderListArr);
    } else {
      const newOrderListArr = [1].map((a) => {
        return {
          Data: "No data found",
        };
      });
      setExportArr(newOrderListArr);
    }
  }, [transactionDetail]);

  const transactionFilterOptions = [
    { value: "All", label: "All" },
    { value: "spent", label: "Advertisement" },
    { value: "walletDeposit", label: "Deposit" },
    { value: "refund", label: "Refund" },
    { value: "withdraw", label: "Withdraw" },
  ];
  const transactionFilterOptions1 = [
    { value: "All", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "reject", label: "Rejected" },
  ];

  const getTabLabel = (permission) => {
    const labelMap = {
      "view-transaction-completed": "Transactions",
      "view-transaction-pending": "Withdrawal",
    };
    return labelMap[permission];
  };

  const renderTransactionPanel = (transactions, isLoading, userRole) => {
    if (transactions?.length > 0) {
      return transactions?.map((data) => (
        <TransactionItem
          data={data}
          key={data._id}
          value={tabValue}
          role={userRole}
          fetchWithdrawalTrans={fetchWithdrawalTrans}
          getTransactionDetails={getTransactionDetails}
        />
      ));
    } else if (isLoading) {
      return Array(5)
        .fill()
        .map((_, i) => <TransactionSkeleton key={i} />);
    } else {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <img
            src={no_data_found_img}
            alt="no transaction found"
            style={{ maxWidth: "400px" }}
          />
        </Grid>
      );
    }
  };

  const panels = [
    {
      permission: "view-transaction-completed",
      component: renderTransactionPanel(transactionDetail, loading, role),
    },
    {
      permission: "view-transaction-pending",
      component: renderTransactionPanel(transactionDetail, loading, role),
    },
  ];
  const permissionList = [
    "view-transaction-completed",
    "view-transaction-pending",
  ];

  const permissions = CheckTabsPermissions(permissionList);
  const handleChangeByPage = (event, value) => {
    console.log("Page Number Clicked ==> ", value);
    setCurrentPage(value);
    if (tabValue === 0) {
      getTransactionDetails();
    } else {
      fetchWithdrawalTrans();
    }
  };

  return (
    <>
      <Grid container sx={sectionStyles} gap="15px" mt={2} mb={2}>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={6}>
            <Typography className="blackcolor484848 fontWeight_600">
              Recent Transaction
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <FilterSelect
              value={transactionFilter}
              onChange={(e) => settransactionFilter(e.target.value)}
              options={
                tabValue === 0
                  ? transactionFilterOptions
                  : transactionFilterOptions1
              }
            />
            <FilterSelect
              labelId="Date"
              value={selectDate}
              onChange={(e) => handleChange(e)}
              options={dateOptions}
            />
            <Button
              sx={buttonStyles}
              className="purple_btn_primary text_transform_inherit"
              onClick={() => exportToExcel(ExportArr, "Transaction History")}
            >
              Export
            </Button>
          </Grid>
        </Grid>
        <Grid item className="width100">
          <TabList
            md={md}
            sx={{ borderRadius: "50px 0px 50px 0px", marginBottom: "20px" }}
            value={tabValue}
            centered={true}
            classes={{
              Tabs: "TabStyleAddDevice",
              Tab: "TabChangesDeviceLive",
            }}
            handleChange={handleTabChange}
            getTabLabel={getTabLabel}
            permissions={permissions}
          />
          <TabPanelList
            value={tabValue}
            permissions={permissions}
            panels={panels}
          />
          {transactionDetail?.length > 0 && (
            <Grid
              justifyContent="center"
              alignItems="center"
              sx={{ display: "flex", marginTop: "20px" }}
            >
              <Stack spacing={2}>
                <Pagination
                  page={currentPage}
                  count={totalPage}
                  onChange={handleChangeByPage}
                  size="large"
                />
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Wallet;
