import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import DonutChart from "./AllCharts/DonutChart";
import BarChart from "./AllCharts/BarChart";
import BillboardAnalyticsTable from "./AllCharts/BillboardTable";
import axiosInstance from "../../../../api/axiosInstance";
const AllAnalyticsTab = () => {
  const [dataType, setDataType] = useState("locationType");
  const [donutData, setDonutData] = useState([]);
  const [showTypeArr, setShowTypeArr] = useState([]);
  const locationTypeArr = [
    { _id: "Outdoor", color: "#FCECB0", count: 0 },
    { _id: "Indoor", color: "#C3F8DF ", count: 0 },
  ];
  const billboardTypeArr = [
    { _id: "KIOSK", color: "rgba(254, 174, 174, 0.7)", count: 0 },
    { _id: "Standing", color: "rgba(208, 232, 255, 0.7)", count: 0 },
    { _id: "TV", color: "rgba(251, 227, 142, 0.7)", count: 0 },
    { _id: "Billboard", color: "rgba(169, 244, 208, 0.7)", count: 0 },
    { _id: "Window", color: "rgba(154, 137, 255, 0.7)", count: 0 },
    { _id: "Monopole", color: "rgba(253, 208, 159, 0.7)", count: 0 },
  ];
  const billboardOrientation = [
    { _id: "1920X1080", color: "rgba(254, 174, 174, 0.7)", count: 0 },
    { _id: "1080X1920", color: "rgba(208, 232, 255, 0.7)", count: 0 },
  ];
  //////////////getCircularGraphData//////////////////////
  const getCircularGraphData = async () => {
    try {
      const res = await axiosInstance(
        `/api/billboard/getBillboardValueByKey?type=${dataType}`
      );
      if (res.status == 200) {
        setDonutData(res?.data?.data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCircularGraphData();
    setShowTypeArr(
      dataType == "locationType"
        ? locationTypeArr
        : dataType == "billboardType"
        ? billboardTypeArr
        : dataType == "billboardSize"
        ? billboardOrientation
        : []
    );
  }, [dataType]);
  useEffect(() => {
    for (let i = 0; i < showTypeArr?.length; i++) {
      const index = donutData?.findIndex(
        (a) => a?._id?.toLowerCase() == showTypeArr?.[i]?._id?.toLowerCase()
      );

      if (index >= 0) {
        setShowTypeArr((current) =>
          current?.map((obj) => {
            if (
              obj?._id?.toLowerCase() == donutData?.[index]?._id?.toLowerCase()
            ) {
              return {
                ...obj,
                count: donutData?.[index]?.count,
              };
            }
            return obj;
          })
        );
      }
    }
  }, [donutData]);
  /////////// sending this function to BillboardAnalyticsTable ////////////////
  const changeTableDataHandler = (data) => {
    if (data?.length == 0) {
      setShowTypeArr((current) =>
        current?.map((obj) => {
          return {
            ...obj,
            revanues: 0,
            views: 0,
            orders: 0,
            billboard: 0,
          };
        })
      );
    }
    for (let i = 0; i < showTypeArr?.length; i++) {
      const index = data?.findIndex(
        (a) => a?._id?.toLowerCase() == showTypeArr?.[i]?._id?.toLowerCase()
      );
      if (index >= 0) {
        setShowTypeArr((current) =>
          current?.map((obj) => {
            if (obj?._id?.toLowerCase() == data[index]?._id?.toLowerCase()) {
              return {
                ...obj,
                revanues: data[index]?.revanues,
                views: data[index]?.views,
                orders: data[index]?.orders,
                billboard: data[index]?.billboard,
              };
            }

            return obj;
          })
        );
      }
    }
  };

  return (
    <div>
      {" "}
      <div className="mb20px">
        <Grid container justifyContent="center" gap="10px">
          <Button
            className={
              dataType === "locationType"
                ? "select_date_filter_class"
                : "filterButtonDesign"
            }
            onClick={() => setDataType("locationType")}
          >
            {" "}
            Billboard Location
          </Button>{" "}
          <Button
            className={
              dataType === "billboardSize"
                ? "select_date_filter_class"
                : "filterButtonDesign"
            }
            onClick={() => setDataType("billboardSize")}
          >
            Billboard Orientation
          </Button>{" "}
          <Button
            className={
              dataType === "billboardType"
                ? "select_date_filter_class"
                : "filterButtonDesign"
            }
            onClick={() => setDataType("billboardType")}
          >
            {" "}
            Billboard Type
          </Button>{" "}
        </Grid>
      </div>
      <div>
        <Grid container justifyContent="space-between" rowGap="20px">
          <Grid item xs={12} md={4} className=" card_div_new">
            <Grid container justifyContent="center">
              <DonutChart showTypeArr={showTypeArr} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={7.8} className=" card_div_new">
            <BarChart billboardType={dataType} showTypeArr={showTypeArr} />
          </Grid>
        </Grid>
        <div style={{ marginTop: "20px" }}>
          <BillboardAnalyticsTable
            billboardType={dataType}
            changeTableDataHandler={changeTableDataHandler}
            showTypeArr={showTypeArr}
          />
        </div>
      </div>
    </div>
  );
};

export default AllAnalyticsTab;
