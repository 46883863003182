import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Typography,
  InputBase,
  MenuItem,
  FormControl,
  TextField,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "../../../../../api/axiosInstance";
import permissionUtils from "../../../../../component/permissionUtils";

import moment from "moment";
import MultipleSelectMenuItem from "../../../../../component/MultipleSelectMenuItem/MultipleSelectMenuItem";
// pagination
import Pagination from "@mui/material/Pagination";
/////////
import Pizza from "../../../../../assets/Img/orderCampaignBanner.png";
import noOrderImage from "../../../../../assets/Img/no_data_found_img.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
// skeleten loading
import Skeleton from "@mui/material/Skeleton";
import { FETCH_URL } from "../../../../../fetchIp";
import use12HrTime from "../../../../../utils/use12HrTime";
///// grid Icon
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import OrderOverviewTable from "./../OrderOverviewTable";
// skeleten loading
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "#ffffff",
  "&:hover": {
    backgroundColor: "#ffffff",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0.7, 0.7, 0.7, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      "&:focus": {
        width: "90%",
      },
    },
  },
}));

//=============================== Order =============================================//
const options = [
  "Bar/restaurant",
  "Bar/restaurant TV",
  "Bowling Alley",
  "Casual Diner",
  "Cinema In-theatre",
  "Cinema lobby",
  "College Campus",
  "College",
  "General Store",
  "Convenience Store",
  "Dental Clinic",
  "Doctor Waiting room",
  "Hospital lobby",
  "Hospital Floor",
  "School",
  "School Lobby",
  "Medical Store",
  "Petrol Pump",
  "Golf Course",
  "Gym",
  "Hotel",
  "Hotel Lobby",
  "Liquor/Wine/Beer Shop",
  "Resort",
  "Offices",
  "Socities",
  "Stadium",
  "Coffee Shop",
  "Sports Complex",
  "Salon(Women)",
  "Salon(Men)",
  "Shopping Mall",
  "Hospital",
  "Airport",
  "Airport Lounge",
  "Railway Station",
  "Railway Station Platform",
  "Metro Station Check in",
  "Metro Station Subway",
  "Bus Stand",
  "Bus Stop",
  "Bus Interior",
  "Taxi",
  "Taxi Interior",
  "Food truck",
  "Metro Station",
];

export default function Order({
  paginatedPage,
  paginateTabValue,
  getPaginatedValueHandler,
  tabValue,
}) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const selectedTab = queryParams.get("page");

  const [currentPage, setCurrentPage] = React.useState(1);

  let otherOrders = location.pathname == "/admin/otherOrder";

  const userId = JSON.parse(localStorage.getItem("userData"))?.user?._id;
  const userRole = JSON.parse(localStorage.getItem("userData"))?.user?.userRole;

  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();
  const storedFilterSettings = JSON.parse(
    localStorage.getItem("filterSettings")
  )?.type;

  const LocalType = storedFilterSettings;

  const { timeslot } = use12HrTime();

  const navigate = useNavigate();

  const [totalPage, setTotalPage] = useState(null);

  const [loading, setLoading] = useState(false);
  //========================= states =========================>
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, setType] = useState(LocalType ? LocalType : "All");
  const [starttimeSlot, setStartTimeSlot] = useState(null);
  const [endtimeSlot, setEndTimeSlot] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timetype, setTimetype] = useState((timetype) => {
    if (moment(new Date()).format("HH") >= 12) {
      return "PM";
    } else if (moment(new Date()).format("HH") < 12) {
      return "AM";
    }
  });
  const [ordersArr, setOrdersArr] = useState([]);
  // advanced dates filter
  const [billtype, setBillBoardType] = useState("All");
  const [locationType, setLoactionType] = useState("All");
  const [venueType, setVenueType] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [billboardOptions, setBillboardOptions] = useState([]);
  const [billtypeForApi, setBillBoardTypeForApi] = useState(null);
  const [locationTypeForApi, setLoactionTypeForApi] = useState(null);
  const [venueTypeForApi, setVenueTypeForApi] = useState(null);
  const [stateForApi, setStateForApi] = useState(null);
  const [cityForApi, setCityForApi] = useState(null);
  const [expand, setExpand] = React.useState(false);
  const [search, setSearch] = useState("");
  const [allAdminStates, setAllAdminStates] = useState([]);
  const [allAdminCity, setAllAdminCity] = useState([]);
  const [gridfilter, setGridFilter] = useState("grid");
  const [OrdersArrayLength, setOrdersArrayLength] = useState([]);
  const [stateData, setStateData] = useState(null);
  const [regiondata, setRegiondata] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [cityData, setCityData] = useState(null);

  // Save filter settings to local storage
  const saveFilterSettings = (callback) => {
    // Save settings
    localStorage.setItem(
      "filterSettings",
      JSON.stringify({
        startDate,
        endDate,
        type,
        // Other filter settings
      })
    );

    // Invoke the callback function to navigate
    let settingsSaved = true;

    if (typeof callback === "function" && settingsSaved) {
      callback(); // Trigger the callback function for navigation
    }
  };
  // advanced dates filter
  // helper function
  const stateChangeHandler = () => {
    setBillBoardTypeForApi(billtype);
    setLoactionTypeForApi(locationType);
    setVenueTypeForApi(venueType);
    setStateForApi(state);
    setCityForApi(city);
  };
  // clearFilter

  const handleChangeByPage = (event, value) => {
    // getPaginatedValueHandler("", value, 0);
    searchParams.set("page", value);

    setCurrentPage(value);
    setSearchParams(searchParams);
  };
  useEffect(() => {
    searchParams.set("page", 1);
    setSearchParams(searchParams);
  }, [tabValue]);
  const dataSelectHandler = (e) => {
    setVenueType(e);
  };
  // Use an effect to save filter settings whenever they change

  const handleData = (data, datatype) => {
    if (datatype == "startDate") {
      setStartDate(moment(data).format("YYYY-MM-DD"));
    } else if (datatype == "endDate") {
      setEndDate(moment(data).format("YYYY-MM-DD"));
    } else if (datatype == "startTime") {
      setStartTime(data);
      setStartTimeSlot(moment(data).format("HH"));
      if (moment(data).format("HH") >= 12) {
        setTimetype("PM");
      } else if (moment(data).format("HH") < 12) {
        setTimetype("AM");
      }
    } else if (datatype == "endTime") {
      setEndTime(data);
      setEndTimeSlot(moment(data).format("HH"));
    } else if (datatype == "timeslot") {
    } else if (datatype == "type") {
      setType(data);
    }
  };
  // Initialize the filter settings from local storage on component mount
  useEffect(() => {
    const storedFilterSettings = localStorage.getItem("filterSettings");

    if (storedFilterSettings) {
      const filterSettings = JSON.parse(storedFilterSettings);
      setStartDate(filterSettings.startDate);
      setEndDate(filterSettings.endDate);
      setType(filterSettings.type);
      // Set other filter settings as well
    }
  }, []);
  useEffect(() => {
    localStorage.removeItem("filterSettings");
  }, []);
  const buttonColor = (status) => {
    if (status === "Approved") {
      return "order-green-btn";
    }
    if (status === "Pending") {
      return "order-orange-btn ";
    }
    if (status === "Live") {
      return "order-white-btn ";
    }
    if (status === "Rejected") {
      return "red-colorbtn ";
    }
    if (status === "Scheduled") {
      return "order-light-purple-btn";
    }
    if (status === "Published") {
      return "order-purple-btn_20br  ";
    }
    if (status === "Expired") {
      return "order-Expired-btn  ";
    }
    if (status === "Cancelled") {
      return "order-Cancelled-btn  ";
    }
  };
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };
  const clearOrder = () => {
    setStartDate(null);
    setEndDate(null);
    setType("All");
    setSearch("");
    setStartTimeSlot(null);
    setEndTimeSlot(null);
    setLoactionType("All");
    setBillBoardType("All");
    setStateData(null);
    setCityData(null);
    localStorage.removeItem("filterSettings");
  };
  //  api call
  const testValue =
    tabValue === 1
      ? true
      : tabValue === 0
      ? false
      : tabValue === 2
      ? null
      : false;
  console.log("Check otherOrders", otherOrders);
  const getOrderData = async () => {
    setLoading(true);
    let resp;
    try {
      let body;
      body = {
        search: search,
        status: type,
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : startDate,
        startHour: starttimeSlot ? +starttimeSlot : null,
        endHour: endtimeSlot ? +endtimeSlot : null,
        orientation: locationType,
        venue: billtype,
        state: stateData,
        city: cityData,
        isTesting: testValue,
        page: currentPage,
        limit: 10,
      };
      if (userRole == 6) {
        resp = await axios.get(
          `api/order/getMyOrdersByUser?search=${
            search == "" ? null : search
          }&startDate=${startDate}&endDate=${endDate}&startHours=${
            starttimeSlot ? +starttimeSlot : null
          }&page=${currentPage}&limit=10&isCampaign=false&userId=${userId}&status=${type}&isTesting=${testValue}`
        );
      } else {
        resp = await axios.post(`/api/order/orderList`, body);
      }
      if (userRole == 2) {
        if (otherOrders) {
          resp = await axios.post(`/api/order/orderList`, body);
        } else {
          resp = await axios.get(
            `api/order/getMyOrdersByUser?search=${
              search == "" ? null : search
            }&startDate=${startDate}&endDate=${endDate}&startHours=${
              starttimeSlot ? +starttimeSlot : null
            }&page=${currentPage}&limit=10&isCampaign=false&userId=${userId}&status=${type}&isTesting=${testValue}`
          );
        }
      }
      if (resp) {
        setLoading(false);
        setOrdersArrayLength(resp?.data?.length);
        setOrdersArr(resp?.data?.data);
        setTotalPage(Math.ceil(resp?.data?.length / 10));
      }
    } catch (err) {
      setLoading(false);
      console.log("error from getOrder => ", err);
    }
  };

  const getAllCountry = async () => {
    try {
      let res = await axios.get("/api/billboard/location");
      setRegiondata(res?.data?.msg);
      setStateList(res?.data?.msg.map((state) => state.state));
      setLoading(false);
    } catch (error) {
      console.log("error from GETTing states ", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllCountry();
  }, []);
  const getAllStates = async (state) => {
    try {
      let res = await axios.get(
        `/api/billboard/city/location?state=${state}&page=1&limit=15`
      );
      setRegiondata(res?.data?.msg);
      setCityList(res?.data?.msg.map((district) => district.district));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error from GETTing citiea ", error.message);
    }
  };
  const getAllCities = async (district) => {
    try {
      let res = await axios.get(
        `/api/billboard/pincode/city/location?district=${district}`
      );
      setRegiondata(res?.data?.msg);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error from GETTing citiea pin ", error.message);
    }
  };
  useEffect(() => {
    if (
      billtypeForApi == billtype &&
      venueTypeForApi == venueType &&
      stateForApi == state &&
      cityForApi == city &&
      locationTypeForApi == locationType &&
      otherOrders
    ) {
      getOrderData(currentPage);
    }
  }, [
    billtypeForApi,
    venueTypeForApi,
    stateForApi,
    cityForApi,
    locationTypeForApi,
    otherOrders,
  ]);
  useEffect(() => {
    setCity(null);
  }, [state]);
  useEffect(() => {
    if (tabValue === 0 || (tabValue && otherOrders)) {
      getOrderData();
    }
  }, [tabValue, otherOrders]);

  useEffect(() => {
    getOrderData();
  }, [
    startDate,
    endDate,
    startTime,
    endTime,
    type,
    timetype,
    currentPage,
    stateData,
    cityData,
    search,
    otherOrders,
  ]);
  useEffect(() => {
    getAllStates();
  }, []);

  return (
    <>
      <div className="mb70px">
        <Grid container className=" ">
          <Accordion className="ArrowIcon accordianstyle100 " expanded={expand}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  onClick={(e) => {
                    toggleAcordion(e.target.value);
                  }}
                  className="purple-icon"
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  style={{ margin: "0px 20px" }}
                />
              }
              className="accordion-summary"
            >
              <Grid container item xs={12} className="mt10px" rowGap="10px">
                <Grid item md={3} sm={6}>
                  <Typography className="blackcolortypo ">
                    What are you looking for?
                  </Typography>
                  <Grid item xs={11} className="mt10px">
                    <Search className="search-order ">
                      <SearchIconWrapper>
                        <SearchIcon className="searchicon" />
                      </SearchIconWrapper>
                      <StyledInputBase
                        className="fs14px"
                        // style={{ padding: "3px 4px 4px 4px " }}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setCurrentPage(1);
                        }}
                        placeholder="Search Order id, name"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Search>
                  </Grid>
                </Grid>
                <Grid item md={2.3} sm={6}>
                  <Grid item xs={12}>
                    <Typography className="blackcolortypo ">
                      What is the status of order?
                    </Typography>
                  </Grid>
                  <Grid item xs={11} className="mt10px">
                    <FormControl
                      className="MainPageFormControl grey-border"
                      size="small"
                    >
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle"
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={type}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => {
                          handleData(e.target.value, "type");
                        }}
                      >
                        <MenuItem
                          value={"All"}
                          className="blackcolor484848 fs13px"
                        >
                          All
                        </MenuItem>
                        <MenuItem
                          value={"Pending"}
                          className="blackcolor484848 fs13px"
                        >
                          Pending
                        </MenuItem>
                        <MenuItem
                          value={"Approved"}
                          className="blackcolor484848 fs13px"
                        >
                          Approved
                        </MenuItem>
                        <MenuItem
                          value={"Live"}
                          className="blackcolor484848 fs13px"
                        >
                          Live
                        </MenuItem>
                        <MenuItem
                          value={"Rejected"}
                          className="blackcolor484848 fs13px"
                        >
                          Rejected
                        </MenuItem>
                        <MenuItem
                          value={"Published"}
                          className="blackcolor484848 fs13px"
                        >
                          Published
                        </MenuItem>
                        <MenuItem
                          value={"Expired"}
                          className="blackcolor484848 fs13px"
                        >
                          Expired
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item md={3.5} sm={6}>
                  {/* ================================== Date Range ======================= */}
                  <Grid item xs={12}>
                    <Typography className="blackcolortypo  ">
                      Date Range
                    </Typography>
                  </Grid>
                  <Grid container className="mt10px">
                    <Grid item xs={5.5}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          className="orderrangepicker"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          inputFormat="MM/dd/yyyy"
                          value={startDate}
                          onChange={(e) => {
                            handleData(e, "startDate");
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="filled"
                              className="width100 orderrangepicker"
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "Start date",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={1} xs={1} className="pt5px">
                      <Typography
                        align="center"
                        className="greycolor505050500 "
                      >
                        to
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="MM/dd/yyyy"
                          value={endDate}
                          onChange={(e) => {
                            handleData(e, "endDate");
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="filled"
                              className="width100 orderrangepicker"
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "End date",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3.2} sm={6}>
                  {/* ================= Start Time =========================== */}
                  <Typography className="blackcolortypo">Time Slot </Typography>
                  <Grid container className="mt10px">
                    <Grid item xs={5.5}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          InputProps={{
                            disableUnderline: true,
                          }}
                          minTime={false}
                          views={["hours"]}
                          disableMinutes={true}
                          value={startTime}
                          onChange={(newValue) => {
                            handleData(newValue, "startTime");
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="width100 orderrangepicker"
                              {...params}
                              variant="filled"
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "Start Time",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>{" "}
                    <Grid item xs={1} className="pt5px">
                      <Typography
                        align="center"
                        className="greycolor505050500 "
                      >
                        to
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          // disabled={true}
                          value={endTime}
                          minTime={false}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          onChange={(newValue) => {
                            handleData(newValue, "endTime");
                          }}
                          disableMinutes={true}
                          format="24hr"
                          views={["hours"]}
                          // onChange={(newValue) => {
                          //   handleData(newValue, "endTime");
                          // }}
                          renderInput={(params) => (
                            <TextField
                              className="width100 orderrangepicker"
                              {...params}
                              variant="filled"
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "End Time",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>{" "}
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>

            <AccordionDetails>
              {" "}
              <Grid container spacing={2} className="pt10px">
                <Grid item xs={6} sm={4} md={2}>
                  <Typography className="blackcolortypo">
                    Billboard Orientation{" "}
                  </Typography>
                  <Grid container className="mt10px ">
                    <FormControl
                      className="MainPageFormControl   "
                      size="small"
                    >
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle-noborder"
                        labelId="demo-select-small"
                        id="demo-select-small"
                        inputProps={{ "aria-label": "Without label" }}
                        defaultValue={"All"}
                        value={locationType ? locationType : "All"}
                        onChange={(e) => {
                          setLoactionType(e.target.value);
                        }}
                      >
                        <MenuItem
                          value={"All"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="blackcolor484848 fs13px">
                            All{" "}
                          </Typography>
                        </MenuItem>

                        <MenuItem
                          value={"1080X1920"}
                          className="Selectmenustyle"
                        >
                          <Typography className="blackcolor484848 fs13px">
                            1080X1920
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          value={"1920X1080"}
                          className="Selectmenustyle"
                        >
                          <Typography className="blackcolor484848 fs13px">
                            1920X1080
                          </Typography>
                        </MenuItem>
                        <MenuItem value={"auto"} className="Selectmenustyle">
                          <Typography className="blackcolor484848 fs13px">
                            auto
                          </Typography>
                        </MenuItem>
                        <MenuItem value={"Adani"} className="Selectmenustyle">
                          <Typography className="blackcolor484848 fs13px">
                            Adani
                          </Typography>
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  <Typography className="blackcolortypo"> Venue</Typography>
                  <FormControl
                    className="MainPageFormControl mt10px grey-border "
                    size="small"
                  >
                    <TextField
                      select
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      className="Selectdropstyle"
                      labelId="demo-select-small"
                      id="demo-select-small"
                      defaultValue={"All"}
                      value={billtype ? billtype : "All"}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={(e) => {
                        setBillBoardType(e.target.value);
                      }}
                    >
                      <MenuItem
                        value={"All"}
                        className="blackcolor484848 fs13px"
                      >
                        <Typography className="blackcolor484848 fs13px">
                          All{" "}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        value={"Indoor"}
                        className="blackcolor484848 fs13px"
                      >
                        <Typography className="blackcolor484848 fs13px">
                          Indoor{" "}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        value={"Outdoor"}
                        className="blackcolor484848 fs13px"
                      >
                        <Typography className="blackcolor484848 fs13px">
                          Outdoor{" "}
                        </Typography>
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>

                {/* <Grid item xs={6} sm={4} md={2}>
                  <Typography
                    className="blackcolortypo"
                    sx={{ marginBottom: "10px" }}
                  >
                    Venue Type
                  </Typography>
                  <div>
                    {" "}
                    <MultipleSelectMenuItem
                      MulClass="multiple_select"
                      options={options}
                      dataSelectHandler={dataSelectHandler}
                    />
                  </div>
                </Grid> */}
                <Grid item xs={6} sm={4} md={2}>
                  <Typography className="blackcolortypo">State</Typography>
                  <Grid container className="mt10px ">
                    <FormControl className="MainPageFormControl  " size="small">
                      <TextField
                        placeholder="hello"
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle"
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={stateData?.length > 0 ? stateData : 1}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => {
                          getAllStates(e.target.value);
                          setStateData(e.target.value);
                        }}
                      >
                        <MenuItem
                          value={1}
                          className="blackcolor484848 fs13px"
                          disabled
                          //onChange={OrderStatusClick}
                        >
                          Please select state
                        </MenuItem>
                        {stateList?.map((data) => (
                          <MenuItem
                            value={data}
                            className="blackcolor484848 fs13px"
                            //onChange={OrderStatusClick}
                          >
                            {data}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  <Typography className="blackcolortypo">City</Typography>
                  <Grid container className="mt10px ">
                    <FormControl className="MainPageFormControl  " size="small">
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle"
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={cityData?.length > 0 ? cityData : 1}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => {
                          getAllCities(e.target.value);
                          setCityData(e.target.value);
                        }}
                      >
                        <MenuItem
                          value={1}
                          className="blackcolor484848 fs13px"
                          disabled
                        >
                          Please select district
                        </MenuItem>
                        {cityList?.map((data, i) => (
                          <MenuItem
                            key={i}
                            value={data}
                            className="blackcolor484848 fs13px"
                          >
                            {data}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>{" "}
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  {/* <Typography align="center" className="width100"> */}
                  <Button
                    className="applypurplebtn mt33px "
                    sx={{ width: "100% !important" }}
                    onClick={() => stateChangeHandler()}
                  >
                    Apply Filter
                  </Button>

                  {/* </Typography> */}
                </Grid>
                <Grid item xs={3} sm={4} md={2}>
                  {/* <Typography align="center" className="width100"> */}
                  <Button
                    className="applypurplebtn mt33px "
                    sx={{ width: "100% !important" }}
                    onClick={() => clearOrder()}
                  >
                    Clear filter
                  </Button>

                  {/* </Typography> */}
                </Grid>
              </Grid>{" "}
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* grid view vs table view */}
        <Grid
          container
          className="pt10px"
          direction="row"
          justifyContent="space-between"
        >
          <div>
            <Typography
              align="center"
              className="fiter-heading-typo family-color"
            >
              {" "}
              Showing {ordersArr?.length} out of {OrdersArrayLength} orders
            </Typography>
          </div>
          <div>
            <TableRowsRoundedIcon
              sx={{
                fontSize: "29px",
              }}
              style={{
                marginRight: "8px",
                cursor: "pointer",
                color: gridfilter === "table" ? "#b937fa" : "",
              }}
              onClick={() => setGridFilter("table")}
            ></TableRowsRoundedIcon>
            <GridViewRoundedIcon
              sx={{
                fontSize: "30px",
              }}
              style={{
                marginRight: "8px",
                cursor: "pointer",
                color: gridfilter === "grid" ? "#b937fa" : "",
              }}
              onClick={() => setGridFilter("grid")}
            ></GridViewRoundedIcon>
          </div>
        </Grid>

        {/* table wise data */}
        {gridfilter === "table" && (
          <OrderOverviewTable
            ordersArr={ordersArr}
            startDate={startDate}
            endDate={endDate}
            OrdersArrayLength={OrdersArrayLength}
            type={type}
            starttimeSlot={starttimeSlot}
            endtimeSlot={endtimeSlot}
            currentPage={currentPage}
            locationType={locationType}
            billtype={billtype}
            stateData={stateData}
            cityData={cityData}
            search={search}
          />
        )}
        {/* ==================================== OrderCards js ============================== */}
        {gridfilter === "grid" && (
          <Grid container className="pt-9px" spacing={3}>
            {ordersArr && ordersArr?.length > 0 ? (
              ordersArr.map((a, i) => (
                <Grid item md={6} sm={12} className="mt20px" key={i}>
                  <Grid
                    container
                    className="box-shadow bg-white relative"
                    sx={{ border: "1px solid #ddd", borderRadius: "8px" }}
                  >
                    <Grid item md={3} sm={3}>
                      <Grid item className="relative">
                        <Grid item className="orderbtn-itemL">
                          <Button
                            // className={"order-orange-btn width100 mt--10px "}
                            className={`${buttonColor(
                              a.status
                            )}  width100 mt--10px`}
                          >
                            {a?.status}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item sx={{ height: "100%" }}>
                        <img
                          src={Pizza}
                          alt="pizza"
                          className="width100"
                          style={{ height: "100%" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      md={9}
                      sm={9}
                      className="p10px"
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid item className="relative">
                        {PermissionCheck("view-order-detail") && (
                          <Grid item className="orderbtn-itemR">
                            <Button
                              className="order-purple-btn   width-20  min-width-120px "
                              sx={{ fontWeight: "500 !important" }}
                              onClick={() =>
                                navigate(`/admin/order/${a._id}`, {
                                  state: { a },
                                })
                              }
                            >
                              View
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                      <Typography className="greycolor505050500 mt25px">
                        Order id : #{a.orderId}
                      </Typography>
                      <Typography
                        className="blackcolortypo mt10px"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {a?.adTitle?.length > 40
                          ? `${a?.adTitle?.substring(0, 40)}...`
                          : a?.adTitle}
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className="mt10px"
                      >
                        <Grid item>
                          <Typography className="greycolor505050500">
                            {a?.startscheduleDate && a?.startscheduleDate}
                            {/* 05th April 2022 */}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className="greycolor505050500">
                            {a?.timeSlots?.length}
                            {""} Timeslots
                          </Typography>
                        </Grid>{" "}
                        <Grid item>
                          <Typography className="greycolor505050500">
                            Duration {""}
                            {a?.durations}
                            {/* Duration: 30 sec */}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className="order_views">
                      <Grid
                        container
                        alignItems="center"
                        gap="10px"
                        className="order_view_div"
                      >
                        <VisibilityIcon className="purple-icon" />
                        <Typography className="purplecolortypo-500 fs14px">
                          {a?.totalViews}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : loading ? (
              <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((a, i) => (
                  <Grid item md={6} sm={12} className="mt20px">
                    <Grid
                      key={i}
                      container
                      className="box-shadow bg-white relative"
                      sx={{ border: "1px solid #ddd", borderRadius: "8px" }}
                    >
                      <Grid item md={3} sm={3}>
                        <Grid item className="relative">
                          <Grid
                            item
                            className="orderbtn-itemL"
                            sx={{
                              marginTop: "-20px",
                              borderRadius: "16px",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              sx={{
                                width: "100%",
                                fontSize: "2rem",
                                borderRadius: "16px",
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item sx={{ height: "100%" }}>
                          <Skeleton
                            variant="rectangular"
                            sx={{ width: "100%", height: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        md={9}
                        sm={9}
                        className="p10px"
                        direction="column"
                        justifyContent="space-between"
                      >
                        <Grid item className="relative">
                          <Grid item className="orderbtn-itemR">
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "2rem", width: "70px" }}
                            />
                          </Grid>
                        </Grid>
                        <Typography className="greycolor505050400 mt25px">
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "70%" }}
                          />
                        </Typography>
                        <Typography className="blackcolortypo mt10px">
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "70%" }}
                          />
                        </Typography>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="mt10px"
                        >
                          <Grid item>
                            <Typography className="greycolor505050500">
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "50px" }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography className="greycolor505050500">
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "50px" }}
                              />
                            </Typography>
                          </Grid>{" "}
                          <Grid item>
                            <Typography className="greycolor505050500">
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "50px" }}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="order_views">
                        <Grid container alignItems="center" gap="10px">
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1.2rem", width: "50px" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : (
              <>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className="relative"
                  style={{ height: "500px" }}
                >
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={noOrderImage}
                      alt="no order found"
                      style={{ width: "35%" }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {ordersArr.length > 0 && (
          <Grid
            justifyContent="center"
            alignItems="center"
            sx={{ display: "flex", marginTop: "20px" }}
          >
            <Stack spacing={2}>
              <Pagination
                page={currentPage}
                count={totalPage}
                onChange={handleChangeByPage}
                size="large"
              />
            </Stack>
          </Grid>
        )}
      </div>
    </>
  );
}
