import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Input,
  Snackbar,
  Tabs,
  Tab,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { MuiOtpInput } from "mui-one-time-password-input";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "../../api/axiosInstance";
import Logo from "../../assets/Img/fulllogoPMA.png";
import LoginScreenImage from "../../assets/Img/LoginScreenImage.png";

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <div>{children}</div>}
  </div>
);

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const [snackErrorOpen, setSnackErrorOpen] = useState(false);
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [mfaCode, setMfaCode] = useState(false);
  const [otp, setOtp] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [authenticatorApp, setAuthenticatorApp] = useState(false);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setOtp("");
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackOpen(false);
    setSnackMsg("");
  };

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackErrorOpen(false);
    setSnackErrMsg("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/user/signin", {
        email,
        password,
      });
      if (response.status === 200) {
        const {
          authenticatorVerification,
          emailVerification,
          user,
          token,
          refreshToken,
        } = response.data;
        const role = user?.userRole;
        if (authenticatorVerification || emailVerification) {
          setMfaCode(true);
          setAuthenticatorApp(!!authenticatorVerification);
        } else if ([0, 1, 2, 6].includes(role)) {
          localStorage.setItem(
            "userData",
            JSON.stringify({ user, token, refreshToken })
          );
          localStorage.setItem("access_token", token);
          window.dispatchEvent(new Event("storage"));
          navigate("/admin/home");
        } else {
          setSnackErrorOpen(true);
          setSnackErrMsg("Wrong credentials");
        }
      }
    } catch (err) {
      console.error("Login error: ", err);
      setSnackErrorOpen(true);
      setSnackErrMsg(err.response?.data?.msg || "An error occurred");
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/user/verifyMFACode", {
        username: email,
        code: otp,
        deviceId: "",
        type: tabValue === 0 ? "email" : "authenticatorApp",
      });

      if (response.status === 200) {
        const { user, token, refreshToken } = response.data;
        const role = user?.userRole;
        if ([0, 1, 2, 6].includes(role)) {
          localStorage.setItem(
            "userData",
            JSON.stringify({ user, token, refreshToken })
          );
          localStorage.setItem("access_token", token);
          window.dispatchEvent(new Event("storage"));
          navigate("/admin/home");
        } else {
          setSnackErrorOpen(true);
          setSnackErrMsg("Wrong credentials");
          navigate("/signIn");
        }
      }
    } catch (err) {
      console.error("Verification error: ", err);
      setSnackErrorOpen(true);
      setSnackErrMsg(err.response?.data?.msg || "An error occurred");
    }
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackErrorOpen}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid item md={6} lg={6} sm={12} sx={{ padding: "40px 60px" }}>
        <Grid container>
          <img src={Logo} alt="Logo" style={{ width: "40%" }} />
        </Grid>
        <Typography className="mt70px greycolor505050400 fs20px">
          Login To Continue
        </Typography>
        <Typography className="lightgreycolortypo mt5px">
          Enter your login details to access your account.
        </Typography>

        {!mfaCode ? (
          <form onSubmit={handleSubmit} className="mt40px">
            <Input
              className="inputstyle-signIn"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              disableUnderline
            />
            <Grid container style={{ position: "relative" }}>
              <Input
                type={showPassword ? "text" : "password"}
                className="inputstyle-signIn"
                placeholder="Password"
                disableUnderline
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Grid item sx={{ position: "absolute", top: 21, right: 10 }}>
                {showPassword ? (
                  <VisibilityOff onClick={() => setShowPassword(false)} />
                ) : (
                  <Visibility onClick={() => setShowPassword(true)} />
                )}
              </Grid>
            </Grid>
            <Button
              variant="outlined"
              type="submit"
              className="navbuttonstyle width100 mt20px"
              sx={{ borderRadius: "20px" }}
            >
              Login
            </Button>
          </form>
        ) : (
          <>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              className="secondary_Tab border_primary"
              centered
            >
              <Tab label="Email" {...a11yProps(0)} style={{ width: "180px" }} />
              {authenticatorApp && (
                <Tab
                  label="Authenticator App"
                  {...a11yProps(1)}
                  style={{ width: "180px" }}
                />
              )}
            </Tabs>
            <Grid container>
              <TabPanel
                value={tabValue}
                index={0}
                style={{ padding: "0px", width: "100%" }}
              >
                <Typography className="mt10px greycolor505050400 fs20px">
                  Please Enter The One-Time Password To Verify Your Account
                </Typography>
                <Typography className="lightgreycolortypo mt1px">
                  We Have Sent You One Time Password On Your Registered Email
                  Account.
                </Typography>
                <form onSubmit={handleVerify}>
                  <Grid container justifyContent="center">
                    <MuiOtpInput
                      autoFocus
                      value={otp}
                      length={4}
                      onChange={setOtp}
                      TextFieldsProps={{ size: "small" }}
                      style={{ width: "380px", textAlign: "center" }}
                    />
                  </Grid>
                  <Button
                    variant="outlined"
                    type="submit"
                    className="navbuttonstyle width100 mt20px"
                    sx={{ borderRadius: "20px" }}
                  >
                    Verify Code
                  </Button>
                </form>
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={1}
                style={{ padding: "0px", width: "100%" }}
              >
                <Typography className="mt10px greycolor505050400 fs20px">
                  Please Enter The One-Time Password To Verify Your Account
                </Typography>
                <Typography className="lightgreycolortypo mt1px">
                  We Have Sent You One Time Password On Your Registered
                  Authenticator App.
                </Typography>
                <form onSubmit={handleVerify}>
                  <Grid container justifyContent="center">
                    <MuiOtpInput
                      autoFocus
                      value={otp}
                      length={6}
                      onChange={setOtp}
                      TextFieldsProps={{ size: "small" }}
                      style={{ width: "380px", textAlign: "center" }}
                    />
                  </Grid>
                  <Button
                    variant="outlined"
                    type="submit"
                    className="navbuttonstyle width100 mt20px"
                    sx={{ borderRadius: "20px" }}
                  >
                    Verify Code
                  </Button>
                </form>
              </TabPanel>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        md={6}
        lg={6}
        sx={{
          background: "linear-gradient(135deg, #D723CD 1.75%, #6907C3 101.75%)",
          color: "white",
          display: { xs: "block", sm: "none", md: "grid" },
        }}
      >
        <Grid item className="width-90LR mt35px">
          <img src={LoginScreenImage} height="100%" width="100%" />
        </Grid>

        <Typography align="center" className="whitecolortypo fs20px mt40px">
          REMOTELY CONTROLLED, DECENTRALIZED SMART ADS
          <Typography
            align="center"
            className="whitecolortypo500  fs16px mt40px  "
          >
            Select billboard, Schedule time & date, Upload your content and
          </Typography>
          <Typography
            align="center"
            className="whitecolortypo500 fs16px mt20px"
          >
            YOU’RE DONE! It’s
          </Typography>
          <Typography
            align="center"
            className="whitecolortypo500 fs16px mt10px"
          >
            that easy.
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
