import * as React from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ProfileTab from "./AdminProfileTab/ProfileTab";
import SettingTab from "./AdminProfileTab/SettingTab";
import WalletTab from "../UserMgt/Utils/AllUserProfiles/Wallet-Tab/WalletTab";
import Access from "../ClusterHeadProfile/ClusterHeadProfileTab/Access";
import axiosInstance from "../../../api/axiosInstance";
import MfaSeting from "./AdminProfileTab/MfaSeting";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminProfile() {
  const [value, setValue] = React.useState(0);
  const [profile, setProfile] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // getProfile
  let userID = JSON.parse(localStorage.getItem("userData")).user._id;
  let userRole = JSON.parse(localStorage.getItem("userData")).user.userRole;
  //get profile
  React.useEffect(() => {
    if (userRole === 6 && userID) {
      getProfile();
    }
  }, [userID, userRole]);
  const getProfile = async () => {
    try {
      let profile = await axiosInstance.get(
        `/api/user/getUserDetail/${userID}`
      );
      setProfile(profile.data.msg);
    } catch (error) {
      console.log("USER PROFILE DATA ==>", error);
    }
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "16px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className="orderTab"
          aria-label="basic tabs example"
        >
          <Tab
            label="Profile"
            {...a11yProps(0)}
            className="Order_TabChangeDevice"
          />
          <Tab
            label="Setting"
            {...a11yProps(1)}
            className="Order_TabChangeDevice"
          />

          {userRole === 6 && [
            // <Tab
            //   key={2}
            //   label="Wallet"
            //   {...a11yProps(2)}
            //   className="Order_TabChangeDevice"
            // />,
            <Tab
              key={2}
              label="Access"
              {...a11yProps(3)}
              className="Order_TabChangeDevice"
            />,
          ]}
        </Tabs>
      </Box>
      <div style={{ marginTop: "24px" }}>
        <TabPanel value={value} index={0} className="superAdmin_profile_tab">
          <ProfileTab userID={userID} />
        </TabPanel>
        <TabPanel value={value} index={1} className="superAdmin_profile_tab">
          <SettingTab userID={userID} />
        </TabPanel>

        {userRole == 6 && (
          <>
            {/* <TabPanel
              value={value}
              index={2}
              className="superAdmin_profile_tab"
            >
              <WalletTab states={{ profile }} userID={userID} />
            </TabPanel> */}
            <TabPanel
              value={value}
              index={2}
              className="superAdmin_profile_tab"
            >
              <Access userID={userID} />
            </TabPanel>
          </>
        )}
      </div>
    </>
  );
}
