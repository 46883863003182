import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Breadcrumbs,
  Box,
  TextField,
  MenuItem,
  Button,
  FormControl,
} from "@mui/material";
import useExportExcel from "../../../../utils/useExportExcelFile";
import { GrView } from "react-icons/gr";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import axios from "../../../../api/axiosInstance";
import TableSkeleton from "../../../../component/TableSkeleton/TableSkeleton";
import noDataImage from "../../../../assets/Img/no_data_found_img.png";
const headCells = [
  {
    id: "State",
    numeric: true,
    label: "State",
  },
  {
    id: "Billboards",
    numeric: true,
    disablePadding: false,
    label: "Billboards  ",
  },
  {
    id: "Users",
    numeric: true,
    disablePadding: false,
    label: "Users ",
  },
  {
    id: "Orders",
    numeric: true,
    disablePadding: false,
    label: "Orders ",
  },
  {
    id: "Campaigns",
    numeric: true,
    disablePadding: false,
    label: "Campaigns ",
  },
  {
    id: "Total Views",
    numeric: true,
    disablePadding: false,
    label: "Total Views ",
  },
  {
    id: "Male Views",
    numeric: true,
    disablePadding: false,
    label: "Male Views ",
  },
  {
    id: "Female Views",
    numeric: true,
    disablePadding: false,
    label: "Female Views ",
  },
  {
    id: "Gross Revenue",
    numeric: true,
    disablePadding: false,
    label: "Gross Revenue",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className="whitecolortypo500 "
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    className="whitecolortypo500"
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Location = () => {
  const { exportToExcel } = useExportExcel();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [file, setFile] = useState("Location_analytics");

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - 25) : 0;
  function handleClickBreadCrumb(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  ////////////////filter states////////////////
  const [countryType, setCountryType] = React.useState("India");
  const [regiondata, setRegiondata] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [cityData, setCityData] = useState();
  const [type, setType] = React.useState("All");
  ////////////////filter states////////////////
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  // loading
  const [loading, setLoading] = useState(true);

  // loading
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /////////api calling////////

  const getAllCountry = async () => {
    headCells.shift();
    headCells.unshift({
      id: "Name",
      numeric: true,
      label: "Name",
    });
    try {
      let res = await axios.get("/api/billboard/location");
      console.log("RESPONSE FROM states => ", res);
      setRegiondata(res?.data?.msg);
      setStateList(res?.data?.msg.map((state) => state?.state));
      setLoading(false);
    } catch (error) {
      console.log("error from GETTing states ", error.message);
      setLoading(false);
    }
  };

  const getAllStates = async (state) => {
    if (state == 1) {
      return;
    }
    headCells.shift();
    headCells.unshift({
      id: "Name",
      numeric: true,
      label: "Name",
    });
    console.log("GET cities before try ==> ");
    try {
      let res = await axios.get(
        `/api/billboard/city/location?state=${state}&page=1&limit=15`
      );
      console.log("RESPONSE FROM cities => ", res);
      setRegiondata(res?.data?.msg);
      setCityList(res?.data?.msg.map((district) => district?.district));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error from GETTing citiea ", error.message);
    }
  };

  const getAllCities = async (district) => {
    if (district == 1) {
      return;
    }
    headCells.shift();
    headCells.unshift({
      id: "Pincode",
      numeric: true,
      label: "Pincode",
    });
    console.log("GET cities before try ==> ");
    try {
      let res = await axios.get(
        `/api/billboard/pincode/city/location?district=${district}`
      );
      console.log("RESPONSE FROM cities pin => ", res);
      setRegiondata(res?.data?.msg);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error from GETTing citiea pin ", error.message);
    }
  };

  useEffect(() => {
    getAllCountry();
  }, []);

  const excelFileDownloader = () => {
    exportToExcel(regiondata, file);
  };
  return (
    <div>
      <Grid container sx={{ margin: "10px 0" }}>
        <Grid
          container
          item
          gap="20px"
          rowGap="10px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item display="flex" gap="20px">
            <Grid style={{ width: "200px" }}>
              <FormControl
                className="MainPageFormControl grey-border"
                size="small"
                style={{ width: "200px" }}
              >
                <TextField
                  select
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  className="Selectdropstyle"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={countryType}
                  inputProps={{ "aria-label": "Without label" }}
                  // onChange={(e) => {

                  // }}
                >
                  <MenuItem
                    value={"India"}
                    className="blackcolor484848 fs13px"
                    //onChange={OrderStatusClick}
                  >
                    India
                  </MenuItem>
                  {/* <MenuItem value={"UAE"} className="Selectmenustyle">
                    UAE
                  </MenuItem> */}
                </TextField>
              </FormControl>
            </Grid>
            <Grid style={{ width: "200px" }}>
              <FormControl
                className="MainPageFormControl grey-border"
                size="small"
              >
                <TextField
                  placeholder="hello"
                  select
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  className="Selectdropstyle"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={stateData?.length > 0 ? stateData : 1}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => {
                    getAllStates(e.target.value);
                    setStateData(e.target.value);
                  }}
                >
                  <MenuItem
                    value={1}
                    className="blackcolor484848 fs13px"
                    disabled
                    //onChange={OrderStatusClick}
                  >
                    Please select state
                  </MenuItem>
                  {stateList?.map((data) => (
                    <MenuItem
                      value={data}
                      className="blackcolor484848 fs13px"
                      //onChange={OrderStatusClick}
                    >
                      {data}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid style={{ width: "200px" }}>
              <FormControl
                className="MainPageFormControl grey-border"
                size="small"
              >
                <TextField
                  select
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  className="Selectdropstyle"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={cityData?.length > 0 ? cityData : 1}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => {
                    getAllCities(e.target.value);
                    setCityData(e.target.value);
                  }}
                >
                  <MenuItem
                    value={1}
                    className="blackcolor484848 fs13px"
                    disabled
                  >
                    Please select district
                  </MenuItem>
                  {cityList?.map((data, i) => (
                    <MenuItem
                      key={i}
                      value={data}
                      className="blackcolor484848 fs13px"
                    >
                      {data}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            sx={{ padding: "5px 20px" }}
            className="purple_btn_primary text_transform_inherit"
            onClick={() => {
              excelFileDownloader();
            }}
          >
            Export
          </Button>
        </Grid>
      </Grid>
      <Grid container className="width100">
        <Grid container className="width100 mt10px">
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer className="width100  table_border_radius">
            <Table
              sx={{
                minWidth: 800,
                backgroundColor: "#fff",
                width: "100%",
              }}
              style={{ tableLayout: "fixed" }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                className="tablerow"
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
              />
              {regiondata?.length > 0 ? (
                <TableBody className="tablerow ">
                  {regiondata?.map((data) => (
                    <>
                      <TableRow
                        hover
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          {data?.state
                            ? data.state
                            : data.district
                            ? data.district
                            : data.pincode
                            ? data.pincode
                            : "No state selected"}
                        </TableCell>

                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          {data?.billboardCount}
                        </TableCell>
                        <TableCell
                          ccomponent="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          {data?.userCount}
                        </TableCell>
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          {data?.orderCount}
                        </TableCell>
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          {data?.campaignCount}
                        </TableCell>
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          {data?.views ? data?.views * 2 : 0}
                        </TableCell>
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          {data?.maleViews ? data?.maleViews * 2 : 0}
                        </TableCell>
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          {data.femaleViews ? data?.femaleViews * 2 : 0}
                        </TableCell>
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "auto" }}
                        >
                          ₹ {data?.amount ? Math.floor(data?.amount) : 0}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              ) : (
                loading && (
                  <TableSkeleton
                    rowNumber={new Array(10).fill(0)}
                    tableCell={new Array(9).fill("16.66%")}
                  />
                )
              )}
            </Table>
            {!loading && regiondata?.length < 0 && (
              <Grid container justifyContent="center">
                <img src={noDataImage} style={{ width: "40%" }} />
              </Grid>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Location;
