import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  FormControl,
  CardActions,
  InputBase,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import RestartPma from "../../../../Edit-Billboard/Restart-pma-api";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import VenueComponent from "../../../BillBoardSceenDetails/filter/Venue";
import Badge from "@mui/material/Badge";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// All Images
import ShowBillAccordingMap from "../../../BillBoardSceenDetails/filter/ShowBillaccorMap";
import ScreenFilterDialog from "../../../BillBoardSceenDetails/filter/ScreenFilterDialog";
import BillboardDelete from "../../../BillBoardSceenDetails/BillboardScreenTab's/Dialog/BillboardDeleteDialog";
import { AuthContext } from "../../../../../../Context/AuthContext";
//React Icons
import SearchIcon from "@mui/icons-material/Search";
import { MdOutlineCameraIndoor } from "react-icons/md";
///===> FetchIP
import { FETCH_URL } from "../../../../../../fetchIp";
// checkbox
import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { styled } from "@mui/material/styles";
import no_billboard_found from "../../../../../../assets/Img/no_billboard_found.gif";
// skeleten loading
import Skeleton from "@mui/material/Skeleton";
// skeleten loading
//InfiniteScroll
import InfiniteScroll from "react-infinite-scroll-component";
// custom hook
import useBillboardData from "../../../../../../utils/useBillboardData";
import axiosInstance from "../../../../../../api/axiosInstance";
import permissionUtils from "../../../../../../component/permissionUtils";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

// checkbox
// Mui Icons
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      "&:focus": {
        width: "90%",
      },
    },
  },
}));
//============================== Main Component =============================//
export default function BillListLive({ value }) {
  const [isTesting, setIsTesting] = useState(false);
  const {
    handleSetFirstVenue,
    handleisFetching,
    handlePriceHandler,
    getFilteredBillboards,
    clearVenueFilter,
    filter,
    firstVenue,
    selectedFilter,
    smartBillboard,
    loading,
    setPage,
    setSmartBillboard,
    setBillboardLength,
    searchBillboard,
    setSearchText,
    setSelectedFilter,
    setCurrentIndexOfSortBy,
    isFetching,
    filterBadge,
    searchText,
    currentIndexOfSortBy,
    billboardLength,
    clearAllBillboard,
  } = useBillboardData({
    type: value === 0 ? "billboard" : "billboardTest",
    url: "/api/billboard/getBillboardsWithFilter",
  });
  // getting data from custom hook
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "596px",
  });
  const { vertical, horizontal, open } = state;
  // media query state
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  // context state
  const { user } = React.useContext(AuthContext);

  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  // billboard states

  const [wishListData, setWishListData] = useState([]);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [seletDistrict, setseletDistrict] = useState([]);
  const [location, setLocation] = React.useState("All");
  const [accessPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))?.permission || []
  );
  let userRole = JSON.parse(localStorage.getItem("userData")).user.role;

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  // add to wishList
  const addToWishListHandler = (e, billboardId) => {
    if (e.target.checked) {
      addToWishList(billboardId);
    } else {
      removeFromWishList(billboardId);
    }
  };
  // Api calling
  const getWishListData = async () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    try {
      const response = await fetch(`${FETCH_URL}/api/user/getAllWishlist`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let res = await response.json();
      if (response.ok) {
        console.log("wishList data ===>", res);
        setWishListData(res?.msg?.billboardWishList);
      } else {
        console.log("Error in wishList data ==> ", res.msg);
      }
    } catch (error) {
      console.log("Error in wishList data => ", error.message);
    }
  };

  const addToWishList = async (billboardId) => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    console.log("add to wishList");
    try {
      const response = await fetch(
        `${FETCH_URL}/api/user/addbillboardtowishlist`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            billboardId: billboardId,
          }),
        }
      );
      let res = await response.json();
      if (response.ok) {
        console.log("add to wishList res ===>", res);
        setSnackOpen(true);
        getWishListData();
        setSnackMsg("Added to wishlist");
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.msg);
        console.log("Error in adding to wishlist==> ", res.msg);
      }
    } catch (error) {
      console.log("Error in adding to wishlist => ", error.message);
    }
  };
  const removeFromWishList = async (billboardId) => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    console.log("remove to wishList");
    try {
      const response = await fetch(
        `${FETCH_URL}/api/user/removebillboardtowishlist`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            billboardId: billboardId,
          }),
        }
      );
      let res = await response.json();
      if (response.ok) {
        console.log("remove from wishList res ===>", res);
        setSnackerropen(true);
        getWishListData();
        setSnackErrMsg("Removed from wishlist");
      } else {
        console.log("Error in removing wishlist==> ", res.msg);
        setSnackerropen(true);
        setSnackErrMsg(res.msg);
      }
    } catch (error) {
      console.log("Error in removing wishlist==>", error.message);
    }
  };
  const [testinData, setTestingData] = useState(null);
  const getTestingStatus = async () => {
    try {
      const res = await axiosInstance.get(`api/billboard/getTestingStatus`);
      if (res.status == 200) {
        setTestingData(res?.data?.data?.[0]);
        setIsTesting(res?.data?.data?.[0]?.isEnable);
      } else {
      }
      console.log(res, "check testing");
    } catch (err) {}
  };
  const changeTestingStatus = async (e) => {
    let body = {
      testingBillId: testinData?._id,
      status: e.target.checked,
    };
    try {
      const res = await axiosInstance.post(
        `/api/billboard/changeTestBillStatus`,
        body
      );
      if (res.status == 200) {
        getTestingStatus();
      } else {
      }
    } catch (err) {}
  };

  const getAllCities = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/billboard/getAllDistrictOfBillboard?isTesting=${testinData}`
      );

      if (res.status == 200 || res.statusText == "OK") {
        setseletDistrict(res?.data?.data);
      } else {
        console.log("error in getAllBillboards", res);
      }
    } catch (err) {
      console.log("error in getAllBillboards", err);
    }
  };

  useEffect(() => {
    getWishListData();
    getTestingStatus();
    getAllCities();
  }, []);
  // infine
  const [severity, setSeverity] = useState("info");
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };

  const restartPmaApp = async () => {
    try {
      const res = await axiosInstance.post(`api/billboard/reStartAllPmaTvApp`);

      if (res.status == 200) {
        console.log("res", res);
        setSnackOpen(true);
        setSnackMsg(res?.data?.msg);

        handleShowSnackbar("success");
      } else {
        setSnackErrMsg(res?.data?.msg);

        handleShowSnackbar("error");
      }
    } catch (error) {
      console.log(error, "error");
      handleShowSnackbar("error");
    }
  };

  return (
    <>
      <Snackbar
        sx={{ marginTop: "60px" }}
        open={snackopen}
        autoHideDuration={1000}
        onClose={SnanbarClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        sx={{ marginTop: "60px" }}
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>

      <div
        id="scrollableDiv"
        style={{
          marginBottom: "40px",
          overflowY: "scroll",
          height: "100vh",
          scrollBehavior: "smooth",
        }}
      >
        {userRole !== "ClusterHead" ? (
          <>
            <Grid
              container
              justifyContent="space-between"
              sx={{ margin: "16px 0" }}
              rowGap="10px"
            >
              <Typography className="greycolor1c1c1c fs20px ">
                Browse by venue
              </Typography>

              <Grid item>
                <Grid container gap="20px" alignItems="center">
                  <Grid item>
                    {value === 1 && (
                      <Grid
                        container
                        alignItems="center"
                        gap="10px"
                        sx={{
                          padding: "8px 10px ",
                          borderRadius: "8px",
                          background: "#D6C2FF",
                        }}
                      >
                        {/*  */}
                        <label class="toggle">
                          <input
                            checked={isTesting}
                            class="toggle-checkbox"
                            type="checkbox"
                            onClick={(e) => {
                              changeTestingStatus(e);
                            }}
                          />

                          <div class="toggle-switch"></div>
                        </label>

                        <Typography className="whitecolortypo500">
                          Hide Testing Billboard
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  {userRole === "SAdmin" && (
                    <>
                      {PermissionCheck("restart-billboard") && (
                        <RestartPma
                          restartPmaApp={restartPmaApp}
                          billboard={"All"}
                        />
                      )}
                    </>
                  )}
                  {PermissionCheck("create-delete-burner-ad") && (
                    <Button
                      sx={{ height: "35px", width: "150px !important" }}
                      className="purplebtn"
                    >
                      <Link
                        to="/admin/addBurnerAd"
                        className="linkcolorwhite width100"
                      >
                        Add Burner Ads
                      </Link>
                    </Button>
                  )}
                  {PermissionCheck("add-billboard") && (
                    <Button
                      sx={{ height: "35px", width: "150px !important" }}
                      className="purplebtn"
                    >
                      <Link
                        to="/admin/addbillboard"
                        className="linkcolorwhite width100"
                      >
                        Add Billboard
                      </Link>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {!isSm && (
              <>
                <Grid
                  container
                  sm={12}
                  md={12}
                  className="mt16px"
                  alignItems="center"
                >
                  <Grid
                    item
                    lg={2.5}
                    md={3.5}
                    sm={6}
                    sx={{
                      borderRight: "2px solid #dddddd",
                    }}
                    className={
                      firstVenue == "All"
                        ? "first_venue-selected venue_divder first_venue_container"
                        : "venue_divder first_venue_container"
                    }
                  >
                    <Grid container justifyContent="space-around">
                      <Grid
                        item
                        className="fiter-item first_venue"
                        onClick={() => handleSetFirstVenue("All")}
                      >
                        <IconButton
                          sx={{
                            height: "60px",
                            marginTop: "5px",
                          }}
                          className={
                            firstVenue == "All"
                              ? "first_venue-selected-2 venue_divder first_venue_container"
                              : "venue_divder first_venue_container grey-border"
                          }
                          onClick={() => handleSetFirstVenue("All")}
                        >
                          <Grid item className=" first_venue">
                            <Typography
                              className="filter-icon-txt family-color mt25px fs13px"
                              style={{ width: "25px" }}
                            >
                              All
                            </Typography>{" "}
                          </Grid>{" "}
                        </IconButton>
                      </Grid>
                      <Grid
                        item
                        className={
                          firstVenue == "Indoor"
                            ? "first_venue-selected-item fiter-item first_venue"
                            : " first_venue fiter-item"
                        }
                        onClick={() => handleSetFirstVenue("Indoor")}
                      >
                        <MdOutlineCameraIndoor className="filter-icon family-color" />
                        <Typography
                          align="center"
                          className="filter-icon-txt family-color"
                        >
                          Indoor
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className={
                          firstVenue == "Outdoor"
                            ? "first_venue-selected-item fiter-item first_venue"
                            : "fiter-item first_venue"
                        }
                        onClick={() => handleSetFirstVenue("Outdoor")}
                      >
                        <MdOutlineCameraIndoor className="filter-icon family-color" />
                        <Typography
                          align="center"
                          className="filter-icon-txt family-color"
                        >
                          Outdoor
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={9.5} md={8} sm={6}>
                    <VenueComponent />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container className="fiter-heading mt16px" rowGap="10px">
              <Typography align="center" className="greycolor1c1c1c mt10px ">
                Showing {billboardLength ? billboardLength : 0} Smart Billboards
              </Typography>
              <Grid item className="fiter-heading-left">
                <Grid container alignItems="center" gap="10px">
                  <div item>
                    <Search
                      className="search-admin"
                      sx={{
                        height: "36px !important",
                      }}
                    >
                      <SearchIconWrapper>
                        <SearchIcon className="searchicon" />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search Billboard "
                        inputProps={{ "aria-label": "search" }}
                        value={searchText}
                        onChange={(e) => {
                          setPage(1);
                          setSmartBillboard([]);
                          setBillboardLength(0);

                          setSearchText(e.target.value);
                        }}
                      />
                    </Search>
                  </div>
                  <Grid
                    item
                    // className="fiter-heading-right"
                    sx={{ height: "100%" }}
                  >
                    <FormControl className="billboard_price_filter">
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle"
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={selectedFilter?.district}
                        inputProps={{ "aria-label": "Without label" }}
                        defaultValue={0}
                        onChange={(e) => {
                          setPage(1);
                          setSmartBillboard([]);
                          setSelectedFilter((prevState) => ({
                            ...prevState,
                            district: e.target.value,
                          }));

                          setSearchText("");
                        }}
                      >
                        <MenuItem
                          value={"All"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="greycolor505050500 fs14px ">
                            All District
                          </Typography>
                        </MenuItem>
                        {seletDistrict?.map((res, index) => (
                          <MenuItem
                            value={res}
                            key={index}
                            className="blackcolor484848 fs13px"
                          >
                            <Typography className="greycolor505050500 fs14px ">
                              {res}
                            </Typography>
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    // className="fiter-heading-right"
                    sx={{ height: "100%" }}
                  >
                    <FormControl
                      className="billboard_price_filter"
                      sx={{ width: "180px !important" }}
                    >
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle"
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={selectedFilter?.size?.[0]}
                        defaultValue={0}
                        onChange={(e) => {
                          setPage(1);
                          setSmartBillboard([]);
                          setSelectedFilter((prevState) => ({
                            ...prevState,
                            size: [e.target.value],
                          }));

                          setSearchText("");
                        }}
                      >
                        <MenuItem
                          value={"All Sizes"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="greycolor505050500 fs14px ">
                            All Orientation
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          value={"1920X1080 full screen"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="greycolor505050500 fs14px ">
                            Landscape (1920X1080)
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          value={"1080X1920 potrait screen"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="greycolor505050500 fs14px ">
                            Portrait (1080X1920)
                          </Typography>
                        </MenuItem>

                        <MenuItem
                          value={"AdaniPotrait"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="greycolor505050500 fs14px ">
                            Adani Portrait
                          </Typography>
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    // className="fiter-heading-right"
                    sx={{ height: "100%" }}
                  >
                    <FormControl className="billboard_price_filter">
                      <TextField
                        select
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        className="Selectdropstyle"
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={currentIndexOfSortBy}
                        defaultValue={0}
                        onChange={(e) => {
                          setPage(1);
                          setSmartBillboard([]);
                          setCurrentIndexOfSortBy(e.target.value);
                          getFilteredBillboards(e.target.value, 1);
                          setSearchText("");
                        }}
                      >
                        <MenuItem
                          value={"online"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="greycolor505050500 fs14px ">
                            Online
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          value={"offline"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="greycolor505050500 fs14px ">
                            Offline
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          value={"newlyAdded"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="greycolor505050500 fs14px ">
                            Newly added
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          value={"lowToHigh"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="greycolor505050500 fs14px ">
                            Cost - Low to high
                          </Typography>
                        </MenuItem>

                        <MenuItem
                          value={"highToLow"}
                          className="blackcolor484848 fs13px"
                        >
                          <Typography className="greycolor505050500 fs14px ">
                            Cost - High To Low
                          </Typography>
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ height: "100%", marginRight: "10px" }}>
                    {" "}
                    <Badge badgeContent={filterBadge} className="filter_badge">
                      <ScreenFilterDialog
                        states={{
                          selectedFilter,
                          setSelectedFilter,
                          firstVenue,
                        }}
                        filter={filter}
                        handleSetFirstVenue={handleSetFirstVenue}
                        handlePriceHandler={handlePriceHandler}
                        clearVenueFilter={clearVenueFilter}
                        getFilteredBillboards={getFilteredBillboards}
                      />
                    </Badge>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container justifyContent="space-between">
              <Typography
                align="center"
                className="fiter-heading-typo family-color"
              >
                Showing {billboardLength ? billboardLength : 0} Smart Billboards
              </Typography>
              <Grid item>
                <Grid container>
                  {PermissionCheck("add-billboard") && (
                    <Button
                      sx={{ height: "35px", ml: 2, width: "150px !important" }}
                      className="purplebtn"
                    >
                      <Link
                        to="/admin/addbillboard"
                        className="linkcolorwhite width100"
                      >
                        Add Billboard
                      </Link>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <InfiniteScroll
          dataLength={isFetching.length}
          next={handleisFetching}
          hasMore={smartBillboard?.length !== billboardLength ? true : false}
          scrollableTarget="scrollableDiv"
          className="width100"
          loader={
            <Grid
              container
              className="billboard-card mb70px "
              sx={{ marginTop: "5px" }}
              spacing={4}
            >
              {" "}
              {[0, 1, 2, 3].map((a, i) => (
                <Grid
                  key={i}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="cursorpoint relative"
                >
                  <Grid item className="box-shadow ">
                    <Skeleton variant="rectangular" sx={{ height: "150px" }} />
                    <Grid
                      container
                      gap="5px"
                      direction="column"
                      sx={{ padding: "5px" }}
                    >
                      <Skeleton sx={{ width: "80%" }} />

                      <Skeleton sx={{ width: "40%" }} />

                      <Skeleton sx={{ width: "40%" }} />
                    </Grid>{" "}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          }
        >
          <Grid
            container
            sx={{ paddingBottom: "10px" }}
            className="billboard-card pt20px  "
            spacing={4}
          >
            {smartBillboard?.length > 0 ? (
              smartBillboard?.map((data, index) => (
                <>
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <div className="relative">
                      {" "}
                      <Grid
                        item
                        key={data.billboardName}
                        className="box-shadow cursorpoint"
                        style={{
                          borderRadius: "8px",
                        }}
                      >
                        <Link
                          to={`/admin/billboardscreens/${data?._id}`}
                          className="linkcolor"
                          state={{ item: data }}
                        >
                          <div style={{ background: "transparent" }}>
                            <img
                              src={data?.thumbnail}
                              loading="lazy"
                              alt="bill_image"
                              style={{
                                height: "150px",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                            {/* <img
                              src="https://i.ytimg.com/vi/c7X9g4UO994/hqdefault.jpg"
                              loading="lazy"
                              alt="bill_image"
                              style={{
                                height: "150px",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            /> */}
                          </div>
                          <CardActions className="campaign-billboardaction">
                            <Grid
                              container
                              justifyContent="space-between"
                              direction="row"
                            >
                              <Grid item>
                                <Typography
                                  className="greycolor1c1c1c fs18px"
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {data?.billboardName}
                                </Typography>
                                <Typography className="greycolor505050500 fs12px ">
                                  #{data?.billboardId}
                                </Typography>
                                <Typography className="greycolor505050500 fs14px">
                                  {data?.district}
                                </Typography>{" "}
                                <Typography className="greycolor1c1c1c fs18px pt5px">
                                  ₹ {data?.basePrice}
                                </Typography>
                              </Grid>
                            </Grid>{" "}
                          </CardActions>
                        </Link>
                      </Grid>
                      {/* {user?.role === "SAdmin" && (
                        <Grid item className="billBoard_delete_btn">
                          <BillboardDelete
                            data={data}
                            clearAllBillboard={clearAllBillboard}
                            currentIndexOfSortBy={currentIndexOfSortBy}
                            getFilteredBillboards={getFilteredBillboards}
                          />
                        </Grid>
                      )} */}
                      {/* {billBoardData?.deviceId?.deviceStatus == "Online" ? (
                        <>
                          {" "}
                          <span className="online_dot"></span>{" "}
                          <Typography className="secondary_heading500 fs16px">
                            Online
                          </Typography>
                        </>
                      ) : (
                        <>
                          <span className="onffine_dot"></span>{" "}
                          <Typography className="secondary_heading500 fs16px">
                            Offline
                          </Typography>
                        </>
                      )} */}
                      {data?.deviceIdObj?.deviceStatus == "Online" ? (
                        <Grid item className="billBoardLive">
                          <span className="online_dot "></span>
                        </Grid>
                      ) : (
                        <>
                          {" "}
                          <Grid item className="billBoardLive">
                            <span className="onffine_dot"></span>
                          </Grid>
                        </>
                      )}
                      {PermissionCheck("delete-billboard") && (
                        <Grid item className="billBoard_delete_btn">
                          <BillboardDelete
                            data={data}
                            clearAllBillboard={clearAllBillboard}
                            currentIndexOfSortBy={currentIndexOfSortBy}
                            getFilteredBillboards={getFilteredBillboards}
                          />
                        </Grid>
                      )}
                      <Grid item className="billBoard_delete_btn">
                        <Checkbox
                          className="wishList_btn purple-icon"
                          {...label}
                          checked={
                            wishListData?.find((a) => a._id === data._id)
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            addToWishListHandler(e, data._id);
                          }}
                          icon={<FavoriteBorder />}
                          checkedIcon={<Favorite />}
                        />
                      </Grid>
                      {data?.isTesting && (
                        <div
                          style={{
                            position: "absolute",
                            top: "6px",
                            left: "6px",
                            padding: "5px 10px",
                            background: "orange",
                            color: "white",
                            borderRadius: "8px",
                          }}
                        >
                          Testing
                        </div>
                      )}
                    </div>
                  </Grid>
                </>
              ))
            ) : loading === true ? (
              <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((a, i) => (
                  <Grid
                    key={i}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="cursorpoint relative"
                  >
                    <Grid
                      item
                      className="box-shadow "
                      style={{
                        borderRadius: "8px",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        sx={{ height: "150px" }}
                      />
                      <Grid
                        container
                        gap="5px"
                        direction="column"
                        sx={{ padding: "5px" }}
                      >
                        <Skeleton sx={{ width: "80%" }} />

                        <Skeleton sx={{ width: "40%" }} />

                        <Skeleton sx={{ width: "40%" }} />
                      </Grid>{" "}
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : (
              <Grid
                container
                item
                sx={{
                  height: "100%",
                  marginTop: "100px",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={no_billboard_found}
                  alt="no_billboard_found"
                  style={isMd ? { width: "450px" } : { width: "600px" }}
                />
              </Grid>
            )}
          </Grid>
        </InfiniteScroll>
        {smartBillboard && smartBillboard?.length > 0 && (
          <Grid
            container
            item
            sm={12}
            md={12}
            className="float_btn_container"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ShowBillAccordingMap
              states={{
                selectedFilter,
                setSelectedFilter,
                smartBillboard,
                setSmartBillboard,
              }}
              filter={filter}
              getFilteredBillboards={getFilteredBillboards}
            />
          </Grid>
        )}
      </div>
    </>
  );
}
