import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { Link } from "react-router-dom";
import { FETCH_URL } from "../../../fetchIp";
import Sidebar from "../../../component/Sidebar/Sidebar";
import { Breadcrumbs, Grid, Typography } from "@mui/material";
import { MAPBOX_ACCESS_KEY } from "../../../utils/constant";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
mapboxgl.accessToken = MAPBOX_ACCESS_KEY;

function Map() {
  const navigate = useNavigate();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lat, setLat] = useState(28.7041);
  const [lng, setLng] = useState(77.1025);
  const [zoom, setZoom] = useState(2);
  const [allBillboards, setAllBillboards] = useState([]);
  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });
    // Add markers to the map
    if (Array.isArray(allBillboards)) {
      allBillboards.forEach((city) => {
        const marker = new mapboxgl.Marker()
          .setLngLat([city?.longitude, city?.lattitude])
          .addTo(map.current);
      });
    } else {
      const marker = new mapboxgl.Marker()
        .setLngLat([allBillboards?.longitude, allBillboards?.lattitude])
        .addTo(map.current);
    }
  }, [allBillboards]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, [allBillboards]);

  const getallDevices = async () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    const response = await fetch(`${FETCH_URL}/api/billboard/getallbillboard`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let res = await response.json();
    if (response.ok) {
      //console.log("All Billboard ===> ", res.msg);
      setAllBillboards(res.msg);
    } else {
      console.log("Error in All Billboard ==> ", res);
    }
  };

  useEffect(() => {
    getallDevices();
  }, []);

  return (
    <>
      <div className="width-90LR ">
        <div className="flex-class">
          <Grid container direction="row" justifyContent="start">
            <KeyboardBackspaceIcon
              onClick={() => navigate(-1)}
              style={{ marginRight: "8px", textAlign: "center" }}
            ></KeyboardBackspaceIcon>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                to="/admin/home"
                className="linkcolor"
                underline="hover"
                key="1"
              >
                <Typography className="purplecolortypo fs16px">
                  Dashboard
                </Typography>
              </Link>
              ,
              <Link
                to="/"
                className="linkcolor"
                underline="hover"
                key="2"
                color="inherit"
                href="/material-ui/getting-started/installation/"
              >
                <Typography className="blackcolortypo fs16px">
                  Location
                </Typography>
              </Link>
              ,
            </Breadcrumbs>
          </Grid>
        </div>
        <div style={{ width: "100%", height: "100%", marginTop: "20px" }}>
          <div
            ref={mapContainer}
            style={{ height: "80vh", minWidth: "400px" }}
          />
        </div>
      </div>
    </>
  );
}
export default React.memo(Map);
