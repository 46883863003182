import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

//  ** Mui Component
import { Box, useMediaQuery, useTheme } from "@mui/material";

//  ** Core Component
import OverviewTab from "./OrderTabPanel/Order";
import AnalyticsTabs from "./OrderTabPanel/AnalyticsTabs";
import TabList from "../../../component/CustomTabs/TabList";
import usePaginatedValue from "../../../utils/usePaginatedValue";
import permissionUtils from "../../../component/permissionUtils";
import TabPanelList from "./../../../component/CustomTabs/TabPanel";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";

const OrderAnalyticsTab = (props) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let otherOrders = location.pathname == "/admin/otherOrder";

  const { paginatedPage, paginateTabValue, getPaginatedValueHandler } =
    usePaginatedValue(navigate);

  const [currentPage, setCurrentPage] = useState(
    paginatedPage ? paginatedPage : 1
  );
  const [value, setValue] = React.useState(0);
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const handleChange = (event, newValue) => {
    // getPaginatedValueHandler("", currentPage, newValue);
    searchParams.set("tab", newValue);
    setValue(newValue);
    setSearchParams(searchParams);
  };
  useEffect(() => {
    if (paginateTabValue === undefined) {
      setValue(0);
      return;
    }
    setValue(paginateTabValue);
  }, [paginateTabValue]);

  useEffect(() => {
    if (props.value) setValue(props.value);
  }, [props]);

  const permissionList = ["overview", "view-order-analytics"];
  const permissions = CheckTabsPermissions(permissionList);
  const breadcrumbs = [
    { label: "Dashboard", link: "/" },
    { label: otherOrders ? "Other Orders" : "Orders", link: otherOrders },
  ];

  const getTabLabel = (permission) => {
    const labelMap = {
      overview: "Overview",
      "view-order-analytics": "Analytics",
    };
    return labelMap[permission];
  };

  const panels = [
    {
      permission: "overview",
      component: (
        <OverviewTab
          paginatedPage={paginatedPage}
          paginateTabValue={paginateTabValue}
          getPaginatedValueHandler={getPaginatedValueHandler}
          tabValue={value}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ),
    },
    {
      permission: "view-order-analytics",
      component: <AnalyticsTabs />,
    },
  ];
  return (
    <>
      <div className="width-90LR">
        <div className="flex-class">
          <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
        </div>
        <Box sx={{ borderBottom: 1, mb: 4, borderColor: "divider" }}>
          <TabList
            md={md}
            value={value}
            centered={false}
            classes={{ Tabs: "orderTab", Tab: "Order_TabChangeDevice" }}
            handleChange={handleChange}
            getTabLabel={getTabLabel}
            permissions={permissions}
          />
        </Box>
        <TabPanelList value={value} permissions={permissions} panels={panels} />{" "}
      </div>
    </>
  );
};

export default OrderAnalyticsTab;
