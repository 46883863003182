import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axiosInstance from "../../../../../../api/axiosInstance";
import Calendar from "../../../../../../component/ReactDateRangePicker/ReactDateRangePickerFormonth";
import moment from "moment";
import useExportExcel from "../../../../../../utils/useExportExcelFile";

// temp
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    xAxes: [{ stacked: true }],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        stacked: false,
      },
    ],
  },
};

const RevenueBarAnalystic = () => {
  const [dataType, setdataType] = useState("Orders");
  const [ratio, setRatio] = useState("All");
  let barChartData = [];

  const [analyticsData, setAnalyticsData] = useState([]);
  const [date, setDate] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [acceptedGraphData, setAcceptedGraphData] = useState([]);
  const [datasets, setDataSets] = useState([]);
  const [amountType, setamountType] = useState("margin");
  const [acceptedOrderCount, setAcceptedOrderCount] = useState(0);
  const { exportToExcel } = useExportExcel();
  const [dataForTableOrder, setdataForTableOrder] = useState(null);
  const [fileName, setfileName] = useState("Revenue");
  let userID = JSON.parse(localStorage.getItem("userData")).user._id;

  const timeInHour = [
    "12:00 Am",
    "1:00 Am",
    "2:00 Am",
    "3:00 Am",
    "4:00 Am",
    "5:00 Am",
    "6:00 Am",
    "7:00 Am",
    "8:00 Am",
    "9:00 Am",
    "10:00 Am",
    "11:00 Am",
    "12:00 Pm",
    "1:00 Pm",
    "2:00 Pm",
    "3:00 Pm",
    "4:00 Pm",
    "5:00 Pm",
    "6:00 Pm",
    "7:00 Pm",
    "8:00 Pm",
    "9:00 Pm",
    "10:00 Pm",
    "11:00 Pm",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels:
      analyticsDate?.length == 24
        ? timeInHour
        : analyticsDate?.length == 12
        ? months
        : analyticsDate,
    datasets: datasets,
  };
  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };

  ///////////////////// api call to get data for bar graph //////////////////////

  const getWalletOverallGraph = async () => {
    try {
      let res = await axiosInstance.get(
        `/api/payment/getRevenueGraphByDateRangeByUserId?amountType=${amountType}&userId=${userID}&startDate=${moment(
          date?.[0]?.startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(date?.[0]?.endDate).format(
          "YYYY-MM-DD"
        )}`
      );

      console.log("response from getRevenueGraphByDateRange =>", res);
      if (res.status == 200 || res.status == 201) {
        //  setAcceptedOrderAnalyticsData(res?.data.data);

        setAcceptedGraphData(res?.data.data);
      }
    } catch (err) {
      console.log("error from from getRevenueGraphByDateRange => ", err);
    }
  };
  useEffect(() => {
    if (!date) {
      return;
    }
    getWalletOverallGraph();
  }, [amountType, date]);

  ////////////// set data in the dataset ///////////////

  let amountData = [];
  useEffect(() => {
    for (let i = 0; i < analyticsDate.length; i++) {
      let index = acceptedGraphData?.findIndex(
        (item) => item._id === analyticsDate[i]
      );
      if (index >= 0) {
        let obj = {
          Date: analyticsDate[i],
          amount: acceptedGraphData[index].amount,
        };
        amountData.push(obj);
      } else {
        let obj = {
          Date: analyticsDate[i],
          amount: 0,
        };
        amountData.push(obj);
      }
    }
    setdataForTableOrder(amountData);
  }, [acceptedGraphData]);

  // making an empty array and pushing all data in (data with 0 count)
  // setting datasets
  useEffect(() => {
    if (amountType === "refund") {
      setDataSets([
        {
          label: "Refund",
          data: amountData?.map((a) => a?.amount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (amountType === "margin") {
      const firstData = acceptedGraphData?.map((a) => a.amount);
      const firstDataNew = firstData?.reduce((accum, elem) => {
        return (accum = accum + elem);
      }, 0);

      setAcceptedOrderCount(firstDataNew.toFixed(2));
      setDataSets([
        {
          label: "Margin",
          data: amountData?.map((a) => a?.amount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (amountType === "walletDeposit") {
      setDataSets([
        {
          label: "Wallet Deposit",
          data: amountData?.map((a) => a?.amount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (amountType === "spent") {
      setDataSets([
        {
          label: "Spent",
          data: amountData?.map((a) => a?.amount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    } else if (amountType === "Widhdraw") {
      setDataSets([
        {
          label: "widhdraw",
          data: amountData?.map((a) => a?.amount),
          borderColor: "rgb(95,202,93,100%)",
          backgroundColor: "rgba(95,202,93,100%)",
          stack: "Stack 0",
          borderRadius: 20,
          barThickness: 24,
        },
      ]);
    }
  }, [amountType, acceptedGraphData]);
  // setting datasets

  return (
    <>
      <div style={{ paddingBottom: "10px", marginBottom: "10px" }}>
        <Grid
          container
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "20px",
          }}
        >
          <Grid item xs={12}>
            <div>
              {" "}
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid container gap="10px">
                    <Typography className="gray5A5A5Atypo500 fs20px">
                      Revenue
                    </Typography>
                  </Grid>
                  <Grid container gap="10px">
                    <Typography className="gray5A5A5Atypo500 fs20px">
                      Total: &#x20b9; {acceptedOrderCount}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container gap="10px">
                    <Calendar
                      getDataFromChildHandler={getDataFromChildHandler}
                    />
                    <Button
                      sx={{ padding: "0 10px" }}
                      className="purple_btn_primary text_transform_inherit"
                      onClick={() => exportToExcel(dataForTableOrder, fileName)}
                    >
                      Export
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Line options={options} data={data} />
        </Grid>
      </div>
    </>
  );
};

export default RevenueBarAnalystic;
