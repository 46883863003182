import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Grid,
  Typography,
  IconButton,
  ImageListItemBar,
  CardActions,
} from "@mui/material";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// All Images
import BILLBOARD from "../../../../assets/Img/BillBoardImg.png";
import { FETCH_URL } from "../../../../fetchIp";
//React Icons
import { BiRupee } from "react-icons/bi";
// Mui Icons
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const BillList = ({ userID }) => {
  const [billBoardList, setAllBillboards] = useState([]);
  let userId = JSON.parse(localStorage.getItem("userData")).user._id;
  console.log("Check billBoardList data =========<>", billBoardList);

  const getallBillBoardScreen = async () => {
    const response = await fetch(
      `${FETCH_URL}/api/billboard/adminScreens?adminId=${
        userID ? userID : userId
      }`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );
    let res = await response.json();
    if (response.ok) {
      console.log("All Billboard ===> ", res.msg);
      setAllBillboards(res.msg);
    } else {
      console.log("Error in All Billboard ==> ", res.err);
    }
  };
  const [currentIndexOfSortBy, setCurrentIndexOfSortBy] =
    useState("newlyAdded");

  React.useEffect(() => {
    getallBillBoardScreen();
  }, [userID]);
  return (
    <>
      <div>
        <Grid container>
          <Grid container className="fiter-heading">
            <Typography
              align="center"
              className="fiter-heading-typo  family-color "
            >
              Showing {billBoardList?.length} Smart
            </Typography>
            {/* <Grid item className="fiter-heading-right">
              <Typography className=" family-color ">Sort By:</Typography>
              <FormControl
                sx={{ m: 1, minWidth: 160, border: "1px solid #dddddd" }}
                // className="MainPageFormControl-2  "
                size="small"
              >
                <TextField
                  select
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  className="Selectdropstyle-noborder"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  inputProps={{ "aria-label": "Without label" }}
                  value={currentIndexOfSortBy}
                  defaultValue={0}
                  onChange={(e) => {
                    setCurrentIndexOfSortBy(e.target.value);
                  }}
                >
                  <MenuItem value={"newlyAdded"} className="Selectmenustyle">
                    <Typography className="Selectmenustyle fs10px  greycolor505050">
                      Newly added
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"LowToHigh"} className="Selectmenustyle">
                    <Typography className="Selectmenustyle fs10px  greycolor505050">
                      Cost - Low to high
                    </Typography>
                  </MenuItem>

                  <MenuItem value={"highToLow"} className="Selectmenustyle">
                    <Typography className="Selectmenustyle fs10px  greycolor505050">
                      Cost - High To Low
                    </Typography>
                  </MenuItem>
                </TextField>
              </FormControl>
            </Grid> */}
          </Grid>

          <Grid
            container
            className="billboard-card  pt20px mb70px "
            spacing={4}
          >
            {billBoardList.length > 0 &&
              billBoardList.map((item, index) => {
                return (
                  <Grid item md={3} sm={6} lg={3}>
                    <Link
                      to={"/admin/billboardscreendetials"}
                      className="linkcolor"
                      state={{ item }}
                    >
                      <Grid
                        item
                        key={item.billboardName}
                        className="box-shadow "
                        style={{
                          borderRadius: "8px",
                          position: "relative",
                        }}
                      >
                        <ImageListItemBar
                          sx={{
                            background: "transparent",
                          }}
                          position="top"
                          actionIcon={
                            <IconButton aria-label={`star ${item.title}`}>
                              <FavoriteBorderIcon className="purple-icon" />
                            </IconButton>
                          }
                          actionPosition="right"
                        />
                        <img
                          src={
                            item && item.filesArr.length > 0
                              ? item.filesArr[0].fileurl
                              : BILLBOARD
                          }
                          alt="green iguana"
                          style={{
                            height: "150px",
                            width: "100%",
                          }}
                        />
                        <CardActions className="campaign-billboardaction">
                          <Grid
                            container
                            justifyContent="space-between"
                            direction="row"
                          >
                            <Grid item>
                              <Typography className="greycolor505050500  ">
                                {item.billboardName}
                              </Typography>
                              <Typography className="greycolor505050500  fs12px ">
                                {item.billboardId}
                              </Typography>
                              <Typography className="greycolor505050500  ">
                                {item.city}
                              </Typography>
                              <Typography className="greycolor505050500 ">
                                <BiRupee /> {item.basePrice}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Grid>
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BillList;
