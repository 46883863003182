import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Typography,
  Container,
  Input,
  Button,
  Snackbar,
  InputBase,
  Accordion,
  Checkbox,
  AccordionDetails,
  FormControlLabel,
  AccordionSummary,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import MuiAlert from "@mui/material/Alert";
import axiosInstance from "../../../../api/axiosInstance";
import MfaSeting from "./MfaSeting";
import Secure from "./../../../../assets/Img/secure.png"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function UserManagment({ userID }) {
  // ===================== SnackBar ==================== //
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const vertical = "top";
  const horizontal = "center";
  const openSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };
  const [show, setShow] = useState(false);
  const [showconfrim, setShowConfrim] = useState(false);
  const [showcurrentshow, setCurrentShow] = useState(false);

  const [securityState, setSecurityState] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPassError, setCurrentPassError] = useState("");

  const [passwordValue] = useState(null);
  const [confirmPasswordValue] = useState(null);
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const [value, setValue] = React.useState();

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const newPassword = passwordInput.confirmPassword;
  // For PassWord Extra Validation
  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  function ChangeSecurityState() {
    setSecurityState(false);
  }

  function ChangeSecurityCancel(e) {
    setConfirmPasswordError("");
    setPasswordErr("");
    setCurrentPassError("");
    setSecurityState(true);
    setShow(false);
    setCurrentShow(false);
    setShowConfrim(false);
    setCurrentPassword("");
    setPasswordInput((prevState) => ({
      ...prevState,
      password: "",
      confirmPassword: "",
    }));
  }
  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;

    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-8])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password can not  empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" &&
        passwordInput.confirmPassword.length > 0)
    ) {
      if (passwordInput.confirmPassword !== passwordInput.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };
  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...passwordInput,
      [passwordInputFieldName]: passwordInputValue,
    };
    setPasswordInput(NewPasswordInput);
  };

  const handleChangePass = async () => {
    if (currentPassword.length == 0) {
      setCurrentPassError("Current password is required");
      return;
    }
    setCurrentPassError("");
    if (
      passwordInput.password.length === 0 &&
      passwordInput.confirmPassword.length === 0
    ) {
      setPasswordErr("Password is required");
      setConfirmPasswordError(" Confirm password is required");
      return;
    }
    if (passwordInput.confirmPassword !== passwordInput.password) {
      setConfirmPasswordError("Confirm password is not matched");
      return;
    }
    if (passwordInput.confirmPassword.length < 8) {
      setConfirmPasswordError("At least minumum 8 characters");
      return;
      setSecurityState(false);
    }
    if (passwordInput.confirmPassword.length < 8) {
      setConfirmPasswordError("At least minumum 8 characters");
      return;
      setSecurityState(false);
    }

    let body = {
      userId: userID,
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    try {
      const resp = await axiosInstance.post(
        "/api/user/updatePasswordForUser",
        body
      );
      if (resp) {
        openSnackbar(resp?.data?.msg, "success");
        setShow(false);
        setCurrentShow(false);
        setShowConfrim(false);
        setSecurityState(true);
        ChangeSecurityCancel();
      }
    } catch (error) {
      console.log("Catch Block", error);
      openSnackbar(error?.response?.data?.msg || "An error occurred.", "error");
    }
  };

  //// accordion
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // ////////// mfa toggle
  // mfa

  const [severity, setSeverity] = useState("info");
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [snackerropen, setSnackerropen] = useState(false);

  const [mfaToggle, setmfaToggle] = useState();
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };

  const [mfaAdStatus, setmfaAdStatus] = useState(false);
  useEffect(() => {
    if (mfaAdStatus) {
      setmfaToggle(mfaAdStatus);
    }
  }, [mfaAdStatus]);

  const onClickmfaToggle = (e) => {
    const newBurnerToggle = !mfaToggle;
    setmfaToggle(newBurnerToggle);

    changemfaAdsStatus(newBurnerToggle); // Pass the new state as the status
  };
  const getmfaAdStatus = async () => {
    try {
      const res = await axiosInstance.get(
        `api/user/getUserMFAStatus/${userID}`
      );

      //.log(res, "res");
      if (res.status == 200 || res.status == 201) {
        console.log("res for status", res);
        setmfaAdStatus(res?.data?.enabled);
      } else {
        //.log("error getting burnerVdo res", res.msg);
      }
    } catch (error) {
      //.log(error, "error");
    }
  };
  useEffect(() => {
    getmfaAdStatus();
  }, []);
  const changemfaAdsStatus = async (status) => {
    let body = {
      _id: userID,
      status: status,
    };
    try {
      const res = await axiosInstance.post(`api/user/changeMFAStatus `, body);
      if (res.status == 200 || res.status == 201) {
        setSnackOpen(true);
        setSnackMsg("Status Updated");
        handleShowSnackbar("success");
      }
    } catch (error) {
      //.log(error, "error");
      handleShowSnackbar("error");
    }
  };

  return (
    <>
      <Snackbar
        sx={{ marginTop: "60px" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={closeSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={severity}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={severity}>
          {snackErrMsg}
        </Alert>
      </Snackbar>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ border: "1px solid #ddd", marginBottom: "20px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel1bh-content`}
          id={`panel1bh-header`}
        >
          <Typography className="blackcolortypo fs20px">
            {" "}
            Password Change
          </Typography>
        </AccordionSummary>
        <Grid container>
          <Grid item xs={12}>
            <AccordionDetails>
              <Grid container className="p-20px mt-20px whitebackground">
                <Grid container xs={12} justifyContent="end">
                  {/* <Typography className="blackcolortypo fs20px  ">
                    Password Change
                  </Typography> */}
                  <Grid item>
                    {securityState ? (
                      <>
                        <Button
                          className="purplebtn  min-width-120px"
                          onClick={() => ChangeSecurityState()}
                        >
                          Edit
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => ChangeSecurityCancel()}
                          className="previousbtn mr10px min-width-120px"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="purplebtn  min-width-120px"
                          onClick={handleChangePass}
                        >
                          Update
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={4} mt={0.25}>
                  <Grid item md={4} className="relative">
                    <Typography className="darkblacktypo">
                      Current Password
                    </Typography>
                    <InputBase
                      className="inputstyle"
                      disabled={securityState}
                      type={showcurrentshow ? "text" : "password"}
                      placeholder="Enter your Current Password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />{" "}
                    {securityState ? null : (
                      <Typography
                        sx={{ position: "absolute", top: 70, right: 10 }}
                        align="right"
                        className="fs-24 cursor-point"
                        onClick={() => setCurrentShow(!showcurrentshow)}
                      >
                        {showcurrentshow ? (
                          <AiOutlineEye color="grey" />
                        ) : (
                          <AiOutlineEyeInvisible color="grey" />
                        )}
                      </Typography>
                    )}
                    <Typography className="redcolortypo" variant={"subtitle2"}>
                      {currentPassError}
                    </Typography>{" "}
                  </Grid>
                  <Grid item md={4} className="relative">
                    <Typography className="darkblacktypo">Password</Typography>
                    <InputBase
                      name="password"
                      type={show ? "text" : "password"}
                      placeholder="Enter your New Password"
                      disabled={securityState}
                      className="inputstyle"
                      value={
                        passwordValue ? passwordValue : passwordInput?.password
                      }
                      onChange={handlePasswordChange}
                      onKeyUp={handleValidation}
                    />
                    {securityState ? null : (
                      <Typography
                        sx={{ position: "absolute", top: 70, right: 10 }}
                        align="right"
                        className="fs-24 cursor-point"
                        onClick={() => setShow(!show)}
                      >
                        {show ? (
                          <AiOutlineEye color="grey" />
                        ) : (
                          <AiOutlineEyeInvisible color="grey" />
                        )}
                      </Typography>
                    )}
                    <Typography className="redcolortypo" variant={"subtitle2"}>
                      {passwordError}
                    </Typography>{" "}
                  </Grid>
                  {securityState === false ? (
                    <Grid item md={4} className="relative">
                      <Typography className="darkblacktypo">
                        Confirm Password
                      </Typography>
                      <InputBase
                        disabled={securityState}
                        name="confirmPassword"
                        type={showconfrim ? "text" : "password"}
                        placeholder="Enter your Confirm Pass..."
                        className="inputstyle"
                        value={
                          confirmPasswordValue
                            ? confirmPasswordValue
                            : passwordInput?.confirmPassword
                        }
                        onChange={handlePasswordChange}
                        onKeyUp={handleValidation}
                      />
                      {securityState ? null : (
                        <Typography
                          sx={{ position: "absolute", top: 70, right: 10 }}
                          align="right"
                          className="fs-24 cursor-point"
                          onClick={() => setShowConfrim(!showconfrim)}
                        >
                          {showconfrim ? (
                            <AiOutlineEye color="grey" />
                          ) : (
                            <AiOutlineEyeInvisible color="grey" />
                          )}
                        </Typography>
                      )}
                      <Typography
                        className="redcolortypo"
                        variant={"subtitle2"}
                      >
                        {confirmPasswordError}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Grid>
        </Grid>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{ border: "1px solid #ddd", marginBottom: "20px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel2bh-content`}
          id={`panel2bh-header`}
        >
          <Typography className="blackcolortypo fs20px">
            Multi-factor authentication (MFA)
          </Typography>
        </AccordionSummary>
        <Grid container>
          <Grid item xs={12}>
            <AccordionDetails>
              <Grid container className="p-20px whitebackground">
                <Grid
                  container
                  justifyContent="right"
                  item
                  gap="10px"
                  sx={{
                    borderRadius: "8px",
                  }}
                >
                  {/*  */}
                  <Typography className="blackcolor484848 fs18px">
                    Turn on 2-Step Verification
                  </Typography>
                  <label class="toggle">
                    <input
                      checked={mfaToggle}
                      class="toggle-checkbox"
                      type="checkbox"
                      value=""
                      onClick={(e) => {
                        onClickmfaToggle(e);
                      }}
                    />
                    <div class="toggle-switch"></div>
                  </label>{" "}
                </Grid>{" "}
                {mfaToggle ? (
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Tabs
                        value={value}
                        onChange={handleChangeTab}
                        className="orderTab"
                        aria-label="basic tabs example"
                      >
                        <Tab
                          label="Authenticator App"
                          className="Order_TabChangeDevice"
                          {...a11yProps(0)}
                        />
                        <Tab
                          label="Email"
                          className="Order_TabChangeDevice"
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </Box>
                    <div style={{ marginTop: "10px" }}>
                      <CustomTabPanel value={value} index={0}>
                        <MfaSeting userID={userID} />
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={1}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ marginTop: "20px" }}
                        >
                          <img src={Secure} style={{backgroundColor: "transparent",width:"1   50px"}} />
                          <Typography className="mt10px greycolor505050400 fs20px">
                            Your account is protected with 2-Step Verification
                          </Typography>
                          <Typography className="lightgreycolortypomfa mt1px">
                            You received one time passcode on your email while
                            login use this passcode for login .
                          </Typography>
                        </Grid>
                      </CustomTabPanel>
                    </div>
                  </Box>
                ) : (
                  <>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ marginTop: "20px" }}
                    >
                      <Typography className="mt10px greycolor505050400 fs20px">
                        Protect your account with 2-Step Verification
                      </Typography>
                      <Typography className="lightgreycolortypomfa mt1px">
                        Prevent hackers from accessing your account with an
                        additional layer of security. When you sign in, 2-Step
                        Verification helps make sure that your personal
                        information stays private, safe and secure.In addition
                        to your password, 2-Step Verification adds a quick,
                        second step to verify that it’s you.
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </AccordionDetails>
          </Grid>
        </Grid>
      </Accordion>
    </>
  );
}
