import React from "react";
import { Typography, Grid, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import AdminProfile from "../AdminProfile/AdminProfile";
import ClusterheadProfile from "../ClusterHeadProfile/ClusterHeadProfile";
export default function UserDashboard() {
  // context State
  let userRole = JSON.parse(localStorage?.getItem("userData"))?.user?.userRole;
  return (
    <>
      <div className="width-90LR  mb70px">
        <div className="flex-class">
          <Grid container direction="row" justifyContent="start">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                to="/admin/home"
                className="linkcolor"
                underline="hover"
                key="1"
              >
                <Typography className="purplecolortypo fs16px">
                  {" "}
                  Dashboard
                </Typography>
              </Link>
              ,
              <Link
                to="/admin/userdashborad"
                className="linkcolor"
                underline="hover"
                key="2"
              >
                <Typography className="blackcolortypo fs16px">
                  Profile
                </Typography>
              </Link>
              ,
            </Breadcrumbs>
          </Grid>
        </div>

        {userRole && userRole === 2 ? <ClusterheadProfile /> : <AdminProfile />}
      </div>
    </>
  );
}
