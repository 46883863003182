import React, { useEffect, useState, useRef } from "react";
import SideBar from "../../../component/Sidebar/Sidebar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Grid, Typography, TextField, Button } from "@mui/material";

import axiosInstance from "../../../api/axiosInstance";
// temp
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
// temp
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const Home_Bar_Analytics = () => {
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const refOne = useRef(null);
  const [range, setRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateType, setDateType] = useState("last7days");
  const [datasets, setDataSets] = useState([]);
  const [dates, setAnalyticsDate] = useState([]);
  const [orderType, setOrderType] = useState("All");
  function dateRange(startDate, endDate, steps = 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    return dateArray;
  }

  useEffect(() => {
    if (dateType === "last7days") {
      let d = new Date(); // today!
      let previousDay = d.setDate(d.getDate() - 1);
      let x = 6; // go back 7 days!
      d.setDate(d.getDate() - x);
      setAnalyticsDate(dateRange(d, previousDay));
    }
  }, [dateType]);

  const data = {
    labels: dates,
    datasets: datasets,
  };
  //

  // get OrderAnalyics
  const [revenue, setRevenueAnalytics] = useState([]);

  // GET order count, revenue and views  ()  by billboardId

  const getrevenueAnalyticsData = async () => {
    try {
      let body = {
        startDate: startDate,
        endDate: endDate,
      };
      let res = await axiosInstance.post(`/api/order/orderRevenueStats`, body);
      console.log("response from getting revenue analytics data =>", res);
      if (res.status == 200 || res.status == 201) {
        setRevenueAnalytics(res?.data?.msg);
      }
    } catch (err) {
      console.log("error from revenue anlytics data => ", err);
    }
  };

  useEffect(() => {
    if (dateType === "last7days") {
      const endDate = new Date().toISOString()?.split("T")[0];
      let d = new Date(); // today!
      let x = 6; // go back 7 days!
      d.setDate(d.getDate() - x);
      setStartDate(d.toISOString()?.split("T")[0]);
      setEndDate(endDate);
    }
  }, [dateType, range]);

  // date logics

  let revenueData = [];

  useEffect(() => {
    for (let i = 0; i < dates.length; i++) {
      let index = revenue?.findIndex((item) => item._id === dates[i]);
      if (index >= 0) {
        let obj = {
          _id: dates[i],
          totalViews: revenue[index].totalViews,
        };
        revenueData.push(obj);
      } else {
        let obj = { _id: dates[i], totalViews: 0 };
        revenueData.push(obj);
      }
    }
  }, [revenue]);

  useEffect(() => {
    if (!startDate && !endDate) return;
    getrevenueAnalyticsData();
  }, [endDate, startDate]);

  useEffect(() => {
    setDataSets([
      {
        label: "Amount",
        data: revenueData?.map((a) => a?.totalViews),
        borderColor: "rgb(95,202,93,100%)",
        backgroundColor: "rgba(95,202,93,100%)",
        stack: "Stack 0",
        borderRadius: 20,
        barThickness: 24,
      },
    ]);
  }, [revenue]);

  return (
    <Grid container>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: " 0 10px" }}
      >
        <Grid item>
          <Typography className="greycolor505050400 fs20px">Amount</Typography>
        </Grid>
        {(user?.role === "SAdmin" || user?.permissions?.finance) && (
          <Grid
            item
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/admin/financeAnalytics")}
          >
            <Typography className="purplecolortypo">See All</Typography>
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ marginTop: "12px", padding: "0 10px" }}>
        <Button className="purple_btn_primary fs12px cursor_auto">
          Last 7 Days
        </Button>
      </Grid>
      <Grid xs={12}>
        {" "}
        <Bar options={options} data={data} />
      </Grid>
    </Grid>
  );
};

export default Home_Bar_Analytics;
