import React, { useState, useEffect } from "react";
import {
  Button,
  Step,
  Stepper,
  Box,
  StepLabel,
  Typography,
  Grid,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Link, useParams } from "react-router-dom";
//React Icons
import { IoChevronBack } from "react-icons/io5";
//core components that
import AdminDetails from "./SubAdminPages/Sub-Admin";
import Access from "./SubAdminPages/Access";
import Submit from "./SubAdminPages/Submit";
import SuccessDialog from "../../../../utils/Dialog/SuceedFullDialog";
import WrongDialog from "../../../../utils/Dialog/WrongDialog";
import axios from "../../../../api/axiosInstance";
import LoaderDialogue from "../../../../component/LoaderDialogue/LoaderDialogue";
import axiosInstance from "../../../../api/axiosInstance";
//--------------------------- custom hook -------------------------------------//
import useInputScrollRef from "../../../../utils/useInputScrollRef";
//--------------------------- custom hook -------------------------------------//
const steps = ["Sub-Admin Details", "Access"];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function SubAdmin() {
  const params = useParams();
  const newURL = `/admin/manage-user?page=1&tabValue=${params.value}`;

  const { addButtonClicked, inputRefs } = useInputScrollRef();
  const [failedMsg, setFailedMsg] = useState("");
  const [checkForPinCode, setCheckForPinCode] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  //================================ All states =================================//
  const [Country, setCountry] = useState("null");
  const [state, setState] = useState("null");
  const [city, setCity] = useState("null");
  const [pincodes, setPincodes] = useState([]);
  //=========== Data from API ====================//
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [allpincodes, setAllPincodes] = useState([]);
  //================= For Admin-Detials =======================================//
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [landmark, setLandMark] = useState("");
  const [adminCountry, setAdminCountry] = useState("null");
  const [adminState, setAdminState] = useState("");
  const [adminCity, setAdminCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [permissions, setPermissions] = useState([{}]); //===> For access permissions array
  const [adharCard, setAdharCard] = useState("");
  const [adharCardFile, setAdharCardFile] = useState("");
  const [agreement, setAgreement] = useState(false);
  // loading for loader
  const [loading, setLoading] = useState(false);
  // loading for loader
  const [emailErr, setEmailErr] = useState(false);
  const [firstnameErr, setFirstnameErr] = useState(false);
  const [lastnameErr, setLastnameErr] = useState(false);
  const [landmarkErr, setLandmakErr] = useState(false);
  const [adminCountryErr, setAdminCountryErr] = useState(false);
  const [adminStateErr, setAdminStateErr] = useState(false);
  const [adminCityErr, setAdminCityErr] = useState(false);
  const [phoneNumberErr, setPhoneNumberErr] = useState(false);
  const [adharCardErr, setAdharCardErr] = useState(false);
  const [agreementErr, setAgreementErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [pincodeErr, setPincodeErr] = useState(false);
  const [existEmail, setExistEmail] = useState(false);
  const [emailCheck, setEmailCheck] = useState(true);
  const [existPhoneNum, setExistPhoneNum] = useState(false);
  const [mobileNumerCheck, setMobileNumCheck] = useState(true);
  //========================== For Access ===============================//
  const handleNext = () => {
    if (activeStep == 0) {
      addButtonClicked();
      if (!firstName) {
        setFirstnameErr(true);
      }
      if (!email || !email.includes("@")) {
        setEmailErr(true);
      }
      if (!lastName) {
        setLastnameErr(true);
      }
      if (!landmark) {
        setLandmakErr(true);
      }

      if (!phoneNumber || String(phoneNumber)?.trim().length !== 10) {
        setPhoneNumberErr(true);
      }

      if (!agreement) {
        setAgreementErr(true);
      }
      if (!address) {
        setAddressErr(true);
      }
      if (String(pincode)?.trim()?.length !== 6) {
        setPincodeErr(true);
      }
      if (!adminState) {
        setAdminStateErr(true);
      }
      if (!adminCity) {
        setAdminCityErr(true);
      }
      if (existPhoneNum || existEmail) {
        return;
      }
      if (
        email &&
        email.includes("@") &&
        firstName &&
        lastName &&
        landmark &&
        !checkForPinCode &&
        String(pincode)?.trim()?.length == 6 &&
        String(pincode)?.length === 6 &&
        adminCity &&
        String(phoneNumber)?.length === 10 &&
        address &&
        phoneNumber &&
        agreement
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep == 1) {
      if (permissions.length > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep == 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      if (pincodes.length > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  //================================ handling Cluster data ===========================//
  const handleClusterData = (value, datatype) => {
    if (datatype == "permissionArr") {
      console.log("permission Arr Val ==> ", value);
      setPermissions(value);
    } else if (datatype == "email") {
      setEmail(value);
    } else if (datatype == "firstname") {
      setFirstName(value);
    } else if (datatype == "lastname") {
      setLastName(value);
    } else if (datatype == "landmark") {
      setLandMark(value);
    } else if (datatype == "mobilenumber") {
      setPhoneNumber(value);
    } else if (datatype == "address") {
      setAddress(value);
    } else if (datatype == "adminstate") {
      setAdminState(value);
    } else if (datatype == "adminCountry") {
      setAdminCountry(value);
    } else if (datatype == "adminCity") {
      setAdminCity(value);
    } else if (datatype == "pincode") {
      setPincode(value);
    } else if (datatype == "adharCard") {
      setAdharCard(value);
    } else if (datatype == "agreement") {
      setAgreement(value);
    }
  };
  const [openDialogName, setOpenDialog] = React.useState(null);

  const handlePincodeData = (value, datatype) => {
    if (datatype == "Country") {
      if (value == "null") {
        setAllCities([]);
        setAllPincodes([]);
        setAllStates([]);
        setCountry("null");
        setCity("null");
        setState("null");
      } else {
        setCountry(value);
      }
    } else if (datatype == "state") {
      setState(value);
    } else if (datatype == "city") {
      setPincodes([]);
      setCity(value);
    } else if (datatype == "pincodeArr") {
      console.log("pincode Arr Val ==> ", value);
      setPincodes([...value]);
    }
  };
  const [checkedItems, setCheckedItems] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("success"); // or 'error', 'warning', etc.

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const showSnackbar = () => {
    setSnackbarOpen(true);
    setSeverity("error"); // or 'error', 'warning', etc.
  };

  const checkHandler = (e, item) => {
    setCheckedItems((prevCheckedItems) => {
      const isChecked = prevCheckedItems.includes(item.key);
      if (isChecked) {
        return prevCheckedItems.filter(
          (checkedItem) => checkedItem !== item.key
        );
      } else {
        return [...prevCheckedItems, item.key];
      }
    });
  };

  //================================== Submit API ==================================//
  const handleSubmitdata = async () => {
    if (checkedItems.length === 0) {
      showSnackbar();
      return;
    }
    let body = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      landmark: landmark,
      country: adminCountry,
      state: adminState,
      pincode: pincode,
      city: adminCity,
      address: address,
      mobileNumber: `91${phoneNumber}`,
      aadharCard: adharCard,
      allowedPermissions: checkedItems,
      userType: "SubAdmin",
    };
    try {
      setLoading(true);
      let resp = await axios.post("/admin/createSubAdmin", body);
      if (resp.status === 201) {
        setOpenDialog("success");
        setLoading(false);
      } else {
        setFailedMsg(resp?.response?.data?.msg);
        setLoading(false);
        setOpenDialog("reject");
      }
    } catch (error) {
      console.log("SubAdmin creating error ==>", error);
      console.log("i am inside else catch");
      setLoading(false);
      setOpenDialog("reject");
    }
  };
  const searchByPostalCode = async (pincode) => {
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`
    );
    const res = await response.json();
    if (response.ok) {
      const Postaldata = res;
      if (res[0].PostOffice === null || res[0].PostOffice === undefined) {
        setAdminCity("");

        setCheckForPinCode(true);
        return;
      }
      setCheckForPinCode(false);
      setAdminCity(Postaldata?.map((a) => a?.PostOffice[0]?.District)[0]);
      setAdminState(Postaldata?.map((a) => a?.PostOffice[0].State)[0]);
      setAdminCountry("India");
    }
  };

  useEffect(() => {
    if (emailCheck) {
      setEmailCheck(false);
      return;
    }
    let getData = null;
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `/api/user/checkEmailandMobileNumber?email=${email}`
        );
        if (response?.status == 200) {
          setExistEmail(true);
        } else {
          setExistEmail(false);
        }

        console.log(response, "i am response");
      } catch (error) {
        console.error(error);
        setExistEmail(false);
      }
    }

    getData = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => {
      if (getData) {
        clearTimeout(getData);
      }
    };
  }, [email]);
  useEffect(() => {
    if (mobileNumerCheck) {
      setMobileNumCheck(false);
      return;
    }
    let getData = null;
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `/api/user/checkEmailandMobileNumber?mobileNumber=91${phoneNumber}`
        );
        if (response?.status == 200) {
          setExistPhoneNum(true);
        } else {
          setExistPhoneNum(false);
        }

        console.log(response, "i am response");
      } catch (error) {
        console.error(error);
        setExistPhoneNum(false);
      }
    }

    getData = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => {
      if (getData) {
        clearTimeout(getData);
      }
    };
  }, [phoneNumber]);
  useEffect(() => {
    if (firstName) {
      console.log(firstName);
      setFirstnameErr(false);
    }
    if (lastName) {
      setLastnameErr(false);
    }
    if (email && email.includes("@")) {
      setEmailErr(false);
    }
    if (adminCountry) {
      setAdminCountryErr(false);
    }
    if (adminCity) {
      setAdminCityErr(false);
    }
    if (adminState) {
      setAdminStateErr(false);
    }
    if (address) {
      setAddressErr(false);
    }
    if (phoneNumber && phoneNumber?.length === 10) {
      setPhoneNumberErr(false);
    }
    if (landmark) {
      setLandmakErr(false);
    }
    if (agreement) {
      setAgreementErr(false);
    }

    if (pincode) {
      setPincodeErr(false);
    }
  }, [
    email,
    firstName,
    lastName,
    landmark,
    adminCountry,
    adminState,
    pincode,
    adminCity,
    address,
    phoneNumber,
    adharCard,
    agreement,
  ]);
  React.useEffect(() => {
    if (String(pincode).length === 6) {
      searchByPostalCode(pincode);
    } else {
    }
  }, [pincode]);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={severity}
          onClose={handleSnackbarClose}
        >
          Please select at least one option
        </Alert>
      </Snackbar>
      <Box className="width100">
        <div className="flex-class">
          <div className="width-20">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="cursor pt14px">
                <Link to={newURL} className="link-style">
                  <IoChevronBack className="back-arrowcolor" />
                </Link>
              </Typography>
              <Link to={newURL} className="link-style">
                <Typography className="cursor pt7px greycolortypo6F6F6F fs16px">
                  Create Sub-Admin
                </Typography>{" "}
              </Link>
            </Grid>
          </div>
          <div className="width-80">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className=" stepper "
            >
              {steps.map((data, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={data} {...stepProps}>
                    <StepLabel className="step" {...labelProps}>
                      {data}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>{" "}
          </div>
        </div>

        <div className="stepmain-div ">
          {activeStep === 0 && (
            <AdminDetails
              data={{
                inputRefs,
                email,
                firstName,
                lastName,
                landmark,
                adminCountry,
                adminState,
                pincode,
                adminCity,
                address,
                phoneNumber,
                adharCard,
                agreement,
                adharCardFile,
                handleClusterData,
                searchByPostalCode,
                //============///
                emailErr,
                firstnameErr,
                lastnameErr,
                landmarkErr,
                adminCountryErr,
                adminStateErr,
                adminCityErr,
                phoneNumberErr,
                adharCardErr,
                agreementErr,
                addressErr,
                pincodeErr,
                existPhoneNum,
                existEmail,
                checkForPinCode,
              }}
            />
          )}
          {activeStep === 1 && (
            <Access checkedItems={checkedItems} checkHandler={checkHandler} />
          )}
          {activeStep === 2 && (
            <Submit
              data={{
                Country,
                state,
                city,
                pincodes,
                allStates,
                allCities,
                allpincodes,
                setCountry,
                handlePincodeData,
              }}
            />
          )}

          {activeStep === 4 && null}
          <React.Fragment>
            <Box className="stepmain-box ">
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                className="mt10px p-tb20-lr10"
              >
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 4 }}
                  className={activeStep ? "previousbtn " : null}
                >
                  Previous
                </Button>
                {activeStep === 1 ? null : (
                  <Button
                    onClick={handleNext}
                    className={
                      activeStep === steps.length - 1 ? null : "purplebtn"
                    }
                  >
                    Next
                  </Button>
                )}
                {activeStep === 1 ? (
                  <Button className="purplebtn" onClick={handleSubmitdata}>
                    Submit
                    <SuccessDialog
                      openData={openDialogName === "success"}
                      data={"Sub-Admin Added Successfully"}
                      navigateTo={newURL}
                    />
                    <Typography className="fs16px">
                      <WrongDialog
                        openData={openDialogName === "reject"}
                        data={failedMsg}
                      />
                    </Typography>
                  </Button>
                ) : null}
              </Grid>
            </Box>
          </React.Fragment>
        </div>
      </Box>
      <LoaderDialogue loading={loading} />
    </>
  );
}
