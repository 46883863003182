import React, { useState, useEffect } from "react";
import {
  Grid,
  DialogActions,
  Button,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  InputBase,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import { Typography, Grid, Breadcrumbs, Button, Stack } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import SelectedBillboard from "./billBoardTab/SelectedBillboard";
import AllBillboard from "./billBoardTab/AllBillboard";
import Badge from "@mui/material/Badge";
import axiosInstance from "../../../../api/axiosInstance";
//===============================================================================//
import "react-responsive-carousel/lib/styles/carousel.min.css"; //
// import ScreenFilterDialog from "../../BillboardDetail/BillBoardSceenDetails/filter/ScreenFilterDialog";
import ScreenFilterDialog from "../../BillboardDetail/BillBoardSceenDetails/filter/FilterCampaignCreate";
//React Icons
import SearchIcon from "@mui/icons-material/Search";
///===> FetchIP
import { styled } from "@mui/material/styles";
// skeleten loading
//InfiniteScroll
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "@mui/material/Skeleton";
//InfiniteScroll
import useBillboardData from "../../../../utils/useBillboardData";
import useBillboardCamp from "../../../../utils/useBillboardCamp";
import { useCampaignController } from "../../../../Context/campaignBook";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
// checkbox
// Mui Icons
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      "&:focus": {
        width: "90%",
      },
    },
  },
}));
//============================== Main Component =============================//
//===============================================================================//

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
export default function CampaignTab(props) {
  const {
    // states: {},
    TabPre2,
    TabNext2,
    formErrors,
    selectDevice,
  } = props;
  console.log("selected>>>>>>>>", selectDevice);
  const theme = useTheme();
  const [campaignController, campaignDispatch] = useCampaignController();
  const { timeSlots, adDuration } = campaignController;
  const [multiTimeSolts, setMultiTimeSolts] = useState();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [filterState, setFilterState] = useState([]);
  const {
    handleSetFirstVenue,
    handleisFetching,
    handlePriceHandler,
    getFilteredBillboards,
    clearVenueFilter,
    filter,
    firstVenue,
    selectedFilter,
    smartBillboard,
    loading,
    setPage,
    setSmartBillboard,
    setBillboardLength,
    searchBillboard,
    setSearchText,
    setSelectedFilter,
    isFetching,
    filterBadge,
    searchText,
    billboardLength,
  } = useBillboardCamp({
    type: "campaign",
    url: "/api/billboard/getAvailBillboards",
    campData: props.data,
    multiTimes: multiTimeSolts,
    videoDuration: adDuration,
  });
  // infinite loading state
  //checkbox func

  // tab states
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // tab states

  const filterArr =
    smartBillboard?.length > 0 &&
    smartBillboard?.filter((data, index) => {
      if (data?.deviceId !== null) {
        return data;
      }
    });
  //======================= Added By AMAN ===============================//
  function storeBillBoardId(deviceId, selecttype) {
    if (selecttype == "selectAll") {
      let arr = [...props?.data?.billBoardArr];
      if (arr.length == 0) {
        arr =
          filterArr?.length > 0 &&
          filterArr?.map((data, index) => {
            if (data?.deviceId !== null) {
              return data?._id;
            }
          });
        console.log("Arr ===> ", arr);
        props?.data?.handleCampaignData(arr, "billboardarr");
      } else if (arr?.length > 0) {
        arr = [];
        props?.data?.handleCampaignData(arr, "billboardarr");
      }
    }
    //======================================================================//
    else if (deviceId && selecttype == "singleSelect") {
      let arr = [...props.data.billBoardArr]; //selectedBillboards;
      let index = arr.findIndex((element) => element == deviceId);

      if (index == -1) {
        arr.push(deviceId);

        props?.data?.handleCampaignData(arr, "billboardarr");
      } else if (index != -1) {
        arr.splice(index, 1);

        props?.data?.handleCampaignData(arr, "billboardarr");
      }
    }
  }

  const storeDeviceId = (deviceId, selecttype) => {
    if (selecttype == "selectAll") {
      let arr = props.data.deviceArr;
      if (arr.length == 0) {
        arr =
          filterArr?.length > 0 &&
          filterArr?.map((data, index) => {
            if (data?.deviceId !== null) {
              return data?.deviceId?._id;
            }
          });

        props?.data?.handleDevicesArr(arr, "deviceArr");
      } else if (arr.length > 0) {
        arr = [];
        props?.data?.handleDevicesArr(arr, "deviceArr");
      }
    } else if (deviceId && selecttype == "singleSelect") {
      let arr = props?.data?.deviceArr; //selectedBillboards;
      let index = arr.findIndex((element) => element == deviceId);

      if (index == -1) {
        arr.push(deviceId);

        props?.data.handleDevicesArr(arr, "deviceArr");
      } else if (index != -1) {
        arr.splice(index, 1);

        props?.data.handleDevicesArr(arr, "deviceArr");
      }
    }
  };

  function storeMacId(deviceId, selecttype) {
    if (selecttype == "selectAll") {
      console.log("//==== yes SelectAll got hit ====//");
      let arr = props?.data?.macIdArr;
      if (arr.length == 0) {
        arr =
          filterArr?.length > 0 &&
          filterArr.map((data, index) => {
            if (data?.deviceId !== null) {
              return data?.deviceId?.macId;
            }
          });
        console.log("Arr ===> ", arr);
        props?.data?.handleCampaignData(arr, "macIdArr");
      } else if (arr?.length > 0) {
        arr = [];
        props?.data?.handleCampaignData(arr, "macIdArr");
      }
    }
    //======================================================================//
    else if (deviceId && selecttype == "singleSelect") {
      let arr = props?.data?.macIdArr; //selectedBillboards;
      let index = arr.findIndex((element) => element == deviceId);

      if (index == -1) {
        arr.push(deviceId);

        props?.data?.handleCampaignData(arr, "macIdArr");
      } else if (index != -1) {
        arr.splice(index, 1);

        props?.data?.handleCampaignData(arr, "macIdArr");
      }
    }
  }

  const [selectScreens, setSelectScreens] = useState(selectDevice);
  function storeScreenData(e, x, i) {
    console.log("selected x", x);
    let storeArr = [...selectScreens];
    const index = selectScreens.findIndex((a) => a._id === x._id);
    if (x?.deviceId !== null) {
      console.log("xx", index);
      if (index === -1) {
        storeArr.push(x);
      } else {
        storeArr.splice(index, 1);
      }
    }
    setSelectScreens(storeArr);
    props?.data?.selectDeviceHandler(storeArr);
  }

  const selectAllScreensHandler = (e) => {
    if (e.target.checked) {
      if (props?.data?.billBoardArr.length > 0) {
        setSelectScreens([]);
        return;
      }
      let arr =
        smartBillboard?.length > 0 &&
        smartBillboard?.filter((data, index) => {
          if (data?.deviceId !== null) {
            return data;
          }
        });
      setSelectScreens(arr);
      props?.data?.selectDeviceHandler(arr);
    } else {
      setSelectScreens([]);
      props?.data?.selectDeviceHandler([]);
    }
  };

  const filterdata = selectScreens.filter((a) => {
    if (a !== undefined || null) {
      return a;
    }
  });

  useEffect(() => {
    let newValue = [];
    Object.keys(timeSlots)?.map((item) => {
      timeSlots[item]?.map((itemObj) => {
        newValue.push({ ...itemObj, date: item });
      });
    });
    console.log(newValue, "uuuuuuuuuuuuuuuuuuiiooo");

    let multi = [];

    newValue.forEach((item, index) => {
      multi.push({
        date: item?.date,
        startHour: item?.time,
        repetitions: item?.repetition,
        // actualTime: item?.actualTime,
      });
    });
    console.log("sum sum", multi);

    setMultiTimeSolts(multi);
  }, [timeSlots]);

  useEffect(() => {
    console.log("selected>>>>>>>>", selectDevice);
  }, [selectDevice]);

  const getAllCountry = async () => {
    try {
      const res = await axiosInstance.get(
        `api/billboard/getAllDistrictOfBillboard `
      );
      console.log("getAllDistrictOfBillboard", res?.data?.data);
      if (res?.status == 200) {
        setFilterState(res?.data?.data);
      } else {
        console.log("error in  agency members");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCountry();
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <Grid container>
          <Grid
            container
            sx={{
              width: "100%",
            }}
          >
            <Grid
              item
              style={{
                width: "100%",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                centered
                className="TabStyleAddDevice badge_tab"
              >
                <Tab label="All " className="TabChangesDevice" />

                <Tab
                  label="Selected"
                  className="TabChangesDevice"
                  style={{ border: "1px solid #dddd", marginLeft: "5px" }}
                />
                <Badge
                  badgeContent={filterdata?.length}
                  className="filter_badge"
                ></Badge>
              </Tabs>
            </Grid>
          </Grid>

          <Grid container sx={{ width: "100%" }}>
            {" "}
            {value === 0 && (
              <Grid container item xs={12}>
                {" "}
                <Grid
                  container
                  className="fiter-heading mt16px"
                  rowGap="10px"
                  alignItems="center"
                >
                  <Typography align="center" className="greycolor1c1c1c  ">
                    Showing {smartBillboard?.length} out of{" "}
                    {smartBillboard?.length} Smart Billboards
                  </Typography>

                  <Grid item className="fiter-heading-left">
                    <Grid container alignItems="center" gap="10px">
                      <div item>
                        <Search
                          className="search-admin"
                          sx={{
                            height: "36px !important",
                          }}
                        >
                          <SearchIconWrapper>
                            <SearchIcon className="searchicon" />
                          </SearchIconWrapper>
                          <StyledInputBase
                            placeholder="Search Billboard "
                            inputProps={{ "aria-label": "search" }}
                            value={searchText}
                            onChange={(e) => {
                              setPage(1);
                              setSmartBillboard([]);
                              setBillboardLength(0);

                              setSearchText(e.target.value);
                            }}
                          />
                        </Search>
                      </div>
                      <Grid
                        item
                        // className="fiter-heading-right"
                        sx={{ height: "100%" }}
                      >
                        <FormControl
                          className="billboard_price_filter"
                          sx={{ width: "180px !important" }}
                        >
                          <TextField
                            select
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            className="Selectdropstyle"
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={selectedFilter?.size}
                            defaultValue={0}
                            onChange={(e) => {
                              setPage(1);
                              setSmartBillboard([]);
                              setSelectedFilter((prevState) => ({
                                ...prevState,
                                size: e.target.value,
                              }));

                              setSearchText("");
                            }}
                          >
                            <MenuItem
                              value={"1920X1080"}
                              className="blackcolor484848 fs13px"
                            >
                              <Typography className="greycolor505050500 fs14px ">
                                Landscape (1920X1080)
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              value={"1080X1920"}
                              className="blackcolor484848 fs13px"
                            >
                              <Typography className="greycolor505050500 fs14px ">
                                Portrait (1080X1920)
                              </Typography>
                            </MenuItem>
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item sx={{ height: "100%", marginRight: "10px" }}>
                        {" "}
                        <Badge
                          badgeContent={filterBadge}
                          className="filter_badge"
                        >
                          <ScreenFilterDialog
                            states={{
                              selectedFilter,
                              setSelectedFilter,
                              firstVenue,
                            }}
                            filter={filter}
                            filterState={filterState}
                            handleSetFirstVenue={handleSetFirstVenue}
                            handlePriceHandler={handlePriceHandler}
                            clearVenueFilter={clearVenueFilter}
                            getFilteredBillboards={getFilteredBillboards}
                          />
                        </Badge>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <TabPanel
              value={value}
              index={0}
              style={{
                padding: "0px",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <div
                id="scrollableDiv"
                style={{
                  height: "500px",
                  overflowY: "scroll",
                  scrollBehavior: "smooth",
                }}
              >
                <InfiniteScroll
                  dataLength={isFetching.length}
                  next={handleisFetching}
                  hasMore={
                    smartBillboard?.length !== billboardLength ? true : false
                  }
                  scrollableTarget="scrollableDiv"
                  className="width100"
                  loader={
                    <>
                      <></>
                    </>
                  }
                >
                  {" "}
                  <AllBillboard
                    formErrors={formErrors}
                    data={props?.data}
                    storeBillBoardId={storeBillBoardId}
                    storeMacId={storeMacId}
                    storeDeviceId={storeDeviceId}
                    storeScreenData={storeScreenData}
                    selectAllScreensHandler={selectAllScreensHandler}
                    smartBillboard={smartBillboard}
                    filterArr={filterArr}
                    billboardLength={billboardLength}
                    loading={loading}
                    filterdata={filterdata}
                    selectedFilter={selectedFilter}
                  />
                </InfiniteScroll>
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
              style={{
                padding: "0px",

                width: "100%",
              }}
            >
              <SelectedBillboard
                data={props?.data}
                filterdata={filterdata}
                storeBillBoardId={storeBillBoardId}
                storeDeviceId={storeDeviceId}
                storeMacId={storeMacId}
                storeScreenData={storeScreenData}
                loading={loading}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
