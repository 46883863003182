import { useEffect, useState } from "react";

function useAccessPermissionFilter(accessPermission) {
  const filterAccessPermissions = (path) =>
    accessPermission?.filter((data) => data.path === path) || [];

  const [filteredPermissions, setFilteredPermissions] = useState(null);

  useEffect(() => {
    if (accessPermission) {
      setFilteredPermissions({
        Billboard: filterAccessPermissions("billboard"),
        Device: filterAccessPermissions("device"),
        UserProfile: filterAccessPermissions("profile"),
        Campaign: filterAccessPermissions("campaign"),
        UserManagement: filterAccessPermissions("user"),
        Finance: filterAccessPermissions("finance"),
        Coupons: filterAccessPermissions("coupons"),
        Order: filterAccessPermissions("order"),
        Agency: filterAccessPermissions("agency"),
      });
    }
  }, [accessPermission]);

  return filteredPermissions;
}

export default useAccessPermissionFilter;

function useOnlyAccessPermission(accessPermission) {
  const filterAccessPermissions = (path) =>
    accessPermission?.filter((data) => data.path === path && data.allowed) ||
    [];

  const [AccessfilteredPermissions, setAccessFilteredPermissions] =
    useState(null);

  useEffect(() => {
    if (accessPermission) {
      const filteredPermissionsObject = {
        Billboard: filterAccessPermissions("billboard"),
        Device: filterAccessPermissions("device"),
        UserProfile: filterAccessPermissions("profile"),
        Campaign: filterAccessPermissions("campaign"),
        UserManagement: filterAccessPermissions("user"),
        Finance: filterAccessPermissions("finance"),
        Coupons: filterAccessPermissions("coupons"),
        Order: filterAccessPermissions("order"),
        Agency: filterAccessPermissions("agency"),
      };
      const filteredPermissions = Object.fromEntries(
        Object.entries(filteredPermissionsObject).filter(
          ([_, value]) => value.length > 0
        )
      );

      setAccessFilteredPermissions(filteredPermissions);
    }
  }, [accessPermission]);

  return AccessfilteredPermissions;
}

export { useOnlyAccessPermission };
