import React, { useState } from "react";

import { Typography, Grid, Card, CardContent } from "@mui/material";
export default function OverviewTab() {
  return (
    <>
      <Grid container className="mb70px">
        <Grid container spacing={5} className="mt10px">
          <Grid item md={2.4} sm={4} className="cursorpoint">
            <Card className="campaign-analytics-card ">
              <CardContent>
                <Typography className="greycolortypo fs1-5rem ">
                  BILLBOARDS
                </Typography>
                <Typography className="blackcolortypo fs1-5rem    pt10px  ">
                  16,500
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={2.4} sm={4} className="cursorpoint">
            <Card className="campaign-analytics-card ">
              <CardContent>
                <Typography className="greycolortypo fs1-5rem ">
                  ORDERS
                </Typography>
                <Typography className="blackcolortypo fs1-5rem    pt10px  ">
                  74,330
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={2.4} sm={4} className="cursorpoint">
            <Card className="campaign-analytics-card ">
              <CardContent>
                <Typography className="greycolortypo fs1-5rem ">
                  Revenue
                </Typography>
                <Typography className="blackcolortypo fs1-5rem    pt10px  ">
                  ₹ 48,670
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={2.4} sm={4} className="cursorpoint">
            <Card className="campaign-analytics-card ">
              <CardContent>
                <Typography className="greycolortypo fs1-5rem ">
                  VIEWS
                </Typography>
                <Typography className="blackcolortypo fs1-5rem    pt10px  ">
                  ₹ 48,670
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={2.4} sm={4} className="cursorpoint">
            <Card className="campaign-analytics-card ">
              <CardContent>
                <Typography className="greycolortypo fs1-5rem ">
                  ecpm
                </Typography>
                <Typography className="blackcolortypo fs1-5rem    pt10px  ">
                  ₹ 48,670
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
