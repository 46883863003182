import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
const MarginAndCutDialogueTwo = ({
  update,
  updateHandler,
  setPostMyAdPer1,
  setClusterHeadPer1,
  setBusinessOwner1,
  sum,
}) => {
  const [open, setOpen] = React.useState(false);
  console.log(sum, "123");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const checkValueTrue = () => {
    updateHandler(true);
    if (update && sum === 100) {
      updateHandler(false);
    }
  };

  const checkValueFalse = () => {
    // if(sum!==100){

    // }
    setPostMyAdPer1(98);
    setClusterHeadPer1(2);
    setBusinessOwner1(0);
    updateHandler(false);
  };

  return (
    <div>
      <Button
        onClick={() => {
          handleClickOpen();
        }}
        className="btn_secondary"
        style={{ marginTop: "15px" }}
      >
        {update ? "Save Changes" : "Update"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography className="greycolor505050500">
              {update
                ? "  Are you sure you want save the changes"
                : "  Are you sure you want to update the margins"}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="  diallog-no-btn"
            onClick={() => {
              checkValueTrue();
              handleClose();
            }}
          >
            Yes
          </Button>
          <Button
            className="  diallog-no-btn"
            onClick={() => {
              checkValueFalse();
              handleClose();
            }}
            autoFocus
          >
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MarginAndCutDialogueTwo;
