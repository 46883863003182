import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { MAPBOX_ACCESS_KEY } from "../../../../utils/constant";
mapboxgl.accessToken = MAPBOX_ACCESS_KEY;

function Map(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lat, setLat] = useState(28.7041);
  const [lng, setLng] = useState(77.1025);
  const [zoom, setZoom] = useState(2);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });

    // Add markers to the map
    if (Array.isArray(props.data)) {
      props.data.forEach((city) => {
        const marker = new mapboxgl.Marker()
          .setLngLat([city?.longitude, city?.lattitude])
          .addTo(map.current);
      });
    } else {
      const marker = new mapboxgl.Marker()
        .setLngLat([props.data?.longitude, props.data?.lattitude])
        .addTo(map.current);
    }
  }, [props]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, [props]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        ref={mapContainer}
        className={
          props?.map__container2 ? props?.map__container2 : "map-container"
        }
      />
    </div>
  );
}
export default React.memo(Map);
