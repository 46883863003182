import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Grid,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TableContainer,
  TableSortLabel,
} from "@mui/material";
import UserDeleteDialog from "../../Manage-User/ActionButton/UserDeleteDialog";
import PasswordReset from "../../Manage-User/ActionButton/PasswordResetDialog";
import { visuallyHidden } from "@mui/utils";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
//React ICons for
import { GrView } from "react-icons/gr";
import TableSkeleton from "../../../../../component/TableSkeleton/TableSkeleton";
import no_data_img from "../../../../../assets/Img/no_data_found_img.png";
//core components
import permissionUtils from "../../../../../component/permissionUtils";

const headCells = [
  {
    id: "_id",
    numeric: false,
    label: "UID",
  },
  {
    id: "firstName",
    numeric: true,
    label: " Name",
  },

  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Role ",
  },
  {
    id: "email",
    numeric: true,
    label: " Email",
  },
  {
    id: "Contact",
    numeric: true,
    disablePadding: false,
    label: "Contact",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created on",
  },

  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="width100 tablehead homelist-item">
        <TableRow className="tablehead-2">
          {headCells.map((headCell) => (
            <TableCell
              style={{ minWidth: headCell.minWidth }}
              className="whitecolortypo500 tablecell-head"
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "none"}
              sortDirection={orderBy === headCell.firstName ? order : false}
            >
              {headCell.label}
              {headCell?.label !== "Action" &&
                headCell?.label !== "Contact" && (
                  <TableSortLabel
                    align="center"
                    className="whitecolortypo500 "
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {orderBy === headCell.id ? (
                      <Box
                        component="span"
                        sx={visuallyHidden}
                        className="whitecolortypo500"
                      >
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>{" "}
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

//======================================= Main Component =======================================//
export default function CustomPaginationActionsTable(props) {
  const {
    value,
    data,
    getuseraccordingType,
    adminType,
    loading,
    searchQuery,
    route,
  } = props;

  console.log("value", value);

  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sorbyName, setSortByName] = useState("");
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - 25) : 0;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);

  const handleRequestSort = (event, property) => {
    setSortByName(property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (sorbyName == property) {
      getuseraccordingType(searchQuery, property, isAsc ? -1 : 1);
    }
  };

  return (
    <>
      <Grid container className="width100">
        <Grid container className="width100 mt10px">
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer className="width100 table_border_radius">
            <Table
              sx={{
                minWidth: 1000,
                width: "100%",
              }}
              // style={{ tableLayout: "fixed" }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                className="tablerow"
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
              />
              {props?.data && props?.data?.length > 0 ? (
                <TableBody className="tablerow ">
                  {data?.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    console.log("Data from subAdmin ===>", row);
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        hover
                        tabIndex={-1}
                        key={row?._id}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{ width: "13% !important" }}
                        >
                          {/* {row?.deviceUID} */}#{row?._id.slice(-5)}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="greycolortypo text_transform_capitalize"
                          sx={{ width: "13%" }}
                        >
                          {row?.name
                            ? row?.name
                            : `${row?.firstName} ${row?.lastName}`}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "13%" }}
                        >
                          {/* {row?.macId} */}
                          {row?.role}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "20%" }}
                        >
                          {/* {row?.macId} */}
                          {row?.email}
                        </TableCell>
                        <TableCell
                          ccomponent="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="greycolortypo"
                          sx={{ width: "13%" }}
                        >
                          {row?.mobileNumber}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="greycolortypo"
                          sx={{ width: "13%" }}
                        >
                          {new Date(row?.createdAt)
                            .toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .replace(/\./g, "/")}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="greycolortypo"
                          sx={{ width: "15%" }}
                        >
                          <Grid container>
                            <Tooltip title="View" className="tooltipheight">
                              <IconButton className="mt-5px">
                                <Link
                                  to={`/admin/userprofile/${row?._id}/${value}`}
                                  state={adminType}
                                >
                                  <GrView color="rgba(0, 0, 0, 0.54)" />
                                </Link>
                              </IconButton>
                            </Tooltip>

                            {/* <Tooltip title="Switch">
<IconButton
disableRipple
className="ActionButtonSize"
>
<Switch
{...label}
className="SwitchStyle"
open={open}
/>
</IconButton>
</Tooltip> */}
                            {PermissionCheck("change-user-password") && (
                              <PasswordReset
                                getuseraccordingType={getuseraccordingType}
                                userId={row?._id}
                              />
                            )}
                            {PermissionCheck("delete-user") && (
                              <UserDeleteDialog
                                getuseraccordingType={getuseraccordingType}
                                userId={row?._id}
                                adminType={adminType}
                              />
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                        color: "white",
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                loading && (
                  <TableSkeleton
                    rowNumber={new Array(10).fill(0)}
                    tableCell={["13%", "13%", "13%", "20%", "13%", "13%"]}
                    showOption={["25%", "25%", "25%"]}
                  />
                )
              )}
            </Table>

            {props?.data?.length <= 0 && !loading && (
              <Grid container justifyContent="center">
                {" "}
                <img
                  src={no_data_img}
                  alt="no data found"
                  style={{ width: "40%" }}
                />
              </Grid>
            )}
            {/* <Grid container>
<TablePagination
className="width100"
rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
colSpan={3}
count={25}
rowsPerPage={rowsPerPage}
page={page}
SelectProps={{
inputProps: {
"aria-label": "rows per page",
},
native: true,
}}
onPageChange={handleChangePage}
onRowsPerPageChange={handleChangeRowsPerPage}
ActionsComponent={TablePaginationActions}
/>
</Grid> */}
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
