import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { addDays, subDays, differenceInDays } from "date-fns";
import {
  Grid,
  Backdrop,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2 }} className="purple-gradient" {...other}>
      {children}
      <Typography className="whitecolortypo">Confirmation </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Calendar = ({ getDataFromChildHandler }) => {
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");

  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    const startDate = new Date(selection.startDate);
    const endDate = new Date(selection.endDate);
    const diffInDays = differenceInDays(endDate, startDate);

    // if (diffInDays > 60) {
    //   // Adjust the end date to be exactly 31 days from the start date
    //   const adjustedEndDate = addDays(startDate, 365);
    //   selection.endDate = adjustedEndDate;
    // }

    setState([selection]);
  };
  function getDates(startDate, endDate) {
    const dates = [];
    const current = new Date(startDate);
    const end = new Date(endDate);

    while (current <= end) {
      const year = current.getFullYear();
      const month = String(current.getMonth() + 1).padStart(2, "0");
      const day = String(current.getDate()).padStart(2, "0");
      const dateString = `${year}-${month}-${day}`;
      dates.push(dateString);
      current.setDate(current.getDate() + 1);
    }

    return dates;
  }
  // useEffect(() => {
  //   const dates = getDates(state?.[0].startDate, state?.[0].endDate);

  //   var resultArray;

  //   if (dates.length == 1) {
  //     resultArray = [
  //       0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  //       20, 21, 22, 23,
  //     ];
  //   } else if (dates.length > 31) {
  //     resultArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  //   } else {
  //     resultArray = dates;
  //   }

  //   getDataFromChildHandler(state, resultArray);
  // }, [state]);

  useEffect(() => {
    getDataFromChildHandler(
      state,
      getDates(state?.[0].startDate, state?.[0].endDate)?.length > 1
        ? getDates(state?.[0].startDate, state?.[0].endDate)
        : [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23,
          ]
    );
  }, [state]);

  // if (dateType === "date_range") {
  //   setAnalyticsDate(dates2);
  // }

  return (
    <>
      <>
        <input
          onClick={handleClickOpen}
          // disabled
          value={`${
            state?.[0].startDate
              ? moment(state?.[0].startDate).format("DD/MM/YYYY")
              : "dd/mm/yyyy"
          } - ${
            state?.[0].endDate
              ? moment(state?.[0].endDate).format("DD/MM/YYYY")
              : "dd/mm/yyyy"
          }`}
          style={{
            borderRadius: "8px",
            padding: "9px 10px",
            width: "176px",
            fontWeight: "bold",
            fontSize: "14px",
            background: "#fff",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        />
      </>{" "}
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: "SmallDialog",
        }}
      >
        <DateRangePicker
          onChange={handleOnChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
        />
      </BootstrapDialog>
    </>
  );
};

Calendar.propTypes = {
  onChange: PropTypes.func,
};

export default Calendar;
