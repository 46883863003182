import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  Grid,
  DialogActions,
  Button,
  DialogContent,
  // DialogActions,
  DialogTitle,
  Dialog,
  Input,
  FormControl,
  IconButton,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  Box,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
// import timeSolt from "../../../../components/timeSlot/index";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  useCampaignController,
  setTimeSlots,
  setTimeSlotsKey,
  setApplyToAllDays,
} from "../../../../Context/campaignBook";

import {
  useSelectBillboardController,
  setDate,
  setDuration,
  setTime,
  setItemDetail,
  setFinalPrice,
  setAdName,
  setTypeName,
  setAbout,
} from "../../../../Context/selectBillboardNew";
import axiosInstance from "../../../../api/axiosInstance";
import { useSnackbar } from "notistack";

// Model Open

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="dialogtitle" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogcrossicon"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ScheduleTab = (props) => {
  const {
    states: { sec, setRepeat, minsarr, repeat, minTime, minDate },
    TabPre1,
    TabNext1,
    formErrors,
    handleCustomTimeChange,
  } = props;

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [campaignController, campaignDispatch] = useCampaignController();
  const [controller, dispatch] = useSelectBillboardController();
  const {
    campaignName,
    aboutCampaign,
    dateRange,
    adDuration,
    timeSlots,
    timeSlotKey,
    applyToAllDays,
  } = campaignController;
  const { date, duration, adName, typeName, about, itemDetail } = controller;

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());

  useEffect(() => {
    const newMaxDate = new Date(
      selectedDate.getTime() + 90 * 24 * 60 * 60 * 1000
    );
    setMaxDate(newMaxDate);
  }, [selectedDate]);

  const iSm = useMediaQuery(theme.breakpoints.up("sm"));

  // Calculate the max date that can be selected
  //=============================================================//
  // const startMaxDate = new Date().add(1);
  const startMaxDate = new Date();

  startMaxDate.setDate(new Date().getDate() + 60);
  // ---------------------------------------------------------------------------------------------------

  const mobileView = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { billboardId } = useParams();
  const { state } = useLocation();
  const [value, setValue] = useState(0);

  const [currentTime, setCurrentTime] = useState(new Date().getHours());
  const [timeSlot, setTimeSlot] = useState({});

  const [selectedtime, setSelectedTime] = useState(null);
  const [selectTimeDuration, setSelectedTimeDuration] = useState(null);
  const [checked, setChecked] = useState();
  const [pricef, setPriceF] = useState();
  const [applyToAll, setApplyToAll] = useState(applyToAllDays);
  const [selectedSlot, setSelectedSlot] = useState(timeSlots || {});
  const [selectedSlotKey, setSelectedSlotKey] = useState(timeSlotKey || {});
  const [scheduleDate, setScheduleDate] = useState(dateRange || []);

  // model open states

  const [open, setOpen] = useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectSlot = (data) => {
    let temp = selectedSlotKey[scheduleDate[value]] || [];
    let temp2 = selectedSlot[scheduleDate[value]] || [];
    console.log(">>>>>>>", temp, temp2, data);

    if (selectedSlotKey[scheduleDate[value]]?.includes(data?.time)) {
      temp = temp.filter((key) => key != data?.time);

      setSelectedSlotKey({ ...selectedSlotKey, [scheduleDate[value]]: temp });
      temp2 = temp2.filter((item) => item?.time != data?.time);
      setSelectedSlot({ ...selectedSlot, [scheduleDate[value]]: temp2 });
    } else {
      temp.push(data?.time);
      setSelectedSlotKey({ ...selectedSlotKey, [scheduleDate[value]]: temp });
      data = { ...data, repetition: 1 };

      temp2.push(data);
      setSelectedSlot({ ...selectedSlot, [scheduleDate[value]]: temp2 });
    }
    console.log(">>>>>>>", temp, temp2);
  };

  const applyToAllDay = () => {
    if (!selectedSlotKey[scheduleDate[0]])
      return enqueueSnackbar("Please Choose any Slot", {
        variant: "error",
        autoHideDuration: 5000,
      });
    let firstDaySlotKey = selectedSlotKey[scheduleDate[0]];
    let firstDaySlot = selectedSlot[scheduleDate[0]];
    let newSelectedSlotKey = {};
    let newSelectedSlot = {};
    scheduleDate.map((day) => {
      newSelectedSlotKey[day] = JSON.parse(JSON.stringify(firstDaySlotKey));
      newSelectedSlot[day] = JSON.parse(JSON.stringify(firstDaySlot));
    });
    setSelectedSlotKey(newSelectedSlotKey);
    setSelectedSlot(newSelectedSlot);
    setApplyToAll(true);
    setValue(scheduleDate?.length - 1);
    setApplyToAllDays(campaignDispatch, true);
    handleClose();
  };

  const handleTags = (value) => {
    // console.log("handle Tags of Add-Bill-Board.js ===>", value)
    // setFormdata({ ...formdata, venueTags: value });
  };

  const handleClickitem = (name, i) => {
    let temparr = [...checked];
    let index = temparr.findIndex((item) => item === name);
    if (index === -1) {
      temparr.push(name);
    } else {
      temparr.splice(index, 1);
    }
    setChecked(temparr);
    handleTags(temparr);
    // console.log("timeslot", timeSlots);
  };

  const getTimeSlot = async () => {
    try {
      console.log("getTimeSlot called ");
      let UserId;

      const response = await axiosInstance.post(
        `api/billBoardBooking/bookingSlot`,
        {
          date: date,
          billboardId: "64a50d286d9435b702eaabf5",
          //   videoDuration: duration * 30,
        }
      );
      console.log("getTime Slot>>", response);
      if (response.status == 200 || response.status == 201) {
        let dataTimeSlot = response.data.data[0].timeslots;
        dataTimeSlot.map((data) => {
          if (data?.time < 4) {
            if (data.time === 0) data["actualTime"] = "12am - 1am";
            else data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
          } else if (data?.time > 3 && data?.time < 8) {
            data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
          } else if (data.time > 7 && data.time < 12) {
            if (data.time === 11) data["actualTime"] = "11am - 12pm";
            else data["actualTime"] = `${data.time}am - ${data.time + 1}am`;
          } else if (data.time > 11 && data.time < 16) {
            if (data.time === 12)
              data["actualTime"] = `12pm - ${data.time - 12 + 1}pm`;
            else
              data["actualTime"] = `${data.time - 12}pm - ${
                data.time - 12 + 1
              }pm`;
          } else if (data.time > 15 && data.time < 20) {
            data["actualTime"] = `${data.time - 12}pm - ${
              data.time - 12 + 1
            }pm`;
          } else if (data.time > 19) {
            if (data.time === 23)
              data["actualTime"] = `11pm - ${data.time - 12 + 1}am`;
            else
              data["actualTime"] = `${data.time - 12}pm - ${
                data.time - 12 + 1
              }pm`;
          }
        });
        // setTimeSlots(dataTimeSlot || []);
        // setTimeSlots();
      }
    } catch (err) {
      console.log("error getting timeslot");
    }
  };

  const handleChangeRepetition = (data, selectValue, index) => {
    let dataAll = selectedSlot[scheduleDate[value]];
    dataAll?.map((item, i) => {
      if (item?.time === data?.time) {
        item["repetition"] = selectValue;
      }
    });

    setSelectedSlot({
      ...selectedSlot,
      [scheduleDate[value]]: JSON.parse(JSON.stringify(dataAll)),
    });
  };

  useEffect(() => {
    setSelectedTimeDuration(duration * 30);
    // if (date && duration && billboardId)
    getTimeSlot();
  }, [duration]);

  useEffect(() => {
    setSelectedTime(null);
  }, [date]);

  useEffect(() => {
    if (state) {
      setAdName(dispatch, state?.adName);
      setTypeName(dispatch, state?.typeName);
      setAbout(dispatch, state?.about);
    }
  }, [state]);

  useEffect(() => {
    if (scheduleDate) {
      let temp = {};

      scheduleDate.map((item) => {
        temp = {
          ...temp,
          [item]: [
            {
              actualTime: "12am-1am",
              time: 0,
              secondsLeft: "3600",
            },
            {
              actualTime: "1am-2am",
              time: 1,
              secondsLeft: "3600",
            },
            {
              actualTime: "2am-3am",
              time: 2,
              secondsLeft: "3600",
            },
            {
              actualTime: "3am-4am",
              time: 3,
              secondsLeft: "3600",
            },
            {
              actualTime: "4am-5am",
              time: 4,
              secondsLeft: "3600",
            },
            {
              actualTime: "5am-6am",
              time: 5,
              secondsLeft: "3600",
            },
            {
              actualTime: "6am-7am",
              time: 6,
              secondsLeft: "3600",
            },
            {
              actualTime: "7am-8am",
              time: 7,
              secondsLeft: "3600",
            },
            {
              actualTime: "8am-9am",
              time: 8,
              secondsLeft: "3600",
            },
            {
              actualTime: "9am-10am",
              time: 9,
              secondsLeft: "3600",
            },
            {
              actualTime: "10am-11am",
              time: 10,
              secondsLeft: "3600",
            },
            {
              actualTime: "11am-12pm",
              time: 11,
              secondsLeft: "3600",
            },
            {
              actualTime: "12pm-1pm",
              time: 12,
              secondsLeft: "3600",
            },
            {
              actualTime: "1pm-2pm",
              time: 13,
              secondsLeft: "3600",
            },
            {
              actualTime: "2pm-3pm",
              time: 14,
              secondsLeft: "3600",
            },
            {
              actualTime: "3pm-4pm",
              time: 15,
              secondsLeft: "3600",
            },
            {
              actualTime: "4pm-5pm",
              time: 16,
              secondsLeft: "3600",
            },
            {
              actualTime: "5pm-6pm",
              time: 17,
              secondsLeft: "3600",
            },
            {
              actualTime: "6pm-7pm",
              time: 18,
              secondsLeft: "3600",
            },
            {
              actualTime: "7pm-8pm",
              time: 19,
              secondsLeft: "3600",
            },
            {
              actualTime: "8pm-9pm",
              time: 20,
              secondsLeft: "3600",
            },
            {
              actualTime: "9pm-10pm",
              time: 21,
              secondsLeft: "3600",
            },
            {
              actualTime: "10pm-11pm",
              time: 22,
              secondsLeft: "3600",
            },
            {
              actualTime: "11pm-12am",
              time: 23,
              secondsLeft: "3600",
            },
          ],
        };
      });
      setTimeSlot(temp);
    }
  }, [scheduleDate]);
  const handleChange = (e, newValue) => {
    if (selectedSlotKey[scheduleDate[value]]) setValue(newValue);
    else if (newValue < value) setValue(newValue);
  };

  useEffect(() => {
    setTimeSlots(campaignDispatch, selectedSlot);
    setTimeSlotsKey(campaignDispatch, selectedSlotKey);
  }, [selectedSlot, selectedSlotKey]);
  console.log("scheduleDate>>>>>>>>>>>>>>>>>>>>", selectedSlot);

  return (
    <>
      <Grid container item className="mt10px">
        <Tabs
          value={value}
          onChange={handleChange}
          className="TabStyleCampaign Border" //MuiTabs-centered
          centered={iSm ? true : false}
          variant={"scrollable"}
          scrollButtons
          sx={{
            width: "100%",
          }}
          // iSm ? false :
        >
          {scheduleDate?.map((item, i) => (
            <Tab
              label={item}
              key={i}
              className={
                selectedSlotKey[item] && selectedSlotKey[item].length > 0
                  ? "TabChangesDevice font-Lato white-color mui_selected_tab"
                  : "TabChangesDevice font-Lato"
              }
            />
          ))}
        </Tabs>

        <Grid
          container
          item
          xs={12}
          sm={12}
          gap={1}
          sx={{ margin: "2vh auto", justifyContent: "space-between" }}
        >
          <Grid
            container
            justifyContent="space-between"
            xs={12}
            sm={6}
            md={7}
            className="Border borderRadius_8px"
          >
            {/* <Grid
              className="width100 flex"
              sx={{ padding: '10px', borderBottom: '1px solid silver' }}
            >
              <Typography className="flex">
                <Typography className="bold fs16px">
                  Selected Duration :{' '}
                </Typography>
                <Typography className="bold fs16px colorApply">
                  {' '}
                  {duration} Second
                </Typography>
              </Typography>
            </Grid> */}
            <Grid
              container
              item
              xs={12}
              className="width100 flex"
              // sx={{ padding: "10px", borderBottom: "1px solid silver" }}
            >
              {/* <Grid item md={4}>
                <Typography className="bold fs16px">
                  Select available time slot
                </Typography>
              </Grid>

              <Grid item md={8} sx={{ columnGap: "15px" }} className="flex">
                <Grid
                  className="flex"
                  sx={{ alignItems: "center", columnGap: "5px" }}
                >
                  <Typography
                    sx={{
                      height: "15px",
                      width: "15px",
                      backgroundColor: "green",
                      borderRadius: "4px",
                    }}
                  ></Typography>
                  <Typography className="fs12px bold">Selected</Typography>
                </Grid>
                <Grid
                  className="flex"
                  sx={{ alignItems: "center", columnGap: "5px" }}
                >
                  <Typography
                    sx={{
                      height: "15px",
                      width: "15px",
                      backgroundColor: "gray",
                      borderRadius: "4px",
                    }}
                  ></Typography>
                  <Typography className="fs12px bold">Not Available</Typography>
                </Grid>
                <Grid
                  className="flex"
                  sx={{ alignItems: "center", columnGap: "5px" }}
                >
                  <Typography
                    sx={{
                      height: "15px",
                      width: "15px",
                      backgroundColor: "orange",
                      borderRadius: "4px",
                    }}
                  ></Typography>
                  <Typography className="fs12px bold">
                    Few minutes remaining
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>

            <Grid
              className="flex"
              flexDirection="column"
              gap="20px"
              sx={{
                height: "70vh",
                overflow: "auto",
                padding: "10px",
              }}
            >
              <Grid>
                <div
                  className="grid_container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    // justifyContent: "center",
                  }}
                >
                  {timeSlot[scheduleDate[value]]?.map(
                    (data, index) => (
                      // data.ownedBy ? (
                      <>
                        {data.time == 0 && (
                          <Typography className=" fs14px mt10px bold  width100">
                            MidNight{" "}
                          </Typography>
                        )}
                        {data.time == 4 && (
                          <Typography className=" fs14px mt10px bold  width100">
                            Early Morning{" "}
                          </Typography>
                        )}
                        {data.time == 8 && (
                          <Typography className=" fs14px mt10px bold  width100">
                            Morning
                          </Typography>
                        )}
                        {data.time == 12 && (
                          <Typography className=" fs14px mt10px bold  width100">
                            Afternoon
                          </Typography>
                        )}
                        {data.time == 16 && (
                          <Typography className=" fs14px mt10px bold  width100">
                            Evening
                          </Typography>
                        )}
                        {data.time == 20 && (
                          <Typography className=" fs14px mt10px bold  width100">
                            Night
                          </Typography>
                        )}
                        <Grid
                          style={
                            mobileView ? { width: "85%" } : { width: "auto" }
                          }
                          className="grid_container flex"
                        >
                          <Grid
                            container
                            direction="column"
                            gap="5px"
                            xs={12}
                            style={{
                              minWidth: "200px",
                            }}
                          >
                            <Button
                              key={index}
                              className={
                                selectedSlotKey[scheduleDate[value]]?.includes(
                                  data?.time
                                )
                                  ? " selectedbg"
                                  : data.secondsLeft <= 3600
                                  ? "   timeslot-green-btn"
                                  : data.secondsLeft <= 1800
                                  ? "  timelslot-orange-btn "
                                  : data.secondsLeft <= 0
                                  ? "   timelslot-orange-btn "
                                  : null
                              }
                              sx={
                                moment().hour() >= data?.time &&
                                moment().format("YYYY-MM-DD") ==
                                  scheduleDate[value] && {
                                  color: "#FFFFFF !important",
                                  backgroundColor: "#ddd !important",
                                  border: "1px solid #ddd !important",
                                  cursor: "not-allowed !important",
                                }
                              }
                              onClick={() => {
                                handleSelectSlot(data);
                              }}
                              disabled={
                                moment().hour() >= data?.time &&
                                moment().format("YYYY-MM-DD") ==
                                  scheduleDate[value]
                                  ? true
                                  : false
                              }
                            >
                              <Typography
                                align="center"
                                className="fs12px timeslot-typo"
                              >
                                {data?.actualTime}
                              </Typography>

                              <Typography className="fs12px flex center timeslot-typoType">
                                {Math.floor(data.secondsLeft / adDuration)}{" "}
                                Slots
                              </Typography>
                            </Button>

                            {/* <Grid container item>
                              <Typography className="fs12px black-price">{`Price : ₹ ${data?.finalprice}/sec`}</Typography>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </>
                    )
                    // ) : null
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={5.5}
            md={4.9}
            className="Border borderRadius_8px"
            sx={{
              border: "2px solid gray",
              display: "block !important",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderBottom: "1px solid silver",
                padding: "5px 10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography className="fs16px bold">Repetition</Typography>
              {value == 0 && !applyToAll && (
                <Button
                  className="disabledbtn fs14px"
                  sx={{ padding: "5px 10px !important" }}
                  onClick={handleClickOpen}
                >
                  Apply to all days
                </Button>
              )}
              {applyToAll && (
                <Button
                  className=" fs14px  borderRadius_8px "
                  sx={{
                    border: "1px solid green",
                    color: "green",
                  }}
                >
                  Applied to all days <CheckCircleOutlineIcon />
                </Button>
              )}
            </Grid>

            <Grid
              className="grid_container flex width100 flexSpaceJc "
              style={{
                flexWrap: "wrap",
                padding: "15px 10px",
              }}
            >
              <Grid
                className="flex width100"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography className="bold fs14px">
                  Selected timeslots
                </Typography>
                <Typography className="bold fs14px">Repetition</Typography>
              </Grid>
              {/* data.time > 3 && data.time < 8 && data.ownedBy  */}
              <Grid
                className="flex"
                flexDirection="column"
                gap="20px"
                sx={{
                  height: "60vh",
                  overflow: "auto",
                  padding: "10px",
                }}
                item
                xs={12}
              >
                {selectedSlot[scheduleDate[value]]?.map((data, index) => (
                  <>
                    <Grid
                      className="flex width100 flexSpaceJc centerAi mb10px "
                      // sx={{
                      //   alignItems: "center",
                      //   marginBottom: "10px",
                      //   justifyContent: "space-between",
                      // }}
                      gap={1}
                    >
                      <Grid
                        item
                        // style={
                        //   mobileView ? { width: "85%" } : { width: "auto" }
                        // }
                        className="grid_container flex  flexSpaceJc"
                      >
                        <Grid
                          item
                          gap="5px"
                          xs={12}
                          style={{
                            minWidth: "200px",
                            maxWidth: "250px",
                          }}
                          className="flex centerAi"
                        >
                          <CancelOutlinedIcon
                            className=" Redcolor"
                            style={{
                              color: "red",
                              cursor: "pointer !important",
                            }}
                            onClick={() => {
                              // setSelectedTime(data.time);
                              // setPriceF(data.finalprice);
                              // setLeftSeconds(data);
                              handleSelectSlot(data);
                            }}
                          />
                          <Button
                            key={index}
                            className={
                              selectedSlotKey[scheduleDate[value]]?.includes(
                                data.time
                              )
                                ? " selectedbg"
                                : data.secondsLeft <= 3600
                                ? "   timeslot-green-btn"
                                : data.secondsLeft <= 1800
                                ? "  timelslot-orange-btn "
                                : data.secondsLeft <= 0
                                ? "   timelslot-orange-btn "
                                : null
                            }
                            sx={
                              new Date().toISOString().slice(0, 10) == date &&
                              data?.time < currentTime && {
                                color: "#ffffff !important",
                                backgroundColor: "#ddd !important",
                                border: "1px solid #ddd !important",
                              }
                            }
                            onClick={() => {
                              // setSelectedTime(data.time);
                              // setPriceF(data.finalprice);
                              // setLeftSeconds(data);
                              handleSelectSlot(data);
                            }}
                            disabled={
                              new Date().toISOString().slice(0, 10) == date &&
                              (data?.secondsLeft == 0 ||
                                data?.time < currentTime)
                                ? true
                                : false
                            }
                          >
                            <Typography
                              align="center"
                              className="fs12px timeslot-typo"
                            >
                              {data?.time < 4 &&
                                `${
                                  data.time === 0
                                    ? "12am - 1am"
                                    : `${data?.time}am - ${data?.time + 1}am`
                                }`}

                              {data?.time > 3 &&
                                data?.time < 8 &&
                                `${
                                  data?.time === 0
                                    ? "12am - 1am"
                                    : `${data?.time}am - ${data?.time + 1}am`
                                }`}
                              {data?.time > 7 &&
                                data?.time < 12 &&
                                `${
                                  data?.time === 11
                                    ? "11am - 12pm"
                                    : `${data?.time}am - ${data?.time + 1}am`
                                }`}
                              {data?.time > 11 &&
                                data?.time < 16 &&
                                `${
                                  data?.time === 12
                                    ? `12pm - ${data?.time - 12 + 1}pm`
                                    : `${data?.time - 12}pm - ${
                                        data?.time - 12 + 1
                                      }pm`
                                }`}
                              {data?.time > 15 &&
                                data?.time < 20 &&
                                `${data?.time - 12}pm - ${
                                  data?.time - 12 + 1
                                }pm`}
                              {data?.time > 19 &&
                                `${
                                  data?.time === 23
                                    ? `11pm - ${data?.time - 12 + 1}am`
                                    : `${data?.time - 12}pm - ${
                                        data?.time - 12 + 1
                                      }pm`
                                }`}
                            </Typography>
                            <Typography
                              className="fs12px flex center timeslot-typoType"
                              justifyContent="center"
                              sx={{ paddingLeft: "5px" }}
                            >
                              {Math.floor(data?.secondsLeft / adDuration)} Slot
                            </Typography>
                          </Button>
                          {/* <Grid container item>
                              <Typography className="fs12px black-price">{`Price : ₹ ${data?.finalprice}/sec`}</Typography>
                            </Grid> */}
                        </Grid>
                      </Grid>
                      <Typography className=" bold">X</Typography>

                      <Box>
                        <FormControl
                          fullWidth
                          // style={{ paddingLeftLeft: "5px" }}
                        >
                          <Select
                            value={data?.repetition || 1}
                            onChange={(e) =>
                              handleChangeRepetition(
                                data,
                                e.target.value,
                                index
                              )
                            }
                            sx={{ height: "35px", paddingLeftLeft: "5px" }}
                            className="borderRadius_8px"
                          >
                            {Array.from(
                              new Array(data?.secondsLeft / adDuration)
                            )?.map((item, i) => (
                              <MenuItem value={i + 1} key={i}>
                                {i + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  </>
                ))}

                {value < scheduleDate?.length - 1 ? (
                  <Button
                    className={
                      selectedSlot[scheduleDate[value]]?.length > 0
                        ? "purplebtn width100 fs14px"
                        : " disabledbtn width100 fs14px"
                    }
                    onClick={() => {
                      handleChange("", value + 1);
                      // setValue(() => value + 1);
                    }}
                    // disabled={selectedSlot.length > 0 ? false : true}
                  >
                    Next Day
                  </Button>
                ) : (
                  <Button
                    className={
                      selectedSlot[scheduleDate[value]]?.length > 0
                        ? "purplebtn width100 fs14px"
                        : " disabledbtn width100 fs14px"
                    }
                    onClick={() => {
                      setTimeSlots(campaignDispatch, selectedSlot);
                      console.log(selectedSlot);
                    }}
                    disabled={
                      selectedSlot[scheduleDate[value]]?.length > 0
                        ? false
                        : true
                    }
                  >
                    Completed
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <BootstrapDialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Typography className="whitecolortypo500">
              Apply to all days
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent sx={{ m: 2 }}>
            <div>
              <Grid container>
                <Grid container>
                  <Typography className="greycolor505050500 fs16px mt15px">
                    Are you sure you want to select same timeslot and repetition
                    for other days as well?
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography className="greycolor505050500 fs16px mt15px">
                    This will play this campaign at selected timeslots and
                    repetition on everyday
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </DialogContent>

          <DialogActions>
            <Button className="previousbtn" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="purplebtn" onClick={() => applyToAllDay()}>
              Yes
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ScheduleTab;
