import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Input,
  Button,
  DialogActions,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { FETCH_URL } from "../../../../../../../fetchIp";
import moment from "moment";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const [timetype, setTimetype] = useState((timetype) => {
    if (moment(new Date()).format("HH") >= 12) {
      return "PM";
    } else if (moment(new Date()).format("HH") < 12) {
      return "AM";
    }
  });
  console.log(timetype, "timetypetimetype");
  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "#fffff" }} {...other}>
      {children}
      <Grid container justifyContent="space-between" className="width-80">
        <Typography className="greycolor505050400">
          Select available time slot
        </Typography>
        <Typography className="greycolor505050400">
          <span className="timeslot-spangreen"></span> Available
        </Typography>
        <Typography className="greycolor505050400">
          <span className="timeslot-spanred"></span> Not Available
        </Typography>
        <Typography className="greycolor505050400">
          <span className="timeslot-spanorange"></span> Few minute remaning
        </Typography>
      </Grid>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 5,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function MaxWidthDialog({
  billboardId,
  getTimeSlot,
  duration,
  date,
  editTimeSlot2,
  setEditTimeSlot2,
  editRepeatSec,
}) {
  const [open, setOpen] = React.useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().getHours());
  const [timeslot, setTimeSlot] = React.useState([]);
  // time detail
  const [selectTimeDetail, setSelectTimeDetail] = useState([]);
  // time detail

  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const closeHandler = () => {
    setOpen(false);
  };

  const getTimeSlotdata = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/billBoardBooking/bookingSlot`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            date: date,
            billboardId: billboardId,
            videoDuration: duration * editRepeatSec,
          }),
        }
      );
      let res = await response.json();
      if (response.ok) {
        setTimeSlot(res.data[0].timeslots);
        console.log("response from getTimeSlot ==> ", res.data);
      } else {
        console.log("Error From Server ==> ", res.msg);
      }
    } catch (error) {
      console.log("error from GetTimeSlotdata =>", error);
    }
  };
  React.useEffect(() => {
    getTimeSlotdata();
  }, [duration, date, editRepeatSec]);

  return (
    <React.Fragment>
      <div className="relative">
        <Typography className="timeSlot_selectTime_typo">Time Slot</Typography>
        <Grid
          container
          className="mt5px"
          style={{
            padding: "6px ",
            borderRadius: "4px",
            border: "1px solid #dddddd",
          }}
        >
          <Input
            onClick={handleClickOpen}
            variant="standard"
            className="width100 greycolor505050400 "
            disableUnderline
            value={
              editTimeSlot2 === null
                ? "select time"
                : editTimeSlot2 === 12
                ? "12pm - 1pm"
                : editTimeSlot2 > 12
                ? `${editTimeSlot2 - 12}pm - ${editTimeSlot2 + 1 - 12}pm`
                : `${editTimeSlot2}am - ${editTimeSlot2 + 1}am`
            }
          />
        </Grid>
      </div>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <Divider />

        <DialogContent>
          <Grid container justifyContent="space-between">
            <Typography className="greycolor505050400 fs18px mt10px  width100">
              MidNight
            </Typography>
            <div className="grid_container" style={{ width: "100%" }}>
              {timeslot.map((data, index) => {
                return (
                  <>
                    {data.time < 4 && data.ownedBy ? (
                      <div>
                        <Grid container direction="column" gap="10px">
                          <Button
                            className={
                              editTimeSlot2 === data.time
                                ? "mt20px selectedbg"
                                : data.secondsLeft <= 3600 &&
                                  data.secondsLeft > 1800
                                ? " mt20px  timeslot-green-btn"
                                : data.secondsLeft <= 1800 &&
                                  data.secondsLeft > 0
                                ? " mt20px timelslot-orange-btn"
                                : data.secondsLeft == 0
                                ? "mt20px timelslot-red-btn"
                                : "mt20px timelslot-red-btn"
                            }
                            onClick={() => {
                              setEditTimeSlot2(data.time);
                              setSelectTimeDetail(data);
                            }}
                            sx={
                              new Date().toISOString().slice(0, 10) == date &&
                              data.time < currentTime && {
                                color: "#ffffff !important",
                                backgroundColor: "#ddd !important",
                                border: "1px solid #ddd !important",
                              }
                            }
                            disabled={
                              new Date().toISOString().slice(0, 10) == date &&
                              (data.secondsLeft == 0 || data.time < currentTime)
                                ? true
                                : false
                            }
                          >
                            <Typography
                              align="center"
                              className="fs14px timeslot-typo"
                            >
                              {`${
                                data.time === 0
                                  ? "12am - 1am"
                                  : `${data.time}am - ${data.time + 1}am`
                              }`}{" "}
                            </Typography>
                            <Typography
                              sx={{ width: "50%" }}
                              className="fs12px"
                            >
                              {data.secondsLeft > 0 ? data.secondsLeft : 0}{" "}
                              Remaning
                            </Typography>
                          </Button>
                          <Grid container item>
                            <Typography className="fs14px">{`Price : ₹ ${data?.finalprice}/sec`}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
            <Typography className="greycolor505050400 fs18px mt10px  width100">
              Early Morning
            </Typography>
            <div className="grid_container" style={{ width: "100%" }}>
              {timeslot.map((data, index) => {
                return (
                  <>
                    {data.time > 3 && data.time < 8 && data.ownedBy ? (
                      <div>
                        <Grid container direction="column" gap="10px">
                          <Button
                            className={
                              editTimeSlot2 === data.time
                                ? "mt20px purpleselectedbg"
                                : data.secondsLeft <= 3600 &&
                                  data.secondsLeft > 1800
                                ? " mt20px  timeslot-green-btn"
                                : data.secondsLeft <= 1800 &&
                                  data.secondsLeft > 0
                                ? " mt20px timelslot-orange-btn"
                                : data.secondsLeft == 0
                                ? "mt20px timelslot-red-btn"
                                : "mt20px timelslot-red-btn"
                            }
                            onClick={() => {
                              setEditTimeSlot2(data.time);
                              setSelectTimeDetail(data);
                            }}
                            sx={
                              new Date().toISOString().slice(0, 10) == date &&
                              data.time < currentTime && {
                                color: "#ffffff !important",
                                backgroundColor: "#ddd !important",
                                border: "1px solid #ddd !important",
                              }
                            }
                            disabled={
                              new Date().toISOString().slice(0, 10) == date &&
                              (data.secondsLeft == 0 || data.time < currentTime)
                                ? true
                                : false
                            }
                          >
                            <Typography
                              align="center"
                              className="fs14px timeslot-typo"
                            >
                              {`${
                                data.time === 0
                                  ? "12am - 1am"
                                  : `${data.time}am - ${data.time + 1}am`
                              }`}
                            </Typography>
                            <Typography
                              sx={{ width: "50%" }}
                              className="fs12px"
                            >
                              {data.secondsLeft > 0 ? data.secondsLeft : 0}{" "}
                              Remaning
                            </Typography>
                          </Button>
                          <Grid container item>
                            <Typography className="fs14px">{`Price : ₹ ${data?.finalprice}/sec`}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
            <Typography className="greycolor505050400 fs18px mt10px  width100">
              Morning
            </Typography>
            <div className="grid_container" style={{ width: "100%" }}>
              {timeslot.map((data, index) => {
                // console.log("data from timeslot ==> ", timeslot);
                return (
                  <>
                    {data.time > 7 && data.time < 12 && data.ownedBy ? (
                      <div>
                        <Grid container direction="column" gap="10px">
                          <Button
                            className={
                              editTimeSlot2 === data.time
                                ? "mt20px purpleselectedbg"
                                : data.secondsLeft <= 3600 &&
                                  data.secondsLeft > 1800
                                ? " mt20px  timeslot-green-btn"
                                : data.secondsLeft <= 1800 &&
                                  data.secondsLeft > 0
                                ? " mt20px timelslot-orange-btn"
                                : data.secondsLeft == 0
                                ? "mt20px timelslot-red-btn"
                                : "mt20px timelslot-red-btn"
                            }
                            onClick={() => {
                              setEditTimeSlot2(data.time);
                              setSelectTimeDetail(data);
                            }}
                            sx={
                              new Date().toISOString().slice(0, 10) == date &&
                              data.time < currentTime && {
                                color: "#ffffff !important",
                                backgroundColor: "#ddd !important",
                                border: "1px solid #ddd !important",
                              }
                            }
                            disabled={
                              new Date().toISOString().slice(0, 10) == date &&
                              (data.secondsLeft == 0 || data.time < currentTime)
                                ? true
                                : false
                            }
                          >
                            <Typography
                              align="center"
                              className="fs14px timeslot-typo"
                            >
                              {`${
                                data.time === 11
                                  ? "11am - 12pm"
                                  : `${data.time}am - ${data.time + 1}am`
                              }`}
                            </Typography>
                            <Typography
                              sx={{ width: "50%" }}
                              className="fs12px"
                            >
                              {data.secondsLeft > 0 ? data.secondsLeft : 0}{" "}
                              Remaning
                            </Typography>
                          </Button>
                          <Grid container item>
                            <Typography className="fs14px">{`Price : ₹ ${data?.finalprice}/sec`}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
            <Typography className="greycolor505050400 fs18px mt10px  width100">
              Afternoon
            </Typography>
            <div className="grid_container" style={{ width: "100%" }}>
              {timeslot.map((data, index) => {
                // console.log("data from timeslot ==> ", timeslot);
                return (
                  <>
                    {data.time > 11 && data.time < 16 && data.ownedBy ? (
                      <div>
                        <Grid container direction="column" gap="10px">
                          <Button
                            className={
                              editTimeSlot2 === data.time
                                ? "mt20px purpleselectedbg"
                                : data.secondsLeft <= 3600 &&
                                  data.secondsLeft > 1800
                                ? " mt20px  timeslot-green-btn"
                                : data.secondsLeft <= 1800 &&
                                  data.secondsLeft > 0
                                ? " mt20px timelslot-orange-btn"
                                : data.secondsLeft == 0
                                ? "mt20px timelslot-red-btn"
                                : "mt20px timelslot-red-btn"
                            }
                            onClick={() => {
                              setEditTimeSlot2(data.time);
                              setSelectTimeDetail(data);
                            }}
                            sx={
                              new Date().toISOString().slice(0, 10) == date &&
                              data.time < currentTime && {
                                color: "#ffffff !important",
                                backgroundColor: "#ddd !important",
                                border: "1px solid #ddd !important",
                              }
                            }
                            disabled={
                              new Date().toISOString().slice(0, 10) == date &&
                              (data.secondsLeft == 0 || data.time < currentTime)
                                ? true
                                : false
                            }
                          >
                            <Typography
                              align="center"
                              className="fs14px timeslot-typo"
                            >
                              {`${
                                data.time === 12
                                  ? `12pm - ${data.time - 12 + 1}pm`
                                  : `${data.time - 12}pm - ${
                                      data.time - 12 + 1
                                    }pm`
                              }`}
                            </Typography>
                            <Typography
                              sx={{ width: "50%" }}
                              className="fs12px"
                            >
                              {data.secondsLeft > 0 ? data.secondsLeft : 0}{" "}
                              Remaning
                            </Typography>
                          </Button>
                          <Grid container item>
                            <Typography className="fs14px">{`Price : ₹ ${data?.finalprice}/sec`}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
            <Typography className="greycolor505050400 fs18px mt10px  width100">
              Evening
            </Typography>
            <div className="grid_container" style={{ width: "100%" }}>
              {timeslot.map((data, index) => {
                // console.log("data from timeslot ==> ", timeslot);
                return (
                  <>
                    {data.time > 15 && data.time < 20 && data.ownedBy ? (
                      <div>
                        <Grid container direction="column" gap="10px">
                          <Button
                            className={
                              editTimeSlot2 === data.time
                                ? "mt20px selectedbg"
                                : data.secondsLeft <= 3600 &&
                                  data.secondsLeft > 1800
                                ? " mt20px  timeslot-green-btn"
                                : data.secondsLeft <= 1800 &&
                                  data.secondsLeft > 0
                                ? " mt20px timelslot-orange-btn"
                                : data.secondsLeft == 0
                                ? "mt20px timelslot-red-btn"
                                : "mt20px timelslot-red-btn"
                            }
                            onClick={() => {
                              setEditTimeSlot2(data.time);
                              setSelectTimeDetail(data);
                            }}
                            sx={
                              new Date().toISOString().slice(0, 10) == date &&
                              data.time < currentTime && {
                                color: "#ffffff !important",
                                backgroundColor: "#ddd !important",
                                border: "1px solid #ddd !important",
                              }
                            }
                            disabled={
                              new Date().toISOString().slice(0, 10) == date &&
                              (data.secondsLeft == 0 || data.time < currentTime)
                                ? true
                                : false
                            }
                          >
                            <Typography
                              align="center"
                              className="fs14px timeslot-typo"
                            >
                              {`${data.time - 12}pm - ${data.time - 12 + 1}pm`}
                            </Typography>
                            <Typography
                              sx={{ width: "50%" }}
                              className="fs12px"
                            >
                              {data.secondsLeft > 0 ? data.secondsLeft : 0}{" "}
                              Remaning
                            </Typography>
                          </Button>
                          <Grid container item>
                            <Typography className="fs14px">{`Price : ₹ ${data?.finalprice}/sec`}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
            <Typography className="greycolor505050400 fs18px mt10px  width100">
              Night
            </Typography>

            <div className="grid_container" style={{ width: "100%" }}>
              {timeslot.map((data, index) => {
                // console.log("data from timeslot ==> ", timeslot);
                return (
                  <>
                    {data.time > 19 && data.ownedBy ? (
                      <div>
                        <Grid container direction="column" gap="10px">
                          <Button
                            className={
                              editTimeSlot2 === data.time
                                ? "mt20px selectedbg"
                                : data.secondsLeft <= 3600 &&
                                  data.secondsLeft > 1800
                                ? " mt20px  timeslot-green-btn"
                                : data.secondsLeft <= 1800 &&
                                  data.secondsLeft > 0
                                ? " mt20px timelslot-orange-btn"
                                : data.secondsLeft == 0
                                ? "mt20px timelslot-red-btn"
                                : "mt20px timelslot-red-btn"
                            }
                            onClick={() => {
                              setEditTimeSlot2(data.time);
                              setSelectTimeDetail(data);
                            }}
                            sx={
                              new Date().toISOString().slice(0, 10) == date &&
                              data.time < currentTime && {
                                color: "#ffffff !important",
                                backgroundColor: "#ddd !important",
                                border: "1px solid #ddd !important",
                              }
                            }
                            disabled={
                              new Date().toISOString().slice(0, 10) == date &&
                              (data.secondsLeft == 0 || data.time < currentTime)
                                ? true
                                : false
                            }
                          >
                            <Typography
                              align="center"
                              className="fs14px timeslot-typo"
                            >
                              {`${data.time - 12}pm - ${data.time - 12 + 1}pm`}
                            </Typography>
                            <Typography
                              sx={{ width: "50%" }}
                              className="fs12px"
                            >
                              {data.secondsLeft > 0 ? data.secondsLeft : 0}{" "}
                              Remaning
                            </Typography>
                          </Button>
                          <Grid container item>
                            <Typography className="fs14px">{`Price : ₹ ${data?.finalprice}/sec`}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          {duration * editRepeatSec > selectTimeDetail.secondsLeft ? (
            <Typography className="redcolortypo400">
              Insufficient seconds remaning
            </Typography>
          ) : (
            <Typography className="redcolortypo400"></Typography>
          )}

          <Button
            className={
              duration * editRepeatSec > selectTimeDetail.secondsLeft
                ? "disabledbtn"
                : "purplebtn-no-radious"
            }
            sx={{ width: "30%", borderRadius: "8px" }}
            disabled={
              duration * editRepeatSec > selectTimeDetail.secondsLeft
                ? true
                : false
            }
            onClick={() => closeHandler()}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
