import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axiosInstance from "../../../../api/axiosInstance";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function ConfirMationDialog({
  name,
  id,
  val,
  permanentlyDeleteBillboard,
  restoreBillboard,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {name === "Restore" ? (
        <Button
          onClick={handleClickOpen}
          sx={{ width: "100%" }}
          className="chipsuccescolor"
        >
          Restore
        </Button>
      ) : (
        <Button
          onClick={handleClickOpen}
          sx={{ width: "100%" }}
          className="cancel-secondary"
        >
          Delete{" "}
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 1.2,
            background: name === "Restore" ? "rgba(95, 202, 93, 1)" : "red",
          }}
        >
          <Typography className="whitecolortypo">
            {name === "Restore" ? "Restore" : "Delete"}
          </Typography>{" "}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 2,
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "20px",
          }}
        >
          {val === 0 && (
            <Typography className="greycolor505050500">
              {name === "Restore"
                ? ` Are you sure you want to Restore this Billboard ?`
                : ` Are you sure you want to permanently Delete this Billboard ?`}
            </Typography>
          )}
          {val === 2 && (
            <Typography className="greycolor505050500">
              {name === "Restore"
                ? ` Are you sure you want to Restore this Device ?`
                : ` Are you sure you want to permanently Delete this Device ?`}
            </Typography>
          )}
          {val === 1 && (
            <Typography className="greycolor505050500">
              {name === "Restore"
                ? ` Are you sure you want to Restore this User ?`
                : ` Are you sure you want to permanently Delete this User ?`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="diallog-no-btn">
            No
          </Button>
          <Button
            onClick={() => {
              if (name === "Restore") {
                restoreBillboard(id, handleClose);
              } else {
                permanentlyDeleteBillboard(id, handleClose);
              }
            }}
            className={
              name === "Restore" ? "chipsuccescolor" : "reject-btn width-25"
            }
            style={{ padding: "4px 50px" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
