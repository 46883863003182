import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
} from "@mui/material";
import no_billboard_found from "../../../../../assets/Img/no_billboard_found.gif";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
}));
const SelectedBillboard = ({
  filterdata,
  storeBillBoardId,
  storeDeviceId,
  storeMacId,
  storeScreenData,
  data,
}) => {
  return (
    <div style={{ padding: "20px" }}>
      <Grid container>
        <Grid container spacing={3}>
          {filterdata && filterdata?.length > 0 ? (
            <>
              {" "}
              {filterdata?.map((item, i) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ paddingBottom: "40px" }}
                >
                  <Item>
                    <FormControlLabel
                      className="billboard-screencheckbox width100"
                      control={
                        <Checkbox
                          className="purple-icon"
                          checked={
                            data?.billBoardArr.includes(item?._id)
                              ? true
                              : false
                          }
                        />
                      }
                      onChange={(e) => {
                        storeBillBoardId(item?._id, "singleSelect");
                        storeDeviceId(item?.deviceId?._id, "singleSelect");
                        storeMacId(item?.deviceId?.macId, "singleSelect");
                        storeScreenData(e, item, i);
                      }}
                      //value={}
                      label={
                        <React.Fragment>
                          <div className="width100">
                            <Card className="billboardImage-card  mt10px ">
                              <img
                                src={item?.thumbnail}
                                alt="Kidsk"
                                className="width100"
                              />
                            </Card>
                            <CardContent className="billboard-cardcontetnt width100">
                              <Typography
                                className="blackcolortypo500 fs16px width100"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {item?.billboardName}
                              </Typography>
                              <Grid container justifyContent="space-between">
                                <Typography className="greycolor505050500 14px ">
                                  {item?.district}
                                </Typography>
                                <Typography className="greycolor505050500 14px ">
                                  ₹ {item?.basePrice}
                                </Typography>
                              </Grid>
                            </CardContent>
                          </div>
                        </React.Fragment>
                      }
                    />
                  </Item>
                </Grid>
              ))}{" "}
            </>
          ) : (
            <>
              <Grid container justifyContent="center" alignItems="center">
                <img
                  src={no_billboard_found}
                  alt="no_billboard_found"
                  style={{ width: "500px" }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectedBillboard;
