import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  Modal,
  Alert,
  Button,
  Snackbar,
  Typography,
  CardActions,
} from "@mui/material";
import DeleteDilogue from "./DeleteDialogue";
import axiosInstance from "../../../../../../api/axiosInstance";
import screenImg from "../../../../../../assets/Img/screenshot_747448.png";
import permissionUtils from "../../../../../../component/permissionUtils";
import DeleteDilogueAll from "./DeleteAllScreenShot";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  display: "flex",
  justifyContent: "center",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const Screenshot = ({ billBoardData }) => {
  const macId = billBoardData?.deviceId?.macId;
  const [loading, setLoading] = useState(false);
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [snackerropen, setSnackerropen] = useState(false);
  const [severity, setSeverity] = useState("info");
  // screenShot

  const [ScreenShot, setScreenShot] = useState([]);
  const [type, setType] = useState("screenshot");
  const [selectedImage, setSelectedImage] = useState(null);

  // Imgfull width popup
  const [open, setOpen] = React.useState(false);
  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };
  const getScreenShotData = async () => {
    try {
      const res = await axiosInstance.get(
        `api/device/fetchDeviceSnapOrScreenShot?macId=${macId}&type=${type}&page=1&limit=100`
      );
      console.log(res, "res");
      if (res.status == 200 || res.status == 201) {
        console.log("res for screenShot api >>>>>>>>>>>", res);
        setScreenShot(res?.data.data);
      } else {
        console.log("error getting burnerVdo res", res.msg);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (billBoardData?.deviceId?.macId) {
      getScreenShotData();
    }
  }, [billBoardData]);

  const takeScreenShotEvent = async (macID) => {
    let body = { macId: macId };
    try {
      setLoading(true);

      const res = await axiosInstance.post(
        `api/billboard/takePmaAppScreenShot`,
        body
      );

      if (res.status == 200) {
        setSnackMsg(res?.data?.msg);
        setLoading(false);
        handleShowSnackbar("success");
      } else {
        console.log("error in handle off ==> ", res.msg);
        setSnackerropen(true);
        // setOpenDialog("reject");
        setSnackErrMsg(res.msg);
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (err) {
      setLoading(false);
      handleShowSnackbar("error");
    }
  };
  // delete api
  const deleteScreenShot = async (_id) => {
    setLoading(true);
    try {
      const res = await axiosInstance.delete(
        `api/device/deleteDeviceSnapOrScreenShot/${_id}`
      );
      if (res.status == 200 || res.status == 201) {
        setSnackOpen(true);
        setSnackMsg("ScreenShot Has been deleted");
        setLoading(false);
        getScreenShotData();
        handleShowSnackbar("success");
      } else {
        setSnackErrMsg(res.msg);
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (error) {
      console.log(error, "error");
      handleShowSnackbar("error");
      setLoading(false);
    }
  };
  // delete api
  const deleteScreenShotAll = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.delete(
        `api/device/deleteAllDeviceSnapOrScreenShot?action=${type}&macId=${macId}`
      );
      if (res.status == 200 || res.status == 201) {
        setSnackOpen(true);
        setSnackMsg("ScreenShot Has been deleted");
        setLoading(false);
        getScreenShotData();
        handleShowSnackbar("success");
      } else {
        setSnackErrMsg(res.msg);
        setLoading(false);
        handleShowSnackbar("error");
      }
    } catch (error) {
      console.log(error, "error");
      handleShowSnackbar("error");
      setLoading(false);
    }
  };

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={severity}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={severity}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <div>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography className="blackcolor484848 fs24px">
            Showing {ScreenShot.length} ScreenShot{" "}
          </Typography>
          {ScreenShot?.length > 0 && (
            <DeleteDilogueAll deleteScreenShotAll={deleteScreenShotAll} />
          )}
        </Grid>
        <Grid container mt={3} gap={3} className="relative">
          <Grid item xs={12} sm={5.8} md={2.8}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              xs={12}
              style={{
                height: "250px",
                position: "relative",
                border: "1px solid #ddd",
                background: "#F7F8FD",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <>
                <input
                  style={{ display: "none" }}
                  type="text"
                  id="raised-button-file"
                />
                <img
                  src={screenImg}
                  alt="green iguana"
                  style={{
                    maxHeight: "120px",
                    maxWidth: "100%",
                    borderRadius: "8px",
                  }}
                />
              </>
            </Grid>{" "}
            {PermissionCheck("take-billboard-screenshot") && (
              <Button
                className={"purple_btn_primary--burnerad fs18px"}
                onClick={() => takeScreenShotEvent(macId)}
              >
                Take Screen Shot
              </Button>
            )}
          </Grid>
          {ScreenShot.map((item, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={5.8}
                md={2.8}
                key={index}
                className="cursorpoint relative"
              >
                <Grid
                  item
                  className="box-shadow "
                  style={{
                    borderRadius: "8px",
                    height: "300px",
                    border: "1px solid #ddd",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      height: "230px",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={item?.reference}
                      alt="green iguana"
                      className="curserZoom"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        borderRadius: "8px",
                      }}
                      onClick={() => handleOpen(item?.reference)}
                    />
                  </div>

                  <CardActions className="campaign-billboardaction">
                    <Grid
                      container
                      justifyContent="space-between"
                      direction="row"
                    >
                      <Grid item>
                        <Typography
                          className="greycolor1c1c1c  fs18px"
                          sx={{ textTransform: "capitalize" }}
                        >
                          Date
                        </Typography>
                        <Typography
                          className="greycolor1c1c1c  fs18px"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {new Date(item.createdAt).toLocaleString()}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <DeleteDilogue
                          deleteScreenShot={deleteScreenShot}
                          data={item}
                        />
                      </Grid>
                    </Grid>{" "}
                  </CardActions>
                </Grid>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    {" "}
                    <img
                      src={selectedImage}
                      alt="green iguana"
                      style={{
                        width: "80%",
                        height: "auto",
                        maxWidth: "80%",
                      }}
                    />
                  </Box>
                </Modal>
              </Grid>
            );
          })}
        </Grid>
      </div>
      {/* <LoaderDialogue loading={loading} /> */}
    </>
  );
};
export default Screenshot;
