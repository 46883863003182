import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Grid,
  Typography,
  InputBase,
  Button,
  MenuItem,
  FormControl,
  TextField,
  Autocomplete,
  DialogActions,
  DialogContent,
  IconButton,
  DialogTitle,
  Dialog,
} from "@mui/material";
import { MdFilterListAlt } from "react-icons/md";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
//core Component
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import orderIcon from "../../../../assets/Img/orderCampaignBanner.png";
import { SiPointy } from "react-icons/si";
import { DateRange } from "react-date-range";

import format from "date-fns/format";
import { FETCH_URL } from "../../../../fetchIp";
//Icons
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
//======================================= OrdersTab =========================================//

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "#ffffff",
  "&:hover": {
    backgroundColor: "#ffffff",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0.7, 0.7, 0.7, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      "&:focus": {
        width: "90%",
      },
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function Order(props) {
  const theme = useTheme();
  const navigate = useNavigate();

  // const [controller, dispatch] = useSelectBillboardController();
  // const { date, duration, time, itemDetail } = controller;
  const [range, setRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const refOne = useRef(null);
  const [open, setOpen] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState([null, null]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [sortByTime, setSortByTime] = useState([
    // { key: "All", value: 7 },
    { key: "12 - 1 AM", value: 0 },
    { key: "1 - 2 AM", value: 1 },
    { key: "2 - 3 AM", value: 2 },
    { key: "3 - 4 AM", value: 3 },
    { key: "4 - 5 AM", value: 4 },
    { key: "5 - 6 AM", value: 5 },
    { key: "6 - 7 AM", value: 6 },
    { key: "7 - 8 AM", value: 7 },
    { key: "8 - 9 AM", value: 8 },
    { key: "9 - 10 AM", value: 9 },
    { key: "10 - 11 AM", value: 10 },
    { key: "11 - 12 AM", value: 11 },
    { key: "12 - 1 PM", value: 12 },
    { key: "1 - 2 PM", value: 13 },
    { key: "2 - 3 PM", value: 14 },
    { key: "3 - 4 PM", value: 15 },
    { key: "4 - 5 PM", value: 16 },
    { key: "5 - 6 PM", value: 17 },
    { key: "6 - 7 PM", value: 18 },
    { key: "7 - 8 PM", value: 19 },
    { key: "8 - 9 PM", value: 20 },
    { key: "9 - 10 PM", value: 21 },
    { key: "10 - 11 PM", value: 22 },
    { key: "11 - 12 PM", value: 23 },
    { key: "12 - 00 PM", value: 24 },
  ]);
  const [orderselect, setOrderSelect] = useState("");
  const [selectedSortBy, setSelectedSortBy] = useState(0);

  const [sortBy, setSortBy] = useState([
    "All",
    "Pending",
    "Approve",
    "Rejected",
    "Live",
  ]);

  const [allOrder, setAllOrder] = useState([]);

  // dialog open
  const [filterOpen, setFilterOpen] = useState(false);

  const [value, setValue] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const buttonColor = (status) => {
    if (status === "approved") {
      return "order-green-btn";
    }
    if (status === "pending") {
      return "order-orange-btn";
    }
    if (status === "live") {
      return "order-white-btn";
    }
    if (status === "rejected") {
      return "red-colorbtn ";
    }
    if (status === "scheduled") {
      return "order-light-purple-btn";
    }
    if (status === "published") {
      return "order-light-purple-btn";
    }
  };

  const getAllOrder = async () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    try {
      let today;
      let startDate = range[0].startDate
        ? format(range[0].startDate, "yyyy/MM/dd")
        : null;
      let endDate = range[0].endDate
        ? format(range[0].endDate, "yyyy/MM/dd")
        : null;
      console.log(
        "order api",
        sortBy[selectedSortBy],
        selectedTime,
        startDate,
        endDate
      );
      console.log(sortBy[selectedSortBy], " sortBy[selectedSortBy],");
      const response = await fetch(
        `${FETCH_URL}/api/order/orderListbyuser?limit=${10}&startDate=${
          startDate ? startDate : "null"
        }&endDate=${endDate ? endDate : "null"}&page=${1}&status=${
          sortBy[selectedSortBy]
        }&time=[${selectedTime ? selectedTime : 10}}]`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = JSON.parse(response);
      console.log("res order", res);
      if (res.status == 200 || res.status == 201) {
        console.log("i am running");
        setAllOrder(res.data.msg);
      }
    } catch (err) {
      console.log("error in getAllOrder", err);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  useEffect(() => {
    getAllOrder();
  }, []);
  return (
    <>
      <Grid
        container
        md={12}
        sm={12}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Grid
          md={5}
          sm={5}
          xs={12}
          item
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        ></Grid>

        <Grid
          container
          md={7}
          sm={7}
          justifyContent="flex-end"
          className="fiter-heading-right"
        >
          <Button
            className=" family-color filter-btn"
            startIcon={<MdFilterListAlt />}
            onClick={() => setFilterOpen(true)}
          >
            Filters
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        className="pt-9px"
        spacing={3}
        sx={{
          marginTop: "1rem",
          justifyContent: "space-between",
          rowGap: "1.6rem",
          marginLeft: ".3rem",
        }}
        sm={12}
      >
        {allOrder.length > 0 ? (
          allOrder.map((item, index) => (
            <Grid
              container
              item
              md={5.7}
              sm={12}
              key={index}
              sx={{
                border: "1px solid #ddd",
                borderRadius: "10px",
              }}
            >
              <Grid
                container
                md={12}
                sm={12}
                className="box-shadow bg-white"
                sx={{
                  justifyContent: "space-between",

                  columnGap: "1rem !important",
                  rowGap: "1.4rem !important",
                }}
              >
                <Grid item md={3.5} sm={3.5}>
                  <Grid item className="relative">
                    <Grid item className="orderbtn-itemL">
                      <Button
                        className={`${buttonColor(
                          item.status.toLowerCase()
                        )}   mt--10px`}
                        sx={{ width: "135px" }}
                      >
                        {item.status}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <img src={orderIcon} alt="logo" width="135" height="105" />
                  </Grid>
                </Grid>
                <Grid item md={8} sm={8} className="p5px">
                  <Grid item className="relative">
                    <Grid item className="orderbtn-itemR">
                      <Button
                        className="order-purple-btn linkcolorwhite  width-60  min-width-100px "
                        onClick={() =>
                          navigate(`/order/${item._id}`, {
                            state: { detail: item },
                          })
                        }
                      >
                        View
                      </Button>
                    </Grid>
                  </Grid>
                  <Typography className="greycolor505050400 mt10px">
                    Order id #
                    {item._id.length > 6
                      ? `....... ${item._id.slice(
                          item._id.length - 6,
                          item._id.length
                        )}`
                      : item._id}
                  </Typography>
                  <Typography className="blackcolortypo mt5px fs16px">
                    {item.adTitle}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="mt10px"
                  >
                    <Grid item>
                      <Typography className="greycolor505050500">
                        {item.startscheduleDate}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="greycolor505050500">
                        {item.timeSlot}
                      </Typography>
                    </Grid>{" "}
                    <Grid item>
                      <Typography className="greycolor505050500">
                        Repeation:{" "}
                        {(item.imageDuration || item.videoDuration) / 30}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid container item sx={{ justifyContent: "center" }}>
            <Typography sx={{ fontSize: "20px" }} className="family-color">
              No Order's Found
            </Typography>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={filterOpen}
        keepMounted
        onClose={() => setFilterOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        className="order-filter-modal"
      >
        <DialogTitle sx={{ padding: "0" }}>
          <BootstrapDialogTitle
            className="family-color "
            onClose={() => setFilterOpen(false)}
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "700",
              color: "black",
              fontSize: "16px",
              padding: "0px !important ",
            }}
          >
            Filter
          </BootstrapDialogTitle>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "0px !important",
              top: "0px !important",

              color: "black !important",
            }}
            className="time-range-pop-up"
          ></IconButton>
        </DialogTitle>
        <hr />
        <DialogContent>
          <Grid
            sx={{
              width: "100%",
            }}
            className="filter-parent-container"
          >
            <Grid
              container
              item
              md={10}
              sm={12}
              xs={11}
              sx={{
                display: "flex",
                alignItems: "center",

                rowGap: "1rem",
                columnGap: "1rem",
              }}
            >
              <Grid
                container
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid item md={12} sm={12}>
                  <Typography className="family-color">Date Range :</Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  sx={{
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <input
                    value={
                      range[0].startDate
                        ? `${format(
                            range[0].startDate,
                            "MM/dd/yyyy"
                          )} to ${format(range[0].endDate, "MM/dd/yyyy")}`
                        : null
                    }
                    readOnly
                    className="inputBox-dateRange"
                    onClick={() => setOpen((open) => !open)}
                    placeholder="Select Start & End Date"
                  />

                  <div ref={refOne}>
                    {open && (
                      <DateRange
                        onChange={(item) => setRange([item.selection])}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        months={1}
                        direction="horizontal"
                        className="calendarElement"
                      />
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                }}
              >
                <Grid item xs={12} md={12} sm={12}>
                  <Typography className="family-color">Time Slot :</Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Autocomplete
                    className="MainPageFormControl orderrangepicker"
                    sx={{
                      border: "1px solid none",
                      outline: "none",
                    }}
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    disableCloseOnSelect
                    disableUnderline
                    disableClearable={true}
                    options={sortByTime}
                    getOptionLabel={(option) => option.key}
                    defaultValue={[]}
                    filterSelectedOptions
                    onChange={(event, value) => {
                      let temp = value.map((item) => item.value);

                      setSelectedTime(temp);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} sx={{ color: "000" }}>
                        <SiPointy
                          style={{ marginRight: 8 }}
                          disableUnderline
                          className="iconcolor"
                        />
                        {option.key}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        className="lightgreycolortypo fs10px"
                        sx={{ color: "white" }}
                        {...params}
                        disableUnderline
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography className=" family-color ">Sort By:</Typography>

                <FormControl
                  sx={{
                    border: "1px solid #dddddd",
                    height: "40px",
                    borderRadius: "2px",
                    backgroundColor: "white !important",
                  }}
                  size="small"
                >
                  <TextField
                    select
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    className="Selectdropstyle-noborder"
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={selectedSortBy || 0}
                    defaultValue={0}
                    onChange={(e) => {
                      setSelectedSortBy(e.target.value);
                    }}
                  >
                    {sortBy.map((status, index) => (
                      <MenuItem
                        value={index}
                        key={index}
                        className="Selectmenustyle"
                      >
                        <Typography
                          className="lightgreycolortypo fs10px"
                          sx={{ fontSize: "16px !important" }}
                        >
                          {status}
                        </Typography>
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "space-between",
          }}
        >
          <Button
            className="clear-all-btn family-color"
            onClick={() => {
              setRange([
                {
                  startDate: null,
                  endDate: null,
                  key: "selection",
                },
              ]);
              setSelectedTime(null);
              setSelectedSortBy(0);
              getAllOrder();
            }}
          >
            Clear all
          </Button>
          <Button
            className="filter-apply-btn family-color "
            onClick={() => {
              getAllOrder();
              setFilterOpen(false);
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
