import React, { useRef, useState } from "react";
import {
  Button,
  Step,
  Stepper,
  Box,
  StepLabel,
  Typography,
  Grid,
} from "@mui/material";
import moment from "moment";

import { Link, useNavigate } from "react-router-dom";
//React Icons
import { IoChevronBack } from "react-icons/io5";
//core components that
import Sidebar from "../../../component/Sidebar/Sidebar";
import BillBoard from "./BillBoardDetails-Step/billboard-Step";
import BillBoardTypo from "./Bill-BoardType/billboard-type";
import Venue from "./Venue/Venue";
import TimeSlots from "./TimeSlots/TimeSlots";
import Sumbit from "./Submit/Submit";
import axios from "../../../api/axiosInstance";
import { FETCH_URL } from "../../../fetchIp";
import SuccessDialog from "../../../utils/Dialog/SuceedFullDialog";
import WrongDialog from "../../../utils/Dialog/WrongDialog";
//--------------------------- custom hook -------------------------------------//
import useInputScrollRef from "../../../utils/useInputScrollRef";
import axiosInstance from "../../../api/axiosInstance";
//--------------------------- custom hook -------------------------------------//
const steps = [
  "Billboard Details",
  "Billboard Type",
  "Venue",
  "Time Slots",
  "Submit",
];
export default function Clusterhead() {
  // Scrolls to the first empty input field and stops the loop
  const { addButtonClicked, inputRefs } = useInputScrollRef();
  // Scrolls to the first empty input field and stops the loop
  const [activeStep, setActiveStep] = React.useState(0);
  const [openDialogName, setOpenDialog] = React.useState(null);
  //=========================== For Media Upload by Aman ===============================//
  const [billboardIdMongo, setBillboardIdMongo] = React.useState("");
  const [file, setFile] = React.useState({
    file1: null,
    file2: null,
    file3: null,
    file4: null,
    file5: null,
    file6: null,
  });
  const [thumbNail, setThumbNail] = useState(null);
  const [showImagefile, setShowImageFile] = React.useState({
    file1: null,
    file2: null,
    file3: null,
    file4: null,
    file5: null,
    file6: null,
  });
  // ================= STATES Added by Rahul ==================== //
  // validation form
  const [formErrors, setFormErrors] = React.useState({});
  const [fileErrors, setFileErrors] = React.useState({});
  // new changes
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [timeSlotData, setTimeSlotData] = useState(null);
  const [formdata, setFormdata] = React.useState({
    billboardName: "",
    billboardOwner: "",
    billboardSize: "1080X1920",
    fullAddress: "",
    deviceId: "",
    billboardId: "",
    landmark: "",
    pincode: "",
    city: "",
    district: "",
    state: "",
    country: "",
    aboutBillboard: "",
    lattitude: 28.6448,
    longitude: 77.216721,
    billboardType: "",
    locationType: "",
    venueType: [],
    venueTags: [],
    timeslotType: "",
    basePrice: null,
    guidelines: "",
    specification: "",
    supportedResolution: "",
    maxSalary: "",
    maxAge: "",
  });
  const [priceData, setPriceData] = React.useState({
    billboardId: "",
    deviceId: "",
    timeslotType: "",
    basePrice: null,
    totalViews: 0,
    avgViews: 0,
    type: "normal",
    timesArray: [],
  });
  const [loading, setLoading] = useState(false);
  // helper func
  const getTimeSlotDataHandler = (data) => {
    setTimeSlotData(data);
  };
  const getData = (data, dataType) => {
    if (dataType == "deviceName") {
      setDeviceName(data);
    }
  };

  const [billboardExit, setBillboardExit] = useState(true);
  console.log("status bill", billboardExit);
  const validate = (values) => {
    // checkBillboardId(values?.billboardId);

    const errors = {};
    let numeric = /^\d+$/;
    console.log(thumbNail, "thumbNailthumbNail");
    if (!thumbNail) {
      errors.thumbNail = "ThumbNail is required !";
    }
    if (!values.deviceId) {
      errors.deviceId = "Device Id are required !";
    }

    if (!values.guidelines) {
      errors.guidelines = "Guidelines are required !";
    }
    if (!values.maxAge) {
      errors.maxAge = "maxAge are required !";
    }
    if (!values.maxSalary) {
      errors.maxSalary = "maxSalary are required !";
    }
    if (!values.supportedResolution) {
      errors.supportedResolution = "Supported Content are required !";
    }
    if (!values.specification) {
      errors.specification = "Specification are required !";
    }
    if (!values.billboardName) {
      errors.billboardName = "Billboard Name is required !";
    }
    if (!values.billboardOwner) {
      errors.billboardOwner = "Billboard Owner is required !";
    }

    if (!values.billboardSize) {
      errors.billboardSize = "Billboard Size is required !";
    }

    if (!values.landmark) {
      errors.landmark = "Landmark type is required !";
    }
    if (String(values.pincode).length !== 6) {
      errors.pincode = "Pincode should have 6 digits !";
    }
    if (!values.pincode) {
      errors.pincode = "Pincode is required !";
    } else if (!numeric.test(values.pincode)) {
      errors.pincode = "Pincode must be a number !";
    }

    if (!values.fullAddress) {
      errors.fullAddress = "Full Address is required !";
    }

    if (!values.state) {
      errors.state = "State is required !";
    }

    if (!values.city) {
      errors.city = "City is required !";
    }

    if (!values.billboardId || values.billboardId?.length < 3) {
      errors.billboardId = "Minimum length of Billboard Id is atlest 3 !";
    }
    // if (!billboardExit) {
    //   errors.billboardId = "Already Exit";
    // }

    if (!values.lattitude) {
      errors.lattitude = "Mark position on Map !";
    }

    if (!values.country) {
      errors.country = "Country is required !";
    }

    if (!values.aboutBillboard) {
      errors.aboutBillboard = "About Billboard is required !";
    }
    if (activeStep == 1) {
      if (!values.billboardType) {
        errors.billboardType = "Billboard type is required !";
      }
    }
    if (activeStep == 2) {
      if (!values.venueType || values.venueTags.length == 0) {
        errors.venueType = "Venue type is required !";
      }
    }
    return errors;
  };
  const validateForPriceData = (values) => {
    const errors = {};
    let numeric = /^\d+$/;
    if (!values.timeslotType) {
      errors.timeslotType = "TimeslotType is required !";
    }
    if (!values.basePrice) {
      errors.basePrice = "BasePrice is required !";
    }
    if (values.timeslotType && values.basePrice) {
      if (!values.timesArray) {
        errors.timesArray = "Please press the apply button!";
      }

      if (
        values.timesArray &&
        values.timeslotType == "24 Hours" &&
        values.timesArray?.length !== 24
      ) {
        errors.timesArray = "Please press the apply button!";
      }
      if (
        values.timesArray &&
        values.timeslotType == "12 Hours" &&
        values.timesArray?.length !== 12
      ) {
        errors.timesArray = "Please press the apply button!";
      }
    }

    return errors;
  };
  const checkBillboardId = async (billboardId) => {
    try {
      let body = {
        billboardId: billboardId,
      };
      const response = await axiosInstance.post(
        "/api/billboard/checkBillId",
        body
      );
      console.log("res bill", response);
      if (response) {
        setBillboardExit(true);
      } else {
      }
    } catch (error) {
      console.log("res bill", error.response);
      setBillboardExit(false);
    }
  };

  const validateFile = (values) => {
    const filter = Object.values(values);
    const filterData = filter.filter((a) => a !== undefined && a !== null);

    const errors = {};
    if (
      (!values.file1 &&
        !values.file2 &&
        !values.file3 &&
        !values.file4 &&
        !values.file5 &&
        !values.file6) ||
      filterData.length < 3 ||
      filterData?.filter((a) => a.type == "image/jpeg")?.length == 0
    ) {
      errors.file = "At least three media (Atleast One Image) are required !";
      errors.id = "image_id";
    }
    return errors;
  };

  // ============== Function added by Rahul ============= //
  const handleChange = (e) => {
    if (e.target.name == "billboardId") {
      setFormdata({ ...formdata, [e.target.name]: e.target.value?.trim("") });
      return;
    }

    if (e.target.name === "pincode") {
      setFormdata({ ...formdata, [e.target.name]: e.target.value, city: null });
    } else {
      setFormdata({ ...formdata, [e.target.name]: e.target.value });
    }
  };

  const handleCountryStateChange = (
    countryValue,
    stateValue,
    districtValue
  ) => {
    // console.log("Country ==> ", value)
    setFormdata({
      ...formdata,
      country: countryValue,
      state: stateValue,
      district: districtValue,
    });
  };

  const handleDeviceId = (value) => {
    // console.log("Add Billboard DeviceID ===> ", value)
    setFormdata({ ...formdata, deviceId: value });
  };

  const handleFormsubmit = () => {
    setFileErrors(validateFile(file));
    setFormErrors(validate(formdata));

    if (activeStep === 0) {
      setIsSubmit(true);
    }
    if (activeStep === 3) {
      setFormErrors(validateForPriceData(priceData));
    }
  };

  const handleFileSubmit = (fileNumber, Originalfile) => {
    if (fileNumber === 1) {
      setFile({ ...file, file1: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file1: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }

    if (fileNumber === 2) {
      setFile({ ...file, file2: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file2: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }

    if (fileNumber === 3) {
      setFile({ ...file, file3: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file3: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }
    if (fileNumber === 4) {
      setFile({ ...file, file4: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file4: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }
    if (fileNumber === 5) {
      setFile({ ...file, file5: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file5: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }
    if (fileNumber === 6) {
      setFile({ ...file, file6: Originalfile });
      setShowImageFile({
        ...showImagefile,
        file6: Originalfile == null ? null : URL.createObjectURL(Originalfile),
      });
    }
  };
  const [uniqueThumbName, setUniqueThumbName] = useState("");
  const handleThumbnail = (file) => {
    setThumbNail(file);
    const uniqueName = file ? `thumbnail_${Date.now()}_${file.name}` : null;
    setUniqueThumbName(uniqueName);
  };

  const handleLatLong = (lattitudeValue, longitudeValue) => {
    setFormdata({
      ...formdata,
      lattitude: lattitudeValue,
      longitude: longitudeValue,
    });
  };

  const handleLocationType = (value) => {
    setFormdata({ ...formdata, locationType: value });
  };

  const handleBillBoardType = (value) => {
    // console.log("Billboard Type ===>", value)
    setFormdata({ ...formdata, billboardType: value });
  };

  const handleVenueType = (value) => {
    setFormdata({ ...formdata, venueType: value });
  };

  const handleTags = (value) => {
    setFormdata({ ...formdata, venueTags: value });
  };
  const handleNext = () => {
    if (activeStep === 0) {
      addButtonClicked();
      handleFormsubmit();
    }

    if (activeStep === 1) {
      handleFormsubmit();
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (activeStep === 2) {
      handleFormsubmit();
    }

    if (activeStep === 3) {
      handleFormsubmit();
    }
    if (activeStep == 4) {
      createBillboard();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  ////////////////////////////////////////////////////////////////////////////////////
  const handlepriceData = (name, value) => {
    setPriceData({ ...priceData, [name]: value });
    if (name === "timeslotType") {
      setFormdata({ ...formdata, timeslotType: value });
    }
    if (name === "basePrice") {
      setFormdata({ ...formdata, basePrice: value });
    }
  };

  // =============================== Api call ========================= //
  const [failedMsg, setFailedMsg] = useState("");
  const createBillboard = async () => {
    setOpenDialog(null);
    setLoading(true);
    try {
      let body = formdata;

      let res = await axios.post("/api/billboard/addscreen", body);
      console.log(res, "res me chjange");
      if (res.status === 201) {
        setBillboardIdMongo(res.data.msg._id);
        setPriceData({
          ...priceData,
          deviceId: res.data.msg.deviceId,
          billboardId: res.data.msg._id,
        });
        setTimeout(() => {
          imageUploadHandler(res.data.msg._id);
          thumbnailHandler(res.data.msg._id);
          CreateBillboardPrice(res.data.msg._id, res.data.msg.deviceId);
        }, 2000);
      } else {
        setFailedMsg(res?.response?.data?.msg);
        setOpenDialog("reject");
        setLoading(false);
      }
    } catch (error) {
      setOpenDialog("reject");
      setFailedMsg(error?.response?.data?.msg);
      console.log("ERROR FROM CreateBillboard ==> ", error.message);
    }
  };

  //============================ Image Upload Handler by Aman ==============================//
  const thumbnailHandler = async (id) => {
    console.log(uniqueThumbName, thumbNail, "check for error");
    try {
      const formData = new FormData();
      formData.append("uniqueFileName", uniqueThumbName);
      formData.append("billboardId", id);
      formData.append("file", thumbNail);
      const res = await axiosInstance.post(
        `/api/billboard/uploadThumnail`,
        formData
      );
      if (res.status == 200) {
        console.log("//====  Image Has Been Uploaded  ===//");
      } else {
        console.log("error in handle off during Image Upload ==> ", res.msg);
      }
    } catch (err) {
      console.log("error in handle off during Image Upload ==> ", err);
    }
  };
  const imageUploadHandler = async (id) => {
    const formdata = new FormData();
    formdata.append("billboardId", id);
    formdata.append("file", file.file1);
    formdata.append("file", file.file2);
    formdata.append("file", file.file3);
    formdata.append("file", file.file4);
    formdata.append("file", file.file5);
    formdata.append("file", file.file6);

    if (id) {
      const response = await fetch(
        `${FETCH_URL}/api/billboard/uploaddevicefile`,
        {
          method: "POST",
          body: formdata,
        }
      );
      let res = await response.json();
      if (response.ok) {
        console.log("//====  Image Has Been Uploaded  ===//");
      } else {
        console.log("error in handle off during Image Upload ==> ", res.msg);
      }
    }
  };

  //============================ Billboard Price Create ==============================//
  const currentDate = new Date();
  const CreateBillboardPrice = async (BillBoardID, deviceID) => {
    try {
      let body = {
        billboardId: BillBoardID,
        timeSlotType: priceData.timeslotType,
        basePrice: priceData.basePrice,
        timesArray: priceData.timesArray,
        date: moment(currentDate).format("YYYY-MM-DD"),
      };

      let res = await axios.post("/api/timeslot/createtime", body);

      if (res.status === 201) {
        setLoading(false);
        setOpenDialog("success");
      } else {
        setOpenDialog("reject");
      }
    } catch (error) {
      setOpenDialog("reject");
      setLoading(false);
      console.log("Error during creating Billboard Price => ", error.message);
    }
  };

  //===============================  ALL Form Elements here ================================//
  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <BillBoard
            inputRefs={inputRefs}
            formdata={formdata}
            handleChange={handleChange}
            handleCountryStateChange={handleCountryStateChange}
            formErrors={formErrors}
            fileErrors={fileErrors}
            file={showImagefile}
            checkFile={file}
            handleDeviceId={handleDeviceId}
            handleFileSubmit={handleFileSubmit}
            handleLatLong={handleLatLong}
            getData={getData}
            handleThumbnail={handleThumbnail}
            thumbNail={thumbNail}
          />
        );
      case 1:
        return (
          <BillBoardTypo
            formdata={formdata}
            formErrors={formErrors}
            handleLocationType={handleLocationType}
            handleBillBoardType={handleBillBoardType}
          />
        );
      case 2:
        return (
          <Venue
            formdata={formdata}
            handleVenueType={handleVenueType}
            handleTags={handleTags}
            formErrors={formErrors}
          />
        );
      case 3:
        return (
          <TimeSlots
            priceData={priceData}
            handlepriceData={handlepriceData}
            formErrors={formErrors}
            getTimeSlotDataHandler={getTimeSlotDataHandler}
            timeSlotData={timeSlotData}
          />
        );
      case 4:
        return (
          <Sumbit
            formdata={formdata}
            priceData={priceData}
            file={file}
            checkFile={file}
            loading={loading}
            deviceName={deviceName}
          />
        );
    }
  }
  // =================== useEffect to validate form data ====================== //
  React.useEffect(() => {
    if (
      Object.keys(formErrors).length === 0 &&
      isSubmit &&
      Object.keys(fileErrors).length === 0
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [formErrors]);
  return (
    <>
      <Box className="width100">
        <div className="flex-class">
          <div className="width-30">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="cursor pt14px">
                <Link to="/admin/home" className="link-style">
                  <IoChevronBack className="back-arrowcolor" />
                </Link>
              </Typography>
              <Link to="/admin/home" className="link-style">
                <Typography className="cursor pt7px greycolortypo6F6F6F fs16px">
                  Add Billboard
                </Typography>{" "}
              </Link>
            </Grid>
          </div>

          <div className="width-80">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className=" stepper "
            >
              {steps.map((data, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={data} {...stepProps}>
                    <StepLabel className="step" {...labelProps}>
                      {data}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>{" "}
          </div>
        </div>

        <div className="stepmain-div ">
          {/* ====================== Rendering of element ========================= */}
          {_renderStepContent(activeStep)}
          <React.Fragment>
            <Box className="stepmain-box ">
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                className="mt10px p-tb20-lr10"
              >
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 4 }}
                  className={activeStep ? "previousbtn " : null}
                >
                  Previous
                </Button>

                {activeStep == 4 ? null : (
                  <Button
                    onClick={handleNext}
                    // className="purplebtn"
                    // className={activeStep ? "purplebtn " : null}
                    className={
                      activeStep === steps.length - 1 ? null : "purplebtn"
                    }
                  >
                    {/* {activeStep === steps.length - 1 ? null : "Next"} */}
                    {activeStep == 4 ? null : "next"}
                  </Button>
                )}

                {activeStep == 4 && (
                  <Button
                    onClick={handleNext}
                    className={
                      activeStep === steps.length - 1 ? "purplebtn" : null
                    }
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </Box>
          </React.Fragment>
        </div>
        <SuccessDialog
          openData={openDialogName === "success"}
          data={"Billboard Added Successfully"}
        />
        <WrongDialog openData={openDialogName === "reject"} data={failedMsg} />
      </Box>
    </>
  );
}
