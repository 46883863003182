import React, { useEffect, useState } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

// ** Core Component

import TabList from "../../../component/CustomTabs/TabList";

import permissionUtils from "../../../component/permissionUtils";
import TabPanelList from "./../../../component/CustomTabs/TabPanel";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";
import Content from "./GalleryTabs/content";
import usePaginatedValue from "../../../utils/usePaginatedValue";

const Galleryindex = () => {
  const navigate = useNavigate();
  const { paginatedPage, paginateTabValue, getPaginatedValueHandler } =
    usePaginatedValue(navigate);
  const [currentPage, setCurrentPage] = useState(
    paginatedPage ? paginatedPage : 1
  );

  const theme = useTheme();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const queryParams = new URLSearchParams(location.search);
  const selectedTab = parseInt(queryParams.get("tab"), 10); // Convert to a number with base 10
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const [searchParams, setSearchParams] = useSearchParams();

  const [routeValue, setRouteValue] = useState(false);

  useEffect(() => {
    setValue(Number.isNaN(selectedTab) ? 0 : selectedTab);
  }, [selectedTab]);

  //  ** function
  const handleChange = (event, newValue) => {
    searchParams.set("tab", newValue);
    setSearchParams(searchParams);
    setValue(newValue);
    setCurrentPage(1);
    getPaginatedValueHandler("", 1, newValue);
  };
  const routeHandler = () => {
    setRouteValue(!routeValue);
  };
  useEffect(() => {
    if (paginateTabValue === undefined) {
      setValue(0);
      return;
    }
    setValue(paginateTabValue);
  }, [paginateTabValue]);

  const permissionList = [
    "Pending-content",
    "Approved-content",
    "Rejected-content",
  ];

  const getTabLabel = (permission) => {
    const labelMap = {
      "Pending-content": "Pending",
      "Approved-content": "Approved",
      "Rejected-content": "Rejected",
    };
    return labelMap[permission];
  };
  const permissions = CheckTabsPermissions(permissionList);

  const panels = [
    {
      permission: "Pending-content",
      component: (
        <Content
          paginatedPage={paginatedPage}
          paginateTabValue={paginateTabValue}
          getPaginatedValueHandler={getPaginatedValueHandler}
          tabValue={value}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          status={1}
        />
      ),
    },
    {
      permission: "Approved-content",
      component: (
        <Content
          paginatedPage={paginatedPage}
          paginateTabValue={paginateTabValue}
          getPaginatedValueHandler={getPaginatedValueHandler}
          tabValue={value}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          status={0}
        />
      ),
    },
    {
      permission: "Rejected-content",
      component: (
        <Content
          paginatedPage={paginatedPage}
          paginateTabValue={paginateTabValue}
          getPaginatedValueHandler={getPaginatedValueHandler}
          tabValue={value}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          status={-1}
        />
      ),
    },
  ];

  const breadcrumbs = [{ label: "Dashboard", link: "/" }, { label: "Gallery" }];
  return (
    <>
      <div className="width-90LR">
        <div className="flex-class">
          <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
        </div>
        <Box sx={{ borderBottom: 1, mb: 4, borderColor: "divider" }}>
          <TabList
            md={md}
            value={value}
            centered={false}
            classes={{ Tabs: "orderTab", Tab: "Order_TabChangeDevice" }}
            handleChange={handleChange}
            getTabLabel={getTabLabel}
            permissions={permissions}
          />
        </Box>
        <TabPanelList value={value} permissions={permissions} panels={panels} />{" "}
      </div>
    </>
  );
};

export default Galleryindex;
