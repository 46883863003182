import React, { useState } from "react";
import {
  Radio,
  Grid,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";
import SettingView from "./AdvanceSetting";
import axiosInstance from "../../../../../../api/axiosInstance";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";

export default function ControlledRadioButtonsGroup({
  id,
  billBoardData,
  getBillBoardData,
}) {
  const backendData = {
    setting: billBoardData?.screenSettingType,
    ...(billBoardData.screenSettingType === "advance"
      ? { advance: { ...billBoardData.screenSettings } }
      : { default: { ...billBoardData.screenSettings } }),
  };
  console.log("Check backendData", backendData);
  // screenSettings;
  // screenSettingType;
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(backendData?.setting);
  const [settings, setSettings] = useState(null);
  const [open, setOpen] = useState(false);
  const [dimensionUnit, setDimensionUnit] = useState("percentage");
  const [dimension, setDimension] = useState({ height: 80, width: 80 });
  const [resizeMode, setResizeMode] = useState("contain");

  const {
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      orientation: "portrait",
      dimension: {
        height: 80,
        width: 80,
      },
      position: "center",
      resizeMode: "contain",
    },
  });

  React.useEffect(() => {
    const initialSetting = backendData.setting || "default";
    setValue(initialSetting);
    setSettings(backendData[initialSetting]);
  }, []);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setValue(selectedValue);
    const fetchedSettings = backendData[selectedValue] || null;
    setSettings(fetchedSettings);
  };
  const validateDimension = (value, unit) => {
    const numValue = parseFloat(value);
    if (unit === "percentage" && (numValue < 0 || numValue > 100)) {
      return "Value must be between 0 and 100%";
    }
    return true;
  };

  const handleDimensionChange = (event) => {
    const newUnit = event.target.value;
    setDimensionUnit(newUnit);
  };

  const onSubmit = async (data) => {
    const finalData = {
      billboardId: id,
      screenSettingType: value,
      screenSettings: {
        orientation: data.orientation,
        dimension: {
          height: dimension.height,
          width: dimension.width,
        },
        position: data.position,
        resizeMode: data.resizeMode,
      },
    };

    try {
      const { data } = await axiosInstance.post(
        "https://api.postmyad.ai/api/billboard/screenSettings",
        finalData
      );
      if (data.success) {
        getBillBoardData();
        enqueueSnackbar(data?.msg || "Default settings applied successfully!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar(
        error.response?.data?.message ||
          "An error occurred while applying default settings.",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
    console.log("Final Data:", finalData);
    setOpen(false);
  };

  const handleDefaultApply = async () => {
    const defaultSettings = {
      orientation: "portrait",
      dimension: {
        height: "100%",
        width: "100%",
      },
      position: "center",
      resizeMode: "contain",
    };

    const body = {
      billboardId: id,
      screenSettingType: "default",
      screenSettings: defaultSettings,
    };

    try {
      const { data } = await axiosInstance.post(
        "https://api.postmyad.ai/api/billboard/screenSettings",
        body
      );
      if (data.success) {
        getBillBoardData();
        enqueueSnackbar(data.msg || "Default settings applied successfully!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar(
        error.response?.data?.msg ||
          "An error occurred while applying default settings.",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        sx={{ border: "2px solid #ddd", borderRadius: "10px" }}
        alignItems={"center"}
        p={1.4}
        justifyContent={"space-between"}
      >
        <Grid item xs={6}>
          <FormControlLabel
            value="default"
            control={
              <Radio checked={value === "default"} onChange={handleChange} />
            }
            label="Default Setting"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography align="end" variant="subtitle1">
            Height: 100%, Width: 100%, Position: centered, Resize Mode: Contain{" "}
            {value === "default" && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleDefaultApply}
              >
                Apply
              </Button>
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ border: "2px solid #ddd", borderRadius: "10px" }}
        alignItems={"center"}
        p={1.4}
        mt={2}
        justifyContent={"space-between"}
      >
        <Grid item xs={6}>
          <FormControlLabel
            value="advance"
            control={
              <Radio checked={value === "advance"} onChange={handleChange} />
            }
            label="Advance Setting"
          />
        </Grid>
        <Grid item xs={6} textAlign={"end"}>
          <SettingView
            open={open}
            value={value}
            watch={watch}
            errors={errors}
            control={control}
            trigger={trigger}
            setOpen={setOpen}
            settings={settings}
            onSubmit={onSubmit}
            dimension={dimension}
            resizeMode={resizeMode}
            Controller={Controller}
            setDimension={setDimension}
            setResizeMode={setResizeMode}
            handleSubmit={handleSubmit}
            dimensionUnit={dimensionUnit}
            validateDimension={validateDimension}
            handleDimensionChange={handleDimensionChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
