import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import axios from "../../api/axiosInstance";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  // validation form
  const [formErrors, setFormErrors] = useState("");
  const [setIsSubmit] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
    navigate("/auth/signin");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === "" || repassword === "") {
      setFormErrors("Fill all the required field");
    } else if (password !== repassword) {
      setFormErrors("Password is not matching");
    } else {
      setFormErrors("");
      setIsSubmit(true);
      console.log("new Password", password);
      const sendmail = async () => {
        const body = {
          password: password,
        };
        try {
          const resp = await axios.put(`/api/auth/resetpassword/${id}`, body);
          setSnackOpen(true);
          setSnackMsg(resp.data.msg);
          // setSnackMsg("Password reset successfully")
          setPassword("");
          setRepassword("");
        } catch (err) {
          // Handle Error Here
          console.error(err);
          // setSnackerropen(true);
          // setSnackErrMsg(err);
        }
      };
      sendmail();
    }
  };

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>

      <div
        style={{
          background: "#05254C",
          height: "100vh",
        }}
      >
        <div>
          <Grid container component="main" sx={{ padding: "4em" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.5)",
                background: "#ffffff",
              }}
            >
              <Typography align="center">
                <img
                  src="/img/logosmall.png"
                  alt="logo"
                  style={{ width: "10em", marginTop: "80px" }}
                />
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "4em",
                  marginBottom: "0px",
                  paddingLeft: "2em",
                  paddingRight: "3em",
                }}
              >
                <Typography sx={{ fontWeight: "700", fontSize: "32px" }}>
                  Create New Password
                </Typography>
              </Box>
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "0em 3em 0em 2em",
                  marginTop: "2em",
                }}
              >
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="password"
                    label="Enter New Password"
                    name="password"
                    variant="filled"
                    size="small"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    InputProps={{ disableUnderline: true }}
                    sx={{ background: "#F7F8FD", borderRadius: "6px" }}
                    InputLabelProps={{
                      style: { color: "#080605" },
                    }}
                  />
                  <TextField
                    margin="normal"
                    error
                    fullWidth
                    id="retypepassword"
                    label="Retype New Password"
                    name="retypepassword"
                    variant="filled"
                    size="small"
                    value={repassword}
                    onChange={(e) => {
                      setRepassword(e.target.value);
                    }}
                    InputProps={{ disableUnderline: true }}
                    sx={{ background: "#F7F8FD", borderRadius: "6px" }}
                    InputLabelProps={{
                      style: { color: "#080605" },
                    }}
                    helperText={formErrors}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 16,
                      backgroundColor: "#05254C",
                      fontWeight: "700",
                      fontSize: "18px",
                      "&:hover": {
                        backgroundColor: "#05254C",
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
