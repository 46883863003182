import { Grid, Typography } from "@mui/material";
import React from "react";
import PieAnalytics from "./PieAnalytics";
import BarAnalytics from "./BarAnalytics";
import BillboardAnalyticsTable from "./BillboardAnalyticsTable";
const SlotsAnalytics = (props) => {
  const billBoardID = props.id;
  return (
    <>
      <Grid container justifyContent="space-between" rowGap="20px">
        <Grid item xs={12} md={4.3} className=" card_div_new">
          <Grid container justifyContent="center">
            <PieAnalytics billBoardID={billBoardID} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={7.3} className=" card_div_new">
          <BarAnalytics billBoardID={billBoardID} />
        </Grid>
      </Grid>
      <div style={{ marginTop: "20px" }}>
        <BillboardAnalyticsTable billBoardID={billBoardID} />
      </div>
    </>
  );
};

export default SlotsAnalytics;
