import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

export default function ConfirMationDialog({
  name,
  id,
  handleCloseDialog,
  confirmationFun,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {name === "Approve" ? (
        <Button
          onClick={handleClickOpen}
          sx={{ width: "100%" }}
          className="chipsuccescolor"
        >
          Approve
        </Button>
      ) : (
        <Button
          onClick={handleClickOpen}
          sx={{ width: "100%" }}
          className="cancel-secondary"
        >
          Reject{" "}
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 1.2,
            background: name === "Approve" ? "rgba(95, 202, 93, 1)" : "red",
          }}
        >
          <Typography className="whitecolortypo">
            {name === "Approve" ? "Approve" : "Reject"}
          </Typography>{" "}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 2,
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "20px",
          }}
        >
          <Typography className="greycolor505050500">
            {name === "Approve"
              ? ` Are you sure you want to Approve this content ?`
              : ` Are you sure you want to Reject this content ?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="diallog-no-btn">
            No
          </Button>
          <Button
            onClick={() => {
              handleClose();
              confirmationFun(id, name);
              handleCloseDialog();
            }}
            className={
              name === "Approve" ? "chipsuccescolor" : "reject-btn width-25"
            }
            style={{ padding: "4px 50px" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
