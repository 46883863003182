import React, { useRef } from "react";

const useInputScrollRef = () => {
  // Scrolls to the first empty input field and stops the loop
  const inputRefs = useRef([]);
  const addButtonClicked = () => {
    for (let i = 0; i < inputRefs?.current?.length; i++) {
      if (inputRefs?.current?.[i]?.value === "") {
        inputRefs?.current?.[i]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        break;
      }
    }
  };
  // Scrolls to the first empty input field and stops the loop
  return { addButtonClicked, inputRefs };
};

export default useInputScrollRef;
