import React from "react";
import {
  Grid,
  Accordion,
  Checkbox,
  AccordionDetails,
  FormControlLabel,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionPanel = ({ id, edit, expanded, handleChange, data }) => {
  return (
    <Accordion
      key={id}
      expanded={expanded}
      onChange={handleChange}
      sx={{ border: "1px solid #ddd" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Typography className="greycolor505050500 fs16px">{id}</Typography>
      </AccordionSummary>
      <Grid container>
        {data?.map((item, index) => (
          <Grid item xs={6}>
            <AccordionDetails key={index}>
              <FormControlLabel
                control={<Checkbox disabled className="pl25px" checked />}
                className="radiostyle access-radio-formcontrolabel"
                label={
                  <Typography className="width100 ml10px">
                    <Typography>{item.name}</Typography>
                  </Typography>
                }
              />
            </AccordionDetails>
          </Grid>
        ))}{" "}
      </Grid>
    </Accordion>
  );
};

export default AccordionPanel;
