import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { BsInfoSquare } from "react-icons/bs";
import {
  DialogContent,
  IconButton,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  Snackbar,
  Alert,
  Grid,
} from "@mui/material";
import axiosInstance from "../../../../api/axiosInstance";
import moment from "moment";
import Calendar from "../../../../component/ReactDateRangePicker/ReactDateRangePicker";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitleConfirmation = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2 }} className="purple-gradient" {...other}>
      {children}
      <Typography className="whitecolortypo"> Report </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 3,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitleConfirmation.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ReportDialog({ deviceslength }) {
  const [date, setDate] = useState();
  const [analyticsDate, setAnalyticsDate] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");
  const [adminData, setAdminData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allIds, setAllIds] = useState([]);
  // SnackBar

  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState("");
  const [snackerropen, setSnackerropen] = useState(false);

  const [severity, setSeverity] = useState("info");

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleShowSnackbar = (severity) => {
    setSeverity(severity);
    setSnackOpen(true);
  };
  const getDataFromChildHandler = (data, dates2) => {
    setAnalyticsDate(dates2);
    setDate(data);
  };
  console.log("report", allIds);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const getuseraccordingType = async (query, sortBy, sortType) => {
    setAdminData([]);

    setLoading(true);
    try {
      let resp = await axiosInstance.get(
        `/api/user/getUserLists?userRole=${4}&sortBy=${
          sortBy ? sortBy : "createdAt"
        }&sortType=${
          sortType ? sortType : -1
        }&page=${1}&limit=${deviceslength}&searchQuery=${
          query ? query : null
        }&isDelete=false`
      );
      setLoading(false);
      setAdminData(resp?.data?.msg);
      setAllIds(
        resp?.data?.msg?.map((item) => {
          return item?._id;
        })
      );
    } catch (error) {
      setLoading(false);
      console.log("error from Error ", error);
    }
  };
  const sendReport = async () => {
    setLoading(true);
    try {
      let body = {
        startDate: moment(date?.[0]?.startDate).format("YYYY-MM-DD"),
        endDate: moment(date?.[0]?.endDate).format("YYYY-MM-DD"),
        userIds: allIds,
      };
      let res = await axiosInstance.post(`api/order/getUserReport`, body);

      if (res.status == 200 || res.status == 201) {
        setSnackOpen(true);
        setSnackMsg("Report send  successfully");
        setLoading(false);

        handleShowSnackbar("success");
      }
    } catch (err) {
      console.log("error from revenue anlytics data => ", err);
      handleShowSnackbar("error");
      setSnackMsg("Report send  failed");
      setLoading(false);
    }
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={severity}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={severity}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Button
        sx={{ padding: "8px 20px", marginLeft: "10px" }}
        className="purple_btn_primary text_transform_inherit"
        onClick={() => {
          handleClickOpen();
          getuseraccordingType();
        }}
      >
        Report
      </Button>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitleConfirmation
          onClose={handleClose}
        ></BootstrapDialogTitleConfirmation>
        <DialogContent>
          <Grid container sx={{ padding: "10px" }}>
            <Typography className="type-info fs13px">
              <BsInfoSquare />{" "}
              <span style={{ fontSize: "14px", marginLeft: "3px" }}>
                Send detailed analytics report to all the user's. Select Date
                range and click on “Send Report”.
              </span>
            </Typography>
          </Grid>

          <Grid container gap={1} sx={{ padding: "20px 20px" }}>
            <Calendar getDataFromChildHandler={getDataFromChildHandler} />
            <Button
              sx={{ padding: "8px 20px", marginLeft: "10px" }}
              className="purple_btn_primary text_transform_inherit"
              onClick={() => {
                sendReport();
              }}
            >
              Send Report
            </Button>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default ReportDialog;
