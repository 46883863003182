import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";

import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { AiOutlineDelete } from "react-icons/ai";

import { FETCH_URL } from "../../../fetchIp";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "red" }} {...other}>
      {children}
      <Typography className="whitecolortypo">Delete Apk </Typography>{" "}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 5,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DeleteDilogue({ data, ApkVersionList }) {
  const [open, setOpen] = React.useState(false);
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [uploading, setUploading] = useState(false); // Add the state variable

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const deleteApk = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    let token = userData.token;
    const urlObject = new URL(data.contentLink);
    let pathname = urlObject.pathname;
    const partToRemove = "/storage.saps.one/";
    pathname = pathname.replace(partToRemove, "");

    console.log(pathname);
    const apiUrl = `${FETCH_URL}/api/apk/deleteApk?key=${pathname}`;
    try {
      setUploading(true); // Set the state to true when starting the upload

      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //   return response.data;
      let res = response.data;
      if (response.status === 200) {
        setSnackOpen(true);
        setSnackMsg(res.msg);
        ApkVersionList();
        handleClose();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.msg.err);
        setUploading(false); // Set the state to false when the upload is completed
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error deleting APK:", error);
      throw error;
    }
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={1000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>

      <IconButton
        className="danger_color cursor_pointer"
        onClick={handleClickOpen}
      >
        <AiOutlineDelete />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle onClose={handleClose}> </BootstrapDialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "20px",
          }}
        >
          <Typography className="greycolor505050500">
            {" "}
            {` Are you sure you want to delete this Apk ?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="  diallog-no-btn">
            No
          </Button>
          <Button
            onClick={() => {
              deleteApk();
            }}
            className="reject-btn width-25 "
            style={{ padding: "4px 0px" }}
            onC
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
