import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import useAccessPermissionFilter from "../../../../../component/permissionUtils/customHook/useAccessPermissionFilter";
import permissionUtils from "../../../../../component/permissionUtils";
import AccordionPanel from "../../../../../component/AccordionCustom/UnCheckAccordian";

export default function Testing({ checkedItems, checkHandler }) {
  const [
    updatedRoutesPermit,
    PermissionCheck,
    CheckTabsPermissions,
    accessPermission,
  ] = permissionUtils();
  const [expanded, setExpanded] = useState(false);
  const AllFilteredPermission = useAccessPermissionFilter(accessPermission);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const shouldRemoveKeys = "BusinessOwner";

  if (shouldRemoveKeys && AllFilteredPermission) {
    const keysToRemove = [
      "Agency",
      "Coupons",
      "Finance",
      "UserManagement",
      "UserProfile",
    ];

    keysToRemove.forEach((key) => {
      if (key in AllFilteredPermission) {
        delete AllFilteredPermission[key];
      }
    });
  }

  return (
    <>
      <div>
        <div className="admin-head">
          <Typography className="whitecolortypo500 fs16px">
            Business Details
          </Typography>
        </div>

        <Grid container spacing={4} mt={0} style={{ marginBottom: "20px" }}>
          {AllFilteredPermission &&
            Object.values(AllFilteredPermission)?.map((data, index) => (
              <Grid item sm={12} mt={1} xs={12} md={6} lg={6} key={index}>
                <AccordionPanel
                  data={data}
                  edit={true}
                  id={Object.keys(AllFilteredPermission)[index]}
                  content="Additional content here"
                  expanded={expanded === index}
                  checkHandler={checkHandler}
                  checkedItems={checkedItems}
                  handleChange={handleChange(index)}
                />
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
}
