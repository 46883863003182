import React, { useState } from "react";

const useGetPincodeData = () => {
  const [checkForPinCode, setCheckForPinCode] = useState(false);
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("India");

  const searchByPostalCode = async () => {
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`
    );
    const res = await response.json();

    if (response.ok) {
      const Postaldata = res;
      if (res[0].PostOffice === null || res[0].PostOffice === undefined) {
        setCheckForPinCode(true);
        setCity("");
        setCountry("");
        setState("");
        return;
      }
      setCity(Postaldata?.map((a) => a?.PostOffice[0]?.District)[0]);
      setState(Postaldata?.map((a) => a?.PostOffice[0].State)[0]);
      setCountry("India");
      setCheckForPinCode(false);
    }
  };
  // const [dontRunFirst, setDontRunFirst] = useState(true);
  React.useEffect(() => {
    // if (dontRunFirst) {
    //   setDontRunFirst(false);
    // }
    searchByPostalCode();
  }, [pincode]);
  return {
    setPincode,
    setCountry,
    setState,
    checkForPinCode,
    pincode,
    city,
    state,
    country,
  };
};

export default useGetPincodeData;
