import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Typography,
  DialogContent,
  FormControlLabel,
  InputBase,
  ListItemButton,
  FormLabel,
  DialogActions,
  IconButton,
  Stack,
  Pagination,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
//React Icons
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../../../api/axiosInstance";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px",
  backgroundColor: "rgba(247, 248, 253, 1)",
  "&:hover": {
    backgroundColor: "rgba(247, 248, 253, 1)",
  },
  marginLeft: 0,
  width: "100%",
  fontWeight: "400",
  // [theme.breakpoints.up("lg")]: {
  //   marginLeft: theme.spacing(1),
  //   width: "auto",
  // },
  width: "36%",
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "93%",
      },
    },
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle className="dialogtitle" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogcrossicon"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function CustomizedDialogs({ handleDeviceId, getData }) {
  // const { deviceId, setDeviceId, devicename, setDevicename } = useBillboard();

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  // States
  const [originalData, setOriginalData] = React.useState(null);
  const [deviceData, setDeviceData] = React.useState([]);
  const [totalPage, setTotalPage] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const getDevices = async (value) => {
    try {
      let resp = await axios.get(
        `/api/device/getallDeviceforbillboard?page=${
          value || currentPage
        }&limit=10`
      );
      console.log("RESPONSE  getbillboardNodeviceId ===> ", resp);
      setTotalPage(Math.ceil(resp?.data?.length / 10));
      setDeviceData(resp?.data?.data);
    } catch (error) {
      console.log("ERROR from GET DEVICE => ", error.message);
    }
  };
  React.useEffect(() => {
    console.log("original Data ==> ", originalData);
    getDevices();
  }, []);
  // paginations
  const handleChangeByPage = (event, value) => {
    console.log("Page Number Clicked ==> ", value);
    setCurrentPage(value);
    getDevices(value);
  };
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const handleClick = () => {
    setSnackOpen(true);
  };
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //=================================================//
  const handleChange = (e, data) => {
    console.log("select device handle chanbge ==> ", e.target.value);
    setOriginalData(data.deviceUID);
    handleDeviceId(data._id);
  };

  //=============================================================================//
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Typography className="selectableInput " onClick={handleClickOpen}>
        {originalData ? originalData : "Select Device"}
      </Typography>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className="whitecolortypo500 fs16px">
            Assign Device
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ m: 0 }}>
          {deviceData ? (
            <div className="admin-content">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Search className="search-admin">
                  <SearchIconWrapper>
                    <SearchIcon className="searchicon" />
                  </SearchIconWrapper>
                  <StyledInputBase
                    className=""
                    placeholder="Search Device"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </Grid>
              <Grid container>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  sx={{ width: "100%" }}
                >
                  {deviceData?.map((data, i) => {
                    return (
                      <Grid
                        item
                        key={i}
                        xs={12}
                        sm={5.5}
                        md={2.7}
                        sx={{ border: "1px solid #dddddd", marginLeft: "20px" }}
                        className="access-radio-grid "
                      >
                        <Grid container justifyContent="space-between">
                          <FormLabel>
                            <ListItemButton>
                              <Typography className=" greycolor505050400">
                                {data.deviceName}
                                <Typography className="lightgreycolortypo">
                                  {data.deviceUID}{" "}
                                </Typography>
                              </Typography>
                            </ListItemButton>
                          </FormLabel>

                          <FormControlLabel
                            value={data.deviceUID}
                            className="radiostyle access-radio-formcontrolabel"
                            control={<Radio />}
                            style={{ justifyContent: "space-between" }}
                            onChange={(e) => {
                              handleChange(e, data);
                              getData(data.deviceName, "deviceName");
                            }}
                            key={data._id}
                          />
                        </Grid>
                      </Grid>
                    );
                    // ========================================================== //
                  })}
                </RadioGroup>
              </Grid>

              <Grid container justifyContent="center" alignItems="center">
                <DialogActions className="dialogaction-p0px mt20px">
                  <Stack spacing={2}>
                    <Pagination
                      page={currentPage}
                      count={totalPage}
                      onChange={handleChangeByPage}
                      size="large"
                    />
                  </Stack>
                </DialogActions>
              </Grid>
              <DialogActions>
                <Button className="dcancelbutton" onClick={handleClose}>
                  Cancel
                </Button>
                <Button className="purplebtn" onClick={handleClose}>
                  Save
                </Button>
              </DialogActions>
            </div>
          ) : (
            <Grid container sx={{ height: "70vh" }}>
              <Typography
                align="center"
                alignItems="center"
                alignSelf="center"
                className="width100 blackcolortypo fs25px"
              >
                No billboard found! <br />
                All billboard have device assigned to them.
              </Typography>
            </Grid>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
