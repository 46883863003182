import React from "react";
const use12HrTime = () => {
  function timeslot(num) {
    let startHour, endHour, period;
    switch (num) {
      case 0:
      case 24:
        startHour = 12;
        endHour = 1;
        period = "AM";
        break;
      case 11:
      case 23:
        startHour = 11;
        endHour = 12;
        period = num === 11 ? "AM" : "PM";
        break;
      case 12:
        startHour = 12;
        endHour = 1;
        period = "PM";
        break;
      default:
        if (num < 12) {
          startHour = num;
          endHour = num + 1;
          period = "AM";
        } else {
          startHour = num - 12;
          endHour = num - 11;
          period = "PM";
        }
    }

    return `${startHour}-${endHour} ${period}`;
  }
  return { timeslot };
};

export default use12HrTime;
