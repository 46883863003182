import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Typography,
  DialogContent,
  Snackbar,
  Grid,
  Alert as MuiAlert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import TextField from "../../../../../component/TextField";
import axiosInstance from "../../../../../api/axiosInstance";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
  <DialogTitle className="dialogtitle-cancel" {...other}>
    {children}
    {onClose && (
      <IconButton
        aria-label="close"
        onClick={onClose}
        className="dialogcrossicon"
      >
        <CloseIcon />
      </IconButton>
    )}
  </DialogTitle>
);

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  data,
  firstClose,
  fetchWithdrawalTrans,
}) {
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const onSubmit = async (item) => {
    try {
      let body = {
        ...item,
        id: data?._id,
        status: "rejected",
      };
      console.log("check body", body);
      const res = await axiosInstance.post(
        "api/payment/updateWithdrawStatus",
        body
      );
      if (res?.status === 200 || res?.status === 201) {
        showSnackbar(res.data.msg, "success");
        fetchWithdrawalTrans();
        firstClose();
        handleClose();
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.msg || "An unexpected error occurred";
      showSnackbar(errorMessage, "error");
    }
  };

  return (
    <div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Button
        size="medium"
        onClick={handleClickOpen}
        className="filterButtonDesign"
      >
        Reject
      </Button>
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className="whitecolortypo500">Reject Request</Typography>
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ m: 2 }}>
            <Typography className="redcolortypo   fs18px">
              {`Are you sure you want to reject withdrawal request of “INR ${data?.amount}?`}
            </Typography>
            <Grid container>
              <Typography className="blackcolor484848 fs18px" mt={2}>
                Rejection Reason:
              </Typography>
              <TextField
                {...register("reason", {
                  required: "reason is required!",
                })}
                label="Reason"
                variant="outlined"
                size="small"
                className="width100"
                sx={{ mt: 2 }}
                placeholder="Enter Reason"
                error={!!errors.reason}
                helperText={errors.reason?.message}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="medium"
              onClick={handleClose}
              className="filterButtonDesign"
            >
              Cancel
            </Button>{" "}
            <Button variant="medium" className="cancel-secondary" type="submit">
              Reject
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
