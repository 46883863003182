import * as React from "react";
import {
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1.2, background: "red" }} {...other}>
      {children}
      <Typography className="whitecolortypo">Approve order </Typography>{" "}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function MaxWidthDialog({
  controlOrderStatus,
  RejectedAPI,
  selectedOptions,
  setErrMsg,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");
  const handleClickOpen = () => {
    if (selectedOptions?.length == 0) {
      return;
    } else if (
      selectedOptions
        ?.filter((a) => a.data1 == "Other Reason")?.[0]
        ?.data2?.trim("")?.length == 0
    ) {
      setErrMsg("Please enter other reason");
      return;
    }

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const rejectOrder = () => {
    controlOrderStatus(true);
  };

  return (
    <React.Fragment>
      <Button
        className="reject-btn width-25 "
        style={{ padding: "3.5px 0px" }}
        onClick={handleClickOpen}
      >
        Reject
      </Button>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: "SmallDialog",
        }}
        BackdropComponent={styled(Backdrop, {
          name: "MuiModal",
          slot: "Backdrop",
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({ zIndex: -1, backdropFilter: "blur(2px)" })}
      >
        <BootstrapDialogTitle> </BootstrapDialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#fffff",
            marginTop: "10px",
          }}
        >
          <Typography className="greycolor505050500">
            Are you sure you want to reject this ad?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className="  diallog-no-btn"
            onClick={() => {
              handleClose();
            }}
          >
            No
          </Button>
          <Button
            className="reject-btn width-25 "
            style={{ padding: "4px 0px" }}
            onClick={() => {
              handleClose();
              RejectedAPI();
              rejectOrder();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
