import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import BillboardtableAnalstics from "./Camp_Analytics/BillboardtableAnalystics";
import axiosInstance from "../../../../../api/axiosInstance";
// pie chart
import { Doughnut, Pie } from "react-chartjs-2";
import moment from "moment";
// pie chart
const options = {
  title: {
    display: true,
    text: "Donut Chart",
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  cutout: "0%",
};
const optionsSecond = {
  title: {
    display: true,
    text: "Donut Chart",
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  // cutout: "0%",
};

const SlotsAnalytics = (props) => {
  const [value, setValue] = React.useState(null);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [maleView, setMaleView] = useState(null);
  const [Allviews, setAllviews] = useState(null);
  const [femaleView, setfemaleView] = useState(null);
  const [apiDataAnalystics, setapiDataAnalystics] = useState(null);
  const [ageGroup, setAgeGroup] = useState([]);
  const [dataRange, setDataRange] = useState([]);
  const [acceptedBarAnalyticsData, setAcceptedBarAnalyticsData] = useState([]);
  const handleData = (data, datatype) => {
    setDate(moment(data).format("YYYY-MM-DD"));
  };
  const getBillboardActiveHours = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/order/getViewsAgeGroupByOrderId/${props?.data?._id}`
      );
      setAcceptedBarAnalyticsData(res?.data.data);

      console.log("dataOccupency", res);
      if (res.status == 200 || res.status == 204) {
        setapiDataAnalystics(res?.data.data);
      }
    } catch (err) {
      console.log("error from getOrder anlytics data => ", err);
    }
  };
  console.log("Api data", apiDataAnalystics);
  useEffect(() => {
    getBillboardActiveHours();
  }, [props]);

  const data = {
    labels: dataRange,
    datasets: [
      {
        data: ageGroup,
        // backgroundColor: ["#36A2EB"],
        // hoverBackgroundColor: ["#36A2EB"],
        // borderWidth: 0,
        backgroundColor: [
          "rgba(144, 70, 210, 1)",
          "rgba(180, 131, 225, 1)",
          "rgba(217, 192, 240, 1)",
        ],
        borderColor: [
          "rgba(144, 70, 210, 1)",
          "rgba(180, 131, 225, 1)",
          "rgba(217, 192, 240, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const dataSecond = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: [maleView, femaleView],
        backgroundColor: ["#91D5EF", "#EF669E"],
        hoverBackgroundColor: ["#91D5EF", "#EF669E"],
        borderWidth: 1,
      },
    ],
  };
  const dataDemo2 = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: [34, 12],
        backgroundColor: ["#91D5EF", "#EF669E"],
        hoverBackgroundColor: ["#91D5EF", "#EF669E"],
        borderWidth: 1,
      },
    ],
  };
  const dataDemo = {
    labels: dataRange,
    datasets: [
      {
        data: [23, 4, 13],
        backgroundColor: ["#9046D2", "#B483E1", "#D9C0F0"],
        hoverBackgroundColor: ["#9046D2", "#B483E1", "#D9C0F0"],
        borderWidth: 0,
      },
    ],
  };
  useEffect(() => {
    const firstData = acceptedBarAnalyticsData?.map((a) => a.totalViews);
    const firstDataNew = firstData?.reduce((accum, elem) => {
      return (accum = accum + elem);
    }, 0);
    setAllviews(firstDataNew);
    const firstDataMale = acceptedBarAnalyticsData?.map((a) => a.maleViews);

    const firstDataNewMale = firstDataMale?.reduce((accum, elem) => {
      return (accum = accum + elem);
    }, 0);
    setMaleView(firstDataNewMale);
    const firstDatafemale = acceptedBarAnalyticsData?.map((a) => a.femaleViews);
    const firstDataNewfemale = firstDatafemale?.reduce((accum, elem) => {
      return (accum = accum + elem);
    }, 0);
    setfemaleView(firstDataNewfemale);
  }, [acceptedBarAnalyticsData]);

  const ageGroupData = (acceptedBarAnalyticsData) => {
    console.log(acceptedBarAnalyticsData);
    let data1 = [];
    let data2 = [];
    acceptedBarAnalyticsData?.map((item, i) => {
      data1.push(item?.totalViews);
      data2.push(item?.ageGroup);
    });
    // console.log(data1, data2);
    setAgeGroup(data1);
    setDataRange(data2);
  };
  useEffect(() => {
    if (acceptedBarAnalyticsData.length > 0) {
      ageGroupData(acceptedBarAnalyticsData);
    }
    // console.log(ageGroup);
  }, [acceptedBarAnalyticsData]);
  console.log("status", props?.data);

  return (
    <>
      {props?.data?.status === "Published" ||
      props?.data?.status === "Cancelled" ||
      props?.data?.status === "Live" ? (
        <>
          <Grid container justifyContent="space-between" rowGap="20px">
            <Grid container xs={12} md={5.8} className=" card_div_new">
              <Grid container justifyContent="center">
                <Grid container justifyContent="center">
                  <Typography className="gray5A5A5Atypo500 fs20px">
                    Age group Distribution
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    width: "300px",
                    marginTop: "50px",
                    marginBottom: "20px",
                  }}
                >
                  {ageGroup?.length > 0 ? (
                    <Pie data={data} />
                  ) : (
                    <div className="fakeDataChart"></div>
                  )}
                  {acceptedBarAnalyticsData?.map((item, i) => {
                    return (
                      <>
                        <Grid display="flex" justifyContent="space-evenly">
                          <Grid
                            className="analyticsDisk"
                            sx={{ margin: "10px" }}
                            item
                          >
                            {item?.ageGroup} : {item?.totalViews}
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>

            <Grid container className="relative card_div_new" xs={12} md={5.8}>
              <Grid container justifyContent="center">
                <Grid container justifyContent="center">
                  <Typography className="gray5A5A5Atypo500 fs20px">
                    Gender Distribution
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    width: "300px",
                    marginTop: "50px",
                    marginBottom: "20px",
                  }}
                >
                  {Allviews > 0 ? (
                    <Doughnut data={dataSecond} options={optionsSecond} />
                  ) : (
                    <div className="fakeDataChart"></div>
                  )}
                </Grid>
                <Grid className="text_inside_donut" item>
                  <Grid container alignItems="center" direction="column">
                    {" "}
                    <Typography className="greycolor505050500 fs24px">
                      Total view
                    </Typography>
                    <Typography className="blackcolortypo fs24px">
                      {Allviews ? Allviews : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container gap="20px">
                  <Grid container item justifyContent="center" gap={"80px"}>
                    <Grid item>
                      <Grid container>
                        {" "}
                        <span className="pink_blockAnalytics"></span>
                        <Typography className="greyColorTypo717171_400">
                          Male : {maleView}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        {" "}
                        <span className="blue_blockAnalytics"></span>
                        <Typography className="greyColorTypo717171_400">
                          Female {femaleView}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>
            <BillboardtableAnalstics data={props?.data} />
          </div>
        </>
      ) : (
        <div style={{ paddingBottom: "10px" }} className=" relative">
          <Grid
            container
            justifyContent="space-between"
            rowGap="24px"
            className={
              props?.data?.status !== "Published" ? "section_blur" : ""
            }
            sx={{
              paddingBottom: "10px",
              marginTop: "20px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              md={5.8}
              className="border_primary"
              gap="20px"
              sx={{ padding: "20px" }}
              justifyContent="center"
            >
              <Grid
                container
                item
                direction="column"
                gap="5px"
                alignItems="center"
              >
                <Typography className="blackcolortypo fs24px">
                  Audience Count
                </Typography>
                {/* <Typography className="greycolor505050500 fs20px">
                    Total number of people who viewed this Ad.
                  </Typography> */}
              </Grid>
              <Grid container item justifyContent="center">
                <Doughnut data={dataDemo} options={options} />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={5.8}
              className="border_primary"
              gap="20px"
              sx={{ padding: "20px" }}
              justifyContent="center"
            >
              <Grid
                container
                item
                direction="column"
                gap="5px"
                alignItems="center"
              >
                <Typography className="blackcolortypo fs24px">
                  Gender Distribution
                </Typography>
              </Grid>
              <Grid container item justifyContent="center">
                <Doughnut data={dataDemo2} options={optionsSecond} />
              </Grid>
            </Grid>{" "}
          </Grid>
          {/* <Grid container item className="border_primary">
                <Donut_chart_three viewData={props?.data} />
              </Grid>
            </Grid> */}
          {props?.data?.status !== "Published" ||
            props?.data?.status !== "Cancelled" ||
            (props?.data?.status !== "Rejected" && (
              <Grid
                container
                className="analytics_blur_div"
                justifyContent="center"
              >
                <Grid
                  container
                  item
                  sx={{
                    borderRadius: "15px",
                    background: "#FFFFFF",
                    border: "1px solid #ddd",
                    height: "150px",
                    padding: "30px 0",
                    width: "450px",
                  }}
                  justifyContent="center"
                  alignItems="space-between"
                >
                  <Typography
                    sx={{ textAlign: "center" }}
                    className="purplecolortypo-500 fs20px"
                  >
                    No analytics yet!
                  </Typography>
                  <Typography
                    sx={{ textAlign: "center" }}
                    className="purplecolortypo-500 fs20px"
                  >
                    Analytics will be available after ad is published.
                  </Typography>
                </Grid>
              </Grid>
            ))}
        </div>
      )}
    </>
  );
};

export default SlotsAnalytics;
