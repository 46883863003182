import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
//core component that
import Overview from "./ProfileTab/Overview";
import Assigned from "./ProfileTab/Assigned";
import OrderLogs from "./ProfileTab/OrderLogs";
import axiosInstance from "../../../../api/axiosInstance";
import TabList from "../../../../component/CustomTabs/TabList";
import permissionUtils from "../../../../component/permissionUtils";
import CustomBreadcrumbs from "../../../../component/CustomBreadcrumbs";
import TabPanelList from "../../../../component/CustomTabs/TabPanel";

export default function Testing() {
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();

  const { state } = useLocation();
  const theme = useTheme();

  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [value, setValue] = React.useState(0);
  const deviceData = state.deviceData;
  const deviceID = deviceData?._id;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [deviceinfo, setDeviceInfo] = useState(null);

  const getDeviceInfo = async () => {
    try {
      let resp = await axiosInstance.get(
        `/api/device/getDeviceInfo/${deviceID}`
      );
      if (resp.data.msg) {
        setDeviceInfo(resp.data.msg);
      }
      console.log("GetDeviceInfo () response ==> ", resp.data.msg);
    } catch (error) {
      console.log("Error from GetdeviceInfo ==> ", error);
    }
  };

  React.useEffect(() => {
    getDeviceInfo();
  }, [deviceID]);

  const breadcrumbs = [
    { label: "Dashboard", link: "/" },
    { label: "Devices", link: "/admin/device" },
    { label: state?.deviceData?.deviceName },
  ];

  const permissionList = ["overview", "assigned", "view-device-logs"];
  const getTabLabel = (permission) => {
    const labelMap = {
      overview: "Overview",
      assigned: "Assigned",
      "view-device-logs": "Logs",
    };
    return labelMap[permission];
  };

  const permissions = CheckTabsPermissions(permissionList);

  const panels = [
    {
      permission: "overview",
      component: (
        <Overview deviceData={state.deviceData} getDeviceInfo={getDeviceInfo} />
      ),
    },
    {
      permission: "assigned",
      component: (
        <Assigned
          value={value}
          deviceData={deviceinfo}
          getDeviceInfo={getDeviceInfo}
        />
      ),
    },
    {
      permission: "view-device-logs",
      component: (
        <OrderLogs
          value={value}
          deviceData={deviceinfo}
          getDeviceInfo={getDeviceInfo}
        />
      ),
    },
  ];
  return (
    <>
      <div className="width-90LR ">
        <div className="flex-class">
          <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
        </div>
        <Grid container className="device-container mt20px">
          <Grid item xs={12}>
            <TabList
              md={md}
              value={value}
              centered={true}
              classes={{ Tabs: "TabStyleAddDevice", Tab: "TabChangesDevice" }}
              handleChange={handleChange}
              getTabLabel={getTabLabel}
              permissions={permissions}
            />
          </Grid>
        </Grid>
        <TabPanelList value={value} permissions={permissions} panels={panels} />{" "}
      </div>
    </>
  );
}
