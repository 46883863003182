import React, { useEffect, useState } from "react";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import { useSearchParams, useLocation } from "react-router-dom";

// ** Core Component
import axiosInstance from "../../../api/axiosInstance";
import Revenue from "./FinanceTableTab/Revenue/Revenue";
import TabList from "../../../component/CustomTabs/TabList";
import FinanceMarginCut from "./FinanceTableTab/Margin_Cut";
import FinanceWallet from "./FinanceTableTab/Wallet/Wallet";
import FinanceAnalytics from "./FinanceTableTab/Analytics";
import FinanceTransaction from "./FinanceTableTab/Transaction";
import permissionUtils from "../../../component/permissionUtils";
import TabPanelList from "./../../../component/CustomTabs/TabPanel";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";

const Finance = () => {
  const theme = useTheme();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const queryParams = new URLSearchParams(location.search);
  const selectedTab = parseInt(queryParams.get("tab"), 10); // Convert to a number with base 10
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const [revenueData, setRevenueData] = useState([]);
  const [routeValue, setRouteValue] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [wallletDetails, setWalletDetails] = useState([]);
  const [userId, setUserId] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    setValue(Number.isNaN(selectedTab) ? 0 : selectedTab);
  }, [selectedTab]);
  useEffect(() => {
    const fetchUserId = () => {
      const storedData = localStorage.getItem("userData");
      if (storedData) {
        const userData = JSON.parse(storedData);
        setUserId(userData?.user?._id || "");
        setRole(userData?.user?.role);
      }
    };
    fetchUserId();
  }, []);

  //  ** function
  const handleChange = (event, newValue) => {
    searchParams.set("tab", newValue);
    setSearchParams(searchParams);
    setValue(newValue);
  };
  const routeHandler = () => {
    setRouteValue(!routeValue);
  };

  // ** Api calling
  const GetRevenueDetails = async () => {
    try {
      let res = await axiosInstance.get("/api/revenue/revenueDetail");
      if (res?.status == 200 || res?.status == 201) {
        setRevenueData(res?.data);
      }
    } catch (error) {
      console.log("error from revenueDetail ", error.message);
    }
  };
  const CheckWalletandAddMoney = async () => {
    try {
      const res = await axiosInstance.post(`/api/payment/getWalletData`, {
        userId: userId,
      });
      if (res.status == 200 || res.status == 201) {
        setWalletDetails(res?.data?.msg);
      }
    } catch (err) {
      console.log("error in get wallet data", err);
    }
  };
  useEffect(() => {
    CheckWalletandAddMoney();
    GetRevenueDetails();
  }, []);

  const permissionList = [
    "view-finance-margin-cut",
    "view-finance-wallet",
    "view-finance-analytics",
    "view-finance-revenue",
    "view-finance-transaction",
  ];

  const getTabLabel = (permission) => {
    const labelMap = {
      "view-finance-margin-cut": "Margin & Cut",
      "view-finance-wallet": "Wallet",
      "view-finance-analytics": "Analytics",
      "view-finance-revenue": "Revenue",
      "view-finance-transaction": "Transaction",
    };
    return labelMap[permission];
  };
  const permissions = CheckTabsPermissions(permissionList);

  const panels = [
    {
      permission: "view-finance-margin-cut",
      component: <FinanceMarginCut />,
    },
    {
      permission: "view-finance-wallet",
      component: (
        <FinanceWallet
          role={role}
          userId={userId}
          revenueData={revenueData}
          wallletDetails={wallletDetails}
          routeHandler={routeHandler}
        />
      ),
    },
    {
      permission: "view-finance-analytics",
      component: <FinanceAnalytics />,
    },
    {
      permission: "view-finance-revenue",
      component: <Revenue />,
    },
    {
      permission: "view-finance-transaction",
      component: (
        <FinanceTransaction
          role={role}
          userId={userId}
          revenueData={revenueData}
          wallletDetails={wallletDetails}
          routeHandler={routeHandler}
        />
      ),
    },
  ];

  const breadcrumbs = [{ label: "Dashboard", link: "/" }, { label: "Finance" }];
  return (
    <>
      <div className="width-90LR">
        <div className="flex-class">
          <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
        </div>
        <Grid container className="device-container mt20px">
          <Grid item xs={12}>
            <TabList
              md={md}
              value={value}
              centered={true}
              classes={{ Tabs: "TabStyleAddDevice", Tab: "TabChangesDevice" }}
              handleChange={handleChange}
              getTabLabel={getTabLabel}
              permissions={permissions}
            />
          </Grid>
        </Grid>
        <TabPanelList value={value} permissions={permissions} panels={panels} />{" "}
      </div>
    </>
  );
};

export default Finance;
