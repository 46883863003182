import React, { useState, useEffect } from "react";

import { Grid, useTheme, useMediaQuery } from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
//core component
import axios from "../../../../api/axiosInstance";
import ViewTab from "./Camp_Details_Tab/Camp_view_Tab";
import ProgressTab from "./Camp_Details_Tab/ProgressTab";
import TabList from "../../../../component/CustomTabs/TabList";
import OverviewTab from "./Camp_Details_Tab/Camp_Overview_Tab";
import AnalticsTab from "./Camp_Details_Tab/Camp_Analytics_Tab";
import BillBoradTab from "./Camp_Details_Tab/Camp_BillBoard_Tab";
import permissionUtils from "../../../../component/permissionUtils";
import TabPanelList from "../../../../component/CustomTabs/TabPanel";
import CustomBreadcrumbs from "../../../../component/CustomBreadcrumbs";

//================================ Main Component =============================//
export default function Status() {
  const params = useParams();
  const theme = useTheme();
  const location = useLocation();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [updatedRoutesPermit, PermissionCheck, CheckTabsPermissions] =
    permissionUtils();
  const [loading, setLoading] = useState(true);
  const [campaignData, setCampaignData] = useState("");
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [imagefile, setImageFile] = useState([]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  // get Order by id

  const getCampaignDataById = async (campId) => {
    try {
      let res = await axios.get(
        `api/order/getOrderById_v1/${campId ? campId : params?.campaign_id}`
      );
      console.log("response from getting order by id =>", res);
      if (res.status == 200 || res.status == 201) {
        setCampaignData(res?.data?.msg);
        setLoading(false);
      }
    } catch (err) {
      console.log("error from getOrder => ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCampaignDataById();
  }, []);

  const breadcrumbs = [
    { label: "Dashboard", link: "/" },
    {
      label: "Campaign Management",
      link: location?.state?.route
        ? location?.state?.route
        : "/admin/campaignmgt",
    },
    {
      label:
        campaignData?.adTitle?.length > 40
          ? `${campaignData?.adTitle?.substring(0, 40)}`
          : campaignData?.adTitle,
    },
  ];

  const permissionList = [
    "overview-Tab",
    "view-campaign-billboard-list",
    "view-campaign-analytics",
    "view-campaign-images",
    "view-campaign-progress",
  ];
  const getTabLabel = (permission) => {
    const labelMap = {
      "overview-Tab": "Overview",
      "view-campaign-billboard-list": "Billboards",
      "view-campaign-analytics": "Analytics",
      "view-campaign-images": "Views",
      "view-campaign-progress": "Progress",
    };
    return labelMap[permission];
  };
  const permissions = CheckTabsPermissions(permissionList);
  const panels = [
    {
      permission: "overview-Tab",
      component: (
        <OverviewTab
          data={campaignData}
          loading={loading}
          handleChange={handleChange}
          getCampaignDataById={getCampaignDataById}
        />
      ),
    },
    {
      permission: "view-campaign-billboard-list",
      component: <BillBoradTab data={campaignData} loading={loading} />,
    },
    {
      permission: "view-campaign-analytics",
      component: <AnalticsTab data={campaignData} loading={loading} />,
    },
    {
      permission: "view-campaign-images",
      component: (
        <ViewTab
          data={campaignData}
          imagefile={imagefile}
          loadingFiles={loadingFiles}
        />
      ),
    },
    {
      permission: "view-campaign-progress",
      component: (
        <ProgressTab
          data={campaignData}
          campaignData={campaignData}
          loading={loading}
        />
      ),
    },
  ];
  return (
    <>
      <div className="width-90LR ">
        <div className="flex-class">
          <CustomBreadcrumbs breadcrumbItems={breadcrumbs} />
        </div>
        <Grid container className="device-container mt20px">
          <Grid item xs={12}>
            <TabList
              md={md}
              value={value}
              centered={true}
              classes={{ Tabs: "TabStyleAddDevice", Tab: "TabChangesDevice" }}
              handleChange={handleChange}
              getTabLabel={getTabLabel}
              permissions={permissions}
            />
          </Grid>
        </Grid>
        <TabPanelList value={value} permissions={permissions} panels={panels} />{" "}
      </div>
    </>
  );
}
